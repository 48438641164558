// import * as types from '../store/mutation-types'
import { store, service } from '../main.js'
import config from '../config'

export default {
  async GetWallet() {
    const response = await service.get('/wallet/user/' + store.getters['user/info'].ID, 'userwallet')
    if (response.data) {
      // call the action to commit here
      store.dispatch('user/setWallet', response.data)
    }
  },
  GetPSPBlockedCountries() {
    return service.get('/finance/psp-blocked-countries', null, 'pspblockedcountries').then(response => {
      if (response.data.PSPs) {
        // call the action to commit here
        store.dispatch('finance/setPSPBlockedCountries', response.data.PSPs)
      }
    })
  },
  GetUserAccountTypes() {
    return service.get('/users/account-types/' + store.getters['user/info'].ID, 'useraccounttypes').then(response => {
      if (response.data.accountTypes) {
        // call the action to commit here
        store.dispatch('user/setAccountTypes', response.data.accountTypes)
      } else {
        // ('empty account types')
      }
    })
  },
  GetTradingAccounts() {
    return new Promise((resolve, reject) => {
      return service
        .post('/ta/user', {
          userID: store.getters['user/info'].ID,
        })
        .then(response => {
          let TA = response?.data?.TAs || []
          // remove if PAMM account become available
          // TA = TA.filter((t) => t.platform !== 'pamm')
          TA.map(ta => {
            let b = parseFloat(ta.balance).toFixed(2)
            let e = parseFloat(ta.equity).toFixed(2)
            let cs = config.currencies[ta.currency]
            ta.balance = isNaN(b) ? '' : b
            ta.balance_format = isNaN(b) ? '' : cs + ' ' + b
            ta.equity = isNaN(e) ? '' : e
            ta.equity_format = isNaN(e) ? '' : cs + ' ' + e
          })
          // call the action to commit here
          store.dispatch('user/setTA', TA)

          resolve(response)
        })
        .catch(reject)
    })
  },
  GetDocumentCount() {
    return service.get('/documents/user/' + store.getters['user/info'].ID).then(response => {
      let count = 0
      if (response.data && response.data.Documents) {
        let docs = response.data.Documents
        for (let d in docs) {
          if (docs[d].document_status != 'rejected') {
            count++
          }
        }
      }
      store.dispatch('user/setDocCount', count)
    })
  },

  async GetBonusData() {
    if (store.getters['user/info'] && store.getters['user/info'].ID > 0) {
      const response = await service.get('/bonus/' + store.getters['user/info'].ID)
      if (response.data && response.data.Bonuses) {
        store.dispatch('bonusconfig/setBonusData', response.data.Bonuses)
      }
    }
  },
}
