<template>
  <div>
    <sub-menu
      :title="title"
      :routes="routes"
    ></sub-menu>

    <router-view></router-view>
  </div>
</template>

<script>
import SubMenu from '@/components/SubMenu.vue'
import { mapGetters } from 'vuex'


export default {
  data() {
    return {
      title: this.$t('trading'),
      routes: this. getRoutes()
    }
  },
  components: {
    SubMenu,
  },
    watch: {
    Language() {
      this.updateRoutesAndTitle()
    }
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
    }),
  },
  methods: {
    getRoutes() {
      return   [
        { path: '/trading-account', title: this.$t('account_list').tTitle() },
        { path: '/trading-account/vps', title: this.$t('vps') },
        { path: '/trading-account/download', title: this.$t('platforms').tTitle() },
      ]
    },
    updateRoutesAndTitle() {
      this.title = this.$t('trading').tTitle()
      this.routes = this.getRoutes()
    }
  }
}
</script>
