<template>
  <div
    class="content has-text-left"
  >
    <div
      id="clientDetails"
      class="modal"
      v-show="clientDetailsModal"
    >
      <div class="fixed inset-0 z-50 bg-gray-900 bg-opacity-70 w-screen h-screen">
        <div
          id="ClientDetailsInner"
          class="flex flex-col items-center lg:items-end ltr:right-0 rtl:left-0 top-0 z-30 overflow-x-hidden overflow-y-scroll h-full w-full lg:w-auto justify-center"
        >
          <div class="w-11/12 max-w-[1540px]">
            <button
              @click="closeClientDetailsModal()"
              class="bg-white rounded-full h-10 w-10 lg:self-start appearance-none flex-shrink-0 mt-2 p-0"
            >
              <div class="h-6 w-0.5 bg-blue rotate-45 ltr:ml-5 rtl:mr-5"></div>
              <div class="h-6 w-0.5 bg-blue -rotate-45 -mt-6 ltr:ml-5 rtl:mr-5"></div>
            </button>
          </div>
          <div class="bg-white ltr:rounded-br-xl ltr:lg:rounded-br-none ltr:rounded-tr-xl ltr:lg:rounded-tr-none ltr:rounded-tl-xl ltr:rounded-bl-xl rtl:rounded-bl-xl rtl:lg:rounded-bl-none rtl:rounded-tl-xl rtl:lg:rounded-tl-none rtl:rounded-tr-xl rtl:rounded-br-xl py-8 md:py-12 px-5 md:px-10 lg:px-24 w-11/12 max-w-[1540px] relative mt-4  mb-20 md:mb-0 md:max-h-[700px] md:h-full overflow-y-auto"
          >
            <div class="mb-6 md:mb-12 mt-0 md:mt-4">
              <p class="text-gray-900 font-bold font-display text-[1.4rem] md:text-3xl">{{$t('client_details')}}</p>
              <p class="text-base text-gray-900">
                <span v-if="modal.client.total_commission_generated">{{$t('commission').tTitle()}}: {{currencies['USD'].symbol}}{{modal.client.total_commission_generated.toFixed(2)}}</span>
                <span v-if="modal.client.volume_traded">| {{$t('volume').tTitle()}}: {{modal.client.volume_traded}}</span>
              </p>
            </div>
            <div class="flex w-full dates">
              <div
                class="w-1/2 mb-6 mt-4"
                v-if="dateFrom"
              >
                <h4 class="text-base text-gray-900 font-medium">{{$t('date_from').tTitle()}}:</h4>
                <p class="text-base text-gray-900">
                  <span>{{$formatDateTime(dateFrom)}}</span>
                </p>
              </div>
              <div
                class="w-1/2 mb-6 mt-4"
                v-if="dateTo"
              >
                <h4 class="text-base text-gray-900 font-medium">{{$t('date_to').tTitle()}}:</h4>
                <p class="text-base text-gray-900">
                  <span>{{$formatDateTime(dateTo)}}</span>
                </p>
              </div>
            </div>
            <div
              class="flex justify-between w-full gap-10 flex-col md:flex-row lg:max-h-[500px] lg:overflow-hidden lg:overflow-y-scroll"
              id="taClientContainer"
              ref="containerToTop"
            >
              <div class="w-full md:w-[15rem]" >

                <p
                  class="text-base text-gray-900 font-semibold pb-2"
                  @click="modal.TAListActive = !modal.TAListActive"
                >
                  {{$t('select_trading_account').tTitle()}}
                </p>
                <div
                  class="w-full flex flex-row md:flex-col gap-2.5 md:gap-0 overflow-x-scroll"
                  id="TAsContainer"
                >
                  <div
                    class="h-[7.3rem] w-60 rounded-lg border border-solid shadow-ts2 justify-center items-center flex gap-4 md:mb-4 cursor-pointer shrink-0 md:shrink"
                    v-for="ta in modal.ta"
                    :class="[ta.external_id == modal.selected.ta.external_id?'border-blue':'border-gray-300']"
                    @click="modal.cLogsPage = 1, modal.selected.ta = ta, modal.TAListActive = false, getCommissionLogs() , scrollToTop()"
                    :key="ta.external_id"
                  >
                    <div>
                      <img
                        v-if="ta.type=='demo'"
                        class="h-[70px] w-[70px]"
                        src="../../assets/img/demo.svg"
                      />
                      <img
                        v-else-if="ta.account_type_identifier=='silver'"
                        class="h-[70px] w-[70px]"
                        src="../../assets/img/silver.svg"
                      />
                      <img
                        v-else-if="ta.account_type_identifier=='gold-ecn' || ta.account_type_identifier=='gold-raw'"
                        class="h-[70px] w-[70px]"
                        src="../../assets/img/gold.svg"
                      />
                      <img
                        v-else-if="ta.account_type_identifier=='silver-islamic'"
                        class="h-[70px] w-[70px]"
                        src="../../assets/img/silver-islamic.svg"
                      />
                      <img
                        v-else-if="ta.account_type_identifier=='gold-raw-is'"
                        class="h-[70px] w-[70px]"
                        src="../../assets/img/islamic.svg"
                      />
                      <img
                        v-else-if="ta.account_type_identifier=='platinum-vip'"
                        class="h-[70px] w-[70px]"
                        src="../../assets/img/platinum.svg"
                      />
                      <img
                        v-else-if="ta.account_type_identifier=='micro'"
                        class="h-[70px] w-[70px]"
                        src="../../assets/img/micro.svg"
                      />
                      <img
                        v-else-if="ta.account_type_identifier=='pamm-investor'"
                        class="h-[70px] w-[70px]"
                        src="../../assets/img/pamm-investor.svg"
                      />
                      <img
                        v-else-if="ta.account_type_identifier=='pamm-master'"
                        class="h-[70px] w-[70px]"
                        src="../../assets/img/pamm-investor.svg"
                      />
                    </div>
                    <div class="ltr:mr-5 rtl:ml-5">
                      <div class="h-5 bg-green text-white text-xs font-semibold rounded-xl px-5 pt-0.5 inline-block">{{ta.type.tTitle()}}</div>
                      <p class="text-gray-900 font-bold text-xl">{{ta.external_id}}</p>
                      <p class="text-gray-900 text-xs">{{ ta.platform=='mt4'?'MetaTrader 4':ta.platform=='mt5'?'MetaTrader 5':ta.platform=='pamm'?'PAMM':''}}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-full md:w-3/4 md:max-w-[60%] lg:max-w-none md:mt-[30px]">
                <div
                  class="flex gap-2.5 overflow-x-scroll mb-7 flex-col md:flex-row"
                  id="taList"
                >
                  <div class="w-full md:w-60 bg-blue bg-opacity-15 rounded-lg py-3 md:py-7 px-5 shrink-0">
                    <p class="text-gray-900 text-sm font-medium pb-1">{{$t('trading_account').tTitle()}}</p>
                    <p class="text-blue font-bold text-4xl">{{modal.selected.ta.external_id}}</p>
                  </div>
                  <div class="w-full md:w-60 bg-blue bg-opacity-15 rounded-lg py-3 md:py-7 px-5 shrink-0">
                    <p class="text-gray-900 text-sm font-medium pb-1">{{$t('platform').tTitle()}}</p>
                    <p class="text-blue font-bold text-4xl">{{ modal.selected.ta.platform=='mt4'?'MT4':modal.selected.ta.platform=='mt5'?'MT5':modal.selected.ta.platform=='pamm'?'PAMM':''}}</p>
                  </div>
                  <div class="w-full md:w-60 bg-blue bg-opacity-15 rounded-lg py-3 md:py-7 px-5 shrink-0">
                    <p class="text-gray-900 text-sm font-medium pb-1">{{$t('total_commission_generated').tTitle()}}</p>
                    <p class="text-blue font-bold text-4xl">{{$formatMoney(modal.selected.commission, currencies['USD'].symbol)}}</p>
                  </div>
                  <div class="w-full md:w-60 bg-blue bg-opacity-15 rounded-lg py-3 md:py-7 px-5 shrink-0">
                    <p class="text-gray-900 text-sm font-medium pb-1">{{$t('volume_traded').tTitle()}}</p>
                    <p class="text-blue font-bold text-4xl">{{modal.selected.volume.toFixed(2)}}</p>
                  </div>
                </div>

                <div v-if="modal.commissionLogs.length>0">
                  <table
                    class="w-full hidden lg:table"
                    :class="{'direction-rtl': Language == 'fa'}"
                  >
                    <thead>
                      <tr>
                        <th class="ltr:pl-7 rtl:pr-7 w-36 text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t('order').tTitle()}}</th>
                        <th class="w-36 text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t('pair').tTitle()}}</th>
                        <th class="w-36 text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t('volume').tTitle()}}</th>
                        <th class="w-36 text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t('close_date').tTitle()}}</th>
                        <th class="w-36 text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t('commission').tTitle()}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="clog in modal.commissionLogs"
                        :key="clog.order_id"
                        class="odd:bg-[#F0F0F4]"
                      >
                        <td class="ltr:pl-7 rtl:pr-7 text-base text-gray-900 py-3 ltr:rounded-tl-md ltr:rounded-bl-md rtl:rounded-tr-md rtl:rounded-br-md">{{clog.order_id}}</td>
                        <td class="text-base text-gray-900 py-3">{{clog.order_pair}}</td>
                        <td class="text-base text-gray-900 py-3">{{clog.order_volume.toFixed(2)}}</td>
                        <td class="text-base text-gray-900 py-3">{{$formatDateTime(clog.order_close_date)}}</td>
                        <td class="text-base text-gray-900 py-3 ltr:rounded-tr-md ltr:rounded-br-md rtl:rounded-tl-md rtl:rounded-bl-md ltr:pr-5 rtl:pl-5">{{$formatMoney(clog.calculated_commission, currencies['USD'].symbol)}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="w-full lg:hidden text-body">
                    <div
                      v-for="(clog,i) in modal.commissionLogs"
                      :key="clog.order_id"
                      :id="clog.order_id"
                      class="px-4 pt-3 rounded-lg odd:bg-[#F0F0F4] overflow-hidden"
                    >
                      <div
                        @click="expandClientsDetailsRow(i)"
                        class="flex justify-between cursor-pointer pb-3"
                      >
                        <label class="w-1/2 block text-blue font-bold">
                          <img
                            :id="'arrow-details-'+i"
                            width="12px"
                            height="12px"
                            class="arrow-modal ltr:mr-1 rtl:ml-1 inline"
                            :class="{'-rotate-90': !clog.expanded}"
                            src='../../assets/img/platform-dropdown.svg'
                          >
                          {{$t('order').tTitle()}}
                        </label>
                        <p class="w-1/2 ltr:text-right rtl:text-left">{{clog.order_id}}</p>
                      </div>
                      <div
                        :id="'content-details-'+i"
                        class="row-modal "
                        :class="{'hidden': !clog.expanded}"
                      >
                        <div class="flex justify-between">
                          <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("pair").tTitle()}}</label>
                          <p class="h-9">{{clog.order_pair}}</p>
                        </div>
                        <div class="flex justify-between">
                          <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("volume").tTitle()}}</label>
                          <p class="h-9">{{clog.order_volume.toFixed(2)}}</p>
                        </div>
                        <div class="flex justify-between">
                          <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("close_date").tTitle()}}</label>
                          <p class="h-9">{{$formatDateTime(clog.order_close_date)}}</p>
                        </div>
                        <div class="flex justify-between">
                          <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("commission").tTitle()}}</label>
                          <p class="h-9">{{$formatMoney(clog.calculated_commission, currencies['USD'].symbol)}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <pagination
                ref="modalPagination"
                for="commission-logs"
                :records="modal.totalCommissionLogs"
                :per-page="pagination.maxItemsPerPage"
                :chunk="4"
                :count-text="pagination.total + ' ' + $t('records') "
              ></pagination> -->
                  <div class="flex justify-between w-full py-5 mx-5">
                    <div class="w-[100px] h-[40px] font-body text-sm leading-10">{{modal.pagination.total}} {{$t('records')}}</div>
                    <div
                      v-if="modal.pagination.total > modal.pagination.rowsPerPage"
                      class="flex h-[40px] font-body text-center leading-10"
                    >
                      <pagination-component
                        :page="modal.pagination.page"
                        :maxPerPage="modal.pagination.rowsPerPage"
                        :total="modal.pagination.total"
                      />
                    </div>
                    <div class="w-[100px]"></div>
                  </div>
                </div>
                <div
                  v-else
                  class="mt-10"
                >
                  <p class="text-center text-base text-blue font-semibold">{{$t('no_records')}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mx-5 md:mx-8 mt-10 flex gap-5 flex-col md:flex-row mb-14">
      <div class="w-full md:w-1/3">
        <label class="text-base text-gray-900 inline-block pb-1.5 w-full">{{$t('name').tTitle()}}</label>
        <input
          v-model="nameSearch"
          name="nameSearch"
          class="rounded-md bg-gray-100 ltr:pl-5 rtl:pr-5 w-full h-[50px] text-base text-gray-900 focus:outline-none focus:ring-0 focus:bg-gray-100 border placeholder:text-gray-900 placeholder:text-opacity-20"
          type="text"
          :placeholder="$t('name').tTitle()"
          @change="getIBClients(1)"
        />
      </div>
      <div class="w-full md:w-1/3 relative">
        <label class="text-base text-gray-900 inline-block pb-1.5 w-full">{{$t('date_from').tTitle()}}</label>
        <date-picker
          class="date-picker text-base text-gray-900 font-semibold"
          type="date"
          format="YYYY-MM-DD"
          value-type="format"
          v-model="dateFrom"
          lang="en"
          name="dateFrom"
          :placeholder="$t('date_from').tTitle()"
        ></date-picker>
        <img
          class="absolute w-3.5 h-2 mt-0.5 ltr:right-5 rtl:left-5 top-[3.2rem]"
          src="../../assets/img/platform-dropdown.svg"
        />
      </div>
      <div class="w-full md:w-1/3 relative">
        <label class="text-base text-gray-900 inline-block pb-1.5 w-full">{{$t('date_to').tTitle()}}</label>
        <date-picker
          class="date-picker text-base text-gray-900 font-semibold"
          type="date"
          format="YYYY-MM-DD"
          value-type="format"
          v-model="dateTo"
          lang="en"
          name="dateTo"
          :placeholder="$t('date_to').tTitle()"
        ></date-picker>
        <img
          class="absolute w-3.5 h-2 mt-0.5 ltr:right-5 rtl:left-5 top-[3.2rem]"
          src="../../assets/img/platform-dropdown.svg"
        />
      </div>

    </div>

    <div
      v-if="IBClients.length>0"
      class="mx-5 lg:mx-7"
    >
      <table
        id="ib-clients"
        class="w-full hidden lg:table"
      >
        <thead>
          <tr>
            <th class="ltr:pl-7 rtl:pr-7 text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t("status").tTitle()}}</th>
            <th
              class="text-blue font-bold text-base pb-4 cursor-pointer ltr:text-left rtl:text-right"
              :class="orderClass('date')"
              @click="changeOrderBy('date')"
            >
              <div class="flex">
                {{$t("date_joined").tTitle()}}
                <img
                  class="w-3 h-2 mt-2.5 ltr:ml-1.5 rtl:mr-1.5"
                  src="../../assets/img/platform-dropdown.svg"
                />
              </div>
            </th>
            <th
              class="text-blue font-bold text-base pb-4 cursor-pointer ltr:text-left rtl:text-right"
              :class="orderClass('fullName')"
              @click="changeOrderBy('fullName')"
            >
              <div class="flex">
                {{$t('full_name').tTitle()}}
                <img
                  class="w-3 h-2 mt-2.5 ltr:ml-1.5 rtl:mr-1.5"
                  src="../../assets/img/platform-dropdown.svg"
                />
              </div>
            </th>
            <th
              class="text-blue font-bold text-base pb-4 cursor-pointer ltr:text-left rtl:text-right"
              :class="orderClass('commission')"
              @click="changeOrderBy('commission')"
            >
              <div class="flex">
                {{$t('total_commission_generated').tTitle()}}
                <img
                  class="w-3 h-2 mt-2.5 ltr:ml-1.5 rtl:mr-1.5"
                  src="../../assets/img/platform-dropdown.svg"
                />
              </div>
            </th>
            <th
              class="text-blue font-bold text-base pb-4 cursor-pointer ltr:text-left rtl:text-right"
              :class="orderClass('volume')"
              @click="changeOrderBy('volume')"
            >
              <div class="flex">
                {{$t('volume_traded').tTitle()}}
                <img
                  class="w-3 h-2 mt-2.5 ltr:ml-1.5 rtl:mr-1.5"
                  src="../../assets/img/platform-dropdown.svg"
                />
              </div>
            </th>
            <th class="ltr:text-left rtl:text-right text-blue font-bold text-base pb-4">{{$t('total_deposits').tTitle()}}</th>
            <th class="ltr:text-left rtl:text-right text-blue font-bold text-base pb-4">{{$t('total_withdrawals').tTitle()}}</th>
            <th class="ltr:text-left rtl:text-right text-blue font-bold text-base pb-4"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="client in IBClients"
            :key="client.client_id"
            class="odd:bg-[#F0F0F4]"
          >
            <td class="ltr:pl-7 rtl:pr-7 text-base text-gray-900 py-3 ltr:rounded-tl-md ltr:rounded-bl-md rtl:rounded-tr-md rtl:rounded-br-md">{{$statusFormat(client.status)}}</td>
            <td class="text-base text-gray-900 py-3">{{$formatDate(client.date_joined)}}</td>
            <td class="text-base text-gray-900 py-3">{{client.full_name}}</td>
            <td class="text-base text-gray-900 py-3">
              <template v-if="client.total_commission_generated">{{$formatMoney(client.total_commission_generated, currencies['USD'].symbol)}}</template>
            </td>
            <td class="text-base text-gray-900 py-3">{{client.volume_traded}}</td>
            <td class="text-base text-gray-900 py-3 tableCarousel">
              <VueSlickCarousel
                v-if="client.total_deposits"
                :dots="false"
                :infinite="true"
                :slidesToShow=1
                :speed=500
                class="w-[100px]"
              >
                <span
                  v-for="(value, currency) in client.total_deposits"
                  :key="currency"
                  class="ltr:pl-3 rtl:pr-3"
                >{{$formatMoney(value, currencies[currency].symbol)}}</span>
              </VueSlickCarousel>
            </td>
            <td class="text-base text-gray-900 py-3 tableCarousel">
              <VueSlickCarousel
                v-if="client.total_withdrawals"
                :dots="false"
                :infinite="true"
                :slidesToShow=1
                :speed=500
                class="w-[100px]"
              >
                <span
                  v-for="(value, currency) in client.total_withdrawals"
                  :key="currency"
                  class="ltr:pl-3 rtl:pr-3"
                >{{$formatMoney(value, currencies[currency].symbol)}}</span>
              </VueSlickCarousel>
            </td>
            <td class="text-base text-gray-900 py-3 ltr:rounded-tr-md ltr:rounded-br-md rtl:rounded-tl-md rtl:rounded-bl-md ltr:pr-5 rtl:pl-5">
              <a
                class="cursor-pointer"
                @click="openClientDetailsModal(client)"
              >
                <img
                  src="../../assets/img/info.svg"
                  class="h-4 w-4"
                />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="w-full lg:hidden text-body">
        <div
          v-for="(client,i) in IBClients"
          :key="client.client_id"
          :id="client.client_id"
          class="px-4 pt-3 rounded-lg odd:bg-[#F0F0F4] overflow-hidden"
        >
          <div
            @click="expandIBClient(i)"
            class="flex justify-between cursor-pointer pb-3"
          >
            <label class="w-1/2 block text-blue font-bold">
              <img
                :id="'arrow-'+i"
                width="12px"
                height="12px"
                class="arrow ltr:mr-1 rtl:ml-1 inline"
                :class="{'-rotate-90': !client.expanded}"
                src='../../assets/img/platform-dropdown.svg'
              >
              {{$t('full_name').tTitle()}}
            </label>
            <p class="w-1/2 ltr:text-right rtl:text-left">{{client.full_name}}</p>
          </div>
          <div
            :id="'content-'+i"
            class="row "
            :class="{'hidden': !client.expanded}"
          >
            <div class="flex justify-between">
              <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("status").tTitle()}}</label>
              <p class="h-9">{{$statusFormat(client.status)}}</p>
            </div>
            <div class="flex justify-between">
              <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("date_joined").tTitle()}}</label>
              <p class="h-9">{{$formatDate(client.date_joined)}}</p>
            </div>
            <div class="flex justify-between">
              <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("total_commission").tTitle()}}</label>
              <p class="h-9"><template v-if="client.total_commission_generated">{{$formatMoney(client.total_commission_generated, currencies['USD'].symbol)}}</template></p>
            </div>
            <div class="flex justify-between">
              <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("volume_traded").tTitle()}}</label>
              <p class="h-9">{{client.volume_traded}}</p>
            </div>
            <div class="flex justify-between tableCarousel">
              <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("total_deposits").tTitle()}}</label>
              <VueSlickCarousel
                v-if="client.total_deposits"
                :dots="false"
                :infinite="true"
                :slidesToShow=1
                :speed=500
                class="w-[100px]"
              >
                <p class="h-9"><span
                    v-for="(value, currency) in client.total_deposits"
                    :key="currency"
                    class="ltr:pl-3 rtl:pr-3"
                  >{{$formatMoney(value, currencies[currency].symbol)}}</span></p>
              </VueSlickCarousel>
            </div>
            <div class="flex justify-between tableCarousel">
              <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("total_withdrawals").tTitle()}}</label>
              <VueSlickCarousel
                v-if="client.total_withdrawals"
                :dots="false"
                :infinite="true"
                :slidesToShow=1
                :speed=500
                class="w-[100px]"
              >
                <p class="h-9"><span
                    v-for="(value, currency) in client.total_withdrawals"
                    :key="currency"
                    class="ltr:pl-3 rtl:pr-3"
                  >{{$formatMoney(value, currencies[currency].symbol)}}</span></p>
              </VueSlickCarousel>
            </div>
            <div class="ltr:ml-5 rtl:mr-5 h-9">
              <a
                class="cursor-pointer"
                @click="openClientDetailsModal(client)"
              >
                <img
                  src="../../assets/img/info.svg"
                  class="h-4 w-4"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text-center text-base text-blue font-semibold">{{$t('no_records')}}</p>
    </div>
    <!-- <pagination
      ref="ibClients"
      for="ib-client"
      :records="pagination.total"
      :per-page="pagination.maxItemsPerPage"
      :chunk="4"
      :count-text="pagination.total + ' ' + $t('records') "
    ></pagination> -->
    <div
      v-if="IBClients.length>0"
      class="flex justify-between w-full py-5 mx-5"
    >
      <div class="w-[100px] h-[40px] font-body text-sm leading-10">{{pagination.total}} {{$t('records')}}</div>
      <div
        v-if="pagination.total > pagination.rowsPerPage"
        class="flex h-[40px] font-body text-center leading-10"
      >
        <pagination-component
          :page="pagination.page"
          :maxPerPage="pagination.rowsPerPage"
          :total="pagination.total"
        />
      </div>
      <div class="w-[100px]"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { service } from '../../main.js'
import config from '../../config'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import PaginationComponent from '../../components/Pagination.vue'
import { exportCSVFile } from '@/utils/csvUtils.js';

export default {
  props: {
    IBID: Number,
    isClientTab:Boolean
  },
  components: {
    DatePicker,
    VueSlickCarousel,
    PaginationComponent,
  },
  data() {
    return {
      expanded: false,
      localIBID: null,
      IBClients: [],
      currencies: config.currencies,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        pageIBClients: [],
        total: 0
      },
      orderBy: 'date',
      orderMode: 'desc',
      dateFrom: '',
      dateTo: '',
      nameSearch: '',
      datepicker: {
        clearButton: true,
        clearButtonIcon: 'fa fa-close',
        format: 'dd/MM/yyyy',
      },
      modal: {
        cLogsPage: 1,
        selected: {
          ta: 0,
          client: 0,
          commission: 0,
          volume: 0,
        },
        client: {},
        ta: [],
        commissionLogs: [],
        totalCommissionLogs: 0,
        TAListActive: true,
        pagination: {
          page: 1,
          rowsPerPage: 10,
          pageCommissionLogs: [],
          total: 0
        },
      },
      clientDetailsModal:false,
      IBClientsArgs:{}
    }
  },
  watch: {
    dateFrom() {
      this.getIBClients(1)
    },
    dateTo() {
      this.getIBClients(1)
    },
    IBID() {
      this.setIBID()
    },
    User() {
      this.setIBID()
    },
    'pagination.page'(p) {
      // this.changePage()
      this.getIBClients(p)
    },
    'modal.pagination.page'(p) {
      // this.changeClogsPage()
      this.getCommissionLogs(p)
    },
    'clientDetailsModal'(val){
     if (val == true && this.isClientTab == true ) {
      this.disableScroll()
     } else if (val == false && this.isClientTab == true){
      this.enableScroll()
     }
    },

  },

  computed: {
    ...mapGetters({
      User: 'user/all',
      Requests: 'requests/all',
      Language: 'language/code',
      logoLoaderActive: 'logoLoader/active',
    }),
  },
  created() {
    this.$bus.$on('pageChanged', p => {
      this.pagination.page = p
    })
    this.$bus.$on('pageChanged', p => {
      this.modal.pagination.page = p
    })
    this.$on('export-csv', this.exportCSVData);
  },
  mounted() {
    // clients pagination
    // PaginationEvent.$on('vue-pagination::ib-client', page => {
    //   this.pagination.page = page
    //   this.getIBClients(page)
    // })
    // // commission logs pagination
    // PaginationEvent.$on('vue-pagination::commission-logs', page => {
    //   this.modal.cLogsPage = page
    //   this.getCommissionLogs(page)
    // })
    this.setIBID()
  },
  beforeDestroy() {
    this.$bus.$off('pageChanged', p => {
      this.pagination.page = p
    })
    this.$bus.$off('pageChanged', p => {
      this.modal.pagination.page = p
    })
  },
  methods: {
    setIBID() {
      if (this.IBID > 0) {
        this.localIBID = this.IBID
      } else {
        this.localIBID = this.User.info.ID
      }
      this.getIBClients(1)
    },
    openClientDetailsModal(client) {
      this.initializeModal()
      this.modal.client = client
      this.clientDetailsModal= true
      // document.getElementById('clientDetails').classList.toggle('hidden')
      // this.$refs.modalPagination.setPage(1)
      // this.modal.cLogsPage = 1

      // get client trading account(s)
      this['logoLoader/set'](true)
      service
        .post('/ib/clients/ta', {
          userID: this.localIBID,
          clientID: client.client_id,
        })
        .then(response => {
          if (response.data.TAs) {
            this.modal.ta = response.data.TAs
            // this.modal.ta = this.modal.ta.filter((t) => t.platform !== 'pamm')
            // get first ta's commission logs
            // client/commission-logs
            this.modal.selected.client = client.client_id
            this.modal.selected.ta = this.modal.ta[0]
            this.getCommissionLogs()
            this.dragContainer('#taList')
            this.dragContainer('#TAsContainer')
          }
          this['logoLoader/set'](false)
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    },
    closeClientDetailsModal() {
      // document.getElementById('clientDetails').classList.add('hidden')
      this.clientDetailsModal = false
      this.pagination.page = 1
      this.modal.pagination.page = 1
    },
    getCommissionLogs(page) {
      if (!this.modal.selected.client) {
        return
      }
      if (!page) {
        if (this.modal.cLogsPage) {
          page = this.modal.cLogsPage
        } else {
          page = 1
        }
      }

      let dateTimeFrom = this.dateFrom
      let dateTimeTo = this.dateTo

      if (dateTimeFrom != '') {
        dateTimeFrom += ' 00:00:00'
      }
      if (dateTimeTo != '') {
        dateTimeTo += ' 23:59:59'
      }

      let dateFromUnix = moment(dateTimeFrom, 'YYYY-MM-DD hh:mm:ss').utcOffset(0, true).unix()
      let dateToUnix = moment(dateTimeTo, 'YYYY-MM-DD hh:mm:ss').utcOffset(0, true).unix()

      if (isNaN(dateFromUnix)) {
        dateFromUnix = 0
      }
      if (isNaN(dateToUnix)) {
        dateToUnix = 0
      }

      this.modal.cLogsPage = page
      this['logoLoader/set'](true)
      service
        .post('/ib/clients/ta/commission-logs', {
          userID: this.localIBID,
          clientID: this.modal.selected.client,
          page: page,
          TAID: this.modal.selected.ta.external_id,
          dateFrom: dateFromUnix,
          dateTo: dateToUnix,
        })
        .then(response => {
          this.modal.selected.commission = 0
          this.modal.selected.volume = 0
          this.modal.commissionLogs = []
          if (response.data.commissionLogs) {
            this.modal.commissionLogs = response.data.commissionLogs
          }
          if (response.data.totalCommissionLogs) {
            this.modal.totalCommissionLogs = response.data.totalCommissionLogs
          }
          if (response.data.totalCommission) {
            this.modal.selected.commission = response.data.totalCommission
          }
          if (response.data.totalVolume) {
            this.modal.selected.volume = response.data.totalVolume
          }
          this.modal.pagination.total = response.data.totalCommissionLogs
          this.changeClogsPage()
          this['logoLoader/set'](false)
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    },
    orderClass(name) {
      return this.orderBy === name ? this.orderMode : ''
    },
    changeOrderBy(orderBy) {
      if (this.orderBy === orderBy) {
        this.orderMode = this.orderMode === 'desc' ? 'asc' : 'desc'
      } else {
        this.orderMode = 'desc'
      }
      this.orderBy = orderBy
      this.getIBClients(1)
    },
    getIBClients(page) {
      if (isNaN(parseInt(page))) {
        setTimeout(() => {
          this.getIBClients(1)
        }, 200)
        return
      }
      if (!page) {
        if (this.pagination.page) {
          page = this.pagination.page
        } else {
          page = 1
        }
      }

      let dateTimeFrom = this.dateFrom
      let dateTimeTo = this.dateTo

      if (dateTimeFrom != '') {
        dateTimeFrom += ' 00:00:00'
      }
      if (dateTimeTo != '') {
        dateTimeTo += ' 23:59:59'
      }

      let dateFromUnix = moment(dateTimeFrom, 'YYYY-MM-DD hh:mm:ss').utcOffset(0, true).unix()
      let dateToUnix = moment(dateTimeTo, 'YYYY-MM-DD hh:mm:ss').utcOffset(0, true).unix()

      if (isNaN(dateFromUnix)) {
        dateFromUnix = 0
      }
      if (isNaN(dateToUnix)) {
        dateToUnix = 0
      }

      this.IBClientsArgs = {
         userID: this.localIBID,
          page: -1,
          orderBy: this.orderBy,
          orderMode: this.orderMode || 'desc',
          dateFrom: dateFromUnix,
          dateTo: dateToUnix,
          nameSearch: this.nameSearch,
      }

      this['logoLoader/set'](true)
      service
        .post('/ib/clients', {
          userID: this.localIBID,
          page: page,
          orderBy: this.orderBy,
          orderMode: this.orderMode || 'desc',
          dateFrom: dateFromUnix,
          dateTo: dateToUnix,
          nameSearch: this.nameSearch,
        })
        .then(response => {
          if (typeof response.data.IBClients !== 'undefined') {
            this.IBClients = response.data.IBClients
            this.pagination.total = response.data.cnt
            // this.orderBy = 'date'
          } else {
            // empty table
            this.IBClients = {}
            this.pagination.total = 0
          }
          this.changePage()
          this['logoLoader/set'](false)
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    },
    initializeModal() {
      this.modal.cLogsPage = 1
      this.modal.selected.ta = 0
      this.modal.selected.client = 0
      this.modal.selected.commission = 0
      this.modal.selected.volume = 0
      this.modal.client = {}
      this.modal.ta = []
      this.modal.totalCommissionLogs = 0
      this.modal.TAListActive = true
    },
    changePage() {
      this.pagination.pageIBClients = this.IBClients

      // let start = (this.pagination.page - 1) * this.pagination.rowsPerPage
      // let end = start + this.pagination.rowsPerPage

      // for (let i = start; i < end; i++) {
      //   if (this.IBClients[i] === undefined) {
      //     break
      //   }
      //   this.IBClients[i].expanded = false
      //   this.pagination.pageIBClients.push(this.IBClients[i])
      // }
    },
    changeClogsPage() {
      this.modal.pagination.pageCommissionLogs = this.modal.commissionLogs

      // let start = (this.modal.pagination.page - 1) * this.modal.pagination.rowsPerPage
      // let end = start + this.modal.pagination.rowsPerPage

      // for (let i = start; i < end; i++) {
      //   if (this.modal.commissionLogs[i] === undefined) {
      //     break
      //   }
      //   this.modal.commissionLogs[i].expanded = false
      //   this.modal.pagination.pageCommissionLogs.push(this.modal.commissionLogs[i])
      // }
    },
    expandIBClient(i) {
      this.$set(this.pagination.pageIBClients, i, { ...this.pagination.pageIBClients[i], expanded: !this.pagination.pageIBClients[i].expanded })
    },
    expandClientsDetailsRow(i) {
      // let arrows = document.getElementsByClassName('arrow-modal')
      // let rows = document.getElementsByClassName('row-modal')

      // for (let i = 0; i < arrows.length; i++) {
      //   if (i != index) {
      //     arrows[i].classList.add('-rotate-90')
      //     rows[i].classList.add('hidden')
      //   }
      // }

      // let arrow = document.getElementById('arrow-details-' + index)
      // arrow.classList.toggle('-rotate-90')

      // let content = document.getElementById('content-details-' + index)
      // content.classList.toggle('hidden')

      this.$set(this.modal.pagination.pageCommissionLogs, i, { ...this.modal.pagination.pageCommissionLogs[i], expanded: !this.modal.pagination.pageCommissionLogs[i].expanded })
    },

    scrollToTop() {
      this.$refs.containerToTop.scrollTop = 0;
    },
     disableScroll() {
      document.querySelector('body').classList.add('overflow-hidden')
    },
    enableScroll() {
      document.querySelector('body').classList.remove('overflow-hidden')
    },
    async exportCSVData() {
      let CsvData;

      try {
        const response = await service.post('/ib/clients', this.IBClientsArgs);
        if (response && response.data && response.data.IBClients) {
          CsvData = response.data.IBClients;
        }
      } catch (error) {
        return;
      }

      const headers = {
        client_id: 'Client ID',
        date_joined: 'Date Joined',
        full_name: 'Full Name',
        ib_id: 'Ib ID',
        status: 'Status',
      };

      const itemsFormatted = CsvData.map(item => ({
        client_id: item.client_id,
        date_joined: item.date_joined,
        full_name: item.full_name,
        ib_id: item.ib_id,
        status: item.status,
      }));

      const fileTitle = 'Clients';
      exportCSVFile(headers, itemsFormatted, fileTitle);
    },
    ...mapActions(['logoLoader/set', 'user/setIB']),
  },
}
</script>
<style>
div.date-picker.mx-datepicker {
  width: 100% !important;
  background-color: #f0f0f4;
  border-radius: 6px;
  padding-left: 10px;
  height: 49px;
  font-size: 16px;
  color: #2d394b;
}

div.date-picker.mx-datepicker input[type='text'].mx-input {
  height: 49px !important;
  border: 0px !important;
  background-color: #f0f0f4;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

div.date-picker.mx-datepicker input[type='text'].mx-input::placeholder {
  color: #2d394b;
  opacity: 0.2;
}

div.date-picker.mx-datepicker i.mx-icon-calendar {
  opacity: 0 !important;
}

.mx-datepicker-popup {
  z-index: 10!important;
}

#taList::-webkit-scrollbar,
#TAsContainer::-webkit-scrollbar {
  display: none;
}

.tableCarousel .slick-prev,
.tableCarousel .slick-next {
  text-indent: -999999px;
  margin-left: -10px;
  position: absolute;
  top: 0;
}
.tableCarousel .slick-next {
  right: 10px;
}
.tableCarousel .slick-prev:before,
.tableCarousel .slick-next:before {
  content: '';
  background-image: url('../../assets/img/platform-dropdown.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 30px;
  background-size: 10px 10px;
  background-position: center;
  transform: rotate(90deg);
  inset: 0px;
  z-index: 1;
}

.tableCarousel .slick-next:before {
  transform: rotate(270deg);
}

#taClientContainer::-webkit-scrollbar {
  width: 4px;
}

#taClientContainer::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 12px;
}

#taClientContainer::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 12px;
}

/* Handle */
#taClientContainer::-webkit-scrollbar-thumb {
  background: #2b63e3;
  border-radius: 12px;
}

.date-picker input {
  color: #2d394b;
  font-weight: 600;
  font-size: 16px;
}
</style>
