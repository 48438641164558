
export default {
  inserted(el, binding) {
    const adjustAttribute = (el, attr) => {
      const elementType = el.tagName.toLowerCase();
      const para = document.querySelector(elementType);
      const currentStyle = window.getComputedStyle(para);

      const currentValueString = currentStyle.getPropertyValue(attr);

      const currentValue = parseFloat(currentValueString);

      if (!isNaN(currentValue) && currentValue > 0) {
        const zoomLevel = window.devicePixelRatio * 100;
        if (zoomLevel === 100) {
          el.style.removeProperty(attr);
        } else {
          const adjustedValue = zoomLevel > 125 ? currentValue - 8 : currentValue;
          el.style[attr] = `${adjustedValue}px`;
        }
      }
    };

    adjustAttribute(el, binding.value);
  },

  unbind(el, binding) {
    const attr = binding.value;
    el.style.removeProperty(attr);
  }
};
