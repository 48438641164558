import menuRoutes from '../../router/menu'

const state = {
  all: menuRoutes.initial(),
}

const mutations = {
  RESET_MENU(state) {
    state.all = menuRoutes.initial()
  },
}

const actions = {
  reset: ({ commit }) => commit('RESET_MENU'),
}

const getters = {
  all: state => state.all,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
