<template>
  <div>
    <div
      class="w-full flex flex-wrap justify-center bg-blue items-center relative z-50"
      style="height: 70px;"
    >
      <router-link :to="'/' + Language + '/'">
        <img
          width="176"
          src="../../assets/img/plexytrade-logo-white.svg"
        />
      </router-link>
      <div class="absolute ltr:right-4 rtl:left-4 top-4">
          <language-popover></language-popover>
      </div>
    </div>
    <div class="flex justify-center items-center h-calc1 relative z-0 m-2 md:m-0">
      <div class="bg-white rounded-lg shadow-ts1 px-5 md:px-7 py-12 w-full md:w-[36rem] max-w-[580px]">
        <h1 class="text-blue font-display font-bold text-3xl md:text-[2.5rem] pb-10 text-center">{{$t('reset_password').tTitle()}}</h1>
        <form
          v-if="validCode == true"
          @submit.prevent
          data-vv-scope="form-resetPassword"
        >
          <div class="mb-10">
            <label
              for="newPassword"
              class="text-base text-gray-900"
            >{{$t('new_password').tTitle()}}</label>
            <input
              type="password"
              name="newPassword"
              v-model="data.body.password"
              :data-vv-as="$t('new_password').tTitle()"
              v-validate="'required|strong-password|confirmed:newPasswordConfirm'"
              class="rounded-md bg-gray-100 ltr:pl-5 rtl:pr-5 w-full h-12 mt-2 text-base text-gray-900 font-semibold focus:outline-none focus:ring-0 border border-gray-100 border-solid focus:border-gray-900 placeholder:text-gray-900 placeholder:text-opacity-20"
              :placeholder="$t('new_password').tTitle()"
            />
            <span
              v-show="errors.has('form-resetPassword.newPassword')"
              class="text-xs leading-3 text-red"
              :class="{'text-right': Language == 'fa'}"
            >{{ errors.first('form-resetPassword.newPassword') }}</span>
          </div>
          <div class="mb-10">
            <label
              for="newPasswordConfirm"
              class="text-base text-gray-900"
            >{{$t('confirm_new_password').tTitle()}}</label>
            <input
              type="password"
              name="newPasswordConfirm"
              v-model="data.body.passwordConfirm"
              v-validate="'required'"
              class="rounded-md bg-gray-100 ltr:pl-5 rtl:pr-5 w-full h-12 mt-2 text-base text-gray-900 font-semibold focus:outline-none focus:ring-0 border border-gray-100 border-solid focus:border-gray-900 placeholder:text-gray-900 placeholder:text-opacity-20"
              :placeholder="$t('confirm_new_password').tTitle()"
              :data-vv-as="$t('confirm_new_password').tTitle()"
              ref="newPasswordConfirm"
            />
            <span
              v-show="errors.has('form-resetPassword.newPasswordConfirm')"
              class="text-xs text-red"
              :class="{'text-right': Language == 'fa'}"
            >{{ errors.first('form-resetPassword.newPasswordConfirm') }}</span>
          </div>
          <button
            @click="updatePassword()"
            class="bg-blue text-white text-center text-base font-bold h-[3.1rem] w-[12.3rem] rounded-md m-auto table appearance-none mb-10"
          >{{$t('submit').tTitle()}}</button>
          <p class="text-gray-900 w-full text-center text-[11px] md:text-xs border-t border-solid border-[#DEDFE2] pt-10">{{$t('risk_sentence')}}<a
              class="font-bold ltr:pl-1 rtl:pr-1"
              :href="privacyPolicyLink"
              target="_blank"
            >{{$t('privacy_policy').tTitle()}}</a></p>
        </form>
        <div
          v-if="validCode == false"
          class="text-center text-red"
        >{{$t("error_expired_or_invalid_request").tTitle()}}</div>
      </div>
    </div>
    <div class="max-w-[1399px] relative m-auto">
      <footer-collapsible></footer-collapsible>
    </div>
  </div>
</template>

<script>
import { service } from '../../main.js'
import config from '../../config'
import { mapActions, mapGetters } from 'vuex'
import FooterCollapsible from '../../components/FooterCollapsible.vue'
import LanguagePopover from '@/components/Language.vue'
import links from '../../config/links'


export default {
  components: {
    FooterCollapsible,
    LanguagePopover
  },
  data() {
    return {
      validCode: false,
      data: {
        body: {
          password: '',
          passwordConfirm: '',
        },
      },
      error: null,
    }
  },
  computed: {
    websiteURL() {
      return config.URL.website
    },
     privacyPolicyLink() {
      return links.getLink('privacyPolicy', this.Language);
    },
    ...mapGetters({
      Language: 'language/code',
      logoLoaderActive: 'logoLoader/active',
    }),
  },
  mounted() {
    this.$bus.$emit('ChangeHeadings', this.$t('reset_password').tCapitalizeFirstLetter(), null)
    this.checkForgotPasswordRequestCode()

    if (this.$auth.check()) {
      this.$router.push('/' + (this.Language || 'en') + '/' + config.defaultURL)
    }
  },
  watch: {
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },
  },
  methods: {
    checkForgotPasswordRequestCode() {
      service
        .post('/users/validate-forgot-password-request', {
          code: this.$route.query.c,
        })
        .then(response => {
          if (response.data.success) {
            this.validCode = true
          } else {
            this.validCode = false
          }
        })
        .catch(() => {
          this.validCode = false
        })
    },
    updatePassword() {
      this['logoLoader/set'](true)
      this.$validator
        .validateAll('form-resetPassword')
        .then(result => {
          if (!result) {
            this['logoLoader/set'](false)
            return
          } else {
            service
              .post('/users/reset-password', {
                code: this.$route.query.c,
                newPassword: this.data.body.password,
              })
              .then(response => {
                this['logoLoader/set'](false)
                if (response.data.success) {
                  this.$toasted.success(this.$t('password_reset_successfully').tTitle(), { duration: 10000 })
                  this.$router.push('/' + (this.Language || 'en') + '/login')
                }
                 else {
                  if (typeof response.data.messageID !== 'undefined') {
                    if (response.data.messageID === "new_old_password_are_the_same") {
                      this.$toasted.error(this.$t('new_old_password_are_the_same').tTitle(), { duration: 10000 })
                      this.data.body.password = ""
                      this.data.body.passwordConfirm = ""
                      this.validCode = true
                    } else {
                    this.$toasted.error(this.$t(response.data.messageID).tTitle(), { duration: 10000 })
                    this.validCode = false
                    }
                  }
                }
              })
              .catch(() => {
                this['logoLoader/set'](false)
                this.$toasted.error(this.$t('internal_error'), { duration: 10000 })
                this.validCode = false
              })
          }
        })
        .catch(() => {
          this['logoLoader/set'](false)
          this.$toasted.error(this.$t('password_too_week_or_mismatch').tTitle(), { duration: 10000 })
        })
    },
    ...mapActions(['logoLoader/set']),
  },
  filters: {
    json: function (value) {
      return value
    },
  },
}
</script>
