<template>
  <div
    class="register-menu-wrapper h-[30%] lg:h-auto w-full lg:w-[30%] xl:w-[20%] rounded-b-2xl lg:rounded-r-2xl lg:rounded-bl-none lg:flex lg:flex-col"
    :class="accountType.toLowerCase() === 'demo' ? 'bg-gray-900' : 'bg-blue'"
  >
    <div class="risk-wrapper w-full lg:hidden lg:w-[70%] lg:m-auto">
      <footer-collapsible></footer-collapsible>
    </div>
    <!-- change logo with the correct one -->
    <div class="flex flex-col items-center">
      <div class="logo-wrapper lg:order-2 w-full">
        <div class="bg-blue lg:bg-transparent flex justify-center py-[18px] relative">
          <router-link :to="'/' + Language + '/login'">
            <img class="w-[162px] lg:w-[200px] h-[32px] lg:h-[40px]" src="@/assets/img/plexytrade-logo-white.svg" />
          </router-link>
          <div class="block lg:hidden absolute ltr:right-4 rtl:left-4 top-4">
            <language-popover></language-popover>
          </div>
        </div>
      </div>
      <!-- add back button background -->
      <div class="back-button my-[18px] lg:my-10 lg:order-1 cursor-pointer">
        <router-link class="font-body text-base text-white flex items-center gap-2" :to="'/' + Language + '/register'">
          <img class="h-3 w-4 inline" src="/static/img/back-arrow.svg" />
          {{ $t('back').tTitle() }}
        </router-link>
      </div>
    </div>
    <div class="content-wrapper flex flex-col items-center" :class="accountType.toLowerCase() === 'demo' ? 'bg-gray-900' : 'bg-blue'">
      <div class="account-type py-4 lg:hidden">
        <div class="font-display font-bold text-3xl text-white">
          {{ $t(`${accountType}_account`).tTitle() }}
        </div>
      </div>
      <div class="steps-wrapper grid pb-7 lg:grid-cols-1 lg:mt-[160px]" :class="accountType.toLowerCase() === 'demo' ? 'w-[60%] grid-cols-2-default' : 'grid-cols-3-default'">
        <div
          class="step flex flex-row relative justify-center"
          :class="{ 'lg:mb-[112px]': (accountType.toLowerCase() === 'demo' && step.step < 2) || (accountType !== 'demo' && step.step < 3) }"
          v-for="(step, index) in steps"
          :key="index"
        >
          <div class="step-content flex flex-col items-center text-center" :class="{ 'opacity-30': step.step !== activeStep }">
            <span
              class="counter flex rounded-full bg-white justify-center text-[25px] lg:text-[50px] lg:leading-[55px] font-display font-bold px-[7px] py-[9px] w-[50px] h-[50px] lg:w-[70px] lg:h-[70px] lg:mb-5"
              :class="accountType.toLowerCase() === 'demo' ? 'text-gray-900' : 'text-blue'"
            >
              {{ step.step }}
            </span>
            <p class="text-white text-4 w-full">{{ $t(`${step.label}`) }}</p>
          </div>
          <span
            v-if="(accountType.toLowerCase() === 'demo' && step.step < 2) || (accountType !== 'demo' && step.step < 3)"
            class="spacer absolute ltr:right-[-15px] rtl:left-[-15px] w-[30px] lg:w-[85px] h-[2px] bg-white rounded-xl mt-[25px] opacity-30 lg:rotate-90 ltr:lg:left-0 ltr:lg:right-0 rtl:lg:right-0 rtl:lg:left-0 lg:top-[140%] lg:m-auto"
          ></span>
        </div>
      </div>
      <div class="disclaimer-wrapper pb-7 w-[70%] text-center" v-if="accountType.toLowerCase() === 'demo'">
        <p class="font-body font-bold text-white pb-5">{{ $t('reg_disclaimer_title') }}</p>
        <p class="font-body text-white pb-5" :class="showDisclaimer ? 'block' : 'hidden lg:block'">{{ $t('reg_disclaimer_text') }}</p>
        <div class="lg:hidden cursor-pointer flex justify-center" @click="showDisclaimer = !showDisclaimer">
          <img v-if="showDisclaimer" class="h-[16px] w-[16px]" src="/static/img/demo-arrow-up.svg" />
          <img v-else class="h-[16px] w-[16px]" src="/static/img/demo-arrow-down.svg" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FooterCollapsible from '@/components/FooterCollapsible.vue'
import LanguagePopover from '@/components/Language.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    FooterCollapsible,
    LanguagePopover
  },
  props: {
    accountType: String,
    activeStep: Number,
    isCompany: Boolean,
    steps: Array,
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
    }),
  },
  data() {
    return {
      showDisclaimer: false,
    }
  },
}
</script>
