<template>
  <div>
    <label for="coinType" class="text-gray-900">{{$t('bitcoin_note').tTitle()}}</label>

    <div class="mt-2 mb-5">
      <p class="control">
        <span class="select-box">
          <span class="select">
            <select
              v-validate="'required'"
              name="coinType"
              v-model="coinType"
              :data-vv-as="$t('cryptocurrency')"
              class="bg-white font-semibold rtl:bg-left"
              :class="{'is-danger': errors.has('coinAddress')}"
            >
              <option disabled value="">{{ $t('cryptocurrency')}}</option>
              <option value="BITCOIN-BLOCKCHAIN-MAINNET-BTC">Bitcoin (Bitcoin Network)</option>
              <option value="ETHEREUM-BLOCKCHAIN-MAINNET-ETH">Ether (Ethereum Network ERC-20)</option>
              <option value="LITECOIN-BLOCKCHAIN-MAINNET-LTC">Litecoin (Litecoin Network)</option>
              <!-- <option value="SOLANA-BLOCKCHAIN-MAINNET-SOL">Sol (Solana Network)</option> -->
              <option value="ETHEREUM-BLOCKCHAIN-MAINNET-USDC">USDC (Ethereum Network ERC-20)</option>
              <option value="TRON-BLOCKCHAIN-MAINNET-USDT">USDT (Tron Network TRC-20)</option>
              <!-- <option value="TRON-BLOCKCHAIN-MAINNET-TRX">Tronix (Tron Network TRC-20)</option> -->
            </select>
          </span>
        </span>
        <span
          v-show="errors.has('coinType')"
          class="text-red text-xs pt-0.5"
        >{{ errors.first('coinType') }}</span>
      </p>
    </div>

    <label for="coinAddress" class="text-base text-gray-900 mb-2">{{$t('crypto_network_address').tTitle()}}</label>
    <div v-if="coinType != 'LIGHTNING'" class="mt-2 mb-5">
      <p class="control">
        <input
          v-validate="'required'"
          :class="{'input': true, 'is-danger': errors.has('coinAddress') }"
          name="coinAddress"
          type="text"
          class="bg-white input placeholder:text-gray-900 placeholder:opacity-50"
          :data-vv-as="$t('cryptocurrency_address')"
          :placeholder="$t('cryptocurrency_address')"
          v-model="coinAddress"
        />
        <span
          v-show="errors.has('coinAddress')"
          class="text-red text-xs pt-0.5"
        >{{ errors.first('coinAddress') }}</span>
      </p>
    </div>

    <div class="mb-5 flex gap-5 items-start flex-col md:flex-row">
      <div class="grow flex flex-col w-full">
        <label for="OTP" class="mb-2">{{$t("otp")}}</label>
        <div class="columns">
          <div class="column is-8">
            <input
              v-validate="'required'"
              :class="{'input': true, 'is-danger': errors.has('OTP') }"
              name="OTP"
              type="text"
              :data-vv-as="'OTP'"
              :placeholder="'OTP'"
              v-model="OTP"
              class="bg-white input placeholder:text-gray-900 placeholder:opacity-50"
            />
            <span
              v-show="errors.has('OTP')"
              class="text-red text-xs pt-0.5"
            >{{ errors.first('OTP') }}</span>
          </div>
        </div>
      </div>
      <div class="column is-4 mt-2">
        <button
          class="min-w-[160px] px-2 h-12 bg-green text-center text-white text-sm font-bold table rounded-md p-0 cursor-pointer md:mt-6"
          :disabled="!otpBtnEnabled"
          @click="generateOTP()"
        >{{$t("otp_send_email")}}</button>
      </div>
    </div>

    <div class="flex gap-5 mt-7 items-start flex-col md:flex-row">
      <div class="w-full md:w-auto">
        <label
          for="currency"
          class="text-base text-gray-900"
        >{{$t('currency').tTitle()}}</label>
        <div class="mt-2">
          <div class="bg-white py-3 w-full md:w-44 text-gray-900 text-base font-semibold ltr:pl-5 rtl:pr-5 rounded-md">{{User.wallet.currency}}</div>
        </div>
      </div>
      <div class="grow w-full md:w-auto">
        <label
          for="amount"
          class="text-base text-gray-900"
        >{{$t('amount').tTitle()}}</label>
        <input
          v-validate="'required|currency|min_value:15|max_value:50000'"
          :class="{'input': true, 'is-danger': errors.has('amount') }"
          name="amount"
          type="text"
          :data-vv-as="translations.text.amount"
          :placeholder="currencySymbols[User.wallet.currency].symbol+'00.000'"
          v-model="amount"
          class="mt-2 bg-white"
        />
        <span
          v-show="errors.has('amount')"
          class="text-red text-xs pt-0.5"
          :class="{'text-right': Language == 'fa'}"
        >{{ amount>=50000? $t('withdrawal_confirmo_amount_error') :errors.first('amount') }}</span>
      </div>
    </div>

    <div class="mt-5 mb-10">
      <p class="text-gray-900 text-base">{{$t('popular_withdrawals')}}</p>
      <div class="flex mt-2 gap-1 md:gap-2 flex-wrap xl:flex-nowrap">
        <div
          v-for="item in popularWithdrawals"
          :key=item
          @click="selectedPopularWithdrawal=item;amount=item"
          class="w-[100px] xl:w-1/6 shrink-0 xl:shrink py-3 text-gray-900 text-base font-semibold rounded-md text-center bg-white border border-solid cursor-pointer focus:border-0 focus:outline-none focus:ring-0"
          :class="[selectedPopularWithdrawal==item?'border-green':'border-white']"
        >{{currencySymbols[User.wallet.currency].symbol+item}}</div>
      </div>
    </div>

    <a
      class="bg-gray-900 text-white text-center text-base font-bold py-3 rounded-md w-52 table cursor-pointer mb-10"
      @click="withdraw"
    >
      <span>{{$t("withdraw").tTitle()}}</span>
    </a>
    <!-- <p class="text-sm text-gray-900 mb-10">{{ $t('provider_redirect', { provider: 'Confirmo'}) }}</p> -->
  </div>
</template>

<script>
// import {service} from '../../../main.js'
import config from '../../../config'
import { mapActions, mapGetters } from 'vuex'
import { service } from '../../../main.js'

export default {
  data() {
    return {
      amount: 0,
      coinType: '',
      coinAddress: '',
      otpBtnEnabled: true,
      OTP: '',
      translations: {
        text: {
          amount: this.$t('amount'),
          coinAddress: this.$t('your_cryptocurrency_address'),
        },
      },
      currencySymbols: config.currencies,
      selectedPopularWithdrawal: '',
      popularWithdrawals: ['50', '75', '250', '500', '1000', '5000'],
    }
  },
  watch: {
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
    }),
  },
  mounted() {},
  methods: {
    withdraw() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$bus.$emit('RequestWithdraw', '/finance/withdraw', {
            amount: String(this.amount),
            userID: this.$store.state.user.info.ID,
            psp: 'confirmo',
            extraInfo: JSON.stringify({ coinType: this.coinType, coinAddress: this.coinAddress }),
            OTP: this.OTP,
          })
        }
      })
    },
    generateOTP() {
      service
        .post('/finance/generate-otp', {
          userID: this.$store.state.user.info.ID,
          additionalText: this.$t('otp_additional_text'),
        })
        .then(response => {
          if (response.data.success) {
            this.$toasted.success(this.$t('otp_new_password'), { duration: 10000 })
          } else {
            this.$toasted.error(this.$t('otp_error'), { duration: 10000 })
          }
        })
        .catch(error => {
          this.$toasted.error(this.$t('otp_error') + error, { duration: 10000 })
        })
      this.otpBtnEnabled = false
      setTimeout(
        function () {
          this.otpBtnEnabled = true
        }.bind(this),
        15000
      )
    },
    ...mapActions(['logoLoader/set']),
  },
}
</script>
