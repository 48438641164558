<template>
  <div class="content has-text-centered">
    <success></success>
    <p class="has-text-left">{{ $t('edata_success_note')}}</p>
  </div>
</template>

<script>
import Success from './Success'

export default {
  components: {
    Success,
  },
  data() {
    return {
      title: '',
      blockCookieName: 'ccFailedAttempts',
    }
  },
  mounted() {
    this.$createCookie(this.blockCookieName, 0, 1)

    // check if pending
    if (Object.keys(this.$route.query).length > 0) {
      if (this.$route.query['status'] && this.$route.query['status'].toLowerCase() === 'pending') {
        // show pending deposit message
        this.$toasted.success(this.$t('pending_deposit_success'), { duration: 10000 })
      }
    }

    this.title = this.$t('method_deposit_status', { method: 'Credit Card' })
    this.$bus.$emit('ChangeHeadings', this.title, 'Credit Card')
  },
  methods: {},
}
</script>
