<template>
  <div>
    <!-- <div class="box p-5 bg-[#d0d2d9] rounded-lg mb-6">
      <h4
        class="mb-2 text-gray-900 text-base font-semibold"
        :class="{'text-right': Language == 'fa'}"
      >
        <span class="text-red font-semibold">{{$t("price_estimate")}}</span>
      </h4>
      <p
        class="mb-6 text-gray-900 text-base"
        :class="{'has-text-right': Language == 'fa'}"
      >{{ $t('lydiax_withdrawal_note', { currency: User.wallet.currency, crypto: selectedCrypto.name || 'crypto'}) }}</p>

      <div
        class="flex gap-5 items-start flex-col md:flex-row"
        :class="{'text-right': Language == 'fa'}"
      >
        <div class="grow">
          <p
            class="control flex flex-col"
            :class="{'has-text-right': Language == 'fa'}"
          >
            <label
              class="text-base text-gray-900 font-semibold"
              for="estimate"
            >{{ $t('enter_currency_amount', { currency: User.wallet.currency}) }}</label>
            <input
              class="mt-2 bg-white"
              v-validate="'decimal:10'"
              name="estimate"
              type="text"
              v-model="estimate.fiat"
            />
            <span
              v-show="errors.has('estimate')"
              :class="{'has-text-right': Language == 'fa'}"
              class="text-red text-xs pt-0.5"
            >{{ errors.first('estimate') }}</span>
          </p>
        </div>

        <a
          class="w-40 h-[50px] bg-green text-center text-white text-base font-bold table rounded-md py-3 cursor-pointer md:mt-8"
          @click="estimatePrice"
        >
          <span>{{ $t('check_price') }}</span>
        </a>

      </div>
      <span
        class="text-red text-sm"
        v-if="estimate.crypto"
      >{{ $t('estimated') }} {{ estimate.crypto.toFixed(10) }} {{ selectedCrypto.code || 'crypto' }}</span>
    </div> -->

    <h5
      class="text-base text-gray-900 mt-5 mb-2 rtl:text-right"
    >{{$t("select_cryptocurrency")}}</h5>
    <div
      class="field"
    >
      <div class="control">
        <span class="select">
          <select
            name="cryptocurrency"
            v-model="cryptocurrency"
            @change="$validator.clean()"
            v-validate="'required'"
            class="bg-white font-semibold rtl:bg-left"
          >
            <option value="">{{$t('lydiax_please_select')}}</option>
            <option
              :value="crypto.code"
              v-for="(crypto, i) in filteredCryptocurrencyOptions"
              :key="'crypto-options-' + i"
            >{{ crypto.nameWithNetwork }}</option>
          </select>
          <span
              v-show="errors.has('cryptocurrency')"
              class="text-xs text-red rtl:text-right"
            >{{ errors.first('cryptocurrency') }}</span>
        </span>
      </div>
    </div>

    <div v-if="cryptocurrency == 'USDT'"  class="select-box network-box">
      <h5
        class="text-base text-gray-900 mt-5 mb-2"
      >{{ $t('select_crypto_network', { crypto: cryptocurrency}).tTitle() }}</h5>
      <span class="select">
        <select
          name="network"
          v-validate="'required'"
          v-model="network"
          class="bg-white rtl:bg-left"
        >
          <option
            :value="netw"
            v-for="(netw, i) in networkOptsUSDT"
            :key="'network-' + i"
          >{{ netw }}</option>
        </select>
        <span
            v-show="errors.has('network')"
            class="text-xs text-red rtl:text-right"
          >{{ errors.first('network') }}</span>
      </span>
    </div>
    <div v-if="cryptocurrency == 'USDC'"  class="select-box network-box">
      <h5
        class="text-base text-gray-900 mt-5 mb-2"

      >{{ $t('select_crypto_network', { crypto: cryptocurrency}).tTitle() }}</h5>
      <span class="select">
        <select
          name="network"
          v-validate="'required'"
          v-model="network"
          class="bg-white rtl:bg-left"
        >
          <option
            :value="netw"
            v-for="(netw, i) in networkOptsUSDC"
            :key="'network-' + i"
          >{{ netw }}</option>
        </select>
        <span
            v-show="errors.has('network')"
            class="text-xs text-red rtl:text-right"
          >{{ errors.first('network') }}</span>
      </span>
    </div>
    <h5
      class="text-base text-gray-900 mt-5 mb-2"
    >{{ $t('please_enter_your_crypto_address', { crypto: selectedCrypto.name}) }}</h5>
    <!-- <p class="subtitle"></p> -->

    <div class="field">
      <p class="control">
        <input
          v-validate="'required'"
          :class="{'input': true, 'is-danger': errors.has('cryptoAddress') }"
          name="cryptoAddress"
          type="text"
          :data-vv-as="$t('your_crypto_address', {crypto: this.selectedCrypto.name})"
          :placeholder="selectedCrypto.name !== undefined ? $t('please_enter_your_crypto_address', { crypto: selectedCrypto.name}) : $t('crypto_address')"
          v-model="cryptoAddress"
          class="bg-white"
        />
        <span
          v-show="errors.has('cryptoAddress')"
          class="text-xs text-red"
          :class="{'has-text-right': Language == 'fa'}"
        >{{ errors.first('cryptoAddress') }}</span>
      </p>
    </div>

    <div
      class="field"
      v-if="selectedCrypto.code === 'XRP'"
    >
      <p class="control">
        <input
          v-validate=""
          :class="{'input': true, 'is-danger': errors.has('destinationTag') }"
          name="destinationTag"
          type="text"
          :data-vv-as="$t('destination_tag')"
          :placeholder="$t('destination_tag')"
          v-model="destinationTag"
          class="mt-2 bg-white"
        />
      </p>
    </div>


    <div class="mt-5 flex gap-5 items-start flex-col md:flex-row">
      <div class="grow flex flex-col">
        <label
          for="OTP"
          class="text-base text-gray-900"
          :class="{'has-text-right': Language == 'fa'}"
        >{{$t("otp")}}</label>
        <div class="columns">
          <div class="column is-8">
            <input
              v-validate="'required'"
              :class="{'input': true, 'is-danger': errors.has('OTP') }"
              name="OTP"
              type="text"
              :data-vv-as="'OTP'"
              :placeholder="'OTP'"
              v-model="OTP"
              class="mt-2 bg-white"
            />
            <span
              v-show="errors.has('OTP')"
              class="text-xs text-red"
              :class="{'has-text-right': Language == 'fa'}"
            >{{ errors.first('OTP') }}</span>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <button
          :disabled="!otpBtnEnabled"
          @click="generateOTP()"
          class="min-w-[160px] h-[50px] px-2 bg-green text-center text-white text-sm font-bold table rounded-md p-0 cursor-pointer md:mt-8"
        >{{$t("otp_send_email")}}</button>
      </div>
    </div>

    <div class="flex gap-5 mt-7 items-start flex-col md:flex-row">
      <div class="w-full md:w-auto">
        <label
          for="currency"
          class="text-base text-gray-900"
        >{{$t('currency').tTitle()}}</label>
        <div class="mt-2">
          <div class="bg-white py-3 w-full md:w-44 text-gray-900 text-base font-semibold ltr:pl-5 rtl:pr-5 rounded-md">{{User.wallet.currency}}</div>
        </div>
      </div>
      <div class="grow w-full md:w-auto">
        <label
          for="amount"
          class="text-base text-gray-900"
        >{{$t('amount').tTitle()}}</label>
        <input
          v-if="User.wallet.currency === 'USD'"
          v-validate="'required|currency|min_value:75|max_value:50000'"
          :class="{'input': true, 'is-danger': errors.has('amount') }"
          name="amount"
          type="text"
          :data-vv-as="$t('amount')"
          :placeholder="$t('amount')"
          v-model="amount"
          class="mt-2 bg-white"
        />
        <input
          v-else
          v-validate="'required|currency|min_value:75|max_value:50000'"
          :class="{'input': true, 'is-danger': errors.has('amount') }"
          name="amount"
          type="text"
          :data-vv-as="$t('amount')"
          :placeholder="$t('amount')"
          v-model="amount"
          class="mt-2 bg-white"
        />
        <span
          v-show="errors.has('amount')"
          class="text-xs text-red"
          :class="{'has-text-right': Language == 'fa'}"
        >{{ amount>50000 ? $t('lydiax_withdrawal_limit_error'):errors.first('amount') }}</span>
      </div>
    </div>
    <a
      class="bg-gray-900 text-white text-center text-base font-bold py-3 rounded-md w-52 table cursor-pointer my-5 md:my-10"
      @click="withdraw"
    >
      <span>{{$t("withdraw").tTitle()}}</span>
    </a>
    <div v-if="selectedCrypto.code === 'XRP' && showXRPPopUp">
      <div class="fixed flex justify-center items-center inset-0 z-50 bg-gray-900 bg-opacity-70 w-full h-full">
        <div class="bg-white rounded-lg w-11/12 lg:w-[642px] max-w-[642px] m-auto relative max-h-[82%] p-10">
          <button @click="showXRPPopUp = false" class="bg-white rounded-full h-10 w-10 absolute -top-14 right-0 appearance-none p-0">
            <div class="h-6 w-0.5 bg-blue rotate-45 ml-5"></div>
            <div class="h-6 w-0.5 bg-blue -rotate-45 -mt-6 ml-5"></div>
          </button>
          <p class="text-base leading-7 text-gray-dark">{{ $t('destination_tag_required')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '../../../config'
import { mapActions, mapGetters } from 'vuex'
import { service } from '../../../main.js'

export default {
  data() {
    return {
      amount: 0,
      cryptoAddress: '',
      cryptocurrency: '',
      cryptocurrencyOptions: config.cryptocurrencyOptions,
      otpBtnEnabled: true,
      OTP: '',
      destinationTag: '',
      currencySymbols: config.currencies,
      estimate: {
        crypto: 0,
        fiat: 0,
        raw: {},
      },
      network: '',
      networkOptsUSDC: ['ERC20'],
      networkOptsUSDT: ['ERC20', 'TRC20'],
      showXRPPopUp: false
    }
  },
  watch: {
    selectedCrypto(){
      console.log(this.selectedCrypto)
      if (this.selectedCrypto.code === 'XRP') return this.showXRPPopUp = true
    },
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },
  },
  computed: {
    filteredCryptocurrencyOptions() {
      return this.cryptocurrencyOptions
    },
    selectedCrypto() {
      if(this.cryptocurrency!=''){
        return this.cryptocurrencyOptions.filter(c => c.code === this.cryptocurrency)[0]
      }
      return ''
    },
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
    }),
  },
  mounted() {},
  methods: {
    estimatePrice() {
      this.estimate.crypto = 0
      this.estimate.raw = {}

      this['logoLoader/set'](true)
      service
        .post('finance/get-lydiax-price', {
          order_type: 'buy',
          crypto: this.cryptocurrency,
        })
        .then(response => {
          const orderPrice = response.data
          if (orderPrice.currency !== this.User.wallet.currency) {
            service.get('/finance/rates/' + orderPrice.currency + '/' + this.User.wallet.currency).then(response => {
              this.estimate.crypto = this.estimate.fiat / (orderPrice.price * response.data.rate)
            })
          } else {
            this.estimate.crypto = this.estimate.fiat / orderPrice.price
          }

          this.estimate.raw = orderPrice
          this['logoLoader/set'](false)
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    },
    withdraw() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$bus.$emit('RequestWithdraw', '/finance/withdraw', {
            amount: String(this.amount),
            userID: this.$store.state.user.info.ID,
            psp: 'lydiax',
            extraInfo: JSON.stringify({
              address: this.cryptoAddress,
              cryptocurrency: this.cryptocurrency,
              destinationTag: this.destinationTag,
              network: (this.cryptocurrency === 'USDT' || this.cryptocurrency === 'USDC') ? this.network : '',
            }),
            OTP: this.OTP,
          })
        }
      })
    },
    generateOTP() {
      service
        .post('/finance/generate-otp', {
          userID: this.$store.state.user.info.ID,
          additionalText: this.$t('lydiax_withdrawal_additional_note'),
        })
        .then(response => {
          if (response.data.success) {
            this.$toasted.success(this.$t('otp_new_password'), { duration: 10000 })
          } else {
            this.$toasted.error(this.$t('otp_error'), { duration: 10000 })
          }
        })
        .catch(error => {
          this.$toasted.error( this.$t('otp_error') + ' - ' + error, { duration: 10000 })
        })
      this.otpBtnEnabled = false
      setTimeout(
        function () {
          this.otpBtnEnabled = true
        }.bind(this),
        15000
      )
    },
    ...mapActions(['logoLoader/set']),
  },
}
</script>
