<template>
  <div class="flex flex-wrap gap-4 mt-6 mb-12">
    <div class="bg-white w-full m-auto shadow-ts1 rounded-lg">
      <div class="border-b border-gray-300 w-full px-4 md:px-8 pt-5 p-4">
        <h2 class="font-display text-xl font-bold text-blue leading-none">{{$t('internal_transfer').tTitle()}}</h2>
      </div>
      <div class="px-4 md:px-8 py-6">
        <!-- From -->
        <div class="font-semibold mb-2 text-gray-900">{{ $t('transfer_from').tTitle() }}</div>
        <div class="w-10/12 max-w-[320px] px-0 flex bg-gray-400 rounded-3xl items-center mb-4">
          <div
            @click="selected.fromWallet = 'yes'"
            :class="[ selected.fromWallet == 'yes'?'text-white bg-blue font-semibold': 'text-gray-900' ]"
            class="w-1/2 text-center text-base rounded-3xl h-full py-2 pt-2.5 cursor-pointer"
          >{{ $t('wallet').tTitle() }}</div>
          <div
            @click="selected.fromWallet = 'no'"
            :class="[ selected.fromWallet !='yes'?'text-white bg-blue font-semibold': 'text-gray-900' ]"
            class="w-1/2 text-center text-base rounded-3xl h-full py-2 pt-2.5 cursor-pointer"
          >{{ $t('trading_account').tTitle() }}</div>
        </div>
        <div
          class="flex justify-start pb-3.5 overflow-x-auto gap-4"
          id="fromAccounts"
          v-if="selected.fromWallet != 'yes'"
        >
          <div
            v-for="item in filterAccounts()"
            :key="'from-' + item.external_id"
            :id="'from-' + item.external_id"
            class="w-80 flex-shrink-0 mb-5 rounded-lg shadow-ts2 border border-solid cursor-pointer"
            :class="{'border-blue border-2': item.external_id === selected.from.external_id, 'border-gray-300': item.external_id !== selected.from.external_id}"
            @click="selected.from.external_id = item.external_id"
          >
            <div class="p-5 relative select-none">
              <div class="flex mb-5">
              <img v-if="getItemImage(item)" class="h-[60px] w-[60px] m-auto" :src="getItemImage(item)" />
                <div class="w-full">
                  <div class="flex justify-between items-center">
                    <div class="h-5 bg-green text-white text-xs font-medium rounded-xl px-5 ltr:ml-4 rtl:mr-4 pt-0.5 inline-block">{{item.type.tTitle()}}</div>
                  </div>
                  <p class="text-gray-900 font-bold text-xl ltr:ml-4 rtl:mr-4">{{item.external_id}}</p>
                  <p class="text-gray-900 text-xs ltr:ml-4 rtl:mr-4">{{item.account_type==''?$t('archived'):item.account_type_identifier=='silver-islamic'?'Islamic':item.account_type}} <span v-if="item.platform!='transfer'">/ {{ item.platform=='mt4'?'MetaTrader 4':item.platform=='mt5'?'MetaTrader 5':item.platform=='pamm'?'PAMM':'-'}}</span></p>
                </div>
              </div>
              <div class="w-full rounded-lg bg-blue bg-opacity-15 p-4">
                <p class="text-gray-900 text-sm leading-6 font-medium">{{ $t('account_balance').tTitle() }}</p>
                <p class="pt-1 text-blue font-bold text-4xl">{{$formatMoney(item.balance, currencySymbols[item.currency])}}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="w-80 flex-shrink-0 mx-2.5 ltr:lg:ml-0 rtl:lg:mr-0 ltr:lg:mr-5 rtl:lg:ml-5 mb-5 rounded-lg shadow-ts2 border-2 border-blue border-solid">
            <div class="p-5 relative">
              <div class="flex mb-5 items-center">
                <div class="h-[70px] max-w-[70px] px-6 rounded-full flex justify-center items-center bg-blue text-white text-4xl font-bold">
                  W
                </div>
                <div class="w-full">
                  <p class="text-gray-900 font-bold text-xl ltr:ml-4 rtl:mr-4">{{$t('wallet').tTitle()}}</p>
                </div>
              </div>
              <div class="w-full rounded-lg bg-blue bg-opacity-15 p-4">
                <p class="text-gray-900 text-sm leading-6 font-medium">{{ $t('balance').tTitle() }}</p>
                <p class="pt-1 text-blue font-bold text-4xl">{{$formatMoney(Math.floor(User.wallet.balance * 100) / 100, currencySymbols[User.wallet.currency])}}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- To -->
        <div class="font-semibold mb-2 mt-12 text-gray-900">{{ $t('transfer_to').tTitle() }}</div>
        <div class="w-10/12 max-w-[320px] px-0 flex bg-gray-400 rounded-3xl items-center mb-4">
          <div
            @click="selected.toWallet = 'yes'"
            :class="[ selected.toWallet=='yes'?'text-white bg-blue font-semibold': 'text-gray-900' ]"
            class="w-1/2 text-center text-base rounded-3xl h-full py-2 pt-2.5 cursor-pointer"
          >{{ $t('wallet').tTitle() }}</div>
          <div
            @click="selected.toWallet = 'no'"
            :class="[ selected.toWallet != 'yes'?'text-white bg-blue font-semibold': 'text-gray-900' ]"
            class="w-1/2 text-center text-base rounded-3xl h-full py-2 pt-2.5 cursor-pointer"
          >{{ $t('trading_account').tTitle() }}</div>
        </div>

        <div
          class="flex justify-start pb-3.5 overflow-x-auto gap-4"
          id="toAccounts"
          v-if="selected.toWallet != 'yes'"
        >
          <div
            v-for="item in filterAccounts()"
            :key="'to-' + item.external_id"
            :id="'to-' + item.external_id"
            class="w-80 flex-shrink-0 mb-5 rounded-lg shadow-ts2 border border-solid cursor-pointer"
            :class="{'border-blue border-2': item.external_id === selected.to.external_id, 'border-gray-300': item.external_id !== selected.to.external_id}"
            @click="selected.to.external_id = item.external_id"
          >
            <div class="p-5 relative select-none">
              <div class="flex mb-5 ">
                <img v-if="getItemImage(item)" class="h-[60px] w-[60px] m-auto" :src="getItemImage(item)" />
                <div class="w-full">
                  <div class="flex justify-between items-center">
                    <div class="h-5 bg-green text-white text-xs font-medium rounded-xl px-5 ltr:ml-4 rtl:mr-4 pt-0.5 inline-block">{{item.type.tTitle()}}</div>
                  </div>
                  <p class="text-gray-900 font-bold text-xl ltr:ml-4 rtl:mr-4">{{item.external_id}}</p>
                  <p class="text-gray-900 text-xs ltr:ml-4 rtl:mr-4">{{item.account_type==''?$t('archived'):item.account_type_identifier=='silver-islamic'?'Islamic':item.account_type}} <span v-if="item.platform!='transfer'">/ {{ item.platform=='mt4'?'MetaTrader 4':item.platform=='mt5'?'MetaTrader 5':item.platform=='pamm'?'PAMM':'-'}}</span></p>
                </div>
              </div>
              <div class="w-full rounded-lg bg-blue bg-opacity-15 p-4">
                <p class="text-gray-900 text-sm leading-6 font-medium">{{ $t('account_balance').tTitle() }}</p>
                <p class="pt-1 text-blue font-bold text-4xl">{{$formatMoney(item.balance, currencySymbols[item.currency])}}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="w-80 flex-shrink-0 mx-2.5 ltr:lg:ml-0 rtl:lg:mr-0 ltr:lg:mr-5 rtl:lg:ml-5 mb-5 rounded-lg shadow-ts2 border-2 border-blue border-solid">
            <div class="p-5 relative">
              <div class="flex mb-5 items-center">
                <div class="h-[70px] max-w-[70px] px-6 rounded-full flex justify-center items-center bg-blue text-white text-4xl font-bold">
                  W
                </div>
                <div class="w-full">
                  <p class="text-gray-900 font-bold text-xl ltr:ml-4 rtl:mr-4">{{$t('wallet').tTitle()}}</p>
                </div>
              </div>
              <div class="w-full rounded-lg bg-blue bg-opacity-15 p-4">
                <p class="text-gray-900 text-sm leading-6 font-medium">{{ $t('balance').tTitle() }}</p>
                <p class="pt-1 text-blue font-bold text-4xl">{{$formatMoney(Math.floor(User.wallet.balance * 100) / 100, currencySymbols[User.wallet.currency])}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-y-4 lg:flex-row rounded-lg bg-blue p-8 items-center gap-x-4">
        <div class="text-white w-full lg:w-auto lg:max-w-[30%]">{{ $t('please_enter_amount_transfer').tTitle() }}</div>
        <div class="w-full lg:flex-1 mb-8 lg:mb-0">
          <input
            type="number"
            v-model="amount"
            :placeholder="$formatMoney(0, currencySymbols[User.wallet.currency])"
            min="1"
          />
        </div>
        <div class="w-full lg:w-auto relative flex flex-col lg:flex-row gap-y-2">
          <button
            class="btn btn-green w-full lg:w-64"
            @click="requestInternalTransfer()"
            :disabled="!((selected.fromWallet == 'yes' && selected.to.external_id != '') || (selected.from.external_id != '' && selected.toWallet == 'yes') || (selected.from.external_id != '' && selected.to.external_id != ''))"
          >{{ $t('transfer').tTitle() }}</button>
          <div
            class="absolute text-gold text-xs -bottom-6 ltr:lg:right-1 rtl:lg:left-1 whitespace-nowrap ltr:text-right rtl:text-left"
            v-if="needsCurrencyConversion"
          >{{ $t('warning_currency_conversion_will_take_place').tTitle() }}</div>
        </div>
      </div>
    </div>

    <error-modal></error-modal>
  </div>
</template>

<script>
import { service } from '../../main.js'
import { mapActions, mapGetters } from 'vuex'
import api from '../../api'
import config from '../../config'
import ErrorModal from '../../components/ErrorModal'

export default {
  components: {
    ErrorModal,
  },
  data: function () {
    return {
      selected: {
        fromWallet: 'yes',
        from: {
          external_id: '',
          currency: false,
        },
        toWallet: 'no',
        to: {
          external_id: '',
          currency: false,
        },
      },
      amount: '',
      currencySymbols: config.currencies
    }
  },
  computed: {
    needsCurrencyConversion() {
      let f = this.selected.from.currency
      let t = this.selected.to.currency

      // console.log(f, t)

      let currencies = ['USD', 'EUR']

      if (currencies.indexOf(f) === -1 || currencies.indexOf(t) === -1) {
        return false
      } else {
        return f !== t
      }
    },
    ...mapGetters({
      User: 'user/all',
      Finance: 'finance/all',
      Language: 'language/code',
      ErrorModalState:'errorModal/active'
    }),
  },
  watch: {
    'selected.fromWallet'() {
      if (this.selected.fromWallet === 'yes') {
        this.selected.from.external_id = ''
        this.selected.toWallet = 'no'
        this.selected.from.currency = this.User.wallet.currency
      }
      if (this.selected.fromWallet === 'no') {
        this.selected.toWallet = 'yes'
        this.selected.to.external_id = ''
      }
      this.setDraggable()
    },
    'selected.toWallet'() {
      if (this.selected.toWallet === 'yes') {
        this.selected.to.external_id = ''
        this.selected.fromWallet = 'no'
        this.selected.to.currency = this.User.wallet.currency
      }
      if (this.selected.toWallet === 'no') {
        this.selected.fromWallet = 'yes'
        this.selected.from.external_id = ''
      }
      this.setDraggable()
    },
    'selected.from.external_id'() {
      if (this.selected.fromWallet !== 'yes') {
        this.selected.from.currency = this.getTACurrency(this.selected.from.external_id)
      }
    },
    'selected.to.external_id'() {
      if (this.selected.toWallet !== 'yes') {
        this.selected.to.currency = this.getTACurrency(this.selected.to.external_id)
        if (this.selected.fromWallet == 'yes') {
          this.selected.from.currency = this.User.wallet.currency
        }
      }
    },
    'ErrorModalState'(val){
      if (val === true) {
        this.disableScroll()
      } else {
        this.enableScroll()
      }
    }
  },
  mounted() {
    if (this.Finance.deposit.selected.paymentMethod !== null) {
      this.selected.from.external_id = this.Finance.deposit.selected.paymentMethod.external_id
      // reset
      this['finance/preSelectedDepositValue'](null)
    }

    api.GetWallet()

    if (typeof this.User.info.ID !== 'undefined') {
      this['logoLoader/set'](true)
      api.GetTradingAccounts().then(() => {
        this.filterAccounts()
        this['logoLoader/set'](false)
      })
    }

    this.setDraggable()
  },
  updated() {
    this.setDraggable()
  },
  methods: {
    getFromAmount(ta) {
      if (ta.equity > 0 && parseFloat(ta.balance) > parseFloat(ta.equity)) {
        return ta.equity
      }
      return ta.balance
    },
    disableSection2() {
      if (this.selected.fromWallet === 'yes' || (this.selected.fromWallet === 'no' && this.selected.from.external_id !== '')) {
        return ''
      } else {
        return 'disabled'
      }
    },
    disableSection3() {
      if (this.selected.toWallet === 'yes' || (this.selected.toWallet === 'no' && this.selected.to.external_id !== '')) {
        return ''
      } else {
        return 'disabled'
      }
    },
    disableToWallet() {
      if (this.selected.fromWallet === 'yes') {
        return 'disabled'
      } else {
        return ''
      }
    },
    getTACurrency(TAID) {
      for (let i = 0; i < this.User.TA.length; i++) {
        if (TAID === this.User.TA[i].external_id) {
          return this.User.TA[i].currency
        }
      }
      return false
    },
    setDraggable() {
      if (this.selected.toWallet == 'no') {
        this.dragContainer('#toAccounts')
      }
      if (this.selected.fromWallet == 'no') {
        this.dragContainer('#fromAccounts')
      }
    },
    requestInternalTransfer() {
      if (this.selected.fromWallet === 'no' && this.selected.toWallet === 'no') {
        this.$toasted.error(this.$t('internal_transfer_note'), { duration: 10000 })
        return
      }
      this['logoLoader/set'](true)

      service
        .post('/finance/internal-transfer', {
          userID: this.User.info.ID,
          amount: String(this.amount),
          fromWallet: this.selected.fromWallet === 'yes',
          toWallet: this.selected.toWallet === 'yes',
          fromTA: this.selected.from.external_id === '' ? -1 : parseInt(this.selected.from.external_id),
          toTA: this.selected.to.external_id === '' ? -1 : parseInt(this.selected.to.external_id),
        })
        .then(response => {
          if (response.data.success) {
            api.GetWallet()
            api.GetTradingAccounts().then(() => {
              this.filterAccounts()
              this['logoLoader/set'](false)
            })
            this.amount = ''
            this.$toasted.success(this.$t('internal_transfer_processed_successfully'), { duration: 10000 })
          } else {
            this['logoLoader/set'](false)
          }
        })
        .catch(error => {
          if (error.response.data.meta) {
            let meta = error.response.data.meta
            let tkey = config.API.translationKeyName
            if (meta[tkey]) {
              let trep = {}
              if (meta[tkey] === 'error_min_balance_required_is') {
                trep = { TAAccountType: meta['TAAccountType'], TAID: meta['TAID'], currency: meta['currency'], min: meta['min'] }
              }
              if (meta[tkey] === 'withdrawal_unsuccessful_free_margin_taid') {
                trep = { TAID: this.selected.from.external_id }
              }
              this['errorModal/set']({ active: true, text: this.$t(meta[tkey], trep).tTitle() })
            }
          }else if(error.response.data.detail){
            this['errorModal/set']({ active: true, text: error.response.data.detail })
          }
          this['logoLoader/set'](false)
        })
    },
    filterAccounts() {
      let filteredTA = []
      for (let i = 0; i < this.User.TA.length; i++) {
        let ta = this.User.TA[i]
        if (ta.type === 'live' && ta.transfer_locked === 'no' && ta.account_type_identifier !== 'pamm-master' && ta.account_type_identifier !== 'no-deposit-bonus' && ta.account_type !== 'Archived' && ta.account_type !== '') {
          filteredTA.push(this.User.TA[i])
        }
      }


      return filteredTA
    },
     disableScroll() {
      document.querySelector('body').classList.add('overflow-hidden')
    },
    enableScroll() {
      document.querySelector('body').classList.remove('overflow-hidden')
    },
     getItemImage(item) {
       if (item.type === 'demo') {
        return require('../../assets/img/demo.svg');
      }
      const accountTypeImages = {
        'silver': 'silver',
        'gold-ecn': 'gold',
        'gold-raw': 'gold',
        'gold-raw-is':'islamic',
        'silver-islamic': 'silver-islamic',
        'platinum-vip': 'platinum',
        'micro': 'micro',
        'pamm-investor': 'pamm-investor',
        'pamm-master': 'pamm-investor',
        'no-deposit-bonus': 'no-deposit-bonus',
        'transfer':'local-depositor-icon'
      };

      return accountTypeImages[item.account_type_identifier]
        ? require(`../../assets/img/${accountTypeImages[item.account_type_identifier]}.svg`)
        : null;
    },
    ...mapActions(['logoLoader/set', 'errorModal/set', 'finance/preSelectedDepositValue']),
  },
}
</script>

<style lang="css">
#fromAccounts::-webkit-scrollbar,
#toAccounts::-webkit-scrollbar {
  /* @apply h-1; */
  height: 4px;
  width: 200px;
}
/* Track */
#fromAccounts::-webkit-scrollbar-track,
#toAccounts::-webkit-scrollbar-track {
  /* @apply bg-gray-400 rounded-xl; */
  background: #e9edf4;
  border-radius: 12px;
  width: 200px;
}

/* Handle */
#fromAccounts::-webkit-scrollbar-thumb,
#toAccounts::-webkit-scrollbar-thumb {
  /* @apply bg-blue rounded-xl; */
  background: #2b63e3;
  border-radius: 12px;
  width: 200px;
}

#fromAccounts,
#toAccounts {
  scroll-behavior: smooth;
}

#fromAccounts.active,
#toAccounts.active {
  scroll-behavior: auto;
}
</style>
