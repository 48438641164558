import config from '../../config'

const initial = {
  info() {
    return {}
  },
  wallet() {
    return {}
  },
  TA() {
    return []
  },
  IB() {
    return {}
  },
  docCount() {
    return -1
  },
  accountTypes() {
    return {}
  },
  isDemo() {
    return {}
  },
  isLive() {
    return {}
  },
  isVerified() {
    return {}
  },
  isCorporate() {
    return false
  },
  isEmailVerified() {
    return {}
  }
}

const state = {
  info: initial.info(),
  wallet: initial.wallet(),
  TA: initial.TA(),
  IB: initial.IB(),
  docCount: initial.docCount(),
  accountTypes: initial.accountTypes(),
  isDemo: initial.isDemo(),
  isLive: initial.isLive(),
  isVerified: initial.isVerified(),
  isCorporate: initial.isCorporate(),
  isEmailVerified: initial.isEmailVerified()
}

const mutations = {
  /* SET_USER(state, user) {
  	state = user
  }, */
  SET_USER_INFO(state, info) {
    state.info = info
  },
  RESET_USER(state) {
    state.info = Object.assign({}, initial.info())
    state.wallet = Object.assign({}, initial.wallet())
    state.TA = Object.assign({}, initial.TA())
    state.IB = Object.assign({}, initial.IB())
    state.accountTypes = Object.assign({}, initial.accountTypes())
    state.isDemo = Object.assign({}, initial.isDemo())
    state.isLive = Object.assign({}, initial.isLive())
    state.isVerified = Object.assign({}, initial.isVerified())
    state.isEmailVerified = Object.assign({}, initial.isEmailVerified())
  },
  SET_WALLET(state, wallet) {
    state.wallet = wallet
  },
  SET_TRADING_ACCOUNTS(state, TA) {
    state.TA = TA
  },
  SET_USER_IB(state, IB) {
    state.IB = IB
  },
  SET_DOC_COUNT(state, count) {
    state.docCount = count
  },
  SET_USER_ACCOUNT_TYPES(state, accountTypes) {
    state.accountTypes = accountTypes
  },
  SET_TA_MT4_EQUITY(state, res) {
    state.TA[res.index].equity = parseFloat(res.data.equity).toFixed(2)
    state.TA[res.index].equity_format = config.currencies[state.TA[res.index].currency] + ' ' + parseFloat(res.data.equity).toFixed(2)
  },
  SET_IS_DEMO(state, isDemo) {
    state.isDemo = !!isDemo
  },
  SET_IS_LIVE(state, isLive) {
    state.isLive = !!isLive
  },
  SET_IS_VERIFIED(state, isVerified) {
    state.isVerified = !!isVerified
  },
  SET_IS_CORPORATE(state, isCorporate) {
    state.isCorporate = !!isCorporate
  },
  SET_EMAIL_VERIFIED(state, isEmailVerified) {
    state.isEmailVerified = isEmailVerified
  }
}

const actions = {
  set: ({
    commit
  }, user) => commit('SET_USER', user),
  setInfo: ({
    commit
  }, info) => commit('SET_USER_INFO', info),
  reset: ({
    commit
  }) => commit('RESET_USER'),
  setWallet: ({
    commit
  }, wallet) => commit('SET_WALLET', wallet),
  setTA: ({
    commit
  }, TA) => commit('SET_TRADING_ACCOUNTS', TA),
  setIB: ({
    commit
  }, IB) => commit('SET_USER_IB', IB),
  setDocCount: ({
    commit
  }, count) => commit('SET_DOC_COUNT', count),
  setAccountTypes: ({
    commit
  }, accountTypes) => commit('SET_USER_ACCOUNT_TYPES', accountTypes),
  setMT4Equity: ({
      commit
    }, {
      data,
      index
    }) =>
    commit('SET_TA_MT4_EQUITY', {
      data,
      index,
    }),
  setIsDemo: ({
    commit
  }, isDemo) => commit('SET_IS_DEMO', isDemo),
  setIsLive: ({
    commit
  }, isLive) => commit('SET_IS_LIVE', isLive),
  setIsVerified: ({
    commit
  }, isVerified) => commit('SET_IS_VERIFIED', isVerified),
  setIsCorporate: ({
    commit
  }, isCorporate) => commit('SET_IS_CORPORATE', isCorporate),
  setEmailVerified: ({
    commit
  }, isEmailVerified) => commit('SET_EMAIL_VERIFIED', isEmailVerified),
}

const getters = {
  all: state => state,
  info: state => state.info,
  wallet: state => state.wallet,
  TA: state => state.TA,
  IB: state => state.IB,
  docCount: state => state.docCount,
  accountTypes: state => state.accountTypes,
  isDemo: state => state.isDemo,
  isLive: state => state.isLive,
  isVerified: state => state.isVerified,
  isCorporate: state => state.isCorporate,
  isEmailVerified: state => state.isEmailVerified
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
