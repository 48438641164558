<template>
  <div class="flex flex-wrap gap-x-4 mb-36">
    <div class="bg-white w-full m-auto mx-2 md:mx-0 lg:w-3/5 shadow-ts1 rounded-lg">
      <div class="border-b border-gray-300 w-full px-4 md:px-8 pt-5 pb-4">
        <h2 class="font-display text-xl font-bold text-blue leading-none">{{$t('profile').tTitle()}}</h2>
      </div>
      <div class="border-b border-gray-300 mx-4 md:mx-8 py-8 ">
        <div class="md:flex items-center">
          <div class="flex h-[180px] w-[180px] m-auto mb-8 md:mb-0 md:m-0 text-6xl font-display font-bold justify-center items-center text-white bg-blue rounded-full shrink-0">
            <div>{{initials}}</div>
          </div>
          <div class="ltr:ml-7 rtl:mr-7">
            <div class="text-2xl md:text-3xl font-bold text-center ltr:md:text-left rtl:md:text-right text-gray-900 font-display">{{displayName}}</div>
            <div
              class="mt-1.5 text-center ltr:md:text-left rtl:md:text-right text-base font-semibold"
              :class="{'text-green' : User.info.client.account_state == 'full',
                'text-orange-light': User.info.client.account_state == 'pending',
                'text-red' : User.info.client.account_state == 'rejected' || User.info.client.account_state == 'incomplete'}"
            > {{ $t('client_state').tTitle() }}:
              <span v-if="User.info.client.account_state == 'full'">{{ $t('client_state_approved').tTitle() }}</span>
              <span v-else-if="User.info.client.account_state == 'pending'">{{ $t('client_state_pending').tTitle() }}</span>
              <span v-if="User.info.client.account_state == 'incomplete'">{{ $t('client_state_incomplete').tTitle() }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="User.info.client && User.info.client.company_name==''" class="p-4 md:p-8 pb-4">
        <profile-field
          :title="$t('first_name').tTitle()"
          :value="User.info.firstname"
          disabled
        />
        <profile-field
          :title="$t('last_name').tTitle()"
          :value="User.info.lastname"
          disabled
        />
      </div>
      <div v-else class="p-4 md:p-8 pb-4">
        <profile-field
          :title="$t('company_name').tTitle()"
          :value="User.info.client ? User.info.client.company_name : ''"
          disabled
        />
      </div>
      <div class="border-b border-gray-300 mx-4 md:mx-8 pb-6">
        <h2 class="font-display text-xl font-bold text-blue">{{$t('personal_details').tTitle()}}</h2>
      </div>
      <div class="p-4 md:p-8 pb-4">
        <profile-field
          :title="$t('email').tTitle()"
          :value="User.info.email"
          disabled
        />
        <profile-field
          :title="$t('username').tTitle()"
          :value="User.info.username"
          disabled
        />
        <profile-field
          v-if="User.info.client && User.info.client.company_name==''"
          :title="$t('company_name').tTitle()"
          :value="User.info.client ? User.info.client.company_name : ''"
          disabled
        />
        <profile-field
          :title="$t('website').tTitle()"
          :value="User.info.client ? User.info.client.website : ''"
          disabled
        />
        <profile-field
          v-if="User.info.client && User.info.client.company_name==''"
          :title="$t('date_of_birth').tTitle()"
          :value="$formatDate(User.info.date_of_birth)"
          disabled
        />
        <profile-field
          v-else
          :title="$t('date_of_registration').tTitle()"
          :value="$formatDate(User.info.date_of_birth)"
          disabled
        />
        <profile-field
          v-if="User.info.client && User.info.client.company_name==''"
          :title="$t('gender').tTitle()"
          :value="User.info.gender ? User.info.gender == '' ? $t('no_gender').tTitle() : User.info.gender.tTitle() : $t('no_gender').tTitle()"
          disabled
        />
        <profile-field
          :title="$t('phone').tTitle()"
          :value="User.info.phone"
          disabled
        />
        <profile-field
          :title="$t('country').tTitle()"
          :value="countries[User.info.country_code].name"
          disabled
        />
        <profile-field
          :title="$t('address').tTitle()"
          :value="User.info.address"
          disabled
        />
        <profile-field
          :title="$t('year_moved_to_address').tTitle()"
          :value="User.info.year_moved_to_address"
          disabled
        />
        <profile-field
          :title="$t('post_code').tTitle()"
          :value="User.info.post_code"
          disabled
        />
        <profile-field
          :title="$t('selected_currency').tTitle()"
          :value="User.wallet.currency ? User.wallet.currency.toUpperCase() : ''"
          disabled
        />
      </div>
    </div>
    <div class="w-full md:flex-1 self-start m-auto md:m-0 mb-20 lg:mb-0 mx-2 md:mx-0 ">
      <div class="bg-white m-auto mt-8 lg:mt-0 shadow-ts1 rounded-lg ">
        <change-password />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChangePassword from './ChangePassword.vue'

import ProfileField from '../../components/ProfileField.vue'
import config from '../../config'

export default {
  components: {
    ChangePassword,
    ProfileField,
  },
  data() {
    return {
      options: {
        newsletter: false,
        vps: false,
      },
      countries: config.countries
    }
  },
  computed: {
    initials() {
      let initials = ''
      let firstName = this.User.info.firstname
      let lastName = this.User.info.lastname
      if (firstName && lastName) {
        initials = firstName.charAt(0) + lastName.charAt(0)
        return initials.toUpperCase()
      } else if (this.User.info.client && this.User.info.client.company_name!='') {
        initials = this.User.info.client.company_name.charAt(0)
        return initials.toUpperCase()
      } else {
        return ''
      }
    },
    displayName(){
       if(this.User.info.firstname!='')
      {
        return this.User.info.firstname + ' ' + this.User.info.lastname
      }
      else if(this.User.info.client && this.User.info.client.company_name!=''){
        return this.User.info.client.company_name
      }
      else {
        return ''
      }
    },
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
    }),
  },
  mounted() {},
  methods: {},
}
</script>
