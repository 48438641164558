<template>
  <div>
    <div
      @click="close()"
      class="fixed inset-0 z-50 bg-gray-900 bg-opacity-70 w-screen h-screen"
    ></div>

    <div
      id="VPScontainer"
      class="transition-all duration-300 translate-x-full ease-in-out fixed flex flex-col justify-start items-center lg:items-end ltr:right-0 rtl:left-0 top-0 z-50 overflow-x-hidden overflow-y-scroll h-full w-full lg:w-10/12 max-w-[1200px]"
    >
      <div class="w-11/12 lg:w-full">
        <button
          @click="close()"
          class="bg-white rounded-full h-10 w-10 lg:self-start appearance-none flex-shrink-0 mt-2 p-0"
        >
          <div class="h-6 w-0.5 bg-blue rotate-45 ltr:ml-5 rtl:mr-5"></div>
          <div class="h-6 w-0.5 bg-blue -rotate-45 -mt-6 ltr:ml-5 rtl:mr-5"></div>
        </button>
      </div>
      <div class="bg-white ltr:rounded-br-xl ltr:lg:rounded-br-none ltr:rounded-tr-xl ltr:lg:rounded-tr-none ltr:rounded-tl-xl ltr:rounded-bl-xl rtl:rounded-bl-xl rtl:lg:rounded-bl-none rtl:rounded-tl-xl rtl:lg:rounded-tl-none rtl:rounded-tr-xl rtl:rounded-br-xl  py-10 md:py-12 px-5 md:px-10 lg:px-24 w-11/12 lg:w-full relative mt-4 mb-20 md:mb-0">

        <div class="text-gray-900 font-display font-bold text-2xl md:text-3xl mb-6 md:mb-12">{{$t('vps_packages')}}</div>

        <!-- Step 1 -->
        <div v-if="showStep1">
          <div class="flex justify-between mb-0 md:mb-7 items-start md:items-center flex-col md:flex-row">
            <p class="text-gray-900 text-base font-semibold mb-6 md:mb-0">{{$t('choose_vps_package')}}</p>
            <select
              v-model="selectedCurrency"
              name="currency"
              class="pl-4 py-3 w-28 bg-white text-blue border border-gray-300 border-solid rounded-md text-base font-bold appearance-none focus:outline-none focus:ring-0 focus:border focus:border-gray-300 focus:border-solid rtl:bg-left"
            >
              <option>USD</option>
              <option>EUR</option>
            </select>
          </div>

          <div
            id="packages"
            class="w-auto md:w-full flex gap-2.5 md:gap-5 items-center justify-start md:justify-center overflow-x-scroll md:overflow-x-visible py-5 md:py-0"
          >

            <div
              @click="gotoStep2('standard')"
              class="w-[300px] md:w-1/3 border border-solid border-gray-300 shadow-ts2 rounded-lg shrink-0 md:shrink cursor-pointer"
            >
              <p class="pt-8 font-bold text-3xl leading-9 text-gray-900 text-center">{{$t('silver')}}</p>
              <p class="text-green text-center text-5xl leading-9 font-bold mt-5">{{standardPricing[selectedCurrency]}}</p>
              <p class="text-green text-xl font-bold text-center mt-2 mb-4">{{$t('per_month')}}</p>
              <p
                v-html="standardPackageText"
                class="text-gray-900 text-base text-center p-1 pb-0 mb-7"
              ></p>
              <p class="py-3.5 border-t border-solid border-gray-300 text-gray-900 text-base text-center">1GB RAM</p>
              <p class="py-3.5 border-t border-solid border-gray-300 text-gray-900 text-base text-center">1 CPU</p>
              <p class="py-3.5 border-t border-solid border-gray-300 text-gray-900 text-base text-center">40GB {{$t('hard_disk')}}</p>
              <p class="py-3.5 border-y border-solid border-gray-300 text-gray-900 text-base text-center mb-9">Windows OS {{$t('included')}}</p>
              <button class="appearance-none table py-3 text-base rounded-md text-white bg-green w-48 text-center font-bold m-auto mb-2">{{$t('order_now')}}</button>
              <a class="text-gray-900 text-center text-[10px] table m-auto mb-5">{{$t('terms_and_conditions_apply')}}</a>
            </div>

            <div
              @click="gotoStep2('gold')"
              class="w-[300px] md:w-1/3 border border-solid border-green bg-green shadow-ts2 rounded-lg relative shrink-0 md:shrink cursor-pointer"
            >
              <div class="w-full absolute -top-5 ltr:left-0 rtl:right-0">
                <div class="text-white bg-blue text-base font-semibold py-1.5 w-40 text-center m-auto rounded-md">{{$t('most_popular')}}</div>
              </div>

              <p class="pt-8 font-bold text-3xl leading-9 text-white text-center">{{$t('gold')}}</p>
              <p class="text-white text-center text-5xl leading-9 font-bold mt-5">{{goldPricing[selectedCurrency]}}</p>
              <p class="text-white text-xl font-bold text-center mt-2 mb-4">{{$t('per_month')}}</p>
              <p
                v-html="goldPackageText"
                class="text-white text-base text-center p-1 pb-0 mb-7"
              ></p>
              <p class="py-3.5 border-t border-solid border-gray-300 text-white text-base text-center">2GB RAM</p>
              <p class="py-3.5 border-t border-solid border-gray-300 text-white text-base text-center">2 CPU</p>
              <p class="py-3.5 border-t border-solid border-gray-300 text-white text-base text-center">50GB {{$t('hard_disk')}}</p>
              <p class="py-3.5 border-y border-solid border-gray-300 text-white text-base text-center mb-9">Windows OS {{$t('included')}}</p>
              <button class="appearance-none table py-3 text-base rounded-md text-green bg-white w-48 text-center font-bold m-auto mb-2">{{$t('order_now')}}</button>
              <a class="text-white text-center text-[10px] table m-auto mb-5">{{$t('terms_and_conditions_apply')}}</a>
            </div>

            <div
              @click="gotoStep2('advance')"
              class="w-[300px] md:w-1/3 border border-solid border-gray-300 shadow-ts2 rounded-lg shrink-0 md:shrink cursor-pointer"
            >
              <p class="pt-8 font-bold text-3xl leading-9 text-gray-900 text-center">{{$t('platinum')}}</p>
              <p class="text-green text-center text-5xl leading-9 font-bold mt-5">{{advancePricing[selectedCurrency]}}</p>
              <p class="text-green text-xl font-bold text-center mt-2 mb-4">{{$t('per_month')}}</p>
              <p
                v-html="advancePackageText"
                class="text-gray-900 text-base text-center p-1 pb-0 mb-7"
              ></p>
              <p class="py-3.5 border-t border-solid border-gray-300 text-gray-900 text-base text-center">4GB RAM</p>
              <p class="py-3.5 border-t border-solid border-gray-300 text-gray-900 text-base text-center">4 CPU</p>
              <p class="py-3.5 border-t border-solid border-gray-300 text-gray-900 text-base text-center">100GB {{$t('hard_disk')}}</p>
              <p class="py-3.5 border-y border-solid border-gray-300 text-gray-900 text-base text-center mb-9">Windows OS {{$t('included')}}</p>
              <button class="appearance-none table py-3 text-base rounded-md text-white bg-green w-48 text-center font-bold m-auto mb-2">{{$t('order_now')}}</button>
              <a class="text-gray-900 text-center text-[10px] table m-auto mb-5">{{$t('terms_and_conditions_apply')}}</a>
            </div>

          </div>

        </div>

        <!-- Step 2 -->
        <div v-if="!showStep1">
          <div
            @click="showStep1=!showStep1;initializeValues();"
            class="h-6 w-20 bg-blue bg-opacity-15 rounded-xl flex items-center justify-center gap-1.5 m-auto cursor-pointer absolute top-10 md:top-14 ltr:right-5 ltr:md:right-10 ltr:lg:right-24 rtl:left-5 rtl:md:left-10 rtl:lg:left-24"
          >
            <img
              src="../assets/img/back-arrow-blue.svg"
              class="w-3"
            />
            <span class="text-blue text-base">{{$t('back').tCapitalizeFirstLetter()}}</span>
          </div>

          <div class="mb-11">
            <p class="text-gray-900 text-base font-semibold mb-3">{{$t('choose_trading_account')}}</p>
            <p class="text-gray-900 text-base leading-7 max-w-2xl">{{$t('vps_description')}}</p>
          </div>

          <div class="relative mb-16 md:mb-28">
            <div
              v-show="showArrows"
              class="hidden md:block"
            >
              <div
                @click="scrollLeft()"
                class="cursor-pointer absolute ltr:-left-6 rtl:-right-6 top-[8.5rem] z-10"
              >
                <img
                  class="h-12 w-12 rounded-full shadow-ts3"
                  src="../assets/img/previous-arrow-2.svg"
                />
              </div>

              <div
                @click="scrollRight()"
                class="cursor-pointer absolute ltr:-right-6 rtl:-left-6 top-[8.5rem] z-10"
              >
                <img
                  class="h-12 w-12 rounded-full shadow-ts3"
                  src="../assets/img/next-arrow-2.svg"
                />
              </div>
            </div>
            <div
              class="w-full overflow-x-scroll"
              id="VpsTAContainer"
            >
              <div class="flex lg:pr-8 pb-3.5 md:pb-0 space-x-5 rtl:space-x-reverse">
                <div
                  v-for="item in availableTradingAccounts"
                  :key="item.external_id"
                  :id="item.external_id"
                  @click="selectedTA=item"
                  :class="[selectedTA==item?'border-blue':'border-gray-300']"
                  class="w-80 flex-shrink-0 rounded-lg shadow-ts2 border border-solid"
                >
                  <div class="p-4 relative noUserSelect">
                    <div class="flex mb-4">
                      <img
                        v-if="item.type=='demo'"
                        class="h-[70px] w-[70px]"
                        src="../assets/img/demo.svg"
                      />
                      <img
                        v-else-if="item.account_type_identifier=='silver'"
                        class="h-[70px] w-[70px]"
                        src="../assets/img/silver.svg"
                      />
                      <img
                        v-else-if="item.account_type_identifier=='gold-ecn' || item.account_type_identifier=='gold-raw'"
                        class="h-[70px] w-[70px]"
                        src="../assets/img/gold.svg"
                      />
                      <img
                        v-else-if="item.account_type_identifier=='silver-islamic'"
                        class="h-[70px] w-[70px]"
                        src="../assets/img/silver-islamic.svg"
                      />
                       <img
                        v-else-if="item.account_type_identifier=='gold-raw-is'"
                        class="h-[70px] w-[70px]"
                        src="../assets/img/islamic.svg"
                      />
                      <img
                        v-else-if="item.account_type_identifier=='platinum-vip'"
                        class="h-[70px] w-[70px]"
                        src="../assets/img/platinum.svg"
                      />
                      <img
                        v-else-if="item.account_type_identifier=='micro'"
                        class="h-[70px] w-[70px]"
                        src="../assets/img/micro.svg"
                      />
                      <img
                        v-else-if="item.account_type_identifier=='pamm-investor'"
                        class="h-[70px] w-[70px]"
                        src="../assets/img/pamm-investor.svg"
                      />
                      <img
                        v-else-if="item.account_type_identifier=='pamm-master'"
                        class="h-[70px] w-[70px]"
                        src="../assets/img/pamm-investor.svg"
                      />
                      <div class="w-full">
                        <div class="flex justify-between items-center">
                          <div
                            :class="[item.type=='live'?'bg-green':'bg-gray-900']"
                            class="h-5 text-white text-xs font-semibold rounded-xl px-5 ltr:ml-4 rtl:mr-4 pt-0.5 inline-block">{{item.type.tTitle()}}</div>
                        </div>
                        <p class="text-gray-dark font-bold text-xl ltr:ml-4 rtl:mr-4">{{item.external_id}}</p>
                        <p class="text-gray-dark text-xs ltr:ml-4 rtl:mr-4 font-medium">{{item.account_type==''?$t('archived'):item.account_type_identifier=='silver-islamic'?'Islamic':item.account_type_identifier=='pamm-master'?'Master':item.account_type_identifier=='pamm-investor'?'Investor':item.account_type}} / {{ item.platform=='mt4'?'MetaTrader 4':item.platform=='mt5'?'MetaTrader 5':'PAMM'}}</p>
                      </div>
                    </div>
                    <div class="w-full rounded-lg bg-blue bg-opacity-15 px-4 py-1.5 mb-3.5">
                      <p class="text-gray-dark text-sm leading-6 font-medium">{{ $t('account_balance').tTitle() }}</p>
                      <p class="pt-1 text-blue font-bold text-4xl">{{$formatMoney(item.balance)}}</p>
                    </div>
                    <div class="w-full flex space-x-3.5 rtl:space-x-reverse mb-3.5">
                      <div class="w-1/2 rounded-lg bg-blue bg-opacity-15">
                        <p class="text-gray-dark text-sm leading-4 font-medium pt-1 pl-4">{{ $t('equity').tTitle() }}</p>
                        <p class="text-blue font-semibold text-base pl-4 pb-1 leading-6">{{$formatMoney(item.equity)}}</p>
                      </div>
                      <div class="w-1/2 rounded-lg bg-blue bg-opacity-15">
                        <p class="text-gray-dark text-sm leading-4 font-medium pt-1 pl-4">{{ $t('leverage').tTitle() }}</p>
                        <p class="text-blue font-semibold text-base pl-4 pb-1 leading-6">1:{{item.leverage}}</p>
                      </div>
                    </div>
                    <div :class="[item.accept_bonus=='no'?'bg-[#e0e1e4]':'bg-[#def4ec]']" class="w-full rounded-lg flex gap-1 px-4 py-2 mt-3.5">
                      <p class="text-gray-900 text-sm font-semibold">{{ $t('bonus_120') }}:</p>
                      <p class="text-gray-900 font-medium text-sm">{{item.accept_bonus=='no'?$t('no_bonus'):getTABonus(item.external_id)}}</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <div class="flex justify-end items-start md:items-center gap-5 flex-col md:flex-row">
            <div class="w-full md:w-auto flex gap-5 md:gap-6 flex-col md:flex-row">
              <div class="form-check relative flex items-center">
                <input
                  class="checkbox"
                  :class="{'appearance-none': !agreeTerms}"
                  type="checkbox"
                  v-model="agreeTerms"
                  id="agreeTerms"
                >
                <label
                  class="checkbox-label"
                  for="agreeTerms"
                  v-html="$t('agree_to_terms',{URL:TCsLink})"
                >
                </label>
              </div>
              <button
                @click="addVPSPackage()"
                :disabled="selectedTA === null || !agreeTerms || submitting"
                class="w-40 py-3 text-white rounded-md text-base font-bold text-center bg-green disabled:bg-gray-300 flex items-center justify-center"
              >
                <svg
                  class="animate-spin ltr:-ml-1 rtl:-mr-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  v-if="submitting"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                {{$t('submit').tTitle()}}
              </button>
            </div>
          </div>

          <div
            v-show="showErrorSubmitMessage"
            class="text-sm text-red pb-3 md:pt-0 text-center w-full"
          >{{$t('something_went_wrong')}}</div>

        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { service } from '@/main.js'
import links from '@/config/links'


export default {
  props: {
    availableTradingAccounts: Array,
  },
  data() {
    return {
      submitting: false,
      selectedCurrency: 'USD',
      selectedPackage: '',
      showStep1: true,
      showErrorSubmitMessage: false,
      selectedTA: null,
      tradingAccounts: [],
      showArrows: false,
      agreeTerms: false,
      standardPricing: {
        USD: '$50',
        EUR: '€40',
        //GBP: '£35',
      },
      goldPricing: {
        USD: '$60',
        EUR: '€50',
        //GBP: '£45',
      },
      advancePricing: {
        USD: '$110',
        EUR: '€100',
        //GBP: '£90',
      },
    }
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
      Bonuses:'bonusconfig/bonusData'
    }),
    standardPackageText() {
      return this.$t('standard_text', { standard_min_deposit: '$2000' }) + (this.selectedCurrency != 'USD' ? ' ' + this.$t('equivalent') : '')
    },
    goldPackageText() {
      return this.$t('gold_text', { gold_min_deposit: '$5000' }) + (this.selectedCurrency != 'USD' ? ' ' + this.$t('equivalent') : '')
    },
    advancePackageText() {
      return this.$t('advance_text', { advance_min_deposit: '$25000' }) + (this.selectedCurrency != 'USD' ? ' ' + this.$t('equivalent') : '')
    },
    TCsLink() {
    return links.getLink('vpsTermsAndConditions', this.Language);
    },
  },
  watch: {},
  mounted() {
    this.dragContainer('#packages')

  },
  methods: {
    gotoStep2(type) {
      this.selectedPackage = type
      this.showStep1 = !this.showStep1

      if (this.availableTradingAccounts.length) {
        this.$nextTick(() => {
          this.dragContainer('#VpsTAContainer')
          this.showArrows = true
        })
      }
    },
    initializeValues() {
      this.selectedPackage = ''
      this.selectedTA = null
      this.agreeTerms = false
      this.showStep1 = true
      this.showErrorSubmitMessage = false
    },
    close() {
      document.querySelector('body').classList.remove('overflow-hidden')
      this.initializeValues()
      this.$emit('close')
    },
    scrollLeft() {
      document.getElementById('VpsTAContainer').scrollLeft -= 320
    },
    scrollRight() {
      document.getElementById('VpsTAContainer').scrollLeft += 320
    },
    addVPSPackage() {
      this.submitting = true
      service
        .post(
          'vps/request',
          {
            userID: this.User.info.ID,
            TAID: this.selectedTA.external_id,
            platform: this.selectedTA.platform,
            productName: this.selectedPackage,
          },
          'requestNewVPS'
        )
        .then(response => {
          if (response.data.success) {
            this.$toasted.success(this.$t('success_vps_order'), { duration: 10000 })
            this.close()
            this.complete()
          }
          this.submitting = false
        })
        .catch(error => {
          this.$toasted.error(error.response.data.detail, { duration: 10000 })
          this.submitting = false
        })
    },
    complete() {
      this.$emit('complete')
    },
    getTABonus(ta) {
      for (let i = 0; i < this.Bonuses.length; i++) {
        if (this.Bonuses[i].trading_account === ta) {
          return this.Bonuses[i].bonusAmount
        }
      }
      return 0
    },
  },
}
</script>
<style>
#VPScontainer::-webkit-scrollbar {
  width: 4px;
}
/* Track */
#VPScontainer::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 12px;
}

#VPScontainer::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 12px;
}

/* Handle */
#VPScontainer::-webkit-scrollbar-thumb {
  background: #2b63e3;
  border-radius: 12px;
}

#VpsTAContainer::-webkit-scrollbar {
  display: none;
}

#packages::-webkit-scrollbar {
  /* @apply h-1; */
  height: 4px;
}
/* Track */
#packages::-webkit-scrollbar-track {
  /* @apply bg-gray-400 rounded-xl; */
  background: #e9edf4;
  border-radius: 12px;
}
/* Handle */
#packages::-webkit-scrollbar-thumb {
  /* @apply bg-blue rounded-xl; */
  background: #2b63e3;
  border-radius: 12px;
}

#packages,
#VpsTAContainer {
  scroll-behavior: smooth;
}

#packages.active,
#VpsTAContainer.active {
  scroll-behavior: auto;
}

@media only screen and (max-width: 768px) {
  #VpsTAContainer::-webkit-scrollbar {
    /* @apply h-1; */
    height: 4px;
    display: block;
  }
  /* Track */
  #VpsTAContainer::-webkit-scrollbar-track {
    /* @apply bg-gray-400 rounded-xl; */
    background: #e9edf4;
    border-radius: 12px;
  }
  /* Handle */
  #VpsTAContainer::-webkit-scrollbar-thumb {
    /* @apply bg-blue rounded-xl; */
    background: #2b63e3;
    border-radius: 12px;
  }
  .noUserSelect {
    -webkit-user-select: none;
    -webkit-user-drag: none;
    -webkit-app-region: no-drag;
  }
}
</style>
