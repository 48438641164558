export default {
  defaultURL: '',
  URL: {
    track: 'https://plexytrade.com/?t=',
    website: 'https://plexytrade.com/',
  },
  API: {
    host: process.env.NODE_ENV === 'development' ? 'http://api.tradesmart.test/' : 'https://api.plexytrade.com/',
    translationKeyName: 'messageID',
  },
  setMaintenanceMode: false,
  recaptchaSiteKey: process.env.NODE_ENV === 'development' ? '6LfTfI0mAAAAAHcMdkbUdJF1Yf-EI1QeRMfw_SIA' : '6Lf8ooEpAAAAAEnOvmMA3w6gQJjelCKlbTFD_9Co',
  ws: {
    feed: process.env.NODE_ENV === 'development' ? 'wss://clients.tradesmart.vue/ws' : 'wss://clients.plexytrade.com/ws',
  },
  platformDownloadLinks: {
    mt4: {
      windows: 'https://download.mql5.com/cdn/web/plexy.trade.ltd/mt4/plexytrade4setup.exe',
      android: 'https://download.mql5.com/cdn/mobile/mt4/android?server=PlexyTrade-Demo,PlexyTrade-Live',
      ios: 'https://download.mql5.com/cdn/mobile/mt4/ios?server=PlexyTrade-Demo,PlexyTrade-Live',
      webtrader: 'https://plexytrade.com/platforms/metatrader-4-webtrader',
    },
    mt5: {
      windows: 'https://download.mql5.com/cdn/web/plexy.trade.ltd/mt5/plexytrade5setup.exe',
      android: 'https://download.mql5.com/cdn/mobile/mt5/android?server=PlexyTrade-Live',
      ios: 'https://download.mql5.com/cdn/mobile/mt5/ios?server=PlexyTrade-Live',
      webtrader: 'https://plexytrade.com/platforms/metatrader-5-webtrader',
    },
  },
  currencies: {
    USD: {
      symbol: '$',
      active: true,
    },
    EUR: {
      symbol: '€',
      active: true,
    },
    CNY: {
      symbol: '¥',
      active: false,
    },
    THA: {
      symbol: '฿',
      active: false,
    },
    GBP: {
      symbol: '£',
      active: false,
    },
  },
  cryptocurrencyOptions: [
    { code: 'BTC', name: 'Bitcoin', nameWithNetwork: 'Bitcoin (Bitcoin Network)' },
    { code: 'BCH', name: 'Bitcoin Cash', nameWithNetwork: 'Bitcoin Cash (Bitcoin Cash Network)' },
    { code: 'ADA', name: 'Cardano', nameWithNetwork: 'Cardano (Cardano Network)' },
    // { code: 'LINK', name: 'Chain Link' },
    { code: 'ETH', name: 'Ether', nameWithNetwork: 'Ether (Ethereum Network ERC-20)' },
    { code: 'LTC', name: 'Litecoin', nameWithNetwork: 'Litecoin (Litecoin Network)' },
    { code: 'XRP', name: 'Ripple', nameWithNetwork: 'Ripple (Ripple Network)' },
    { code: 'USDC', name: 'USDC', nameWithNetwork: 'USDC (Ethereum Network ERC-20)' },
    { code: 'USDT', name: 'USDT (Tether)', nameWithNetwork: 'USDT (Ethereum Network ERC-20 and Tron Network TRC-20)' },
  ],
  finance: {
    minDeposit: 20,
  },
  languages: {
    en: 'English',
    es: 'Spanish',
    fa:'Farsi'
  },
  titles: {
    mrs: 'Mrs',
    mr: 'Mr',
    ms: 'Ms',
    miss: 'Miss',
    dr: 'Dr',
    other: 'Other',
  },
  nationalities: [
    'Afghan',
    'Albanian',
    'Algerian',
    'American',
    'Andorran',
    'Angolan',
    'Antiguans',
    'Argentinean',
    'Armenian',
    'Australian',
    'Austrian',
    'Azerbaijani',
    'Bahamian',
    'Bahraini',
    'Bangladeshi',
    'Barbadian',
    'Barbudans',
    'Batswana',
    'Belarusian',
    'Belgian',
    'Belizean',
    'Beninese',
    'Bhutanese',
    'Bolivian',
    'Bosnian',
    'Brazilian',
    'British',
    'Bruneian',
    'Bulgarian',
    'Burkinabe',
    'Burmese',
    'Burundian',
    'Cambodian',
    'Cameroonian',
    'Canadian',
    'Cape Verdean',
    'Central African',
    'Chadian',
    'Chilean',
    'Chinese',
    'Colombian',
    'Comoran',
    'Congolese',
    'Costa Rican',
    'Croatian',
    'Cuban',
    'Cypriot',
    'Czech',
    'Danish',
    'Djibouti',
    'Dominican',
    'Dutch',
    'East Timorese',
    'Ecuadorean',
    'Egyptian',
    'Emirian',
    'Equatorial Guinean',
    'Eritrean',
    'Estonian',
    'Ethiopian',
    'Fijian',
    'Filipino',
    'Finnish',
    'French',
    'Gabonese',
    'Gambian',
    'Georgian',
    'German',
    'Ghanaian',
    'Greek',
    'Grenadian',
    'Guatemalan',
    'Guinea-Bissauan',
    'Guinean',
    'Guyanese',
    'Haitian',
    'Herzegovinian',
    'Honduran',
    'Hungarian',
    'I-Kiribati',
    'Icelander',
    'Indian',
    'Indonesian',
    'Iranian',
    'Iraqi',
    'Irish',
    'Israeli',
    'Italian',
    'Ivorian',
    'Jamaican',
    'Japanese',
    'Jordanian',
    'Kazakhstani',
    'Kenyan',
    'Kittian and Nevisian',
    'Kuwaiti',
    'Kyrgyz',
    'Laotian',
    'Latvian',
    'Lebanese',
    'Liberian',
    'Libyan',
    'Liechtensteiner',
    'Lithuanian',
    'Luxembourger',
    'Macedonian',
    'Malagasy',
    'Malawian',
    'Malaysian',
    'Maldivan',
    'Malian',
    'Maltese',
    'Marshallese',
    'Mauritanian',
    'Mauritian',
    'Mexican',
    'Micronesian',
    'Moldovan',
    'Monacan',
    'Mongolian',
    'Moroccan',
    'Mosotho',
    'Motswana',
    'Mozambican',
    'Namibian',
    'Nauruan',
    'Nepalese',
    'New Zealander',
    'Nicaraguan',
    'Nigerian',
    'Nigerien',
    'North Korean',
    'Northern Irish',
    'Norwegian',
    'Omani',
    'Pakistani',
    'Palauan',
    'Palestinian',
    'Panamanian',
    'Papua New Guinean',
    'Paraguayan',
    'Peruvian',
    'Polish',
    'Portuguese',
    'Qatari',
    'Romanian',
    'Russian',
    'Rwandan',
    'Saint Lucian',
    'Salvadoran',
    'Samoan',
    'San Marinese',
    'Sao Tomean',
    'Saudi',
    'Scottish',
    'Senegalese',
    'Serbian',
    'Seychellois',
    'Sierra Leonean',
    'Singaporean',
    'Slovakian',
    'Slovenian',
    'Solomon Islander',
    'Somali',
    'South African',
    'South Korean',
    'Spanish',
    'Sri Lankan',
    'Sudanese',
    'Surinamer',
    'Swazi',
    'Swedish',
    'Swiss',
    'Syrian',
    'Taiwanese',
    'Tajik',
    'Tanzanian',
    'Thai',
    'Togolese',
    'Tongan',
    'Trinidadian/Tobagonian',
    'Tunisian',
    'Turkish',
    'Tuvaluan',
    'Ugandan',
    'Ukrainian',
    'Uruguayan',
    'Uzbekistani',
    'Venezuelan',
    'Vietnamese',
    'Welsh',
    'Yemenite',
    'Zambian',
    'Zimbabwean',
  ],
  countries: {
    AF: {
      id: 1,
      name: 'AFGHANISTAN',
      dialCode: '93',
    },
    AX: {
      id: 2,
      name: 'ALAND ISLANDS',
      dialCode: '358',
    },
    AL: {
      id: 3,
      name: 'ALBANIA',
      dialCode: '355',
    },
    DZ: {
      id: 4,
      name: 'ALGERIA',
      dialCode: '213',
    },
    AS: {
      id: 5,
      name: 'AMERICAN SAMOA',
      dialCode: '1684',
    },
    AD: {
      id: 6,
      name: 'ANDORRA',
      dialCode: '376',
    },
    AO: {
      id: 7,
      name: 'ANGOLA',
      dialCode: '244',
    },
    AI: {
      id: 8,
      name: 'ANGUILLA',
      dialCode: '1264',
    },
    AQ: {
      id: 9,
      name: 'ANTARCTICA',
      dialCode: '672',
    },
    AG: {
      id: 10,
      name: 'ANTIGUA AND BARBUDA',
      dialCode: '1268',
    },
    AR: {
      id: 11,
      name: 'ARGENTINA',
      dialCode: '54',
    },
    AM: {
      id: 12,
      name: 'ARMENIA',
      dialCode: '374',
    },
    AW: {
      id: 13,
      name: 'ARUBA',
      dialCode: '297',
    },
    AU: {
      id: 14,
      name: 'AUSTRALIA',
      dialCode: '61',
    },
    AT: {
      id: 15,
      name: 'AUSTRIA',
      dialCode: '43',
    },
    AZ: {
      id: 16,
      name: 'AZERBAIJAN',
      dialCode: '994',
    },
    BS: {
      id: 17,
      name: 'BAHAMAS',
      dialCode: '1242',
    },
    BH: {
      id: 18,
      name: 'BAHRAIN',
      dialCode: '973',
    },
    BD: {
      id: 19,
      name: 'BANGLADESH',
      dialCode: '880',
    },
    BB: {
      id: 20,
      name: 'BARBADOS',
      dialCode: '1246',
    },
    BY: {
      id: 21,
      name: 'BELARUS',
      dialCode: '375',
    },
    BE: {
      id: 22,
      name: 'BELGIUM',
      dialCode: '32',
    },
    BZ: {
      id: 23,
      name: 'BELIZE',
      dialCode: '501',
    },
    BJ: {
      id: 24,
      name: 'BENIN',
      dialCode: '229',
    },
    BM: {
      id: 25,
      name: 'BERMUDA',
      dialCode: '1441',
    },
    BT: {
      id: 26,
      name: 'BHUTAN',
      dialCode: '975',
    },
    BO: {
      id: 27,
      name: 'BOLIVIA, PLURINATIONAL STATE OF',
      dialCode: '591',
    },
    BQ: {
      id: 28,
      name: 'BONAIRE, SINT EUSTATIUS AND SABA',
      dialCode: '599',
    },
    BA: {
      id: 29,
      name: 'BOSNIA AND HERZEGOVINA',
      dialCode: '387',
    },
    BW: {
      id: 30,
      name: 'BOTSWANA',
      dialCode: '267',
    },
    BV: {
      id: 31,
      name: 'BOUVET ISLAND',
      dialCode: '47',
    },
    BR: {
      id: 32,
      name: 'BRAZIL',
      dialCode: '55',
    },
    IO: {
      id: 33,
      name: 'BRITISH INDIAN OCEAN TERRITORY',
      dialCode: '246',
    },
    BN: {
      id: 34,
      name: 'BRUNEI DARUSSALAM',
      dialCode: '673',
    },
    BG: {
      id: 35,
      name: 'BULGARIA',
      dialCode: '359',
    },
    BF: {
      id: 36,
      name: 'BURKINA FASO',
      dialCode: '226',
    },
    BI: {
      id: 37,
      name: 'BURUNDI',
      dialCode: '257',
    },
    KH: {
      id: 38,
      name: 'CAMBODIA',
      dialCode: '855',
    },
    CM: {
      id: 39,
      name: 'CAMEROON',
      dialCode: '237',
    },
    CA: {
      id: 40,
      name: 'CANADA',
      dialCode: '1',
    },
    CV: {
      id: 41,
      name: 'CAPE VERDE',
      dialCode: '238',
    },
    KY: {
      id: 42,
      name: 'CAYMAN ISLANDS',
      dialCode: '1345',
    },
    CF: {
      id: 43,
      name: 'CENTRAL AFRICAN REPUBLIC',
      dialCode: '236',
    },
    TD: {
      id: 44,
      name: 'CHAD',
      dialCode: '235',
    },
    CL: {
      id: 45,
      name: 'CHILE',
      dialCode: '56',
    },
    CN: {
      id: 46,
      name: 'CHINA',
      dialCode: '86',
    },
    CX: {
      id: 47,
      name: 'CHRISTMAS ISLAND',
      dialCode: '61',
    },
    CC: {
      id: 48,
      name: 'COCOS (KEELING) ISLANDS',
      dialCode: '61',
    },
    CO: {
      id: 49,
      name: 'COLOMBIA',
      dialCode: '57',
    },
    KM: {
      id: 50,
      name: 'COMOROS',
      dialCode: '269',
    },
    CG: {
      id: 51,
      name: 'CONGO',
      dialCode: '242',
    },
    CD: {
      id: 52,
      name: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
      dialCode: '243',
    },
    CK: {
      id: 53,
      name: 'COOK ISLANDS',
      dialCode: '682',
    },
    CR: {
      id: 54,
      name: 'COSTA RICA',
      dialCode: '506',
    },
    CI: {
      id: 55,
      name: "COTE D'IVOIRE",
      dialCode: '225',
    },
    HR: {
      id: 56,
      name: 'CROATIA',
      dialCode: '385',
    },
    CU: {
      id: 57,
      name: 'CUBA',
      dialCode: '53',
    },
    CW: {
      id: 58,
      name: 'CURACAO',
      dialCode: '599',
    },
    CY: {
      id: 59,
      name: 'CYPRUS',
      dialCode: '357',
    },
    CZ: {
      id: 60,
      name: 'CZECH REPUBLIC',
      dialCode: '420',
    },
    DK: {
      id: 61,
      name: 'DENMARK',
      dialCode: '45',
    },
    DJ: {
      id: 62,
      name: 'DJIBOUTI',
      dialCode: '253',
    },
    DM: {
      id: 63,
      name: 'DOMINICA',
      dialCode: '1767',
    },
    DO: {
      id: 64,
      name: 'DOMINICAN REPUBLIC',
      dialCode: '1',
    },
    EC: {
      id: 65,
      name: 'ECUADOR',
      dialCode: '593',
    },
    EG: {
      id: 66,
      name: 'EGYPT',
      dialCode: '20',
    },
    SV: {
      id: 67,
      name: 'EL SALVADOR',
      dialCode: '503',
    },
    GQ: {
      id: 68,
      name: 'EQUATORIAL GUINEA',
      dialCode: '240',
    },
    ER: {
      id: 69,
      name: 'ERITREA',
      dialCode: '291',
    },
    EE: {
      id: 70,
      name: 'ESTONIA',
      dialCode: '372',
    },
    ET: {
      id: 71,
      name: 'ETHIOPIA',
      dialCode: '251',
    },
    FK: {
      id: 72,
      name: 'FALKLAND ISLANDS (MALVINAS)',
      dialCode: '500',
    },
    FO: {
      id: 73,
      name: 'FAROE ISLANDS',
      dialCode: '298',
    },
    FJ: {
      id: 74,
      name: 'FIJI',
      dialCode: '679',
    },
    FI: {
      id: 75,
      name: 'FINLAND',
      dialCode: '358',
    },
    FR: {
      id: 76,
      name: 'FRANCE',
      dialCode: '33',
    },
    GF: {
      id: 77,
      name: 'FRENCH GUIANA',
      dialCode: '594',
    },
    PF: {
      id: 78,
      name: 'FRENCH POLYNESIA',
      dialCode: '689',
    },
    TF: {
      id: 79,
      name: 'FRENCH SOUTHERN TERRITORIES',
      dialCode: '262',
    },
    GA: {
      id: 80,
      name: 'GABON',
      dialCode: '241',
    },
    GM: {
      id: 81,
      name: 'GAMBIA',
      dialCode: '220',
    },
    GE: {
      id: 82,
      name: 'GEORGIA',
      dialCode: '995',
    },
    DE: {
      id: 83,
      name: 'GERMANY',
      dialCode: '49',
    },
    GH: {
      id: 84,
      name: 'GHANA',
      dialCode: '233',
    },
    GI: {
      id: 85,
      name: 'GIBRALTAR',
      dialCode: '350',
    },
    GR: {
      id: 86,
      name: 'GREECE',
      dialCode: '30',
    },
    GL: {
      id: 87,
      name: 'GREENLAND',
      dialCode: '299',
    },
    GD: {
      id: 88,
      name: 'GRENADA',
      dialCode: '1473',
    },
    GP: {
      id: 89,
      name: 'GUADELOUPE',
      dialCode: '590',
    },
    GU: {
      id: 90,
      name: 'GUAM',
      dialCode: '1671',
    },
    GT: {
      id: 91,
      name: 'GUATEMALA',
      dialCode: '502',
    },
    GG: {
      id: 92,
      name: 'GUERNSEY',
      dialCode: '44',
    },
    GN: {
      id: 93,
      name: 'GUINEA',
      dialCode: '224',
    },
    GW: {
      id: 94,
      name: 'GUINEA-BISSAU',
      dialCode: '245',
    },
    GY: {
      id: 95,
      name: 'GUYANA',
      dialCode: '592',
    },
    HT: {
      id: 96,
      name: 'HAITI',
      dialCode: '509',
    },
    HM: {
      id: 97,
      name: 'HEARD ISLAND AND MCDONALD ISLANDS',
      dialCode: '0',
    },
    VA: {
      id: 98,
      name: 'HOLY SEE (VATICAN CITY STATE)',
      dialCode: '39',
    },
    HN: {
      id: 99,
      name: 'HONDURAS',
      dialCode: '504',
    },
    HK: {
      id: 100,
      name: 'HONG KONG',
      dialCode: '852',
    },
    HU: {
      id: 101,
      name: 'HUNGARY',
      dialCode: '36',
    },
    IS: {
      id: 102,
      name: 'ICELAND',
      dialCode: '354',
    },
    IN: {
      id: 103,
      name: 'INDIA',
      dialCode: '91',
    },
    ID: {
      id: 104,
      name: 'INDONESIA',
      dialCode: '62',
    },
    IR: {
      id: 105,
      name: 'IRAN, ISLAMIC REPUBLIC OF',
      dialCode: '98',
    },
    IQ: {
      id: 106,
      name: 'IRAQ',
      dialCode: '964',
    },
    IE: {
      id: 107,
      name: 'IRELAND',
      dialCode: '353',
    },
    IM: {
      id: 108,
      name: 'ISLE OF MAN',
      dialCode: '44',
    },
    IL: {
      id: 109,
      name: 'ISRAEL',
      dialCode: '972',
    },
    IT: {
      id: 110,
      name: 'ITALY',
      dialCode: '39',
    },
    JM: {
      id: 111,
      name: 'JAMAICA',
      dialCode: '1876',
    },
    JP: {
      id: 112,
      name: 'JAPAN',
      dialCode: '81',
    },
    JE: {
      id: 113,
      name: 'JERSEY',
      dialCode: '44',
    },
    JO: {
      id: 114,
      name: 'JORDAN',
      dialCode: '962',
    },
    KZ: {
      id: 115,
      name: 'KAZAKHSTAN',
      dialCode: '7',
    },
    KE: {
      id: 116,
      name: 'KENYA',
      dialCode: '254',
    },
    KI: {
      id: 117,
      name: 'KIRIBATI',
      dialCode: '686',
    },
    KP: {
      id: 118,
      name: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
      dialCode: '850',
    },
    KR: {
      id: 119,
      name: 'KOREA, REPUBLIC OF',
      dialCode: '82',
    },
    KW: {
      id: 120,
      name: 'KUWAIT',
      dialCode: '965',
    },
    KG: {
      id: 121,
      name: 'KYRGYZSTAN',
      dialCode: '996',
    },
    LA: {
      id: 122,
      name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
      dialCode: '856',
    },
    LV: {
      id: 123,
      name: 'LATVIA',
      dialCode: '371',
    },
    LB: {
      id: 124,
      name: 'LEBANON',
      dialCode: '961',
    },
    LS: {
      id: 125,
      name: 'LESOTHO',
      dialCode: '266',
    },
    LR: {
      id: 126,
      name: 'LIBERIA',
      dialCode: '231',
    },
    LY: {
      id: 127,
      name: 'LIBYA',
      dialCode: '218',
    },
    LI: {
      id: 128,
      name: 'LIECHTENSTEIN',
      dialCode: '423',
    },
    LT: {
      id: 129,
      name: 'LITHUANIA',
      dialCode: '370',
    },
    LU: {
      id: 130,
      name: 'LUXEMBOURG',
      dialCode: '352',
    },
    MO: {
      id: 131,
      name: 'MACAO',
      dialCode: '853',
    },
    MK: {
      id: 132,
      name: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
      dialCode: '389',
    },
    MG: {
      id: 133,
      name: 'MADAGASCAR',
      dialCode: '261',
    },
    MW: {
      id: 134,
      name: 'MALAWI',
      dialCode: '265',
    },
    MY: {
      id: 135,
      name: 'MALAYSIA',
      dialCode: '60',
    },
    MV: {
      id: 136,
      name: 'MALDIVES',
      dialCode: '960',
    },
    ML: {
      id: 137,
      name: 'MALI',
      dialCode: '223',
    },
    MT: {
      id: 138,
      name: 'MALTA',
      dialCode: '356',
    },
    MH: {
      id: 139,
      name: 'MARSHALL ISLANDS',
      dialCode: '692',
    },
    MQ: {
      id: 140,
      name: 'MARTINIQUE',
      dialCode: '596',
    },
    MR: {
      id: 141,
      name: 'MAURITANIA',
      dialCode: '222',
    },
    MU: {
      id: 142,
      name: 'MAURITIUS',
      dialCode: '230',
    },
    YT: {
      id: 143,
      name: 'MAYOTTE',
      dialCode: '262',
    },
    MX: {
      id: 144,
      name: 'MEXICO',
      dialCode: '52',
    },
    FM: {
      id: 145,
      name: 'MICRONESIA, FEDERATED STATES OF',
      dialCode: '691',
    },
    MD: {
      id: 146,
      name: 'MOLDOVA, REPUBLIC OF',
      dialCode: '373',
    },
    MC: {
      id: 147,
      name: 'MONACO',
      dialCode: '377',
    },
    MN: {
      id: 148,
      name: 'MONGOLIA',
      dialCode: '976',
    },
    ME: {
      id: 149,
      name: 'MONTENEGRO',
      dialCode: '382',
    },
    MS: {
      id: 150,
      name: 'MONTSERRAT',
      dialCode: '1664',
    },
    MA: {
      id: 151,
      name: 'MOROCCO',
      dialCode: '212',
    },
    MZ: {
      id: 152,
      name: 'MOZAMBIQUE',
      dialCode: '258',
    },
    MM: {
      id: 153,
      name: 'MYANMAR',
      dialCode: '95',
    },
    NA: {
      id: 154,
      name: 'NAMIBIA',
      dialCode: '264',
    },
    NR: {
      id: 155,
      name: 'NAURU',
      dialCode: '674',
    },
    NP: {
      id: 156,
      name: 'NEPAL',
      dialCode: '977',
    },
    NL: {
      id: 157,
      name: 'NETHERLANDS',
      dialCode: '31',
    },
    NC: {
      id: 158,
      name: 'NEW CALEDONIA',
      dialCode: '687',
    },
    NZ: {
      id: 159,
      name: 'NEW ZEALAND',
      dialCode: '64',
    },
    NI: {
      id: 160,
      name: 'NICARAGUA',
      dialCode: '505',
    },
    NE: {
      id: 161,
      name: 'NIGER',
      dialCode: '227',
    },
    NG: {
      id: 162,
      name: 'NIGERIA',
      dialCode: '234',
    },
    NU: {
      id: 163,
      name: 'NIUE',
      dialCode: '683',
    },
    NF: {
      id: 164,
      name: 'NORFOLK ISLAND',
      dialCode: '672',
    },
    MP: {
      id: 165,
      name: 'NORTHERN MARIANA ISLANDS',
      dialCode: '1670',
    },
    NO: {
      id: 166,
      name: 'NORWAY',
      dialCode: '47',
    },
    OM: {
      id: 167,
      name: 'OMAN',
      dialCode: '968',
    },
    PK: {
      id: 168,
      name: 'PAKISTAN',
      dialCode: '92',
    },
    PW: {
      id: 169,
      name: 'PALAU',
      dialCode: '680',
    },
    PS: {
      id: 170,
      name: 'PALESTINIAN TERRITORY, OCCUPIED',
      dialCode: '970',
    },
    PA: {
      id: 171,
      name: 'PANAMA',
      dialCode: '507',
    },
    PG: {
      id: 172,
      name: 'PAPUA NEW GUINEA',
      dialCode: '675',
    },
    PY: {
      id: 173,
      name: 'PARAGUAY',
      dialCode: '595',
    },
    PE: {
      id: 174,
      name: 'PERU',
      dialCode: '51',
    },
    PH: {
      id: 175,
      name: 'PHILIPPINES',
      dialCode: '63',
    },
    PN: {
      id: 176,
      name: 'PITCAIRN',
      dialCode: '64',
    },
    PL: {
      id: 177,
      name: 'POLAND',
      dialCode: '48',
    },
    PT: {
      id: 178,
      name: 'PORTUGAL',
      dialCode: '351',
    },
    PR: {
      id: 179,
      name: 'PUERTO RICO',
      dialCode: '1',
    },
    QA: {
      id: 180,
      name: 'QATAR',
      dialCode: '974',
    },
    RE: {
      id: 181,
      name: 'REUNION',
      dialCode: '262',
    },
    RO: {
      id: 182,
      name: 'ROMANIA',
      dialCode: '40',
    },
    RU: {
      id: 183,
      name: 'RUSSIAN FEDERATION',
      dialCode: '7',
    },
    RW: {
      id: 184,
      name: 'RWANDA',
      dialCode: '250',
    },
    BL: {
      id: 185,
      name: 'SAINT BARTHELEMY',
      dialCode: '590',
    },
    SH: {
      id: 186,
      name: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA',
      dialCode: '290',
    },
    KN: {
      id: 187,
      name: 'SAINT KITTS AND NEVIS',
      dialCode: '1869',
    },
    LC: {
      id: 188,
      name: 'SAINT LUCIA',
      dialCode: '1758',
    },
    MF: {
      id: 189,
      name: 'SAINT MARTIN (FRENCH PART)',
      dialCode: '590',
    },
    PM: {
      id: 190,
      name: 'SAINT PIERRE AND MIQUELON',
      dialCode: '508',
    },
    VC: {
      id: 191,
      name: 'SAINT VINCENT AND THE GRENADINES',
      dialCode: '1784',
    },
    WS: {
      id: 192,
      name: 'SAMOA',
      dialCode: '685',
    },
    SM: {
      id: 193,
      name: 'SAN MARINO',
      dialCode: '378',
    },
    ST: {
      id: 194,
      name: 'SAO TOME AND PRINCIPE',
      dialCode: '239',
    },
    SA: {
      id: 195,
      name: 'SAUDI ARABIA',
      dialCode: '966',
    },
    SN: {
      id: 196,
      name: 'SENEGAL',
      dialCode: '221',
    },
    RS: {
      id: 197,
      name: 'SERBIA',
      dialCode: '381',
    },
    SC: {
      id: 198,
      name: 'SEYCHELLES',
      dialCode: '248',
    },
    SL: {
      id: 199,
      name: 'SIERRA LEONE',
      dialCode: '232',
    },
    SG: {
      id: 200,
      name: 'SINGAPORE',
      dialCode: '65',
    },
    SX: {
      id: 201,
      name: 'SINT MAARTEN (DUTCH PART)',
      dialCode: '1721',
    },
    SK: {
      id: 202,
      name: 'SLOVAKIA',
      dialCode: '421',
    },
    SI: {
      id: 203,
      name: 'SLOVENIA',
      dialCode: '386',
    },
    SB: {
      id: 204,
      name: 'SOLOMON ISLANDS',
      dialCode: '677',
    },
    SO: {
      id: 205,
      name: 'SOMALIA',
      dialCode: '252',
    },
    ZA: {
      id: 206,
      name: 'SOUTH AFRICA',
      dialCode: '27',
    },
    GS: {
      id: 207,
      name: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
      dialCode: '500',
    },
    SS: {
      id: 208,
      name: 'SOUTH SUDAN',
      dialCode: '211',
    },
    ES: {
      id: 209,
      name: 'SPAIN',
      dialCode: '34',
    },
    LK: {
      id: 210,
      name: 'SRI LANKA',
      dialCode: '94',
    },
    SD: {
      id: 211,
      name: 'SUDAN',
      dialCode: '249',
    },
    SR: {
      id: 212,
      name: 'SURINAME',
      dialCode: '597',
    },
    SJ: {
      id: 213,
      name: 'SVALBARD AND JAN MAYEN',
      dialCode: '47',
    },
    SZ: {
      id: 214,
      name: 'SWAZILAND',
      dialCode: '268',
    },
    SE: {
      id: 215,
      name: 'SWEDEN',
      dialCode: '46',
    },
    CH: {
      id: 216,
      name: 'SWITZERLAND',
      dialCode: '41',
    },
    SY: {
      id: 217,
      name: 'SYRIAN ARAB REPUBLIC',
      dialCode: '963',
    },
    TW: {
      id: 218,
      name: 'TAIWAN, PROVINCE OF CHINA',
      dialCode: '886',
    },
    TJ: {
      id: 219,
      name: 'TAJIKISTAN',
      dialCode: '992',
    },
    TZ: {
      id: 220,
      name: 'TANZANIA, UNITED REPUBLIC OF',
      dialCode: '255',
    },
    TH: {
      id: 221,
      name: 'THAILAND',
      dialCode: '66',
    },
    TL: {
      id: 222,
      name: 'TIMOR-LESTE',
      dialCode: '670',
    },
    TG: {
      id: 223,
      name: 'TOGO',
      dialCode: '228',
    },
    TK: {
      id: 224,
      name: 'TOKELAU',
      dialCode: '690',
    },
    TO: {
      id: 225,
      name: 'TONGA',
      dialCode: '676',
    },
    TT: {
      id: 226,
      name: 'TRINIDAD AND TOBAGO',
      dialCode: '1868',
    },
    TN: {
      id: 227,
      name: 'TUNISIA',
      dialCode: '216',
    },
    TR: {
      id: 228,
      name: 'TURKEY',
      dialCode: '90',
    },
    TM: {
      id: 229,
      name: 'TURKMENISTAN',
      dialCode: '993',
    },
    TC: {
      id: 230,
      name: 'TURKS AND CAICOS ISLANDS',
      dialCode: '1649',
    },
    TV: {
      id: 231,
      name: 'TUVALU',
      dialCode: '688',
    },
    UG: {
      id: 232,
      name: 'UGANDA',
      dialCode: '256',
    },
    UA: {
      id: 233,
      name: 'UKRAINE',
      dialCode: '380',
    },
    AE: {
      id: 234,
      name: 'UNITED ARAB EMIRATES',
      dialCode: '971',
    },
    GB: {
      id: 235,
      name: 'UNITED KINGDOM',
      dialCode: '44',
    },
    US: {
      id: 236,
      name: 'UNITED STATES',
      dialCode: '1',
    },
    UM: {
      id: 237,
      name: 'UNITED STATES MINOR OUTLYING ISLANDS',
      dialCode: '1',
    },
    UY: {
      id: 238,
      name: 'URUGUAY',
      dialCode: '598',
    },
    UZ: {
      id: 239,
      name: 'UZBEKISTAN',
      dialCode: '998',
    },
    VU: {
      id: 240,
      name: 'VANUATU',
      dialCode: '678',
    },
    VE: {
      id: 241,
      name: 'VENEZUELA, BOLIVARIAN REPUBLIC OF',
      dialCode: '58',
    },
    VN: {
      id: 242,
      name: 'VIETNAM',
      dialCode: '84',
    },
    VG: {
      id: 243,
      name: 'VIRGIN ISLANDS, BRITISH',
      dialCode: '1284',
    },
    VI: {
      id: 244,
      name: 'VIRGIN ISLANDS, U.S.',
      dialCode: '1340',
    },
    WF: {
      id: 245,
      name: 'WALLIS AND FUTUNA',
      dialCode: '681',
    },
    EH: {
      id: 246,
      name: 'WESTERN SAHARA',
      dialCode: '212',
    },
    YE: {
      id: 247,
      name: 'YEMEN',
      dialCode: '967',
    },
    ZM: {
      id: 248,
      name: 'ZAMBIA',
      dialCode: '260',
    },
    ZW: {
      id: 249,
      name: 'ZIMBABWE',
      dialCode: '263',
    },
    ZZ: {
      id: 1000,
      name: 'OTHER',
      dialCode: '',
    },
  },
  countriesWithIslamicAccountType: ['IR', 'TR', 'PK', 'MY', 'SA', 'NG', 'AE', 'EG']
}
