export const fa = {
  "account_list": "لیست حساب",
  "create_live_account": "ایجاد حساب کاربری زنده",
  "upgrade_to_live_account": "ارتقا به اکانت زنده",
  "create_demo_account": "ایجاد حساب آزمایشی",
  "finance": "مالی",
  "deposit_funds": "سپرده‌گذاری وجوه",
  "withdraw_funds": "برداشت وجوه",
  "internal_transfer": "انتقال داخلی",
  "external_transfer": "انتقال خارجی",
  "payment_history": "سابقه پرداخت",
  "my_cards": "کارت‌های من",
  "personal_information": "اطلاعات شخصی",
  "change_password": "تغییر رمز عبور",
  "change_password_of": "رمز عبور را تغییر دهید",
  "upload_documents": "بارگذاری اسناد",
  "upload_documents_for_account_approval": "اسناد را برای تأیید حساب، بارگذاری کنید",
  "partner": "شریک",
  "overview": "بررسی اجمالی",
  "one_final_step": "گام آخر",
  "clients": "مشتریان",
  "sub_ibs": "بانک‌های سرمایه‌گذاری فرعی",
  "payout": "پرداخت",
  "marketing_material": "مطالب بازاریابی",
  "become_a_partner": "شریک شوید",
  "what_geographic_region": "مشتریان خود را از کدام منطقه هدف قرار می دهید؟",
  "website_social_media": "وب‌سایت/رسانه‌های اجتماعی",
  "skype_ID": "نام کاربری اسکایپ",
  "submit": "ارسال",
  "changepass_error": "رمز عبور فعلی اشتباه است، لطفاً دوباره امتحان کنید.",
  "changepass_success": "رمز عبور با موفقیت تغییر کرد",
  "current_password": "رمز عبور فعلی",
  "new_password": "رمز عبور جدید",
  "confirm_new_password": "رمز عبور جدید را تأیید کنید",
  "account_approval_pending": "تأیید حساب در انتظار",
  "account_must_be_approved_message": "قبل از ایجاد یک حساب تجاری زنده جدید، حساب شما باید تأیید شود.",
  "new_trading_account_message": "حساب تجاری جدید با موفقیت ایجاد شد",
  "password_generated_for_ta": "یک رمز عبور برای حساب تجاری جدید شما ایجاد و به ایمیل شما ارسال شد",
  "choose_your_desired_trading_platform_and_settings": "پلتفرم معاملاتی و تنظیمات مورد نظر خود را انتخاب کنید",
  "select_leverage": "لوریج را انتخاب کنید",
  "create": "ايجاد كردن",
  "select_account_type": "نوع حساب را انتخاب کنید",
  "select_currency": "واحد پول را انتخاب کنید",
  "no_trading_account_found": "هیچ حساب تجاری پیدا نشد.",
  "bankwire_notice": "از اینکه مشخصات بانکی خود را ارسال کردید متشکریم. ما مشخصات شما را در بانک خود ثبت خواهیم کرد.<br><br>شما جزئیات نقل و انتقالات را از طریق ایمیل در عرض 24 ساعت به همراه مرجع پرداخت شخصی خود دریافت خواهید کرد. شما می‌توانید از این جزئیات برای هرگونه واریزی آتی استفاده کنید.<br><br><strong>مهم:</strong> مرجع پرداخت برای یک مشتری شخصی‌سازی شده است. اگر از مرجع پرداختی که دریافت می‌کنید استفاده نکنید، ما نمی‌توانیم واریزی شما را بپذیریم. هیچ شخص ثالثی نمی‌تواند از همان مرجع پرداخت استفاده کند.",
  "deposit": "واریز",
  "account_details": "جزئیات حساب",
  "account_id": "شناسه حساب",
  "continue": "ادامه",
  "bankwire_deposit_details_notice": "بانک ما برای پردازش واریزی‌تان، به جزئیات بانکی شما نیاز دارد. هر گونه واریزی که از طریق انتقال الکترونیکی انجام شود به همان حسابی که از آن واریز کرده‌اید بازگردانده می‌شود.",
  "bankwire_withdrawal_details_notice": "بانک ما برای پردازش برداشتتان، به جزئیات بانکی شما نیاز دارد. هرگونه برداشتی که از طریق انتقال الکترونیکی انجام شود به همان حسابی که از آن واریز کرده‌اید بازگردانده می‌شود.",
  "bank_name": "نام بانک",
  "full_name": "نام و نام خانوادگی",
  "amount": "مبلغ",
  "deposit_failed": "واریز انجام نشد",
  "deposit_successful": "واریز انجام شد",
  "login": "ورود",
  "already_logged_in": "قبلاً وارد شده‌اید",
  "trades": "معاملات",
  "client_id": "شناسه مشتری",
  "trading_account": "حساب تجاری",
  "platform": "پلتفرم‌",
  "order": "سفارش",
  "pair": "جفت",
  "volume": "حجم",
  "close_date": "تاریخ بستن",
  "commission": "کمیسیون",
  "status": "وضعیت",
  "total_commission_generated": "کل کمیسیون ایجاد شده",
  "total_deposits": "کل سپرده‌ها",
  "total_withdrawals": "کل برداشت‌ها",
  "date_joined": "تاریخ عضویت",
  "more": "بیشتر",
  "banners": "بنرها",
  "partner_payout_request_message": "درخواست شما ثبت شد.",
  "bank_address": "آدرس بانک",
  "your_provider_email": "ایمیل {provider} شما",
  "select": "انتخاب",
  "next": "بعد",
  "bank": "بانک",
  "state": "حالت",
  "date": "تاریخ",
  "transfer_from": "انتقال از",
  "transfer_to": "انتقال به",
  "internal_transfer_processed_successfully": "انتقال داخلی با موفقیت پردازش شد.",
  "external_transfer_processed_successfully": "انتقال خارجی با موفقیت پردازش شد.",
  "select_account": "حساب را انتخاب کنید",
  "to": "به",
  "warning_currency_conversion_will_take_place": "هشدار: تبدیل واحد پول انجام خواهد شد",
  "transfer": "انتقال",
  "account": "حساب",
  "currency": "واحد پول",
  "balance": "موجودی ",
  "equity": "دارایی خالص",
  "download": "دانلود",
  "launch": "راه‌اندازی",
  "email": "ایمیل",
  "password": "کلمه عبور",
  "forgot": "فراموش کرده‌ام",
  "forgot_password": "رمز عبور را فراموش کرده‌ام",
  "risk_warning_footer_uncollapsed": "<span class=\"font-semibold\">هشدار ریسک:</span> قراردادهای مابه‌التفاوت («CFD») لوریجی و محصولات فارکس، ابزارهای مالی پیچیده‌ای هستند که با مارجین معامله می‌شوند.",
  "risk_warning_footer_uncollapsed_mobile": "<span class=\"font-semibold\">هشدار ریسک:</span> قراردادهای مابه‌التفاوت لوریجی...",
  "risk_warning_footer": "<span class=\"font-semibold\">هشدار ریسک:</span> قراردادهای مابه‌التفاوت («CFD») لوریجی و محصولات فارکس، ابزارهای مالی پیچیده‌ای هستند که با مارجین معامله می‌شوند. لطفاً اطمینان حاصل کنید که قبل از معامله، با در نظر گرفتن اهداف سرمایه‌گذاری و سطح تجربه خود، ریسک‌های موجود را کاملاً درک کرده‌اید. شما نباید بیش از حد توانِ از دست دادن، ریسک کنید. تمام اطلاعات موجود در این وب‌سایت به‌منظور جلب شهروندان یا ساکنان کره شمالی، بریتانیا، سوریه، ایالات متحده، کانادا، منطقه اقتصادی اروپا یا هر حوزه قضایی دیگری که مغایر با قوانین یا مقررات محلی باشد، نیست. تجارت محصولات لوریجی مانند CFDها و فارکس ممکن است برای همه سرمایه‌گذاران مناسب نباشد زیرا ریسک بالایی برای سرمایه شما دارد. لطفاً اطمینان حاصل کنید که ریسک‌های موجود را به طور کامل درک کرده‌اید و در صورت لزوم به دنبال مشاوره مستقل باشید. لطفاً <a href=\"{websiteURL}assets/files/fa/risk-disclosure-1.pdf?v=1.5\" target=\"_blank\" class=\"font-semibold\">Risk Disclosure (بررسی ریسک)</a> را کامل بخوانید.",
  "error_contact_support": "مشکلی پیش آمد، لطفاً با پشتیبانی تماس بگیرید.",
  "type": "نوع حساب",
  "username": "نام کاربری",
  "company_name": "نام شرکت",
  "website": "وب‌سایت",
  "first_name": "نام",
  "last_name": "نام خانوادگی",
  "date_of_birth": "تاریخ تولد",
  "gender": "جنسیت",
  "phone": "تلفن",
  "dial_code": "کد شماره‌گیری",
  "country": "کشور",
  "address": "آدرس",
  "year_moved_to_address": "سالی که به این آدرس نقل مکان کردید",
  "post_code": "کد پستی",
  "post_zip_code": "کد پستی / زیپ کد",
  "selected_currency": "واحد پول انتخاب شده",
  "january": "ژانویه",
  "february": "فوریه",
  "march": "مارس",
  "april": "آوریل",
  "may": "می",
  "june": "ژوئن",
  "july": "جولای",
  "august": "آگوست",
  "september": "سپتامبر",
  "october": "اکتبر",
  "november": "نوامبر",
  "december": "دسامبر",
  "already_have_an_account": "از قبل حساب کاربری دارید؟",
  "invalid_request_login_required": "درخواست نامعتبر است، ابتدا باید وارد شوید.",
  "individual": "شخص",
  "company": "شرکت",
  "company_information": "اطلاعات تماس",
  "company_website": "وب‌سایت شرکت",
  "title": "عنوان",
  "confirm_password": "رمز عبور جدید را تأیید کنید",
  "select_country": "کشور را انتخاب کنید",
  "phone_number": "شماره تلفن",
  "choose_trading_platform": "پلتفرم معاملاتی را انتخاب کنید",
  "select_option": "گزینه را انتخاب کنید",
  "day": "روز",
  "month": "ماه",
  "year": "سال",
  "company_registration_information": "اطلاعات ثبت شرکت",
  "date_of_registration": "تاریخ ثبت نام",
  "select_nationality": "ملیت را انتخاب کنید",
  "nationality": "ملیت",
  "select_gender": "جنسیت را انتخاب کنید",
  "male": "مرد",
  "female": "زن",
  "no_gender": "ترجیح می‌دهم نگویم",
  "address_line": "خط آدرس",
  "city_town": "شهرک / شهر",
  "state_province": "استان / ایالت",
  "select_year": "سال را انتخاب کنید",
  "company_shareholders_directors": "سهامداران و مدیران شرکت",
  "number_of_shareholders": "تعداد سهامداران",
  "number_of_directors": "تعداد مدیران",
  "shareholder": "سهامدار",
  "financial_information": "اطلاعات مالی",
  "total_estimated_net_worth": "کل ارزش خالص برآورد شده (€)؟",
  "less_than_amount": "کمتر از {amount}",
  "over_amount": "بیشتر از {amount}",
  "total_estimated_annual_income": "کل درآمد تخمینی سالانه (€)؟",
  "employment_information": "اطلاعات شغلی",
  "employment_status": "وضعیت شغلی",
  "employed": "شاغل",
  "self_employed": "خویش‌فرما",
  "retired": "بازنشسته",
  "student": "دانشجو",
  "unemployed": "بیکار",
  "source_of_income": "منبع درآمد",
  "employment": "اشتغال",
  "inheritance": "ارثیه",
  "investment": "سرمایه‌گذاری",
  "trading_experience": "تجربه تجارت",
  "forex_cfds_and_other_instruments": "فارکس، cfdها و ابزارهای دیگر",
  "yes": "بله",
  "no": "خیر",
  "lose_capital_risk": "محصولات مشتقه به عنوان بخشی از اهداف سرمایه‌گذاری و نگرش من نسبت به ریسک مناسب هستند و منمی‌توانم ریسک موجود در معامله آنها را ارزیابی کنم، از جمله احتمال اینکه ممکن است تمام سرمایه خود را از دست بدهم",
  "prev_pro_qualifications": "من دارای مدارک حرفه‌ای و/یا تجربه کاری قبلی در صنعت خدمات مالی هستم",
  "account_type": "نوع حساب",
  "choose_currency": "واحد پول را انتخاب کنید",
  "tos_with_link": "من در <a class=\"font-bold\" target=\"_blank\" href=\"{URL}\">اینجا</a> شرایط و ضوابط و هشدار خطر را خوانده، درک کرده و پذیرفته‌ام.",
  "privacy_policy_link": "من <a class=\"font-bold\" target=\"_blank\" href=\"{URL}\">سیاست حفظ حریم خصوصی</a> را خوانده و پذیرفته‌ام.",
  "terms_link": "من همه <a class=\"font-bold\" target=\"_blank\" href=\"{URL}\">شرایط و ضوابط</a> را خوانده و پذیرفته‌ام.",
  "registration_successful": "ثبت‌نام با موفقیت انجام شد",
  "reset_password_invalid_link": "پیوند بازنشانی شما نامعتبر است، لطفاً دوباره امتحان کنید یا با پشتیبانی تماس بگیرید.",
  "reset_account_password": "بازنشانی رمز عبور حساب",
  "choose_your_new_password": "رمز عبور جدید خود را انتخاب کنید",
  "reset_password": "بازنشانی رمز عبور",
  "withdrawal_request_success_message": "درخواست برداشت شما با موفقیت انجام شد",
  "withdraw": "برداشت کردن",
  "withdrawal": "برداشت",
  "cancel": "لغو",
  "you_became_a_partner_successfully": "شما با موفقیت شریک Plexytrade شدید!",
  "note_wallet_currency_conversion_partner": "لطفاً توجه داشته باشید که با شریک شدن، واحد پول کیف پول شما به دلار آمریکا تغییر می‌کند و هر وجه فعلی با نرخ مبادله فعلی به دلار آمریکا تبدیل می‌شود!",
  "upgraded_to_a_live_account_successfully": "با موفقیت به یک حساب کاربری زنده ارتقا یافت!",
  "passwords_dont_match": "رمزهای عبور مطابقت ندارند",
  "password_rules": "حداقل 1 حرف بزرگ، 1 حرف کوچک و 1 عدد (حداکثر 6 تا 16 کاراکتر)",
  "only_currency_format_allowed": "فقط فرمت واحد پول مجاز است",
  "internal_server_error_support": "خطای سرور داخلی. لطفاً با پشتیبانی تماس بگیرید",
  "please_fill_all_fields": "لطفاً تمام فیلدها را پر کنید",
  "no_logs_found_for_this_client": "هیچ گزارشی برای این مشتری یافت نشد",
  "internal_server_error_try_again": "خطای سرور داخلی. لطفاً بعداً دوباره امتحان کنید",
  "insufficient_balance": "موجودی ناکافی",
  "currency_conversion_connection_error": "از سرور تبدیل واحد پول پاسخی دریافت نشد، لطفاً دوباره امتحان کنید یا با پشتیبانی تماس بگیرید.",
  "currency_conversion_error": "خطای تبدیل واحد پول، لطفاً دوباره امتحان کنید یا با پشتیبانی تماس بگیرید.",
  "invalid_accounts": "حساب‌های نامعتبر",
  "withdrawal_unsuccessful_equity_message": "برداشت ناموفق، دارایی کافی در حساب تجاری {TAID} نیست",
  "withdrawal_failed_free_margin": "برداشت ناموفق بود. لطفاً حاشیه آزاد خود را دوباره بررسی کنید.",
  "pamm_copy_server_field_message": "لطفاً آدرس IP را در فیلد «سِرور» در سرور MT4 خود کپی و جایگذاری کنید",
  "invalid_request": "درخواست نامعتبر",
  "missing_shareholders_directors": "سهامداران/مدیران وارد نشده",
  "missing": "وارد نشده",
  "invalid_request_passwords_do_not_match": "درخواست نامعتبر. رمزهای عبور مطابقت ندارند",
  "email_exists_message": "این ایمیل وجود دارد، لطفاً با آدرس ایمیل دیگری امتحان کنید.",
  "your_payment_method_has_already_been_set": "روش پرداخت شما قبلاً تنظیم شده است. لطفاً با پشتیبانی تماس بگیرید",
  "payment_method_is_required_message": "روش پرداخت الزامی است. لطفاً دوباره امتحان کنید یا با پشتیبانی تماس بگیرید",
  "couldnt_find_your_account": "حساب شما پیدا نشد",
  "ta_limit_error_message": "حساب شما به حد مجاز حساب های تجاری({limit}) برای {platform} رسیده است.",
  "error": "خطا",
  "account_created_successfully": "حساب با موفقیت ایجاد شد",
  "invalid_leverage": "لوریج نامعتبر",
  "insufficient_data_provided": "داده‌های ناکافی ارائه شده است",
  "insufficient_data_provided_missing_field": "داده‌های ناکافی ارائه شده است. {field} وارد نشده.",
  "invalid_amount": "مبلغ نامعتبر",
  "account_taid_doesnt_exist": "حساب {TAID} وجود ندارد",
  "withdrawal_unsuccessful_equity_taid": "برداشت ناموفق، دارایی کافی در حساب تجاری {TAID} نیست",
  "withdrawal_unsuccessful_free_margin_taid": "برداشت ناموفق، حاشیه آزاد کافی در حساب تجاری {TAID} نیست",
  "internal_server_error": "خطای سرور داخلی",
  "deposit_failed_support": "واریز ناموفق بود، لطفاً با پشتیبانی تماس بگیرید.",
  "upload_documents_instructions": "لطفا مدارک زیر را در اختیار ما قرار دهید.<br>فایل‌ها باید در فرمت PDF، JPG یا PNG باشند و نباید بیش از 5 مگابایت باشند.",
  "total_earned": "جمع کسب شده",
  "total_paid": "جمع پرداختی",
  "total_clients": "کل مشتریان",
  "total_sub_ibs": "کل بانک‌های سرمایه‌گذاری فرعی",
  "welcome_title_live": "از اینکه حساب خود را نزد Plexytrade باز کردید سپاسگزاریم!",
  "welcome_title_demo": "از اینکه حساب آزمایشی خود را نزد Plexytrade باز کردید سپاسگزاریم!",
  "welcome_title_ib": "از اینکه حساب شرکای خود را نزد Plexytrade باز کردید سپاسگزاریم!",
  "welcome_text_live": "<p>برای دریافت بودجه، تأیید و آمادگی برای معامله، باید مراحل زیر را انجام دهید:</p><ul><li>به پورتال مشتری Plexytrade خود وارد شوید</li><li>اسناد خود را آپلود کنید</li><li>حساب خود را تامین مالی کنید</li><li>تجارت را شروع کنید!</li></ul><p>اگر به کمک نیاز دارید، تیم پشتیبانی مشتری دوستانه ما 24/5 در دسترس است. برای تماس با آنها، <a href=\"{contactURL}\" target=\"_blank\">اینجا</a></p> را کلیک کنید",
  "welcome_text_demo": "<p>برای دریافت بودجه، تأیید و آمادگی برای معامله، باید مراحل زیر را انجام دهید:</p><ul><li>پلتفرم MT4 را دانلود کنید</li><li>مهارت‌های معاملاتی خود و سرعت اجرای ما را آزمایش کنید</li><li>وارد پورتال مشتری خود شوید و به یک حساب کاربری زنده ارتقا دهید</li><li>اسناد خود را آپلود کنید و حساب خود را تامین مالی کنید</li><li>در بزرگترین بازار مالی جهان تجارت کنید!</li></ul><p>اگر به کمک نیاز دارید، تیم پشتیبانی مشتری دوستانه ما 24/5 در دسترس است. برای تماس با آنها، <a href=\"{contactURL}\" target=\"_blank\">اینجا</a></p> را کلیک کنید",
  "welcome_text_ib": "<p>برای تأیید حساب شرکای خود و کسب آمادگی جهت دریافت URL ارجاع، باید مراحل زیر را انجام دهید:</p><ul><li>وارد پورتال مشتری خود شوید</li><li>اسناد خود را آپلود کنید</li><li>URL ردیابی خود را در بخش «بررسی اجمالی» و «ابزارهای بازاریابی» دریافت کنید</li><li>مشتریان خود را معرفی کنید و کمیسیون دریافت نمایید!</li></ul><p>اگر به کمک نیاز دارید، تیم پشتیبانی مشتری دوستانه ما 24/5 در دسترس است. برای تماس با آنها، <a href=\"{contactURL}\" target=\"_blank\">اینجا</a></p> را کلیک کنید",
  "tracking_links": "لینک‌های ردیابی",
  "login_to_your_account": "وارد حساب کاربری خود شوید",
  "register": "ثبت‌نام",
  "open_demo_account": "باز کردن حساب آزمایشی",
  "page_title_partner": "شریک شوید",
  "home": "صفحه اصلی",
  "about": "درباره",
  "account_types": "انواع حساب",
  "spreads": "اسپردها",
  "funding": "منابع مالی",
  "education": "تحصیلات",
  "contact": "تماس",
  "open_live_account": "افتتاح حساب زنده",
  "not_registered_with_question": "در Plexytrade ثبت‌نام نکرده‌اید؟",
  "forgot_password_q": "رمز عبور خود را فراموش کرده‌اید؟",
  "pending_deposit_success": "عملیات با موفقیت انجام شد، سپرده شما اکنون در انتظار تأیید است",
  "local_depositor": "سپرده‌گذار محلی",
  "converted_amount": "مقدار تبدیل شده",
  "reference_id": "شناسه مرجع",
  "from_account": "از حساب",
  "to_account": "به حساب",
  "from_date": "از تاریخ",
  "to_date": "به تاریخ",
  "wallet": "کیف پول",
  "received": "اخذ شده",
  "send": "ارسال",
  "sender_not_a_transfer_account": "فرستنده یک سپرده‌گذار محلی نیست",
  "withdrawal_failed_support": "برداشت ناموفق بود، لطفاً با پشتیبانی تماس بگیرید.",
  "clear": "پاک کردن",
  "transfer_completed_successfully": "انتقال با موفقیت انجام شد",
  "receive": "دريافت",
  "view_trades": "مشاهده معاملات",
  "your_provider_account_number": "شماره حساب {provider} شما",
  "your_cryptocurrency_address": "آدرس ارز دیجیتال شما",
  "your_crypto_address": " آدرس {crypto}",
  "please_select": "لطفاً انتخاب کنید",
  "destination_tag": "برچسب مقصد",
  "dashboard": "داشبورد",
  "sent": "ارسال شد",
  "client_state": "وضعیت حساب",
  "client_state_approved": "تأیید شد",
  "client_state_pending": "در انتظار",
  "client_state_incomplete": "ناقص",
  "open_trades": "معاملات باز",
  "closed_trades": "معاملات بسته",
  "trade_history": "تاریخ تجارت",
  "search_trades": "جستجوی معاملات",
  "trade_no": "شماره معامله",
  "symbol": "نماد",
  "open_price": "قیمت باز کردن",
  "close_price": "قیمت بستن",
  "datetime": "تاریخ زمان",
  "my_bonuses": "پاداش‌های من",
  "bonus": "پاداش",
  "conversion_progress": "پیشرفت تبدیل",
  "progress_towards": "پیشرفت به سمت",
  "front_of_id": "جلوی شناسه",
  "back_of_id": "پشت شناسه",
  "include_account_in_bonus_program": "من مایل به دریافت 120% پاداش نقدی هستم و با <a class=\"text-blue\" href=\"https://plexytrade.com/assets/files/fa/bonus-terms-and-conditions-1.pdf?v=1.8\" target=\"_blank\">شرایط و ضوابط پاداش</a> موافقم.",
  "master_account": "حساب اصلی",
  "p_l": "P&L",
  "bonus_tos_with_link": "من <a class=\"font-bold\" target=\"_blank\" href=\"{URL}\">شرایط و ضوابط برنامه معاملاتی با بودجه 120%</a> را خوانده و درک کرده‌ام.",
  "i_am_over_18": "من اعلام می‌کنم که برای افتتاح این حساب بیش از 18 (هجده) سال سن دارم.",
  "profit": "سود",
  "attach": "ضمیمه کردن",
  "private_code": "کد خصوصی",
  "investor_link": "لینک سرمایه‌گذار",
  "i_accept_the_lpoa": "من <a href=\"{URL}\" target=\"_blank\" class=\"text-blue\">LPOA</a> را قبول می‌کنم.",
  "read_and_accept_lpoa": "من <a href=\"{URL}\" target=\"_blank\" class=\"text-blue\">LPOA</a> را خوانده و قبول می‌کنم.",
  "must_accept_lpoa": "LPOA باید پذیرفته شود.",
  "invest": "سرمایه‌گذاری",
  "name": "نام",
  "search": "جستجو",
  "partners": "شرکا",
  "reject_withdrawls_during_open_trades": "رد برداشت در طول معاملات باز",
  "private_master_account": "حساب مستر خصوصی",
  "minimum_deposit": "حداقل سپرده",
  "performance_fee": "هزینه عملکرد",
  "display_name": "نام نمایشی",
  "new_master_account": "حساب مستر جدید",
  "pamm_statistics": "آمار pamm",
  "pamm": "PAMM",
  "volume_traded": "حجم معامله",
  "pamm_master_locks_deposits": "این مستر pamm، سپرده‌ها را در طول معاملات باز محدود می‌کند!",
  "cannot_create_more_pamm_master_accounts": "نمی‌توان حساب‌های مستر pamm بیشتری ایجاد کرد.",
  "bitcoin": "Confirmo",
  "cryptocurrency": "ارز دیجیتال",
  "cryptocurrency_address": "آدرس ارز دیجیتال",
  "register_live_account": "ثبت‌نام حساب زنده",
  "withdrawal_confirmo_amount_error": "از حد برداشت فراتر رفت. مبلغ برداشت درخواستی از حداکثر سقف 50,000 دلار تجاوز می‌کند. لطفاً با مبلغ کمتری دوباره امتحان کنید.",
  "confirmo_deposit_note": "<span class=\"font-semibold\">توجه:</span> لطفاً توجه داشته باشید که در حال حاضر، Confirmo از برداشت Bitcoin Lightning پشتیبانی نمی‌کند.",
  "register_partner_account": "ثبت‌نام حساب شریک",
  "register_demo_account": "ثبت‌نام حساب آزمایشی",
  "risk_disclosure": "افشای ریسک",
  "address_line_2": "خط آدرس 2",
  "skype_id": "نام کاربری اسکایپ",
  "social_media": "رسانه‌های اجتماعی",
  "password_rules_extended": "رمز عبور باید حداقل شامل این موارد باشد: 1 حرف بزرگ، 1 حرف کوچک، 1 نماد و 1 عدد. طول رمز عبور باید بین 6 تا 15 کاراکتر باشد.",
  "login_information": "اطلاعات ورود",
  "director": "مدیر",
  "pmethod_visa_mastercard": "VISA/MASTERCARD",
  "pmethod_visa_mastercard_alternative": "VISA/MASTERCARD.",
  "pmethod_bitcoin": "BITCOIN",
  "pmethod_bankwire": "Bankwire",
  "pmethod_fasapay": "Fasapay",
  "pmethod_ideal": "ایده‌آل",
  "pmethod_instant_bank_transfer": "حواله بانکی فوری",
  "pmethod_jeton": "Jeton",
  "pmethod_jeton_wallet": "کیف پول Jeton",
  "pmethod_my_pay": "MyPay",
  "pmethod_neteller": "NETELLER",
  "pmethod_vload": "PayRedeem",
  "pmethod_paykasa": "Paykasa",
  "pmethod_skrill": "Skrill",
  "pmethod_sofort": "Sofort",
  "pmethod_koalapay": "KoalaPay",
  "pmethod_cup": "CUP",
  "pmethod_lydiax": "Crypto",
  "pmethod_safecharge": "SafeCharge",
  "pmethod_trustly": "Trustly - انتفال الکترونیکی بانکی",
  "pmethod_powercash": "PowerCash",
  "field_min_currency_value": "فیلد {field} باید {min} یا بیشتر باشد.",
  "method_deposit": "{method} واریز",
  "method_deposit_status": "{method} وضعیت واریز",
  "quick_menu": "منوی سریع",
  "details": "جزئیات",
  "date_from": "تاریخ از",
  "date_to": "تاریخ به",
  "from": "از",
  "forgot_password_form_instruction": "لطفا آدرس ایمیل حساب خود را در زیر ارسال کنید. سپس یک ایمیل با لینکی برای تنظیم مجدد رمز عبور دریافت خواهید کرد.",
  "forgot_password_submit_success_message": "لینک بازنشانی رمز عبور به ایمیل شما ارسال شده است. لطفاً به ایمیل خود ({email}) بروید و لینک را برای تغییر رمز عبور دنبال کنید.",
  "password_reset_successfully": "رمز عبور شما با موفقیت بازنشانی شد",
  "password_too_week_or_mismatch": "رمز عبور خیلی ضعیف است یا رمزهای عبور وارد شده با هم مطابقت ندارند.",
  "withdrawals_credit_card_remaining_amount_message": "برای برداشت کردن از طریق روش‌های دیگر، ابتدا باید {amount} را برداشت کرده و به کارت اعتباری خود برگردانید.",
  "withdrawals_credit_card_refund_policy_message": "ارائه‌دهندگان کارت اعتباری فقط به شما اجازه می‌دهند کل مبلغی را که به کارت خود واریز کرده‌اید، برداشت کنید.",
  "withdrawals_amount_withdrawable_message": "شما مجموع {amount} را می‌توانید به کارت خود بازگردانید، هر میزانی بیش از این مبلغ را می‌توانید با یکی از روش‌های دیگر ما برداشت کنید.",
  "country_blocked_for_this_method": "کشور شما برای این روش مسدود شده است",
  "copied_text_successfully": "{text} با موفقیت کپی شده است",
  "choose_wallet_currency": "واحد پول کیف پول را انتخاب کنید",
  "pamm_master_account_created_successfully": "حساب مستر PAMM با موفقیت ایجاد شد!",
  "account_number": "شماره حساب",
  "or_account_no_for_us": "یا شماره حساب (برای مشتریان ایالات متحده)",
  "your_physical_address": "آدرس فیزیکی شما، مطابق با آدرسی که هنگام ثبت‌نام برای حساب کاربری استفاده کرده‌اید",
  "must_be_a_utility_bill": "باید قبض خدمات (برق، آب، تلفن، گاز/انرژی، مالیات شورا/قبض‌های بهداشتی) یا صورت حساب بانکی باشد",
  "for_further_info_attach": "**برای مستندات بیشتر، لطفاً آنها را در یک ایمیل پیوست کرده و به بخش پشتیبان @plexytrade.com ارسال کنید",
  "banners_ad_blocker_notice": "اگر در مشاهده بنرهای زیر مشکل دارید، لطفاً مسدود کننده تبلیغات خود را غیرفعال کنید",
  "transfer_out": "انتقال به",
  "transfer_in": "انتقال در",
  "done": "انجام شد",
  "not_required": "لازم نیست",
  "wallet_balance": "موجودی کیف پول",
  "must_agree_terms": "برای ایجاد حساب کاربری باید با شرایط و ضوابط ما موافقت کنید",
  "must_agree_privacy": "برای ایجاد یک حساب کاربری باید با سیاست حفظ حریم خصوصی ما موافقت کنید",
  "select_payment_method": "روش پرداخت را انتخاب کنید",
  "card_number": "شماره کارت",
  "expiry": "انقضا",
  "redeem": "بازخرید",
  "must_be_multiple_of_10": "مقدار باید مضربی از 10 باشد",
  "select_starting_balance": "موجودی شروع را انتخاب کنید",
  "business_name": "نام کسب و کار",
  "error_getting_VPS": "خطا: VPS دریافت نشد",
  "error_request_failed": "خطا: درخواست ناموفق بود",
  "error_no_user_with_this_email": "خطا: حسابی با این آدرس ایمیل پیدا نشد",
  "error_too_many_password_reset_requests": "خطا: تعداد زیادی بازنشانی رمز عبور درخواست شده است",
  "error_expired_or_invalid_request": "خطا: درخواست نامعتبر یا منقضی شده است",
  "error_insufficient_wallet_balance": "خطا: موجودی ناکافی در کیف پول",
  "error_insufficient_balance_or_equity": "خطا: موجودی یا دارایی ناکافی",
  "error_pamm_master_deposit": "نمی‌توان به حساب مستر PAMM واریز کرد",
  "error_pamm_master_withdrawal": "نمی‌توان از حساب مستر PAMM برداشت کرد",
  "error_pamm_master_locked_deposits": "رد شد: مستر pamm حسابی که پول به آن واریز می‌شود، واریزی‌ها را در معاملات باز رد می‌کند.",
  "error_pamm_master_locked_withdrawals": "رد شد: pamm master حسابی که از آن پول برداشت می‌شود، برداشت‌ها را در معاملات باز رد می‌کند.",
  "error_pamm_master_minimum_deposit": "رد شد: حداقل شرط واریز مستر pamm برآورده نشد.",
  "error_live_account_already": "حساب شما در حال حاضر یک حساب کاربری زنده است",
  "error_min_balance_required_is": "حداقل موجودی مورد نیاز برای حساب {TAAccountType} ({TAID}) {min}{currency} است.",
  "error_transfer_same_wallet": "امکان انتقال از یک کیف پول به کیف پول دیگر وجود ندارد.",
  "error_transfer_same_trading_account": "امکان انتقال از و به یک حساب تجاری وجود ندارد.",
  "error_no_deposit_bonus_account_deposit_restricted": "واریز به نوع حساب «پاداش بدون سپرده» مجاز نمی‌باشد.",
  "error_no_deposit_bonus_base_amount_withdrawal": "نمی‌توان مبلغ پایه «پاداش بدون سپرده» را برداشت کرد.",
  "error_no_deposit_bonus_withdraw_profit_requirement_not_met": "اگر شرط سود برآورده نشده باشد، نمی‌توان «پاداش بدون سپرده» را برداشت کرد.",
  "error_no_deposit_bonus_withdraw_exceeds_max_withdrawable_profit": "نمی‌توان بیش از حداکثر سود قابل برداشت، برداشت کرد.",
  "upgrade_required_for_live_ta": "قبل از افتتاح یک حساب معاملاتی زنده، حساب مشتری زنده و تأیید شده لازم است!",
  "error_wallet_currency_not_allowed_for_psp": "واحد پول کیف پول برای این روش پرداخت مجاز نیست.",
  "error_invalid_or_expired_otp": "خطا:  OTP ارائه شده نامعتبر یا منقضی شده است.",
  "error_max_confirmo_withdrawal_exceeded": "خطا: حداکثر درخواست برداشت confirmo معادل 20000 یورو/دلار آمریکا است",
  "error_max_anual_vload_withdrawal_exceeded": "خطا: از حداکثر مبلغ کل درخواست‌های برداشت سالانه PayRedeem بیشتر شد",
  "is_islamic_acc_message": "به منظور ایجاد حساب کاربری اسلامی، لطفاً یک درخواست به بخش پشتیبان@plexytrade.com  ارسال کنید تا به شما کمک کنند.",
  "error_invalid_voucher": "خطا: کارت الکترونیکی PayRedeem نامعتبر است یا قبلاً استفاده شده است",
  "error_blocked_voucher": "خطا: PayRedeem eCard به دلیل مشکوک بودن به کلاهبرداری مسدود شده است",
  "error_voucher_data_mismatch": "خطا: PayRedeem eCard به دلیل عدم تطابق داده‌های حساب رد شد.",
  "error_vload_voucher_below_minimum": "خطا: PayRedeem eCard کمتر از حداقل مبلغ سپرده.",
  "my_bank_has_a_swift_code": "بانک من یک کد سوئیفت دارد",
  "my_bank_does_not_have_a_swift_code": "بانک من کد سوئیفت ندارد",
  "bank_not_in_swift_network_instructions": "اگر بانک شما عضو شبکه سوئیفت نیست، لطفاً برای دریافت نقل و انتقالات الکترونیکی بین‌المللی، مشخصات زیر را از بانک کارگزار بانک خود در اختیار ما قرار دهید:",
  "correspondent_bank_name": "نام بانک کارگزار",
  "correspondent_bank_city": "شهر بانک کارگزار",
  "correspondent_bank_swift": "سوئیفت بانک کارگزار",
  "complete_profile": "پروفایل کامل",
  "document_received": "سند شما دریافت شد و توسط تیم ما بررسی خواهد شد.",
  "must_be_over_18": "برای ثبت‌نام باید بالای 18 سال سن داشته باشید.",
  "shareholders_must_be_over_18": "سهامداران برای ثبت‌نام باید بالای 18 سال سن داشته باشند.",
  "directors_must_be_over_18": "مدیران برای ثبت‌نام باید بالای 18 سال سن داشته باشند.",
  "internal_error": "خطایی رخ داد. لطفاً دوباره امتحان کنید.",
  "please_enter_your_email": "لطفا آدرس ایمیل خود را وارد کنید.",
  "reset_your_old_password": "رمز عبور قدیمی خود را بازنشانی کنید",
  "new_old_password_are_the_same": "رمز عبور جدید می تواند مشابه رمز عبور قدیمی نباشد",
  "reset_password_desc": "به دلیل انتقال سیستم، شما باید رمز عبور خود را به‌روز کنید. لطفاً از فرم زیر برای دریافت لینک بازنشانی رمز عبور استفاده کنید.",
  "eurotrader_login": "ورود Plexytrade | برای ارتقای سطح معاملات خود وارد مرکز معاملات شوید",
  "your_email_is_verified": "ایمیل شما قبلاً تایید شده است.",
  "click_here_to_login": "برای ورود اینجا کلیک کنید",
  "success_email_verified": "با موفقیت، ایمیل شما اکنون تأیید شده است!",
  "your_link_expired": "لینک شما منقضی شده است",
  "click_here_to_request_verification_email": "یک ایمیل تأیید جدید درخواست کنید",
  "a_new_verification_link_send": "یک لینک تأیید جدید به آدرس ایمیل شما ارسال شده است.",
  "invalid_request_please_check_your_link": "درخواست نامعتبر. لطفاً بررسی کنید که لینک شما صحیح باشد و دوباره امتحان کنید.",
  "invalid_verification_code": "کد تأیید نامعتبر است",
  "please_enter_amount": "لطفاً مبلغی را که می‌خواهید واریز کنید وارد نمایید",
  "bankwire_company_desc": "<p>اگر شما صاحب حساب بانکی تجاری هستید و می‌خواهید با ثبت حساب تجاری خود نزد ما از گزینه حواله بانکی الکترونیکی استفاده کنید، لطفاً مدارک زیر را از طریق ایمیل به بخش پشتیبان@plexytrade.com برای ما ارسال کنید.</p><ul><li>گواهی تاسیس / شرکت</li><li>گواهی مدیران و دبیر، در صورت وجود</li><li>گواهی سهامداران ثبت شده / مالک انحصاری.</li><li>اساسنامه</li><li>مدرک شناسایی و آدرس برای همه سهامداران ثبت‌نام شده، مالک یا مدیران ذینفع.</li><li>اثبات آدرس مربوط به شرکت.</li></ul><p>در صورتی که مدارک فوق در چنین قالبی قابل ارائه نباشد، هرگونه سند دولتی/رسمی دیگری که اطلاعات درخواستی فوق را تأیید کند می‌تواند کافی تلقی شود.</p>",
  "bankwire_noncompany_desc": "<p>این کار شما را از حساب شخصی‌تان خارج می‌کند و شما را به صفحه ثبت‌نام ما برای یک حساب شرکت هدایت می‌کند.</p><p>آیا مایل به ادامه هستید؟</p>",
  "please_enter_card_detail_and_amount": "لطفاً مشخصات کارت و مبلغی را که می‌خواهید واریز کنید وارد نمایید",
  "important": "مهم",
  "please_note_that_the_maximum_deposit_amount": "لطفاً توجه داشته باشید که حداکثر مبلغ سپرده در روز 1000 دلار برای هر کارت است.",
  "city": "شهر",
  "zip": "زیپ کد",
  "please_note_that_after_three_unsuccessful_attempts": "* لطفاً توجه داشته باشید که پس از سه (3) تلاش ناموفق، گزینه پرداخت VISA/MASTERCARD به مدت 24 ساعت در دسترس نخواهد بود.",
  "epay_pro_desc": "<p>معامله‌گر عزیز<br><br>متأسفانه، به دلیل مشکلات و محدودیت‌های فنی خاص، سپرده‌های کارت اعتباری از طریق ارائه‌دهنده ما EPayPro به طور موقت غیرفعال می‌شوند.<br><br>Visa و Mastercard در نیمه شب ساعت 23:00 به وقت گرینویچ 04/04/2018 غیرفعال می‌شوند. پس از غیرفعال شدنارائه‌دهنده پرداخت، سپرده‌ها به طور موقت غیرفعال می‌شوند. اگرچه ارائه‌دهندگان پرداخت ما چارچوب زمانی دقیقی را برای عدم پاسخگویی در اختیار ما قرار نداده‌اند، اما ما در حال کار بر روی اجرای جایگزین ها در اسرع وقت هستیم.<br><br>ما با ارائه‌دهندگان خود برای حل مشکلات فعلی همکاری می کنیم تا در آینده، حساب‌ها بتوانند بدون تاخیر پردازش سپرده‌ها را از سر بگیرند.<br><br>در صورت داشتن هرگونه سوال، با تیم پشتیبانی ما تماس بگیرید.</p>",
  "provider_redirect": "شما به {provider} هدایت می‌شوید.",
  "lydiax_note": "حداقل سپرده 50 دلار آمریکا/یورو است",
  "lydiax_note1": "اگر کمتر از 50 دلار آمریکا/یورو به ارزش {crypto} واریز کنید، باید یک ایمیل به بخش پشتیبان@plexytrade.com,  ارسال نمایید و با ارائه آدرس {crypto} که می‌خواهید به آن بازپرداخت شود، درخواست بازپرداخت کنید.",
  "lydiax_note2": "لطفاً مبلغ {crypto} را که می‌خواهید واریز کنید وارد نمایید تا تخمینی از آنچه به دست خواهید آورد ببینید. در نظر داشته باشید قیمت‌های زیر فقط برای زمانی که درخواست شده‌اند معتبر هستند و هر لحظه قابل تغییر می‌باشند.",
  "lydiax_note3": "ما یک آدرس سپرده برای شما ایجاد می‌کنیم تا از آن استفاده کنید.",
  "lydiax_note4": "لطفا برای واریز {crypto} از آدرس زیر استفاده کنید.",
  "lydiax_note5": "از این پس، این آدرس منحصر به فرد برای واریزی‌ها شما به {crypto} استفاده خواهد شد.",
  "lydiax_required_address": "اگر کیف پول شما به آدرس و برچسب جداگانه نیاز دارد، استفاده کنید از:",
  "crypto_note": "توجه",
  "crypto_note_line_1": "این روش پرداخت فقط به یورو عمل می کند!",
  "crypto_note_line_2": "واریزی‌هایی که به واحد پول دیگری ارسال می‌شوند، مشمول هزینه‌های تبدیلی خواهند بود که شرکت ما نمی‌تواند آن را پوشش دهد.",
  "crypto_note_line_3": "برای جلوگیری از هزینه‌های غیرمنتظره، لطفاً روش‌های سپرده جایگزین را در نظر بگیرید.",
  "tag": "برچسب",
  "lydiax_enter_deposit": "لطفاً ارز دیجیتالی را که می‌خواهید واریز کنید وارد نمایید",
  "lydiax_withdrawal_limit_error": "از حد برداشت فراتر رفت. مبلغ برداشت درخواستی از حداکثر سقف 50,000 دلار تجاوز می‌کند. لطفاً با مبلغ کمتری دوباره امتحان کنید.",
  "lydiax_please_select": "لطفاً انتخاب کنید",
  "create_address": "آدرس ایجاد کنید",
  "please_note": "لطفاً توجه کنید:",
  "price_estimate": "برآورد قیمت:",
  "check_price": "قیمت را بررسی کنید",
  "vload_please_enter_voucher": "لطفا پین کارت الکترونیکی PayRedeem خود را وارد کنید",
  "vload_minimum": "<p>(حداقل مقدار قابل قبول کارت الکترونیکی <b>50</b> یورو یا دلار آمریکا است)</p>",
  "vload_vouchers": "من باید کارت‌های الکترونیکی PayRedeem را خریداری کنم",
  "vload_note": "پرداخت بدون دردسر را از طریق Visa/MasterCard و همچنین حواله الکترونیکی بانکی انجام دهید.<br />درباره PayRedeem بیشتر بیاموزید یا کارت الکترونیکی [یورو/دلار آمریکا] را از اینجا بخرید <a href=\"https://app.payredeem.com/buy\" target=\"_blank\" class=\"font-semibold\">app.payredeem.com/buy</a>.",
  "vload_note1": "برای اینکه بتوانید با PayRedeem واریز کنید، ابتدا باید حساب شما تأیید شود!",
  "reason": "دلیل:",
  "attempts": "تلاش {attempts} از {maxAttempts}،",
  "two_more_remaining": "دو (2) تلاش دیگر باقی مانده است",
  "one_more_remaining": "فقط یک (1) تلاش باقی مانده است",
  "please_contact_bank": "<br />لطفاً با بانک صادرکننده کارت تماس بگیرید.<br /> ممکن است کارت شما برای تراکنش‌های خارج از کشور مسدود شود",
  "edata_success_note": "لطفاً توجه داشته باشید که ممکن است به دلیل تراکنش بین‌المللی، کارمزد اضافی برای واریز شما وجود داشته باشد که توسط بانک صادرکننده شما دریافت می‌شود. این کارمزد هزینه کمی خواهد بود، اما از بانکی به بانک دیگر متفاوت است. <br><br>این تراکنش با کارت اعتباری در صورت‌حساب شما به‌عنوان «صورت‌حساب فناوری هوشمند»، «صورت‌حساب تجاری» یا «صورت‌حساب رسانه‌های تجاری» ظاهر می‌شود.",
  "you_can_try": "می‌توانید دوباره امتحان کنید",
  "here": "اینجا",
  "safe_charged_success_message": "سپرده شما ظرف 2 روز کاری توسط بخش پشتیبان ما پردازش می‌شود.",
  "thank_you": "متشکریم!",
  "your_deposit_successful": "واریز شما موفقیت‌آمیز بود",
  "card_redirect": "برای وارد کردن کارت اعتباری و مشخصات شخصی خود هدایت می‌شوید.",
  "bank_wire_withdrawal_note": "در صورت رد حواله بانکی از طرف بانک دریافت‌کننده به دلیل ارائه اطلاعات اشتباه بانکی توسط مشتری، هزینه‌های مختلف حساب بانکی که توسط بانک‌های مربوطه دریافت می‌شود، قبل از اعتبار کیف پول مشتری، از مبلغ برداشت درخواستی کسر می‌شود.",
  "please_enter_amount_withdraw": "لطفاً مبلغی را که می خواهید برداشت کنید وارد کنید",
  "bitcoin_note": "لطفاً ارز دیجیتال خود را انتخاب کنید و مبلغی را که می خواهید برداشت کنید، وارد نمایید",
  "otp": "رمز یکبار مصرف (OTP)",
  "otp_send_email": "OTP را به ایمیل ارسال کنید",
  "otp_additional_text": "OTP فوق، برای درخواست فعلی برداشت Bitcoin است که شما انجام می‌دهید",
  "otp_new_password": "یک رمز عبور یکبار مصرف جدید (OTP) به ایمیل شما ارسال شده است",
  "otp_error": "خطا - OTP ارسال نشد",
  "lydiax_withdrawal_note": "لطفاً مبلغ {currency} که می‌خواهید برداشت کنید را وارد نمایید تا تخمینی از {crypto} که به دست خواهید آورد مشاهده کنید. در نظر داشته باشید قیمت‌های زیر فقط برای زمانی که درخواست شده‌اند معتبر هستند و هر لحظه قابل تغییر می‌باشند.",
  "enter_currency_amount": "مبلغ {currency} را برای بررسی تخمین وارد کنید",
  "lydiax_withdrawal_additional_note": "OTP فوق برای درخواست برداشت فعلی Crypto است که شما انجام می‌دهید",
  "estimated": "تخمینی:",
  "please_enter_your_crypto_address": "لطفاً آدرس و مبلغ {crypto} را که می‌خواهید برداشت کنید وارد کنید",
  "select_cryptocurrency": "ارز دیجیتال را انتخاب کنید",
  "request_amount": "مبلغ درخواستی",
  "amount_changes": "مبلغ قابل دریافت (شامل هزینه)",
  "operation_failed": "عملیات ناموفق بود! درخواست شما نامعتبر بود.",
  "operation_failed_error": "عملیات ناموفق بود! خطایی در پردازش این درخواست وجود دارد.",
  "declarations": "اعلامیه‌ها",
  "please_check_forms_errors": "لطفاً فرم‌ها را از لحاظ وجود خطا بررسی کنید!",
  "profile_succesfully_completed": "پروفایل با موفقیت تکمیل شد",
  "empty_country_code": "کد کشور خالی است، لطفاً صفحه را ریفرش کرده و دوباره امتحان کنید.",
  "invalid_country_code": "کد کشور نامعتبر است، لطفاً صفحه را ریفرش کرده و دوباره امتحان کنید.",
  "prev": "قبلی",
  "previous": "قبلی",
  "complete": "کامل",
  "please_select_an_option": "لطفاً برای هر فیلد یک گزینه انتخاب کنید!",
  "swap": "سواپ",
  "buy": "خرید",
  "sell": "فروش",
  "sums": "مبالغ",
  "net_total": "کل خالص",
  "deposit_page_desc": "اکنون ثبت‌نام کنید و یک کوپن الکترونیکی به ارزش 10 یورو/دلار آمریکا به طور رایگان دریافت کنید",
  "deposit_page_desc1": "کوپن الکترونیکی پاداش 10 یورو/دلار آمریکا زمانی فعال می‌شود که شما",
  "create_new_account": "یک حساب کاربری جدید کنید",
  "create_new_master_account": "یک حساب مستر جدید ایجاد کنید",
  "successfully_purchase_vload": "کارت الکترونیکی PayRedeem با هر ارزشی را با موفقیت خریداری و بازخرید کنید",
  "upgrade_to_tier_2": "به سطح 2 ارتقا دهید",
  "register_now": "هم‌اکنون ثبت‌نام کنید",
  "back": "پشت",
  "my_card_desc": "در اینجا می توانید تمام کارت‌هایی که برای پرداخت استفاده کرده‌اید به همراه وضعیت فعلی‌شان در سیستم ما را مشاهده کنید.",
  "you_have_no_card": "هنوز هیچ واریز کارتی انجام نداده‌اید.",
  "card_no": "شماره کارت",
  "last_used": "آخرین استفاده",
  "risk_warning": "هشدار ریسک",
  "drop_files_upload": "فایل‌ها را اینجا رها کنید تا بارگذاری شوند",
  "date_until": "تاریخ تا",
  "copy": "کپی",
  "cvv": "CVV",
  "please_enter_crypto_amount": "مبلغ {crypto} را وارد کنید",
  "vload_pin": "پین PayRedeem",
  "deposit_info": "ارائه‌دهنده پرداخت در حال حاضر با مشکلات فنی مواجه است و واریز‌های مستقیم Visa/Mastercard در حال حاضر در دسترس نیستند. از طرف دیگر، اگر مایل به واریز با استفاده از Visa/Mastercard هستید، می‌توانید از گزینه`PayRedeem` استفاده کنید",
  "id": "ID",
  "pamm_modal_error": "ورودی نامعتبر برای نام نمایشی. لطفاً خالی نگذارید و بیش از 32 کاراکتر وارد نکنید",
  "pamm_modal_error1": "مقدار نامعتبر برای حداقل سپرده وارد شده است. لطفاً یک عدد کامل مثبت زیر 1,000,000 وارد کنید.",
  "error_missing_required_field": "خطا، عدم تکمیل فیلد الزامی",
  "records": "سوابق",
  "gateway": "دروازه",
  "region": "منطقه",
  "wallet_currency": "واحد ارز کیف پول",
  "join": "پیوستن",
  "demo_account": "حساب آزمایشی",
  "page_title_demo": "باز کردن حساب آزمایشی",
  "what_is_demo": "حساب معاملاتی آزمایشی چیست؟",
  "demo_explanation": "حساب معاملاتی آزمایشی رایگان ما راهی عملی برای شماست تا بتوانید پلتفرم را کاوش کنید، تجارت کنید و استراتژی‌های مختلف را به طور کاملاً رایگان امتحان نمایید. با این کار، می توانید قبل از ورود به هر بازار، یک دید کلی از آن بازار بدست بیاورید.",
  "title_404": "مشکلی رخ داده است",
  "text_line_1_404": "متأسفانه صفحه‌ای که به دنبال آن بودید وجود ندارد.",
  "text_line_2_404": "خوشبختانه شما همچنان می توانید با Plexytrade معامله کنید.",
  "button_404": "بازگشت به عقب",
  "remember_me": "مرا به خاطر بسپار",
  "risk_sentence": "برای آگاهی از نحوه استفاده ما از داده‌های شما، لطفاً این موارد را بررسی کنید",
  "video_courses": "دوره‌های ویدیویی",
  "ebooks": "کتاب‌های الکترونیکی",
  "knowledge_and_experience": "دانش و تجربه",
  "economic_profile": "مشخصات اقتصادی",
  "product_governance": "حاکمیت محصول",
  "select_an_answer": "یک پاسخ را انتخاب کنید",
  "declarations_q1": "آیا در حال حاضر، یا تا به حال، درگیر فعالیت‌های مجرمانه بوده‌اید یا اتهامات جنایی معوقی علیه شما در دادگاه وجود دارد؟",
  "declarations_q2": "آیا شما یا یکی از خویشاوندان یا نزدیکانتان یک مقام سیاسی هستید؟ اشخاصی که مقام سیاسی دارند، افراد حقیقی هستند که در 12 ماه گذشته وظایف عمومی برجسته‌ای را بر عهده دارند یا به آنها سپرده شده است، از جمله سران کشورها یا دولت‌ها، سیاستمداران ارشد، مقامات ارشد دولت، مقامات قضایی یا نظامی، مدیران ارشد شرکت‌های دولتی، مقامات مهم احزاب سیاسی.",
  "declarations_q3": "آیا شما یک شهروند ایالات متحده یا مقیم ایالات متحده برای اهداف مالیاتی هستید؟",
  "live_account": "حساب زنده",
  "data_policy_text": "داده‌های شما نزد ما محفوظ است و می‌توانید در هر زمان اشتراک خود را لغو کنید. با کلیک بر روی زیر، تأیید می‌کنید که <a href=\"{TERMS_CONDITIONS_URL}\" target=\"_blank\">شرایط و قوانین</a> و <a href=\"{RISK_DISCLOSURE_URL}\" target=\"_blank\"> افشای ریسک</a>را خوانده و پذیرفته‌ایذ و موافقت می‌کنید که ما اطلاعات شخصی شما را ذخیره و پردازش کنیم.",
  "partner_account": "حساب شریک",
  "privacy_policy": "خطمشی حفظ حریم خصوصی",
  "live_account_title": "ما برای شروع شما بسیار هیجان زده هستیم!<br/>به ما بگویید، آیا به عنوان یک شخص عضو می شوید یا به عنوان یک شرکت؟",
  "two_factor_register": "برای تأیید شماره تلفنتان، باید یک رمز عبور یکبار مصرف برای شما ارسال کنیم. پس از ارسال، لطفاً کدی که دریافت کرده‌اید را در فیلدی که در زیر ظاهر می‌شود وارد کنید.",
  "two_factor_login": "به شماره تلفنی که با مشخصات کاربری شما ثبت شده پیامی ارسال شده است. لطفاً کد را در فیلد زیر وارد کنید تا ورود خود را تکمیل نمایید.",
  "deposits": "واریزی‌ها",
  "withdrawals": "برداشت‌ها",
  "see_more": "بیشتر ببینید",
  "all": "همه",
  "create_account": "ایجاد حساب",
  "account_balance": "موجودی حساب",
  "trading_account_currency": 'ارز حساب معاملاتی',
  "last_five_days": "5 روز گذشته",
  "learn_more": "بیشتر بدانید",
  "welcome_bonus": "پاداش نقدی",
  "welcome_bonus_breakline": "<span class=\"text-green\">120%</span><br />پاداش<br />نقدی",
  "analytics": "تجزیه و تحلیل",
  "transactions": "معاملات",
  "settings": "تنظیمات",
  "platforms": "پلتفرم‌ها",
  "select_operating_system": "سیستم عامل را انتخاب کنید",
  "select_platform": "پلتفرم را انتخاب کنید",
  "trading": "تجارت",
  "mng_your_ac": "حساب کاربری خود را مدیریت کنید",
  "almost_finished": "کار شما تقریباً تمام شده است. راه‌اندازی حساب را تمام کنید",
  "acc_approved": "حساب تأیید شده است",
  "create_trading_account": "ایجاد حساب تجاری",
  "select_type_of_account": "نوع حساب را انتخاب کنید",
  "select_balance": "موجودی را انتخاب کنید",
  "please_select_all_options": "لطفاً همه گزینه‌ها را انتخاب کنید",
  "type_of_account": "نوع حساب",
  "starting_balance": "موجودی شروع",
  "error_message_create_account": "مشکلی پیش آمده است. لطفاً دوباره امتحان کنید.",
  "leverage": "لوریج",
  "would_like_to_receive_bonus": "من مایل به دریافت 120% پاداش نقدی هستم و با <a class=\"text-blue\" href=\"https://plexytrade.com/assets/files/fa/bonus-terms-and-conditions-1.pdf?v=1.8\" target=\"_blank\">شرایط و ضوابط پاداش</a> موافقم.",
  "receive_bonus": "120% پاداش نقدی دریافت کنید",
  "need_help": "به کمک نیاز دارید؟",
  "forgot_password_text": "رمز عبور خود را فراموش کرده‌اید؟ لطفا آدرس ایمیل حساب خود را در زیر ارسال کنید. سپس یک ایمیل با لینکی برای تنظیم مجدد رمز عبور دریافت خواهید کرد.",
  "id_front": "احراز هویت/ جلو",
  "id_back": "احراز هویت/ پشت",
  "cc_front": "اثبات کارت اعتباری/دبیت/ جلو",
  "cc_back": "اثبات کارت اعتباری/دبیت/ پشت",
  "address_proof": " اثبات آدرس",
  "other": "افزودن یک سند (اختیاری)",
  "id_back_info": "",
  "cc_front_info": "",
  "cc_back_info": "",
  "address_proof_info": "",
  "other_info": "*می‌توانید تا 4 سند دیگر اضافه کنید.",
  "id_front_instructions": "لطفاً شناسنامه، گذرنامه یا گواهینامه رانندگی خود را بارگذاری کنید.",
  "id_back_instructions": "لطفاً شناسنامه، گذرنامه یا گواهینامه رانندگی خود را بارگذاری کنید.",
  "cc_front_instructions": "لطفاً کارت اعتباری یا دبیت خود را بارگذاری کنید.",
  "cc_back_instructions": "لطفاً کارت اعتباری یا دبیت خود را بارگذاری کنید.",
  "address_proof_instructions": "لطفاً قبض خدمات، صورتحساب بانکی، صورتحساب وام مسکن و صورتحساب وام خود را بارگذاری کنید.",
  "other_instructions": "سایر اسنادی که توسط بخش تأیید ما درخواست شده است.",
  "documents_must_contain_all_of_the_following_details": "مدارک باید شرایط زیر را داشته باشند:",
  "id_front_req1": "کپی کامل سند که هر 4 گوشه مشخص باشد.",
  "id_front_req2": "سند باید رنگی باشد",
  "id_front_req3": "نام و نام خانوادگی کامل",
  "id_front_req4": "یک شماره شناسایی منحصر به فرد",
  "id_front_req5": "تاریخ تولد شما",
  "id_front_req6": "محل تولد شما",
  "id_front_req7": "یک عکس واضح",
  "id_front_req8": "ملیت شما",
  "id_front_req9": "تاریخ صدور",
  "id_front_req10": "تاریخ انقضا",
  "id_back_req1": "کپی کامل سند که هر 4 گوشه مشخص باشد.",
  "id_back_req2": "سند باید رنگی باشد",
  "id_back_req3": "نام و نام خانوادگی کامل",
  "id_back_req4": "یک شماره شناسایی منحصر به فرد",
  "id_back_req5": "تاریخ تولد شما",
  "id_back_req6": "محل تولد شما",
  "id_back_req7": "یک عکس واضح",
  "id_back_req8": "ملیت شما",
  "id_back_req9": "تاریخ صدور",
  "id_back_req10": "تاریخ انقضا",
  "cc_front_req1": "کپی کامل سند که هر 4 گوشه مشخص باشد.",
  "cc_front_req2": "نام روی کارت: کارتی که استفاده می‌کنید باید صرفاً به نام شما صادر شود. هر گونه واریزی که با استفاده از کارت متعلق به شخص ثالث انجام شود، بلافاصله مسترد خواهد شد.",
  "cc_front_req3": "تاریخ انقضا: حداقل 3 ماه.",
  "cc_front_req4": "شماره کارت: هشت رقم وسط کارت باید با یک تکه کاغذ پوشانده شود. فقط چهار رقم اول و چهار رقم آخر باید قابل مشاهده باشد.",
  "cc_back_req1": "کپی کامل سند که هر 4 گوشه مشخص باشد.",
  "cc_back_req2": "نام روی کارت: کارتی که استفاده می‌کنید باید صرفاً به نام شما صادر شود. هر گونه واریزی که با استفاده از کارت متعلق به شخص ثالث انجام شود، بلافاصله مسترد خواهد شد.",
  "cc_back_req3": "تاریخ انقضا: حداقل 3 ماه.",
  "cc_back_req4": "شماره کارت: هشت رقم وسط کارت باید با یک تکه کاغذ پوشانده شود. فقط چهار رقم اول و چهار رقم آخر باید قابل مشاهده باشد.",
  "cc_back_req5": "کد CVV: لطفاً از یک تکه کاغذ برای پوشاندن کد CVV استفاده کنید.",
  "cc_back_req6": "کارت باید امضا شود.",
  "address_proof_req1": "کپی کامل سند که هر 4 گوشه در آن مشخص باشد",
  "address_proof_req2": "نام و نام خانوادگی کامل",
  "address_proof_req3": "آدرس فیزیکی شما، مطابق با آدرسی که هنگام ثبت‌نام برای حساب کاربری استفاده کرده‌اید",
  "address_proof_req4": "تاریخ صدور (حداکثر 6 ماه)",
  "address_proof_req5": "باید قبض خدمات (برق، آب، تلفن، گاز/انرژی، مالیات شورا/قبض‌های بهداشتی) یا صورت حساب بانکی باشد",
  "address_proof_req6": "کدپستی کامل باید نشان داده شود.",
  "other_req1": "کپی کامل سند که هر 4 گوشه در آن مشخص باشد",
  "click_add_another_doc": "برای افزودن فضای بارگذاری برای یک سند دیگر، کلیک کنید. می‌توانید تا 4 سند دیگر اضافه کنید.",
  "btn_new_slot": "فضای جدید",
  "additional_docs_email": "* اسناد بیشتر را می‌توان از طریق ایمیل به بخش پشتیبان@plexytrade.com ارسال کرد",
  "doc_approved": "سند شما تأیید شده است.",
  "opt_in_options": "انتخاب‌های داوطلبانه (نمونه اولیه)",
  "cpa": "CPA",
  "copied": "با موفقیت کپی شد!",
  "try_before_u_trade": "قبل از معامله امتحان کنید",
  "client_details": "جزئیات مشتری",
  "select_trading_account": "حساب معاملاتی را انتخاب کنید",
  "total_commission": "کل کمیسیون",
  "banner": "بنر",
  "vps": "VPS",
  "hosting": "هاستینگ",
  "vps_text": "برای دسترسی بدون وقفه به پلتفرم معاملاتی خود همراه با ایمنی، پایداری و سرعت بی‌نظیر، هاست قوی VPS و بدون قطعی دریافت کنید.",
  "order_now": "اکنون سفارش دهید",
  "vps_packages": "بسته‌های VPS",
  "choose_vps_package": "لطفاً یک بسته VPS را انتخاب کنید",
  "standard": "استاندارد",
  "silver": "نقره‌ای",
  "platinum": "پلاتینیوم",
  "per_month": "در هر ماه",
  "standard_text": "یا <b>رایگان</b><br />با {standard_min_deposit} حداقل سپرده",
  "gold_text": "یا <b>رایگان</b><br />با {gold_min_deposit} حداقل سپرده",
  "advance_text": "یا <b>رایگان</b><br />با {advance_min_deposit} حداقل سپرده",
  "per_live_trading_account": "در هر حساب معاملاتی زنده",
  "terms_and_conditions_apply": "شرایط و ضوابط اعمال می‌شود",
  "gold": "طلایی",
  "most_popular": "محبوب‌ترین",
  "advance": "پیشرفته",
  "equivalent": "معادل",
  "something_went_wrong": "مشکلی پیش آمده است. لطفاً دوباره امتحان کنید.",
  "verify": "تأیید کنید",
  "choose_trading_account": "لطفاً حساب معاملاتی را انتخاب کنید",
  "vps_description": "سرویس VPS بر اساس حساب معاملاتی زنده در دسترس است. برای هر بسته VPS اضافی، تعداد مساوی حساب معاملاتی زنده مورد نیاز است.",
  "agree_to_terms": "من با <a href=\"{URL}\" target=\"_blank\" class=\"font-semibold underline\">شرایط و ضوابط</a> موافق هستم",
  "renewal_on": "تمدید در",
  "vps_hosting_service": "خدمات هاستینگ VPS",
  "load_more": "بارگذاری بیشتر",
  "please_select_payment_method": "لطفاً روش پرداخت را انتخاب کنید",
  "lydiax": "Crypto",
  "select_crypto_network": "لطفاً شبکه {crypto} خود را انتخاب کنید",
  "vload": "PayRedeem",
  "popular_deposits": "واریزی‌های محبوب",
  "popular_withdrawals": "برداشت‌های محبوب",
  "my_settings": "پروفایل Plexy",
  "personal_details": "جزئیات شخصی",
  "profile": "پروفایل",
  "pamm_account": "PAMM",
  "help": "راهنمایی",
  "transfer_history": "تاریخچه انتقال",
  "total_bonuses_granted": "مجموع پاداش‌های اعطا شده",
  "total_bonuses_converted": "کل پاداش تبدیل شده",
  "bonuses": "پاداش‌ها",
  "pamm_dashboard": "داشبورد PAMM",
  "pamm_text_line_1": "آمار Pamm در منطقه مشتری PAMM ما در دسترس است.",
  "pamm_text_line_2": "اگر حساب PAMM دارید، لطفاً روی لینک زیر کلیک کنید تا هدایت شوید و از اعتبار حساب PAMM خود برای ورود استفاده کنید.",
  "please_enter_amount_transfer": "لطفاً مبلغ مورد نظر برای انتقال را وارد کنید",
  "live": "زنده",
  "demo": "آزمایشی",
  "no_available_live_account": "شما یک حساب معاملاتی زنده در دسترس ندارید. <span class=\"underline cursor-pointer font-bold\"><br />برای ایجاد این حساب اینجا را کلیک کنید</span>.",
  "check_your_options": "برای ایجاد حساب کاربری، مشخصات خود را تأیید کنید",
  "okay": "بسیار خب",
  "hard_disk": "هارد دیسک",
  "included": "شامل می‌شود",
  "quick_links": "لینک‌های سریع",
  "vps_hosting_service_dashboard": "<span class=\"text-green\">هاستینگ</span>VPS<br />",
  "vps_hosting_service_dashboard_mobile": "<span class=\"text-green\">هاستینگ</span>VPS",
  "hello": "سلام",
  "shareholders_directors": "سهامداران و مدیران",
  "alpha_generation_plugin": "پلاگین<br />نسل آلفا",
  "daily_market_newsletter": "خبرنامه<br />بازار<br />روزانه",
  "no_records": "بدون سابقه",
  "subscribe": "مشترک شوید",
  "sign_out": "خروج از سیستم",
  "account_approved": "حساب تأیید شد",
  "verified_account": "حساب تأیید شده",
  "pending_account": "حساب معلق",
  "privacy": "حریم خصوصی",
  "reset": "بازنشانی کنید",
  "change_password_note": "این تغییر رمز عبور روی رمزهای عبور حساب تجاری شما تأثیری نخواهد داشت. لطفاً با استفاده از گزینه تغییر رمز عبور در زیر نماد چرخ دنده، برای تغییر رمزهای عبور حساب تجاری خود از لیست حساب بازدید کنید.",
  "newsletter_sub_full": "شما آماده اید! صندوق پستی خود را برای دریافت خبرنامه بازار روزانه بررسی کنید.",
  "newsletter_sub_partial": "از ثبت‌نام شما سپاسگزاریم! برای شروع دریافت خبرنامه بازار روزانه به {amount} واریزی بیشتر نیاز دارید.",
  "logoloader_newsletter_subscribing": "مشترک شوید...",
  "statistics": "آمار",
  "requirements": "الزامات",
  "select_file": "فایل را انتخاب کنید",
  "vload_vouchers_text": "اگر نیاز به خرید کارت‌های الکترونیکی PayRedeem دارید، لطفاً به <a href=\"https://payredeem.com/\" target=\"_blank\" class=\"font-semibold\">payredeem.com</a> مراجعه کنید",
  "vload_text_1": "با چندین ارز، کارت های اعتباری/دبیت، حواله های بانکی، ارزهای دیجیتال و پرداخت های محلی پرداخت کنید.",
  "vload_text_2": "یک حساب کاربری در <a href=\"https://payredeem.com/\" target=\"_blank\" class=\"font-semibold\">payredeem.com</a> ایجاد کنید",
  "vload_text_3": "روی خرید کلیک کنید و وب‌سایت را انتخاب کنید.",
  "vload_text_4": "با روش پرداخت دلخواه خود پرداخت کنید.",
  "vload_text_5": "پین کارت الکترونیکی را بازخرید کنید.",
  "vload_text_6": "به کمک نیاز دارید؟ به <a href=\"https://help.payredeem.com/\" target=\"_blank\" class=\"font-semibold\">help.payredeem.com</a> بروید و 24/7 از طریق چت، ایمیل یا تلفن با ما تماس بگیرید.",
  "wd_vload_text_1": "هنگامی که برداشت را آغاز می‌کنید، کارت‌های الکترونیکی Payout در حساب PayRedeem شما صادر می‌شود. می‌توانید از این کارت‌های الکترونیکی در هر فروشگاهی که PayRedeem را می‌پذیرد استفاده کنید، یا می‌توانید آن‌ها را در حساب بانکی یا کیف پول ارزهای دیجیتال خود نقد کنید.",
  "wd_vload_text_2": "می‌توانید کارت‌های الکترونیکی Payout خود را در<a href=\"https://app.payredeem.com/payouts\" target=\"_blank\" class=\"font-semibold\">داشبورد پروفایل PayRedeem</a> پیدا کنید.",
  "wd_vload_text_3": "در برخی موارد، ممکن است از شما خواسته شود که آدرس Payout خود را ارائه دهید. به راحتی می‌توانید آن را در پروفایل خود، در بخش Payouts (پرداخت‌ها) پیدا کنید.",
  "country_of_residence": "کشور محل اقامت",
  "firstname_placeholder": "همان چیزی که در سند هویتی شما عنوان شده است",
  "lastname_placeholder": "همان چیزی که در سند هویتی شما عنوان شده است",
  "personal_and_address_info": "اطلاعات شخصی و آدرس",
  "address_placeholder": "نام خیابان، خانه، شماره آپارتمان",
  "gender_other": "سایر",
  "optional": "اختیاری",
  "email_address": "آدرس ایمیل",
  "trading_account_info": "اطلاعات حساب معاملاتی",
  "trading_platform": "پلتفرم معاملاتی",
  "account_currency": "واحد پول حساب",
  "marketing_checkbox": "من می‌خواهم اخبار شرکت، به‌روزرسانی محصولات و تبلیغات را دریافت کنم.",
  "invalid_email": "لطفاً یک آدرس ایمیل معتبر وارد کنید.",
  "launch_webtrader": "راه‌اندازی Webtrader",
  "accept": "من توافق‌نامه شرکا را ",
  "partners_agreement": "خوانده‌ام و پذیرفته‌ام",
  "recaptchaText": "این سایت توسط reCAPTCHA محافظت می‌شود و <a class=\"font-bold\" href=\"https://policies.google.com/privacy\">خط مشی حفظ حریم خصوصی </a> و <a class=\"font-bold\" href=\"https://policies.google.com/terms\">شرایط خدمات </a> Google اعمال می‌شود.",
  "verify_your_email": "ایمیل خود را تأیید کنید",
  "verify_your_email_description": "یک ایمیل با کد اعتبارسنجی به آدرس ایمیل ثبت شده شما ارسال شد. برای تکمیل ثبت‌نام، لطفاً آدرس ایمیل خود را با وارد کردن کد 6 رقمی زیر تأیید کنید.",
  "code_not_recieved": "کد را دریافت نکردید؟",
  "resend": "ارسال مجدد",
  "confirm_email": "ایمیل تأیید",
  "contact_us": "با ما تماس بگیرید",
  "try_again": "دوباره امتحان کنید",
  "try_again_description": "کدی که وارد کردید صحیح نیست",
  "attempts_left": "تلاش باقی مانده است",
  "resend_code": "دوباره کد را ارسال کنید",
  "try_again_expired": "لطفاً دوباره امتحان کنید! کدی که وارد کردید منقضی شده است.",
  "verification_failed": "تأیید ناموفق بود",
  "verification_failed_header": "تأیید آدرس ایمیل انجام نشد!",
  "verification_failed_description": "آیا در تأیید ایمیل خود مشکل دارید؟",
  "bonus_120": "پاداش 120%",
  "no_bonus": "بدون پاداش",
  "cash_bonus_120": "120% پاداش نقدی",
  "reg_form_step_1_individual": "اطلاعات شخصی",
  "reg_form_step_1_company": "اطلاعات شرکت",
  "reg_form_step_2_individual": "اطلاعات شخصی و آدرس",
  "reg_form_step_2_company": "سهامداران و مدیران",
  "reg_form_step_3_individual": "اطلاعات حساب معاملاتی",
  "reg_form_step_3_company": "ثبت نام و مشخصات پروفایل",
  "reg_disclaimer_title": "حساب معاملاتی آزمایشی چیست؟",
  "reg_disclaimer_text": "حساب معاملاتی آزمایشی رایگان ما راهی عملی برای شماست تا بتوانید پلتفرم را کاوش کنید، تجارت کنید و استراتژی‌های مختلف را امتحان نمایید.",
  "trading_account_type": "نوع حساب معاملاتی",
  "company_name_placeholder": "همان چیزی که در سند ثبت شرکت شما عنوان شده است",
  "referal_links": "لینک‌های ارجاع",
  "registration_link": "صفحه ثبت‌نام",
  "website_home_page": "صفحه اصلی وب‌سایت",
  "bonus_page": "صفحه پاداش",
  "internal_transfer_note": "شما نمی‌توانید بین 2 حساب تجاری انتقال دهید.",
  "upgrade_to_live_text": "برای ادامه هر تراکنش، ابتدا باید به «زنده» ارتقا دهید و حساب خود را تأیید کنید.",
  "upgrade_to_live": "ارتقا به زنده",
  "upload_your_documents_text": "برای ادامه هر تراکنش، ابتدا باید مدارک خود را بارگذاری کرده و حسابتان تأیید شود.",
  "upload_your_documents": "اسناد خود را بارگذاری کنید",
  "under_maintenance": "تحت تعمیر و نگهداری برنامه ریزی شده",
  "under_maintenance_text": "از این بابت عذرخواهی می‌کنیم.<br>به زودی برمی‌گردد.",
  "processed": "پردازش شده",
  "cancelled": "لغو شده",
  "pending": "در انتظار",
  "requested": "درخواست شده",
  "approved": "تأیید شده",
  "declined": "رد شده",
  "mismatch": "عدم تطابق",
  "sending": "در حال ارسال",
  "lydiax_deposit_note": "لطفاً اطمینان حاصل کنید که همیشه روی دکمه ایجاد آدرس کلیک کنید و آدرس ایجاد شده را قبل از واریز وجه جدید در PLEXYTRADE بررسی کنید.",
  "important_note": "نکته مهم",
  "withdrawal_note": "لطفاً توجه داشته باشید که برای اینکه بتوانید درخواست برداشت را ارسال کنید، ابتدا باید حساب شما کاملاً تأیید شود.",
  "failed_account_creating_message": "ایجاد حساب معاملاتی ناموفق بود: ",
  "english": "انگلیسی",
  "spanish": "اسپانیایی",
  "italian": "ایتالیایی",
  "chinese": "چینی",
  "opened_a": "را باز کرد",
  "position_on": "موقعیت در",
  "at": "در",
  "likes": "لایک‌ها",
  "sub_status_check_fail": "بررسی وضعیت اشتراک ناموفق بود: ",
  "news_sub_req_fail": "درخواست اشتراک خبرنامه انجام نشد: ",
  "lang_settings_saved": "تنظیمات زبان ذخیره شد",
  "unable_save_lang_settings": "تنظیمات زبان ذخیره نشد",
  "archived": "بایگانی شد",
  "welcome_to_plexytrade": "به Plexytrade خوش آمدید",
  "select_demo_live": "لطفاً یک نوع حساب را انتخاب کنید",
  "export": "صادر کردن",
  "success_vps_order": "VPS   جدید با موفقیت درخواست شد.",
  "success": "موفقیت",
  "gains_prc": " سود %",
  "open_p_l": "سود و زیان باز",
  "monthly_avg": "میانگین ماهانه",
  "pamm_name_or_account_id": "نام یا شناسه حساب",
  "invest_under_master_account": "سرمایه‌گذاری تحت حساب مستر",
  "view_graph": "مشاهده نمودار",
  "pamm_master_locks_withdrawals": ". برداشت‌ها را در طول معاملات باز محدود می‌کند  PAMM این مستر ",
  "i_accept_the_terms_of_service": "من <a href=\"{URL}\" target=\"_blank\" class=\"text-blue\">شرایط خدمات</a> را می‌پذیرم.",
  "enter_private_code": "کد خصوصی را وارد کنید",
  "additional_terms_apply": "شرایط اضافی اعمال می‌شود، لطفاً اگر مطلع هستید، کادر را علامت بزنید ",
  "select_a_pamm_account_to_attach": " برای پیوستن انتخاب کنید  PAMMیک حساب ",
  "create_and_attach_new_pamm_account": " جدید PAMM  ایجاد و پیوستن حساب ",
  "create_and_attach": "ایجاد و پیوستن",
  "max_drawdown": "حداکثر افت سرمایه",
  "all_time": "تمام زمان‌ها",
  "last_month": "ماه گذشته",
  "last_week": "هفته گذشته",
  "last_3_days": "سه روز گذشته",
  "create_pamm_question": "آیا مطمئن هستید که می‌خواهید یک حساب جدید ایجاد کنید و آن را تحت این مستر پیوست کنید؟",
  "attach_pamm_question": "آیا مطمئن هستید که می‌خواهید حساب انتخاب شده را به این مستر پیوست کنید؟",
  "additional_terms_check": "با ادامه دادن، شما تأیید می‌کنید که تحت شرایط مدیر صندوق قرار دارید.",
  "refer_to_pamm_dept": " مراجعه کنید  PAMM لطفاً برای اطلاعات بیشتر به بخش ",
  "pamm_operation_failed_error": "عملیات ناموفق بود! خطایی در پردازش این درخواست رخ داد ",
  "pamm_operation_failed_invalid": "عملیات ناموفق بود! درخواست شما نامعتبر بود ",
  "pamm_success": ". شدید PAMM شما موفق به پیوستن یک سرمایه‌گذار",
  "attached_to": "پیوست شده به",
  "display_name_on_statistics_table": "نمایش نام در جدول آمار",
  "server_name": "نام سرور",
  "congratulations": "تبریک می‌گوییم",
  "credentials_to_login_to_mt": " به حساب جدید خود استفاده کنید MetaTrader لطفاً از جزئیات زیر برای ورود به پلتفرم ",
  "master_accounts": 'حساب‌های مستر',
  "private": 'خصوصی',
  "reject_withdrawals_during_open_trades": 'رد برداشت‌ها در حین معاملات باز',
  "additional_terms": 'در صورت وجود شرایط اضافی، تیک بزنید',
  "transfer_back_to_my_local_depositor": 'انتقال به سپرده‌گذار محلی من',
  "transfer_back": 'انتقال به عقب',
  "destination_tag_required": '.ما به شدت توصیه می‌کنیم که  قبل از وارد کردن جزئیات درخواستی،با ارائه دهنده کیف پول خود بررسی کنید اگر آنها به برچسب مقصد نیاز دارند یا خیر،  تا از هدر رفتن وجه جلوگیری کنید',
  "min_deposit": 'حداقل واریز',
  "Copied": '!کپی شد',
  "empty_dropzone": " شما باید ابتدا جعبه خالی را پر کنید",
  "banner_trading": "بنر  تجارت",
  "banner_pamm_platform": "بنر پلتفرم PAMM",
  "banner_mt4_mt5_platform": "بنر پلتفرم MT4-MT5",
  "banner_cash_bonus": "بنر ۱۲۰٪ پاداش نقدی",
  "banner_leverage": "بنر اهرم مالی",
  "guidance_documents": 'راهنما ی مدارک ',
  "front": 'جلو',
  "identity_card_text_front_1":'قسمت جلوی کارت شناسایی خود را روی یک سطح صاف (مثلاً یک میز یا پیشخوان) قرار دهید تا از انعکاس نور یا سایه‌ها جلوگیری شود.',
  "identity_card_text_back_1": 'قسمت پشت کارت شناسایی خود را روی یک سطح صاف (مثلاً یک میز یا پیشخوان) قرار دهید تا از انعکاس نور یا سایه‌ها جلوگیری شود.',
  "identity_card_text_2":'لطفاً اطمینان حاصل کنید که تمام مدارک درخواستی را به صورت جداگانه بارگذاری می‌کنید.',
  "identity_card_text_3":' مدرک باید به صورت رنگی ارائه شود.',
  "identity_card_text_4":'اطمینان حاصل کنید که کارت شناسایی شما حداقل ۳ ماه اعتبار داشته باشد.',
  "identity_card_image_text":'۱. کل مدرک باید به صورت کامل قابل مشاهده باشد، از جمله چهار گوشه آن.',
  "passport_text_1":'گذرنامه خود را روی یک سطح صاف (مثلاً یک میز یا پیشخوان) قرار دهید تا از انعکاس نور یا سایه‌ها جلوگیری شود.',
  "passport_text_2":'لطفاً اطمینان حاصل کنید که تمام مدارک درخواستی را به صورت جداگانه بارگذاری می‌کنید.',
  "passport_text_3":'اطمینان حاصل کنید که گذرنامه شما حداقل ۳ ماه اعتبار داشته باشد.',
  "passport_image_text":' مدرک باید باز باشد تا هر دو صفحه در تصویر دیده شوند.',
  "driving_license_text_front_1":'قسمت جلوی گواهینامه رانندگی خود را روی یک سطح صاف (مثلاً یک میز یا پیشخوان) قرار دهید تا از انعکاس نور یا سایه‌ها جلوگیری شود.',
  "driving_license_text_back_1": 'قسمت پشت گواهینامه رانندگی خود را روی یک سطح صاف (مثلاً یک میز یا پیشخوان) قرار دهید تا از انعکاس نور یا سایه‌ها جلوگیری شود.',
  "driving_license_text_2":'لطفاً اطمینان حاصل کنید که تمام مدارک درخواستی را به صورت جداگانه بارگذاری می‌کنید.',
  "driving_license_text_3":' مدرک باید به صورت رنگی ارائه شود.',
  "driving_license_text_4":'اطمینان حاصل کنید که گواهینامه رانندگی شما حداقل ۳ ماه اعتبار داشته باشد.',
  "driving_license_image_text":'۱. کل مدرک باید به صورت کامل قابل مشاهده باشد، از جمله چهار گوشه آن.',
  "bank_statement_text_1": ' مدرک بانکی خود را روی یک سطح صاف (مثلاً میز یا پیشخوان) قرار دهید تا تمام جزئیات مربوطه شامل شوند.',
  "bank_statement_text_2":' مدرک باید حداقل ۶ ماه از تاریخ صدور اعتبار داشته باشد.',
  "bank_statement_image_text_1": '۱. سند نباید برش خورده یا تا شده باشد و کل صفحه باید قابل مشاهده باشد، از جمله چهار گوشه آن.',
  "bank_statement_image_text_2": '۲. یک تکه کاغذ برش دهید و اعداد را بپوشانید، به طوری که فقط شش رقم اول و چهار رقم آخر قابل مشاهده باشند.',
  "bank_statement_image_text_3":'۳.نام، تاریخ صدور و لوگوی شرکت باید قابل مشاهده باشند.',
  "bank_statement_image_text_4":"۴.آدرس کامل شما ( باید همان آدرسی باشد که هنگام ثبت‌نام درپلکسی ترید اعلام کردید)",
  "utility_bill_text_1": 'سند صورتحساب خدمات را روی یک سطح صاف (مثلاً میز یا پیشخوان) قرار دهید تا تمام جزئیات مورد نیاز قابل مشاهده باشند.',
  "utility_bill_text_2":'از انعکاس نور یا سایه‌ها جلوگیری کنید.',
  "utility_bill_text_3":' مدرک باید به صورت رنگی باشد.',
  "utility_bill_text_4":'چهار گوشه مدرک باید به وضوح داخل تصویر قابل مشاهده باشند.',
  "utility_bill_text_5":'سند باید حداقل ۶ ماه از تاریخ صدور اعتبار داشته باشد.',
  "utility_bill_image_text_1":'۱. سند نباید برش خورده یا تا شده باشد و کل صفحه باید قابل مشاهده باشد، از جمله چهار گوشه آن.',
  "utility_bill_image_text_2":'۲.نام، تاریخ صدور و لوگوی شرکت باید قابل مشاهده باشند.',
  "utility_bill_image_text_3": '۳ .آدرس کامل شما ( باید همان آدرسی باشد که هنگام ثبت‌نام درپلکسی ترید اعلام کردید)',
  "identity_card": "کارت شناسایی",
  "passport": "گذرنامه",
  "driving_license": "گواهینامه رانندگی",
  "bank_statement": "صورت حساب بانکی",
  "utility_bill": "قبض خدمات",
  "crypto_network_address": "لطفا آدرس ارز دیجیتال خود را وارد کنید",
  "required": " لازم است",
  "social_platform": "چگونه در مورد PlexyTrade شنیدید؟",
  "other_social_platform": 'لطفاً به ما بگویید از کجا در مورد  plexytrade شنیده اید (اختیاری)',
  "export_csv": "خروجی بهCSV",
  "referred": "ارجاع شده (خانواده/دوست/همکار...)",
  "other_text":"دیگر"
}
