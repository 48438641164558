<template>
  <div class="lg:flex lg:flex-wrap px-2 lg:p-0">
    <div class="bg-white w-full shadow-ts1 rounded-lg mb-10">
      <div class="border-b border-gray-400 w-full px-5 lg:px-8 pt-5 pb-4">
        <h2 class="font-display text-xl font-bold text-blue leading-none">{{ $t('guidance_documents').tTitle() }}</h2>
      </div>
      <div class="p-4">
        <div class="py-2 custom-scrollbar"
          :class="{ 'overflow-x-hidden': !needsScroll, 'overflow-x-scroll': needsScroll }" ref="scroller" id="docTypeContainer">
          <ul class="p-2 grid grid-flow-col gap-x-2 self-start" id="doc-types">
            <li
              class="px-4 py-2 w-[190px] lg:px-8 lg:py-4 lg:w-[228px] flex content-center border rounded-lg cursor-pointer select-none"
              v-for="(type) in docTypes" :key="type.name"
              :class="{ 'bg-gray-400 border-blue': type.active, 'opacity-50 border-gray-300': !type.active, }"
              @click="selectDoc(type.name)">
              <div class="flex gap-x-2 lg:gap-x-4 items-center w-full">
                <img :src="require(`../../../assets/img/${type.name}.svg`)" :alt="type.name + ' icon'"
                  class="w-[36px] h-[36px]" />
                <div class="text-sm" :class="[type.active ? 'text-blue font-semibold' : 'text-gray-900']">
                  {{ $t(type.name.replace('-', '_')).tTitle() }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="pt-8 pb-7 px-7">
        <component v-bind:is="selectedType" :face="face" />
      </div>
    </div>
  </div>
</template>

<script>
import identityCard from './types/IdentityCard.vue';
import Passport from './types/Passport.vue';
import DrivingLicense from './types/DrivingLicense.vue';
import BankStatement from './types/BankStatement.vue';
import UtilityBill from './types/UtilityBill.vue';

export default {
  components: { identityCard, Passport, DrivingLicense, BankStatement, UtilityBill },
  data() {
    return {
      docTypes: [
        { name: 'identity-card', active: true },
        { name: 'passport', active: false },
        { name: 'driving-license', active: false },
        { name: 'bank-statement', active: false },
        { name: 'utility-bill', active: false }
      ],
      needsScroll: false,
      face: 'front'
    }
  },
  computed: {
    selectedType() {
      return this.docTypes.filter(type => type.active === true)[0].name
    },
  },
  mounted() {
    this.checkOverflow();
    window.addEventListener('resize', this.checkOverflow); // Recheck on window resize
    // set the face of the document that should show as default
    if (this.$route.query.face) {
      this.face = this.$route.query.face
    }
    // Check for document type as a route param in link.
    if (this.$route.query.docType) {
      // open to the doc type specified in link
      this.selectDoc(this.$route.query.docType)
      // remove route params from the link
      this.$router.replace({ 'query': null });
    }
    this.dragContainer('#docTypeContainer')
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkOverflow);
  },
  methods: {
    selectDoc(doc) {
      this.docTypes.map(type => {
        if (type.name === doc) {
          type.active = true
        } else type.active = false
      })
    },
    checkOverflow() {
      const container = this.$refs.scroller;
      this.needsScroll = container.scrollWidth > container.clientWidth;
    }
  },
}
</script>
