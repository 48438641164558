<template>
  <div class="step-three px-[20px]">
    <div class="form-wrapper flex flex-col gap-5 mb-5">
      <!-- Wallet Currency -->
      <div class="currency-wrapper grid grid-cols-4-default gap-5">
        <label class="font-body text-base text-gray-900 tracking-wide col-span-4" for="walletCurrency">{{ $t('wallet_currency').tTitle() }}<span class="text-orange ml-1">*</span></label>
        <div class="col-span-2" v-for="(currency, index) in $getCurrencies()" :key="index">
          <div class="radio-item flex justify-center items-center rounded-[25px]" :class="data.walletCurrency !== null && data.walletCurrency !== index ? 'not-active' : ''">
            <input
              type="radio"
              :value="index"
              :id="index"
              class="radio-input"
              name="walletCurrency"
              v-validate="'required'"
              v-model="data.walletCurrency"
              :data-vv-as="translations.text.wallet_currency"
            />
            <!-- @change="updateAccountType" -->
            <label class="radio-label w-full text-center py-[15px] font-semibold cursor-pointer" :for="index">{{ index }}</label>
          </div>
        </div>
        <span v-show="errors.has('walletCurrency')" class="font-body text-xs text-orange font-semibold col-span-4">{{ errors.first('walletCurrency') }}</span>
      </div>
      <!-- Platforms -->
      <div class="platforms-wrapper">
        <label class="font-body text-base text-gray-900 tracking-wide col-span-4" for="platform">{{ translations.text.platform }}<span class="text-orange ml-1">*</span></label>
        <div class="grid gap-5 mt-5" :class="[availablePlatforms.includes('PAMM') ? 'grid-cols-3-default' : 'grid-cols-4-default']">
          <div :class="[availablePlatforms.includes('PAMM') ? 'col-span-1' : 'col-span-2']" v-for="(platform, index) in availablePlatforms" :key="index">
            <div class="radio-item flex justify-center items-center rounded-[25px]" :class="data.platform !== null && data.platform !== platform.toLowerCase() ? 'not-active' : ''">
              <input
                type="radio"
                :value="platform.toLowerCase()"
                :id="platform"
                class="radio-input"
                name="platform"
                v-validate="'required'"
                v-model="data.platform"
                :data-vv-as="translations.text.platform"
                @change="updateAccountType"
              />
              <label class="radio-label w-full text-center py-[15px] font-semibold cursor-pointer" :for="platform">{{ platform }}</label>
            </div>
          </div>
        </div>
        <span v-show="errors.has('platform')" class="font-body text-xs text-orange font-semibold col-span-4">{{ errors.first('platform') }}</span>
      </div>
      <!-- Account currency -->
      <div class="account-currency-wrapper grid grid-cols-4-default gap-5">
        <label class="font-body text-base text-gray-900 tracking-wide col-span-4" for="tradingAccountCurrency">{{ translations.text.choose_currency }}<span class="text-orange ml-1">*</span></label>
        <div class="col-span-2" v-for="(currency, index) in tradingAccountCurrencies" :key="index">
          <div
            class="radio-item flex justify-center items-center rounded-[25px]"
            :class="data.tradingAccountCurrency !== null && data.tradingAccountCurrency !== index ? 'not-active-account' : ''"
          >
            <input
              type="radio"
              :value="index"
              :id="`tradingAccount-${index}`"
              class="radio-input"
              name="tradingAccountCurrency"
              v-validate="'required'"
              :data-vv-as="translations.text.trading_account_currency"
              v-model="data.tradingAccountCurrency"
              @change="updateAccountType"
            />
            <label class="radio-label w-full text-center py-[15px] font-semibold cursor-pointer" :for="`tradingAccount-${index}`">{{ `${index}` }}</label>
          </div>
        </div>
        <span v-show="errors.has('tradingAccountCurrency')" class="font-body text-xs text-orange font-semibold col-span-4">{{ errors.first('tradingAccountCurrency') }}</span>
      </div>
      <!-- Account Balance -->
      <div class="col-span-4 lg:col-span-4" v-if="accountType.toLowerCase() === 'demo'">
        <label class="font-body text-base text-gray-900 tracking-wide" for="startingDemoBalance">{{ translations.text.account_balance }}</label>
        <div class="my-2">
          <span class="select is-fullwidth">
            <select
              v-validate="'required'"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('startingDemoBalance') }"
              :data-vv-as="translations.text.account_balance"
              name="startingDemoBalance"
              v-model="data.startingDemoBalance"
              class="text-base text-gray-900 font-semibold rtl:bg-left"
            >
              <option :value="500" selected hidden>{{ $getCurrencies()[data.walletCurrency] ? $getCurrencies()[data.walletCurrency].symbol : '' }} 500</option>
              <option v-for="(item, index) in demoAccountBalanceOptions" :value="item" :key="index">
                {{ $getCurrencies()[data.walletCurrency] ? $getCurrencies()[data.walletCurrency].symbol : '' }} {{ item }}
              </option>
            </select>
          </span>
          <span v-show="errors.has('startingDemoBalance')" class="font-body text-xs text-orange font-semibold">{{ errors.first('startingDemoBalance') }}</span>
        </div>
      </div>
      <!-- Trading Account Type -->
      <div class="col-span-4 lg:col-span-4 account-type-wrapper">
        <label class="font-body text-base text-gray-900 tracking-wide" for="account_type">{{ translations.text.trading_account_type }}</label>
        <div class="my-2">
          <span class="select is-fullwidth">
            <select
              v-validate="'required|is-islamic'"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('account_type') }"
              :data-vv-as="translations.text.account_type"
              name="account_type"
              v-model="data.account_type"
              class="text-base text-gray-900 font-semibold cursor-pointer rtl:bg-left"
              :disabled="data.platform && data.walletCurrency ? false : true"
            >
              <option v-for="(g, i) in groups" :key="i" :value="g.name" :data-currency="g.currency" :ref="'group-' + g.name">
                {{ g.display_name }}{{ data.tradingAccountCurrency == '' ? ' (' + g.currency + ')' : '' }}
              </option>
            </select>
          </span>
          <span v-show="errors.has('account_type')" :class="{ 'has-text-right': Language == 'fa' }" class="font-body text-xs text-orange font-semibold">{{
            errors.first('account_type')
          }}</span>
        </div>
      </div>
      <!--Laverage-->
      <div v-if="data.platform !== 'pamm'" class="col-span-4 lg:col-span-4 account-type-wrapper">
        <label class="font-body text-base text-gray-900 tracking-wide" for="leverage">{{ translations.text.leverage }}</label>
        <div class="my-2">
          <span class="select is-fullwidth">
            <select
              v-validate="'required'"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('leverage') }"
              :data-vv-as="translations.text.leverage"
              name="leverage"
              v-model="data.leverage"
              class="text-base text-gray-900 font-semibold cursor-pointer rtl:bg-left"
              :disabled="data.platform && data.walletCurrency && data.account_type ? false : true"
            >
              <option v-for="(item, i) in leveragesData" :key="i" :value="String(item.leverage)">1:{{ item.leverage }}</option>
            </select>
          </span>
          <span v-show="errors.has('leverage')" :class="{ 'has-text-right': Language == 'fa' }" class="font-body text-xs text-orange font-semibold">{{
            errors.first('leverage')
          }}</span>
        </div>
      </div>
      <!-- Checkboxes -->
      <div v-if="data.platform === 'pamm'" class="col-span-4 lg:col-span-4">
        <div :class="{ 'has-text-right': Language == 'fa' }">
          <p>
            <label class="font-body text-xs text-gray-900 flex items-center">
              <input
                v-validate="'required'"
                class="agreements text-gray-900"
                :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('read_and_accept_lpoa') }"
                name="read_and_accept_lpoa"
                type="checkbox"
                :data-vv-as="translations.text.read_and_accept_lpoa"
                v-model="data.read_and_accept_lpoa"
              />
              <span class="checkmark"></span>
              <span class="ltr:ml-2 rtl:mr-2" v-html="translations.text.read_and_accept_lpoa"></span>
            </label>
            <span v-show="errors.has('read_and_accept_lpoa')" class="font-body text-xs text-orange font-semibold">{{ $t('must_accept_lpoa') }}</span>
          </p>
        </div>
      </div>
      <div
        v-if="
          BonusConfig.depositBonus.enabled &&
          !grantNoDepositBonus &&
          data.platform !== 'pamm' &&
          data.account_type !== 'investor' &&
          data.account_type !== 'pamm-investor' &&
          data.account_type !== 'no-deposit-bonus' &&
          data.account_type !== 'no-deposit' &&
          accountType.toLowerCase() !== 'demo'
        "
        class="col-span-4 lg:col-span-4"
      >
        <div :class="{ 'has-text-right': Language == 'fa' }">
          <p>
            <label class="font-body text-xs text-gray-900">
              <input
                class="agreements text-gray-900"
                :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('include_account_in_bonus_program') }"
                name="include_account_in_bonus_program"
                type="checkbox"
                :data-vv-as="translations.text.include_account_in_bonus_program"
                v-model="data.include_account_in_bonus_program"
              />
              <span class="checkmark"></span>
              <span class="ltr:ml-2 rtl:mr-2" v-html="translations.text.include_account_in_bonus_program"></span>
            </label>
            <span v-show="errors.has('include_account_in_bonus_program')" class="font-body text-xs text-orange font-semibold">{{
              errors.first('include_account_in_bonus_program')
            }}</span>
          </p>
        </div>
      </div>
      <div v-if="(data.account_type == 'no-deposit-bonus' || data.account_type == 'no-deposit') && data.tradingAccountCurrency == 'USD'" class="col-span-4 lg:col-span-4">
        <div :class="{ 'has-text-right': Language == 'fa' }">
          <p>
            <label class="font-body text-xs text-gray-900">
              <input
                v-validate="'required'"
                class="agreements text-gray-900"
                :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('agree_with_no_deposit_bonus') }"
                name="agree_with_no_deposit_bonus"
                type="checkbox"
                :data-vv-as="translations.text.agree_with_no_deposit_bonus"
                v-model="data.agree_with_no_deposit_bonus"
              />
              <span class="checkmark"></span>
              <span class="ml-2" v-html="translations.text.agree_with_no_deposit_bonus"></span>
            </label>
            <span v-show="errors.has('agree_with_no_deposit_bonus')" class="font-body text-xs text-orange font-semibold">{{
              errors.first('agree_with_no_deposit_bonus') ? removeAnchor(errors.first('agree_with_no_deposit_bonus')) : ''
            }}</span>
          </p>
        </div>
      </div>
      <div class="col-span-4 lg:col-span-4">
        <div :class="{ 'has-text-right': Language == 'fa' }">
          <label class="font-body text-xs text-gray-900 flex items-center">
            <input
              v-validate="'required'"
              class="agreements text-gray-900"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('privacy_policy_link') }"
              name="privacy_policy_link"
              type="checkbox"
              :data-vv-as="translations.text.privacy_policy_link"
              v-model="data.privacy_policy_link"
            />
            <span class="checkmark"></span>
            <span class="ltr:ml-2 rtl:mr-2" v-html="translations.text.privacy_policy_link"></span><span class="text-orange ml-1">*</span>
          </label>
          <span v-show="errors.has('privacy_policy_link')" :class="{ 'has-text-right': Language == 'fa' }" class="font-body text-xs text-orange font-semibold">{{
            errors.first('privacy_policy_link') ? removeAnchor(errors.first('privacy_policy_link')) : ''
          }}</span>
        </div>
      </div>
      <div class="col-span-4 lg:col-span-4">
        <div :class="{ 'has-text-right': Language == 'fa' }">
          <label class="font-body text-xs text-gray-900 flex items-center">
            <input
              v-validate="'required'"
              class="agreements text-gray-900"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('terms_link') }"
              name="terms_link"
              type="checkbox"
              :data-vv-as="translations.text.terms_link"
              v-model="data.terms_link"
            />
            <span class="checkmark"></span>
            <span class="ltr:ml-2 rtl:mr-2" v-html="translations.text.terms_link"></span><span class="text-orange ml-1">*</span>
          </label>
          <span v-show="errors.has('terms_link')" :class="{ 'has-text-right': Language == 'fa' }" class="font-body text-xs text-orange font-semibold">{{
            errors.first('terms_link') ? removeAnchor(errors.first('terms_link')) : ''
          }}</span>
        </div>
      </div>
      <div class="col-span-4 lg:col-span-4">
        <div :class="{ 'has-text-right': Language == 'fa' }">
          <label class="font-body text-xs text-gray-900 flex items-center">
            <input
              class="agreements text-gray-900"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('marketing_link') }"
              name="marketing_link"
              type="checkbox"
              :data-vv-as="translations.text.marketing_link"
              v-model="data.marketing_link"
            />
            <span class="checkmark"></span>
            <span class="ltr:ml-2 rtl:mr-2" v-html="translations.text.marketing_link"></span>
          </label>
        </div>
      </div>
    </div>
    <div class="button-wrapper flex md:justify-start gap-[5%]">
      <button class="w-full h-[50px] max-w-[185px] bg-white text-blue border border-blue rounded-md font-body font-bold text-[16px] px-[2%]" @click="changePage()">
        {{ $t('previous') }}
      </button>
      <button class="w-full h-[50px] max-w-[185px] bg-green text-white rounded-md font-body font-bold text-[16px] px-[2%]" @click="changePage(true)">
        {{ $t('submit').tTitle() }}
      </button>
    </div>
  </div>
</template>
<script>
import { service } from '../../main'
import { mapGetters } from 'vuex'
import config from '../../config'
export default {
  props: {
    accountType: String,
    activeStep: Number,
    isCompany: Boolean,
    translations: Object,
    formData: Object,
    upgrade: Boolean,
    prefilledTestData: Boolean,
    testData: Object,
  },
  data() {
    return {
      data: {
        walletCurrency: '',
        platform: '',
        tradingAccountCurrency: '',
        account_type: '',
        startingDemoBalance: 500,
        include_account_in_bonus_program: false,
        agree_with_no_deposit_bonus: false,
        privacy_policy_link: false,
        terms_link: false,
        marketing_link: false,
        leverage: '',
      },
      groups: {},
      demoAccountBalanceOptions: ['500', '1000', '1500', '2000', '3000', '5000', '10000', '50000'],
      finalFormErrors: null,
      grantNoDepositBonus: false,
      leveragesData: [],
      availablePlatforms: ['MT4', 'MT5', 'PAMM'],
      restrictedBonusCountries: ['AS', 'UM', 'VG', 'VI', 'ZZ'],
    }
  },
  created() {
    this.$bus.$on('PopulateFinalForm', this.populateFinalForm)
    this.$bus.$on('grantNoDepositBonus', e => {
      this.grantNoDepositBonus = e
    })
    if (this.prefilledTestData && this.testData) {
      Object.keys(this.testData).forEach(key => {
        this.data[key] = this.testData[key]
      })
    }
  },
  mounted() {
    if (this.formData.walletCurrency) {
      Object.keys(this.formData).forEach(key => {
        this.data[key] = this.formData[key]
      })
    }
    if (this.accountType.toLowerCase() === 'demo') {
      this.data.walletCurrency = 'USD'
      this.availablePlatforms = ['MT4', 'MT5']
    }
    this.updateAccountType()
  },
  watch: {
    'data.account_type'(value) {
      this.getGroupLeverages(value)
    },
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },
  },
  computed: {
    tradingAccountCurrencies() {
      var TAcurrencies = {}
      if (this.accountType.toLowerCase() === 'demo' && !this.upgrade) {
        TAcurrencies = {
          USD: {
            symbol: '$',
            active: true,
          },
        }
      } else {
        if (this.data.platform == 'pamm') {
          TAcurrencies = {
            USD: {
              symbol: '$',
              active: true,
            },
          }
        } else {
          TAcurrencies = this.$getCurrencies()
        }
      }
      return TAcurrencies
    },
    ...mapGetters({
      Language: 'language/code',
      BonusConfig: 'bonusconfig/all',
      User: 'user/all'
    }),
  },

  methods: {
    changePage(nextPage) {
      if (nextPage) {
        if (this.isCompany) {
          this.$bus.$emit('ValidateFinalForm')
        }
        if (this.grantNoDepositBonus === true) {
          this.data.account_type = 'no-deposit-bonus'
        }
        if (this.data.account_type == 'no-deposit-bonus') {
          this.data.include_account_in_bonus_program = false
        }
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$bus.$emit('DataSubmitted', this.data)
            return this.$bus.$emit('ApiCall')
          }
          // scroll to first message
          let el = document.getElementsByName(this.errors.items[0].field)[0]
          if (el) return el.focus()
        })
      } else return this.$bus.$emit('ChangePage', this.activeStep - 1)
    },
    getPlatformAccountGroups(platform, currency) {
      if (platform === 'pamm') {
        currency = 'USD'
      }
      return new Promise(resolve => {
        resolve(
          service
            .post('/account-types/list-filtered', {
              platform: platform,
              currency: currency,
              mode: this.accountType.toLowerCase() === 'demo' ? 'demo' : 'live',
            })
            .then(response => {
              if (response.data) {
                // if PAMM investors are needed rework the following
                let finalArray = response.data.account_types
                response.data.account_types.forEach((type, index) => {
                  // if (!type.display_name.toLowerCase().includes('pamm')) finalArray.push(type)

                  if (this.restrictedBonusCountries.includes(this.formData.country) && (type.name == 'no-deposit-bonus' || type.name == 'no-deposit')) {
                    finalArray.splice(index, 1)
                  }
                  // console.log(type)
                  if (
                    !this.restrictedBonusCountries.includes(this.formData.country) &&
                    (type.name == 'no-deposit-bonus' || type.name == 'no-deposit') &&
                    this.data.tradingAccountCurrency != 'USD'
                  ) {
                    finalArray.splice(index, 1)
                  }
                  // Remove Islamic account type when the users country is not from countriesWithIslamicAccountType.
                  if (config.countriesWithIslamicAccountType.findIndex(country => country == this.formData.country) === -1) {
                    finalArray = finalArray.filter(acc => acc.display_name.toLowerCase() !== 'islamic')
                  }
                })
                this.data.account_type = finalArray[0].name
                this.groups = finalArray
              }
            })
            .finally(() => {
              if (platform === 'pamm') return (this.data.account_type = 'investor')
            })
            .catch(() => {})
        )
      })
    },
    updateAccountType() {
      this.data.account_type = ''
      if (this.data.platform && this.data.tradingAccountCurrency) return this.getPlatformAccountGroups(this.data.platform, this.data.tradingAccountCurrency)
    },
    populateFinalForm(errors) {
      this.finalFormErrors = errors
    },
    removeAnchor(text) {
      return text.replace(/<a [^>]*>|<\/a>./g, '')
    },
    getGroupLeverages(group) {
      if (group) {
        if (group == 'investor') {
          this.data.leverage = String(300)
          return
        }
        service
          .post('/account-types/list-leverages', {
            name: group,
          })
          .then(response => {
            if (response.data) {
              this.leveragesData = Object.assign({}, response.data.leverages)
              this.data.leverage = String(this.leveragesData[0].leverage)
            }
          })
      } else {
        this.data.leverage = ''
      }
    },
  },
}
</script>
<style scoped>
.radio-item {
  border: 1px solid #717171;
}
.radio-item input[type='radio'] {
  display: none;
}
.not-active,
.not-active-account {
  opacity: 30%;
}
.radio-item:has([type='radio']:checked) {
  @apply bg-gray-400 border-solid border-blue;
}
</style>
