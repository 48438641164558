<template>
  <div class="md:flex md:flex-wrap px-2 md:px-6 lg:p-0">
    <div class="bg-white w-full shadow-ts1 rounded-lg mb-10">
      <div class="border-b border-gray-400 w-full px-5 md:px-8 pt-5 pb-4">
        <h2 class="font-display text-xl font-bold text-blue leading-none">{{ $t('upload_documents').tTitle() }}</h2>
      </div>
      <div class="p-4">
        <div class="lg:flex">
          <ul class="p-2 overflow-x-scroll no-scrollbar grid grid-flow-col gap-x-2 lg:grid-flow-row lg:w-[30%] lg:overflow-x-hidden self-start" id="doc-types">
            <li
              class="px-2 py-3 min-w-[35vw] lg:min-w-fit flex content-center w-56 md:w-auto shrink-0 md:shrink"
              v-for="(doc, type) in documents"
              :key="type"
              :class="{ 'rounded-lg shadow-ts3': activeDoc == type, 'opacity-50': activeDoc != type && doc.status === false }"
              @click="activeDoc = type"
            >
              <div class="flex gap-x-2 lg:gap-x-4 items-center cursor-pointer w-full">
                <img :src="doc.icon" :alt="type + ' icon'" class="w-[36px] h-[36px]" />
                <div :class="[activeDoc == type && doc.status == false ? 'text-blue font-semibold' : doc.status != false ? 'text-green' : 'text-gray-900']">
                  {{ $t(type.replace('-', '_')).tTitle() }}
                </div>
                <img :class="{ hidden: doc.status !== 'approved' }" class="w-[16px] h-[16px] ltr:ml-auto rtl:mr-auto" src="../../assets/img/icons/upload/approved.svg" alt="Approved document" />
              </div>
            </li>
          </ul>
          <div class="w-full md:flex-1 py-2" v-for="(doc, type) in documents" :key="'dropzone-' + type" :class="{ hidden: activeDoc !== type }">
            <div class="border border-gray-400 rounded-lg shadow-ts1">
              <div class="border-b border-gray-400 w-full px-8 py-4">
                <div class="flex items-center gap-x-2">
                  <div class="font-semibold text-gray-900">{{ $t(type.replace('-', '_')).tTitle() }}</div>
                  <!-- <div class="relative group">
                    <img
                      :class="{ hidden: $t(type.replace('-', '_') + '_info') === '' }"
                      class="w-[13px] h-[13px]"
                      src="../../assets/img/icons/upload/info.svg"
                      alt="Additional info"
                    />
                    <div class="absolute t-6 bg-white rounded-lg shadow-ts3 w-72 p-4 text-base text-gray-900 object-right-bottom hidden group-hover:block">
                      {{ $t(type.replace('-', '_') + '_info').tTitle() }}
                    </div>
                  </div> -->
                </div>
                <div class="mt-8 text-gray-900" :class="type == 'other'? 'mb-2': 'mb-5'">{{ $t(type.replace('-', '_') + '_instructions').tTitle() }}</div>
                <div v-if="type=='other'" class="mt-2 mb-5 text-red">{{ $t('other_info').tTitle() }}</div>
                <div :class="type == 'other' ? 'lg:grid-cols-2' : 'grid-cols-1'" class="grid gap-4">
                  <div v-for="dz in dzCount" :key="'dz-' + dz" class="rounded-lg">
                    <Dropzone
                      v-if="type !== 'other' && doc.status === false"
                      :id="'myVueDropzone_' + type"
                      :ref="'myVueDropzone_' + type"
                      class="dropzone"
                      :useCustomSlot="true"
                      :use-font-awesome="true"
                      :options="dropzoneOptions"
                      @vdropzone-sending="sending(...arguments, type, docTypes[type])"
                      @vdropzone-error="collectErrors(dz - 1)"
                      @vdropzone-file-added="heicSupportFunction(...arguments, type, docTypes[type])"
                      @vdropzone-success="showSuccess"
                    >
                      <div>
                        <div class="mb-4 text-white">{{ $t('drop_files_upload') }}</div>
                        <div>
                          <button class="btn">{{ $t('select_file') }}</button>
                        </div>
                      </div>
                    </Dropzone>
                    <div v-else-if="type === 'other'" class="grid gap-4">
                      <Dropzone
                        v-if="doc[`other${dz}`].status === false"
                        :id="'myVueDropzone_' + type + (dz > 1 ? dz : '')"
                        :ref="'myVueDropzone_' + type + (dz > 1 ? dz : '')"
                        class="dropzone"
                        :useCustomSlot="true"
                        :use-font-awesome="true"
                        :options="dropzoneOptions"
                        @vdropzone-sending="sending(...arguments, type + (type == 'other' ? dz : ''), docTypes[type])"
                        @vdropzone-error="collectErrors(dz - 1)"
                        @vdropzone-file-added="heicSupportFunction(...arguments, type + (dz > 1 ? dz : ''), docTypes[type])"
                        @vdropzone-success="showSuccess"
                      >
                        <div>
                          <div class="mb-4 text-white">{{ $t('drop_files_upload') }}</div>
                          <div>
                            <button class="btn">{{ $t('select_file') }}</button>
                          </div>
                        </div>
                      </Dropzone>
                      <div v-else class="bg-green rounded-lg p-4 text-white">
                        <div class="h-[180px] min-h-[150px] flex items-center justify-center text-center">
                          <div v-if="doc[`other${dz}`].status === 'pending'">{{ $t('document_received').tTitle() }}</div>
                          <div v-else-if="doc[`other${dz}`].status === 'approved'">{{ $t('doc_approved').tTitle() }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="bg-green rounded-lg p-4 text-white">
                      <div class="h-[180px] min-h-[150px] flex items-center justify-center text-center">
                        <div v-if="doc.status === 'pending'">{{ $t('document_received').tTitle() }}</div>
                        <div v-else-if="doc.status === 'approved'">{{ $t('doc_approved').tTitle() }}</div>
                      </div>
                    </div>
                  </div>
                  <!-- <div
                    v-if="activeDoc == 'other' && otherCount < 4"
                    @click="addMoreOther"
                    class="flex flex-wrap content-center justify-center p-5 bg-gray-100 hover:bg-gray-300 ease-linear duration-200 min-h-[150px] rounded-lg border border-gray-300 py-5 "
                  >
                    <div class="w-full text-center mb-4 text-gray-900">{{ $t('click_add_another_doc').tTitle() }}</div>
                    <button class="btn">{{ $t('btn_new_slot').tTitle() }}</button>
                  </div> -->
                </div>
              </div>
              <div class="w-full px-8 py-4">
                <div class="flex space-x-4 rtl:space-x-reverse items-center cursor-pointer" @click="doc.requirements = !doc.requirements">
                  <img v-if="doc.requirements === true" src="../../assets/img/icons/upload/arrow-up.svg" :alt="'Close requirements'" class="w-[13px] h-[10px]" />
                  <img v-else src="../../assets/img/icons/upload/arrow-down.svg" :alt="'Open requirements'" class="w-[13px] h-[10px]" />
                  <div class="text-blue text-lg">{{ $t('requirements') }}</div>
                </div>
                <div v-if="doc.requirements === true" class="mt-4">
                  <div class="font-semibold text-gray-900 text-base">{{ $t('documents_must_contain_all_of_the_following_details').tTitle() }}</div>
                  <ul class="list-disc ltr:ml-6 rtl:mr-6" v-if="type==activeDoc">
                    <li v-for="req in totalRequirements" :key="activeDoc + 'req' + req" class="text-gray-900 text-base">
                      {{ $t(type.replace('-', '_') + '_req' + req).tTitle() }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="mt-4 ltr:md:ml-8 rtl:md:mr-8 text-gray-900 text-sm">
              {{ $t('additional_docs_email') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { service } from '../../main.js'
import { mapActions, mapGetters } from 'vuex'
import Dropzone from 'vue2-dropzone'
import config from '../../config'
import api from '../../api'
import heic2any from 'heic2any'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

const imgPath = require.context('../../assets/img/icons/upload', true)

export default {
  components: {
    Dropzone,
  },
  data() {
    return {
      /*
      countries: config.countries,
      modals: {
        POA: {
          active: false,
          country: '',
        },
      },
      */
      documents: {},
      docTypes: { 'id-front': 'ple', 'id-back': 'ple', 'address-proof': 'poa', other: 'other' },
      activeDoc: '',
      otherCount: 1,
      dropzoneOptions: {
        headers: {
          Authorization: this.$auth.token(),
        },
        url: '',
        uploadMultiple: false,
        maxFiles: 1,
        maxFilesize: 10,
        maxThumbnailFilesize: 10,
        acceptedFiles: 'image/jpeg,image/png,image/heic,image/jpg,application/pdf',
        accept: function (file, done) {
          if (file.size === 0) {
            done('File is empty.')
          } else {
            done()
          }
        },
      },
      dzName: '',
      dzType: '',
    }
  },
  computed: {
    userHasCountry() {
      return this.User.info.country_code !== ''
    },
    // getOtherRows() {
    //   return Math.floor(this.totalOthers / 4) + 1;
    // },
    totalRequirements() {
      let req
      switch (this.activeDoc) {
        case 'id-front':
          req = 10
          break
        case 'id-back':
          req = 10
          break
        case 'address-proof':
          req = 6
          break
        case 'other':
          req = 1
          break
      }

      return req

      // let i = 0
      // let key = '',
      //   req = ''

      // do {
      //   i++
      //   key = this.activeDoc.replace('-', '_') + '_req' + i
      //   req = this.$t(key)
      // } while (req != key)

      // return --i
    },
    dzCount() {
      if (this.activeDoc == 'other') {
        return this.otherCount
      } else {
        return 1
      }
    },
    ...mapGetters({
      Language: 'language/code',
      User: 'user/all',
      Finance: 'finance/all',
      // documents:'user/uploadDocData'
    }),
  },
  created() {
    this.dropzoneOptions.url = config.API.host + 'documents/upload'
    this.getUploadDoc()
  },
  watch: {
    // Revert requirements to opened when navigating through document types.
    activeDoc() {
      this.getUploadDoc()
    },
  },
  mounted() {
    this.dragContainer('#doc-types')

    Object.keys(this.docTypes).forEach(type => {
      if (!(type in this.documents)) {
        let data = {
          status: false,
          icon: imgPath('./' + type + '.svg', true),
          requirements: true,
        }
        if (type !== 'other') {
          this.$set(this.documents, type, data)
        } else {
          let otherData = {
            status: false,
            icon: imgPath('./' + type + '.svg', true),
            requirements: true,
            other1: data,
            other2: data,
            other3: data,
            other4: data,
          }
          this.$set(this.documents, 'other', otherData)
        }
      }
    })

    this.activeDoc = Object.keys(this.docTypes).length > 0 ? Object.keys(this.docTypes)[0] : ''
  },
  methods: {
    heicSupportFunction(file, name, type) {
      if (file.name.includes('.heic')) {
        let errorItem = 'myVueDropzone_' + name
        this.$refs[errorItem][0].dropzone.element.querySelector('.dz-error-message').innerHTML = 'Converting .heic to jpeg...'
        heic2any({ blob: file, toType: 'image/jpeg' }).then(fileBlob => {
          const newFile = new File([fileBlob], file.name.replace('.heic', '.jpeg'), { type: 'image/jpeg' })
          // transfer current file details
          newFile.accepted = true
          newFile.processing = true
          newFile.status = 'success'
          newFile.upload = file.upload
          newFile.upload.filename = newFile.name
          Object.keys(this.$refs).forEach(item => {
            if (name === 'other1') name = 'other'
            if (item === 'myVueDropzone_' + name) {
              this.$refs[item][0].dropzone.removeFile(file)
              this.$refs[item][0].dropzone.addFile(newFile)
              this.$refs[item][0].dropzone.processFile(newFile, type)
            }
          })
        })
      }
    },
    // addMoreOther() {
    //   if (this.otherCount < 4) {
    //     if (this.documents.other[`other${this.otherCount}`].hasData) {
    //       this.otherCount++
    //       this.$set(this.documents.other, `other${this.otherCount}`, { status: false, requirements: true, hasData: false })
    //     } else {
    //       this.$toasted.error(this.$t('empty_dropzone'), { duration: 5000 })
    //     }
    //   }
    // },
    /**
     * Function when an error occurs in uploading a document. Adds a click listener  to the icon of error to be able to click it and to remove all documents from the dropzone.
     */
    collectErrors(dz) {
      // Dropzone where the error occurred
      let dropzone = document.getElementById(`myVueDropzone_${this.activeDoc}${parseInt(dz) == 0 ? '' : parseInt(dz) + 1}`)
      let errorRefs = dropzone.getElementsByClassName('dz-error-mark')
      let entries = Object.entries(errorRefs)
      // map through each errorElement
      entries.map(errRef => {
        let innerErrorRefSvg = errRef[1].querySelector('svg')
        let innerSvg = innerErrorRefSvg.getElementById('Check-+-Oval-2').querySelector('path')
        let errorMessage = dropzone.getElementsByClassName('dz-error-message')[0]
        // add the id to dz(number of dropzone) in order to catch the dropzone on icon click and delete the files for that specific dropzone
        errRef[1].id = dz
        innerErrorRefSvg.id = dz
        innerSvg.id = dz
        if (errRef[1]) {
          // Pointer events to all in order to enable click events on error icon.
          errRef[1].style.pointerEvents = 'all'
          // Add click event listener  to remove the uploaded documents when there is an error
          errRef[1].addEventListener(
            'click',
            el => {
              // Here we remove files of the clicked dropzone by the id we have stored earlier into the clicked element.
              this.$refs[`myVueDropzone_${this.activeDoc}${parseInt(el.target.id) == 0 ? '' : parseInt(el.target.id) + 1}`][0].removeAllFiles()
            },
            false
          )
          // Here I set the styles for the error message to go on a new line(default behaviour: text goes outside of the div)
          errorMessage.style.display = 'block'
          errorMessage.style.wordBreak = 'break-all'
        }
      })
    },
    triggerUserReload() {
      // reload user by setting firstname to undefined
      let nu = Object.assign({}, this.User.info)
      delete nu.firstname
      this['user/setInfo'](nu)
    },
    setCountry() {
      if (this.modals.POA.country === '') {
        this.$toasted.error(this.$t('empty_country_code'), { duration: 10000 })
      }
      if (this.modals.POA.country.length > 2) {
        this.$toasted.error(this.$t('invalid_country_code'), { duration: 10000 })
      }

      service
        .post('/users/update-country', {
          userID: this.User.info.ID,
          countryCode: this.modals.POA.country,
        })
        .then(response => {
          if (response.data.success) {
            this.modals.POA.active = false
            // reload user
            this.triggerUserReload()
          }
        })
    },
    sending(file, xhr, formData, name, type) {
      formData.append('userID', this.User.info.ID)
      formData.append('documentName', name)
      formData.append('documentType', type)
    },
    showSuccess(file, response) {
      let jres = JSON.parse(response)
      if (typeof jres.message !== 'undefined') {
        let msg = JSON.parse(jres.message)
        if (typeof msg !== 'undefined') {
          let type = msg.documentName
          if (type.includes('other')) {
            Object.keys(this.documents.other).forEach(key => {
              if (key === type) {
                this.$set(this.documents.other, key, { status: 'pending', requirements: true, hasData: true })
                if (this.otherCount < 4) this.otherCount++
              }
            })
          } else {
            this.documents[type].status = 'pending'
          }
          api.GetDocumentCount()
        }
        // jsres.message.filename
      }
    },
    canUseCC() {
      let psp = this.Finance.PSPs['visa-mastercard']

      if (!this.User.wallet.currency) {
        return false
      }
      if (!psp.BlockedCurrencies && !psp.BlockedCountries) {
        return true
      }

      if (this.isCorporate()) {
        return false
      }

      let PSPCurrencyAllowed = !psp.BlockedCurrencies || !psp.BlockedCurrencies.length || psp.BlockedCurrencies.indexOf(this.User.wallet.currency.toUpperCase()) === -1
      let PSPCountryAllowed = !psp.BlockedCountries || !psp.BlockedCountries.length || psp.BlockedCountries.indexOf(this.User.info.country_code.toUpperCase()) === -1

      return PSPCurrencyAllowed && PSPCountryAllowed
    },
    isCorporate() {
      return this.$store.state.user.info.client.company_name !== undefined && this.$store.state.user.info.client.company_name !== ''
    },
    getUploadDoc() {
      // get uploaded documents
      this['logoLoader/set'](true)
      service
        .get('/documents/user/' + this.User.info.ID)
        .then(response => {
          if (response.data.Documents) {
            let uploadedDocs = response.data.Documents
            let counter = []
            uploadedDocs.forEach(document => {
              const docType = document.document_name.includes('other') ? 'other' : document.document_name
              const status = document.document_status === 'rejected' ? false : document.document_status

              if (docType !== 'other') return (this.documents[docType].status = status)
              if (docType === 'other') {
                this.$set(this.documents.other, document.document_name, { status: status, requirements: true, hasData: true })
              }

              Object.keys(this.documents.other).forEach(key => {
                if (key.includes('other') && counter.indexOf(key) === -1) {
                  if (this.documents.other[key].status !== false) counter.push(key)
                }
              })
            })
            this.otherCount =
              Number(counter[counter.length - 1].split('other')[1]) < 4
                ? Number(counter[counter.length - 1].split('other')[1]) + 1
                : Number(counter[counter.length - 1].split('other')[1])
          }
          this['logoLoader/set'](false)
          this.maxOthers = this.getMaxOtherDocs()
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    },
    ...mapActions(['logoLoader/set', 'user/setInfo']),
  },
}
</script>

<style type="text/css" scoped>
.dropzone {
  @apply bg-gray-900 rounded-lg;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 210px;
}

.dropzone:hover {
  @apply bg-gray-800;
}
</style>
