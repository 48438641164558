<template>
  <div  class="md:relative w-full text-right md:w-auto ltr:maxMd:pr-3 rtl:maxMd:pl-3 ">
    <!-- the GUI icon -->
    <button @click="showPopover()" ref="button" class="toggle-button">
      <div class="cursor-pointer h-[40px] w-[40px] border md:border-0 border-white bg-blue rounded-full font-display font-bold text-[18px] text-white text-center py-2 leading-6 md:leading-[26px]">{{ extractInitials }}</div>
    </button>
    <!-- the popover -->
    <div v-if="displayHide" class="fixed md:absolute top-[66px] md:top-[45px] ltr:right-0 rtl:left-0 z-9 w-full md:hidden h-screen md:h-[90vh] bg-blue"></div>
    <div v-closable="{ exclude: ['button'], handler: 'onClose'}" v-if="displayHide" class="fixed md:absolute top-20 md:top-[50px] ltr:right-0 rtl:left-0 z-10 w-full md:w-auto">
      <div class="w-[90%] md:w-[300px] pt-5 pb-4 bg-white rounded-md shadow-ts1 m-auto flex flex-col justify-center items-center border border-solid border-gray-300">
        <div class="flex flex-col justify-center items-center">
          <!-- image div -->
          <div>
            <div class="h-[80px] w-[80px] bg-blue rounded-full text-2xl font-display font-bold text-white text-center py-7">{{ extractInitials }}</div>
          </div>
          <div class="text-center mt-3 mb-2.5">
            <div v-if="verified == true" class="px-8 text-green text-xs font-semibold pb-1">
              {{ $t('verified_account') }}
            </div>
            <div v-else-if="isPending" class="px-8 text-orange-light text-xs font-semibold pb-1">
              {{ $t('pending_account').tTitle() }}
            </div>
            <div class="font-display font-bold text-lg text-gray-900">{{ capitalizedFirstName }} {{ capitalizedLastName }}</div>
            <div class="font-body text-xs text-gray-900">{{ emailAddress }}</div>
          </div>
          <!-- button  -->
          <div v-if="showManageYourAccount" class="mb-8 mx-10">
            <router-link
              class="h-10 border-2 py-[8px] px-[25px] border-blue-500 rounded-md font-body text-sm text-blue-500 table"
              :to="'/' + Language + '/profile'"
              @click.native="showPopover()"
              >{{ $t('mng_your_ac') }}</router-link
            >
          </div>
        </div>
        <!-- separation line hr -->
        <div class="bg-gray-300 w-full h-px"></div>

        <!-- help button  -->
        <a
          class="mt-4 mb-3 w-[270px] h-10 py-2 px-6 rounded-md font-body text-sm bg-blue-500 text-white text-center leading-loose"
          :href="contactLink"
          target="_blank"
          >{{ $t('help') }}</a
        >

        <!-- log out button -->
        <a href="" class="mb-6 px-6 font-body text-sm text-center text-gray-900" @click="logout()">{{ $t('sign_out').tTitle() }}</a>

        <!-- separation line hr -->
        <div class="bg-gray-300 w-full h-px"></div>

        <!-- Terms | Privacy | Risk Disclosure -->
        <div class="font-body text-xs text-center text-gray-900 pt-3">
          <a :href="privacyPolicyLink" target="_blank">{{ $t('privacy') }}</a> |
          <a :href="riskDisclosureLink" target="_blank">{{ $t('risk_disclosure').tCapitalizeFirstLetter() }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '../config'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import links from '../config/links'

// This variable will hold the reference to
// document's click handler
let handleOutsideClick
Vue.directive('closable', {
  bind (el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation()
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value
      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          const excludedEl = vnode.context.$refs[refName]
          // See if this excluded element
          // is the same element the user just clicked on
          clickedOnExcludedEl = excludedEl.contains(e.target)
        }
      })
      // We check to see if the clicked element is not
      // the dialog element and not excluded

      // array below is needed for mobile
      let menuItemsArray = ['open-mobile-menu','close-mobile-menu','menu-toggle-icon','middle-menu-line']
      if (!el.contains(e.target) && !clickedOnExcludedEl && !menuItemsArray.includes(e.target.id)) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]()
      }
    }
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unbind () {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
})

export default {
  name: 'ProfilePopover',
  components: {},
  props: ['firstName', 'lastName', 'coName', 'emailAddress', 'verified'],
  data() {
    return {
      displayHide: false,
      websiteURL: config.URL.website,
      showManageYourAccount: true,
      isPending: false
    }
  },
  watch: {
    isEmailVerified(v) {
      if (v !== undefined) {
        if (v.verified) {
          this.showManageYourAccount = true
        } else {
          this.showManageYourAccount = false
        }
      }
    },
    UserInfo(u) {
      if (u.ID <= 0) {
        return
      }
      if (u.client) {
        const accountState = u.client.account_state
        if(accountState=='pending'){
          this.isPending = true
        }
      }
    }
  },
  mounted(){

  },
  computed: {
    capitalizedFirstName() {
      if (this.firstName) {
        return this.firstName[0].toUpperCase() + this.firstName.substring(1)
      } else if (this.coName) {
        return this.coName[0].toUpperCase() + this.coName.substring(1)
      } else return ''
    },
    capitalizedLastName() {
      if (this.lastName !== undefined && this.lastName !== '') {
        return this.lastName[0].toUpperCase() + this.lastName.substring(1)
      } else return ''
    },
    extractInitials() {
      let initials = ''
      if (this.firstName && this.lastName) {
        initials = this.firstName.charAt(0) + this.lastName.charAt(0)
        return initials.toUpperCase()
      } else if (this.coName) {
        initials = this.coName.charAt(0)
        return initials.toUpperCase()
      } else {
        return ''
      }
    },
    privacyPolicyLink() {
      return links.getLink('privacyPolicy', this.Language);
    },
    riskDisclosureLink() {
      return links.getLink('riskDisclosure', this.Language);
    },
    contactLink() {
      return links.getLink('contact', this.Language);
    },

    ...mapGetters({
      Language: 'language/code',
      isEmailVerified: 'user/isEmailVerified',
      UserInfo: 'user/info',
    }),
  },
  methods: {
    showPopover() {
      this.displayHide = !this.displayHide
    },
    onClose () {
      this.displayHide = false
    },
    logout() {
      this.$emit('log-out')
    },
  },
}
</script>
