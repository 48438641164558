<template>
  <div>
    <sub-menu
      :title="title"
      :routes="routes"
    ></sub-menu>

    <router-view></router-view>
  </div>
</template>

<script>
import SubMenu from '@/components/SubMenu.vue'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      title: this.$t('partners').tTitle(),
      routes: this.getRoutes()
    }
  },
  components: {
    SubMenu,
  },
  watch: {
    Language() {
      this.updateRoutesAndTitle()
    }
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
    }),
  },
  methods: {
    getRoutes() {
      return [
        { path: '/partner', title: this.$t('overview').tTitle() },
        { path: '/partner/marketing-material', title: this.$t('marketing_material').tTitle() },
      ]
    },
    updateRoutesAndTitle() {
      this.title = this.$t('partners').tTitle()
      this.routes = this.getRoutes()
    }
  }
}
</script>
