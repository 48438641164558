<template>
  <div class="w-full bg-theme-600 px-4 py-3 flex items-center justify-between sm:px-6 text-theme">
    <div class="flex-1 flex justify-between sm:hidden">
      <a
        class="relative inline-flex justify-center items-center w-[60px] h-[35px] text-sm border-border-blue font-body rounded-md cursor-pointer"
        :class="page === 1 ? 'hidden':'bg-transparent text-blue'"
        @click="changePage(page-1)"
      >
        {{ $t('previous') }}
      </a>
      <a
        class="ltr:ml-3 rtl:mr-3 relative inline-flex justify-center items-center w-[60px] h-[35px] text-sm border-border-blue font-body rounded-md cursor-pointer"
        :class="page === lastPage ? 'bg-blue text-white':'bg-transparent text-blue'"
        @click="changePage(page+1)"
      >
        {{ $t('next').tTitle() }}
      </a>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <nav
          class="relative z-0 inline-flex rounded-md select-none"
          aria-label="Pagination"
        >
          <a
            class="relative inline-flex justify-center items-center w-[35px] h-[35px] text-blue border border-blue rounded-md m-1 text-sm font-body cursor-pointer"
            :class="{'hidden': page === 1}"
            @click="changePage(page-1)"
          >
            <span class="sr-only text-blue">{{ $t('previous') }}</span>
            <!-- Heroicon name: solid/chevron-left -->
            <img
              width="9px"
              src="../../src/assets/img/icons/arrow-back-circle-outline.svg"
            >
          </a>

          <a
            v-for="(p, i) in pageList"
            :key="`pagination-number-list-${i}`"
            aria-current="page"
            class="relative inline-flex justify-center items-center w-[35px] h-[35px] border border-blue rounded-md m-1 text-sm font-body cursor-pointer"
            :class="page === p ? 'z-10 bg-blue text-white':'bg-transparent text-blue'"
            @click="changePage(p)"
          >
            {{ p }}
          </a>
          <a
            class="relative inline-flex justify-center items-center w-[35px] h-[35px] text-blue border border-blue rounded-md m-1 text-sm font-body cursor-pointer"
            @click="changePage(page+1)"
          >
            <span class="sr-only">{{ $t('next').tTitle() }}</span>
            <!-- Heroicon name: solid/chevron-right -->
            <img
              width="9px"
              src="../../src/assets/img/icons/arrow-forward-circle-outline.svg"
            >
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    page: Number,
    maxPerPage: Number,
    total: Number,
  },
  data() {
    return {}
  },
  computed: {
    showing() {
      const from = (this.page - 1) * this.maxPerPage + 1
      let to = (this.page - 1) * this.maxPerPage + this.maxPerPage
      if (to > this.total) {
        to = this.total
      }
      return { from, to }
    },
    lastPage() {
      return Math.ceil(this.total / this.maxPerPage)
    },
    pageList() {
      const delta = 2
      const range = Array(this.lastPage)
        .fill()
        .map((_, index) => index + 1)
      return range.reduce((pages, page) => {
        // allow adding of first and last pages
        if (page === 1 || page === this.lastPage) {
          return [...pages, page]
        }
        // if within delta range add page
        if (page - delta <= this.page && page + delta >= this.page) {
          return [...pages, page]
        }
        // otherwise add 'gap if gap was not the last item added.
        if (pages[pages.length - 1] !== '...') {
          return [...pages, '...']
        }
        return pages
      }, [])
    },
  },
  mounted() {},
  methods: {
    changePage(page) {
      if (!Number.isInteger(page)) {
        return
      }
      if (page < 1 || page > this.lastPage) {
        return
      }

      this.$bus.$emit('pageChanged', page)
    },
  },
}
</script>
