export const en = {
  account_list: 'account list',
  create_live_account: 'create live account',
  upgrade_to_live_account: 'Upgrade to Live Account',
  create_demo_account: 'create demo account',
  finance: 'Finance',
  deposit_funds: 'deposit funds',
  withdraw_funds: 'withdraw funds',
  internal_transfer: 'internal transfer',
  external_transfer: 'external transfer',
  payment_history: 'payment history',
  my_cards: 'my cards',
  personal_information: 'personal information',
  change_password: 'change password',
  change_password_of: 'change password of',
  upload_documents: 'upload documents',
  upload_documents_for_account_approval: 'upload documents for account approval',
  guidance_documents: 'Guidance Documents',
  partner: 'Partner',
  overview: 'overview',
  one_final_step: 'one final step',
  clients: 'clients',
  sub_ibs: 'sub IBs',
  payout: 'payout',
  marketing_material: 'marketing material',
  become_a_partner: 'become a partner',
  what_geographic_region: 'From which region are you targeting your clients?',
  website_social_media: 'Website/social media',
  skype_ID: 'Skype ID',
  submit: 'submit',
  changepass_error: 'Current password is wrong, please try again.',
  changepass_success: 'Password changed successfully',
  current_password: 'current password',
  new_password: 'new password',
  confirm_new_password: 'confirm new password',
  account_approval_pending: 'account approval pending',
  account_must_be_approved_message: 'Your account must be approved prior to creating a new live trading account.',
  new_trading_account_message: 'New trading account created successfully',
  password_generated_for_ta: 'A password was generated and sent to your email for your new trading account',
  choose_your_desired_trading_platform_and_settings: 'Choose your desired trading platform and settings',
  select_leverage: 'select leverage',
  create: 'create',
  select_account_type: 'select account type',
  select_currency: 'select currency',
  no_trading_account_found: 'No trading account found.',
  bankwire_notice:
    'Thank you for submitting your bank details. We will register your details with our bank.<br><br>You will receive the wire details via email within 24 hours alongside your personal payment reference. You can use these details for any future deposits.<br><br><strong>IMPORTANT:</strong> The payment reference is personalized for one client. If you do not use the payment reference you receive, we will not be able to accept your deposit. No third parties are able to use the same payment reference.',
  deposit: 'deposit',
  account_details: 'account details',
  account_id: 'Account ID',
  continue: 'continue',
  // bankwire_details_notice: 'Our bank requires your bank details in order to process your deposit. Any deposits made via Wire Transfer will be returned to the same account from which you deposited.',
  bankwire_deposit_details_notice:
    'Our bank requires your bank details in order to process your deposit. Any deposits made via Wire Transfer will be returned to the same account from which you deposited.',
  bankwire_withdrawal_details_notice:
    'Our bank requires your bank details in order to process your withdrawal. Any withdrawals made via Wire Transfer will be returned to the same account from which you deposited.',
  bank_name: 'bank name',
  full_name: 'full name',
  amount: 'amount',
  deposit_failed: 'deposit failed',
  deposit_successful: 'deposit successful',
  login: 'log in',
  already_logged_in: 'already logged in',
  trades: 'trades',
  client_id: 'Client ID',
  trading_account: 'Trading account',
  platform: 'platform',
  order: 'order',
  pair: 'pair',
  volume: 'volume',
  close_date: 'close date',
  commission: 'commission',
  status: 'status',
  total_commission_generated: 'total commission generated',
  total_deposits: 'total deposits',
  total_withdrawals: 'total withdrawals',
  date_joined: 'date joined',
  more: 'more',
  banners: 'banners',
  partner_payout_request_message: 'Your request have been recorded.',
  bank_address: 'Bank Address',
  your_provider_email: 'Your {provider} email',
  select: 'select',
  next: 'next',
  bank: 'bank',
  state: 'state',
  date: 'date',
  transfer_from: 'transfer from',
  transfer_to: 'transfer to',
  internal_transfer_processed_successfully: 'Internal transfer was processed successully.',
  external_transfer_processed_successfully: 'External transfer was processed successully.',
  select_account: 'select account',
  to: 'to',
  warning_currency_conversion_will_take_place: 'warning: currency conversion will take place',
  transfer: 'transfer',
  account: 'account',
  currency: 'currency',
  balance: 'balance ',
  equity: 'equity',
  download: 'download',
  launch: 'launch',
  email: 'email',
  password: 'password',
  forgot: 'forgot',
  forgot_password: 'forgot password',
  risk_warning_footer_uncollapsed:
    '<span class="font-semibold">Risk Warning:</span> Leveraged Contracts for Difference (‘CFDs’) and Forex products are complex financial instruments that are traded on margin.',
  risk_warning_footer_uncollapsed_mobile: '<span class="font-semibold">Risk Warning:</span> Leveraged Contracts for Difference...',
  risk_warning_footer: `<span class="font-semibold">Risk Warning:</span> Leveraged Contracts for Difference (‘CFDs’) and Forex products are complex financial instruments that are traded on margin. Before deciding to trade, please ensure that you understand the risks involved whilst taking into account your investment objectives and level of experience. You should not risk more than you can afford to lose. All information on this website is not directed toward soliciting citizens or residents of North Korea, UK, Syria, United States, Canada, EEA or any other jurisdiction that would be contrary to local law or regulation. Trading leveraged products such as CFDs and Forex may not be suitable for all investors as they carry a degree of risk to your capital. Please ensure that you fully understand the risks involved, and if necessary, seek independent advice. Please read the full <a href="{websiteURL}assets/files/risk-disclosure-1.pdf?v=1.5" target="_blank" class="font-semibold">Risk Disclosure.</a>`,
  error_contact_support: 'Something went wrong, please contact support.',
  type: 'type',
  username: 'username',
  company_name: 'company name',
  website: 'website',
  first_name: 'First Name',
  last_name: 'Last Name',
  date_of_birth: 'Date of Birth',
  gender: 'gender',
  phone: 'phone',
  dial_code: 'Dialing Code',
  country: 'country',
  address: 'address',
  year_moved_to_address: 'year moved to address',
  post_code: 'post code',
  post_zip_code: 'Postal / Zip Code',
  selected_currency: 'selected currency',
  january: 'january',
  february: 'february',
  march: 'march',
  april: 'april',
  may: 'may',
  june: 'june',
  july: 'july',
  august: 'august',
  september: 'september',
  october: 'october',
  november: 'november',
  december: 'december',
  already_have_an_account: 'Already have an account?',
  invalid_request_login_required: 'invalid request, you need to login first.',
  individual: 'individual',
  company: 'company',
  company_information: 'company information',
  company_website: 'company website',
  title: 'title',
  confirm_password: 'confirm new password',
  social_platform: 'How did you hear about PlexyTrade?',
  other_social_platform: 'Please tell us where you heard about PlexyTrade',
  select_country: 'select country',
  phone_number: 'Phone Number',
  choose_trading_platform: 'choose trading platform',
  select_option: 'select option',
  day: 'day',
  month: 'month',
  year: 'year',
  company_registration_information: 'company registration information',
  date_of_registration: 'date of registration',
  select_nationality: 'select nationality',
  nationality: 'nationality',
  select_gender: 'select gender',
  male: 'male',
  female: 'female',
  no_gender: 'I prefer not to say',
  address_line: 'Address Line',
  city_town: 'Town / City',
  state_province: 'Province / State',
  select_year: 'select year',
  company_shareholders_directors: 'company shareholders & directors',
  number_of_shareholders: 'number of shareholders',
  number_of_directors: 'number of directors',
  shareholder: 'shareholder',
  financial_information: 'financial information',
  total_estimated_net_worth: 'total estimated net worth (€)?',
  less_than_amount: 'less than {amount}',
  over_amount: 'over {amount}',
  total_estimated_annual_income: 'total estimated annual income (€)?',
  employment_information: 'employment information',
  employment_status: 'employment status',
  employed: 'employed',
  self_employed: 'self employed',
  retired: 'retired',
  student: 'student',
  unemployed: 'unemployed',
  source_of_income: 'source of income',
  employment: 'employment',
  inheritance: 'inheritance',
  investment: 'investment',
  trading_experience: 'trading experience',
  forex_cfds_and_other_instruments: 'forex, cfds and other instruments',
  yes: 'yes',
  no: 'no',
  lose_capital_risk:
    'Derivative products are suitable as part of my investment objectives and attitude towards risk and i am able to assess the risk involved in trading them, including the possibility that i may lose all of my capital',
  prev_pro_qualifications: 'I have previous professional qualifications and/or work experience in the financial services industry',
  account_type: 'account type',
  choose_currency: 'choose currency',
  tos_with_link: 'I have read, understood and accepted the terms and conditions and risk warning disclosure <a class="font-bold" target="_blank" href="{URL}">here</a>.',
  privacy_policy_link: 'I have read and accepted the <a class="font-bold" target="_blank" href="{URL}">Privacy Policy</a>.',
  terms_link: 'I have read and accepted all <a class="font-bold" target="_blank" href="{URL}">Terms and Conditions</a>.',
  registration_successful: 'registration successful',
  reset_password_invalid_link: 'Your reset link is invalid, please try again or contact support.',
  reset_account_password: 'reset account password',
  choose_your_new_password: 'choose your new password',
  reset_password: 'reset password',
  withdrawal_request_success_message: 'your withdrawal request has been successful',
  withdraw: 'withdraw',
  withdrawal: 'withdrawal',
  cancel: 'cancel',
  you_became_a_partner_successfully: 'You became a Plexytrade partner successfully!',
  note_wallet_currency_conversion_partner:
    "Please note that by becoming a Partner, your wallet's currency will be changed to USD and that any current funds will be converted to USD with the current exhchange rate!",
  upgraded_to_a_live_account_successfully: 'Upgraded to a live account successfully!',
  passwords_dont_match: "Passwords don't match",
  password_rules: 'At least 1 capital, 1 lowercase and 1 number (6 to 16 max characters)',
  only_currency_format_allowed: 'Only currency format allowed',
  internal_server_error_support: 'Internal server error. Please contact support',
  please_fill_all_fields: 'Please fill all fields',
  no_logs_found_for_this_client: 'No logs found for this client',
  internal_server_error_try_again: 'Internal server error. Please try again later',
  insufficient_balance: 'Insufficient balance',
  currency_conversion_connection_error: 'No response from currency conversion server, please try again or contact support.',
  currency_conversion_error: 'Currency conversion error, please try again or contact support.',
  invalid_accounts: 'Invalid accounts',
  withdrawal_unsuccessful_equity_message: 'Withdrawal unsuccessful, not enough equity on trading account {TAID}',
  withdrawal_failed_free_margin: 'Withdrawal failed. Please double check your free margin.',
  pamm_copy_server_field_message: 'please copy and paste the IP address in to the "Server" field in your MT4 server',
  invalid_request: 'invalid request',
  missing_shareholders_directors: 'Missing shareholders/directors',
  missing: 'Missing',
  invalid_request_passwords_do_not_match: 'Invalid request. Passwords do not match',
  email_exists_message: 'Email exists, please try with another email address.',
  your_payment_method_has_already_been_set: 'Your payment method has already been set. Please contact support',
  payment_method_is_required_message: 'Payment method is required. Please try again or contact support',
  couldnt_find_your_account: "Couldn't find your account",
  ta_limit_error_message: 'Your account has reached the trading accounts limit({limit}) for {platform}.',
  error: 'error',
  account_created_successfully: 'account created successfully',
  invalid_leverage: 'invalid leverage',
  insufficient_data_provided: 'insufficient data provided',
  insufficient_data_provided_missing_field: 'Insufficient data provided. Missing {field}.',
  invalid_amount: 'invalid amount',
  account_taid_doesnt_exist: "Account {TAID} doesn't exist",
  withdrawal_unsuccessful_equity_taid: 'Withdrawal unsuccessful, not enough equity on trading account {TAID}',
  withdrawal_unsuccessful_free_margin_taid: 'Withdrawal unsuccessful, not enough free margin on trading account {TAID}',
  internal_server_error: 'internal server error',
  deposit_failed_support: 'Deposit was unsuccessful, please contact support.',
  upload_documents_instructions: 'Please provide us with the following documents.<br>Files should be in PDF, JPG or PNG format and must not exceed 5mb.',
  total_earned: 'total earned',
  total_paid: 'total paid',
  total_clients: 'total clients',
  total_sub_ibs: 'Total sub IBs',
  welcome_title_live: 'Thank you for opening your account with Plexytrade!',
  welcome_title_demo: 'Thank you for opening your demo account with Plexytrade!',
  welcome_title_ib: 'Thank you for opening your Partners account with Plexytrade!',
  welcome_text_live:
    '<p>To get funded, approved and ready to trade, you need to complete the following steps:</p><ul><li>Log in to your Plexytrade Client Portal</li><li>Upload your documents</li><li>Fund your account</li><li>Start trading!</li></ul><p>If you need any help, our friendly customer support team is available 24/5. To contact them, click <a href="{contactURL}" target="_blank">here</a></p>',
  welcome_text_demo:
    '<p>To get funded, approved and ready to trade, you need to complete the following steps:</p><ul><li>Download the MT4 platform</li><li>Test your trading skills and our execution speeds</li><li>Log in to your Client Portal and upgrade to a live account</li><li>Upload your documents and fund your account</li><li>Trade the largest financial market in the world!</li></ul><p>If you need any help, our friendly customer support team is available 24/5. To contact them, click <a href="{contactURL}" target="_blank">here</a></p>',
  welcome_text_ib:
    '<p>In order to have your Partners account approved and ready for you to receive your referral URL, you need to complete the following steps:</p><ul><li>Log in to your Client Portal</li><li>Upload your documents</li><li>Receive your tracking URL in the "overview" and "marketing tools" section</li><li>Refer your clients and receive commission!</li></ul><p>If you need any help, our friendly customer support team is available 24/5. To contact them, click <a href="{contactURL}" target="_blank">here</a></p>',
  tracking_links: 'tracking links',
  login_to_your_account: 'login to your account',
  register: 'register',
  open_demo_account: 'open demo account',
  page_title_partner: 'Become a partner',
  home: 'home',
  about: 'about',
  account_types: 'account types',
  spreads: 'spreads',
  funding: 'funding',
  education: 'education',
  contact: 'contact',
  open_live_account: 'open live account',
  not_registered_with_question: 'Not registered with Plexytrade?',
  forgot_password_q: 'forgot your password?',
  pending_deposit_success: 'Operation successful, your deposit is now pending approval',
  local_depositor: 'Local depositor',
  converted_amount: 'converted amount',
  reference_id: 'reference id',
  from_account: 'from account',
  to_account: 'to account',
  from_date: 'from date',
  to_date: 'to date',
  wallet: 'wallet',
  received: 'received',
  send: 'send',
  sender_not_a_transfer_account: 'sender is not a local depositor',
  withdrawal_failed_support: 'Withdrawal was unsuccessful, please contact support.',
  clear: 'clear',
  transfer_completed_successfully: 'transfer completed successfully',
  receive: 'receive',
  view_trades: 'view trades',
  your_provider_account_number: 'Your {provider} account number',
  your_cryptocurrency_address: 'Your cryptocurrency address',
  your_crypto_address: '{crypto} address',
  please_select: 'Please select',
  destination_tag: 'Destination tag',
  dashboard: 'Dashboard',
  sent: 'sent',
  client_state: 'Account status',
  client_state_approved: 'approved',
  client_state_pending: 'pending',
  client_state_incomplete: 'incomplete',
  open_trades: 'open trades',
  closed_trades: 'closed trades',
  trade_history: 'trade history',
  search_trades: 'search trades',
  trade_no: 'trade no',
  symbol: 'symbol',
  open_price: 'open price',
  close_price: 'close price',
  datetime: 'datetime',
  my_bonuses: 'my bonuses',
  bonus: 'bonus',
  conversion_progress: 'conversion progress',
  progress_towards: 'progress towards',
  front_of_id: 'Front of ID',
  back_of_id: 'Back of ID',
  front: 'Front',
  include_account_in_bonus_program:
    'I would like to receive the 120% Cash Bonus and I agree with <a class="font-bold" href="https://plexytrade.com/assets/files/bonus-terms-and-conditions-1.pdf?v=1.9" target="_blank">Bonus Terms and Conditions</a>.',
  agree_with_no_deposit_bonus: 'I have read and understood the No Deposit Bonus <a class="font-bold" target="_blank" href="{URL}">terms and conditions</a>.',
  master_account: 'master account',
  master_accounts: 'master accounts',
  p_l: 'P&L',
  bonus_tos_with_link: 'I have read and understood the <a class="font-bold" target="_blank" href="{URL}">terms and conditions of the 120% Funded Trading Program</a>.',
  i_am_over_18: 'I declare that I am over 18 (eighteen) years old to open this Account.',
  profit: 'profit',
  attach: 'attach',
  private: 'private',
  private_code: 'private code',
  investor_link: 'Investor Link',
  i_accept_the_lpoa: 'I accept the <a href="{URL}" target="_blank" class="text-blue">LPOA</a>.',
  read_and_accept_lpoa: 'I have read and accept the <a href="{URL}" target="_blank" class="text-blue">LPOA</a>.',
  must_accept_lpoa: 'The LPOA must be accepted.',
  invest: 'invest',
  name: 'name',
  search: 'search',
  partners: 'partners',
  reject_withdrawals_during_open_trades: 'reject withdrawals during open trades',
  private_master_account: 'private master account',
  minimum_deposit: 'minimum deposit',
  min_deposit: 'Min Deposit',
  performance_fee: 'performance fee',
  display_name: 'display name',
  new_master_account: 'new master account',
  pamm_statistics: 'pamm statistics',
  pamm_masters: 'PAMM Masters',
  pamm: 'PAMM',
  volume_traded: 'volume traded',
  cannot_create_more_pamm_master_accounts: 'Cannot create more pamm master accounts.',
  additional_terms: 'Tick if any additional terms apply',

  // new with vue
  bitcoin: 'Confirmo',
  cryptocurrency: 'Cryptocurrency',
  cryptocurrency_address: 'Cryptocurrency Address',
  register_live_account: 'register live account',
  withdrawal_confirmo_amount_error: 'Withdrawal Limit Exceeded. The requested withdrawal amount exceeds the maximum limit of $50,000. Please try again with a lower amount.',
  confirmo_deposit_note: '<span class="font-semibold">Attention:</span> Please note that for the time being, Confirmo does not support Bitcoin Lightning withdrawals.',
  register_partner_account: 'register partner account',
  register_demo_account: 'register demo account',
  risk_disclosure: 'risk disclosure',
  address_line_2: 'Address Line 2',
  skype_id: 'skype id',
  social_media: 'Social media',
  password_rules_extended: 'The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 symbol and 1 number. Password length must be between 6 to 15 characters.',
  login_information: 'login information',
  director: 'director',
  pmethod_visa_mastercard: 'VISA/MASTERCARD',
  pmethod_visa_mastercard_alternative: 'VISA/MASTERCARD.',
  pmethod_bitcoin: 'BITCOIN',
  pmethod_bankwire: 'Bankwire',
  pmethod_fasapay: 'Fasapay',
  pmethod_ideal: 'ideal',
  pmethod_instant_bank_transfer: 'Instant Bank Transfer',
  pmethod_jeton: 'Jeton',
  pmethod_jeton_wallet: 'Jeton Wallet',
  pmethod_my_pay: 'MyPay',
  pmethod_neteller: 'NETELLER',
  pmethod_vload: 'PayRedeem',
  pmethod_paykasa: 'Paykasa',
  pmethod_skrill: 'Skrill',
  pmethod_sofort: 'Sofort',
  pmethod_koalapay: 'KoalaPay',
  pmethod_cup: 'CUP',
  pmethod_lydiax: 'Crypto',
  pmethod_safecharge: 'SafeCharge',
  pmethod_trustly: 'Trustly - Bank Wire',
  pmethod_powercash: 'PowerCash',
  field_min_currency_value: 'The {field} field must be {min} or more.',
  method_deposit: '{method} deposit',
  method_deposit_status: '{method} deposit status',
  quick_menu: 'quick menu',
  details: 'details',
  date_from: 'date from',
  date_to: 'date to',
  from: 'from',
  forgot_password_form_instruction: "Please submit your account's email address below. You will then receive an email with a link for resetting your password.",
  forgot_password_submit_success_message: 'A password reset link has been sent to your email. Please go to your email ({email}) and follow the link to change your password.',
  password_reset_successfully: 'Your password has been successfully reset',
  password_too_week_or_mismatch: 'Password too weak or passwords entries mismatch.',
  withdrawals_credit_card_remaining_amount_message: 'In order to withdraw via other methods, you must first withdraw {amount} back to your credit card.',
  withdrawals_credit_card_refund_policy_message: 'Credit Card providers only allow you to withdraw the total amount you deposited back to your card.',
  withdrawals_amount_withdrawable_message: 'A total of {amount} can be withdrawn back to your card, anything over that amount can be withdrawn by one of our other methods.',
  country_blocked_for_this_method: 'Your country is blocked for this method',
  copied_text_successfully: 'copied {text} successfully',
  choose_wallet_currency: 'choose wallet currency',
  pamm_master_account_created_successfully: 'PAMM master account created successfully!',
  account_number: 'Account Number',
  export_csv: 'Export to CSV',
  or_account_no_for_us: 'or Account Number (for US Clients)',
  your_physical_address: 'Your physical address, matching the address you have used when registering for the account',
  must_be_a_utility_bill: 'Must be a Utility Bill (electricity, water, telephone, gas /energy, council tax/sanitation bills) or a Bank statement',
  for_further_info_attach: '**For further documentation, please attach them on an email and send to backoffice@plexytrade.com',
  banners_ad_blocker_notice: 'If you have trouble viewing the banners below, please disable your ad-blocker',
  transfer_out: 'transfer out',
  transfer_in: 'transfer in',
  done: 'done',
  not_required: 'not required',
  wallet_balance: 'wallet balance',
  must_agree_terms: 'You must agree to our Terms and Conditions to create an account',
  must_agree_privacy: 'You must agree to our Privacy Policy to create an account',
  select_payment_method: 'select payment method',
  card_number: 'card number',
  expiry: 'Expiry',
  redeem: 'redeem',
  must_be_multiple_of_10: 'Value must be a multiple of 10',
  select_starting_balance: 'Select starting balance',
  business_name: 'business name',

  error_getting_VPS: "Error: Couldn't get VPS",
  error_request_failed: 'Error: Request failed',
  error_no_user_with_this_email: 'Error: An account with this email address was not found',
  error_too_many_password_reset_requests: 'Error: Too many password resets requested',
  error_expired_or_invalid_request: 'Error: Invalid or expired request',
  error_insufficient_wallet_balance: 'Error: Insufficient balance in wallet',
  error_insufficient_balance_or_equity: 'Error: Insufficient balance or equity',
  error_pamm_master_deposit: 'Cannot deposit in a PAMM master account',
  error_pamm_master_withdrawal: 'Cannot withdraw from a PAMM master account',
  error_pamm_master_locked_deposits: 'Rejected: The pamm master of the account to which money is deposited rejects deposits during open trades.',
  error_pamm_master_locked_withdrawals: 'Rejected: The pamm master of the account from which money is withdrawn rejects withdrawals during open trades.',
  error_pamm_master_minimum_deposit: "Rejected: The pamm master's minimum deposit requirement was not met.",
  error_live_account_already: 'Your account is a live account already',
  error_min_balance_required_is: 'The minimum balance required for the {TAAccountType} account({TAID}) is {min}{currency}.',
  error_transfer_same_wallet: 'Cannot transfer from wallet to wallet.',
  error_transfer_same_trading_account: 'Cannot transfer from and to the same trading account.',
  error_no_deposit_bonus_account_deposit_restricted: 'Deposits to "No Deposit Bonus" type of account is not allowed.',
  error_no_deposit_bonus_base_amount_withdrawal: 'Cannot withdraw base amount of "No Deposit Bonus".',
  error_no_deposit_bonus_withdraw_profit_requirement_not_met: 'Cannot withdraw "No Deposit Bonus" if profit requirement has not been met.',
  error_no_deposit_bonus_withdraw_exceeds_max_withdrawable_profit: 'Cannot withdraw more than the max withdrawable profit.',
  upgrade_required_for_live_ta: 'Live and Approved client account is required before a live trading account can be opened!',
  error_wallet_currency_not_allowed_for_psp: 'Wallet currency not allowed for this payment method.',
  error_invalid_or_expired_otp: 'Error: Provided OTP is invalid or expired.',
  error_max_confirmo_withdrawal_exceeded: 'Error: Maximum confirmo withdrawal request is 20000 EUR/USD',
  error_max_anual_vload_withdrawal_exceeded: 'Error: Maximum anual PayRedeem withdrawal requests total amount exceeded',

  // Check for islamic trading account (temporarily disabled)
  is_islamic_acc_message: 'In order to create an Islamic account, please send a request to backoffice@plexytrade.com as to assist you further.',
  error_invalid_voucher: 'Error: PayRedeem eCard invalid or already redeemed',
  error_blocked_voucher: 'Error: PayRedeem eCard is blocked due to fraud suspicion',
  error_voucher_data_mismatch: 'Error: PayRedeem eCard was declined due to mismatch of account data.',
  error_vload_voucher_below_minimum: 'Error: PayRedeem eCard below minimum deposit amount.',
  my_bank_has_a_swift_code: 'My bank has a swift code',
  my_bank_does_not_have_a_swift_code: 'My bank does not have a swift code',
  bank_not_in_swift_network_instructions:
    'If your bank is not a SWIFT network member, please provide us with the following details of your bank’s Correspondent bank in order to receive International wires:',
  correspondent_bank_name: "Correspondent Bank's Name",
  correspondent_bank_city: "Correspondent Bank's City",
  correspondent_bank_swift: "Correspondent Bank's Swift",

  complete_profile: 'Complete Profile',
  document_received: 'Your document was received and will be reviewed by our team.',
  must_be_over_18: 'You must be over 18 years of age to register.',
  shareholders_must_be_over_18: 'Shareholders must be over 18 years of age to register.',
  directors_must_be_over_18: 'Directors must be over 18 years of age to register.',
  passwords_must_match: 'The New Password confirmation does not match',
  internal_error: 'An error occurred. Please try again.',
  please_enter_your_email: 'Please enter your email address.',
  reset_your_old_password: 'Reset your old password',
  new_old_password_are_the_same: 'New password may not be the same as old password',
  reset_password_desc: 'Due to a system transfer, your are required to update your password. Please use the form below to receive a reset password link.',
  eurotrader_login: 'Plexytrade Login | Enter the trading hub to level up your trading',
  your_email_is_verified: 'Your email is already verified.',
  click_here_to_login: 'Click here to login',
  success_email_verified: 'Success, Your email is now verified!',
  your_link_expired: 'Your link has expired',
  click_here_to_request_verification_email: 'Request a new verification email',
  a_new_verification_link_send: 'A new verification link has been sent to your email address.',
  invalid_request_please_check_your_link: 'Invalid request. Please check your link is correct and try again.',
  invalid_verification_code: 'Invalid verification code',
  please_enter_amount: 'Please enter the amount you wish to deposit',
  bankwire_company_desc:
    '<p>If you are a business bank account holder and you want to use the option of bank wire transfer by registering your business account with us, please send us the following documentation via e-mail to backoffice@plexytrade.com</p><ul><li>Certificate of Incorporation/Company</li><li>Certificate of Directors and Secretary, if applicable</li><li>Certificate of Registered Shareholders/ Sole Owner.</li><li>Memorandum and Articles of Association, also known as Articles</li><li>Proof of Identification and Address for all registered Shareholder/s, Beneficial Owner/s and Director/s.</li><li>Proof of address relating to the company.</li></ul><p>Any other government/official documents verifying the above requested information can be considered sufficient, in case the above documents cannot be provided in such format.</p>',
  bankwire_noncompany_desc:
    '<p>This will log you out from your personal account and redirect you to our registration page for a Company account.</p><p>Would you like to proceed?</p>',
  please_enter_card_detail_and_amount: 'PLEASE ENTER CARD DETAILS AND AMOUNT YOU WISH TO DEPOSIT',
  important: 'IMPORTANT',
  please_note_that_the_maximum_deposit_amount: 'Please note that the maximum deposit amount per day is $1000 per card.',
  city: 'City',
  zip: 'Zip',
  please_note_that_after_three_unsuccessful_attempts:
    '* Please note that after three (3) unsuccessful attempts the VISA/MASTERCARD payment option will be unavailable for 24hours.',
  epay_pro_desc:
    '<p>Dear Trader<br><br>Unfortunately, due to certain technical issues and restrictions, Credit Card Deposits will be temporarily disabled via our provider EPayPro.<br><br>Visa & Mastercard will be disabled at midnight 23:00 GMT 04/04/2018. Following the payment provider being disabled, deposits will be temporarily disabled. Although our payment providers have not provided us with an exact time frame for the downtime, we are working on implementing alternatives as soon as possible.<br><br>We are working with our providers to resolve the current issues so that in future, the accounts can resume processing deposits without delay.<br><br>Feel free to contact our support team should have any further questions.</p>',
  provider_redirect: 'You will be redirected to {provider}.',
  lydiax_note: 'The minimum deposit is 50 USD/EUR',
  lydiax_note1:
    'If you deposit less than 50 USD/EUR worth of {crypto}, you will need to send an email to backoffice@plexytrade.com, and request a refund, providing the {crypto} address you wish to be refunded to.',
  lydiax_note2:
    'Please enter the {crypto} amount you wish to deposit to see an estimation of what you will get. Keep in mind that the following prices are only valid for when they were requested and can change any moment.',
  lydiax_note3: 'We will create a deposit address for you to use.',
  lydiax_note4: 'Please use the following address to deposit {crypto}.',
  lydiax_note5: 'Hereafter, this unique address will be used for your deposits in {crypto}.',
  lydiax_required_address: 'If your wallet requires address and tag separately use:',
  crypto_note: 'Note',
  crypto_note_line_1: 'This payment method operates only in Euros!',
  crypto_note_line_2: 'Deposits sent in another currency, will be subject to conversion fees that our Company cannot cover.',
  crypto_note_line_3: 'To avoid unexpected charges, please consider alternative deposit methods.',
  tag: 'Tag',
  lydiax_enter_deposit: 'PLEASE ENTER THE CRYPTOCURRENCY YOU WISH TO DEPOSIT',
  lydiax_withdrawal_limit_error: 'Withdrawal Limit Exceeded. The requested withdrawal amount exceeds the maximum limit of $50,000. Please try again with a lower amount.',
  lydiax_please_select: 'Please select',
  create_address: 'Create address',
  please_note: 'Please note:',
  price_estimate: 'Price estimate:',
  check_price: 'Check price',
  vload_please_enter_voucher: 'Please enter your PayRedeem eCard PIN',
  vload_minimum: '<p>(Minimum acceptable eCard value is <b>50</b> EUR or USD)</p>',
  vload_vouchers: 'I need to purchase PayRedeem eCards',
  vload_note:
    'Make a hassle-free payment by Visa/MasterCard as well as Bank Wire Transfer.<br />Learn more about PayRedeem or buy a [EUR / USD] eCard at <a href="https://app.payredeem.com/buy" target="_blank" class="font-semibold">app.payredeem.com/buy</a>.',
  vload_note1: 'In order to be able to deposit with PayRedeem, your account must first be approved!',
  reason: 'Reason:',
  attempts: 'Attempt {attempts} of {maxAttempts},',
  two_more_remaining: 'two (2) more remaining',
  one_more_remaining: 'Only ONE (1) attempt remaining',
  please_contact_bank: '<br />Please contact the card-issuing bank.<br /> Your card may be blocked for overseas transactions',
  edata_success_note:
    'Please note that there may be an additional fee on your deposit, charged by your issuing bank due to the international transaction. This fee will be small charge, but it will vary from bank to bank. <br><br>This credit card transaction will appear on your statement as“ Tech Savvy Billing ", “Business Billing “ or “Business media Billing “.',
  you_can_try: 'you can try again',
  here: 'here',
  safe_charged_success_message: 'Your deposit will be processed by our backoffice within 2 working days.',
  thank_you: 'Thank you!',
  your_deposit_successful: 'Your deposit was successful',
  card_redirect: 'You will be redirected to enter your credit card and personal details.',
  bank_wire_withdrawal_note:
    'In case of rejection of a Bank Wire Transfer on behalf of the receiving bank due to wrong bank details provided by the client, various bank wire administration expenses charged by the banks involved will be subtracted from the requested withdrawal amount prior to crediting the client’s wallet.',
  please_enter_amount_withdraw: 'Please enter the amount you wish to withdraw',
  bitcoin_note: 'Please select your cryptocurrency network',
  crypto_network_address: 'Please fill in your cryptocurrency address',
  otp: 'One-time Password (OTP)',
  otp_send_email: 'Send OTP to Email',
  otp_additional_text: 'The above OTP is for the current Bitcoin withdrawal request you are making',
  otp_new_password: 'A new one-time password (OTP) has been sent to your email',
  otp_error: 'Error - Failed to send OTP',
  lydiax_withdrawal_note:
    'Please enter the {currency} amount you wish to withdraw to see an estimation of {crypto} that you will get. Keep in mind that the following prices are only valid for when they were requested and can change any moment.',
  enter_currency_amount: 'Enter {currency} amount to check estimate',
  lydiax_withdrawal_additional_note: 'The above OTP is for the current Crypto withdrawal request you are making',
  estimated: 'Estimated:',
  please_enter_your_crypto_address: 'Please enter your {crypto} address',
  crypto_address: 'Please enter your crypto address',
  destination_tag_required:
    'We strongly advise checking with your wallet provider if they require a destination tag before entering the requested details, to prevent any loss of funds.',
  select_cryptocurrency: 'Select cryptocurrency',
  request_amount: 'Requested Amount',
  amount_changes: 'Amount Charged (includes fee)',
  operation_failed: 'Operation failed! Your request was invalid.',
  operation_failed_error: 'Operation failed! There was an error processing this request.',
  declarations: 'DECLARATIONS',
  please_check_forms_errors: 'Please check the forms for errors!',
  profile_succesfully_completed: 'Profile successfully completed',
  empty_country_code: 'Empty country code, please refresh the page and try again.',
  empty_dropzone: 'You need to fill the empty box first',
  invalid_country_code: 'Invalid country code, please refresh the page and try again.',
  prev: 'PREV',
  previous: 'Previous',
  complete: 'Complete',
  please_select_an_option: 'Please select an option for each field!',
  swap: 'swap',
  buy: 'buy',
  sell: 'sell',
  sums: 'sums',
  net_total: 'NET TOTAL',
  deposit_page_desc: 'Sign up now and get a FREE 10 EUR/USD eVoucher',
  deposit_page_desc1: 'The 10 EUR/USD Bonus eVoucher will be activated once you',
  create_new_account: 'Create a new Account',
  create_new_master_account: 'Create new master account',
  successfully_purchase_vload: 'successfully purchase and redeem a PayRedeem eCard of any value',
  upgrade_to_tier_2: 'Upgrade to tier 2',
  register_now: 'REGISTER NOW',
  back: 'back',
  my_card_desc: 'Here you can see all the cards you`ve used for payments and their current status in our system.',
  you_have_no_card: 'You have made no card deposits yet.',
  card_no: 'Card No.',
  last_used: 'Last used',
  risk_warning: 'RISK WARNING',
  drop_files_upload: 'Drop files here to upload',
  date_until: 'Date until',
  copy: 'Copy',
  cvv: 'CVV',
  please_enter_crypto_amount: 'Enter {crypto} amount',
  vload_pin: 'PayRedeem Pin',
  deposit_info:
    'The payment provider is currently experiencing technical issues and direct Visa/Mastercard deposits are currently unavailable. Alternatively, if you would like to deposit using Visa/Mastercard you can use the `PayRedeem` option',
  id: 'ID',
  pamm_modal_error: 'Invalid entry for Display Name. Please do not leave empty or enter more than 32 characters',
  pamm_modal_error1: 'Invalid value entered for Minimum Deposit. Please enter a positive whole number under 1,000,000.',
  error_missing_required_field: 'Error, missing required field',
  records: 'Records',
  gateway: 'gateway',

  // Demo Account
  region: 'Region',
  wallet_currency: 'Wallet Currency',
  join: 'join',
  demo_account: 'Demo Account',
  page_title_demo: 'Open demo account',
  what_is_demo: 'What Is A Demo Trading Account?',
  demo_explanation:
    'Our free trading demo account is a hands-on way for you to explore the platform, practise trading and try out different strategies without committing a dime or a dollar. With it, you can dip your toe in each market before diving in headfirst.',

  // 404 Page
  title_404: 'Something is wrong',
  text_line_1_404: 'The bad news is that the page you were looking for does not exist.',
  text_line_2_404: 'The good news is that you can still trade with Plexytrade.',
  button_404: 'Go back',

  remember_me: 'Remember me',
  risk_sentence: 'To see how we use your data, please review our',
  video_courses: 'Video Courses',
  ebooks: 'E-Books',

  //Complete Profile
  knowledge_and_experience: 'KNOWLEDGE AND EXPERIENCE',
  economic_profile: 'ECONOMIC PROFILE',
  product_governance: 'PRODUCT GOVERNANCE',
  select_an_answer: 'Select an answer',
  declarations_q1: 'Are you currently, or have you ever been, involved in any criminal activity or are there any outstanding criminal charges against you pending before a court?',
  declarations_q2:
    'Are you or a relative or close associate of yours a Politically Exposed Person? Politically Exposed Persons are the natural persons who are or have been entrusted with prominent public functions within the last 12 months, i.e Heads of State or of government, senior politicians, senior government, judicial or military officials, senior executives of state owned corporations, important political party officials.',
  declarations_q3: 'Are you a U.S citizen or U.S. resident for tax purposes?',
  live_account: 'Live account',
  data_policy_text:
    'Your data is safe with us, and you can unsubscribe at any time. By clicking below, you are confirming that you have read and accepted the <a href="{TERMS_CONDITIONS_URL}" target="_blank">T&Cs</a> and <a href="{RISK_DISCLOSURE_URL}" target="_blank"> risk disclosure</a> and you consent to us storing and processing your personal information.',
  partner_account: 'Partner Account',
  privacy_policy: 'Privacy Policy',
  live_account_title: 'We’re so excited to get you started!<br/>Tell us, are you joining as an individual or a company?',

  two_factor_register:
    'In order to verify your phone number, we will need to send you a One-Time Password. Once it is sent, please enter the code that you received in the field that appears below.',
  two_factor_login: 'A message has been sent to the phone number that was registered with your user details. Please enter the code in the field below to complete your login.',

  deposits: 'Deposits',
  withdrawals: 'Withdrawals',
  see_more: 'See more',
  all: 'All',
  create_account: 'Create account',
  account_balance: 'Account Balance',
  trading_account_currency: 'Trading Account Currency',
  last_five_days: 'Last 5 days',
  learn_more: 'Learn more',
  welcome_bonus: 'Cash Bonus',
  welcome_bonus_breakline: '<span class="text-green">120%</span><br />Cash<br />Bonus',
  analytics: 'Analytics',
  transactions: 'Transactions',
  settings: 'Settings',
  platforms: 'Platforms',
  select_operating_system: 'Select operating system',
  select_platform: 'Select platform',
  trading: 'Trading',
  mng_your_ac: 'Manage your account',
  almost_finished: 'You are almost there. Finish account setup',
  acc_approved: 'Account has been approved',
  create_trading_account: 'Create trading account',
  select_type_of_account: 'Select type of account',
  select_balance: 'Select balance',
  please_select_all_options: 'Please select all the options',
  type_of_account: 'Type of account',
  starting_balance: 'Starting balance',
  error_message_create_account: 'Something went wrong. Please try again.',
  leverage: 'Leverage',
  would_like_to_receive_bonus:
    'I would like to receive the 120% Cash Bonus and I agree with <a class="text-blue" href="https://plexytrade.com/assets/files/bonus-terms-and-conditions-1.pdf?v=1.9" target="_blank">Bonus Terms and Conditions</a>.',
  receive_bonus: 'Receive 120% Cash Bonus',
  need_help: 'Need help?',
  forgot_password_text: 'Forgot your password? Please submit your account’s email address below. You will then receive an email with a link for resetting your password.',

  //Plexytrade document strings
  id_front: 'Proof of Legal Existence/ Front',
  id_back: 'Proof of Legal Existence / Back',
  cc_front: 'Proof of credit/debit card / Front',
  cc_back: 'Proof of credit/debit card / Back',
  address_proof: 'Proof of Address',
  other: 'Add a document (optional)',
  id_back_info: '',
  cc_front_info: '',
  cc_back_info: '',
  address_proof_info: '',
  other_info: '*You can add up to 4 additional documents.',

  id_front_instructions: 'Please upload your ID, Passport or Driver’s License.',
  id_back_instructions: 'Please upload your ID, Passport or Driver’s License.',
  cc_front_instructions: 'Please upload your credit or debit card.',
  cc_back_instructions: 'Please upload your credit or debit card.',
  address_proof_instructions: 'Please upload your Utility Bill, Bank statement, Mortgage Statement and Loan Statement.',
  other_instructions: 'Other documents which have been requested by our verification department.',
  documents_must_contain_all_of_the_following_details: 'Documents must meet the following requirements:',

  id_front_req1: 'Full copy of the document showing all 4 corners.',
  id_front_req2: 'Document must be in color',
  id_front_req3: 'Your full name',
  id_front_req4: 'A unique ID number',
  id_front_req5: 'Your birthdate',
  id_front_req6: 'Your place of birth',
  id_front_req7: 'A visible photo',
  id_front_req8: 'Your nationality',
  id_front_req9: 'Issue date',
  id_front_req10: 'Expiry date',
  id_back_req1: 'Full copy of the document showing all 4 corners.',
  id_back_req2: 'Document must be in color',
  id_back_req3: 'Your full name',
  id_back_req4: 'A unique ID number',
  id_back_req5: 'Your birthdate',
  id_back_req6: 'Your place of birth',
  id_back_req7: 'A visible photo',
  id_back_req8: 'Your nationality',
  id_back_req9: 'Issue date',
  id_back_req10: 'Expiry date',
  cc_front_req1: 'Full copy of the document showing all 4 corners.',
  cc_front_req2:
    'Card name: the card you will be using must be issued in your name solely. Any deposits made using a card belonging to a third party, will be immediately refunded.',
  cc_front_req3: 'Expiry Date: no less than 3 months.',
  cc_front_req4: 'Card number: The middle eight digits of the card must be covered with a piece of paper. Only the first four and last four digits should be visible.',
  cc_back_req1: 'Full copy of the document showing all 4 corners.',
  cc_back_req2:
    'Card name: the card you will be using must be issued in your name solely. Any deposits made using a card belonging to a third party, will be immediately refunded.',
  cc_back_req3: 'Expiry Date: no less than 3 months.',
  cc_back_req4: 'Card number: The middle eight digits of the card must be covered with a piece of paper. Only the first four and last four digits should be visible.',
  cc_back_req5: 'CVV code: Please use a piece of paper to cover the CVV code.',
  cc_back_req6: 'The card must be signed.',
  address_proof_req1: 'Full copy of the document showing all 4 corners',
  address_proof_req2: 'Your full name',
  address_proof_req3: 'Your physical address, matching the address you have used when registering for the account',
  address_proof_req4: 'Recent date (not older than 6 months)',
  address_proof_req5: 'Must be a Utility Bill (electricity, water, telephone, gas /energy, council tax/sanitation bills) or a Bank statement',
  address_proof_req6: 'Full postcode should be shown.',
  other_req1: 'Full copy of the document showing all 4 corners',

  click_add_another_doc: 'Click to add another document upload slot. You can add up to 4 additional documents.',
  btn_new_slot: 'New slot',
  additional_docs_email: '*Additional documentation can be sent in via email to backoffice@plexytrade.com',
  doc_approved: 'Your document has been approved.',

  opt_in_options: 'Opt-in selections (prototype)',
  cpa: 'CPA',
  copied: 'Copied successfully!',
  try_before_u_trade: 'try before you trade',
  client_details: 'Client details',
  select_trading_account: 'Select trading account',
  total_commission: 'total commission',
  banner: 'Banner',
  vps: 'VPS',
  hosting: 'hosting',
  vps_text: 'Get uninterrupted, powerful VPS hosting for non-stop access to your trading platform along with safety, stability and incomparable speed.',
  order_now: 'Order now',
  vps_packages: 'VPS packages',
  choose_vps_package: 'Please choose a VPS package',
  standard: 'Standard',
  silver: 'Silver',
  platinum: 'Platinum',
  per_month: 'per month',
  standard_text: 'or <b>Free</b><br />with {standard_min_deposit} min Deposits',
  gold_text: 'or <b>Free</b><br />with {gold_min_deposit} min Deposits',
  advance_text: 'or <b>Free</b><br />with {advance_min_deposit} min Deposits',
  per_live_trading_account: 'per live trading account',
  terms_and_conditions_apply: 'Terms & conditions apply',
  gold: 'Gold',
  most_popular: 'Most popular',
  advance: 'Advance',
  equivalent: 'equivalent',
  something_went_wrong: 'Something went wrong. Please try again.',
  verify: 'verify',
  choose_trading_account: 'Please choose trading account',
  vps_description: 'The VPS service is available on a per live trading account basis. For every additional VPS package, an equal number of live trading accounts is required.',
  agree_to_terms: 'I agree to <a href="{URL}" target="_blank" class="font-semibold underline">terms and conditions</a>',
  renewal_on: 'Renewal on',
  vps_hosting_service: 'VPS hosting service',
  load_more: 'Load more',
  please_select_payment_method: 'Please select payment method',
  lydiax: 'Crypto',
  select_crypto_network: 'please select your {crypto} network',
  vload: 'PayRedeem',
  popular_deposits: 'Popular deposits',
  popular_withdrawals: 'Popular withdrawals',
  my_settings: 'Plexy Profile',
  personal_details: 'Personal details',
  profile: 'Profile',
  pamm_account: 'PAMM',
  help: 'Help',
  transfer_history: 'Transfer history',
  total_bonuses_granted: 'Total bonuses granted',
  total_bonuses_converted: 'Total bonuses converted',
  bonuses: 'Bonuses',
  pamm_dashboard: 'PAMM Dashboard',
  pamm_text_line_1: 'Pamm Statistics are available at our PAMM Client area.',
  pamm_text_line_2: 'If you have a PAMM account, please click the link below to be redirected, and use your PAMM account credentials to login.',

  please_enter_amount_transfer: 'Please enter the amount you wish to transfer',
  live: 'Live',
  demo: 'Demo',
  no_available_live_account: 'You don’t have an available live trading account. <span class="underline cursor-pointer font-bold"><br />Click here to create one</span>.',
  check_your_options: 'Confirm your details to create your account',
  okay: 'Ok',
  hard_disk: 'Hard disk',
  included: 'included',
  quick_links: 'Quick links',
  vps_hosting_service_dashboard: '<span class="text-green">VPS</span> hosting<br />',
  vps_hosting_service_dashboard_mobile: '<span class="text-green">VPS</span> hosting',
  hello: 'Hello',
  shareholders_directors: 'shareholders & directors',
  alpha_generation_plugin: 'Alpha Generation<br />Plugin',
  daily_market_newsletter: 'Daily<br />Market<br />Newsletter',
  no_records: 'No records',
  subscribe: 'Subscribe',
  sign_out: 'Sign out',
  account_approved: 'Account approved',
  verified_account: 'Verified account',
  pending_account: 'Pending account',
  privacy: 'Privacy',
  reset: 'Reset',
  change_password_note:
    'This password change will not affect your trading account passwords. Please visit account list to change your trading account passwords, using the change password option under the gear icon.',
  newsletter_sub_full: "You're all set! Check your mailbox for the Daily Market Newsletter.",
  newsletter_sub_partial: 'Thanks for signing up! You need {amount} more deposits to start receiving the Daily Market Newsletter.',

  logoloader_newsletter_subscribing: 'Subscribing...',
  statistics: 'Statistics',
  requirements: 'Requirements',
  select_file: 'Select file',
  vload_vouchers_text: 'If you need to purchase PayRedeem eCards, please visit <a href="https://payredeem.com/" target="_blank" class="font-semibold">payredeem.com</a>',
  vload_text_1: 'Pay with multiple currencies, credit/debit cards, bank transfers, crypto and local payments.',
  vload_text_2: 'Create an account at <a href="https://payredeem.com/" target="_blank" class="font-semibold">payredeem.com</a>',
  vload_text_3: 'Click buy and select website.',
  vload_text_4: 'Pay with your preferred payment method.',
  vload_text_5: 'Redeem the eCard PIN.',
  vload_text_6: 'Need help? Go to <a href="https://help.payredeem.com/" target="_blank" class="font-semibold">help.payredeem.com</a> and contact us 24/7 via chat, email or phone.',
  wd_vload_text_1:
    'When you initiate a withdrawal, Payout eCards will be issued to your PayRedeem account. You can use these eCards at any store accepting PayRedeem, or you can cash them out to your bank account or cryptocurrency wallet.',
  wd_vload_text_2: 'You can find your Payout eCards on your <a href="https://app.payredeem.com/payouts" target="_blank" class="font-semibold">PayRedeem profile dashboard</a>.',
  wd_vload_text_3: 'In some cases, you might be asked to provide your Payout Address. You can easily find it on your profile, under the Payouts section.',
  country_of_residence: 'Country of Residence',
  firstname_placeholder: 'Same as on your Identity Document',
  lastname_placeholder: 'Same as on your Identity Document',
  personal_and_address_info: 'Personal and Address Information',
  address_placeholder: 'Street name, house, apartment no',
  gender_other: 'Other',
  optional: 'Optional',
  email_address: 'Email Address',
  trading_account_info: 'Trading Account Info',
  trading_platform: 'Trading Platform',
  account_currency: 'Account Currency',
  marketing_checkbox: 'I would like to receive Company news, products updates, and promotions.',
  invalid_email: 'Please enter a valid email address.',
  launch_webtrader: 'Launch Webtrader',
  accept: 'I have read and accepted the ',
  partners_agreement: 'Partner’s Agreement',
  recaptchaText:
    'This site is protected by reCAPTCHA and the Google <a class="font-bold" href="https://policies.google.com/privacy">Privacy Policy</a> and <a class="font-bold" href="https://policies.google.com/terms">Terms of Service</a> apply.',
  verify_your_email: 'Verify your email',
  verify_your_email_description:
    'An email with a validation code was sent to your registered email address. To complete your registration, please verify your email address by adding the 6-digit code below.',
  code_not_recieved: "Didn't receive code?",
  resend: 'Resend',
  confirm_email: 'Confirm email',
  contact_us: 'Contact us',
  try_again: 'Try Again',
  try_again_description: 'The code you entered is not correct',
  attempts_left: 'attempts left',
  resend_code: 'Resend Code',
  try_again_expired: 'Please try again! The code you entered has expired.',
  verification_failed: 'Verification failed',
  verification_failed_header: 'Email address verification failed!',
  verification_failed_description: 'Are you having trouble verifying your email?',
  bonus_120: '120% Bonus',
  no_bonus: 'No Bonus',
  cash_bonus_120: '120% Cash Bonus',
  reg_form_step_1_individual: 'Personal Information',
  reg_form_step_1_company: 'Company information',
  reg_form_step_2_individual: 'Personal & Address Information',
  reg_form_step_2_company: 'Shareholders & Directors',
  reg_form_step_3_individual: 'Trading Account Info',
  reg_form_step_3_company: 'Company Registration & Profile Details',
  reg_disclaimer_title: 'What Is A Demo Trading Account?',
  reg_disclaimer_text: 'Our free trading demo account is a hands-on way for you to explore the platform, practise trading and try out different strategies.',
  trading_account_type: 'Trading Account Type',
  company_name_placeholder: 'Same as on your Company Registration Document',
  referal_links: 'Referral links',
  registration_link: 'Registration page',
  website_home_page: 'Website`s home page',
  bonus_page: 'Bonus page',
  pamm_page: 'PAMM page',
  internal_transfer_note: 'You can’t transfer between 2 trading accounts.',
  upgrade_to_live_text: 'In order to proceed with any transaction, you must first upgrade to Live and have your account approved.',
  upgrade_to_live: 'Upgrade to Live',
  upload_your_documents_text: 'In order to proceed with any transaction, you must first need to upload your documents and have your account approved.',
  upload_your_documents: 'Upload your documents',
  under_maintenance: 'Under Scheduled Maintenance',
  under_maintenance_text: 'We apologize for any inconveniences caused.<br>Will be back soon.',
  processed: 'Processed',
  cancelled: 'Cancelled',
  pending: 'Pending',
  requested: 'Requested',
  approved: 'Approved',
  declined: 'Declined',
  mismatch: 'Mismatch',
  sending: 'Sending',
  lydiax_deposit_note: 'Please ensure to always click on the create address button and check the generated address before proceeding with a new deposit with PLEXYTRADE.',
  important_note: 'Important note',
  withdrawal_note: 'Please note that in order to be able to submit a withdrawal request, your account must first be fully verified.',
  failed_account_creating_message: 'Failed to create trading account: ',
  english: 'English',
  spanish: 'Spanish',
  italian: 'Italian',
  chinese: 'Chinese',
  farsi:'Farsi',
  opened_a: 'Opened a',
  position_on: 'position on',
  at: 'at',
  likes: 'likes',
  sub_status_check_fail: 'Failed to check subscription status: ',
  news_sub_req_fail: 'Failed to request newsletter subscription: ',
  lang_settings_saved: 'Language setting saved',
  unable_save_lang_settings: 'Unable to save language setting',
  archived: 'Archived',
  welcome_to_plexytrade: 'Welcome to Plexytrade',
  select_demo_live: 'Please select an account type',
  export: 'Export',
  success_vps_order: 'Successfully requested new VPS',
  success:'Success',
  gains_prc: 'Gains %',
  open_p_l: 'Open P&L',
  monthly_avg: 'Monthly Avg',
  pamm_name_or_account_id: 'Name or Account ID',
  invest_under_master_account: 'Invest under Master account',
  view_graph: 'View Graph',
  pamm_master_locks_deposits: 'This PAMM Master restricts deposits during open trades.',
  pamm_master_locks_withdrawals: 'This PAMM Master restricts withdrawals during open trades.',
  i_accept_the_terms_of_service: 'I accept the <a href="{URL}" target="_blank" class="text-blue">Terms of Service</a>',
  enter_private_code: 'Enter private code',
  additional_terms_apply: 'Additional Terms apply, please check the box if you are aware.',
  select_a_pamm_account_to_attach: 'Select a PAMM account to attach',
  create_and_attach_new_pamm_account: 'Create and attach new PAMM account',
  create_and_attach: 'create and attach',
  max_drawdown: 'max. drawdown',
  all_time: 'all time',
  last_month: 'last month',
  last_week: 'last week',
  last_3_days: 'last 3 days',
  create_pamm_question: 'Are you sure you wish to create a new account and attach it under this master?',
  attach_pamm_question: 'Are you sure you wish to attach selected account to this master?',
  additional_terms_check: "By proceeding, you confirm you understand that you are bound by the Fund Manager's Terms.",
  refer_to_pamm_dept: 'Please refer to the PAMM dept for further information',
  pamm_operation_failed_error: 'Operation failed! There was an error processing this request.',
  pamm_operation_failed_invalid: 'Operation failed! Your request was invalid.',
  pamm_success: 'You have successfully attached a PAMM Investor.',
  attached_to: 'Attached to',
  display_name_on_statistics_table: 'Display name on Stats Table',
  server_name: 'Server name',
  congratulations: 'Congratulations',
  credentials_to_login_to_mt: 'Please use the details below to log into the MetaTrader platform with your new account',
  // guidance documents
  identity_card_text_front_1: 'Place the front of your Identity Card on a flat surface (eg, a desk or counter), avoiding any light reflections or shadows.',
  identity_card_text_back_1: 'Place the back of your Identity Card on a flat surface (eg, a desk or counter), avoiding any light reflections or shadows.',
  identity_card_text_2: 'Please ensure that you are uploading all requested documentation individually.',
  identity_card_text_3: 'The document must be provided in colour.',
  identity_card_text_4: 'Ensure that your Identification Card is valid for at least 3 months.',
  identity_card_image_text: '1. The entire document must be visible as a whole, ensuring all four corners are included.',
  passport_text_1: 'Place your Passport on a flat surface (eg, a desk or counter), avoiding any light reflections or shadows.',
  passport_text_2: 'Please ensure that you are uploading all requested documentation individually.',
  passport_text_3: 'Ensure that your Passport is valid for at least 3 months.',
  passport_image_text: 'Document must be open so that both pages are included in the picture',
  driving_license_text_front_1: 'Place the front of your Driving License on a flat surface (eg, a desk or counter), avoiding any light reflections or shadows.',
  driving_license_text_back_1: 'Place the back of your Driving License on a flat surface (eg, a desk or counter), avoiding any light reflections or shadows.',
  driving_license_text_2: 'Please ensure that you are uploading all requested documentation individually.',
  driving_license_text_3: 'The document must be provided in colour.',
  driving_license_text_4: 'Ensure that your Driving Licence is valid for at least 3 months.',
  driving_license_image_text: '1. The entire document must be visible as a whole, ensuring all four corners are included.',
  bank_statement_text_1: 'Place your document on a flat surface (eg. desk or counter), ensuring all relevant details are included.',
  bank_statement_text_2: 'The document must be valid for at least 6 months from the issue date.',
  bank_statement_image_text_1: '1. The document must not be cropped or folded, and the entire page must be visible, including all four corners',
  bank_statement_image_text_2: '2. Cut a piece of paper and hide the numbers as shown so that only the first six and last four digits are visible',
  bank_statement_image_text_3: '3. Your name, Issue date and Company logo must be visible.',
  bank_statement_image_text_4: '4. Your full address (must be the same address as the one you provided when registering with PlexyTrade)',
  utility_bill_text_1: 'Place the document on a flat surface (e.g. desk/table), ensuring all required details are visible.',
  utility_bill_text_2: 'Avoid any light reflections or shadows.',
  utility_bill_text_3: 'The document must be in colour.',
  utility_bill_text_4: 'All four corners must be clearly visible within the provided image.',
  utility_bill_text_5: 'The document must be valid for at least 6 months from the issue date.',
  utility_bill_image_text_1: '1. The document must not be cropped or folded, and the entire page must be visible, including all four corners.',
  utility_bill_image_text_2: '2. Your name, Issue date and Company logo must be visible.',
  utility_bill_image_text_3: '3. Your full address (must be the same address as the one you provided when registering with PlexyTrade).',
  identity_card: 'Identity Card',
  passport: 'Passport',
  driving_license: 'Driving License',
  bank_statement: 'Bank Statement',
  utility_bill: 'Utility Bill',
  transfer_back_to_my_local_depositor: 'transfer back to my local depositor',
  transfer_back: 'Transfer back',
  Copied: "Copied!",
  banner_trading:"Banner Trading",
  banner_pamm_platform: "Banner PAMM Platform",
  banner_mt4_mt5_platform: "Banner MT4 + MT5 Platform",
  banner_cash_bonus: "Banner 120% Cash-Bonus",
  banner_leverage:"Banner Leverage",
  confirmo_maintenance_note: 'Confirmo is currently under maintenance, and it will be available again shortly. <br /><br />We apologize for the inconvenience.',
  required: 'required',
  referred: "Referred (Family/Friend/Associate...)",
  other_text: "other"
}
