const state = {
	all: {}
}

const mutations = {
	REQUEST_ADD(state, res) {
		if (!res.uid) {
			return
		}
		state.all[res.uid] = res.data
	},
	REQUEST_EDIT(state, res) {
		if (!res.uid) {
			return
		}
		state.all[res.uid] = res.data
	},
	REQUEST_DELETE(state, res) {
		if (!res.uid) {
			return
		}
		delete state.all[res.uid]
	},
}

const actions = {
	add: ({ commit }, { uid, data }) => commit('REQUEST_ADD', { uid, data }),
	edit: ({ commit }, { uid, data }) => commit('REQUEST_EDIT', { uid, data }),
	delete: ({ commit }, { uid, data }) => commit('REQUEST_DELETE', { uid, data }),
}

const getters = {
	all: state => state.all
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

