<template>
	<div class="content has-text-centered">
		<success></success>	
	</div>
</template>

<script>
import Success from './Success'

export default {
	components: {
		Success,
	},
	data() {
		return {
			title: '',
		}
	},
	mounted() {
		this.title = this.$t('method_deposit_status', {method: 'Credit Card'})
		this.$bus.$emit('ChangeHeadings', this.title, 'Credit Card')
	},
	methods: {}
}
</script>
