<template>
  <div class="content has-text-centered">
    <RegisterAll :translations="translations" :legalType="legalType" :upgrade="upgrade" :accountType="type"/>
    <!-- <live
      :translations="translations"
      :step="step"
      :legalType="legalType"
      :upgrade="upgrade"
      v-if="$route.meta.registerComponent == 'Live' || $route.meta.registerComponent == 'Upgrade-live'"
    ></live> -->
    <!--
    <demo :translations="translations" :step="step" :legalType="legalType" v-if="$route.meta.registerComponent == 'Demo'"></demo>
    <partner :translations="translations" :step="step" :legalType="legalType" v-if="$route.meta.registerComponent == 'Partner'"></partner> -->

    <logo-loader v-if="logoLoaderActive" :text="logoLoaderText"></logo-loader>
    <div class="hidden lg:block absolute ltr:right-6 rtl:left-6 top-6">
      <language-popover></language-popover>
    </div>

  </div>
</template>

<script>
// import Live from './Live.vue'
// import Demo from './Demo.vue'
// import Partner from './Partner.vue'
import RegisterAll from './RegisterAll.vue'
import { service } from '../../main.js'
import { mapGetters, mapActions } from 'vuex'
import { Validator } from 'vee-validate'
import config from '../../config'
import { Base64 } from 'js-base64'
import LogoLoader from '../../components/LogoLoader.vue'
import LanguagePopover from '../../components/Language.vue'
import links from '../../config/links'


export default {
  components: {
    // Live,
    // Demo,
    // Partner,
    LogoLoader,
    RegisterAll,
    LanguagePopover
  },
  data() {
    return {
      logID: 0,
      step: 1,
      skipStep: null,
      type: this.$route.meta.registerComponent.toLowerCase(),
      upgrade: false,
      userID: false,
      legalType: 'individual',
      isLead: false,
      formData: {},
      translations: {
        months: {},
        genders: {},
        text: {},
      },
      loadingNextStep: false,
      blockFlag: false
    }
  },
  watch: {
    Language(l) {
      if (l !== null && typeof l !== 'undefined') {
        Validator.localize(l)
        this.setTranslations()
      }
    },
    'BonusConfig.noDepositBonus.enabled'() {
      if (this.BonusConfig.noDepositBonus.enabled) {
        if (this.$route.query.ndbonus === '1') {
          this.$bus.$emit('grantNoDepositBonus', true)
        }
      }
    },
  },
  computed: {
    TOSURL() {
      return links.getLink('companyLegalDoc', this.Language);
    },
    bonusTOSURL() {
      return config.URL.website + ''
    },
    noDepositBonusURL() {
      return links.getLink('noDepositBonusURL', this.Language);
    },
    LPOA_URL() {
     return links.getLink('acceptTheLpoa', this.Language);
    },
    PrivacyURL() {
     return links.getLink('privacyPolicy', this.Language);
    },
    termsAndConditionsURL() {
     return links.getLink('termsOfService', this.Language);
    },
    riskDisclosureURL() {
      return config.URL.website + ''
      // return config.URL.website + 'pdf/' + this.Language + '/legal-risk-disclosure.pdf'
    },
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
      BonusConfig: 'bonusconfig/all',
      logoLoaderActive: 'logoLoader/active',
      logoLoaderText: 'logoLoader/text',
    }),
  },
  mounted() {
    // legacy redirection links
    if (this.$route.query.refid !== undefined) {
      // get first ib code
      const IBID = parseInt(this.$route.query.refid)
      service
        .get('/ib/legacy/get-first-code', {
          params: {
            ibid: IBID,
          },
        })
        .then(response => {
          // redirect a couple times :)
          window.location = `/${this.Language}/ib/r?t=${response.data.tracking_link}`
        })

      return
    }

    if (this.type === 'upgrade-live') {
      if (this.$store.state.user.info.ID <= 0) {
        // needs to be logged in
        this.$toasted.error(this.$t('invalid_request_login_required') + '<span class="error-id"></span>', { duration: 10000 })
        this.$router.push('/' + this.Language + '/login')
        return
      }
      if ('live'.indexOf(this.User.accountTypes) !== -1) {
        // already a live account
        this.$toasted.error(this.$t('error_live_account_already') + '<span class="error-id"></span>', { duration: 10000 })
        this.$router.push('/' + this.Language + '/')
        return
      }
      this.upgrade = true
      this.type = 'live'
      this.userID = this.$store.state.user.info.ID
    }
    if (this.type === 'live' || this.type === 'demo') {
      // remove get parameters
      history.replaceState({}, this.$route.name, this.$route.path)
      this.handleQuery()
    }
    this.setTranslations()
    Validator.localize(this.Language)
    this.$bus.$on('Complete', this.onComplete)
    this.$bus.$on('NextStep', this.onNextStep)
    this.$bus.$on('PreviousStep', this.onPreviousStep)
    this.$bus.$on('SelectLegalType', this.onSelectLegalType)


    if(this.type=='live'){
       this.$bus.$emit('ChangeHeadings', 'Open Live Account', this.$t('live_account')+ ' | Plexytrade')
    }
    else if(this.type=='demo'){
       this.$bus.$emit('ChangeHeadings', 'Open Demo Account', this.$t('demo_account')+ ' | Plexytrade')
    }
    else if(this.type=='partner'){
       this.$bus.$emit('ChangeHeadings', 'Open Partner Account', this.$t('partner_account')+ ' | Plexytrade')
    }
  },
  created() {
    if (this.type === 'upgrade-live' && this.$store.state.user.info.ID > 0 && 'live'.indexOf(this.User.accountTypes) === -1) {
      this.upgrade = true
      this.type = 'live'
      this.userID = this.$store.state.user.info.ID
    }
  },
  beforeDestroy() {
    this.$bus.$off('Complete', this.onComplete)
    this.$bus.$off('NextStep', this.onNextStep)
    this.$bus.$off('PreviousStep', this.onPreviousStep)
    this.$bus.$off('SelectLegalType', this.onSelectLegalType)
  },
  methods: {
    handleQuery() {
      // for simple lead
      if (Object.keys(this.$route.query).length > 0) {
        let required = ['title', 'firstname', 'lastname', 'email', 'phone']
        let leadData = {}
        for (let v of required) {
          if (this.$route.query[v] === '') {
            return
          }
          leadData[v] = this.$route.query[v]
        }
        // country code uppercase
        // this.$route.query['country'] = this.$route.query['country'].toUpperCase()
        // log
        this.isLead = true
        this.$bus.$emit('isLead', this.isLead)
        let data = {
          ID: this.logID,
          type: this.type,
          step: this.step,
          legalType: this.legalType,
          formData: leadData,
          isLead: this.isLead,
        }
        // log here
        service.post('/users/create/log', data).then(response => {
          this.logID = response.data.ID
        })
      }
    },
    onComplete(formData) {
      if(this.blockFlag) return
      this.blockFlag = true
      let data = {
        ID: this.logID,
        type: this.type,
        step: this.step,
        legalType: this.legalType,
        formData: formData,
        isLead: this.isLead,
      }

      // upgrade url
      let url = '/users/create/'
      if (this.upgrade) {
        url = '/users/upgrade/'
        data.userID = this.userID
      }

      // register
      data.ID = this.logID
      data.IBCode = this.$getCookie('refcode') === undefined ? '' : this.$getCookie('refcode')
      data.CXToken = this.$getCookie('cx_token') === undefined ? '' : this.$getCookie('cx_token')
      data.affID = parseInt(this.$getCookie('aff_id'))
      data.device = '-'
      data.lang = this.Language

      if (data.formData.startingDemoBalance && data.formData.startingDemoBalance !== '') {
        data.formData.startingDemoBalance = parseFloat(data.formData.startingDemoBalance)
      }

      this['logoLoader/set'](true)

      // last log
      service
        .post('/users/create/log', data)
        .then(response => {
          data.userIP = response.data.UserIP
          this.logID = response.data.ID

          service
            .post(url + this.type.toLowerCase(), data)
            .then(response => {
              if (!response.data.success) {
                this['logoLoader/set'](false)
                if (response.data.errors) {
                  let errk = Object.keys(response.data.errors)
                  for (let i = 0; i < errk.length; i++) {
                    if (this.translations.text[errk[i]]) {
                      if (response.data.errors[errk[i]] === 'required') {
                        this.$toasted.error(this.$t('error_missing_required_field') + this.translations.text[errk[i]] + '"', { duration: 10000 })
                      }
                    }
                  }
                } else {
                  this.$toasted.error(this.$t('error_contact_support'), { duration: 10000 })
                }
                return
              }

              if (this.upgrade) {
                this['logoLoader/set'](false)
                if (response.data.success) {
                  if (this.type === 'live') {
                    this.$toasted.success(this.$t('upgraded_to_a_live_account_successfully'), { duration: 10000 })
                    this.$toasted.success(this.$t('password_generated_for_ta'), { duration: 10000 })
                  }
                  this['user/reset']()
                  this['user/setInfo']({ ID: this.userID })
                  this.$router.push('/' + this.Language + '/' + config.defaultURL).catch(()=>{});
                  this.$gtag.event('upgrade_to_live', {
                    event_category: 'onboarding',
                    event_label: 'Upgrade to live',
                  })
                }
              } else {
                this['logoLoader/set'](false)
                this.login(data.formData.email, data.formData.password, data.formData.recaptchaToken)
                this.$gtag.event('sign_up_' + this.type, {
                  event_category: 'onboarding',
                  event_label: 'sign up ' + this.type,
                })
              }
            })
            .catch(error => {
              this['logoLoader/set'](false)
              if (typeof error.response !== 'undefined') {
                if (typeof error.response.data !== 'undefined') {
                  this.$toasted.error(error.response.data.detail + '<span class="error-id">' + error.response.data.id + '</span>', { duration: 10000 })
                }
              }
            })
        })
        .catch(error => {
          this['logoLoader/set'](false)
          if (typeof error.response !== 'undefined') {
            if (typeof error.response.data !== 'undefined') {
              this.$toasted.error(error.response.data.detail + '<span class="error-id">' + error.response.data.id + '</span>', { duration: 10000 })
            }
          }
        })
    },
    login(email, password, recaptchaToken) {
      this.$auth
        .login({
          url: config.API.host + 'authenticate/login',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic ' + Base64.encode(email.trim() + ':' + password + ':' + recaptchaToken),
          },
          redirect: {
            name: 'Dashboard | english',
          },
          staySignedIn: true,
        })
        .then(
          () => {
            // Success
            this.$auth.fetch().then(() => {
              // Success
              this.$bus.$emit('LiveFeedConnect')
            })
          },
          err => {
            // Error
            if (err.response) {
              this.error = err.response.data
              this.$store.state.token = null
            }
          }
        )
    },
    onNextStep(formData) {
      if (this.loadingNextStep) {
        return
      }
      this.loadingNextStep = true

      let typeLog = this.type
      if (this.upgrade) {
        typeLog = 'upgrade-' + typeLog
        formData.userID = this.userID
      }
      let data = {
        ID: this.logID,
        type: typeLog,
        step: this.step,
        legalType: this.legalType,
        formData: formData,
        isLead: this.isLead,
      }
      // log here
      service
        .post('/users/create/log', data)
        .then(response => {
          this.logID = response.data.ID

          this.step++
          while (this.step === this.skipStep) {
            this.step++
          }
          window.scroll(0, 0)
          this.loadingNextStep = false
        })
        .catch(() => {
          this.loadingNextStep = false
        })
    },
    onPreviousStep() {
      this.step--
      while (this.step === this.skipStep) {
        this.step--
      }
    },
    onSelectLegalType(type) {
      this.legalType = type
    },
    setTranslations() {
      this.translations.months = {
        1: this.$t('january').tTitle(),
        2: this.$t('february').tTitle(),
        3: this.$t('march').tTitle(),
        4: this.$t('april').tTitle(),
        5: this.$t('may').tTitle(),
        6: this.$t('june').tTitle(),
        7: this.$t('july').tTitle(),
        8: this.$t('august').tTitle(),
        9: this.$t('september').tTitle(),
        10: this.$t('october').tTitle(),
        11: this.$t('november').tTitle(),
        12: this.$t('december').tTitle(),
      }
      this.translations.genders = {
        male: this.$t('male').tTitle(),
        female: this.$t('female').tTitle(),
      }
      this.translations.text = {
        title: this.$t('title').tTitle(),
        firstname: this.$t('first_name').tTitle(),
        lastname: this.$t('last_name').tTitle(),
        email: this.$t('email_address').tTitle(),
        dial_code: this.$t('dial_code').tTitle(),
        phone: this.$t('phone_number').tTitle(),
        country: this.$t('country').tTitle(),
        country_of_residence: this.$t('country_of_residence').tTitle(),
        date_of_birth: this.$t('date_of_birth').tTitle(),
        day: this.$t('day').tTitle(),
        month: this.$t('month').tTitle(),
        year: this.$t('year').tTitle(),
        nationality: this.$t('nationality').tTitle(),
        gender: this.$t('gender').tTitle(),
        address: this.$t('address_line').tTitle(),
        address2: this.$t('address_line_2').tTitle(),
        address_placeholder: this.$t('address_placeholder'),
        post_code: this.$t('post_code').tTitle(),
        post_zip_code: this.$t('post_zip_code'),
        city: this.$t('city_town').tTitle(),
        state_province: this.$t('state_province').tTitle(),
        year_moved_to_address: this.$t('year_moved_to_address').tTitle(),
        website: this.$t('website').tTitle(),
        skype_id: this.$t('skype_id').tTitle(),
        social_media: this.$t('social_media').tTitle(),
        password: this.$t('password').tTitle(),
        confirm_password: this.$t('confirm_password').tTitle(),
        social_platform: this.$t('social_platform').tTitle(),
        other_social_platform: this.$t('other_social_platform').tTitle(),
        total_estimated_net_worth: this.$t('total_estimated_net_worth').tTitle(),
        select_option: this.$t('select_option').tTitle(),
        total_estimated_annual_income: this.$t('total_estimated_annual_income').tTitle(),
        employment_status: this.$t('employment_status').tTitle(),
        employed: this.$t('employed').tTitle(),
        self_employed: this.$t('self_employed').tTitle(),
        retired: this.$t('retired').tTitle(),
        student: this.$t('student').tTitle(),
        unemployed: this.$t('unemployed').tTitle(),
        source_of_income: this.$t('source_of_income').tTitle(),
        employment: this.$t('employment').tTitle(),
        inheritance: this.$t('inheritance').tTitle(),
        investment: this.$t('investment').tTitle(),
        other: this.$t('other').tTitle(),
        forex_cfds_and_other_instruments: this.$t('forex_cfds_and_other_instruments').tTitle(),
        yes: this.$t('yes').tTitle(),
        no: this.$t('no').tTitle(),
        lose_capital_risk: this.$t('lose_capital_risk').tTitle(),
        prev_pro_qualifications: this.$t('prev_pro_qualifications').tTitle(),
        choose_trading_platform: this.$t('choose_trading_platform').tTitle(),
        platform: this.$t('platform').tTitle(),
        account_type: this.$t('account_type').tTitle(),
        choose_currency: this.$t('account_currency').tTitle(),
        include_account_in_bonus_program: this.$t('include_account_in_bonus_program').tTitle(),
        agree_with_no_deposit_bonus: this.$t('agree_with_no_deposit_bonus', { URL: this.noDepositBonusURL }).tTitle(),
        i_accept_the_lpoa: this.$t('read_and_accept_lpoa',{ URL: this.LPOA_URL }).tTitle(),
        read_and_accept_lpoa: this.$t('read_and_accept_lpoa',{ URL: this.LPOA_URL }).tTitle(),
        company_name: this.$t('company_name').tTitle(),
        date_of_registration: this.$t('date_of_registration').tTitle(),
        number_of_shareholders: this.$t('number_of_shareholders').tTitle(),
        number_of_directors: this.$t('number_of_directors').tTitle(),
        target_geo_location: this.$t('what_geographic_region').tTitle(),
        company_website: this.$t('company_website').tTitle(),
        not_required: this.$t('not_required').tTitle(),
        tos_with_link: this.$t('tos_with_link', { URL: this.TOSURL }).tTitle(),
        bonus_tos_with_link: this.$t('bonus_tos_with_link', { URL: this.bonusTOSURL }).tTitle(),
        i_am_over_18: this.$t('i_am_over_18').tTitle(),
        select_starting_balance: this.$t('select_starting_balance', { URL: this.bonusTOSURL }).tTitle(),
        data_policy_text: this.$t('data_policy_text', { TERMS_CONDITIONS_URL: this.termsAndConditionsURL, RISK_DISCLOSURE_URL: this.riskDisclosureURL }).tTitle(),
        firstname_placeholder: this.$t('firstname_placeholder').tTitle(),
        lastname_placeholder: this.$t('lastname_placeholder').tTitle(),
        optional: this.$t('optional').tTitle(),
        privacy_policy_link: this.$t('privacy_policy_link', { URL: this.PrivacyURL }).tTitle(),
        marketing_link: this.$t('marketing_checkbox').tTitle(),
        terms_link: this.$t('terms_link', { URL: this.termsAndConditionsURL }).tTitle(),
        trading_account_currency: this.$t('trading_account_currency').tTitle(),
        account_balance: this.$t('account_balance').tTitle(),
        wallet_currency: this.$t('wallet_currency').tTitle(),
        trading_account_type: this.$t('trading_account_type').tTitle(),
        company_name_placeholder: this.$t('company_name_placeholder'),
        leverage: this.$t('leverage'),
      }
    },
    ...mapActions(['user/reset', 'user/setAccountTypes', 'user/set', 'user/setInfo', 'logoLoader/set']),
  },
}
</script>
