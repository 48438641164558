<template>
  <div class="form-wrapper grid grid-cols-4-default gap-5 mb-5">
    <!-- First Name -->
    <div class="col-span-4 md:col-span-2">
      <label class="font-body text-base text-gray-900 tracking-wide" for="firstname">{{ translations.text.firstname }}<span class="text-orange ml-1">*</span></label>
      <div class="my-2">
        <input
          v-validate="'required|alpha_spaces|min:3|max:255'"
          :class="{
            'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has(type === 'Shareholder' ? 'sh-firstname_' + activeIndex : 'dir-firstname_' + activeIndex),
          }"
          :name="type === 'Shareholder' ? 'sh-firstname_' + activeIndex : 'dir-firstname_' + activeIndex"
          type="text"
          :data-vv-as="translations.text.firstname"
          :placeholder="translations.text.firstname"
          v-model="data.firstname"
          @change="validateValues($event)"
          class="text-gray-900 text-base font-semibold"
        />
        <span v-show="errors.has(type === 'Shareholder' ? 'sh-firstname_' + activeIndex : 'dir-firstname_' + activeIndex)" class="font-body text-xs text-orange font-semibold">{{
          errors.first(type === 'Shareholder' ? 'sh-firstname_' + activeIndex : 'dir-firstname_' + activeIndex)
        }}</span>
      </div>
    </div>
    <!-- Last Name -->
    <div class="col-span-4 md:col-span-2">
      <label class="font-body text-base text-gray-900 tracking-wide" for="lastname">{{ translations.text.lastname }}<span class="text-orange ml-1">*</span></label>
      <div class="my-2">
        <input
          v-validate="'required|alpha_spaces|min:3|max:255'"
          :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has(type === 'Shareholder' ? 'sh-lastname_' + activeIndex : 'dir-lastname_' + activeIndex) }"
          :name="type === 'Shareholder' ? 'sh-lastname_' + activeIndex : 'dir-lastname_' + activeIndex"
          type="text"
          :data-vv-as="translations.text.lastname"
          :placeholder="translations.text.lastname"
          v-model="data.lastname"
          @change="validateValues($event)"
          class="text-gray-900 text-base font-semibold"
        />
        <span v-show="errors.has(type === 'Shareholder' ? 'sh-lastname_' + activeIndex : 'dir-lastname_' + activeIndex)" class="font-body text-xs text-orange font-semibold">{{
          errors.first(type === 'Shareholder' ? 'sh-lastname_' + activeIndex : 'dir-lastname_' + activeIndex)
        }}</span>
      </div>
    </div>
    <!-- Date of Birth -->
    <div class="col-span-4 lg:col-span-4 grid grid-cols-6-default gap-5">
      <!-- Year of Birth -->
      <div class="col-span-2 md:col-span-2">
        <label class="label is-small" for="dob_year">{{ translations.text.year }}<span class="text-orange ml-1">*</span></label>
        <div class="my-2">
          <span class="select is-fullwidth">
            <select
              v-validate="{ required: true, 'is-over-18': data.dateofbirth.day + '-' + data.dateofbirth.month + '-' + data.dateofbirth.year }"
              :class="{
                'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has(type === 'Shareholder' ? 'sh-dob_year_' + activeIndex : 'dir-dob_year_' + activeIndex),
              }"
              :data-vv-as="translations.text.year"
              :name="type === 'Shareholder' ? 'sh-dob_year_' + activeIndex : 'dir-dob_year_' + activeIndex"
              v-model="data.dateofbirth.year"
              class="text-base text-gray-900 font-semibold rtl:bg-left"
            >
              <option value="" selected hidden>{{ translations.text.year }}</option>
              <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
            </select>
          </span>
          <span
            v-show="errors.has(type === 'Shareholder' ? 'sh-dob_year_' + activeIndex : 'dir-dob_year_' + activeIndex)"
            class="font-body text-xs text-orange font-semibold"
            :class="{ 'has-text-right': Language == 'fa' }"
            >{{ errors.first(type === 'Shareholder' ? 'sh-dob_year_' + activeIndex : 'dir-dob_year_' + activeIndex) }}</span
          >
        </div>
      </div>
      <!-- Month of Birth -->
      <div class="col-span-2 md:col-span-2">
        <label class="label is-small" for="dob_month">{{ translations.text.month }}<span class="text-orange ml-1">*</span></label>
        <div class="my-2">
          <span class="select is-fullwidth">
            <select
              v-validate="'required'"
              :class="{
                'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has(type === 'Shareholder' ? 'sh-dob_month_' + activeIndex : 'dir-dob_month_' + activeIndex),
              }"
              :data-vv-as="translations.text.month"
              :name="type === 'Shareholder' ? 'sh-dob_month_' + activeIndex : 'dir-dob_month_' + activeIndex"
              v-model="data.dateofbirth.month"
              @change="validateValues($event)"
              @blur="validateValues($event)"
              class="text-base text-gray-900 font-semibold rtl:bg-left"
            >
              <option value="" selected hidden>{{ translations.text.month }}</option>
              <option v-for="(month, activeIndex) in translations.months" :value="activeIndex" :key="activeIndex">{{ month }}</option>
            </select>
          </span>
          <span
            v-show="errors.has(type === 'Shareholder' ? 'sh-dob_month_' + activeIndex : 'dir-dob_month_' + activeIndex)"
            class="font-body text-xs text-orange font-semibold"
            :class="{ 'has-text-right': Language == 'fa' }"
            >{{ errors.first(type === 'Shareholder' ? 'sh-dob_month_' + activeIndex : 'dir-dob_month_' + activeIndex) }}</span
          >
        </div>
      </div>
      <!-- Day of Birth -->
      <div class="col-span-2 md:col-span-2">
        <label class="label is-small" for="dob_day">{{ translations.text.day }}<span class="text-orange ml-1">*</span></label>
        <div class="my-2">
          <span class="select is-fullwidth">
            <select
              v-validate="'required'"
              :class="{
                'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has(type === 'Shareholder' ? 'sh-dob_day_' + activeIndex : 'dir-dob_day_' + activeIndex),
              }"
              :data-vv-as="translations.text.day"
              :name="type === 'Shareholder' ? 'sh-dob_day_' + activeIndex : 'dir-dob_day_' + activeIndex"
              v-model="data.dateofbirth.day"
              @change="validateValues($event)"
              @blur="validateValues($event)"
              class="text-base text-gray-900 font-semibold rtl:bg-left"
            >
              <option value="" selected hidden>{{ translations.text.day }}</option>
              <option v-for="day in daysInMonth" :value="day" :key="day">{{ day }}</option>
            </select>
          </span>
          <span
            v-show="errors.has(type === 'Shareholder' ? 'sh-dob_day_' + activeIndex : 'dir-dob_day_' + activeIndex)"
            class="font-body text-xs text-orange font-semibold"
            :class="{ 'has-text-right': Language == 'fa' }"
            >{{ errors.first(type === 'Shareholder' ? 'sh-dob_day_' + activeIndex : 'dir-dob_day_' + activeIndex) }}</span
          >
        </div>
      </div>
    </div>
    <!-- Address -->
    <div class="col-span-4 md:col-span-4">
      <label class="font-body text-base text-gray-900 tracking-wide" for="address">{{ translations.text.address }} 1<span class="text-orange ml-1">*</span></label>
      <div class="my-2">
        <input
          v-validate="'required|min:3|max:255'"
          :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has(type === 'Shareholder' ? 'sh-address_' + activeIndex : 'dir-address_' + activeIndex) }"
          :name="type === 'Shareholder' ? 'sh-address_' + activeIndex : 'dir-address_' + activeIndex"
          type="text"
          :data-vv-as="translations.text.address"
          :placeholder="translations.text.address_placeholder"
          v-model="data.address"
          @change="validateValues($event)"
          class="text-gray-900 text-base font-semibold"
        />
        <span v-show="errors.has(type === 'Shareholder' ? 'sh-address_' + activeIndex : 'dir-address_' + activeIndex)" class="font-body text-xs text-orange font-semibold">{{
          errors.first(type === 'Shareholder' ? 'sh-address_' + activeIndex : 'dir-address_' + activeIndex)
        }}</span>
      </div>
    </div>
    <!-- Region -->
    <div class="col-span-4 md:col-span-4" v-if="accountType.toLowerCase() === 'partner'">
      <label class="font-body text-base text-gray-900 tracking-wide" for="address">{{ translations.text.target_geo_location }}<span class="text-orange ml-1">*</span></label>
      <div class="my-2">
        <input
          v-validate="'required|alpha_spaces|min:3|max:50'"
          :class="{
            'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has(
              type === 'Shareholder' ? 'sh-ib_main_target_region_' + activeIndex : 'dir-ib_main_target_region_' + activeIndex
            ),
          }"
          :name="type === 'Shareholder' ? 'sh-ib_main_target_region_' + activeIndex : 'dir-ib_main_target_region_' + activeIndex"
          type="text"
          :data-vv-as="translations.text.target_geo_location"
          :placeholder="translations.text.target_geo_location"
          v-model="data.ib_main_target_region"
          @change="validateValues($event)"
          class="text-gray-900 text-base font-semibold"
        />
        <span
          v-show="errors.has(type === 'Shareholder' ? 'sh-ib_main_target_region_' + activeIndex : 'dir-ib_main_target_region_' + activeIndex)"
          class="font-body text-xs text-orange font-semibold"
          >{{ errors.first(type === 'Shareholder' ? 'sh-ib_main_target_region_' + activeIndex : 'dir-ib_main_target_region_' + activeIndex) }}</span
        >
      </div>
    </div>
    <!-- Address 2 -->
    <div class="col-span-4 md:col-span-4">
      <label class="font-body text-base text-gray-900 tracking-wide" for="address2">{{ translations.text.address2 }}</label>
      <div class="my-2">
        <input
          v-validate="'min:3|max:255'"
          :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has(type === 'Shareholder' ? 'sh-address2_' + activeIndex : 'dir-address2_' + activeIndex) }"
          :name="type === 'Shareholder' ? 'sh-address2_' + activeIndex : 'dir-address2_' + activeIndex"
          type="text"
          :data-vv-as="translations.text.address2"
          :placeholder="translations.text.optional"
          v-model="data.address2"
          @change="validateValues($event)"
          class="text-gray-900 text-base font-semibold"
        />
        <span v-show="errors.has(type === 'Shareholder' ? 'sh-address2_' + activeIndex : 'dir-address2_' + activeIndex)" class="font-body text-xs text-orange font-semibold">{{
          errors.first(type === 'Shareholder' ? 'sh-address2_' + activeIndex : 'dir-address2_' + activeIndex)
        }}</span>
      </div>
    </div>
    <!-- Town/City, Province/State, Postal/Zip Code -->
    <div class="col-span-4 lg:col-span-4 grid grid-cols-6-default gap-5">
      <!-- Town/City -->
      <div class="col-span-6 md:col-span-2">
        <label class="font-body text-base text-gray-900 tracking-wide" for="city">{{ translations.text.city }}<span class="text-orange ml-1">*</span></label>
        <div class="my-2">
          <input
            v-validate="'required|min:3|max:255'"
            :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has(type === 'Shareholder' ? 'sh-city_' + activeIndex : 'dir-city_' + activeIndex) }"
            :name="type === 'Shareholder' ? 'sh-city_' + activeIndex : 'dir-city_' + activeIndex"
            type="text"
            :data-vv-as="translations.text.city"
            :placeholder="translations.text.city"
            v-model="data.city"
            @change="validateValues($event)"
            class="text-base text-gray-900 font-semibold"
          />
          <span v-show="errors.has(type === 'Shareholder' ? 'sh-city_' + activeIndex : 'dir-city_' + activeIndex)" class="font-body text-xs text-orange font-semibold">{{
            errors.first(type === 'Shareholder' ? 'sh-city_' + activeIndex : 'dir-city_' + activeIndex)
          }}</span>
        </div>
      </div>
      <!-- Province/State -->
      <div class="col-span-6 md:col-span-2">
        <label class="font-body text-base text-gray-900 tracking-wide" for="state_province">{{ translations.text.state_province }}<span class="text-orange ml-1">*</span></label>
        <div class="my-2">
          <input
            v-validate="'required|min:3|max:255'"
            :class="{
              'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has(
                type === 'Shareholder' ? 'sh-state_province_' + activeIndex : 'dir-state_province_' + activeIndex
              ),
            }"
            :name="type === 'Shareholder' ? 'sh-state_province_' + activeIndex : 'dir-state_province_' + activeIndex"
            type="text"
            :data-vv-as="translations.text.state_province"
            :placeholder="translations.text.state_province"
            v-model="data.state_province"
            @change="validateValues($event)"
            class="text-base text-gray-900 font-semibold"
          />
          <span
            v-show="errors.has(type === 'Shareholder' ? 'sh-state_province_' + activeIndex : 'dir-state_province_' + activeIndex)"
            class="font-body text-xs text-orange font-semibold"
            :class="{ 'has-text-right': Language == 'fa' }"
            >{{ errors.first(type === 'Shareholder' ? 'sh-state_province_' + activeIndex : 'dir-state_province_' + activeIndex) }}</span
          >
        </div>
      </div>
      <!-- Postal/Zip Code -->
      <div class="col-span-6 md:col-span-2">
        <label class="font-body text-base text-gray-900 tracking-wide" for="post_code">{{ translations.text.post_zip_code }}<span class="text-orange ml-1">*</span></label>
        <div class="my-2">
          <input
            v-validate="'required|max:255'"
            :class="{
              'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has(type === 'Shareholder' ? 'sh-post_code_' + activeIndex : 'dir-post_code_' + activeIndex),
            }"
            :name="type === 'Shareholder' ? 'sh-post_code_' + activeIndex : 'dir-post_code_' + activeIndex"
            type="text"
            :data-vv-as="translations.text.post_code"
            :placeholder="translations.text.post_zip_code"
            v-model="data.post_code"
            @change="validateValues($event)"
            class="text-base text-gray-900 font-semibold"
          />
          <span
            v-show="errors.has(type === 'Shareholder' ? 'sh-post_code_' + activeIndex : 'dir-post_code_' + activeIndex)"
            class="font-body text-xs text-orange font-semibold"
            :class="{ 'has-text-right': Language == 'fa' }"
            >{{ errors.first(type === 'Shareholder' ? 'sh-post_code_' + activeIndex : 'dir-post_code_' + activeIndex) }}</span
          >
        </div>
      </div>
    </div>
    <!-- Skype id -->
    <div class="col-span-4 md:col-span-2">
      <label class="font-body text-base text-gray-900 tracking-wide" :class="{ 'has-text-right': Language == 'fa' }" for="skype_id">{{
        translations.text.skype_id.tTitle()
      }}</label>
      <div class="my-2">
        <input
          v-validate="'max:255'"
          :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('skype_id') }"
          name="skype_id"
          type="text"
          :data-vv-as="translations.text.skype_id"
          :placeholder="translations.text.skype_id"
          v-model="data.skype_id"
          @change="validateValues($event)"
          class="text-gray-900 text-base font-semibold"
        />
        <span v-show="errors.has('skype_id')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
          errors.first('skype_id')
        }}</span>
      </div>
    </div>
    <!-- Social media -->
    <div class="col-span-4 md:col-span-2">
      <label class="font-body text-base text-gray-900 tracking-wide" :class="{ 'has-text-right': Language == 'fa' }" for="social_media">{{
        translations.text.social_media.tTitle()
      }}</label>
      <div class="my-2">
        <input
          v-validate="'max:255'"
          :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('social_media') }"
          name="social_media"
          type="text"
          :data-vv-as="translations.text.social_media"
          :placeholder="translations.text.social_media"
          v-model="data.social_media"
          @change="validateValues($event)"
          class="text-gray-900 text-base font-semibold"
        />
        <span v-show="errors.has('social_media')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
          errors.first('social_media')
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  props: {
    accountType: String,
    index: Number,
    translations: Object,
    dataArray: Array,
    type: String,
    prefilledTestData: Boolean,
    testData: Object,
  },
  data() {
    return {
      years: Array.from({ length: 100 }, (v, i) => moment().year() - i + 1),
      data: {
        firstname: '',
        lastname: '',
        dateofbirth: {
          day: '',
          month: '',
          year: '',
        },
        address: '',
        address2: '',
        ib_main_target_region: '',
        city: '',
        state_province: '',
        post_code: '',
        skype_id: '',
        social_media: '',
      },
      activeIndex: this.index,
      shareholderErrors: [[], [], [], []],
      directorErrors: [[], [], [], []],
    }
  },
  created() {
    this.$bus.$on('ValidateForms', this.validateValues)

    if (this.prefilledTestData && this.testData) {
      Object.keys(this.data).forEach(key => {
        if (this.type === 'Shareholder') {
          this.data[key] = this.testData.shareholders[0][key]
        } else {
          this.data[key] = this.testData.directors[0][key]
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
    }),
    arrayCopy() {
      return this.dataArray
    },
    daysInMonth() {
      if (!this.data.dateofbirth) return new Date().getDate()
      const year = this.data.dateofbirth.year || new Date().getFullYear()
      const month = this.data.dateofbirth.month || new Date().getMonth()
      return new Date(year, month, 0).getDate()
    },
  },
  updated: function () {
    this.$nextTick(() => {
      if (this.dataArray[this.index - 1].firstname) {
        this.data = this.dataArray[this.index - 1]
      }
    })
  },
  watch: {
    index() {
      if (Object.keys(this.arrayCopy[this.index - 1]).length > 0) {
        this.shareholderErrors[this.activeIndex - 1] = []
        this.directorErrors[this.activeIndex - 1] = []
        this.data = this.arrayCopy[this.index - 1]
        this.validateValues()
        this.$validator.validateAll().then(result => {
          if (!result) {
            this.errors.items.forEach(item => {
              if (item.field.includes('sh-')) this.shareholderErrors[this.activeIndex - 1].push(item.field)
              else if (item.field.includes('dir-')) this.directorErrors[this.activeIndex - 1].push(item.field)
            })
            return this.$bus.$emit('PopulateValidationErrors', { shareholderErrors: this.shareholderErrors, directorErrors: this.directorErrors })
          } else {
            return this.$bus.$emit('PopulateValidationErrors', { shareholderErrors: [[], [], [], []], directorErrors: [[], [], [], []] })
          }
        })
      } else {
        // this.$validator.reset()
        // this.errors.clear()
        this.data = {
          gender: '',
          dateofbirth: {
            day: '',
            month: '',
            year: '',
          },
          address: '',
          address2: '',
          region: '',
          city: '',
          state_province: '',
          post_code: '',
          skype_id: '',
          socialMedia: '',
        }
        this.validateValues()
      }
    },
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },
  },
  methods: {
    validateValues(e = null, submit = false) {
      this.$validator.validateAll().then(result => {
        this.shareholderErrors[this.index - 1] = []
        this.directorErrors[this.index - 1] = []
        if (!result) {
          this.errors.items.forEach(item => {
            if (item.field.includes('sh-')) this.shareholderErrors[this.index - 1].push(item.field)
            else if (item.field.includes('dir-')) this.directorErrors[this.index - 1].push(item.field)
          })
          this.$bus.$emit('PopulateValidationErrors', { shareholderErrors: this.shareholderErrors, directorErrors: this.directorErrors })
        } else {
          this.$bus.$emit('PopulateValidationErrors', { shareholderErrors: [[], [], [], []], directorErrors: [[], [], [], []] })
        }
        // scroll to first error
        if (this.errors.items.length > 0) {
          let el = ''
          if (e) {
            if (this.shareholderErrors[this.index - 1].length > 0 && e.target.name.startsWith('sh-')) {
              el = document.getElementsByName(this.shareholderErrors[this.index - 1][0])[0]
            } else {
              el = document.getElementsByName(this.directorErrors[this.index - 1][0])[0]
            }
          } else {
            el = document.getElementsByName(this.errors.items[0].field)[0]
          }

          if (submit && el) return el.focus()
        } else {
          if (this.type === 'Shareholder') {
            this.$bus.$emit('ChangeShareholderData', { index: this.index, data: this.data })
          }
          if (this.type === 'Director') {
            this.$bus.$emit('ChangeDirectorData', { index: this.index, data: this.data })
          }
        }
      })
    },
  },
}
</script>
