import { app } from '../../main.js'

const psp = {
  initial() {
    return {
      /*
      'visa-mastercard': {
      	T: 'pmethod_visa_mastercard',
      	PSPName: 'REGULARPAY',
      	Component: 'regular-pay',
      	BlockedCurrencies: [],
      },
      'fasapay': {
      	T: 'pmethod_fasapay',
      	PSPName: 'FASAPAY',
      	Component: 'fasapay',
      	BlockedCurrencies: ['EUR'],
      },
      'koalapay': {
      	T: 'pmethod_koalapay',
      	PSPName: 'KOALAPAY',
      	Component: 'koala-pay',
      	BlockedCurrencies: [],
      },
      // 'cup': {
      // 	T: 'pmethod_cup',
      // 	PSPName: 'CUP',
      // 	Component: 'pago-c-u-p',
      // 	BlockedCurrencies: [],
      // },
      */
      bitcoin: {
        T: 'pmethod_bitcoin',
        PSPName: 'CONFIRMO',
        Component: 'Bitcoin',
        BlockedCurrencies: [],
      },
      lydiax: {
        T: 'pmethod_lydiax',
        PSPName: 'LYDIAX',
        Component: 'Lydiax',
        BlockedCurrencies: [],
      },
      // vload: {
      //   T: 'pmethod_vload',
      //   PSPName: 'VLOAD',
      //   Component: 'VLoad',
      //   BlockedCurrencies: [],
      // },
      // 'visa-mastercard-safecharge': {
      //   T: 'pmethod_visa_mastercard',
      //   PSPName: 'SAFECHARGE',
      //   Component: 'safe-charge',
      //   BlockedCurrencies: [],
      // },
      // 'visa-mastercard-powercash': {
      //   T: 'pmethod_visa_mastercard',
      //   PSPName: 'POWERCASH',
      //   Component: 'power-cash',
      //   BlockedCurrencies: [],
      // },
      // bankwire: {
      //   T: 'pmethod_bankwire',
      //   PSPName: 'BANKWIRE',
      //   Component: 'bank-wire',
      //   BlockedCurrencies: [],
      // },
      // skrill: {
      //   T: 'pmethod_skrill',
      //   PSPName: 'SKRILL',
      //   Component: 'skrill',
      //   BlockedCurrencies: [],
      // },
      // neteller: {
      //   T: 'pmethod_neteller',
      //   PSPName: 'NETELLER',
      //   Component: 'skrill',
      //   BlockedCurrencies: [],
      // },
    }
  },
}

const state = {
  PSPs: psp.initial(),
  deposit: {
    selected: {
      paymentMethod: null,
    },
  },
}

const mutations = {
  PRE_SELECTED_DEPOSIT_VALUE(state, res) {
    if (res === null) {
      state.deposit.selected = null
    }
    if (typeof state.deposit.selected[res.key] !== 'undefined') {
      state.deposit.selected[res.key] = res.value
    }
  },
  SET_PSP_BLOCKED_COUNTRIES(state, PSPs) {
    let PSPNames = Object.keys(PSPs)
    let StorePSPNames = Object.keys(state.PSPs)
    for (let i = 0, l = PSPNames.length; i < l; i++) {
      for (let j = 0, p = StorePSPNames.length; j < p; j++) {
        if (state.PSPs[StorePSPNames[j]].PSPName.toLowerCase() === PSPNames[i].toLowerCase()) {
          app.$set(
            state.PSPs[StorePSPNames[j]],
            'BlockedCountries',
            PSPs[PSPNames[i]].map(v => v.toUpperCase())
          )
          // state.PSPs[StorePSPNames[j]].BlockedCountries = PSPs[PSPNames[i]].map((v) => v.toUpperCase())
        }
      }
    }
  },
}

const actions = {
  preSelectedDepositValue: ({ commit }, { key, value }) =>
    commit('PRE_SELECTED_DEPOSIT_VALUE', {
      key,
      value,
    }),
  setPSPBlockedCountries: ({ commit }, PSPs) => commit('SET_PSP_BLOCKED_COUNTRIES', PSPs),
}

const getters = {
  all: state => state,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
