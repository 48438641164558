<template>
  <div>
    <div
      class="w-full flex flex-wrap justify-center bg-blue items-center relative z-50"
      style="height: 70px;"
    >
      <router-link :to="'/' + Language + '/'">
        <img
          width="176"
          src="../../assets/img/plexytrade-logo-white.svg"
        />
      </router-link>
       <div class="absolute ltr:right-4 rtl:left-4">
      <language-popover></language-popover>
    </div>
    </div>
    <div class="flex justify-center h-auto relative z-0 mt-[72px] md:mt-[170px] mb-6 landscape:mb-32">
      <div class="w-full md:w-[36rem] max-w-[580px]">
        <h1 class="text-blue font-display font-bold text-3xl md:text-[2.5rem] pb-5 text-center max-w-xs md:max-w-none mx-auto">{{$t('welcome_to_plexytrade')}}</h1>
        <p class="text-gray-900 font-display font-bold text-base leading-7 w-full md:w-[24.5rem] m-auto text-center mb-20 md:mb-32">{{$t('select_demo_live')}}</p>
        <div class="flex gap-4 md:gap-6 justify-center relative">
          <div class="w-20 md:w-[185px] absolute -top-14 md:top-[-100px] ltr:left-8 ltr:md:-left-14 rtl:right-8 rtl:md:-right-14 z-0">
            <img
              class="hidden md:block"
              src="../../assets/img/dots.svg"
            />
            <img
              class="block md:hidden"
              src="../../assets/img/dots-mobile.svg"
            />
          </div>
          <router-link
            class="z-10 relative h-[100px] md:h-[150px] w-[156px] md:w-[300px] hover:font-bold hover:border-blue hover:bg-gray-400 bg-white border border-[#DEDFE2] rounded-lg flex justify-center items-center font-display text-[22px] md:text-[34px] text-gray-dark shadow-ts1 transition-all duration-300 ease-in-out"
            :to="'/' +Language + '/register/demo'">
            {{$t('demo')}}
          </router-link>
          <router-link
            class="z-10 relative h-[100px] md:h-[150px] w-[156px] md:w-[300px] hover:font-bold hover:border-blue hover:bg-gray-400 bg-white border border-[#DEDFE2] rounded-lg flex justify-center items-center font-display text-[22px] md:text-[34px] text-gray-dark font-normal shadow-ts1 transition-all duration-300 ease-in-out"
            :to="'/' +Language + '/register/live'">
            {{$t('live')}}
          </router-link>
          <div class="w-20 md:w-[185px] absolute bottom-[-50px] md:bottom-[-100px] ltr:right-6 ltr:md:-right-14 rtl:left-6 rtl:md:-left-14 z-0">
            <img
              class="hidden md:block"
              src="../../assets/img/dots.svg"
            />
            <img
              class="block md:hidden"
              src="../../assets/img/dots-mobile.svg"
            />
          </div>
        </div>
        <div class="mt-[90px] md:mt-40">
          <p class="text-sm text-gray-dark text-center mb-4">{{ $t('already_have_an_account')}}</p>
          <router-link
          class="h-[50px] w-[200px] mx-auto rounded-md border border-green text-green text-center flex justify-center items-center text-base font-bold"
          :to="'/' +Language + '/login'">
          {{ $t('login').tTitle()}}
          </router-link>
        </div>
      </div>
    </div>
    <div class="w-full relative flex justify-center landscape:relative portrait:absolute portrait:bottom-0">
      <div class="w-full max-w-[1399px] mx-auto">
        <footer-collapsible></footer-collapsible>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FooterCollapsible from '../../components/FooterCollapsible.vue'
import LanguagePopover from '../../components/Language.vue'


export default {
  components: {
    FooterCollapsible,
    LanguagePopover
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
    }),
  },
  mounted() {


  },
  methods: {

  },
}
</script>
