<template>
  <div
    v-if="IsVerified"
    class="has-text-left"
  >
    <h5 class="text-gray-900 text-base font-semibold mb-1">{{$t("vload_please_enter_voucher")}}</h5>
    <span
      class="text-sm text-red"
      v-html="$t('vload_minimum')"
    ></span>
    <div class="flex gap-5 mt-5 items-start flex-col md:flex-row">
      <div class="flex flex-col w-full">
        <label
          for="pin"
          class="text-base text-gray-900"
        >{{$t('vload_pin').tTitle()}}</label>
        <input
          v-validate="'required|alpha_num|min:16'"
          maxlength="20"
          :class="{'input': true, 'is-danger': errors.has('pin') }"
          name="pin"
          type="text"
          :data-vv-as="translations.text.pin"
          :placeholder="translations.text.pin"
          v-model="pin"
          class="mt-2 bg-white"
        />
        <span
          v-show="errors.has('pin')"
          :class="{'has-text-right': Language == 'fa'}"
          class="text-red text-xs pt-0.5"
        >{{ errors.first('pin') }}</span>
      </div>
      <a
        class="w-40 h-[50px] bg-green text-center text-white text-base font-bold table rounded-md py-3 cursor-pointer md:mt-8"
        @click="deposit"
      >
        <span>{{$t("redeem").tTitle()}}</span>
      </a>
    </div>

    <p class="mt-7 md:mt-5 text-sm text-gray-900 mb-4">{{$t('vload_text_1')}}</p>
    <div
      class="rounded-lg bg-white border border-solid border-gray-300 p-5 mb-5"
      v-if="showPurchaseVLoad === true"
    >
      <form
        action="https://www.vload.expert/cart/voucher_group"
        method="POST"
        target="_blank"
      >
        <div class="flex gap-4 md:gap-2 flex-col md:flex-row">
          <div class="w-full md:w-1/2">
            <label
              class="text-base text-gray-900 w-full inline-block"
              for="amount"
            >{{$t("amount").tTitle()}}</label>
            <input
              v-validate="'required|min_value:50|multiple-of-10'"
              type="number"
              class="w-full mt-2 bg-white rounded-md h-[50px] text-base text-gray-900 border-gray-300"
              :class="{'input': true, 'text-red': errors.has('amount') }"
              name="amount"
              value="50"
              v-model="amount"
            />
            <span
              v-show="errors.has('amount')"
              class="text-red text-xs leading-3 pt-0.5"
              :class="{'text-right': Language == 'fa'}"
            >{{ errors.first('amount') }}</span>
          </div>
          <div class="w-full md:w-1/2">
            <label
              class="text-base text-gray-900"
              for="currency"
            >{{$t("currency").tTitle()}}</label>
            <div class="relative">
              <select
                class="mt-2 bg-none h-[50px]  bg-white text-gray-900 font-normal border border-gray-300 border-solid focus:border  focus:border-gray-300  focus:border-solid focus:outline-none focus:ring-0 cursor-pointer"
                name="currency"
              >
                <option
                  selected
                  value="EUR"
                >EUR</option>
                <option value="USD">USD</option>
              </select>
              <div class="select-arrow top-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="flex gap-4 items-center mt-4">
          <div>
            <input
              :disabled="errors.has('amount')"
              class="py-2.5 w-40 bg-green text-center text-white text-base font-bold table rounded-md cursor-pointer"
              type="submit"
            />
          </div>
          <a
            class="py-2.5 text-gray-900 text-base cursor-pointer"
            @click="showPurchaseVLoad = false"
          >{{ $t('cancel').tTitle() }}</a>
        </div>
      </form>

    </div>

    <div class="vLoadSubtitle">
      <ol>
        <li
          class="text-gray-900 text-sm mb-2"
          v-html="'1. '+$t('vload_text_2')"
        ></li>
        <li class="text-gray-900 text-sm mb-2">2. {{$t('vload_text_3')}}</li>
        <li class="text-gray-900 text-sm mb-2">3. {{$t('vload_text_4')}}</li>
        <li class="text-gray-900 text-sm mb-5">4. {{$t('vload_text_5')}}</li>
      </ol>
      <p
        class="text-gray-900 text-sm mb-2"
        v-html="$t('vload_text_6')"
      ></p>
    </div>

    <div
      v-if="vloadPromo.modalActive"
      id="vloadPromoModal"
    >
      <div class="fixed inset-0 z-50 bg-gray-900 bg-opacity-70 w-screen h-screen">
        <div class="flex flex-col justify-center items-center lg:items-end right-0 top-0 z-50 overflow-x-hidden overflow-y-scroll h-full w-full lg:w-auto">
          <div class="w-11/12 lg:w-[790px] max-w-[790px]">
            <button
              @click="vloadPromo.modalActive = false"
              class="bg-white rounded-full h-10 w-10 lg:self-start appearance-none flex-shrink-0 mt-2 p-0"
            >
              <div class="h-6 w-0.5 bg-blue rotate-45 ml-5"></div>
              <div class="h-6 w-0.5 bg-blue -rotate-45 -mt-6 ml-5"></div>
            </button>
          </div>
          <div class="bg-white rounded-br-xl lg:rounded-br-none rounded-tr-xl lg:rounded-tr-none rounded-tl-xl rounded-bl-xl py-10 md:py-12 px-5 md:px-10 lg:px-24 w-11/12 lg:w-[790px] max-w-[790px] relative mt-4 mb-20 md:mb-0">
            <div class="modal-card">

              <div class="w-full">
                <div class="w-full flex justify-between items-center flex-col md:flex-row">
                  <div class="text-gray-900 text-xl md:text-base font-semibold text-center md:text-left">{{$t('deposit_page_desc')}}</div>
                  <div class="mt-3 md:mt-0">
                    <img
                      class="h-14"
                      src="../../../assets/img/funding/vload.svg"
                    />
                  </div>
                </div>

                <div class="mt-6">
                  <div class="column is-8 has-text-white has-text-weight-bold">
                    <p class="text-base text-gray-900">{{ $t('deposit_page_desc1') }}:</p>
                    <div class="mt-5">
                      <div class="flex items-center mb-6">
                        <img
                          class="w-10"
                          src="../../../assets/img/promo/vload/account.svg"
                        />
                        <p class="text-gray-900 ml-2 font-semibold">{{ $t('create_new_account') }}</p>
                      </div>
                      <div class="flex items-center mb-6">
                        <img
                          class="w-10"
                          src="../../../assets/img/promo/vload/evoucher.svg"
                        />
                        <p class="text-gray-900 ml-2 font-semibold">{{ $t('successfully_purchase_vload').tTitle() }}</p>
                      </div>
                      <div class="flex items-center mb-6">
                        <img
                          class="w-10"
                          src="../../../assets/img/promo/vload/tier2.svg"
                        />
                        <p class="text-gray-900 ml-2 font-semibold">{{ $t('upgrade_to_tier_2') }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-10">
                    <a
                      target="_blank"
                      href="https://vload.expert/register?_referral=nkwwci6z96ytgm4qj9cx"
                      class="py-2.5 w-40 bg-green text-center text-white text-base font-bold table rounded-md cursor-pointer"
                    >{{ $t('register_now') }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <error-modal></error-modal> -->
  </div>
  <div v-else>
    <p class="has-text-danger is-size-5">{{$t("vload_note1")}}</p>
    {{$t("vload_note")}}
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import config from '../../../config'
import api from '../../../api'
// import ErrorModal from '../../../components/ErrorModal'

export default {
  components: {
    // ErrorModal,
  },
  data() {
    return {
      pin: '',
      showPurchaseVLoad: false,
      amount: 50,
      translations: {
        text: {
          amount: this.$t('amount'),
        },
      },
      currencySymbols: config.currencies,
      vloadPromo: {
        modalActive: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      IsVerified: 'user/isVerified',
      Language: 'language/code',
    }),
  },
  mounted() {},
  watch: {
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },
  },
  methods: {
    deposit() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return false
        }
        var pspServiceCall = '/finance/deposit/vload'

        this.$bus.$emit(
          'RequestDeposit',
          pspServiceCall,
          {
            pin: String(this.pin),
            userID: this.$store.state.user.info.ID,
          },
          response => {
            this['logoLoader/set'](false)
            if (response) {
              api.GetWallet()
              this.$toasted.success(this.$t('deposit_successful').tTitle(), { duration: 10000 })
            }
          },
          error => {
            if (error.response.data.meta) {
              let meta = error.response.data.meta
              if (meta[config.API.translationKeyName]) {
                this.$toasted.error(this.$t(meta[config.API.translationKeyName]).tTitle(), { duration: 10000 })
              }
            } else {
              this.$toasted.error(this.$t('deposit_failed').tTitle(), { duration: 10000 })
            }
          }
        )
      })
    },
    ...mapActions(['logoLoader/set']),
  },
}
</script>
