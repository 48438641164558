<template>
  <div>
    <div
        :class="{ hidden: !transferBack.modalActive }"
        class="fixed flex justify-center items-center inset-0 z-50 bg-gray-900 bg-opacity-70 w-full h-full"
      >
        <div class="bg-white rounded-xl py-10 px-8 w-10/12 lg:w-[550px] max-w-[550px] m-auto relative">
          <button
            @click="closeTransferBackModal()"
            class="bg-white rounded-full h-10 w-10 absolute -top-14 right-0 appearance-none p-0"
          >
            <div class="h-6 w-0.5 bg-blue rotate-45 ml-5"></div>
            <div class="h-6 w-0.5 bg-blue -rotate-45 -mt-6 ml-5"></div>
          </button>
          <p class="font-display text-2xl font-bold text-gray-900 pb-9">{{$t('transfer_back_to_my_local_depositor').tTitle()}}</p>
          <form
            @submit.prevent
            data-vv-scope="form-transfer"
            :class="{'direction-rtl': Language == 'fa'}"
          >
            <div class="mb-3">
              <label
                for="amount"
                class="text-base text-gray-900"
              >{{$t('amount').tTitle()}}</label>
              <div class="relative">
                <input
                  v-validate="'required|min_value:1'"
                  id="amount"
                  name="amount"
                  type="number"
                  :class="[ errors.has('form-transfer.amount')?'border-red focus:border-red':'border-gray-100 focus:border-blue' ]"
                  class="rounded-md bg-gray-100 pl-5 w-full h-11 mt-2 text-base text-gray-900 focus:outline-none focus:ring-0 border placeholder:text-gray-900 placeholder:text-opacity-20"
                  v-model="transferBack.amount"
                />
                <span
                  v-show="errors.has('form-transfer.amount')"
                  class="text-xs leading-3 text-red"
                  :class="{'text-right': Language == 'fa'}"
                >{{ errors.first('form-transfer.amount') }}</span>
              </div>
            </div>

            <div class="mt-9 flex gap-5">
              <a
                class="py-2.5 w-48 bg-blue rounded-md text-white font-bold text-center cursor-pointer text-base"
                @click="executeTransferBack()"
              >{{ $t('transfer_back').tTitle() }}</a>
              <a
                class="py-2.5 text-gray-900 text-base cursor-pointer"
                :class="{'ml-10': Language == 'fa'}"
                @click="closeTransferBackModal()"
              >{{ $t('cancel').tTitle() }}</a>
            </div>
          </form>
        </div>
      </div>

  </div>
</template>

<script>
import { service } from '../main.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['transferBackData'],
  data() {
    return {
      transferBack: this.transferBackData
    }
  },
  watch: {
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
      logoLoaderActive: 'logoLoader/active',
    }),
  },
  mounted() {},
  methods: {
    executeTransferBack() {
      this['logoLoader/set'](true)
      this.$validator
        .validateAll('form-transfer')
        .then(result => {
          if (!result) {
            this['logoLoader/set'](false)
          } else {
            service
              .post('/finance/external-transfer/transfer-back', {
                fromTA: this.transferBack.TAID,
                userID: this.User.info.ID,
                amount: this.transferBack.amount,
              })
              .then(() => {
                this.transferBack.TAID = 0
                this.transferBack.modalActive = false
                this.$toasted.success(this.$t('external_transfer_processed_successfully').tTitle(), { duration: 10000 })
                this.$emit('complete')
                this['logoLoader/set'](false)
              })
              .catch(() => {
                this.transferBack.TAID = 0
                this.transferBack.modalActive = false
                this['errorModal/set']({ active: true, text: this.$t('error_request_failed').tTitle() })
                this['logoLoader/set'](false)
              })
          }
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    },
    closeTransferBackModal() {
      this.transferBack.modalActive = false
      this.transferBack.TAID = 0
      this.transferBack.amount = 0
      this.errors.clear()
      this.$nextTick(() => {
        this.$validator.reset()
      })
    },
    ...mapActions(['logoLoader/set']),
  },
}
</script>
