<template>
  <div class="content has-text-centered">
    <div v-if="status == 'failed'">
      <failed :reason="failureReason"></failed>
    </div>
    <div v-if="status != 'failed'">
      <success></success>
    </div>
  </div>
</template>

<script>
import Success from './Success'
import Failed from './Failed'

export default {
  components: {
    Success,
    Failed,
  },
  data() {
    return {
      status: '',
      title: '',
      failureReason: '',
      reasonCodes: {
        1: 'Transaction is approved',
        2: 'Transaction is declined',
        5: 'Customer canceled transaction',
        6: 'Customer pending approval from bank',
        7: 'Refund registered and pending',
        8: 'Transaction state awaits Gateway response',
        10: 'Transaction is authorized',
        40: 'Transaction is refunded',
        42: 'Transaction has changed to chargeback',
        80: 'Transaction is pending processing',
        100: 'Not a secure connection',
        101: 'Invalid HTTP method',
        102: 'Field missing',
        103: 'Field invalid',
        104: 'Authentication failed',
        105: 'General error',
        106: 'Duplicate order or reporting call',
        107: 'Antifraud reject',
        108: 'Bank redirect',
        109: 'Transaction not found',
        110: 'Transaction is invalid',
        111: 'Bank Timeout',
        140: 'Refund not supported',
        160: 'Recurrent transaction not supported',
        180: 'Currency not supported for Merchant',
        185: 'MCP Rates Datafeed has expired',
        200: 'Bank Decline',
        201: 'Insufficient Funds',
        202: 'Do Not Honor',
        203: 'Stolen Card',
        204: 'Invalid Card',
        205: 'High Risk',
        206: 'Contact Card Issuer',
        207: 'Card blacklisted by the bank',
        300: 'Card not enrolled to 3DS',
        301: '3DS is required',
        302: '3DS timeout',
        504: 'Antifraud reject - Amount limit settings',
        505: 'Antifraud reject - Email velocity',
        506: 'Antifraud reject - IP velocity',
        507: 'Antifraud reject - Card velocity',
        508: 'Antifraud reject - Card Brand disabled',
        1044: 'Invalid Reporting token',
      },
    }
  },
  mounted() {
    this.title = this.$t('method_deposit_status', { method: 'CUP' })
    this.$bus.$emit('ChangeHeadings', this.title, 'CUP')
    this.status = this.$route.query['responseCode']
    if (this.status !== '1') {
      this.status = 'failed'
      if (typeof this.reasonCodes[this.$route.query['reasonCode']] !== 'undefined') {
        this.failureReason = this.reasonCodes[this.$route.query['reasonCode']]
      }
    }
  },
  methods: {},
}
</script>
