export const es = {
  account_list: 'lista de cuentas',
  create_live_account: 'cree una cuenta real',
  upgrade_to_live_account: 'Actualice a una cuenta real',
  create_demo_account: 'cree una cuenta de demostración',
  finance: 'Finanzas',
  deposit_funds: 'depositar fondos',
  withdraw_funds: 'retirar fondos',
  internal_transfer: 'transferencia interna',
  external_transfer: 'transferencia externa',
  payment_history: 'historial de pagos',
  my_cards: 'mis tarjetas',
  personal_information: 'datos personales',
  change_password: 'cambiar contraseña',
  change_password_of: 'cambiar contraseña de',
  upload_documents: 'cargar documentos',
  upload_documents_for_account_approval: 'cargar documentos para la aprobación de la cuenta',
  partner: 'Socio',
  overview: 'resumen',
  one_final_step: 'un último paso',
  clients: 'clientes',
  sub_ibs: 'sub-IB',
  payout: 'pago',
  marketing_material: 'material de marketing',
  become_a_partner: 'conviértase en socio',
  what_geographic_region: '¿Desde qué región se dirige a sus clientes?',
  website_social_media: 'Sitio web/redes sociales',
  skype_ID: 'Id. de Skype',
  submit: 'enviar',
  changepass_error: 'La contraseña actual es incorrecta, inténtelo de nuevo.',
  changepass_success: 'La contraseña se cambió exitosamente.',
  current_password: 'contraseña actual',
  new_password: 'nueva contraseña',
  confirm_new_password: 'confirmar nueva contraseña',
  account_approval_pending: 'aprobación de cuenta pendiente',
  account_must_be_approved_message: 'Su cuenta debe ser aprobada antes de crear una nueva cuenta de trading real.',
  new_trading_account_message: 'Nueva cuenta de trading creada exitosamente',
  password_generated_for_ta: 'Se ha generado y enviado a su correo electrónico una contraseña para su nueva cuenta de trading',
  choose_your_desired_trading_platform_and_settings: 'Elija la plataforma de trading y la configuración que desee',
  select_leverage: 'seleccione el apalancamiento',
  create: 'crear',
  select_account_type: 'seleccione el tipo de cuenta',
  select_currency: 'seleccione la divisa',
  no_trading_account_found: 'No se ha encontrado ninguna cuenta de trading.',
  bankwire_notice:
    'Gracias por enviar sus datos bancarios. Registraremos sus datos en nuestro banco.<br><br>Recibirá los detalles de la transferencia por correo electrónico en un plazo de 24 horas junto con su referencia de pago personal. Puede utilizar estos datos para futuros depósitos.<br><br><strong>IMPORTANTE:</strong> La referencia de pago es personalizada para cada cliente. Si no utiliza la referencia de pago que recibe, no podremos aceptar su depósito. Ningún tercero podrá utilizar la misma referencia de pago.',
  deposit: 'depósito',
  account_details: 'detalles de la cuenta',
  account_id: 'Id. de la cuenta',
  continue: 'continuar',
  bankwire_deposit_details_notice:
    'Nuestro banco necesita sus datos bancarios para procesar su depósito. Los depósitos realizados mediante transferencia bancaria se devolverán a la misma cuenta desde la que se efectuó el depósito.',
  bankwire_withdrawal_details_notice:
    'Nuestro banco necesita sus datos bancarios para procesar su retiro. Cualquier retiro realizado mediante transferencia bancaria se devolverá a la misma cuenta desde la que se efectuó el depósito.',
  bank_name: 'nombre del banco',
  full_name: 'nombre y apellidos',
  amount: 'monto',
  deposit_failed: 'error en el depósito',
  deposit_successful: 'depósito realizado correctamente',
  login: 'inicie sesión',
  already_logged_in: 'ya ha iniciado sesión',
  trades: 'operaciones',
  client_id: 'Id. del cliente',
  trading_account: 'Cuenta de trading',
  platform: 'plataforma',
  order: 'orden',
  pair: 'par',
  volume: 'volumen',
  close_date: 'fecha de cierre',
  commission: 'comisión',
  status: 'estado',
  total_commission_generated: 'total de comisiones generadas',
  total_deposits: 'total de depósitos',
  total_withdrawals: 'total de retiros',
  date_joined: 'fecha de registro',
  more: 'más',
  banners: 'banners',
  partner_payout_request_message: 'Su solicitud se ha registrado.',
  bank_address: 'Dirección del banco',
  your_provider_email: 'Correo electrónico de su {provider}',
  select: 'seleccionar',
  next: 'siguiente',
  bank: 'banco',
  state: 'estado',
  date: 'fecha',
  transfer_from: 'transferencia de',
  transfer_to: 'transferencia a',
  internal_transfer_processed_successfully: 'La transferencia interna se ha procesado correctamente.',
  external_transfer_processed_successfully: 'La transferencia externa se ha procesado correctamente.',
  select_account: 'seleccione la cuenta',
  to: 'a',
  warning_currency_conversion_will_take_place: 'advertencia: se llevará a cabo la conversión de divisa',
  transfer: 'transferencia',
  account: 'cuenta',
  currency: 'divisa',
  balance: 'saldo ',
  equity: 'capital',
  download: 'descargar',
  launch: 'lanzamiento',
  email: 'correo electrónico',
  password: 'contraseña',
  forgot: 'olvidó',
  forgot_password: 'ha olvidado la contraseña',
  risk_warning_footer_uncollapsed:
    '<span class="font-semibold">Advertencia de riesgo:</span> Los contratos por diferencia (CFD) apalancados y los productos de divisas son instrumentos financieros complejos que se operan con margen.',
  risk_warning_footer_uncollapsed_mobile: '<span class="font-semibold">Advertencia de riesgo:</span> Contratos por diferencia apalancados...',
  risk_warning_footer:
    '<span class="font-semibold">Advertencia de riesgo:</span> Los contratos por diferencia (CFD) apalancados y los productos de divisas son instrumentos financieros complejos que se operan con margen. Antes de decidirse a operar, asegúrese de que comprende los riesgos que ello implica, teniendo en cuenta sus objetivos de inversión y su nivel de experiencia. No debe arriesgar más de lo que puede permitirse perder. La información contenida en este sitio web no está dirigida a solicitar información a ciudadanos ni residentes de Corea del Norte, Reino Unido, Siria, Estados Unidos, Canadá, AEE o de cualquier otra jurisdicción que sea contraria a la legislación o normativa local. Operar con productos apalancados, como CFD y divisas, puede no ser adecuado para todos los inversores, ya que conllevan cierto riesgo para su capital. Asegúrese de que comprende perfectamente los riesgos que implica y, si es necesario, busque asesoramiento independiente. Lea completamente la<a href="{websiteURL}assets/files/risk-disclosure-1.pdf?v=1.4" target="_blank" class="font-semibold"> Divulgación de riesgos.</a>',
  error_contact_support: 'Algo salió mal, póngase en contacto con atención al cliente.',
  type: 'type',
  username: 'nombre de usuario',
  company_name: 'nombre de la empresa',
  website: 'sitio web',
  first_name: 'Nombre',
  last_name: 'Apellidos',
  date_of_birth: 'Fecha de nacimiento',
  gender: 'sexo',
  phone: 'teléfono',
  dial_code: 'Código de marcación',
  country: 'país',
  address: 'dirección',
  year_moved_to_address: 'año de traslado a la dirección',
  post_code: 'código postal',
  post_zip_code: 'Código postal',
  selected_currency: 'divisa seleccionada',
  january: 'enero',
  february: 'febrero',
  march: 'marzo',
  april: 'abril',
  may: 'mayo',
  june: 'junio',
  july: 'julio',
  august: 'agosto',
  september: 'septiembre',
  october: 'octubre',
  november: 'noviembre',
  december: 'diciembre',
  already_have_an_account: '¿Ya tienes una cuenta?',
  invalid_request_login_required: 'solicitud no válida, primero debe iniciar sesión.',
  individual: 'individual',
  company: 'empresa',
  company_information: 'información de la empresa',
  company_website: 'sitio web de la empresa',
  title: 'título',
  confirm_password: 'confirmar nueva contraseña',
  social_platform: '¿Cómo ha conocido PlexyTrade?',
  other_social_platform: 'Díganos dónde conoció PlexyTrade (Opcional)',
  select_country: 'seleccione el país',
  phone_number: 'Número de teléfono',
  choose_trading_platform: 'elija la plataforma de trading',
  select_option: 'seleccione la opción',
  day: 'día',
  month: 'mes',
  year: 'año',
  company_registration_information: 'información de registro de la empresa',
  date_of_registration: 'fecha de registro',
  select_nationality: 'seleccione la nacionalidad',
  nationality: 'nacionalidad',
  select_gender: 'seleccionar el género',
  male: 'masculino',
  female: 'femenino',
  no_gender: 'Prefiero no decirlo',
  address_line: 'Línea de dirección',
  city_town: 'Pueblo/ciudad',
  state_province: 'Provincia/estado',
  select_year: 'seleccione el año',
  company_shareholders_directors: 'accionistas y directores de la empresa',
  number_of_shareholders: 'número de accionistas',
  number_of_directors: 'número de directores',
  shareholder: 'accionista',
  financial_information: 'información financiera',
  total_estimated_net_worth: '¿patrimonio neto total estimado (€)?',
  less_than_amount: 'menos que {amount}',
  over_amount: 'más que {amount}',
  total_estimated_annual_income: '¿ingresos anuales totales estimados (€)?',
  employment_information: 'información laboral',
  employment_status: 'situación laboral',
  employed: 'empleado',
  self_employed: 'autónomo',
  retired: 'jubilado',
  student: 'estudiante',
  unemployed: 'desempleado',
  source_of_income: 'fuente de ingresos',
  employment: 'empleo',
  inheritance: 'herencia',
  investment: 'inversión',
  trading_experience: 'experiencia de trading',
  forex_cfds_and_other_instruments: 'divisas, CFD y otros instrumentos',
  yes: 'sí',
  no: 'no',
  lose_capital_risk:
    'Los productos derivados son adecuados para mis objetivos de inversión y mi actitud ante el riesgo, y puedo evaluar el riesgo que conlleva operar con ellos, incluida la posibilidad de perder todo mi capital.',
  prev_pro_qualifications: 'Tengo titulaciones profesionales previas o experiencia laboral en el sector de los servicios financieros',
  account_type: 'tipo de cuenta',
  choose_currency: 'elija la divisa',
  tos_with_link: 'He leído, comprendido y aceptado los términos y condiciones y la advertencia sobre los riesgos <a class="font-bold" target="_blank" href="{URL}">aquí</a>.',
  privacy_policy_link: 'He leído y aceptado la <a class="font-bold" target="_blank" href="{URL}">Política de privacidad</a>.',
  terms_link: 'He leído y aceptado todos los <a class="font-bold" target="_blank" href="{URL}">Términos y condiciones</a>.',
  registration_successful: 'registro realizado correctamente',
  reset_password_invalid_link: 'Su enlace de restablecimiento no es válido, inténtelo de nuevo o póngase en contacto con atención al cliente.',
  reset_account_password: 'restablezca la contraseña de la cuenta',
  choose_your_new_password: 'elija su nueva contraseña',
  reset_password: 'restablezca la contraseña',
  withdrawal_request_success_message: 'su solicitud de retiro se ha realizado correctamente',
  withdraw: 'retire',
  withdrawal: 'retiro',
  cancel: 'cancelar',
  you_became_a_partner_successfully: '¡Se ha convertido en socio de Plexytrade exitosamente!',
  note_wallet_currency_conversion_partner:
    'Tenga en cuenta que al convertirse en socio, la divisa de su monedero cambiará a USD y que todos los fondos actuales se convertirán a USD con el tipo de cambio actual.',
  upgraded_to_a_live_account_successfully: '¡Ha actualizado exitosamente a una cuenta real!',
  passwords_dont_match: 'Las contraseñas no coinciden',
  password_rules: 'Al menos 1 mayúscula, 1 minúscula y 1 número (de 6 a 16 caracteres como máximo)',
  only_currency_format_allowed: 'Solo se permite el formato de divisa',
  internal_server_error_support: 'Error interno del servidor. Póngase en contacto con atención al cliente',
  please_fill_all_fields: 'Rellene todos los campos',
  no_logs_found_for_this_client: 'No se han encontrado registros para este cliente',
  internal_server_error_try_again: 'Error interno del servidor. Inténtelo de nuevo más tarde',
  insufficient_balance: 'Saldo insuficiente',
  currency_conversion_connection_error: 'No hay respuesta del servidor de conversión de divisa, inténtelo de nuevo o póngase en contacto con atención al cliente.',
  currency_conversion_error: 'Error de conversión de moneda, inténtelo de nuevo o póngase en contacto con atención al cliente.',
  invalid_accounts: 'Cuentas no válidas',
  withdrawal_unsuccessful_equity_message: 'Error al realizar el retiro, no hay fondos suficientes en la cuenta de trading{TAID}',
  withdrawal_failed_free_margin: 'Error al realizar el retiro. Compruebe su margen libre.',
  pamm_copy_server_field_message: 'Copie y pegue la dirección IP en el campo «Servidor» de su servidor MT4',
  invalid_request: 'solicitud no válida',
  missing_shareholders_directors: 'Accionistas/directores faltantes',
  missing: 'Faltante',
  invalid_request_passwords_do_not_match: 'Solicitud no válida. Las contraseñas no coinciden',
  email_exists_message: 'El correo electrónico ya existe, inténtelo con otra dirección de correo electrónico.',
  your_payment_method_has_already_been_set: 'Su método de pago ya ha sido configurado. Póngase en contacto con atención al cliente',
  payment_method_is_required_message: 'Se requiere método de pago. Inténtelo de nuevo o póngase en contacto con atención al cliente',
  couldnt_find_your_account: 'No se ha podido encontrar su cuenta',
  ta_limit_error_message: 'Su cuenta ha alcanzado el límite de cuentas de trading({limit}) para {platform}.',
  error: 'error',
  account_created_successfully: 'cuenta creada exitosamente',
  invalid_leverage: 'apalancamiento no válido',
  insufficient_data_provided: 'datos proporcionados insuficientes',
  insufficient_data_provided_missing_field: 'Datos proporcionados insuficientes. {field} faltante.',
  invalid_amount: 'monto no válido',
  account_taid_doesnt_exist: 'La cuenta {TAID} no existe',
  withdrawal_unsuccessful_equity_taid: 'Error al realizar el retiro, no hay fondos suficientes en la cuenta de trading{TAID}',
  withdrawal_unsuccessful_free_margin_taid: 'Error al realizar el retiro, no hay suficiente margen libre en la cuenta de trading {TAID}',
  internal_server_error: 'error interno del servidor',
  deposit_failed_support: 'Error al realizar el depósito, póngase en contacto con atención al cliente.',
  upload_documents_instructions: 'Envíenos los siguientes documentos.<br>Los archivos deben estar en formato PDF, JPG o PNG y no deben superar los 5 MB.',
  total_earned: 'total ganado',
  total_paid: 'total pagado',
  total_clients: 'total de clientes',
  total_sub_ibs: 'Total de sub-IB',
  welcome_title_live: '¡Gracias por abrir su cuenta con Plexytrade!',
  welcome_title_demo: '¡Gracias por abrir su cuenta de demostración con Plexytrade!',
  welcome_title_ib: '¡Gracias por abrir su cuenta de socio con Plexytrade!',
  welcome_text_live:
    '<p>Para obtener fondos, ser aprobado y estar listo para operar, debe completar los siguientes pasos:</p><ul><li>Inicie sesión en su portal de cliente de Plexytrade</li><li>Suba sus documentos</li><li>Deposite fondos en su cuenta</li><li>¡Empiece a operar!</li></ul><p>Si necesita ayuda, nuestro amable equipo de atención al cliente está disponible las 24 horas del día, 5 días a la semana. Para ponerse en contacto con ellos, haga clic <a href="{contactURL}" target="_blank">aquí</a></p>',
  welcome_text_demo:
    '<p>Para obtener fondos, ser aprobado y estar listo para operar, debe completar los siguientes pasos:</p><ul><li>Descargue la plataforma MT4</li><li>Ponga a prueba sus habilidades de trading y nuestra velocidad de ejecución</li><li>Inicie sesión en su portal de cliente y actualice a una cuenta real</li><li>Suba sus documentos y deposite fondos en su cuenta</li><li>¡Opere en el mercado financiero más grande del mundo!</li></ul><p>Si necesita ayuda, nuestro amable equipo de atención al cliente está disponible las 24 horas del día, 5 días a la semana. Para ponerse en contacto con ellos, haga clic <a href="{contactURL}" target="_blank">aquí</a></p>',
  welcome_text_ib:
    '<p>Para que su cuenta de socio sea aprobada y esté lista para recibir su URL de referencia, debe completar los siguientes pasos:</p><ul><li>Inicie sesión en su portal de cliente</li><li>Suba sus documentos</li><li>Reciba su URL de seguimiento en la sección «resumen» y «herramientas de marketing»</li><li>¡Recomiéndenos a sus clientes y reciba comisiones!</li></ul><p>Si necesita ayuda, nuestro amable equipo de atención al cliente está disponible las 24 horas del día, 5 días a la semana. Para ponerse en contacto con ellos, haga clic <a href="{contactURL}" target="_blank">aquí</a></p>',
  tracking_links: 'enlaces de seguimiento',
  login_to_your_account: 'inicie sesión en su cuenta',
  register: 'regístrese',
  open_demo_account: 'abra una cuenta de demostración',
  page_title_partner: 'Conviértase en socio',
  home: 'inicio',
  about: 'acerca de',
  account_types: 'tipos de cuenta',
  spreads: 'spreads',
  funding: 'financiamiento',
  education: 'educación',
  contact: 'contacto',
  open_live_account: 'abra una cuenta real',
  not_registered_with_question: '¿No está registrado en Plexytrade?',
  forgot_password_q: '¿olvidó su contraseña?',
  pending_deposit_success: 'Operación realizada exitosamente, su depósito ahora está pendiente de aprobación',
  local_depositor: 'Depositante local',
  converted_amount: 'monto convertido',
  reference_id: 'id de referencia',
  from_account: 'de la cuenta',
  to_account: 'a la cuenta',
  from_date: 'de la fecha',
  to_date: 'a la fecha',
  wallet: 'monedero',
  received: 'recibido',
  send: 'enviar',
  sender_not_a_transfer_account: 'el remitente no es un depositante local',
  withdrawal_failed_support: 'Error al realizar el retiro, póngase en contacto con atención al cliente.',
  clear: 'borrar',
  transfer_completed_successfully: 'transferencia completada exitosamente',
  receive: 'recibir',
  view_trades: 'ver operaciones',
  your_provider_account_number: 'Número de cuenta de su {provider}',
  your_cryptocurrency_address: 'Su dirección de criptomonedas',
  your_crypto_address: 'dirección {crypto}',
  please_select: 'Seleccione',
  destination_tag: 'Etiqueta de destino',
  dashboard: 'Panel',
  sent: 'enviado',
  client_state: 'Estado de la cuenta',
  client_state_approved: 'aprobada',
  client_state_pending: 'pendiente',
  client_state_incomplete: 'incompleta',
  open_trades: 'operaciones abiertas',
  closed_trades: 'operaciones cerradas',
  trade_history: 'historial de operaciones',
  search_trades: 'buscar operaciones',
  trade_no: 'n.º de operación',
  symbol: 'símbolo',
  open_price: 'precio de apertura',
  close_price: 'precio de cierre',
  datetime: 'fecha y hora',
  my_bonuses: 'mis bonificaciones',
  bonus: 'bonificación',
  conversion_progress: 'progreso de conversión',
  progress_towards: 'progreso hacia',
  front_of_id: 'Anverso del documento de identidad',
  back_of_id: 'Reverso del documento de identidad',
  include_account_in_bonus_program:
    'Me gustaría recibir la bonificación en efectivo del 120% y estoy de acuerdo con los <a class="font-bold" href="https://plexytrade.com/assets/files/bonus-terms-and-conditions-1.pdf?v=1.9" target="_blank">Términos y condiciones de la bonificación</a>.',
  master_account: 'cuenta maestra',
  pamm_master_locks_withdrawals: '[¡este maestro pamm restringe los retiros durante las operaciones abiertas!]',
  p_l: 'PyG',
  bonus_tos_with_link: 'He leído y entendido los <a class="font-bold" target="_blank" href="{URL}">términos y condiciones del Programa de Trading Financiado al 120%</a>.',
  i_am_over_18: 'Declaro que soy mayor de 18 (dieciocho) años para abrir esta cuenta.',
  profit: 'ganancias',
  attach: 'adjuntar',
  private_code: 'código privado',
  i_accept_the_lpoa: 'acepto el LPOA',
  must_accept_lpoa: 'se debe aceptar el LPOA.',
  invest: 'invertir',
  name: 'nombre',
  search: 'buscar',
  partners: 'socios',
  reject_withdrawls_during_open_trades: 'rechazar retiros mientras haya operaciones abiertas',
  private_master_account: 'cuenta maestra privada',
  minimum_deposit: 'depósito mínimo',
  performance_fee: 'comisión de rendimiento',
  display_name: 'mostrar nombre',
  new_master_account: 'nueva cuenta maestra',
  pamm_statistics: 'estadísticas de pamm',
  pamm: 'PAMM',
  volume_traded: 'volumen operado',
  pamm_master_locks_deposits: '[¡este maestro pamm restringe los depósitos durante las operaciones abiertas!]',
  cannot_create_more_pamm_master_accounts: 'No se pueden crear más cuentas maestras pamm.',
  bitcoin: 'Confirmo',
  cryptocurrency: 'Criptomoneda',
  cryptocurrency_address: 'Dirección de criptomonedas',
  register_live_account: 'registrar una cuenta real',
  withdrawal_confirmo_amount_error: 'Límite de retiro superado. El monto de retiro solicitado supera el límite máximo de 50 000 $. Inténtelo de nuevo con un monto menor.',
  confirmo_deposit_note: '<span class="font-semibold">Atención:</span> Tenga en cuenta que, por el momento, Confirmo no admite retiros de Bitcoin Lightning.',
  register_partner_account: 'registrar una cuenta de socio',
  register_demo_account: 'registrar una cuenta de demostración',
  risk_disclosure: 'divulgación de riesgos',
  address_line_2: 'Línea de dirección 2',
  skype_id: 'Id. de Skype',
  social_media: 'Redes sociales',
  password_rules_extended:
    'La contraseña debe contener al menos: 1 letra mayúscula, 1 letra minúscula, 1 símbolo y 1 número. La longitud de la contraseña debe tener entre 6 y 15 caracteres.',
  login_information: 'información de inicio de sesión',
  director: 'director',
  pmethod_visa_mastercard: 'VISA/MASTERCARD',
  pmethod_visa_mastercard_alternative: 'VISA/MASTERCARD.',
  pmethod_bitcoin: 'BITCOIN',
  pmethod_bankwire: 'Transferencia bancaria',
  pmethod_fasapay: 'Fasapay',
  pmethod_ideal: 'ideal',
  pmethod_instant_bank_transfer: 'Transferencia bancaria instantánea',
  pmethod_jeton: 'Jeton',
  pmethod_jeton_wallet: 'Monedero Jeton',
  pmethod_my_pay: 'MyPay',
  pmethod_neteller: 'NETELLER',
  pmethod_vload: 'PayRedeem',
  pmethod_paykasa: 'Paykasa',
  pmethod_skrill: 'Skrill',
  pmethod_sofort: 'Sofort',
  pmethod_koalapay: 'KoalaPay',
  pmethod_cup: 'CUP',
  pmethod_lydiax: 'Criptomonedas',
  pmethod_safecharge: 'SafeCharge',
  pmethod_trustly: 'Trustly - Transferencia bancaria',
  pmethod_powercash: 'PowerCash',
  field_min_currency_value: 'El campo {field} debe tener {min} o más.',
  method_deposit: 'depósito {method}',
  method_deposit_status: 'estado del depósito {method}',
  quick_menu: 'menú rápido',
  details: 'detalles',
  date_from: 'fecha de',
  date_to: 'fecha a',
  from: 'de',
  forgot_password_form_instruction:
    'Indique a continuación la dirección de correo electrónico de su cuenta. A continuación, recibirá un correo electrónico con un enlace para restablecer su contraseña.',
  forgot_password_submit_success_message:
    'Se ha enviado a su correo electrónico un enlace para restablecer la contraseña. Vaya a su correo electrónico ({email}) y haga clic en el enlace para cambiar su contraseña.',
  password_reset_successfully: 'Su contraseña se ha restablecido exitosamente',
  password_too_week_or_mismatch: 'La contraseña es demasiado débil o las entradas de contraseñas no coinciden.',
  withdrawals_credit_card_remaining_amount_message: 'Para retirar fondos mediante otros métodos, primero debe retirar fondos {amount} a su tarjeta de crédito.',
  withdrawals_credit_card_refund_policy_message: 'Los proveedores de tarjetas de crédito solo le permiten retirar el monto total que depositó en su tarjeta.',
  withdrawals_amount_withdrawable_message:
    'Se puede retirar un total de {amount} de nuevo a su tarjeta, todo lo que supere ese monto puede retirarse mediante uno de nuestros otros métodos.',
  country_blocked_for_this_method: 'Su país está bloqueado para este método',
  copied_text_successfully: '{text} copiado exitosamente',
  choose_wallet_currency: 'elija la divisa del monedero',
  pamm_master_account_created_successfully: '¡Cuenta maestra PAMM creada exitosamente!',
  account_number: 'Número de cuenta',
  export_csv: 'Exportar a CSV',
  or_account_no_for_us: 'o número de cuenta (para clientes de EE. UU.)',
  your_physical_address: 'Su dirección física, que debe coincidir con la dirección que utilizó al registrar la cuenta',
  must_be_a_utility_bill:
    'Debe ser una factura de servicios públicos (electricidad, agua, teléfono, gas/energía, impuestos municipales/facturas de saneamiento) o un extracto bancario',
  for_further_info_attach: '**Para otro tipo de documentación, adjúntela en un correo electrónico y envíela a backoffice@plexytrade.com',
  banners_ad_blocker_notice: 'Si tiene problemas para ver los siguientes banners, desactive el bloqueador de anuncios.',
  transfer_out: 'transferencia saliente',
  transfer_in: 'transferencia entrante',
  done: 'hecho',
  not_required: 'no requerido',
  wallet_balance: 'saldo del monedero',
  must_agree_terms: 'Debe aceptar nuestros Términos y condiciones para crear una cuenta',
  must_agree_privacy: 'Debe aceptar nuestra Política de privacidad para crear una cuenta',
  select_payment_method: 'seleccione su método de pago',
  card_number: 'número de tarjeta',
  expiry: 'Caducidad',
  redeem: 'canjear',
  must_be_multiple_of_10: 'El valor debe ser un múltiplo de 10',
  select_starting_balance: 'Seleccione el saldo inicial',
  business_name: 'nombre de la empresa',
  error_getting_VPS: 'Error: No se pudo obtener el VPS',
  error_request_failed: 'Error: Error en la solicitud',
  error_no_user_with_this_email: 'Error: No se ha encontrado ninguna cuenta con esta dirección de correo electrónico',
  error_too_many_password_reset_requests: 'Error: Se han solicitado demasiados restablecimientos de contraseña',
  error_expired_or_invalid_request: 'Error: Solicitud no válida o caducada',
  error_insufficient_wallet_balance: 'Error: Saldo insuficiente en el monedero',
  error_insufficient_balance_or_equity: 'Error: Capital o saldo insuficiente',
  error_pamm_master_deposit: 'No se pueden realizar depósitos en una cuenta maestra PAMM',
  error_pamm_master_withdrawal: 'No se pueden realizar retiros de una cuenta maestra PAMM',
  error_pamm_master_locked_deposits: 'Rechazado: El maestro pamm de la cuenta en la que se deposita el dinero rechaza los depósitos durante las operaciones abiertas.',
  error_pamm_master_locked_withdrawals: 'Rechazado: El maestro pamm de la cuenta de la que se retira el dinero rechaza los retiros durante las operaciones abiertas.',
  error_pamm_master_minimum_deposit: 'Rechazado: No se ha cumplido el requisito de depósito mínimo del maestro pamm.',
  error_live_account_already: 'Su cuenta ya es una cuenta real',
  error_min_balance_required_is: 'El saldo mínimo necesario para la cuenta {TAAccountType} ({TAID}) es {min} {currency}.',
  error_transfer_same_wallet: 'No se pueden realizar transferencias de un monedero a otro.',
  error_transfer_same_trading_account: 'No se pueden realizar transferencias desde y hacia la misma cuenta de trading.',
  error_no_deposit_bonus_account_deposit_restricted: 'No se permiten depósitos en cuentas del tipo «Bonificación sin depósito».',
  error_no_deposit_bonus_base_amount_withdrawal: 'No se puede retirar el monto base de la «Bonificación sin depósito».',
  error_no_deposit_bonus_withdraw_profit_requirement_not_met: 'No se puede retirar la «Bonificación sin depósito» si no se ha cumplido el requisito de ganancias.',
  error_no_deposit_bonus_withdraw_exceeds_max_withdrawable_profit: 'No se puede retirar más de la ganancia máxima retirable.',
  upgrade_required_for_live_ta: '¡Se requiere una cuenta de cliente real y aprobada antes de poder abrir una cuenta de trading real!',
  error_wallet_currency_not_allowed_for_psp: 'La divisa del monedero no está permitida para este método de pago.',
  error_invalid_or_expired_otp: 'Error: La OTP proporcionada no es válida o ha caducado.',
  error_max_confirmo_withdrawal_exceeded: 'Error: La solicitud máxima de retiro de Confirmo es de 20 000 EUR/USD',
  error_max_anual_vload_withdrawal_exceeded: 'Error: Se ha superado el monto total máximo anual de solicitudes de retiro de PayRedeem',
  is_islamic_acc_message: 'Para crear una cuenta Islamic, envíe una solicitud a backoffice@plexytrade.com  para obtener más ayuda.',
  error_invalid_voucher: 'Error: Tarjeta electrónica PayRedeem no válida o ya canjeada',
  error_blocked_voucher: 'Error: La tarjeta electrónica PayRedeem se ha bloqueado por sospecha de fraude',
  error_voucher_data_mismatch: 'Error: La tarjeta electrónica PayRedeem ha sido rechazada debido a una discrepancia en los datos de la cuenta.',
  error_vload_voucher_below_minimum: 'Error: Tarjeta electrónica PayRedeem por debajo del monto de depósito mínimo.',
  my_bank_has_a_swift_code: 'Mi banco tiene un código swift',
  my_bank_does_not_have_a_swift_code: 'Mi banco no tiene un código swift',
  bank_not_in_swift_network_instructions:
    'Si su banco no es miembro de la red SWIFT, facilítenos los siguientes datos del banco corresponsal de su banco para recibir transferencias internacionales:',
  correspondent_bank_name: 'Nombre del banco corresponsal',
  correspondent_bank_city: 'Ciudad del banco corresponsal',
  correspondent_bank_swift: 'Swift del banco corresponsal',
  complete_profile: 'Perfil completo',
  document_received: 'Su documento fue recibido y será revisado por nuestro equipo.',
  must_be_over_18: 'Debe tener más de 18 años para registrarse.',
  shareholders_must_be_over_18: 'Los accionistas deben tener más de 18 años para registrarse.',
  directors_must_be_over_18: 'Los directores deben tener más de 18 años para registrarse.',
  passwords_must_match: 'La confirmación de la nueva contraseña no coincide',
  internal_error: 'Se ha producido un error interno.',
  please_enter_your_email: 'Introduzca su dirección de correo electrónico.',
  reset_your_old_password: 'Restablecer su contraseña anterior',
  reset_password_desc:
    'Debido a una transferencia del sistema, debe actualizar su contraseña. Utilice el siguiente formulario para recibir un enlace de restablecimiento de contraseña.',
  eurotrader_login: 'Iniciar sesión en Plexytrade | Entre en el centro de trading para subir de nivel en su trading',
  your_email_is_verified: 'Su correo electrónico ya está verificado.',
  click_here_to_login: 'Haga clic aquí para iniciar sesión',
  success_email_verified: '¡Su correo electrónico se ha verificado exitosamente!',
  your_link_expired: 'Su enlace ha caducado',
  click_here_to_request_verification_email: 'Solicite un nuevo correo electrónico de verificación',
  a_new_verification_link_send: 'Se ha enviado un nuevo enlace de verificación a su dirección de correo electrónico.',
  invalid_request_please_check_your_link: 'Solicitud no válida. Compruebe que su enlace sea correcto e inténtelo de nuevo.',
  invalid_verification_code: 'Código de verificación no válido',
  please_enter_amount: 'Introduzca el monto que desea depositar',
  bankwire_company_desc:
    '<p>Si es titular de una cuenta bancaria de empresa y desea utilizar la opción de transferencia bancaria registrando su cuenta de empresa con nosotros, envíenos la siguiente documentación por correo electrónico a backoffice@plexytrade.com</p><ul><li>Certificado de Constitución/Empresa</li><li>Certificado de Directores y Secretario, si procede</li><li>Certificado de Accionistas Registrados/Propietario Único</li><li>Memorándum y Artículos de Asociación, también conocidos como Artículos</li><li>Prueba de Identificación y Dirección de todos los Accionista/s, Propietario/s Efectivo/s y Director/es registrados</li><li>Comprobante de domicilio relacionado con la empresa</li></ul><p>Cualquier otro documento oficial o gubernamental que verifique la información solicitada anteriormente puede considerarse suficiente, en caso de que los documentos anteriores no puedan proporcionarse en dicho formato.</p>',
  bankwire_noncompany_desc:
    '<p>Esto cerrará la sesión de su cuenta personal y le redirigirá a nuestra página de registro para obtener una cuenta de empresa.</p><p>¿Le gustaría continuar?</p>',
  please_enter_card_detail_and_amount: 'POR FAVOR INTRODUZCA LOS DATOS DE LA TARJETA Y EL MONTO QUE DESEA DEPOSITAR',
  important: 'IMPORTANTE',
  please_note_that_the_maximum_deposit_amount: 'Tenga en cuenta que el monto de depósito máximo por día es de 1000 $ por tarjeta.',
  city: 'Ciudad',
  zip: 'Código postal',
  please_note_that_after_three_unsuccessful_attempts:
    '* Tenga en cuenta que después de tres (3) intentos fallidos, la opción de pago con VISA/MASTERCARD no estará disponible durante 24 horas.',
  epay_pro_desc:
    '<p>Estimado operador<br><br>Lamentablemente, debido a ciertos problemas técnicos y restricciones, los depósitos con tarjeta de crédito estarán temporalmente desactivados a través de nuestro proveedor EPayPro.<br><br>Visa y Mastercard se desactivarán a las 23:00 GMT del 04/04/2018. Tras la desactivación del proveedor de pagos, los depósitos se desactivarán temporalmente. Aunque nuestros proveedores de servicios de pago no nos han facilitado un plazo exacto para el periodo de inactividad, estamos trabajando para poner en marcha alternativas lo antes posible.<br><br>Estamos trabajando con nuestros proveedores para resolver los problemas actuales, de modo que en el futuro las cuentas puedan volver a procesar los depósitos sin demora.<br><br>Si tiene alguna pregunta, no dude en ponerse en contacto con nuestro equipo de atención al cliente.</p>',
  provider_redirect: 'Se le redirigirá a {provider}.',
  lydiax_note: 'El depósito mínimo es de 30 USD/EUR',
  lydiax_note1:
    'Si deposita menos de 30 USD/EUR de {crypto}, deberá enviar un correo electrónico a backoffice@plexytrade.com,  y solicitar un reembolso, proporcionando la dirección de {crypto} a la que desea recibir el reembolso.',
  lydiax_note2:
    'Introduzca el monto de {crypto} que desea depositar para ver una estimación de lo que obtendrá. Tenga en cuenta que los siguientes precios solo son válidos para el momento en que se solicitaron y pueden cambiar en cualquier momento.',
  lydiax_note3: 'Crearemos una dirección de depósito para que la use.',
  lydiax_note4: 'Utilice la siguiente dirección para depositar {crypto}.',
  lydiax_note5: 'En adelante, esta única dirección se utilizará para sus depósitos en {crypto}.',
  lydiax_required_address: 'Si su monedero requiere una dirección y una etiqueta por separado, use:',
  tag: 'Etiqueta',
  lydiax_enter_deposit: 'POR FAVOR INTRODUZCA LA CRIPTOMONEDA QUE DESEA DEPOSITAR',
  lydiax_withdrawal_limit_error: 'Límite de retiro superado. El monto de retiro solicitado supera el límite máximo de 50 000 $. Inténtelo de nuevo con un monto menor.',
  lydiax_please_select: 'Seleccione',
  create_address: 'Crear dirección',
  please_note: 'Tenga en cuenta lo siguiente:',
  price_estimate: 'Estimación del precio:',
  check_price: 'Compruebe el precio',
  vload_please_enter_voucher: 'Introduzca el PIN de su tarjeta electrónica PayRedeem',
  vload_minimum: '<p>(El valor mínimo aceptable de la tarjeta electrónica es <b>50</b> EUR o USD)</p>',
  vload_vouchers: 'Necesito comprar tarjetas electrónicas PayRedeem',
  vload_note:
    'Pague sin problemas con Visa/MasterCard o transferencia bancaria.<br />Obtenga más información sobre PayRedeem o compre una tarjeta electrónica de [EUR/USD] en <a href="https://app.payredeem.com/buy" target="_blank" class="font-semibold">app.payredeem.com/buy</a>.',
  vload_note1: 'Para poder realizar depósitos con PayRedeem, primero se debe aprobar su cuenta.',
  reason: 'Motivo:',
  attempts: 'Intento {attempts} de {maxAttempts},',
  two_more_remaining: 'quedan dos (2) más',
  one_more_remaining: 'Solo queda UN (1) intento',
  please_contact_bank: '<br />Póngase en contacto con el banco emisor de la tarjeta.<br /> Su tarjeta puede estar bloqueada para transacciones en el extranjero',
  edata_success_note:
    'Tenga en cuenta que puede haber una comisión adicional sobre su depósito, cobrada por su banco emisor debido a la transacción internacional. Esta comisión será pequeña, pero variará de un banco a otro. <br><br>Esta transacción con tarjeta de crédito aparecerá en su extracto como «Tech Savvy Billing», «Business Billing» o «Business media Billing».',
  you_can_try: 'puede intentarlo de nuevo',
  here: 'aquí',
  safe_charged_success_message: 'Nuestro backoffice procesará su depósito en un plazo de 2 días hábiles.',
  thank_you: '¡Gracias!',
  your_deposit_successful: 'Su depósito se ha realizado exitosamente',
  card_redirect: 'Se le redirigirá para que introduzca sus datos personales y de tarjeta de crédito.',
  bank_wire_withdrawal_note:
    'En caso de rechazo de una transferencia bancaria por parte del banco receptor debido a que los datos bancarios facilitados por el cliente son erróneos, se restarán del monto de retiro solicitado los gastos de administración de la transferencia cobrados por los bancos implicados antes de realizar el abono en el monedero del cliente.',
  please_enter_amount_withdraw: 'Introduzca el monto que desea retirar',
  bitcoin_note: 'Seleccione su red de criptomonedas',
  crypto_network_address: 'Introduzca su dirección de criptomoneda',
  otp: 'Contraseña de un solo uso (OTP)',
  otp_send_email: 'Enviar la OTP al correo electrónico',
  otp_additional_text: 'La OTP anterior es para la solicitud actual de retiro de Bitcoin que está realizando',
  otp_new_password: 'Se ha enviado una nueva contraseña de un solo uso (OTP) a su correo electrónico',
  otp_error: 'Error: no se ha podido enviar la OTP',
  lydiax_withdrawal_note:
    'Introduzca el {currency} monto que desea retirar para ver una estimación de {crypto} lo que obtendrá. Tenga en cuenta que los siguientes precios solo son válidos para el momento en que se solicitaron y pueden cambiar en cualquier momento.',
  enter_currency_amount: 'Introduzca {currency} el monto para comprobar la estimación',
  lydiax_withdrawal_additional_note: 'La OTP anterior es para la solicitud actual de retiro de criptomonedas que está realizando',
  estimated: 'Estimado:',
  please_enter_your_crypto_address: 'Introduzca su dirección {crypto}',
  crypto_address: 'Introduzca su dirección criptomoneda',
  destination_tag_required:
    'Le recomendamos encarecidamente que compruebe con su proveedor de monedero si necesita una etiqueta de destino antes de introducir los datos solicitados, para evitar cualquier pérdida de fondos.',
  select_cryptocurrency: 'Seleccione la criptomoneda',
  request_amount: 'Monto solicitado',
  amount_changes: 'Monto cobrado (incluye la comisión)',
  operation_failed: 'Error al realizar la operación Su solicitud no es válida.',
  operation_failed_error: 'Error al realizar la operación Se ha producido un error al procesar esta solicitud.',
  declarations: 'DECLARACIONES',
  please_check_forms_errors: 'Revise los formularios para ver si hay errores.',
  profile_succesfully_completed: 'Perfil completado exitosamente',
  empty_country_code: 'Código de país vacío, actualice la página e inténtelo de nuevo.',
  invalid_country_code: 'Código de país no válido, actualice la página e inténtelo de nuevo.',
  prev: 'ANTERIOR',
  previous: 'Anterior',
  complete: 'Completar',
  please_select_an_option: 'Seleccione una opción para cada campo',
  swap: 'swap',
  buy: 'comprar',
  sell: 'vender',
  sums: 'sumas',
  net_total: 'TOTAL NETO',
  deposit_page_desc: 'Regístrese ahora y obtenga un cupón electrónico de 10 EUR/USD GRATIS',
  deposit_page_desc1: 'El cupón electrónico de bonificación de 10 EUR/USD se activará una vez que',
  create_new_account: 'cree una nueva cuenta',
  create_new_master_account: 'Cree una nueva cuenta maestra',
  successfully_purchase_vload: 'compre y canjee exitosamente una tarjeta electrónica PayRedeem de cualquier valor',
  upgrade_to_tier_2: 'Actualice al nivel 2',
  register_now: 'REGÍSTRESE AHORA',
  back: 'volver',
  my_card_desc: 'Aquí puede ver todas las tarjetas que ha utilizado para realizar pagos y su estado actual en nuestro sistema.',
  you_have_no_card: 'Aún no ha realizado ningún depósito con tarjeta.',
  card_no: 'N.º de tarjeta',
  last_used: 'Utilizado por última vez',
  risk_warning: 'ADVERTENCIA DE RIESGO',
  drop_files_upload: 'Suelte los archivos aquí para cargarlos',
  date_until: 'Fecha hasta',
  copy: 'Copiar',
  cvv: 'CVV',
  please_enter_crypto_amount: 'Introduzca el monto {crypto}',
  vload_pin: 'Pin PayRedeem',
  deposit_info:
    'El proveedor de pagos está experimentando problemas técnicos actualmente y los depósitos directos con Visa/Mastercard no están disponibles en estos momentos. Alternativamente, si desea realizar depósitos con Visa/Mastercard, puede utilizar la opción «PayRedeem»',
  id: 'ID',
  pamm_modal_error: 'Entrada no válida para Mostrar nombre. No lo deje vacío ni introduzca más de 32 caracteres',
  pamm_modal_error1: 'Valor no válido introducido para Depósito mínimo. Introduzca un número entero positivo inferior a 1 000 000.',
  error_missing_required_field: 'Error, falta un campo obligatorio',
  records: 'Registros',
  gateway: 'portal',
  region: 'Región',
  wallet_currency: 'Divisa del monedero',
  join: 'unirse',
  demo_account: 'Cuenta de demostración',
  page_title_demo: 'Abra una cuenta de demostración',
  what_is_demo: '¿Qué es una cuenta de demostración de trading?',
  demo_explanation:
    'Nuestra cuenta de demostración de trading gratuita es una forma práctica de explorar la plataforma, practicar el trading y probar diferentes estrategias sin comprometer ni un céntimo ni un dólar. Con ella, podrá sumergirse en cada mercado antes de lanzarse de cabeza.',
  title_404: 'Algo ha fallado',
  text_line_1_404: 'La mala noticia es que la página que estaba buscando no existe.',
  text_line_2_404: 'La buena noticia es que aún puede operar con Plexytrade.',
  button_404: 'Volver',
  remember_me: 'Recordarme',
  risk_sentence: 'Para ver cómo utilizamos sus datos, consulte',
  video_courses: 'Cursos en vídeo',
  ebooks: 'Libros electrónicos',
  knowledge_and_experience: 'CONOCIMIENTOS Y EXPERIENCIA',
  economic_profile: 'PERFIL ECONÓMICO',
  product_governance: 'GESTIÓN DEL PRODUCTO',
  select_an_answer: 'Seleccione una respuesta',
  declarations_q1: '¿Está implicado actualmente, o lo ha estado alguna vez, en alguna actividad delictiva o existen cargos penales pendientes contra usted ante un tribunal?',
  declarations_q2:
    '¿Es usted, un familiar o un colaborador cercano suyo una persona políticamente expuesta? Las personas políticamente expuestas son las personas físicas que desempeñan o han desempeñado funciones públicas destacadas en los últimos 12 meses, es decir, Jefes de Estado o de Gobierno, políticos de alto nivel, funcionarios gubernamentales, judiciales u oficiales militares, altos ejecutivos de empresas estatales o importantes funcionarios de partidos políticos.',
  declarations_q3: '¿Es usted ciudadano de los EE. UU. o residente de EE. UU. a efectos fiscales?',
  live_account: 'Cuenta real',
  data_policy_text:
    'Sus datos están seguros con nosotros y puede darse de baja en cualquier momento. Al hacer clic a continuación, confirma que ha leído y aceptado los <a href="{TERMS_CONDITIONS_URL}" target="_blank">Términos y condiciones</a> y la <a href="{RISK_DISCLOSURE_URL}" target="_blank"> Divulgación de riesgos</a>, y da su consentimiento para que almacenemos y procesemos sus datos personales.',
  partner_account: 'Cuenta de socio',
  privacy_policy: 'Política de privacidad',
  live_account_title: '¡Estamos deseando que empiece!<br/>Díganos, ¿se une como particular o como empresa?',
  two_factor_register:
    'Para verificar su número de teléfono, tendremos que enviarle una contraseña de un solo uso. Una vez que reciba el correo electrónico, introduzca el código que ha recibido en el campo que aparece a continuación.',
  two_factor_login:
    'Se ha enviado un mensaje al número de teléfono registrado con sus datos de usuario. Introduzca el código en el siguiente campo para completar su inicio de sesión.',
  deposits: 'Depósitos',
  withdrawals: 'Retiros',
  see_more: 'Ver más',
  all: 'Todo',
  create_account: 'Cree una cuenta',
  account_balance: 'Saldo de la cuenta',
  trading_account_currency: 'Divisa de la cuenta de negociación',
  last_five_days: 'Últimos 5 días',
  learn_more: 'Más información',
  welcome_bonus: 'Bonificación en efectivo',
  welcome_bonus_breakline: '<span class="text-green">Bonificación</span><br />en efectivo<br />del 120%',
  analytics: 'Analítica',
  transactions: 'Transacciones',
  settings: 'Ajustes',
  platforms: 'Plataformas',
  select_operating_system: 'Seleccione el sistema operativo',
  select_platform: 'Seleccione la plataforma',
  trading: 'Trading',
  mng_your_ac: 'Gestione su cuenta',
  almost_finished: 'Ya casi lo tiene. Finalice la configuración de la cuenta',
  acc_approved: 'Se ha aprobado la cuenta',
  create_trading_account: 'Cree una cuenta de trading',
  select_type_of_account: 'Seleccione el tipo de cuenta',
  select_balance: 'Seleccione el saldo',
  please_select_all_options: 'Seleccione todas las opciones',
  type_of_account: 'Tipo de cuenta',
  starting_balance: 'Saldo inicial',
  error_message_create_account: 'Algo salió mal. Inténtelo de nuevo.',
  leverage: 'Apalancamiento',
  would_like_to_receive_bonus:
    'Me gustaría recibir la Bonificación en efectivo del 120% y estoy de acuerdo con los<a class="text-blue" href="https://plexytrade.com/assets/files/bonus-terms-and-conditions-1.pdf?v=1.9" target="_blank">Términos y condiciones de la bonificación</a>.',
  receive_bonus: 'Reciba la Bonificación en efectivo del 120%',
  need_help: '¿Necesita ayuda?',
  forgot_password_text:
    '¿Olvidó su contraseña? Indique a continuación la dirección de correo electrónico de su cuenta. A continuación, recibirá un correo electrónico con un enlace para restablecer su contraseña.',
  id_front: 'Prueba de Existencia Legal/Anverso',
  id_back: 'Prueba de Existencia Legal/Reverso',
  cc_front: 'Comprobante de tarjeta de crédito/débito/Anverso',
  cc_back: 'Comprobante de tarjeta de crédito/débito/Reverso',
  address_proof: 'Comprobante de domicilio',
  other: 'Añadir un documento (opcional)',
  id_back_info: '',
  cc_front_info: '',
  cc_back_info: '',
  address_proof_info: '',
  other_info: '*Puede añadir hasta 4 documentos adicionales.',
  id_front_instructions: 'Cargue su documento de identidad, pasaporte o licencia de conducir.',
  id_back_instructions: 'Cargue su documento de identidad, pasaporte o licencia de conducir.',
  cc_front_instructions: 'Cargue su tarjeta de crédito o débito.',
  cc_back_instructions: 'Cargue su tarjeta de crédito o débito.',
  address_proof_instructions: 'Cargue su factura de servicios públicos, el extracto bancario, el extracto de la hipoteca y el extracto del préstamo.',
  other_instructions: 'Otros documentos que hayan sido solicitados por nuestro departamento de verificación.',
  documents_must_contain_all_of_the_following_details: 'Los documentos deben cumplir los siguientes requisitos:',
  id_front_req1: 'Copia completa del documento mostrando las 4 esquinas.',
  id_front_req2: 'El documento debe estar en color',
  id_front_req3: 'Su nombre completo',
  id_front_req4: 'Un número de identificación único',
  id_front_req5: 'Su fecha de nacimiento',
  id_front_req6: 'Su lugar de nacimiento',
  id_front_req7: 'Una foto visible',
  id_front_req8: 'Su nacionalidad',
  id_front_req9: 'Fecha de emisión',
  id_front_req10: 'Fecha de caducidad',
  id_back_req1: 'Copia completa del documento mostrando las 4 esquinas.',
  id_back_req2: 'El documento debe estar en color',
  id_back_req3: 'Su nombre completo',
  id_back_req4: 'Un número de identificación único',
  id_back_req5: 'Su fecha de nacimiento',
  id_back_req6: 'Su lugar de nacimiento',
  id_back_req7: 'Una foto visible',
  id_back_req8: 'Su nacionalidad',
  id_back_req9: 'Fecha de emisión',
  id_back_req10: 'Fecha de caducidad',
  cc_front_req1: 'Copia completa del documento mostrando las 4 esquinas.',
  cc_front_req2:
    'Nombre de la tarjeta: la tarjeta que vaya a utilizar debe estar emitida únicamente a su nombre. Se reembolsará inmediatamente cualquier depósito realizado con una tarjeta perteneciente a un tercero.',
  cc_front_req3: 'Fecha de caducidad: no menos de 3 meses.',
  cc_front_req4:
    'Número de tarjeta: Los ocho dígitos centrales de la tarjeta deben cubrirse con un pedazo de papel. Solo deben ser visibles los cuatro primeros y los cuatro últimos dígitos.',
  cc_back_req1: 'Copia completa del documento mostrando las 4 esquinas.',
  cc_back_req2:
    'Nombre de la tarjeta: la tarjeta que vaya a utilizar debe estar emitida únicamente a su nombre. Se reembolsará inmediatamente cualquier depósito realizado con una tarjeta perteneciente a un tercero.',
  cc_back_req3: 'Fecha de caducidad: no menos de 3 meses.',
  cc_back_req4:
    'Número de tarjeta: Los ocho dígitos centrales de la tarjeta deben cubrirse con un pedazo de papel. Solo deben ser visibles los cuatro primeros y los cuatro últimos dígitos.',
  cc_back_req5: 'Código CVV: Utilice un pedazo de papel para cubrir el código CVV.',
  cc_back_req6: 'La tarjeta debe estar firmada.',
  address_proof_req1: 'Copia completa del documento mostrando las 4 esquinas',
  address_proof_req2: 'Su nombre completo',
  address_proof_req3: 'Su dirección física, que debe coincidir con la dirección que utilizó al registrar la cuenta',
  address_proof_req4: 'Fecha reciente (no más de 6 meses)',
  address_proof_req5:
    'Debe ser una factura de servicios públicos (electricidad, agua, teléfono, gas/energía, impuestos municipales/facturas de saneamiento) o un extracto bancario',
  address_proof_req6: 'Debe verse el código postal completo.',
  other_req1: 'Copia completa del documento mostrando las 4 esquinas',
  click_add_another_doc: 'Haga clic para añadir otra ranura de carga de documentos. Puede añadir hasta 4 documentos adicionales.',
  btn_new_slot: 'Nueva ranura',
  additional_docs_email: '*La documentación adicional puede enviarse por correo electrónico a backoffice@plexytrade.com',
  doc_approved: 'Su documento ha sido aprobado.',
  opt_in_options: 'Selecciones de suscripción (prototipo)',
  cpa: 'CPA',
  copied: '¡Copiado exitosamente!',
  try_before_u_trade: 'pruebe antes de operar',
  client_details: 'Detalles del cliente',
  select_trading_account: 'Seleccione una cuenta de trading',
  total_commission: 'total de comisiones',
  banner: 'Banner',
  vps: 'VPS',
  hosting: 'alojamiento',
  vps_text:
    'Obtenga un alojamiento VPS ininterrumpido y potente para tener un acceso continuo a su plataforma de trading de manera segura, estable y a una velocidad incomparable.',
  order_now: 'Ordene ahora',
  vps_packages: 'Paquetes VPS',
  choose_vps_package: 'Elija un paquete VPS',
  standard: 'Estándar',
  per_month: 'por mes',
  standard_text: 'o <b>Gratis</b><br />con {standard_min_deposit} depósitos mínimos',
  gold_text: 'o <b>Gratis</b><br />con {gold_min_deposit} depósitos mínimos',
  advance_text: 'o <b>Gratis</b><br />con {advance_min_deposit} depósitos mínimos',
  per_live_trading_account: 'por cuenta de trading real',
  terms_and_conditions_apply: 'Se aplican los términos y condiciones',
  gold: 'Oro',
  most_popular: 'Más popular',
  advance: 'Avanzar',
  equivalent: 'equivalente',
  something_went_wrong: 'Algo salió mal. Inténtelo de nuevo.',
  verify: 'comprobar',
  choose_trading_account: 'Elija una cuenta de trading',
  vps_description: 'El servicio VPS está disponible por cuenta de trading real. Por cada paquete VPS adicional, se requiere un número igual de cuentas de trading reales.',
  agree_to_terms: 'Acepto los <a href="{URL}" target="_blank" class="font-semibold underline">términos y condiciones</a>',
  renewal_on: 'Renovación en',
  vps_hosting_service: 'Servicio de alojamiento VPS',
  load_more: 'Cargar más',
  please_select_payment_method: 'Seleccione el método de pago',
  lydiax: 'Criptomonedas',
  select_crypto_network: 'seleccione su red de {crypto}',
  vload: 'PayRedeem',
  popular_deposits: 'Depósitos populares',
  popular_withdrawals: 'Retiros populares',
  my_settings: 'Perfil de Plexy',
  personal_details: 'Datos personales',
  profile: 'Perfil',
  pamm_account: 'PAMM',
  help: 'Ayuda',
  transfer_history: 'Historial de transferencias',
  total_bonuses_granted: 'Total de bonificaciones concedidas',
  total_bonuses_converted: 'Total de bonificaciones convertidas',
  bonuses: 'Bonificaciones',
  pamm_dashboard: 'Panel PAMM',
  pamm_text_line_1: 'Las Estadísticas Pamm están disponibles en nuestra área de Cliente PAMM.',
  pamm_text_line_2: 'Si tiene una cuenta PAMM, haga clic en el enlace a continuación para ser redirigido y utilice las credenciales de su cuenta PAMM para iniciar sesión.',
  please_enter_amount_transfer: 'Introduzca el monto que desea transferir',
  live: 'Real',
  demo: 'Demostración',
  no_available_live_account: 'No hay ninguna cuenta de trading real disponible, <span class="underline cursor-pointer font-bold">haga clic aquí para crear una</span>.',
  check_your_options: 'Confirme sus datos para crear su cuenta',
  okay: 'Aceptar',
  hard_disk: 'Disco duro',
  included: 'incluido',
  quick_links: 'Enlaces rápidos',
  vps_hosting_service_dashboard: '<span class="text-green">Alojamiento</span> VPS<br />',
  vps_hosting_service_dashboard_mobile: '<span class="text-green">Alojamiento</span> VPS',
  hello: 'Hola',
  shareholders_directors: 'accionistas y directores',
  alpha_generation_plugin: 'Alpha<br />Generation<br />Plugin',
  daily_market_newsletter: 'Boletín<br />Diario<br />del Mercado',
  no_records: 'No hay registros',
  subscribe: 'Suscribirse',
  sign_out: 'Cerrar sesión',
  account_approved: 'Cuenta aprobada',
  privacy: 'Privacidad',
  reset: 'Restablecer',
  change_password_note:
    'Este cambio de contraseña no afectará la contraseña de su cuenta de trading. Visite la lista de cuentas para cambiar la contraseña de su cuenta de trading, utilizando la opción de cambio de contraseña situada bajo el icono del engranaje.',
  newsletter_sub_full: '¡Ya está listo! Consulte su buzón para ver el Boletín Diario del Mercado.',
  newsletter_sub_partial: '¡Gracias por registrarse! Necesita realizar {amount} más depósitos para empezar a recibir el Boletín Diario del Mercado.',
  logoloader_newsletter_subscribing: 'Suscribiéndose...',
  statistics: 'Estadísticas',
  requirements: 'Requisitos',
  select_file: 'Seleccione el archivo',
  vload_vouchers_text: 'Si necesita comprar tarjetas electrónicas PayRedeem, visite <a href="https://payredeem.com/" target="_blank" class="font-semibold">payredeem.com</a>',
  vload_text_1: 'Pague con múltiples divisas, tarjetas de crédito/débito, transferencias bancarias, criptomonedas y pagos locales.',
  vload_text_2: 'Cree una cuenta en <a href="https://payredeem.com/" target="_blank" class="font-semibold">payredeem.com</a>',
  vload_text_3: 'Haga clic en comprar y seleccione el sitio web.',
  vload_text_4: 'Pague con su método de pago preferido.',
  vload_text_5: 'Canjee el PIN de la tarjeta electrónica.',
  vload_text_6:
    '¿Necesita ayuda? Vaya a <a href="https://help.payredeem.com/" target="_blank" class="font-semibold">help.payredeem.com</a> y póngase en contacto con nosotros las 24 horas del día, los 7 días de la semana, por chat, correo electrónico o teléfono.',
  wd_vload_text_1:
    'Cuando inicie un retiro, se emitirán tarjetas electrónicas de pago a su cuenta PayRedeem. Puede utilizar estas tarjetas electrónicas en cualquier tienda que acepte PayRedeem o puede cobrarlas en su cuenta bancaria o en su monedero de criptomonedas.',
  wd_vload_text_2:
    'Puede encontrar sus tarjetas electrónicas de pago en su <a href="https://app.payredeem.com/payouts" target="_blank" class="font-semibold">Panel de perfil PayRedeem</a>.',
  wd_vload_text_3: 'En algunos casos, se le pedirá que indique su dirección de pago. Puede encontrarlo fácilmente en su perfil, en la sección Pagos.',
  country_of_residence: 'País de residencia',
  firstname_placeholder: 'Igual que en su documento de identidad',
  lastname_placeholder: 'Igual que en su documento de identidad',
  personal_and_address_info: 'Datos personales y dirección',
  address_placeholder: 'Nombre de la calle, n.º de casa, apartamento',
  gender_other: 'Otros',
  optional: 'Opcional',
  email_address: 'Dirección de correo electrónico',
  trading_account_info: 'Información de la cuenta de trading',
  trading_platform: 'Plataforma de trading',
  account_currency: 'Divisa de la cuenta',
  marketing_checkbox: 'Deseo recibir noticias, actualizaciones de productos y promociones de la empresa.',
  invalid_email: 'Introduzca una dirección de correo electrónico válida.',
  launch_webtrader: 'Ejecutar Webtrader',
  accept: 'He leído y aceptado el ',
  partners_agreement: 'Acuerdo de socios',
  recaptchaText:
    'Este sitio está protegido por reCAPTCHA y se aplican la <a class="font-bold" href="https://policies.google.com/privacy">Política de privacidad</a> y las <a class="font-bold" href="https://policies.google.com/terms">Condiciones de Servicio</a> de Google.',
  verify_your_email: 'Verifique su correo electrónico',
  verify_your_email_description:
    'Se ha enviado un correo electrónico con un código de validación a su dirección de correo electrónico registrada. Para completar su registro, verifique su dirección de correo electrónico añadiendo el código de 6 dígitos que aparece a continuación.',
  code_not_recieved: '¿No ha recibido el código?',
  resend: 'Reenviar',
  confirm_email: 'Confirmar correo electrónico',
  contact_us: 'Contáctenos',
  try_again: 'Inténtelo de nuevo',
  try_again_description: 'El código que ingresó no es correcto',
  attempts_left: 'intentos restantes',
  resend_code: 'Reenviar código',
  try_again_expired: '¡Inténtelo de nuevo! El código que ingresó ha caducado.',
  verification_failed: 'Error en la verificación',
  verification_failed_header: '¡Error al verificar la dirección de correo electrónico!',
  verification_failed_description: '¿Tiene problemas para verificar su correo electrónico?',
  bonus_120: 'Bonificación del 120%',
  no_bonus: 'Sin bonificación',
  cash_bonus_120: 'Bonificación en efectivo del 120%',
  reg_form_step_1_individual: 'Datos personales',
  reg_form_step_1_company: 'Información de la empresa',
  reg_form_step_2_individual: 'Datos personales y dirección',
  reg_form_step_2_company: 'Accionistas y directores',
  reg_form_step_3_individual: 'Información de la cuenta de trading',
  reg_form_step_3_company: 'Detalles de registro y perfil',
  reg_disclaimer_title: '¿Qué es una cuenta de demostración de trading?',
  reg_disclaimer_text: 'Nuestra cuenta de demostración de trading gratuita es una forma práctica de explorar la plataforma, practicar el trading y probar diferentes estrategias.',
  trading_account_type: 'Tipo de cuenta de trading',
  company_name_placeholder: 'Igual que en el documento de registro de su empresa',
  referal_links: 'Enlaces de referencia',
  registration_link: 'Página de registro',
  website_home_page: 'Página de inicio del sitio web',
  bonus_page: 'Página de bonificación',
  pamm_page: 'Página de PAMM',
  internal_transfer_note: 'No puede realizar transferencias entre 2 cuentas de trading.',
  upgrade_to_live_text: 'Para realizar cualquier transacción, primero debe pasar a una cuenta real y tener esta aprobada.',
  upgrade_to_live: 'Actualizar a cuenta real',
  upload_your_documents_text: 'Para realizar cualquier transacción, primero debe cargar sus documentos y obtener la aprobación de su cuenta.',
  upload_your_documents: 'Cargue sus documentos',
  failed_account_creating_message: 'No se ha podido crear la cuenta de trading: ',
  english: 'Inglés',
  spanish: 'Español',
  italian: 'Italiano',
  chinese: 'Chino',
  farsi: 'Farsi',
  opened_a: 'Abrió una',
  position_on: 'posición en',
  at: 'en',
  likes: 'me gusta',
  sub_status_check_fail: 'No se ha podido verificar el estado de la suscripción: ',
  news_sub_req_fail: 'No se ha podido solicitar la suscripción al boletín: ',
  lang_settings_saved: 'Configuración de idioma guardada',
  unable_save_lang_settings: 'No se puede guardar la configuración de idioma',
  processed: 'Procesado',
  cancelled: 'Cancelado',
  pending: 'Pendiente',
  requested: 'Solicitado',
  approved: 'Aprobado',
  declined: 'Rechazado',
  mismatch: 'No coincide',
  sending: 'Enviando',
  export: 'Export',
  empty_dropzone: 'Primero tienes que llenar la caja vacía',
  server_name: 'Nombre del servidor',
  congratulations: 'Felicidades',
  credentials_to_login_to_mt: 'Utilice los detalles a continuación para iniciar sesión en la plataforma MetaTrader con su nueva cuenta.',
  banner_trading: 'Banner Trading',
  banner_pamm_platform: 'Banner PAMM Platform',
  banner_mt4_mt5_platform: 'Banner MT4 + MT5 Platform',
  banner_cash_bonus: 'Banner 120% Cash-Bonus',
  banner_leverage: 'Banner Leverage',
  guidance_documents: 'Documentos de orientación',
  front: 'Anverso',
  identity_card_text_front_1:
    'Coloque el anverso de su documento de identidad sobre una superficie plana (por ejemplo, un escritorio o mostrador), de modo que no se formen reflejos de luz o sombras.',
  identity_card_text_back_1:
    'Coloque el reverso de su documento de identidad sobre una superficie plana (por ejemplo, un escritorio o mostrador), de modo que no se formen reflejos de luz o sombras.',
  identity_card_text_2: 'Cargue todos los documentos solicitados uno por uno.',
  identity_card_text_3: 'El documento debe presentarse en color.',
  identity_card_text_4: 'Su documento de identidad debe tener una validez de al menos 3 meses.',
  identity_card_image_text: '1. El documento entero debe ser visible en su totalidad, incluyendo las cuatro esquinas.',
  passport_text_1: 'Coloque su pasaporte sobre una superficie plana (por ejemplo, un escritorio o mostrador), de modo que no se formen reflejos de luz o sombras.',
  passport_text_2: 'Cargue todos los documentos solicitados uno por uno.',
  passport_text_3: 'Su pasaporte debe tener una validez de al menos 3 meses.',
  passport_image_text: 'El documento debe estar abierto para que la imagen abarque ambas páginas.',
  driving_license_text_front_1:
    'Coloque el anverso de su permiso de conducir sobre una superficie plana (por ejemplo, un escritorio o mostrador), de modo que no se formen reflejos de luz o sombras.',
  driving_license_text_back_1:
    'Coloque el reverso de su permiso de conducir sobre una superficie plana (por ejemplo, un escritorio o mostrador), de modo que no se formen reflejos de luz o sombras.',
  driving_license_text_2: 'Cargue todos los documentos solicitados uno por uno.',
  driving_license_text_3: 'El documento debe presentarse en color.',
  driving_license_text_4: 'Su permiso de conducir debe tener una validez de al menos 3 meses.',
  driving_license_image_text: '1. El documento entero debe ser visible en su totalidad, incluyendo las cuatro esquinas.',
  bank_statement_text_1: 'Coloque su documento sobre una superficie plana (por ejemplo, un escritorio o mostrador), de modo que se incluyan todos los detalles pertinentes.',
  bank_statement_text_2: 'El documento debe tener una validez de al menos 6 meses a partir de la fecha de emisión.',
  bank_statement_image_text_1: '1. El documento no debe aparecer recortado ni doblado, y la página entera debe estar visible, incluidas las cuatro esquinas.',
  bank_statement_image_text_2:
    '2. Corte un trozo de papel y oculte los números, tal y como se muestra, para que solo estén visibles los primeros seis dígitos y los últimos cuatro.',
  bank_statement_image_text_3: '3. Deben estar visibles el nombre, la fecha de emisión y el logotipo de la empresa.',
  bank_statement_image_text_4: '4. Dirección completa (debe ser la misma que la que indicó al registrarse en PlexyTrade).',
  utility_bill_text_1: 'Coloque el documento sobre una superficie plana (por ejemplo, un escritorio o mesa), de modo que estén visibles todos los detalles pertinentes.',
  utility_bill_text_2: 'Evite los reflejos de luz o las sombras.',
  utility_bill_text_3: 'El documento debe ser en color.',
  utility_bill_text_4: 'Las cuatro esquinas deben estar visibles claramente dentro de la imagen facilitada.',
  utility_bill_text_5: 'El documento debe tener una validez de al menos 6 meses a partir de la fecha de emisión.',
  utility_bill_image_text_1: '1. El documento no debe aparecer recortado ni doblado, y la página entera debe estar visible, incluidas las cuatro esquinas.',
  utility_bill_image_text_2: '2. Deben estar visibles el nombre, la fecha de emisión y el logotipo de la empresa.',
  utility_bill_image_text_3: '3. Dirección completa (debe ser la misma que la que indicó al registrarse en PlexyTrade).',
  identity_card: 'Documento de Identidad',
  passport: 'Pasaporte',
  driving_license: 'Permiso de Conducir',
  bank_statement: 'Extracto de Cuenta',
  utility_bill: 'Factura de Servicios Públicos',
  gains_prc: '% de ganancias',
  open_p_l: 'PyG abiertas',
  monthly_avg: 'Prom. mensual',
  max_drawdown: 'Disminución máx.',
  all_time: 'Todo el tiempo',
  last_month: 'Mes pasado',
  last_week: 'Semana pasada',
  last_3_days: '3 últimos días',
  pamm_name_or_account_id: 'Nombre o ID de cuenta',
  welcome_to_plexytrade: 'Le damos la bienvenida a Plexytrade',
  select_demo_live: 'Seleccione un tipo de cuenta',
  required: 'obligatorio',
  additional_terms: 'Marque si se aplican condiciones adicionales',
  additional_terms_apply: 'Se aplican condiciones adicionales. Marque el cuadro si es consciente de ello.',
  additional_terms_check: 'Al proceder, confirma que entiende que está obligado por las condiciones del gestor de fondos.',
  agree_with_no_deposit_bonus: 'He leído y comprendido las <a class="font-bold" target="_blank" href="{URL}">condiciones</a> de la bonificación sin depósito.',
  archived: 'Archivado',
  attach_pamm_question: '¿Está seguro de que desea asignar la cuenta seleccionada a este maestro?',
  attached_to: 'Asignada a',
  create_and_attach: 'crear y asignar',
  create_and_attach_new_pamm_account: 'Crear y asignar una nueva cuenta PAMM',
  crypto_note: 'Nota',
  crypto_note_line_1: 'Este método de pago solo funciona con euros.',
  crypto_note_line_2: 'Los depósitos enviados en otra divisa estarán sujetos a tasas de conversión de los que nuestra empresa no puede hacerse cargo.',
  crypto_note_line_3: 'Para evitar cargos inesperados, considere métodos de depósito alternativos.',
  display_name_on_statistics_table: 'Mostrar nombre en la tabla de estadísticas',
  enter_private_code: 'Introducir código privado',
  i_accept_the_terms_of_service: 'Acepto las <a href="{URL}" target="_blank" class="text-blue">condiciones de servicio</a>',
  important_note: 'Nota importante',
  invest_under_master_account: 'Invertir con cuenta de maestro',
  investor_link: 'Enlace de inversor',
  lydiax_deposit_note: 'Pulse siempre el botón de crear dirección y compruebe la dirección generada antes de proceder a un nuevo depósito con PLEXYTRADE.',
  master_accounts: 'cuentas de maestro',
  min_deposit: 'Depósito mín.',
  new_old_password_are_the_same: 'La nueva contraseña no puede ser la misma que la antigua',
  pamm_masters: 'Maestros PAMM',
  pamm_operation_failed_error: '¡No se ha completado la operación! Se ha producido un error al procesar esta solicitud.',
  pamm_operation_failed_invalid: '¡No se ha completado la operación! Su solicitud no es válida.',
  pamm_success: 'Ha asignado correctamente a un inversor PAMM.',
  pending_account: 'Cuenta pendiente',
  platinum: 'Platinum',
  private: 'privado',
  read_and_accept_lpoa: 'He leído y acepto el <a href="{URL}" target="_blank" class="text-blue">poder limitado</a>.',
  refer_to_pamm_dept: 'Para obtener más información, diríjase al departamento de PAMM',
  reject_withdrawals_during_open_trades: 'rechazar retiradas de fondos durante operaciones abiertas',
  select_a_pamm_account_to_attach: 'Seleccione una cuenta de PAMM que asignar',
  silver: 'Silver',
  transfer_back_to_my_local_depositor: 'devolver a mi depositante local',
  transfer_back: 'Devolver',
  under_maintenance: 'En mantenimiento programado',
  under_maintenance_text: 'Le pedimos disculpas por las molestias ocasionadas.<br>Volveremos pronto.',
  verified_account: 'Cuenta verificada',
  view_graph: 'Ver gráfica',
  withdrawal_note: 'Tenga en cuenta que para poder enviar una solicitud de retirada de fondos, su cuenta debe estar totalmente verificada primero.',
}
