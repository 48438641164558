<template>
  <div>
    <div
      class="flex flex-row justify-between items-center max-w-[1399px] bg-white rounded-t-lg px-[30px] py-[11px] shadow-ts1 z-10 transition-height duration-500 ease-in-out cursor-pointer"
      :class="{ 'absolute bottom-0 w-full': !contractFooter && bottom }"
      @click="expandContract()"
    >
      <div class="w-[95%] leading-none">
        <span
          v-if="contractFooter"
          v-html="$t('risk_warning_footer_uncollapsed')"
          class="hidden lg:inline-block font-body text-xs"
        ></span>
        <span
          v-if="contractFooter"
          v-html="$t('risk_warning_footer_uncollapsed_mobile')"
          class="inline-block lg:hidden font-body text-xs"
        ></span>
        <span
          v-if="!contractFooter"
          v-html="$t('risk_warning_footer',{websiteURL:websiteURL})"
          class="inline-block font-body text-xs"
        ></span>
      </div>
      <span class="inline-block w-auto" v-if="bottom">
        <img
          v-if="contractFooter === true"
          class="ltr:float-right rtl:float-left h-[16px] w-[16px]"
          src="/static/img/footer-up.svg"
        />
        <img
          v-else
          class="float-right rtl:float-left h-[16px] w-[16px]"
          src="/static/img/footer-down.svg"
        />
      </span>
      <span class="inline-block w-auto" v-else>
        <img
          v-if="contractFooter === true"
          class="float-right h-[16px] w-[16px]"
          src="/static/img/footer-down.svg"
        />
        <img
          v-else
          class="float-right h-[16px] w-[16px]"
          src="/static/img/footer-up.svg"
        />
      </span>
    </div>
    <div v-if="!contractFooter" class="h-[40px]"></div>
  </div>
</template>

<script>
import config from '../config'

export default {
  name: 'FooterCollapsible',
  components: {},
  props: {
    bottom: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      footerHeight: 'h-[34px]',
      contractFooter: true,
      websiteURL: config.URL.website,
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    /*     expandFooter() {
      this.footerHeight = 'h-auto]'
    },
    contractFooter() {
      this.footerHeight = 'h-[34px]'
    }, */
    expandContract() {
      this.contractFooter = !this.contractFooter

      if (this.contractFooter) {
        this.footerHeight = 'h-[34px]'
      } else {
        this.footerHeight = 'h-auto]'
      }
    },
  },
}
</script>

<style lang="css" scoped></style>
