import config from '../config';

const websiteURL = config.URL.website;

const links = {
  privacyPolicy: {
    en: `${websiteURL}assets/files/privacy-policy-1.pdf?v=1.6`,
    fa: `${websiteURL}assets/files/fa/privacy-policy-1.pdf?v=1.6`,
  },
  partnersAgreement: {
    en: `${websiteURL}assets/files/partners-agreement-1.pdf?v=1.2`,
    fa: `${websiteURL}assets/files/fa/partners-agreement-1.pdf?v=1.2`,
  },
  riskDisclosure: {
    en: `${websiteURL}assets/files/risk-disclosure-1.pdf?v=1.5`,
    fa: `${websiteURL}assets/files/fa/risk-disclosure-1.pdf?v=1.5`,
  },
  contact: {
    en: `${websiteURL}contact`,
    fa: `${websiteURL}fa/contact`,
    es: `${websiteURL}es/contact`
  },
  vpsTermsAndConditions: {
    en: `${websiteURL}assets/files/vps-terms-and-conditions-1.pdf?v=1.8`,
    fa: `${websiteURL}assets/files/fa/vps-terms-and-conditions-1.pdf?v=1.8`,
  },
  termsOfService: {
    en: `${websiteURL}assets/files/account-opening-agreement-1.pdf?v=1.8`,
    fa: `${websiteURL}assets/files/fa/account-opening-agreement-1.pdf?v=1.8`,
  },
  acceptTheLpoa: {
    en: `${websiteURL}assets/files/lpoa.pdf`,
    fa: `${websiteURL}assets/files/fa/lpoa.pdf`,
    es:`${websiteURL}assets/files/es/lpoa.pdf`
  },
  noDepositBonusURL: {
    en: `${websiteURL}assets/files/no-deposit-bonus-terms-and-conditions.pdf?v=1.2`,
    fa: `${websiteURL}assets/files/fa/no-deposit-bonus-terms-and-conditions.pdf?v=1.2`,
  },
  companyLegalDoc: {
    en: `${websiteURL}company/legal-documents/`,
    fa: `${websiteURL}fa/company/legal-documents/`,
    es:`${websiteURL}es/company/legal-documents/`
  },
  mt4WebTrader: {
    en: `${websiteURL}platforms/metatrader-4-webtrader`,
    fa: `${websiteURL}fa/platforms/metatrader-4-webtrader`,
    es: `${websiteURL}es/platforms/metatrader-4-webtrader`
  },
  mt5WebTrader: {
    en: `${websiteURL}platforms/metatrader-5-webtrader`,
    fa: `${websiteURL}fa/platforms/metatrader-5-webtrader`,
    es: `${websiteURL}es/platforms/metatrader-5-webtrader`,
  },
  promotionWelcomeBonus: {
    en: `${websiteURL}promotions/welcome-bonus`,
    fa: `${websiteURL}fa/promotions/welcome-bonus`,
    es: `${websiteURL}es/promotions/welcome-bonus`,
  },
  vpsToolsHosting: {
    en: `${websiteURL}tools/vps-hosting`,
    fa: `${websiteURL}fa/tools/vps-hosting`,
    es: `${websiteURL}es/tools/vps-hosting`,
  },

  // Method to get the correct link based on language, with fallback to English
  getLink(key, language = 'en') {
    const link = this[key];

    // Check if link is an object with language-specific URLs
    if (typeof link === 'object') {
      // Return the language-specific link if available, else fallback to English
      return link[language] || link.en || '';
    }

    // If the link is a direct string, return it directly
    return link || '';
  }
};

export default links;
