<template>
  <div class="step-three step-three-company px-[20px]">
    <div class="form-wrapper grid grid-cols-4-default gap-5 mb-5">
      <label class="font-body text-base text-gray-900 tracking-wide" for="dob">{{ translations.text.date_of_registration.tTitle() }}</label>
      <div class="col-span-4 lg:col-span-4 grid grid-cols-6-default gap-5">
        <!-- Year of Birth -->
        <div class="col-span-2 md:col-span-2">
          <label class="label is-small" for="dob_year">{{ translations.text.year }}<span class="text-orange ml-1">*</span></label>
          <div class="my-2">
            <span class="select is-fullwidth">
              <select
                v-validate="{ required: true }"
                :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('dob_year') && (data.dateofbirth.year && data.dateofbirth.month && data.dateofbirth.day) }"
                :data-vv-as="translations.text.year"
                name="dob_year"
                v-model="data.dateofbirth.year"
                class="text-base text-gray-900 font-semibold rtl:bg-left"
              >
                <option value="" selected hidden>{{ translations.text.year }}</option>
                <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
              </select>
            </span>
            <span v-show="errors.has('dob_year') && (data.dateofbirth.year && data.dateofbirth.month && data.dateofbirth.day)" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
              errors.first('dob_year')
            }}</span>
          </div>
        </div>
        <!-- Month of Birth -->
        <div class="col-span-2 md:col-span-2">
          <label class="label is-small" for="dob_month">{{ translations.text.month }}<span class="text-orange ml-1">*</span></label>
          <div class="my-2">
            <span class="select is-fullwidth">
              <select
                v-validate="'required'"
                :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('dob_month') }"
                :data-vv-as="translations.text.month"
                name="dob_month"
                v-model="data.dateofbirth.month"
                @blur="validateValues"
                class="text-base text-gray-900 font-semibold rtl:bg-left"
              >
                <option value="" selected hidden>{{ translations.text.month }}</option>
                <option v-for="(month, index) in translations.months" :value="index" :key="index">{{ month }}</option>
              </select>
            </span>
            <span v-show="errors.has('dob_month')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
              errors.first('dob_month')
            }}</span>
          </div>
        </div>
        <!-- Day of Birth -->
        <div class="col-span-2 md:col-span-2">
          <label class="label is-small" for="dob_day">{{ translations.text.day }}<span class="text-orange ml-1">*</span></label>
          <div class="my-2">
            <span class="select is-fullwidth">
              <select
                v-validate="'required'"
                :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('dob_day') }"
                :data-vv-as="translations.text.day"
                name="dob_day"
                v-model="data.dateofbirth.day"
                @blur="validateValues"
                class="text-base text-gray-900 font-semibold rtl:bg-left"
              >
                <option value="" selected hidden>{{ translations.text.day }}</option>
                <option v-for="day in daysInMonth" :value="day" :key="day">{{ day }}</option>
              </select>
            </span>
            <span v-show="errors.has('dob_day')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
              errors.first('dob_day')
            }}</span>
          </div>
        </div>
      </div>
      <!-- Address -->
      <div class="col-span-4 md:col-span-4">
        <label class="font-body text-base text-gray-900 tracking-wide" for="address">{{ translations.text.address }}<span class="text-orange ml-1">*</span></label>
        <div class="my-2">
          <input
            v-validate="'required|max:255'"
            :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('dir-address_') }"
            :name="'dir-address_'"
            type="text"
            :data-vv-as="translations.text.address"
            :placeholder="translations.text.address_placeholder"
            v-model="data.address"
            @blur="validateValues"
            class="text-gray-900 text-base font-semibold"
          />
          <span v-show="errors.has('dir-address_')" class="font-body text-xs text-orange font-semibold">{{ errors.first('dir-address_') }}</span>
        </div>
      </div>
      <!-- Address 2 -->
      <div class="col-span-4 md:col-span-4">
        <label class="font-body text-base text-gray-900 tracking-wide" for="address2">{{ translations.text.address2 }}</label>
        <div class="my-2">
          <input
            v-validate="'max:255'"
            :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('dir-address2_') }"
            :name="'dir-address2_'"
            type="text"
            :data-vv-as="translations.text.address2"
            :placeholder="translations.text.optional"
            v-model="data.address2"
            class="text-gray-900 text-base font-semibold"
          />
          <span v-show="errors.has('dir-address2_')" class="font-body text-xs text-orange font-semibold">{{ errors.first('dir-address2_') }}</span>
        </div>
      </div>
      <!-- Town/City, Province/State, Postal/Zip Code -->
      <div class="col-span-4 lg:col-span-4 grid grid-cols-6-default gap-5">
        <!-- Town/City -->
        <div class="col-span-6 md:col-span-2">
          <label class="font-body text-base text-gray-900 tracking-wide" for="city">{{ translations.text.city }}<span class="text-orange ml-1">*</span></label>
          <p class="my-2">
            <input
              v-validate="'required|max:255'"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('city') }"
              name="city"
              type="text"
              :data-vv-as="translations.text.city"
              :placeholder="translations.text.city"
              v-model="data.city"
              @blur="validateValues"
              class="text-base text-gray-900 font-semibold"
            />
            <span v-show="errors.has('city')" class="font-body text-xs text-orange font-semibold">{{ errors.first('city') }}</span>
          </p>
        </div>
        <!-- Province/State -->
        <div class="col-span-6 md:col-span-2">
          <label class="font-body text-base text-gray-900 tracking-wide" for="state_province">{{ translations.text.state_province }}</label>
          <p class="my-2">
            <input
              v-validate="'max:255'"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('state_province') }"
              name="state_province"
              type="text"
              :data-vv-as="translations.text.state_province"
              :placeholder="translations.text.state_province"
              v-model="data.state_province"
              @blur="validateValues"
              class="text-base text-gray-900 font-semibold"
            />
            <span v-show="errors.has('state_province')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
              errors.first('state_province')
            }}</span>
          </p>
        </div>
        <!-- Postal/Zip Code -->
        <div class="col-span-6 md:col-span-2">
          <label class="font-body text-base text-gray-900 tracking-wide" for="post_code">{{ translations.text.post_zip_code }}<span class="text-orange ml-1">*</span></label>
          <p class="my-2">
            <input
              v-validate="'required|max:255'"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('post_code') }"
              name="post_code"
              type="text"
              :data-vv-as="translations.text.post_code"
              :placeholder="translations.text.post_zip_code"
              v-model="data.post_code"
              @blur="validateValues"
              class="text-base text-gray-900 font-semibold"
            />
            <span v-show="errors.has('post_code')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
              errors.first('post_code')
            }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: {
    accountType: String,
    activeStep: Number,
    isCompany: Boolean,
    translations: Object,
    formData: Object,
    upgrade: Boolean,
    prefilledTestData: Boolean,
    testData: Object,
  },
  data() {
    return {
      years: Array.from({ length: 100 }, (v, i) => moment().year() - i + 1),
      data: {
        dateofbirth: {
          day: '',
          month: '',
          year: '',
        },
        gender: '',
        address: '',
        address2: '',
        city: '',
        state_province: '',
        post_code: '',
        skype_id: '',
        socialMedia: '',
      },
      finalFormErrors: [],
    }
  },
  created() {
    this.$bus.$on('ValidateFinalForm', this.validateValues)
    if (this.prefilledTestData && this.testData) {
      Object.keys(this.testData).forEach(key => {
        this.data[key] = this.testData[key]
      })
    }
  },
  mounted() {
    if (this.isCompany && this.formData.city) {
      Object.keys(this.formData).forEach(key => {
        this.data[key] = this.formData[key]
      })
    }
    document.querySelector(`.step-three-company`).scrollIntoView({ behavior: 'smooth', block: 'center' })
  },
  watch: {
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
      BonusConfig: 'bonusconfig/all',
    }),
    daysInMonth() {
      const year = this.data.dateofbirth.year || new Date().getFullYear();
      const month = this.data.dateofbirth.month || new Date().getMonth();
      return new Date(year, month, 0).getDate();
    }
  },
  methods: {
    validateValues() {
      this.finalFormErrors = []
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.errors.items.forEach(item => {
            this.finalFormErrors.push(item.field)
          })
          this.$bus.$emit('PopulateFinalForm', this.finalFormErrors)
        } else {
          this.$bus.$emit('PopulateFinalForm', [])
          this.$bus.$emit('DataSubmitted', this.data)
          return
        }
        // scroll to first error
        let el = document.getElementsByName(this.errors.items[0].field)[0]
        if (el) return el.focus()
      })
    },
  },
}
</script>
<style scoped>

</style>
