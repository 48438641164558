<template>
  <div class="content has-text-centered"></div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      data: {},
    }
  },
  computed: {
    ...mapGetters({
      Language: 'language/code'
    }),
  },
  mounted() {
    // Login is coming from remote user login.
    if (this.$route.params.key) {
      // make the call to get the login token of the remote user.
      this.$auth.fetch({
        url: 'authenticate/user',
        method: 'GET',
        headers: {
          'Authorization': decodeURI(this.$route.params.key) // Assuming Vue.auth.token() returns the token
        }
      })
      .then((response) => {
        // Create the cookie so when the user closes the tab, the remote user is logged out automatically.
        this.$createCookie(`remote-user-${response.data.userID}`, '1', 1)
        // Redirect to dashboard.
        this.$router.push('/' + this.Language)
      })
    }
  },
  methods: {},
}
</script>
