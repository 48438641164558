<template>
	<div class="content has-text-centered">
		<div v-if="status == 'cancel' || status == 'false'">
			<failed :reason="failureReason"></failed>
		</div>
		<div v-if="status != 'cancel' && status != 'false'">
			<success></success>
		</div>
	</div>
</template>

<script>
import Success from './Success'
import Failed from './Failed'

export default {
	components: {
		Success,
		Failed,
	},
	data() {
		return {
			status: '',
			title: '',
			failureReason: '',
		}
	},
	mounted() {
		this.title = this.$t('method_deposit_status', {method: 'Bitcoin'})
		this.$bus.$emit('ChangeHeadings', this.title, 'Bitcoin')
		this.status = this.$route.query['bitcoinpay-status']
		if (this.status === 'cancel') {
			this.failureReason = 'Cancelled'
		} else if (this.status === 'false') {
			this.failureReason = 'Error'
		}
	},
	methods: {}
}
</script>
