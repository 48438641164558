import Vue from 'vue'
import Router from 'vue-router'
import menu from './menu'
import financeReturn from './financeReturn'
import config from '../config'
import NotFound from '../views/404.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    { path: '*', name: '404', component: NotFound },
    { path: '/', redirect: '/en/' } ,
    ...multilingualRoutes(menu.initial()),
    ...financeReturn,
  ],
})

function multilingualRoutes(menu, routes = [], parent) {
  for (let i = 0, l = menu.length; i < l; i++) {
    let item = menu[i]
    if (!item.component && item.children.length) {
      multilingualRoutes(item.children, routes, item)
    } else if (item.path) {
      for (let c in config.languages) {
        let multi = Object.create(item)
        let ppath = parent ? parent.path : ''
        multi.path = '/' + c + ppath + item.path
        multi.name = item.name + ' | ' + config.languages[c]
        if (parent) {
          if (parent.meta) {
            let pMetaKeys = Object.keys(parent.meta)
            for (let i = 0, l = pMetaKeys.length; i < l; i++) {
              if (typeof multi.meta[pMetaKeys[i]] === 'undefined') {
                multi.meta[pMetaKeys[i]] = parent.meta[pMetaKeys[i]]
              }
            }
          }
        }
        multi.meta.lang = c
        routes.push(multi)
      }
    }
  }
  return routes
}
