<template>
  <div class="has-text-left">
    <h5 class="text-base text-gray-900 font-semibold mb-2">{{$t("please_enter_amount_withdraw")}}</h5>

    <div class="flex gap-5 mt-7 items-start flex-col md:flex-row">
      <div class="w-full md:w-auto">
        <label
          for="currency"
          class="text-base text-gray-900"
        >{{$t('currency').tTitle()}}</label>
        <div class="mt-2">
          <div class="bg-white py-3 w-full md:w-44 text-gray-900 text-base font-semibold pl-5 rounded-md">{{User.wallet.currency}}</div>
        </div>
      </div>
      <div class="grow w-full md:w-auto">
        <label
          class="text-base text-gray-900 inline-block w-full"
          for="amount"
        >{{$t("request_amount")}}</label>
        <input
          v-validate="'required|currency|min-currency-value:50|multiple-of-10'"
          type="number"
          class="border border-white border-solid mt-2 bg-white rounded-md h-12 px-5 text-gray-900 w-full font-semibold"
          :class="{'input': true, 'is-danger': errors.has('amount') }"
          name="amount"
          value="20"
          v-model="amount"
        />
        <span
          v-show="errors.has('amount')"
          class="text-red text-xs pt-0.5"
          :class="{'has-text-right': Language == 'fa'}"
        >{{ errors.first('amount') }}</span>
      </div>
    </div>

    <div class="mt-7">
      <label
        for="deductedAmount"
        class="text-gray-900 text-base"
      >{{$t("amount_changes")}}</label>
      <div class="flex items-center mt-2 gap-2">
        <p class="control">
          <a class="button is-static">{{currencySymbols[User.wallet.currency].symbol}}</a>
        </p>
        <p class="control">
          <input
            v-validate="'required|currency'"
            readonly
            type="number"
            class="input disabled-custom border-0 rounded-md font-semibold"
            name="deductedAmount"
            value="20"
            v-model="deductedAmount"
          />
        </p>
      </div>
    </div>

    <div class="my-5 md:my-10">
      <p class="control">
        <a
          class="bg-gray-900 text-white text-center text-base font-bold py-3 rounded-md w-52 table cursor-pointer"
          @click="withdraw"
        >

          <span>{{$t("withdraw").tTitle()}}</span>
        </a>
      </p>
    </div>

    <p class="text-gray-900 text-sm mb-3">{{$t('wd_vload_text_1')}}</p>
    <ul style="list-style-type: disc;margin-left: 20px;">
      <li
        class="text-gray-900 text-sm mb-2"
        v-html="$t('wd_vload_text_2')"
      ></li>
      <li class="text-gray-900 text-sm mb-5">{{$t('wd_vload_text_3')}}</li>
    </ul>

    <p
      class="text-gray-900 text-sm mb-2"
      v-html="$t('vload_text_6')"
    ></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import config from '../../../config'
import { service } from '../../../main.js'

export default {
  data() {
    return {
      amount: 0.0,
      feePercentage: 0.0,
      translations: {
        text: {
          amount: this.$t('amount'),
        },
      },
      currencySymbols: config.currencies,
    }
  },
  watch: {
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },
  },
  computed: {
    deductedAmount() {
      return parseInt(this.amount) + (parseInt(this.amount) * this.feePercentage) / 100
    },

    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
    }),
  },
  mounted() {
    this.GetVloadPayoutFeePercentage()
  },
  methods: {
    withdraw() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$bus.$emit('RequestWithdraw', '/finance/withdraw', {
            amount: String(this.amount),
            userID: this.$store.state.user.info.ID,
            psp: 'VLoad',
          })
        }
      })
    },
    GetVloadPayoutFeePercentage() {
      service.get('/finance/get-vload-payout-fee-percentage').then(response => {
        this.feePercentage = parseFloat(response.data)
      })
    },
  },
}
</script>
