<template>
  <div
    class="mt-6 mb-24"
  >

    <div class="flex gap-7 h-auto min-h-[70vh] flex-col lg:flex-row mx-5 md:mx-0 mb-24">
      <div class="w-full lg:w-[37%] bg-white rounded-lg shadow-ts1 order-2 lg:order-1 pb-7 lg:pb-0">
        <div key="images">
          <div class="pb-4 border-b border-solid border-gray-300 mt-5">
            <div>
              <p class="ltr:pl-5 ltr:md:pl-8 rtl:pr-5 rtl:md:pr-8 text-blue font-display font-bold text-xl leading-none">{{$t('deposit_funds').tTitle()}}</p>
            </div>
          </div>
          <p class="mt-7 text-gray-900 font-semibold text-base mb-5 ltr:ml-5 ltr:md:ml-8 rtl:mr-5 rtl:md:mr-8">{{$t('please_select_payment_method')}}</p>
          <div class="hidden lg:flex flex-col gap-2 mx-8 items-center">
            <div
              v-for="(img,name) in activePaymentImages"
              @click="selectPaymentMethod(name)"
              :key="img.PSPName"
              class="w-full rounded-lg border border-solid py-3 group relative hover:border-blue max-w-xs cursor-pointer select-none"
              :class="[selected.paymentMethod==name?'border-green':'border-gray-300']"
            >
              <img
                :src="img.path"
                class="w-40 m-auto h-14"
              />
              <div class="w-full rounded-lg flex items-center justify-center bg-blue bg-opacity-85 absolute inset-0 text-white text-center font-bold text-base transition-all ease-in-out duration-200 opacity-0 group-hover:opacity-100"><span>{{$t(name).tTitle()}}</span></div>
            </div>
          </div>
          <div
            class="mx-5 block lg:hidden"
            id="paymentMethodsCarousel"
          >
            <VueSlickCarousel
              v-bind="carouselSettings"
              class="w-full max-w-[480px]"
            >
              <div
                v-for="(img,name) in activePaymentImages"
                @click="selectPaymentMethod(name)"
                :key="img.PSPName"
              >
                <div
                  class="w-[90%] h-[130px] rounded-lg border border-solid flex items-center justify-center"
                  :class="[selected.paymentMethod==name?'border-green':'border-gray-300']"
                >
                  <img
                    :src="img.mobile_path"
                    class="w-20 m-auto h-full"
                  />
                </div>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
      </div>

      <div class="w-full lg:w-3/4 bg-green bg-opacity-15 rounded-lg shadow-ts1 order-3 lg:order-2 mb-20 lg:mb-0 pb-7 lg:pb-0">
        <div class="pb-4 border-b border-solid border-white mt-5">
          <div>
            <p class="ltr:pl-5 ltr:md:pl-8 rtl:pr-5 rtl:md:pr-8 text-green font-display font-bold text-xl leading-none">{{$t(paymentImages[selected.paymentMethod].Component.tLower())}}</p>
          </div>
        </div>
        <div
          class="mx-5 md:mx-8 mt-7"
          key="component"
        >
          <component
            :selected="selected"
            v-bind:is="paymentImages[selected.paymentMethod].Component"
            :apm="selected.paymentMethod == 'neteller' ? 'neteller' : null"
          ></component>
          <div class="tile is-12 is-parent">
            <!-- <div
              class="tile is-11 is-parent"
              v-for="(img, name) in selectedPaymentMethod"
              :key="name"
            >
              <div class="tile is-3">
                <figure class="image is-100x100">
                  <img :src="img.path" />
                </figure>
              </div>

              <div class="tile is-9">
                <div>
                  <span
                    class="title"
                    :class="{ 'has-text-right': Language == 'fa', 'has-text-left': Language != 'fa' }"
                  >{{ $t(img.T) }}</span>

                  <article class="tile is-child psp">
                    <component
                      :selected="selected"
                      v-bind:is="paymentImages[selected.paymentMethod].Component"
                      :apm="selected.paymentMethod == 'neteller' ? 'neteller' : null"
                    ></component>
                  </article>
                </div>
              </div>
            </div> -->

            <!-- <div class="tile is-1">
              <div class="topNav">
                <a
                  class="button"
                  @click="step--, (selected.paymentMethod = '')"
                >
                  <span class="icon is-small">
                    <i
                      class="fa fa-chevron-circle-left"
                      :class="{ 'ml-10': Language == 'fa' }"
                    ></i>
                  </span>
                  <span>{{ $t('back') }}</span>
                </a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="w-full lg:w-1/4 flex flex-row lg:flex-col gap-4 md:gap-8 order-1 lg:order-3">
        <div class="w-full h-28 md:h-[8.2rem] bg-white rounded-lg shadow-ts1">
          <div class="h-full flex justify-center ltr:pl-5 ltr:md:pl-8 rtl:pr-5 rtl:md:pr-8 flex-col">
            <p class="text-gray-900 text-sm leading-6 font-semibold">{{ $t('wallet_balance').tTitle() }}</p>
            <p class="pt-1 text-blue font-bold text-[1.37rem] md:text-4xl">{{ $formatMoney(walletBalance, currencySymbols[User.wallet.currency]) }}</p>
          </div>
        </div>
        <div class="w-full h-28 md:h-[8.2rem] bg-white rounded-lg shadow-ts1 relative">
          <div class="h-full flex justify-center ltr:pl-5 ltr:md:pl-8 rtl:pr-5 rtl:md:pr-8 flex-col relative">
            <div class="flex justify-between flex-col md:flex-row">
              <p class="text-gray-900 text-sm leading-6 font-semibold">{{ $t('total_deposits').tTitle() }}</p>
              <!-- <div class="absolute top-1 md:top-auto right-0 md:relative">
                <select
                  v-model="selectedCurrency"
                  name="currency"
                  class="w-16 h-5 bg-none bg-transparent text-gray-900 border-0 p-0 rounded-md text-sm md:text-base font-bold appearance-none focus:outline-none focus:ring-0"
                >
                  <option>USD</option>
                  <option>EUR</option>
                </select>
                <div class="select-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div> -->
            </div>
            <p class="pt-1 text-green font-bold text-[1.37rem] md:text-4xl">{{ $formatMoney(totalDeposits, currencySymbols[selectedCurrency]) }}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showMaintenanceModal">
      <div class="fixed flex justify-center items-center inset-0 z-50 bg-gray-900 bg-opacity-70 w-full h-full">
        <div class="bg-white rounded-lg w-11/12 lg:w-[642px] max-w-[642px] m-auto relative max-h-[82%] p-10">
          <button @click="showMaintenanceModal = false" class="bg-white rounded-full h-10 w-10 absolute -top-14 right-0 appearance-none p-0">
            <div class="h-6 w-0.5 bg-blue rotate-45 ml-5"></div>
            <div class="h-6 w-0.5 bg-blue -rotate-45 -mt-6 ml-5"></div>
          </button>
          <p class="text-xl font-bold text-gray-dark mb-4">{{ $t('important_note')}}</p>
          <p v-html="$t('confirmo_maintenance_note')" class="text-base leading-7 text-gray-dark"></p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import RegularPay from './deposit-methods/RegularPay'
// import EpayPro from './deposit-methods/EpayPro'
// import EData from './deposit-methods/EData'
// import Skrill from './deposit-methods/Skrill'
// import Neteller from './deposit-methods/Neteller'
import Bitcoin from './deposit-methods/Bitcoin'
// import KoalaPay from './deposit-methods/KoalaPay'
// import PagoCUP from './deposit-methods/PagoCUP'
// import Fasapay from './deposit-methods/Fasapay'
// import BankWire from './deposit-methods/BankWire'
import VLoad from './deposit-methods/VLoad'
import Lydiax from './deposit-methods/Lydiax'
// import SafeCharge from './deposit-methods/SafeCharge'
// import PowerCash from './deposit-methods/PowerCash'
// import axios from 'axios'
import { service } from '../../main.js'
import { mapActions, mapGetters } from 'vuex'
import api from '../../api'
import config from '../../config'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

// import NewTermsModal from '../../components/NewTermsModal'

const imgPath = require.context('../../assets/img/funding', true)

export default {
  props: ['platformImages'],
  components: {
    VueSlickCarousel,
    // RegularPay,
    // EpayPro,
    // EData,
    // Skrill,
    // Neteller,
    Bitcoin,
    // KoalaPay,
    // PagoCUP,
    // Fasapay,
    // BankWire,
    VLoad,
    Lydiax,
    // SafeCharge,
    // PowerCash,
    // NewTermsModal,
  },
  data() {
    return {
      selected: {
        paymentMethod: 'bitcoin',
      },
      step: 1,
      paymentImages: {},
      walletBalance: 0,
      selectedCurrency: '',
      currencySymbols: config.currencies,
      totalDeposits: 0,
      showMaintenanceModal: false,
      carouselSettings: {
        arrows: false,
        dots: true,
        focusOnSelect: true,
        infinite: false,
        slidesToShow: 2,
        speed: 500,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 430,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 380,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    }
  },
  watch: {
    'selected.paymentMethod'() {
      if (this.selected.paymentMethod) {
        this.step = 2
      }
    },
  },
  computed: {
    activePaymentImages() {
      // return this.paymentImages
      if (Object.values(this.paymentImages).length == 0) {
        return null
      }
      var tempObj = new Object()
      for (const property in this.paymentImages) {
        if (!this.User.wallet.currency) {
          break
        }

        let item = this.paymentImages[property]

        if (!item.BlockedCurrencies && !item.BlockedCountries) {
          tempObj[property] = this.paymentImages[property]
        }
        let PSPCurrencyAllowed = !item.BlockedCurrencies || !item.BlockedCurrencies.length || item.BlockedCurrencies.indexOf(this.User.wallet.currency.toUpperCase()) === -1
        let PSPCountryAllowed = !item.BlockedCountries || !item.BlockedCountries.length || item.BlockedCountries.indexOf(this.User.info.country_code.toUpperCase()) === -1

        if (PSPCurrencyAllowed && PSPCountryAllowed) {
          tempObj[property] = this.paymentImages[property]
        }
      }
      return Object.assign(this.paymentImages, tempObj)
      // return this.paymentImages.filter(img => {
      //   if (!this.User.wallet.currency) {
      //     return false
      //   }

      //   if (!img.BlockedCurrencies && !img.BlockedCountries) {
      //     return true
      //   }
      //   let PSPCurrencyAllowed = !img.BlockedCurrencies || !img.BlockedCurrencies.length || img.BlockedCurrencies.indexOf(this.User.wallet.currency.toUpperCase()) === -1
      //   let PSPCountryAllowed = !img.BlockedCountries || !img.BlockedCountries.length || img.BlockedCountries.indexOf(this.User.info.country_code.toUpperCase()) === -1

      //   return PSPCurrencyAllowed && PSPCountryAllowed
      // })
    },
    selectedPaymentMethod() {
      return this.paymentImages.filter(item => this.selected.paymentMethod === item.name)
      // return this.paymentImages.filter((_, name) => name == this.selected.paymentMethod)
    },
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
      Finance: 'finance/all',
      logoLoaderActive: 'logoLoader/active',
    }),
  },
  mounted() {
    // check for preselected values
    if (this.Finance.deposit.selected.paymentMethod) {
      this.selected.paymentMethod = this.Finance.deposit.selected.paymentMethod
      this['finance/preSelectedDepositValue']({ key: 'paymentMethod', value: null })
    }

    this.$bus.$on('RequestDeposit', this.requestDeposit)
    // update wallet
    api.GetWallet()
    if (!this.User.TA.length) {
      // get trading accounts if needed
      api.GetTradingAccounts()
    }

    // PSPs (handling image loading/path)
    // this.paymentImages = this.$store.state.finance.PSPs
    this.paymentImages = Object.assign({}, this.Finance.PSPs)
    let platNames = Object.keys(this.paymentImages)
    for (let i = 0, l = platNames.length; i < l; i++) {
      this.paymentImages[platNames[i]]['path'] = imgPath('./' + platNames[i] + '.svg', true)
      this.paymentImages[platNames[i]]['mobile_path'] = imgPath('./' + platNames[i] + '-mob.svg', true)
    }

    this.walletBalance = Math.floor(this.User.wallet.balance * 100) / 100
    this.selectedCurrency = this.User.wallet.currency
    this.getTotals()

    // if(this.selected.paymentMethod==='bitcoin'){
    //   this.showMaintenanceModal = true
    // }
  },
  beforeDestroy() {
    this.$bus.$off('RequestDeposit', this.requestDeposit)
  },
  methods: {
    selectPaymentMethod(name) {
      if (name === 'e-data') {
        this.$toasted.info(this.$t('deposit_info'), { duration: 10000 })
        return
      }
      // if (name === 'bankwire') {
      // 	this.$toasted.info('The bank wire transfer option is temporarily unavailable. Please select another deposit method until a new payment service provider is made available for sending wires.<br><br>We will make all efforts to offer you an alternative bank wire transfer solution as soon as possible.<br><br>We apologize for the inconvenience and greatly appreciate your patience and understanding.<br><br>On the other hand, we have now made available, what we feel is a much more viable alternative solution:<br><br>We have now set up a faster way for you to deposit and withdraw through our KoalaPay Payment Service Provider, without the hassle of the account set up process, as we can take care of setting up the account on your behalf.<br><br>Please send an email to backoffice@plexytrade.com for more details.', { duration: 100000 })
      // 	return
      // }

      // if(name==='bitcoin'){
      //   this.showMaintenanceModal = true
      // }
      this.selected.paymentMethod = name
    },
    requestDeposit(url, data, successcb, failedcb) {
      this['logoLoader/set'](true)
      service
        .post(url, data)
        .then(response => {
          if (!response.data.success) {
            this['logoLoader/set'](false)
          }
          successcb(response)
        })
        .catch(error => {
          this['logoLoader/set'](false)
          failedcb(error)
        })
    },
    getTotals() {
      service.get('/finance/user/' + this.User.info.ID + '/totals-converted', {}).then(response => {
        if (response.data.total_deposits) {
          for (let i = 0; i < response.data.total_deposits.length; i++) {
            if (response.data.total_deposits[i].currency == this.User.wallet.currency) {
              this.totalDeposits = response.data.total_deposits[i].value
              break
            }
          }
        }
      })
    },
    ...mapActions(['logoLoader/set', 'finance/preSelectedDepositValue']),
  },
}
</script>
<style>
#paymentMethodsCarousel .slick-dots {
  text-align: left;
}
</style>
