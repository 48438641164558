<template>
  <div>
    <sub-menu
      :title="title"
      :routes="routes"
    ></sub-menu>

    <router-view class="mt-6"></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SubMenu from '@/components/SubMenu.vue'

export default {
  data() {
    return {
      title: this.$t('my_settings'),
      routes: this.getRoutes()
    }
  },
  components: {
    SubMenu,
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
    }),
  },
  watch: {
    Language() {
      this.updateRoutesAndTitle()
    }
  },
  mounted() {
    if (!this.User.isDemo) {
      this.routes.push({ path: '/profile/upload-documents', title: this.$t('upload_documents').tTitle() })
      this.routes.push({ path: '/profile/guidance-documents', title: this.$t('guidance_documents').tTitle() })
    }
  },
  methods: {
    getRoutes() {
      const routes = [
        { path: '/profile', title: this.$t('personal_details').tTitle() },
      ]
      if (this.User && !this.User.isDemo) {
        routes.push({ path: '/profile/upload-documents', title: this.$t('upload_documents').tTitle() })
        routes.push({ path: '/profile/guidance-documents', title: this.$t('guidance_documents').tTitle() })

      }
      return routes
    },
    updateRoutesAndTitle() {
      this.title = this.$t('my_settings')
      this.routes = this.getRoutes()
    }
  }
}
</script>
