<template>
  <div class="pb-20">
    <h1 class="font-display text-gray-900 font-bold text-basicTitle pb-9 lg:pb-5 ltr:ml-7 ltr:lg:ml-0 rtl:mr-7 rtl:lg:mr-0">{{ $t('dashboard').tTitle() }}</h1>
    <div id="boxes" class="flex w-[98%] lg:w-full gap-8 pb-2.5 mb-6 overflow-hidden overflow-x-scroll ltr:ml-2 ltr:lg:ml-0 rtl:mr-2 rtl:lg:mr-0">
      <div class="w-[220px] lg:w-1/3 lg:grow h-auto lg:h-[8.2rem] bg-white rounded-lg shadow-ts1 group flex-shrink-0 lg:flex-shrink">
        <div class="h-full flex flex-col lg:flex-row justify-center lg:justify-start p-5 lg:p-8 relative gap-3 lg:gap-6">
          <div class="h-[70px] w-[70px] rounded-full bg-blue bg-opacity-25 shrink-0 flex justify-center items-center">
            <img class="h-8 w-8" src="../assets/img/internal-transfer-quickmenu.svg" />
          </div>
          <div class="grow flex justify-between">
            <div>
              <p class="text-gray-900 text-sm leading-6 font-medium">{{ $t('wallet_balance').tTitle() }}</p>
              <p class="pt-1 text-blue font-bold text-4xl">{{ $formatMoney(walletBalance, currencySymbols[User.wallet.currency]) }}</p>
            </div>
            <img
              class="h-5 w-5 absolute lg:relative top-5 lg:top-auto ltr:right-5 ltr:lg:right-auto rtl:left-5 rtl:lg:left-auto"
              src="../assets/img/internal-transfer-quickmenu.svg"
            />
          </div>

          <div
            class="h-full w-full bg-blue absolute inset-0 flex justify-center items-center rounded-lg bg-opacity-90 opacity-0 transition-all duration-300 ease-in-out group-hover:opacity-100"
          >
            <router-link
              :to="'/' + Language + '/finance/internal-transfer'"
              class="border border-white border-solid bg-blue appearance-none text-white rounded-md text-center px-2 min-w-[160px] h-12 pt-3 font-bold text-base m-auto table"
              >{{ $t('internal_transfer').tTitle() }}</router-link
            >
          </div>
          <!-- <div class="pt-1.5 flex items-center">
                <div>
                  <img
                    v-if="walletBalance>=1000000"
                    class="h-4 w-4"
                    src="../assets/img/up.svg"
                  />
                  <img
                    v-else
                    class="h-4 w-4"
                    src="../assets/img/down.svg"
                  />
                </div>
                <p class="text-gray-600 text-[0.7rem] ml-2"><span
                    :class="[walletBalance>=1000000 ? 'text-green' : 'text-gray-900']"
                    class="pr-1"
                  >{{ $formatMoney(walletBalance, currencySymbols[User.wallet.currency]) }}</span> {{$t('last_five_days')}}</p>
              </div> -->
        </div>
      </div>
      <div class="w-[220px] lg:w-1/3 lg:grow h-auto lg:h-[8.2rem] bg-white rounded-lg shadow-ts1 relative group flex-shrink-0 lg:flex-shrink">
        <div class="h-full flex flex-col lg:flex-row justify-center lg:justify-start p-5 lg:p-8 relative gap-3 lg:gap-6">
          <div class="h-[70px] w-[70px] rounded-full bg-green bg-opacity-25 shrink-0 flex justify-center items-center">
            <img class="h-8 w-8" src="../assets/img/deposit.svg" />
          </div>
          <div class="grow flex justify-between">
            <div>
              <p class="text-gray-900 text-sm leading-6 font-medium">{{ $t('deposits') }}</p>
              <p class="pt-1 text-green font-bold text-4xl">{{ $formatMoney(totalDeposits, currencySymbols[User.wallet.currency]) }}</p>
            </div>
            <img class="h-5 w-5 absolute lg:relative top-5 lg:top-auto ltr:right-5 ltr:lg:right-auto rtl:left-5 rtl:lg:left-auto" src="../assets/img/deposit.svg" />
          </div>
        </div>
        <div
          class="h-full w-full bg-green absolute inset-0 flex justify-center items-center rounded-lg bg-opacity-90 opacity-0 transition-all duration-300 ease-in-out group-hover:opacity-100"
        >
          <router-link
            :to="'/' + Language + '/finance'"
            class="border border-white border-solid bg-green appearance-none text-white rounded-md text-center w-40 h-12 pt-3 font-bold text-base m-auto table"
            >{{ $t('deposit').tTitle() }}</router-link
          >
        </div>
      </div>
      <div class="w-[220px] lg:w-1/3 lg:grow h-auto lg:h-[8.2rem] bg-white rounded-lg shadow-ts1 relative group flex-shrink-0 lg:flex-shrink">
        <div class="h-full flex flex-col lg:flex-row justify-center lg:justify-start p-5 lg:p-8 relative gap-3 lg:gap-6">
          <div class="h-[70px] w-[70px] rounded-full bg-gray-900 bg-opacity-25 shrink-0 flex justify-center items-center">
            <img class="h-8 w-8" src="../assets/img/withdraw.svg" />
          </div>
          <div class="grow flex justify-between">
            <div>
              <p class="text-gray-900 text-sm leading-6 font-medium">{{ $t('withdrawals').tTitle() }}</p>
              <p class="pt-1 text-gray-900 font-bold text-4xl">{{ $formatMoney(totalWithdrawals, currencySymbols[User.wallet.currency]) }}</p>
            </div>
            <img class="h-5 w-5 absolute lg:relative top-5 lg:top-auto ltr:right-5 ltr:lg:right-auto rtl:left-5 rtl:lg:left-auto" src="../assets/img/withdraw.svg" />
          </div>
        </div>
        <div
          class="h-full w-full bg-gray-900 absolute inset-0 flex justify-center items-center rounded-lg bg-opacity-90 opacity-0 transition-all duration-300 ease-in-out group-hover:opacity-100"
        >
          <router-link
            :to="'/' + Language + '/finance/withdraw'"
            class="border border-white border-solid bg-gray-900 appearance-none text-white rounded-md text-center w-40 h-12 pt-3 font-bold text-base m-auto table"
            >{{ $t('withdrawal').tTitle() }}</router-link
          >
        </div>
      </div>
    </div>
    <div class="flex justify-center items-start flex-col lg:flex-row gap-8">
      <div class="w-full lg:w-[calc(66.66%+22px)] overflow-hidden">
        <div class="bg-white rounded-lg shadow-ts1 pt-4 pb-3 block lg:hidden mx-2 mb-7">
          <div class="pb-4 border-b border-solid border-gray-400">
            <div>
              <p class="ltr:pl-5 rtl:pr-5 text-gray-900 font-display font-bold text-xl leading-none">{{ $t('quick_links') }}</p>
            </div>
          </div>
          <div class="w-auto overflow-hidden pt-5" id="carousel1">
            <VueSlickCarousel v-bind="carouselSettings" @init="onInitCarousel" @reInit="onInitCarousel" class="w-[91vw] md:w-[71vw]">
              <div class="pb-3">
                <p class="item-title text-gray-900 text-xs text-center mb-3 font-medium">{{ $t('internal_transfer').tTitle() }}</p>
                <div class="item-icon h-[70px] w-[70px] bg-white rounded-xl shadow-ts3 m-auto mt-5">
                  <img class="w-full" src="../assets/img/internal-transfer.svg" />
                </div>

                <router-link :to="'/' + Language + '/finance/internal-transfer'" class="active-icon h-[82px] w-[82px] bg-gray-900 rounded-xl shadow-ts3 m-auto hidden">
                  <img class="w-full" src="../assets/img/internal-transfer-b.svg" />
                </router-link>
              </div>
              <div class="pb-3">
                <p class="item-title text-gray-900 text-sm text-center mb-3 font-medium">{{ $t('account_list').tTitle() }}</p>
                <div class="item-icon h-[70px] w-[70px] bg-white rounded-xl shadow-ts3 m-auto mt-5">
                  <img class="w-full" src="../assets/img/account-list.svg" />
                </div>
                <router-link :to="'/' + Language + '/trading-account'" class="active-icon h-[82px] w-[82px] bg-gray-900 rounded-xl shadow-ts3 m-auto hidden">
                  <img class="w-full" src="../assets/img/account-list-b.svg" />
                </router-link>
              </div>
              <!-- <div class="pb-3">
                <p class="item-title text-gray-900 text-sm text-center mb-3 font-medium">{{ $t('video_courses') }}</p>
                <div class="item-icon h-[70px] w-[70px] bg-white rounded-xl shadow-ts3 m-auto mt-5">
                  <img class="w-full" src="../assets/img/video-courses.svg" />
                </div>
                <div class="active-icon h-[82px] w-[82px] bg-gray-900 rounded-xl shadow-ts3 m-auto hidden">
                  <img class="w-full" src="../assets/img/video-courses-b.svg" />
                </div>
              </div> -->
              <div class="pb-3">
                <p class="item-title text-gray-900 text-sm text-center mb-3 font-medium">{{ $t('transactions') }}</p>
                <div class="item-icon h-[70px] w-[70px] bg-white rounded-xl shadow-ts3 m-auto mt-5">
                  <img class="w-full" src="../assets/img/transactions-icon.svg" />
                </div>
                <router-link :to="'/' + Language + '/finance/payment-history'" class="active-icon h-[82px] w-[82px] bg-gray-900 rounded-xl shadow-ts3 m-auto hidden">
                  <img class="w-full" src="../assets/img/transactions-icon-b.svg" />
                </router-link>
              </div>
              <div class="pb-3">
                <p class="item-title text-gray-900 text-sm text-center mb-3 font-medium">{{ $t('settings') }}</p>
                <div class="item-icon h-[70px] w-[70px] bg-white rounded-xl shadow-ts3 m-auto mt-5">
                  <img class="w-full" src="../assets/img/my-settings-icon.svg" />
                </div>
                <router-link :to="'/' + Language + '/profile'" class="active-icon h-[82px] w-[82px] bg-gray-900 rounded-xl shadow-ts3 m-auto hidden">
                  <img class="w-full" src="../assets/img/my-settings-icon-b.svg" />
                </router-link>
              </div>
            </VueSlickCarousel>
          </div>
        </div>

        <div class="w-auto lg:w-full bg-white rounded-lg shadow-ts1 pt-5 mb-7 mx-2 lg:mx-0">
          <div class="w-full pb-4 border-b border-gray-400 border-solid">
            <div class="w-full flex justify-between items-start">
              <h2 class="ltr:pl-5 ltr:md:pl-8 rtl:pr-5 rtl:md:pr-8 text-gray-900 font-display font-bold text-xl leading-none">{{ $t('account_list').tTitle() }}</h2>
              <div class="md:hidden ltr:pr-5 rtl:pl-5">
                <router-link :to="'/' + Language + '/trading-account'" class="font-semibold text-gray-900 text-sm">{{ $t('see_more') }}</router-link>
              </div>
            </div>
          </div>
          <div class="flex mt-6 pb-8 px-5 lg:px-8">
            <div class="w-1/2 lg:w-40">
              <div class="w-36 inline-block relative">
                <select
                  v-model="selectedPlatform"
                  name="platform"
                  class="rtl:bg-left bg-none py-3 bg-white text-blue border border-gray-300 border-solid focus:border focus:border-gray-300 focus:border-solid focus:outline-none focus:ring-0 cursor-pointer"
                >
                  <option value="all">{{ $t('platform').tTitle() }}</option>
                  <option value="mt4">MT4</option>
                  <option value="mt5">MT5</option>
                  <option value="pamm">PAMM</option>
                </select>
                <div class="select-arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="w-full hidden lg:flex">
              <div class="w-10/12 max-w-[400px] px-5 lg:px-0 m-auto flex bg-gray-400 rounded-3xl justify-center items-center">
                <div
                  @click="selectedAccountType = 'all'"
                  :class="[selectedAccountType == 'all' ? 'text-white bg-blue font-bold' : 'text-gray-900']"
                  class="w-1/3 text-center text-base rounded-3xl h-full py-2 pt-2.5 cursor-pointer"
                >
                  {{ $t('all') }}
                </div>
                <div
                  @click="selectedAccountType = 'live'"
                  :class="[selectedAccountType == 'live' ? 'text-white bg-blue font-bold' : 'text-gray-900']"
                  class="w-1/3 text-center text-base rounded-3xl h-full py-2 pt-2.5 cursor-pointer"
                >
                  {{ $t('live') }}
                </div>
                <div
                  @click="selectedAccountType = 'demo'"
                  :class="[selectedAccountType == 'demo' ? 'text-white bg-blue font-bold' : 'text-gray-900']"
                  class="w-1/3 text-center text-base rounded-3xl h-full py-2 pt-2.5 cursor-pointer"
                >
                  {{ $t('demo') }}
                </div>
              </div>
            </div>
            <div class="w-1/2 lg:w-1/3">
              <div
                @click="showCAModal()"
                class="py-3 h-full bg-blue rounded-md text-white appearance-none font-bold text-base text-center w-40 ltr:float-right rtl:float-left cursor-pointer"
              >
                {{ $t('create_account') }}
              </div>
            </div>
          </div>

          <div class="w-full max-w-sm mb-6 table m-auto lg:hidden">
            <div class="w-auto lg:w-80 mx-5 lg:mx-0 m-auto flex bg-gray-400 rounded-3xl justify-center items-center">
              <div
                @click="selectedAccountType = 'all'"
                :class="[selectedAccountType == 'all' ? 'text-white bg-blue font-bold' : 'text-gray-900']"
                class="w-1/3 text-center text-base rounded-3xl h-full py-2 pt-2.5 cursor-pointer"
              >
                {{ $t('all') }}
              </div>
              <div
                @click="selectedAccountType = 'live'"
                :class="[selectedAccountType == 'live' ? 'text-white bg-blue font-bold' : 'text-gray-900']"
                class="w-1/3 text-center text-base rounded-3xl h-full py-2 pt-2.5 cursor-pointer"
              >
                {{ $t('live') }}
              </div>
              <div
                @click="selectedAccountType = 'demo'"
                :class="[selectedAccountType == 'demo' ? 'text-white bg-blue font-bold' : 'text-gray-900']"
                class="w-1/3 text-center text-base rounded-3xl h-full py-2 pt-2.5 cursor-pointer"
              >
                {{ $t('demo') }}
              </div>
            </div>
          </div>

          <div class="w-11/12 lg:w-calc1 overflow-x-scroll lg:mx-8 m-auto" id="taContainer">
            <div class="flex ltr:lg:pr-8 rtl:lg:pl-8 pb-3.5 space-x-5 rtl:space-x-reverse  pt-2.5">
              <div v-for="item in filteredTradingAccounts" :key="item.external_id" :id="item.external_id" class="w-80 flex-shrink-0 rounded-lg border border-gray-300 border-solid">
                <div class="p-4 relative noUserSelect" :class="[item.account_type == '' || item.account_type.toLowerCase() == 'archived' ? 'opacity-60' : '']">
                  <div
                    v-if="item.account_type.toLowerCase().includes('slave of')"
                    class="absolute -top-2.5 right-4 h-5 px-2.5 flex justify-center items-center bg-[#F0F3FF] border border-[#D3D3D3] border-solid rounded-xl text-[9px] text-gray-900 font-medium">
                      {{item.account_type.toLowerCase().replace('slave of',$t('attached_to'))}}
                  </div>
                  <div class="flex mb-4">
                    <img v-if="item.type == 'demo'" class="h-[70px] w-[70px]" src="../assets/img/demo.svg" />
                    <img v-else-if="item.account_type_identifier == 'silver'" class="h-[70px] w-[70px]" src="../assets/img/silver.svg" />
                    <img
                      v-else-if="item.account_type_identifier == 'gold-ecn' || item.account_type_identifier == 'gold-raw'"
                      class="h-[70px] w-[70px]"
                      src="../assets/img/gold.svg"
                    />
                    <img
                      v-else-if="item.account_type_identifier=='silver-islamic'"
                      class="h-[70px] w-[70px]"
                      src="../assets/img/silver-islamic.svg"
                    />
                    <img
                      v-else-if="item.account_type_identifier=='gold-raw-is'"
                      class="h-[70px] w-[70px]"
                      src="../assets/img/islamic.svg"
                    />
                    <img
                      v-else-if="item.account_type_identifier=='platinum-vip'"
                      class="h-[70px] w-[70px]"
                      src="../assets/img/platinum.svg"
                    />
                    <img
                      v-else-if="item.account_type_identifier=='micro'"
                      class="h-[70px] w-[70px]"
                      src="../assets/img/micro.svg"
                    />
                    <img
                      v-else-if="item.account_type_identifier=='pamm-investor'"
                      class="h-[70px] w-[70px]"
                      src="../assets/img/pamm-investor.svg"
                    />
                    <img
                      v-else-if="item.account_type_identifier=='pamm-master'"
                      class="h-[70px] w-[70px]"
                      src="../assets/img/pamm-investor.svg"
                    />
                    <img
                      v-else-if="item.account_type_identifier=='no-deposit-bonus'"
                      class="h-[70px] w-[70px]"
                      src="../assets/img/no-deposit-bonus.svg"
                    />
                    <div class="w-full">
                      <div class="flex justify-between items-center">
                        <div
                          :class="[item.type == 'live' ? 'bg-green' : 'bg-gray-900']"
                          class="h-5 text-white text-xs font-semibold rounded-xl px-5 ltr:ml-4 rtl:mr-4 pt-0.5 inline-block"
                        >
                          {{ item.type.tTitle() }}
                        </div>
                        <div
                          v-if="item.account_type != '' && item.account_type.toLowerCase() != 'archived'"
                          @click="showActionsTooltip(item.external_id)"
                          class="tooltip-icon h-7 w-7 bg-white hover:bg-gray-400 text-center rounded-full transition-all duration-300 ease-in-out text-2xl text-gray-900 leading-4 cursor-pointer"
                        >
                          ...
                        </div>
                      </div>
                      <p class="text-gray-900 font-bold text-xl ltr:ml-4 rtl:mr-4">{{ item.external_id }}</p>
                      <p class="text-gray-900 text-xs ltr:ml-4 rtl:mr-4 font-medium">
                        {{
                          item.account_type == ''
                            ? $t('archived')
                            : item.account_type_identifier == 'silver-islamic'
                            ? 'Islamic'
                            : item.account_type_identifier == 'pamm-master'
                            ? 'Master'
                            : item.account_type_identifier == 'pamm-investor'
                            ? 'Investor'
                            : item.account_type
                        }}
                        / {{ item.platform == 'mt4' ? 'MetaTrader 4' : item.platform == 'mt5' ? 'MetaTrader 5' : 'PAMM' }}
                      </p>
                    </div>
                  </div>
                  <div class="w-full rounded-lg bg-blue bg-opacity-15 px-4 py-1.5 mb-3.5">
                    <p class="text-gray-900 text-sm leading-6 font-medium">{{ $t('account_balance').tTitle() }}</p>
                    <p class="pt-1 text-blue font-bold text-4xl">{{ $formatMoney(item.balance, currencySymbols[item.currency]) }}</p>
                  </div>
                  <div class="w-full flex space-x-3.5 rtl:space-x-reverse">
                    <div class="w-1/2 rounded-lg bg-blue bg-opacity-15">
                      <p class="text-gray-900 text-sm leading-4 font-medium pt-1 ltr:pl-4 rtl:pr-4">{{ $t('equity').tTitle() }}</p>
                      <p class="text-blue font-semibold text-base ltr:pl-4 rtl:pr-4 pb-1 leading-6">{{ $formatMoney(item.equity, currencySymbols[item.currency]) }}</p>
                    </div>
                    <div class="w-1/2 rounded-lg bg-blue bg-opacity-15">
                      <p class="text-gray-900 text-sm leading-4 font-medium pt-1 ltr:pl-4 rtl:pr-4">{{ $t('leverage').tTitle() }}</p>
                      <p class="text-blue font-semibold text-base ltr:pl-4 rtl:pr-4 pb-1 leading-6">1:{{ item.leverage }}</p>
                    </div>
                  </div>
                  <div :class="[item.accept_bonus == 'no' ? 'bg-[#e0e1e4]' : 'bg-[#def4ec]']" class="w-full rounded-lg flex gap-1 px-4 py-2 mt-3.5">
                    <p class="text-gray-900 text-sm font-semibold">{{ $t('bonus_120') }}:</p>
                    <p class="text-gray-900 font-medium text-sm">{{ item.accept_bonus == 'no' ? $t('no_bonus') : getTABonus(item.external_id) }}</p>
                  </div>
                  <div class="w-full mt-3.5">
                    <button
                      v-if="item.transfer_locked == 'yes' && item.transfer_account_external_id!=''"
                      class="h-[30px] w-full  rounded-md flex justify-center items-center text-white font-bold text-sm"
                      :class="[item.account_type=='' || item.account_type.toLowerCase()=='archived'?'bg-gray-900 bg-opacity-25 pointer-events-none':'bg-green']"
                      @click="openTransferBackModal(item.external_id, item.equity)"
                    >{{ $t('transfer').tTitle() }}</button>
                   <router-link
                      v-else
                      :to="'/' + Language + '/finance'"
                      :class="[item.account_type=='' || item.account_type.toLowerCase()=='archived'?'bg-gray-900 bg-opacity-25 pointer-events-none':'bg-green']"
                      class="h-[30px] w-full  rounded-md flex justify-center items-center text-white font-bold text-sm">
                      {{ $t('deposit').tTitle() }}
                    </router-link>
                 </div>
                  <div v-if="item.account_type!='' && item.account_type.toLowerCase()!='archived'" class="actions-tooltip absolute top-[3.4rem] right-6 bg-white border border-gray-300 border-solid shadow-ts1 rounded-lg px-3 py-5 w-[215px] hidden">
                    <a
                      v-if="item.account_type_identifier != 'pamm-investor'"
                      @click=";(changeTAPassword.TAID = item.external_id), (changeTAPassword.modalActive = true)"
                      class="w-full hover:bg-blue rounded-md text-gray-900 hover:text-white text-center appearance-none py-2 text-base leading-6 mb-2 table cursor-pointer"
                      >{{ $t('change_password').tTitle() }}</a
                    >
                    <router-link
                      v-if="item.account_type_identifier != 'pamm-investor'"
                      :to="'/' + Language + '/trading-account/download'"
                      class="w-full hover:bg-blue rounded-md text-gray-900 hover:text-white text-center appearance-none py-2 text-base leading-6 mb-2 table"
                      >{{ $t('download').tTitle() }}</router-link
                    >
                    <a
                      v-if="item.platform == 'mt4' || item.platform == 'mt5'"
                      :href="[item.platform == 'mt4' ? mt4WebTraderLinks : mt5WebTraderLinks]"
                      target="_blank"
                      class="w-full hover:bg-blue rounded-md text-gray-900 hover:text-white text-center appearance-none py-2 text-base leading-6 mb-2 table"
                      >{{ $t('launch_webtrader').tTitle() }}</a
                    >
                    <button
                      @click="$refs.viewTradesModalDashboard.openModal(item.external_id, item.platform, item.type)"
                      class="w-full hover:bg-blue rounded-md text-gray-900 hover:text-white text-center appearance-none py-2 text-base leading-6 table cursor-pointer"
                    >
                      {{ $t('view_trades').tTitle() }}
                    </button>
                    <!-- <a
                      v-if="item.account_type_identifier == 'pamm-investor' || item.account_type_identifier == 'pamm-master'"
                      href="https://pamm.plexytrade.com/"
                      target="_blank"
                      class="w-full hover:bg-blue rounded-md text-gray-900 hover:text-white text-center appearance-none py-2 text-base leading-6 mt-2 table"
                      >{{ $t('pamm_dashboard').tTitle() }}</a
                    > -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-11/12 lg:w-auto m-auto flex ltr:lg:ml-8 rtl:lg:mr-8 mt-4 pb-3">
            <div class="w-1/2 flex space-x-2 rtl:space-x-reverse">
              <div @click="scrollLeft()" class="cursor-pointer">
                <img class="h-6 w-6" src="../assets/img/arrow-previous.svg" />
              </div>
              <div @click="scrollRight()" class="cursor-pointer">
                <img class="h-6 w-6" src="../assets/img/arrow-next.svg" />
              </div>
            </div>
            <div class="w-1/2 ltr:text-right rtl:text-left ltr:md:pr-0 ltr:lg:pr-8 rtl:md:pl-0 rtl:lg:pl-8 hidden md:block">
              <router-link :to="'/' + Language + '/trading-account'" class="font-semibold text-gray-900 text-sm">{{ $t('see_more') }}</router-link>
            </div>
          </div>
        </div>

        <div v-if="showPlatforms" class="w-auto bg-white rounded-lg shadow-ts1 pt-5 pb-7 mb-7 mx-2 block lg:hidden">
          <div class="flex justify-between items-center pb-4 border-b border-solid border-gray-300">
            <div>
              <p class="ltr:pl-5 rtl:pr-5 text-blue font-display font-bold text-xl">{{ $t('platforms') }}</p>
            </div>

            <router-link :to="'/' + Language + '/trading-account/download'" class="ltr:pr-5 ltr:lg:pr-8 rtl:pl-5 rtl:lg:pl-8 font-semibold text-gray-900 text-sm">{{
              $t('learn_more')
            }}</router-link>
          </div>
          <div class="relative overflow-hidden">
            <div id="step1Mobile" class="transition-all duration-300 ease-in-out">
              <p class="text-gray-900 text-base pb-6 ltr:pl-5 rtl:pr-5 pt-5">{{ $t('select_operating_system') }}</p>
              <div class="ltr:ml-5 rtl:mr-5 flex spacing-x-2">
                <div class="w-[108px] cursor-default" @click="gotoStep2('windows')">
                  <div class="py-4 px-8 mb-3 bg-white rounded-lg border border-gray-300 relative group ltr:mr-2 rtl:ml-2">
                    <img class="w-full" src="../assets/img/windows.svg" />
                    <div class="w-full bg-blue bg-opacity-85 absolute inset-0 text-center rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                      <div class="table w-full h-full text-center">
                        <p class="text-white font-bold text-xs pt-7">Windows</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-[108px] cursor-default" @click="gotoStep2('android')">
                  <div class="py-4 px-8 mb-3 bg-white rounded-lg border border-gray-300 relative group ltr:mr-2 rtl:ml-2">
                    <img class="w-full" src="../assets/img/android.svg" />
                    <div class="w-full bg-blue bg-opacity-85 absolute inset-0 text-center rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                      <div class="table w-full h-full text-center">
                        <p class="text-white font-bold text-xs pt-7">Android</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-[100px] cursor-default" @click="gotoStep2('ios')">
                  <div class="py-4 px-8 mb-3 bg-white rounded-lg border border-gray-300 relative group">
                    <img class="w-full" src="../assets/img/apple.svg" />
                    <div class="w-full bg-blue bg-opacity-85 absolute inset-0 text-center rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                      <div class="table w-full h-full text-center">
                        <p class="text-white font-bold text-xs pt-7">iOS</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="step2Mobile" class="absolute w-full ltr:left-full rtl:right-full top-0 transition-all duration-300 ease-in-out">
              <p class="text-gray-900 text-base pb-6 ltr:pl-5 rtl:pr-5 pt-5">{{ $t('select_platform') }}</p>
              <div class="ltr:ml-5 rtl:mr-5 flex spacing-x-2.5 relative">
                <div @click="gotoStep1()" class="absolute ltr:-left-3 rtl:-right-3 top-1/3 z-10 cursor-pointer">
                  <img src="/static/img/arrow.svg" class="w-2 rotate-180" />
                </div>
                <div class="w-[108px] cursor-default" @click="downloadPlatform('mt4')">
                  <div class="py-4 px-8 mb-3 bg-white rounded-lg border border-gray-300 relative group ltr:mr-2 rtl:ml-2">
                    <img class="w-full" src="../assets/img/mt4.svg" />
                    <div class="w-full bg-blue bg-opacity-85 absolute inset-0 text-center rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                      <div class="table w-full h-full text-center">
                        <p class="text-white font-bold text-xs pt-7">MetaTrader 4</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-[108px] cursor-default" @click="downloadPlatform('mt5')">
                  <div class="py-4 px-8 mb-3 bg-white rounded-lg border border-gray-300 relative group ltr:mr-2 rtl:ml-2">
                    <img class="w-full" src="../assets/img/mt5.svg" />
                    <div class="w-full bg-blue bg-opacity-85 absolute inset-0 text-center rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                      <div class="table w-full h-full text-center">
                        <p class="text-white font-bold text-xs pt-7">MetaTrader 5</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-[100px] cursor-default">
                  <div class="py-4 px-8 mb-3 bg-white rounded-lg"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="ltr:ml-5 rtl:mr-5 flex space-x-1 rtl:space-x-reverse mb-6">
            <div @click="gotoStep1()" :class="[showDownloadStep1 ? 'bg-blue w-5' : 'w-2.5 bg-gray-300']" class="h-0.5 rounded-xl cursor-pointer"></div>
            <div :class="[!showDownloadStep1 ? 'bg-blue w-5' : 'w-2.5 bg-gray-300']" class="h-0.5 rounded-xl"></div>
          </div>
          <a id="downloadLinkMobile" target="_blank" class="bg-gray-300 text-center text-white rounded-md py-3 w-40 text-base font-bold table ltr:ml-5 rtl:mr-5">{{
            $t('download').tTitle()
          }}</a>
        </div>

        <div
          class="w-auto lg:w-full bg-white rounded-lg shadow-ts1 mx-2 lg:mx-0 px-5 lg:px-8 pt-8 pb-8 md:pb-6 flex justify-center md:justify-between items-center flex-col lg:flex-row"
        >
          <div class="w-full md:w-[85%] break-words lg:w-auto">
            <p class="font-display font-bold text-[2.5rem] leading-none text-blue text-center ltr:lg:text-left rtl:lg:text-right hidden md:block">
              <span class="text-green">120%</span> {{ $t('welcome_bonus') }}
            </p>
            <p v-html="$t('welcome_bonus_breakline')" class="font-display font-bold text-[2.76rem] leading-[47px] text-blue text-center block md:hidden"></p>
          </div>
          <div>
            <a
              :href="promotionWelcomeBonusURL"
              target="_blank"
              class="w-40 bg-green text-center text-white text-base font-bold rounded-md py-4 mt-11 md:mt-5 lg:mt-0 table lg:block"
              >{{ $t('learn_more') }}</a
            >
          </div>
        </div>
      </div>
      <div class="w-full lg:w-[calc(33.33%-22px)]">
        <div class="bg-white rounded-lg shadow-ts1 hidden lg:block pt-5">
          <div class="pb-4 border-b border-solid border-gray-400">
            <div>
              <p class="ltr:pl-7 rtl:pr-7 text-gray-900 font-display font-bold text-xl leading-none">{{ $t('quick_links') }}</p>
            </div>
          </div>
          <div class="w-auto pt-5 pb-3 mb-7" id="carousel2">
            <VueSlickCarousel v-bind="carouselSettings" @init="onInitCarousel2" @reInit="onInitCarousel2"  class="w-[95vw] md:w-full">
              <div class="pb-3 relative">
                <p :class="[Language == 'es' ? 'w-[123px]' : 'w-full']" class="item-title text-gray-900 text-xs text-center mb-3 font-medium absolute">
                  {{ $t('internal_transfer').tTitle() }}
                </p>
                <div class="item-icon h-[4.4rem] w-[4.4rem] bg-white rounded-xl shadow-ts3 m-auto mt-10">
                  <img class="w-full" src="../assets/img/internal-transfer.svg" />
                </div>

                <router-link :to="'/' + Language + '/finance/internal-transfer'" class="active-icon h-20 w-20 bg-gray-900 rounded-xl shadow-ts3 m-auto hidden mt-8">
                  <img class="w-full" src="../assets/img/internal-transfer-b.svg" />
                </router-link>
              </div>
              <div class="pb-3 relative">
                <p class="item-title w-full text-gray-900 text-sm text-center mb-3 font-medium absolute">{{ $t('account_list').tTitle() }}</p>
                <div class="item-icon h-[4.4rem] w-[4.4rem] bg-white rounded-xl shadow-ts3 m-auto mt-10">
                  <img class="w-full" src="../assets/img/account-list.svg" />
                </div>
                <router-link :to="'/' + Language + '/trading-account'" class="active-icon h-20 w-20 bg-gray-900 rounded-xl shadow-ts3 m-auto hidden mt-8">
                  <img class="w-full" src="../assets/img/account-list-b.svg" />
                </router-link>
              </div>
              <!-- <div class="pb-3 relative">
                <p class="item-title w-full text-gray-900 text-sm text-center mb-3 font-medium absolute">{{ $t('video_courses') }}</p>
                <div class="item-icon h-[4.4rem] w-[4.4rem] bg-white rounded-xl shadow-ts3 m-auto mt-10">
                  <img class="w-full" src="../assets/img/video-courses.svg" />
                </div>
                <div class="active-icon h-20 w-20 bg-gray-900 rounded-xl shadow-ts3 m-auto hidden mt-8">
                  <img class="w-full" src="../assets/img/video-courses-b.svg" />
                </div>
              </div> -->
              <div class="pb-3 relative">
                <p class="item-title w-full text-gray-900 text-sm text-center mb-3 font-medium absolute">{{ $t('transactions') }}</p>
                <div class="item-icon h-[4.4rem] w-[4.4rem] bg-white rounded-xl shadow-ts3 m-auto mt-10">
                  <img class="w-full" src="../assets/img/transactions-icon.svg" />
                </div>
                <router-link :to="'/' + Language + '/finance/payment-history'" class="active-icon h-20 w-20 bg-gray-900 rounded-xl shadow-ts3 m-auto hidden mt-8">
                  <img class="w-full" src="../assets/img/transactions-icon-b.svg" />
                </router-link>
              </div>
              <div class="pb-3 relative">
                <p class="item-title w-full text-gray-900 text-sm text-center mb-3 font-medium absolute">{{ $t('settings') }}</p>
                <div class="item-icon h-[4.4rem] w-[4.4rem] bg-white rounded-xl shadow-ts3 m-auto mt-10">
                  <img class="w-full" src="../assets/img/my-settings-icon.svg" />
                </div>
                <router-link :to="'/' + Language + '/profile'" class="active-icon h-20 w-20 bg-gray-900 rounded-xl shadow-ts3 m-auto hidden mt-8">
                  <img class="w-full" src="../assets/img/my-settings-icon-b.svg" />
                </router-link>
              </div>
            </VueSlickCarousel>
          </div>
        </div>

        <div class="bg-white rounded-lg shadow-ts1 py-8 md:py-10 px-5 md:px-8 mb-7 mx-2 lg:mx-0 mt-7 lg:mt-0">
          <div class="flex justify-center md:justify-between items-center flex-col-reverse md:flex-row">
            <div class="">
              <p
                v-html="$t('vps_hosting_service_dashboard')"
                class="font-display font-bold text-[1.75rem] leading-8 text-blue mb-8 text-center ltr:md:text-left rtl:md:text-right hidden md:block"
              ></p>
              <p
                v-html="$t('vps_hosting_service_dashboard_mobile')"
                class="font-display font-bold text-3xl text-blue mb-4 text-center ltr:md:text-left rtl:md:text-right block md:hidden"
              ></p>
              <router-link
                :to="'/' + Language + '/trading-account/vps'"
                class="w-28 py-0.5 table m-auto md:m-0 text-green rounded-xl bg-white hover:bg-[#20B38126] border border-green border-solid text-center text-sm font-medium"
                >{{ $t('order_now') }}</router-link
              >
            </div>
            <img src="../assets/img/vps.svg" class="h-[81px] w-[81px] mb-5 md:mb-0" />
          </div>
        </div>

        <div class="bg-white rounded-lg shadow-ts1 py-8 md:py-10 px-5 md:px-8 mb-7 mx-2 lg:mx-0 mt-7 lg:mt-0">
          <div class="flex gap-5 md:gap-8 w-full h-full overflow-x-scroll" id="promotionsContainer">
            <div class="grow bg-white rounded-lg shadow-ts1">
              <p class="text-gray-900 font-display font-bold text-[1.38rem] leading-7 mb-[30px] cursor-auto"><span v-html="$t('alpha_generation_plugin')"></span></p>
              <a
                href="https://mt.tradingcentral.com/download"
                target="_blank"
                class="w-28 py-0.5 mb-2 table text-blue rounded-xl bg-white hover:bg-[#2B63E326] border border-blue border-solid text-center text-sm font-medium"
                >{{ $t('download').tTitle() }}</a
              >
            </div>
            <!-- <div class="grow bg-white rounded-lg shadow-ts1 p-8">
              <p class="text-gray-900 font-display font-bold text-[1.38rem] leading-7" :class="showNewsletterProgressBar ? 'mb-[26px]' : 'mb-[30px]'">
                <span v-html="$t('daily_market_newsletter')"></span>
              </p>
              <a
                v-if="!showNewsletterProgressBar"
                href="#"
                @click="newsletterSubscribe()"
                class="w-28 py-0.5 mb-2 table text-blue rounded-xl bg-white hover:bg-[#2B63E326] border border-blue border-solid text-center text-sm font-medium"
                >{{ $t('subscribe').tTitle() }}</a
              >
              <div v-else>
                <p class="text-gray-900 text-[8px] pb-1">{{ newsletterProgressCurrency}}</p>
                <div class="h-3 w-full bg-blue rounded-xl bg-opacity-25 relative">
                  <div
                    class="absolute inset-0 rounded-xl text-white text-center font-medium text-[10px] leading-none"
                    :class="newsletterProgressPercentage == 100 ? 'bg-green' : 'bg-blue'"
                    :style="'width: ' + Math.floor(newsletterProgressPercentage) + '%;'"
                  ></div>
                </div>
                <div class="flex flex-wrap justify-between">
                  <span class="text-gray-900 text-[10px] pt-0.5">{{ $formatMoney(Math.min(totalDeposits, newsletterMax), currencySymbols[User.wallet.currency]) }}</span>
                  <span class="text-gray-900 text-[10px] font-bold pt-0.5">{{ $formatMoney(newsletterMax, currencySymbols[User.wallet.currency]) }}</span>
                </div>
              </div>
            </div> -->
          </div>
        </div>

        <div v-if="showPlatforms" class="w-auto bg-white rounded-lg shadow-ts1 pt-5 pb-3 ltr:lg:ml-8 rtl:lg:mr-8 hidden lg:block">
          <div class="flex justify-between items-center pb-4 border-b border-solid border-gray-300">
            <div>
              <p class="ltr:pl-7 rtl:pr-7 text-blue font-display font-bold text-xl">{{ $t('platforms') }}</p>
            </div>

            <router-link :to="'/' + Language + '/trading-account/download'" class="ltr:pr-8 rtl:pr-8 font-semibold text-gray-900 text-sm">{{ $t('learn_more') }}</router-link>
          </div>
          <div class="relative overflow-hidden">
            <div id="step1" class="transition-all duration-300 ease-in-out">
              <p class="text-gray-900 text-base pb-6 ltr:pl-7 rtl:pr-7 pt-5">{{ $t('select_operating_system') }}</p>
              <div class="mx-7 flex spacing-x-2.5 gap-2">
                <div class="w-1/3 cursor-default flex-shrink-0" @click="gotoStep2('windows')">
                  <div class="py-[22px] w-full mb-3 bg-white rounded-lg border border-gray-300 relative group ltr:mr-2.5 rtl:ml-2.5">
                    <img class="w-11 m-auto" src="../assets/img/windows.svg" />
                    <div class="w-full bg-blue bg-opacity-85 absolute inset-0 text-center rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                      <div class="table w-full h-full text-center">
                        <p class="text-white font-bold text-xs pt-9">Windows</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-1/3 cursor-default flex-shrink-0" @click="gotoStep2('android')">
                  <div class="py-[22px] w-full mb-3 bg-white rounded-lg border border-gray-300 relative group ltr:mr-2.5 rtl:ml-2.5">
                    <img class="w-11 m-auto" src="../assets/img/android.svg" />
                    <div class="w-full bg-blue bg-opacity-85 absolute inset-0 text-center rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                      <div class="table w-full h-full text-center">
                        <p class="text-white font-bold text-xs pt-9">Android</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-1/3 cursor-default flex-shrink-0" @click="gotoStep2('ios')">
                  <div class="py-[22px] w-full mb-3 bg-white rounded-lg border border-gray-300 relative group ltr:mr-2.5 rtl:ml-2.5">
                    <img class="w-11 m-auto" src="../assets/img/apple.svg" />
                    <div class="w-full bg-blue bg-opacity-85 absolute inset-0 text-center rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                      <div class="table w-full h-full text-center">
                        <p class="text-white font-bold text-xs pt-9">iOS</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="step2" class="absolute w-full ltr:left-full rtl:right-full top-0 transition-all duration-300 ease-in-out">
              <p class="text-gray-900 text-base pb-6 ltr:pl-7 rtl:pr-7 pt-5">{{ $t('select_platform') }}</p>
              <div class="mx-7 flex spacing-x-2.5 gap-2 relative">
                <div @click="gotoStep1()" class="absolute ltr:-left-4 rtl:-right-4 top-1/3 z-10 cursor-pointer">
                  <img src="/static/img/arrow.svg" class="w-2 rotate-180" />
                </div>
                <div class="w-1/3 cursor-default flex-shrink-0" @click="downloadPlatform('mt4')">
                  <div class="py-[22px] w-full mb-3 bg-white rounded-lg border border-gray-300 relative group ltr:mr-2.5 rtl:ml-2.5">
                    <img class="w-11 m-auto" src="../assets/img/mt4.svg" />
                    <div class="w-full bg-blue bg-opacity-85 absolute inset-0 text-center rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                      <div class="table w-full h-full text-center">
                        <p class="text-white font-bold text-xs pt-9">MetaTrader 4</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-1/3 cursor-default flex-shrink-0" @click="downloadPlatform('mt5')">
                  <div class="py-[22px] w-full mb-3 bg-white rounded-lg border border-gray-300 relative group ltr:mr-2.5 rtl:ml-2.5">
                    <img class="w-11 m-auto" src="../assets/img/mt5.svg" />
                    <div class="w-full bg-blue bg-opacity-85 absolute inset-0 text-center rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                      <div class="table w-full h-full text-center">
                        <p class="text-white font-bold text-xs pt-9">MetaTrader 5</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-1/3 cursor-default flex-shrink-0">
                  <div class="py-[22px] w-full mb-3 bg-white ltr:mr-2.5 rtl:ml-2.5"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="ltr:ml-7 rtl:mr-7 flex space-x-1 rtl:space-x-reverse mb-6">
            <div @click="gotoStep1()" :class="[showDownloadStep1 ? 'bg-blue w-5' : 'w-2.5 bg-gray-300']" class="h-0.5 rounded-xl cursor-pointer"></div>
            <div :class="[!showDownloadStep1 ? 'bg-blue w-5' : 'w-2.5 bg-gray-300']" class="h-0.5 rounded-xl"></div>
          </div>
          <a id="downloadLink" target="_blank" class="bg-gray-300 text-center text-white rounded-md py-3 w-40 text-base font-bold table ltr:ml-7 rtl:mr-7 mb-4">{{
            $t('download').tTitle()
          }}</a>
        </div>
      </div>
    </div>

    <change-password-modal v-if="changeTAPassword.modalActive" :TAPasswordData="changeTAPassword"></change-password-modal>

    <create-account-modal @close="closeModal" @complete="refreshTAs" v-show="showCreateAccountModal"></create-account-modal>

    <view-trades-modal ref="viewTradesModalDashboard"></view-trades-modal>

    <transfer-back-modal
      v-if="transferBack.modalActive"
      :transferBackData="transferBack"
      @complete="refreshTAs">
    </transfer-back-modal>

  </div>
</template>

<script>
import { service } from '../main.js'
import { mapActions, mapGetters } from 'vuex'
import config from '../config'
import api from '../api'
import ChangePasswordModal from '../components/ChangePasswordModal.vue'
import CreateAccountModal from '../components/CreateAccountModal.vue'
import ViewTradesModal from '../components/ViewTradesModal.vue'
import TransferBackModal from '../components/TransferBackModal.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import links from '@/config/links.js'

export default {
  props: ['reason'],
  components: {
    VueSlickCarousel,
    ChangePasswordModal,
    CreateAccountModal,
    ViewTradesModal,
    TransferBackModal
  },
  data() {
    return {
      showNewsletterProgressBar: false,
      showCreateAccountModal: false,
      showPlatforms: false,
      selectedAccountType: 'all',
      selectedPlatform: 'all',
      walletBalance: 0,
      totalDeposits: 0,
      totalWithdrawals: 0,
      graphSwitched: false,
      showDownloadStep1: true,
      links: config.platformDownloadLinks,
      currencySymbols: config.currencies,
      selectedOperatingSystem: '',
      isRegistration: false,
      downloadLink: '',
      carouselSettings: {
        arrows: false,
        dots: true,
        centerMode: true,
        centerPadding: '60px',
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        initialSlide: 2,
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3,
              centerPadding: '20px',
            },
          },
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 3,
              centerPadding: '0px',
            },
          },
          {
            breakpoint: 1270,
            settings: {
              slidesToShow: 3,
              centerPadding: '-15px',
            },
          },
          {
            breakpoint: 1180,
            settings: {
              slidesToShow: 3,
              centerPadding: '-30px',
            },
          },
          {
            breakpoint: 1080,
            settings: {
              slidesToShow: 3,
              centerPadding: '-35px',
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              centerPadding: '125px',
            },
          },
          {
            breakpoint: 590,
            settings: {
              slidesToShow: 3,
              centerPadding: '100px',
            },
          },
          {
            breakpoint: 535,
            settings: {
              slidesToShow: 3,
              centerPadding: '70px',
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
              centerPadding: '20px',
            },
          },
        ],
      },
      filteredTradingAccounts: [],
      changeTAPassword: {
        modalActive: false,
        new: '',
        newConfirm: '',
        TAID: null,
      },
      transferBack: {
        modalActive: false,
        amount: 0,
        equity: 0,
        TAID: 0,
      },
      newsletterMax: 500.0,
      websiteURL: config.URL.website,
    }
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
      IsDemo: 'user/isDemo',
      IsLive: 'user/isLive',
      IsVerified: 'user/isVerified',
      BonusConfig: 'bonusconfig/all',
      logoLoaderActive: 'logoLoader/active',
      Bonuses: 'bonusconfig/bonusData',
    }),
    isIB() {
      if (!this.User.accountTypes.length) {
        return false
      }
      return this.User.accountTypes.indexOf('ib') !== -1
    },
    newsletterProgressPercentage() {
      return (Math.min(this.totalDeposits, this.newsletterMax) / this.newsletterMax) * 100
    },
    newsletterProgressCurrency() {
      if (this.currencySymbols && this.User.wallet.currency) {
        const currencySymbol = this.currencySymbols[this.User.wallet.currency].symbol
        return `${this.$t('progress_towards').tTitle()} ${currencySymbol}${this.newsletterMax}`
      } else {
        return ''
      }
    },
    isRTL() {
      if (this.Language === 'fa') {
        return true
      } else {
        return false
      }
    },
    mt4WebTraderLinks(){
     return links.getLink('mt4WebTrader', this.Language);
    },
    mt5WebTraderLinks(){
     return links.getLink('mt5WebTrader', this.Language);
    },
    promotionWelcomeBonusURL(){
     return links.getLink('promotionWelcomeBonus', this.Language);
    }
  },
  watch: {
    selectedPlatform() {
      this.filterAccounts()
    },
    selectedAccountType() {
      this.filterAccounts()
    },
    'User.info'(u) {
      if (u.ID) {
        this.isUserSubscribed()
        api.GetWallet().then(() => {
          this.walletBalance = Math.floor(this.User.wallet.balance * 100) / 100
        })
        this['logoLoader/set'](true)
        api
          .GetTradingAccounts()
          .then(() => {
            this.filterAccounts()
            this.getWalletTotals()
            this['logoLoader/set'](false)
          })
          .catch(() => {
            this['logoLoader/set'](false)
          })
        if (!this.isRegistration) {
          // todo: this part is not required. Find a more standard way to update wallet balance
          api.GetWallet()
        }
      }
    },
    $route() {
      this.isRegistration = typeof this.$route.meta.registerComponent !== 'undefined'
    },
    showCreateAccountModal(value) {
      if (value === true) {
        this.disableScroll()
      } else {
        this.enableScroll()
      }
    },
    'changeTAPassword.modalActive'(v) {
      if (v === true) {
        this.disableScroll()
      } else {
        this.enableScroll()
      }
    },
  },
  mounted() {
    this.dragContainer('#taContainer')
    this.dragContainer('#boxes')
    this.dragContainer('#promotionsContainer')
    this.onInitCarousel()
    this.onInitCarousel2()

    if (typeof this.User.info.ID !== 'undefined') {
      api.GetWallet().then(() => {
        this.walletBalance = Math.floor(this.User.wallet.balance * 100) / 100
      })
      this['logoLoader/set'](true)
      api
        .GetTradingAccounts()
        .then(() => {
          this.getWalletTotals()
          this.filterAccounts()
          this['logoLoader/set'](false)
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    }
    this.filterAccounts()

    document.addEventListener('click', function (e) {
      var tooltips = document.getElementsByClassName('actions-tooltip')
      var tooltipIcons = document.getElementsByClassName('tooltip-icon')

      for (let i = 0; i < tooltips.length; i++) {
        if (e.target != tooltips[i] && e.target != tooltipIcons[i]) {
          tooltips[i].classList.add('hidden')
        }
      }
    })
  },

  methods: {
    async isUserSubscribed() {
      try {
        const response = await service.post('/tradingcentral/is-user-subscribed', { user_id: this.User.info.ID })
        if (response && response.data) {
          this.showNewsletterProgressBar = response.data
        }
      } catch (error) {
        this.$toasted.error(this.$t('sub_status_check_fail') + error, { duration: 10000 })
      }
    },
    filterAccounts() {
      this.filteredTradingAccounts = []
      for (let i = 0; i < this.User.TA.length; i++) {
        if (
          (this.User.TA[i].platform === this.selectedPlatform || this.selectedPlatform === 'all') &&
          (this.User.TA[i].type === this.selectedAccountType || this.selectedAccountType === 'all') &&
          (this.User.TA[i].platform!='transfer')
        ) {
          this.filteredTradingAccounts.push(this.User.TA[i])
        }
      }
    },
    scrollLeft() {
      document.getElementById('taContainer').scrollLeft -= 320
    },
    scrollRight() {
      document.getElementById('taContainer').scrollLeft += 320
    },
    showActionsTooltip(ta) {
      let tooltips = document.getElementsByClassName('actions-tooltip')
      let clickedTA = document.getElementById(ta)
      let selectedTooltip = clickedTA.getElementsByClassName('actions-tooltip')[0]

      for (let i = 0; i < tooltips; i++) {
        tooltips[i].classList.add('hidden')
      }

      selectedTooltip.classList.toggle('hidden')
    },
    onInitCarousel() {
      setTimeout(() => {
        let itemTitles = document.querySelectorAll('#carousel1 .slick-slide .item-title')
        let itemActiveIcons = document.querySelectorAll('#carousel1 .slick-slide .active-icon')
        let itemIcons = document.querySelectorAll('#carousel1 .slick-slide .item-icon')

        let selectedItemTitle = document.querySelector('#carousel1 .slick-current .item-title')
        let selectedItemIcon = document.querySelector('#carousel1 .slick-current .item-icon')
        let selectedActiveItemIcon = document.querySelector('#carousel1 .slick-current .active-icon')

        for (let i = 0; i < itemTitles.length; i++) {
          itemTitles[i].classList.add('opacity-0')
          itemIcons[i].classList.remove('hidden')
          itemActiveIcons[i].classList.add('hidden')
          itemActiveIcons[i].classList.remove('table')
          selectedItemIcon.classList.remove('hidden')
        }

        selectedItemTitle.classList.remove('opacity-0')

        selectedItemIcon.classList.add('hidden')
        selectedActiveItemIcon.classList.remove('hidden')
        selectedActiveItemIcon.classList.add('table')
      }, 10)
    },
    onInitCarousel2() {
      setTimeout(() => {
        let itemTitles = document.querySelectorAll('#carousel2 .slick-slide .item-title')
        let itemActiveIcons = document.querySelectorAll('#carousel2 .slick-slide .active-icon')
        let itemIcons = document.querySelectorAll('#carousel2 .slick-slide .item-icon')

        let selectedItemTitle = document.querySelector('#carousel2 .slick-current .item-title')
        let selectedItemIcon = document.querySelector('#carousel2 .slick-current .item-icon')
        let selectedActiveItemIcon = document.querySelector('#carousel2 .slick-current .active-icon')

        for (let i = 0; i < itemTitles.length; i++) {
          itemTitles[i].classList.add('opacity-0')
          itemIcons[i].classList.remove('hidden')
          itemActiveIcons[i].classList.add('hidden')
          itemActiveIcons[i].classList.remove('table')
          selectedItemIcon.classList.remove('hidden')
        }

        selectedItemTitle.classList.remove('opacity-0')
        selectedItemIcon.classList.add('hidden')
        selectedActiveItemIcon.classList.remove('hidden')
        selectedActiveItemIcon.classList.add('table')
      }, 10)
    },
    gotoStep1() {
      this.showDownloadStep1 = true
      this.selectedOperatingSystem = ''

      let step1 = document.getElementById('step1')
      let step1Mobile = document.getElementById('step1Mobile')
      let step2 = document.getElementById('step2')
      let step2Mobile = document.getElementById('step2Mobile')

      step1.classList.remove('-translate-x-full')
      step1Mobile.classList.remove('-translate-x-full')

      step2.classList.add('left-full')
      step2Mobile.classList.add('left-full')

      step2.classList.remove('left-0')
      step2Mobile.classList.remove('left-0')

      let downloadLink = document.getElementById('downloadLink')
      let downloadLinkMobile = document.getElementById('downloadLinkMobile')

      downloadLink.classList.add('bg-gray-300')
      downloadLink.classList.remove('bg-green')
      downloadLink.removeAttribute('href')

      downloadLinkMobile.classList.add('bg-gray-300')
      downloadLinkMobile.classList.remove('bg-green')
      downloadLinkMobile.removeAttribute('href')
    },
    gotoStep2(os) {
      this.selectedOperatingSystem = os

      let step1 = document.getElementById('step1')
      let step1Mobile = document.getElementById('step1Mobile')
      let step2 = document.getElementById('step2')
      let step2Mobile = document.getElementById('step2Mobile')

      step1.classList.add('-translate-x-full')
      step1Mobile.classList.add('-translate-x-full')

      step2.classList.remove('left-full')
      step2Mobile.classList.remove('left-full')

      step2.classList.add('left-0')
      step2Mobile.classList.add('left-0')

      this.showDownloadStep1 = !this.showDownloadStep1
    },
    downloadPlatform(platform) {
      let downloadLink = document.getElementById('downloadLink')
      let downloadLinkMobile = document.getElementById('downloadLinkMobile')

      if (platform == 'mt4') {
        if (this.selectedOperatingSystem == 'windows') {
          downloadLink.href = this.links.mt4.windows
        } else if (this.selectedOperatingSystem == 'android') {
          downloadLink.href = this.links.mt4.android + '=' + this.Language
        } else if (this.selectedOperatingSystem == 'ios') {
          downloadLink.href = this.links.mt4.ios
        }
      } else if (platform == 'mt5') {
        if (this.selectedOperatingSystem == 'windows') {
          downloadLink.href = this.links.mt5.windows
        } else if (this.selectedOperatingSystem == 'android') {
          downloadLink.href = this.links.mt5.android + '=' + this.Language
        } else if (this.selectedOperatingSystem == 'ios') {
          downloadLink.href = this.links.mt5.ios
        }
      }

      if (downloadLink.href != '') {
        downloadLink.classList.remove('bg-gray-300')
        downloadLinkMobile.classList.remove('bg-gray-300')
        downloadLink.classList.add('bg-green')
        downloadLinkMobile.classList.add('bg-green')
      }

      downloadLinkMobile.href = downloadLink.href
    },
    closeModal() {
      this.showCreateAccountModal = false
      document.getElementById('CAcontainer').classList.add('translate-x-full')
    },
    refreshTAs() {
      api.GetTradingAccounts().then(() => {
        // this.getUserBonuses()
        this.filterAccounts()
      })
    },
    showCAModal() {
      this.showCreateAccountModal = true
      setTimeout(() => document.getElementById('CAcontainer').classList.remove('translate-x-full'), 10)
    },
    getWalletTotals() {
      this['logoLoader/set'](true)
      service
        .get('/finance/user/' + this.User.info.ID + '/totals-converted', {})
        .then(response => {
          if (response.data.total_deposits) {
            for (let i = 0; i < response.data.total_deposits.length; i++) {
              if (response.data.total_deposits[i].currency == this.User.wallet.currency) {
                this.totalDeposits = response.data.total_deposits[i].value
                break
              }
            }
          }
          if (response.data.total_withdrawals) {
            for (let i = 0; i < response.data.total_withdrawals.length; i++) {
              if (response.data.total_withdrawals[i].currency == this.User.wallet.currency) {
                this.totalWithdrawals = response.data.total_withdrawals[i].value
                break
              }
            }
          }
          this['logoLoader/set'](false)
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    },
    newsletterSubscribe() {
      // this['logoLoader/text'](this.$t('logoloader_newsletter_subscribing').tTitle())
      this['logoLoader/set'](true)
      service
        .post('tradingcentral/newsletter/request', {
          user_id: this.User.info.ID,
          email: this.User.info.email,
          action: 'subscribe',
        })
        .then(response => {
          if (response) {
            if (response.data.is_active) {
              this.$toasted.success(this.$t('newsletter_sub_full').tTitle())
            } else {
              this.$toasted.success(
                this.$t('newsletter_sub_partial', {
                  amount: this.$formatMoney(response.data.remaining_deposit, this.currencySymbols[this.User.wallet.currency]),
                }).tTitle(),
                { duration: 10000 }
              )
            }

            if (response.data.is_active || response.data.remaining_deposit) {
              this.showNewsletterProgressBar = true
            }
          }
          this['logoLoader/set'](false)
        })
        .catch(error => {
          this.$toasted.error(this.$t('news_sub_req_fail') + error, { duration: 10000 })
          this['logoLoader/set'](false)
        })
    },
    getTABonus(ta) {
      for (let i = 0; i < this.Bonuses.length; i++) {
        if (this.Bonuses[i].trading_account === ta) {
          return this.Bonuses[i].bonusAmount
        }
      }
      return 0
    },
    disableScroll() {
      document.querySelector('body').classList.add('overflow-hidden')
    },
    enableScroll() {
      document.querySelector('body').classList.remove('overflow-hidden')
    },
    openTransferBackModal(TAID, equity) {
      this.transferBack.modalActive = true
      this.transferBack.TAID = TAID
      this.transferBack.equity = equity
    },
    ...mapActions(['logoLoader/set', 'logoLoader/text']),
  },
}
</script>
<style lang="css">
#boxes::-webkit-scrollbar,
#promotionsContainer::-webkit-scrollbar {
  display: none;
}
#taContainer::-webkit-scrollbar,
#boxes::-webkit-scrollbar {
  /* @apply h-1; */
  height: 4px;
  width: 4px;
}
/* Track */
#taContainer::-webkit-scrollbar-track {
  /* @apply bg-gray-400 rounded-xl; */
  background: #e9edf4;
  border-radius: 12px;
}

#boxes::-webkit-scrollbar-track {
  /* @apply bg-gray-400 rounded-xl; */
  background: #f8f9fd;
  border-radius: 12px;
}

/* Handle */
#taContainer::-webkit-scrollbar-thumb,
#boxes::-webkit-scrollbar-thumb {
  /* @apply bg-blue rounded-xl; */
  background: #2b63e3;
  border-radius: 12px;
}

#taContainer {
  scroll-behavior: smooth;
}

#taContainer.active {
  scroll-behavior: auto;
}

.switch_mode .slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 0;
  bottom: 0;
  background-color: #2b63e3;
  transition: 0.4s;
  border-radius: 50%;
}

.switch_mode input:checked + .slider {
  background-color: #cfd9de;
}

.switch_mode input:focus + .slider {
  box-shadow: 0 0 1px #cfd9de;
}

.switch_mode input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: relative;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 10px;
  height: 20px;
  margin: 0 4px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 10px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 10px;
  height: 2px;

  content: '';
  text-align: center;

  background-color: #cfd9de;
  border-radius: 12px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active {
  width: 20px;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  width: 20px;
  background-color: #2b63e3;
  border-radius: 12px;
}
[dir=rtl] .slick-slide[data-v-e4caeaf8] {
    float: left;
}
.noUserSelect {
  -webkit-user-select: none;
	-webkit-user-drag: none;
	-webkit-app-region: no-drag;
}
</style>
