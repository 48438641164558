<template>
  <div class="pb-20 mt-[4.5rem] lg:mt-0" v-if="isEmailVerified.verified">
    <h1 class="font-display text-gray-900 font-bold text-basicTitle pb-9 lg:pb-5 ltr:ml-7 ltr:lg:ml-0 rtl:mr-7 rtl:lg:mr-0">{{ $t('local_depositor').tTitle() }}</h1>

    <div class="w-full bg-white rounded-lg shadow-ts1 mb-8">
      <div class="flex gap-5 md:gap-8 items-center justify-center p-5 md:p-8 flex-col md:flex-row">
        <div class="w-full md:w-1/2 rounded-lg border border-gray-300 border-solid py-3 md:py-4 md:px-8">
          <p class="text-gray-900 text-sm font-medium ltr:text-left rtl:text-right md:text-center px-5 md:px-0">{{$t("account_id").tTitle()}}</p>
          <p class="text-blue text-4xl font-bold ltr:text-left rtl:text-right md:text-center px-5 md:px-0">{{externalTransferAccount.ID}}</p>
        </div>
        <div class="w-full md:w-1/2 rounded-lg border border-gray-300 border-solid py-3 md:py-4 md:px-8">
          <p class="text-gray-900 text-sm font-medium ltr:text-left rtl:text-right md:text-center px-5 md:px-0">{{$t("balance").tTitle()}}</p>
          <p class="text-blue text-4xl font-bold ltr:text-left rtl:text-right md:text-center px-5 md:px-0">{{$formatMoney(externalTransferAccount.balance, currencySymbols[externalTransferAccount.currency])}}</p>
        </div>
      </div>
      <div class="pt-6 mx-5 md:mx-0">
        <div class="flex bg-gray-400 rounded-[1.3rem] maxMd:w-full lg:w-[55%] minMaxTablet:w-[95%] justify-center m-auto">
          <div class="w-1/2 rounded-[1.3rem] text-center py-2">
            <router-link
              class="text-gray-900 text-base text-center"
              :to="'/' + Language + '/finance/internal-transfer'"
            >{{$t("internal_transfer").tCapitalizeFirstLetter()}}</router-link>
          </div>
          <div class="w-1/2 rounded-[1.3rem] bg-blue py-2 cursor-pointer">
            <p class="text-white text-base font-bold text-center">{{$t("external_transfer").tCapitalizeFirstLetter()}}</p>
          </div>
        </div>
        <div class="pt-9 w-full px-0 md:px-8 pb-11">
          <form
            @submit.prevent
            data-vv-scope="form-transfer"
          >

            <div class="flex gap-5 w-full items-start flex-col md:flex-row">
              <div class="w-full md:w-1/2">
                <label class="text-gray-900 text-base">{{ $t("account_id").tTitle() }}</label>
                <div class="mt-1.5">
                  <select
                    id="selectLockedAccount"
                    class="text-base text-gray-900 font-semibold rtl:bg-left"
                    v-model="externalTransferAccount.selectLockedAccountTAID"
                  >
                    <option
                      v-for="(lta, i) in externalTransferAccount.lockedAccounts"
                      :value="lta.external_id"
                      :key="`externalTransferAccount.lockedAccounts-${i}`"
                    >{{lta.external_id}}</option>
                  </select>
                </div>
              </div>
              <div class="w-full md:w-1/2">
                <label class="text-gray-900 text-base">{{ $t("amount").tTitle() }}</label>
                <div class="mt-1.5">
                  <input
                    v-validate="'required|min_value:20|max_value:'+externalTransferAccount.balance"
                    :class="{'input': true, 'is-danger': errors.has('form-transfer.amount') }"
                    id="amount"
                    name="amount"
                    type="number"
                    v-model="externalTransferAccount.amount"
                    class="border border-white border-solid bg-[#F0F0F4] rounded-md h-12 px-5 text-gray-900 w-full text-base font-semibold"
                  />
                  <span
                    v-show="errors.has('form-transfer.amount')"
                    id="transferError"
                    :class="{'text-right': Language == 'fa'}"
                    class="text-xs text-red pt-0.5"
                  >{{ errors.first('form-transfer.amount') }}</span>
                </div>
              </div>
              <a
                class="w-full md:w-1/5 bg-blue cursor-pointer h-[45px] text-center text-white text-base rounded-md mb-[3px] pt-2.5 mt-5 md:mt-[31px]"
                @click="executeTransferTo()"
              >{{$t('transfer').tTitle()}}</a>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="w-full bg-white rounded-lg shadow-ts1 mb-8 pt-5">
      <div class="w-full pb-4 border-b border-gray-400 border-solid">
        <h2 class="ltr:pl-5 ltr:md:pl-8 rtl:pr-5 rtl:md:pr-8  text-blue font-display font-bold text-xl leading-none">{{$t('transfer_history')}}</h2>
      </div>
      <div class="w-auto mt-6 pb-5 px-5 md:px-8">
        <div class="flex gap-4 lg:gap-5 flex-col md:flex-row">
          <div class="w-full md:w-1/2">
            <label class="text-gray-900 text-base">{{ $t("type").tTitle() }}</label>
            <div class="mt-1.5">
              <select
                v-model="filters.extTrType"
                class="font-semibold text-gray-900 text-base rtl:bg-left"
              >
                <option
                  selected
                  value
                ></option>
                <option value="send">{{$t("sent").tTitle()}}</option>
                <option value="receive">{{$t("received").tTitle()}}</option>
              </select>
            </div>
          </div>
          <div class="w-full md:w-1/2">
            <label class="text-gray-900 text-base">{{ $t("reference_id").tTitle() }}</label>
            <div class="mt-1.5">
              <input
                class="font-semibold text-gray-900 text-base"
                type="text"
                name="referenceID"
                value
                v-model="filters.referenceID"
              />
            </div>
          </div>
          <div class="w-full lg:w-1/4 flex gap-4 lg:gap-5 items-end mt-3 md:mt-0">
            <button
              class="w-1/2 bg-blue text-center h-[45px] rounded-md text-white text-base mb-[3px]"
              id="searchBtn"
              @click="searchExternalTransfers()"
            >{{$t("search").tTitle()}}</button>
            <button
              class="w-1/2 bg-gray-400 text-center h-[45px] rounded-md text-gray-900 text-base mb-[3px]"
              id="clearBTN"
              @click="clearFilters()"
            >{{$t("clear").tTitle()}}</button>
          </div>
        </div>
      </div>

      <div
        v-if="pagination.total>0"
        class="mt-4 mx-5 md:mx-8 pb-14"
      >
        <table
          class="w-full hidden lg:table"
          :class="{'direction-rtl': Language == 'fa'}"
          id="external-transfers"
        >
          <thead>
            <tr>
              <th class="ltr:pl-7 rtl:pr-7 text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t("reference_id").tTitle()}}</th>
              <th class="text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t("from_account").tTitle()}}</th>
              <th class="text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t("to_account").tTitle()}}</th>
              <th class="text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t("amount").tTitle()}}</th>
              <th class="text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t("converted_amount").tTitle()}}</th>
              <th class="text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t("type").tTitle()}}</th>
              <th class="text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t("date").tTitle()}}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="ext in externalTransferAccount.history"
              :key="ext.reference_id"
              class="odd:bg-[#F0F0F4]"
            >
              <td class="ltr:pl-7 rtl:pr-7 text-base text-gray-900 py-3 rounded-tl-md rounded-bl-md">
                {{ext.reference_id}}
              </td>
              <td class="text-base text-gray-900 py-3">
                {{ext.from_account}}
              </td>
              <td class="text-base text-gray-900 py-3">
                {{ext.to_account}}
              </td>
              <td class="text-base text-gray-900 py-3">
                {{ext.amount}}
              </td>
              <td class="text-base text-gray-900 py-3">
                {{ext.converted_amount}}
              </td>
              <td class="text-base text-gray-900 py-3">
                {{ext.type}}
              </td>
              <td class="text-base text-gray-900 py-3 rounded-tr-md rounded-br-md ltr:pr-5 rtl:pl-5">
                {{$formatDateTime(ext.created_at)}}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="w-full lg:hidden text-body">
          <div
            v-for="(ext,i) in externalTransferAccount.history"
            :key="ext.reference_id"
            :id="ext.reference_id"
            class="px-4 pt-3 rounded-lg odd:bg-[#F0F0F4] overflow-hidden"
          >
            <div
              @click="expandRow(i)"
              class="flex justify-between cursor-pointer pb-3"
            >
              <label class="w-1/2 block text-blue font-bold">
                <img
                  :id="'arrow-'+i"
                  width="12px"
                  height="12px"
                  class="arrow ltr:mr-1 rtl:ml-1 inline -rotate-90"
                  src='../../assets/img/platform-dropdown.svg'
                >
                {{$t('reference_id').tTitle()}}
              </label>
              <p class="w-1/2 ltr:text-right rtl:text-left">{{ext.reference_id}}</p>
            </div>
            <div
              :id="'content-'+i"
              class="row hidden"
            >
              <div class="flex justify-between">
                <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("from_account").tTitle()}}</label>
                <p class="h-9">{{ext.from_account}}</p>
              </div>
              <div class="flex justify-between">
                <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("to_account").tTitle()}}</label>
                <p class="h-9">{{ext.to_account}}</p>
              </div>
              <div class="flex justify-between">
                <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("amount").tTitle()}}</label>
                <p class="h-9">{{ext.amount}}</p>
              </div>
              <div class="flex justify-between">
                <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("converted_amount").tTitle()}}</label>
                <p class="h-9">{{ext.converted_amount}}</p>
              </div>
              <div class="flex justify-between">
                <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("type").tTitle()}}</label>
                <p class="h-9">{{ext.type}}</p>
              </div>
              <div class="flex justify-between">
                <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("date").tTitle()}}</label>
                <p class="h-9">{{$formatDateTime(ext.created_at)}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="py-10"
      >
        <p class="text-center text-base text-blue font-semibold">{{$t('no_records')}}</p>
      </div>

    </div>

    <!-- <pagination
      for="external-transfers"
      :records="pagination.total"
      :per-page="pagination.maxItemsPerPage"
      :chunk="4"
      :count-text="pagination.total + ' ' + $t('records') "
    ></pagination> -->

    <error-modal></error-modal>
  </div>
</template>

<script>
import { service } from '../../main.js'
import { mapActions, mapGetters } from 'vuex'
import api from '../../api'
import moment from 'moment'
import config from '../../config'
import ErrorModal from '../../components/ErrorModal'

export default {
  components: {
    ErrorModal,
  },
  data: function () {
    return {
      externalTransferAccount: {
        ID: 0,
        currency: '',
        balance: 0,
        modalActive: {
          transfer: false,
        },
        history: [],
        amount: 0,
        lockedAccounts: [],
        selectLockedAccountTAID: 0,
      },
      currencySymbols: config.currencies,
      filters: {
        dateFrom: '',
        dateTo: '',
        extTrType: '',
        referenceID: '',
      },
      pagination: {
        page: 1,
        total: 1,
        maxItemsPerPage: 10,
      },
    }
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
      User: 'user/all',
      logoLoaderActive: 'logoLoader/active',
      isEmailVerified: 'user/isEmailVerified',
    }),
  },
  watch: {
    'User.TA'() {
      this.findTransferTA()
      this.searchExternalTransfers()
    },
    'externalTransferAccount.ID'() {
      this.findLockedChildTAs()
    },
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },
  },
  mounted() {
    if (this.$store.state.TAs) {
      this.User.TA = this.$store.state.TAs
    }

    if (typeof this.User.info.ID !== 'undefined' && !this.TALoading) {
      this.TALoading = true
      api.GetTradingAccounts()
    }

    // PaginationEvent.$on('vue-pagination::external-transfers', page => {
    //   this.pagination.page = page
    //   this.searchExternalTransfers()
    // })

    this.styleAlterations()
  },
  methods: {
    clearFilters() {
      this.filters.dateFrom = '2000-01-01'
      this.filters.dateTo = moment(moment().add(1, 'days')).format('YYYY-MM-DD')
      this.filters.extTrType = ''
      this.filters.referenceID = ''
      this.searchExternalTransfers()
    },
    styleAlterations() {
      let wrappers = document.getElementsByClassName('flatpickr-wrapper')
      for (let i = 0; i < wrappers.length; i++) {
        wrappers[i].style.display = 'block'
      }
    },
    searchExternalTransfers() {
      service
        .post('/finance/external-transfer/history', {
          userID: this.User.info.ID,
          TAID: this.externalTransferAccount.ID,
          dateFrom: this.filters.dateFrom,
          dateTo: this.filters.dateTo,
          extTrType: this.filters.extTrType,
          referenceID: this.filters.referenceID,
          page: this.pagination.page,
        })
        .then(response => {
          this.externalTransferAccount.history = response.data.history
          this.pagination.total = response.data.count
        })
    },
    executeTransferTo() {
      this['logoLoader/set'](true)
      this.$validator
        .validateAll('form-transfer')
        .then(result => {
          if (!result) {
            document.getElementById('transferError').classList.add('shakeSubtle')
            setTimeout(function () {
              document.getElementById('transferError').classList.remove('shakeSubtle')
            }, 1200)
            this['logoLoader/set'](false)
          } else {
            service
              .post('/finance/external-transfer/transfer-to', {
                userID: this.User.info.ID,
                amount: this.externalTransferAccount.amount,
                fromTA: this.externalTransferAccount.ID,
                toTA: this.externalTransferAccount.selectLockedAccountTAID,
              })
              .then(() => {
                this.externalTransferAccount.modalActive.transfer = false

                api.GetTradingAccounts()
                this.searchExternalTransfers()
                this.$toasted.success(this.$t('external_transfer_processed_successfully').tTitle(), { duration: 10000 })
                this['logoLoader/set'](false)
              })
              .catch(error => {
                this.externalTransferAccount.modalActive.transfer = false
                this['logoLoader/set'](false)
                if (error.response.data.meta) {
                  let meta = error.response.data.meta
                  let tkey = config.API.translationKeyName
                  if (meta[tkey]) {
                    let trep = {}
                    if (meta[tkey] === 'error_min_balance_required_is') {
                      trep = { TAAccountType: meta['TAAccountType'], TAID: meta['TAID'], currency: meta['currency'], min: meta['min'] }
                    }
                    this['errorModal/set']({ active: true, text: this.$t(meta[tkey], trep).tTitle() })
                    return
                  }
                }
                this['errorModal/set']({ active: true, text: this.$t('error_request_failed').tTitle() })
              })
          }
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    },
    findLockedChildTAs() {
      service
        .post('/ta/locked-TAs', {
          userID: this.User.info.ID,
          parentTAID: this.externalTransferAccount.ID,
        })
        .then(response => {
          if (response.data.TAs) {
            this.externalTransferAccount.lockedAccounts = response.data.TAs
            this.externalTransferAccount.selectLockedAccountTAID = this.externalTransferAccount.lockedAccounts[0].external_id
          }
        })
    },
    findTransferTA() {
      for (let i = 0; i < this.User.TA.length; i++) {
        if (this.User.TA[i].platform === 'transfer') {
          this.externalTransferAccount.ID = this.User.TA[i].external_id
          this.externalTransferAccount.currency = this.User.TA[i].currency
          this.externalTransferAccount.balance = this.User.TA[i].balance
          return
        }
      }
    },
    expandRow(index) {
      let arrows = document.getElementsByClassName('arrow')
      let rows = document.getElementsByClassName('row')

      for (let i = 0; i < arrows.length; i++) {
        if (i != index) {
          arrows[i].classList.add('-rotate-90')
          rows[i].classList.add('hidden')
        }
      }

      let arrow = document.getElementById('arrow-' + index)
      arrow.classList.toggle('-rotate-90')

      let content = document.getElementById('content-' + index)
      content.classList.toggle('hidden')
    },
    ...mapActions(['logoLoader/set', 'errorModal/set']),
  },
}
</script>
