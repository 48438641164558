<template>
  <div class="step-one px-[20px]">
    <div class="form-wrapper grid grid-cols-4-default gap-5 mb-5">
      <!-- First Name -->
      <div class="col-span-4 md:col-span-2" v-show="!companyActive">
        <label class="font-body text-base text-gray-900 tracking-wide" for="firstname">{{ translations.text.firstname }}<span class="text-orange ml-1">*</span></label>
        <div class="my-2">
          <input
            v-validate="{ required: !companyActive, alpha_spaces: !companyActive, min: 3, max: 255 }"
            :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('firstname') }"
            name="firstname"
            type="text"
            :data-vv-as="translations.text.firstname"
            :placeholder="translations.text.firstname_placeholder"
            v-model="data.firstname"
            class="text-base text-gray-900 font-semibold"
            :disabled="upgrade"
          />
          <span v-show="errors.has('firstname')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
            errors.first('firstname')
          }}</span>
        </div>
      </div>
      <!-- Last Name -->
      <div class="col-span-4 md:col-span-2" v-show="!companyActive">
        <label class="font-body text-base text-gray-900 tracking-wide" for="lastname">{{ translations.text.lastname }}<span class="text-orange ml-1">*</span></label>
        <div class="my-2">
          <input
            v-validate="{ required: !companyActive, alpha_spaces: !companyActive, min: 3, max: 255 }"
            :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('lastname') }"
            name="lastname"
            type="text"
            :data-vv-as="translations.text.lastname"
            :placeholder="translations.text.lastname_placeholder"
            v-model="data.lastname"
            class="text-base text-gray-900 font-semibold"
            :disabled="upgrade"
          />
          <span v-show="errors.has('lastname')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
            errors.first('lastname')
          }}</span>
        </div>
      </div>
      <!-- Country of residence -->
      <div class="col-span-4 md:col-span-4">
        <label class="font-body text-base text-gray-900 tracking-wide" for="country">{{ translations.text.country_of_residence }}<span class="text-orange ml-1">*</span></label>
        <div class="my-2">
          <span class="select is-fullwidth">
            <select
              v-validate="'required'"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('country') }"
              :data-vv-as="translations.text.country_of_residence"
              name="country"
              v-model="data.country"
              class="text-base text-gray-900 font-semibold rtl:bg-left"
              @change="setDialCode"
              :disabled="upgrade"
            >
              <option value="" disabled selected hidden>{{ translations.text.country_of_residence }}</option>
              <option v-for="(item, index) in filteredCountries" :value="index" :key="index">{{ item.name.tCapitalizeFirstLetter() }}</option>
            </select>
          </span>
          <span v-show="errors.has('country')" class="font-body text-xs text-orange font-semibold">{{ errors.first('country') }}</span>
        </div>
      </div>
      <!-- Company name -->
      <div class="col-span-4 md:col-span-2" v-show="companyActive">
        <label class="font-body text-base text-gray-900 tracking-wide" for="company">{{ translations.text.company_name }}<span class="text-orange ml-1">*</span></label>
        <div class="my-2">
          <input
            v-validate="{ required: companyActive, min: 3, max: 255 }"
            :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('company_name') }"
            name="company_name"
            type="text"
            :data-vv-as="translations.text.company_name"
            :placeholder="translations.text.company_name_placeholder"
            v-model="data.company_name"
            class="text-gray-900 text-base font-semibold"
          />
          <span v-show="errors.has('company_name')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
            errors.first('company_name')
          }}</span>
        </div>
      </div>
      <!-- Company website -->
      <div class="col-span-4 md:col-span-2" v-show="companyActive">
        <label class="font-body text-base text-gray-900 tracking-wide" for="website">{{ translations.text.company_website }}</label>
        <div class="my-2">
          <input
            v-validate="{ min: 3, max: 255 }"
            :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('website') }"
            name="website"
            type="text"
            :data-vv-as="translations.text.company_website"
            :placeholder="translations.text.website"
            v-model="data.website"
            class="text-gray-900 text-base font-semibold"
          />
          <span v-show="errors.has('website')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
            errors.first('website')
          }}</span>
        </div>
      </div>
      <!-- Dial code -->
      <div class="col-span-2 md:col-span-2 xl:col-span-1 relative">
        <label class="font-body text-base text-gray-900 tracking-wide" for="dialCode">{{ translations.text.dial_code }}<span class="text-orange ml-1">*</span></label>
        <div class="my-2">
          <span class="select is-fullwidth">
            <select
              v-validate="'required'"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('dialCode') }"
              :data-vv-as="translations.text.dial_code"
              name="dialCode"
              v-model="data.dialCode"
              class="text-base text-gray-900 font-semibold rtl:bg-left"
              :disabled="upgrade"
            >
              <option value="" disabled selected hidden>{{ translations.text.dial_code }}</option>
              <option v-for="(item, index) in countriesDialCodes" :value="item" :key="index">{{ '+ ' + item }}</option>
            </select>
            <span v-show="errors.has('dialCode')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
              errors.first('dialCode')
            }}</span>
          </span>
        </div>
      </div>
      <!-- Phone number -->
      <div class="col-span-2 md:col-span-2 xl:col-span-3">
        <div class="">
          <label class="font-body text-base text-gray-900 tracking-wide" for="phone">{{ translations.text.phone }}<span class="text-orange ml-1">*</span></label>
        </div>

        <div class="my-2">
          <span class="">
            <input
              v-validate="'required|numeric|max:15'"
              class="text-gray-900 text-base font-semibold"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('phone') }"
              name="phone"
              type="text"
              :data-vv-as="translations.text.phone"
              :placeholder="translations.text.phone"
              v-model="data.phone"
              :disabled="upgrade"
            />
            <span v-show="errors.has('phone')" class="font-body text-xs text-orange font-semibold">{{ errors.first('phone') }}</span>
          </span>
        </div>
      </div>
      <!-- Email -->
      <div class="col-span-4 md:col-span-4">
        <label class="font-body text-base text-gray-900 tracking-wide" for="email">{{ translations.text.email }}<span class="text-orange ml-1">*</span></label>
        <div class="my-2">
          <input
            v-validate="'required|email-available|max:255'"
            :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('email') }"
            name="email"
            type="text"
            :data-vv-as="translations.text.email"
            :placeholder="translations.text.email"
            v-model.trim="data.email"
            autocomplete="off"
            @blur="checkEmailValidation()"
            class="text-base text-gray-900 font-semibold"
            :disabled="upgrade"
          />
          <span v-show="invalidEmail" class="font-body text-xs text-orange font-semibold">{{ $t('invalid_email') }}</span
          ><br />
          <span v-show="errors.has('email')" class="font-body text-xs text-orange font-semibold">{{ errors.first('email') }}</span>
        </div>
      </div>
      <!-- Password -->
      <div v-if="!upgrade" class="col-span-4 md:col-span-2">
        <label class="font-body text-base text-gray-900 tracking-wide" for="password">{{ translations.text.password }}<span class="text-orange ml-1">*</span></label>
        <div class="my-2 relative">
          <input
            v-validate="{ required: true, 'strong-password': data.password, 'custom-confirmed': [data.password, data.confirm_password] }"
            :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('password') }"
            name="password"
            type="text"
            :data-vv-as="translations.text.password"
            :placeholder="translations.text.password"
            autocomplete="off"
            @input="$event => passwordChanged($event, 'password')"
            :value="showPassword ? data.password : passwordValueMask(data.password)"
            @paste.prevent
            @blur="data.confirm_password !== '' ? validateConfirmPassword() : '', validateField('password')"
            class="text-gray-900 text-base font-semibold"
          />
          <span class="absolute ltr:right-[18px] rtl:left-[18px] top-[18px] focus:border-none focus:outline-none cursor-pointer" @click="showPassword = !showPassword">
            <img src="@/assets/img/settings-eye-hidden.svg" width="20" alt="show password" v-if="showPassword" />
            <img src="@/assets/img/settings-eye-shown.svg" width="20" alt="hide password" v-if="!showPassword" />
          </span>
          <span v-show="errors.has('password')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
            errors.first('password')
          }}</span>
        </div>
      </div>
      <!-- Confirm Password -->
      <div v-if="!upgrade" class="col-span-4 md:col-span-2">
        <label class="font-body text-base text-gray-900 tracking-wide" :class="{ 'has-text-right': Language == 'fa' }" for="confirm_password"
          >{{ translations.text.confirm_password }}<span class="text-orange ml-1">*</span></label
        >
        <div class="my-2 relative">
          <input
            v-validate="'required'"
            :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('confirm_password') }"
            name="confirm_password"
            type="text"
            :data-vv-as="translations.text.confirm_password"
            :placeholder="translations.text.confirm_password"
            autocomplete="off"
            @input="$event => passwordChanged($event, 'confirm_password')"
            :value="showConfirmPassword ? data.confirm_password : passwordValueMask(data.confirm_password)"
            @paste.prevent
            @blur="validateConfirmPassword()"
            class="text-base text-gray-900 font-semibold"
          />
          <span
            class="absolute ltr:right-[18px] rtl:left-[18px] top-[18px] focus:border-none focus:outline-none cursor-pointer"
            @click="showConfirmPassword = !showConfirmPassword"
          >
            <img src="@/assets/img/settings-eye-hidden.svg" width="20" alt="show password" v-if="showConfirmPassword" />
            <img src="@/assets/img/settings-eye-shown.svg" width="20" alt="hide password" v-if="!showConfirmPassword" />
          </span>
          <span v-show="passwordsNotMatch" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
            $t('passwords_dont_match')
          }}</span>
          <span v-show="errors.has('confirm_password')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
            errors.first('confirm_password')
          }}</span>
        </div>
      </div>
      <!-- Social media platform -->
      <div v-if="!upgrade" class="col-span-4 md:col-span-2 flex flex-col justify-between">
        <label class="font-body text-base text-gray-900 tracking-wide" :class="{ 'has-text-right': Language == 'fa' }" for="social_platform"
          >{{ translations.text.social_platform  }}<span class="text-orange ml-1">*</span></label
        >
        <div class="my-2">
          <span class="select is-fullwidth">
            <select
              v-validate="'required'"
              name="social_platform"
              v-model="data.social_platform"
              class="text-base text-gray-900 font-semibold rtl:bg-left"
              :disabled="upgrade"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('social_platform') }"
              :data-vv-as="translations.text.social_platform"

            >
              <option value="" disabled selected hidden>{{ translations.text.social_platform }}</option>
              <option v-for="(item, index) in availablePlatforms" :value="item" :key="index">
                {{ item.includes('referred') ?  $t('referred').tTitle()  : item === 'x' ? 'X (Twitter)': item === 'other' ? $t('other_text') : item.tTitle() }}
              </option>
            </select>
            <span v-show="errors.has('social_platform')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
              errors.first('social_platform')
            }}</span>
          </span>
        </div>
      </div>
      <!-- Social media other platform -->
      <div class="col-span-4 md:col-span-2 flex flex-col justify-between" v-show="data.social_platform === 'other'">
        <label class="font-body text-base text-gray-900 tracking-wide" for="other_social_platform">{{ translations.text.other_social_platform }}</label>
        <div class="my-2">
          <input
            v-validate="{ max: 255 }"
            name="other_social_platform"
            type="text"
            :placeholder="translations.text.other_social_platform"
            :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('other_social_platform') }"
            :data-vv-as="translations.text.other_social_platform"
            v-model="data.other_social_platform"
            class="text-gray-900 text-base font-semibold"
          />
          <span v-show="errors.has('other_social_platform')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
            errors.first('other_social_platform')
          }}</span>
        </div>
      </div>
    </div>
    <div class="button-wrapper flex gap-[5%]">
      <button class="bg-blue text-white rounded-md font-body font-bold text-[16px] max-w-[185px] h-[50px] w-full px-[2%]" @click="changePage">{{ $t('continue').tTitle() }}</button>
    </div>
    <logo-loader />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import config from '../../config'
import LogoLoader from '../LogoLoader.vue'

export default {
  props: {
    accountType: String,
    activeStep: Number,
    isCompany: Boolean,
    translations: Object,
    formData: Object,
    upgrade: Boolean,
    prefilledTestData: Boolean,
    testData: Object,
  },
  components: {
    LogoLoader,
  },
  data() {
    return {
      page: this.activeStep,
      data: {
        firstname: '',
        lastname: '',
        country: '',
        company_name: '',
        website: '',
        dialCode: '',
        phone: '',
        email: '',
        password: '',
        confirm_password: '',
        social_platform: '',
        other_social_platform: '',
      },
      countries: config.countries,
      hiddenCountries: [
        'AT',
        'BE',
        'BG',
        'HR',
        'CY',
        'CZ',
        'DK',
        'EE',
        'FI',
        'FR',
        'DE',
        'GR',
        'HU',
        'IS',
        'IE',
        'IT',
        'LV',
        'LI',
        'LT',
        'LU',
        'MT',
        'NL',
        'NO',
        'PL',
        'PT',
        'RO',
        'SK',
        'SI',
        'ES',
        'SE',
        'GB',
        'US',
        'SY',
        'RU',
        'KP',
        'CA',
        'BY',
        'ZA',
        'ZW',
      ],
      invalidEmail: false,
      showPassword: false,
      showConfirmPassword: false,
      passwordsNotMatch: false,
      availablePlatforms: ['facebook', 'instagram', 'tiktok', 'x', 'reddit', 'forum', 'youTube', 'telegram', 'discord', 'threads', 'referred', 'other'],
    }
  },
  created() {
    if (this.prefilledTestData && this.testData) {
      Object.keys(this.testData).forEach(key => {
        this.data[key] = this.testData[key]
      })
    }
  },
  watch: {
    'data.firstname'() {
      if (this.data.firstname === 'regtest_123go') {
        this.autofillTestData()
      }
    },
    'data.company_name'() {
      if (this.data.company_name === 'regtest_123go') {
        this.autofillTestData()
      }
    },
    'data.phone'() {
      if (this.upgrade) {
        this.data.phone = this.testData.phone.replace(this.countries[this.testData.country].dialCode, '')
        this.data.dialCode = this.countries[this.testData.country].dialCode
      }
    },
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },

    prefilledTestData() {
      Object.keys(this.testData).forEach(key => {
        this.data[key] = this.testData[key]
      })
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      if (this.formData.email) {
        Object.keys(this.formData).forEach(key => {
          this.data[key] = this.formData[key]
        })
      }
    })
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
      BonusConfig: 'bonusconfig/all',
    }),
    filteredCountries() {
      for (var i = 0, keys = Object.keys(this.countries), ii = keys.length; i < ii; i++) {
        if (this.hiddenCountries.includes(keys[i])) {
          delete this.countries[keys[i]]
        }
      }
      return this.countries
    },
    companyActive() {
      this.$validator.reset()
      return this.isCompany
    },
    countriesDialCodes() {
      let dialCodes = []
      let countries = Object.entries(this.countries)
      countries.map(countryArr => {
        dialCodes.push(countryArr[1].dialCode)
      })
      return dialCodes
    },
    passwordValueMask() {
      return password => {
        let maskedResult = '*'.repeat(password.length)
        return maskedResult
      }
    },
  },
  methods: {
    checkEmailValidation() {
      this.invalidEmail = false
      if (this.data.email == '') {
        return
      }
      if (!this.$validateEmail(this.data.email.trim())) {
        this.invalidEmail = true
      }
    },
    validateConfirmPassword() {
      this.passwordsNotMatch = false
      if (this.data.password == '' || this.data.confirm_password == '') {
        return
      }
      if (this.data.password !== this.data.confirm_password) {
        this.passwordsNotMatch = true
      }
    },
    changePage() {
      if (this.passwordsNotMatch && !this.upgrade) {
        return
      }
      this.$validator
        .validateAll(
          !this.isCompany
            ? ['firstname', 'lastname', 'country', 'dialCode', 'phone', 'email', 'password', 'confirm_password', 'social_platform', 'other_social_platform']
            : ['country', 'company_name', 'dialCode', 'phone', 'email', 'password', 'confirm_password', 'social_platform', 'other_social_platform']
        )
        .then(result => {
          if (result && !this.invalidEmail) {
            this.$bus.$emit('ChangePage', this.page + 1)
            this.$bus.$emit('DataSubmitted', this.data)
            return
          }
          // scroll to first message
          let el = null
          if(this.errors.items.length > 0) el = document.getElementsByName(this.errors.items[0].field)[0]
          if(this.invalidEmail) el = document.getElementsByName('email')[0]
          if (el) return el.focus()
        })
    },
    autofillTestData() {
      this.$bus.$emit('fillWithTestData')
    },
    setDialCode() {
      this.data.dialCode = this.countries[this.data.country].dialCode
    },
    passwordChanged(e, field) {
      if (field === 'confirm_password') this.validateField('password')
      const passLength = e.target.value.length
      const newCharacter = e.data
      const cursorPosition = e.target.selectionStart
      const password = this.data[field]
      const changeCount = password.length + 1 - passLength

      if (newCharacter === null) {
        // delete from a cursor position in the password
        if (cursorPosition >= 0) {
          this.data[field] = password.substring(0, cursorPosition) + password.substring(cursorPosition + 1)
        }
        // delete the whole password
        if (password.length === 1 || passLength === 0) {
          this.data[field] = ''
        }
        // delete selection from the password
        if (changeCount > 2) {
          this.data[field] = password.substring(0, cursorPosition) + password.substring(cursorPosition + changeCount - 1)
        }
      } else {
        if (password.length - e.target.value.length < -3) {
          this.data[field] = ''
        } else {
          if (cursorPosition === password.length + 1) {
            // add new char in the end of the password
            this.data[field] += newCharacter
          } else if (changeCount === 0) {
            // add new char in the position of the cursor
            this.data[field] = this.data[field].substring(0, cursorPosition - 1) + newCharacter + this.data[field].substring(cursorPosition - 1)
          } else {
            // replace multiple chars in the password
            this.data[field] = password.substring(0, cursorPosition - 1) + newCharacter + password.substring(changeCount + 1)
          }
          if (this.data[field].length !== passLength) {
            this.data[field] = this.data[field].substring(0, passLength)
          }
        }
      }
      setTimeout(() => {
        e.target.setSelectionRange(cursorPosition, cursorPosition)
      }, 0)
    },
    async validateField(field) {
      return await this.$validator.validate(field, this.data.field)
    },
  },
}
</script>
<style scoped></style>
