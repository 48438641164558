<template>
  <div class="flex flex-wrap gap-4 mt-6 mb-12">
    <div class="bg-white w-full m-auto shadow-ts1 rounded-lg pb-3">
      <div class="border-b border-gray-300 w-full px-4 md:px-8 pt-5 pb-4">
        <h2 class="font-display text-xl font-bold text-blue leading-none">{{$t('payment_history').tTitle()}}</h2>
      </div>
      <div
        v-if="payments"
        class="p-2 md:p-6 justify-center "
      >
      <div class="hidden lg:block overflow-auto pb-6" id="paymentTable">
        <table class="min-w-[1100px] w-full border-collapse" >
          <thead class="font-bold ltr:text-left rtl:text-right text-blue">
            <tr class="h-[50px]">
              <th class="ltr:pl-8 rtl:pr-8">{{ $t('id') }}</th>
              <th>{{ $t('date').tTitle() }}</th>
              <th>{{ $t('account').tTitle() }}</th>
              <th>{{ $t('type').tTitle() }}</th>
              <th>{{ $t('gateway').tTitle() }}</th>
              <th>{{ $t('currency').tTitle() }}</th>
              <th>{{ $t('amount').tTitle() }}</th>
              <th>{{ $t('status').tTitle() }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(payment, i) in payments"
              :key="'payments-' + i"
              class="odd:bg-gray-100 even:bg-white py-4 h-[50px]"
            >
              <td class="font-bold ltr:rounded-l-xl rtl:rounded-r-xl ltr:pl-8 rtl:pr-8">#{{ payment.id }}</td>
              <td>{{ $formatDateTime(payment.date) }}</td>
              <td>{{ getTradingAccountTitle(payment.trading_account) }}</td>
              <td>
                <span v-if="payment.type.indexOf('transfer') !== -1">
                  <span >#{{ payment.id }} - </span>
                  <span v-if="payment.type.indexOf('withdrawal') !== -1">{{ $t('transfer_out').tTitle() }}</span>
                  <span v-if="payment.type.indexOf('deposit') !== -1">{{ $t('transfer_in').tTitle() }}</span>
                </span>
                <span v-else>{{ $t(payment.type).tTitle() }}</span>
              </td>
              <td>{{ renderGatewayContent(payment) }}</td>
              <td>{{ chooseDisplayCurrency(payment) }}</td>
              <td :class="getValueColor(payment.type)">{{ chooseDisplayValue(payment) }}</td>
              <td class="ltr:rounded-r-xl rtl:rounded-l-xl">
                <span v-if="payment.status === 'processed'">
                  <span class="icon has-text-success">
                    <i class="fa fa-check"></i>
                  </span>
                </span>
                <span v-if="payment.status === 'cancelled'">
                  <span class="icon">
                    <i class="fa fa-times"></i>
                  </span>
                </span>
                <span v-if="payment.status === 'pending'">
                  <span class="icon">
                    <i class="fa fa-clock-o"></i>
                  </span>
                </span>
                {{ $t(payment.status) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

        <div class="w-full lg:hidden text-body p-2">
          <div
            v-for="(payment, i) in payments"
            :key="'payment-'+i"
            :id="'payment-'+i"
            class="flex justify-between px-4 pt-[13px] rounded-lg odd:bg-gray-400 overflow-hidden"
            :class="{'max-h-[50px]': !payment.expanded}"
          >
            <div class="text-blue font-bold">
              <label class="block h-9">
                <img
                  @click="expandPayment(i)"
                  width="12px"
                  height="12px"
                  class="ltr:mr-1 rtl:ml-1 cursor-pointer inline"
                  :class="{'ltr:-rotate-90 rtl:rotate-90': !payment.expanded}"
                  src='../../assets/img/platform-dropdown.svg'
                >
                {{ $t('id').tTitle() }}
              </label>
              <label class="block h-9">{{ $t('date').tTitle() }}</label>
              <label class="block h-9">{{ $t('account').tTitle() }}</label>
              <label class="block h-9">{{ $t('type').tTitle() }}</label>
              <label class="block h-9">{{ $t('gateway').tTitle() }}</label>
              <label class="block h-9">{{ $t('currency').tTitle() }}</label>
              <label class="block h-9">{{ $t('amount').tTitle() }}</label>
              <label class="block h-9">{{ $t('status').tTitle() }}</label>
            </div>
            <div class="ltr:text-right rtl:text-left">
              <p class="h-9">#{{ payment.id }}</p>
              <p class="h-9">{{ $formatDateTime(payment.date) }}</p>
              <p class="h-9">{{ getTradingAccountTitle(payment.trading_account) }}</p>
              <p class="h-9">
                <span v-if="payment.type.indexOf('transfer') !== -1">
                  <span>#{{ payment.id }} - </span>
                  <span v-if="payment.type.indexOf('withdrawal') !== -1">{{ $t('transfer_out').tTitle() }}</span>
                  <span v-if="payment.type.indexOf('deposit') !== -1">{{ $t('transfer_in').tTitle() }}</span>
                </span>
                <span v-else>{{ payment.type.tTitle() }}</span>
              </p>
              <p class="h-9">{{ renderGatewayContent(payment) }}</p>
              <p class="h-9">{{ chooseDisplayCurrency(payment) }}</p>
              <p
                class="h-9"
                :class="getValueColor(payment.type)"
              >{{ chooseDisplayValue(payment) }}</p>
              <p class="h-9"><span v-if="payment.status === 'processed'">
                  <span class="icon has-text-success">
                    <i class="fa fa-check"></i>
                  </span>
                </span>
                <span v-if="payment.status === 'cancelled'">
                  <span class="icon">
                    <i class="fa fa-times"></i>
                  </span>
                </span>
                <span v-if="payment.status === 'pending'">
                  <span class="icon">
                    <i class="fa fa-clock-o"></i>
                  </span>
                </span>
                {{ $t(payment.status) }}
              </p>
            </div>
          </div>
        </div>
        <div class="flex justify-center content-center w-full py-5 mx-8 relative">
          <div class="w-[100px] h-[40px] font-body text-sm leading-10 absolute ltr:left-0 rtl:right-0">{{this.pagination.total}} {{ $t('records') }}</div>
          <div
            v-if="this.pagination.total > pagination.rowsPerPage"
            class="flex h-[40px] font-body text-center leading-10"
          >
            <div class="text-center">
              <pagination-component
                :page="pagination.page"
                :maxPerPage="pagination.rowsPerPage"
                :total="pagination.total"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="my-10"
      >
        <p class="text-center text-base text-blue font-semibold">{{$t('no_records')}}</p>
      </div>
    </div>
    <!-- <pagination
      for="payment-history"
      :records="pagination.total"
      :per-page="pagination.maxItemsPerPage"
      :chunk="4"
      :count-text="pagination.total + ' ' + $t('records')"
    ></pagination> -->

    <!-- <div class="bg-white w-full shadow-ts1 rounded-lg">
      <div class="border-b border-gray-300 w-full px-4 md:px-8 pt-5 pb-4">
        <h2 class="font-display text-xl font-bold text-blue leading-none">{{$t('my_cards').tTitle()}}</h2>
      </div>
      <div class="p-6 justify-center">
        <my-cards />
      </div>
    </div> -->
  </div>
</template>

<script>
import { service } from '@/main.js'
import { mapGetters, mapActions } from 'vuex'
import config from '../../config'

// import MyCards from './MyCards.vue'
import PaginationComponent from '@/components/Pagination.vue'

export default {
  components: {
    // MyCards,
    PaginationComponent,
  },
  data() {
    return {
      payments: [],
      currencySymbols: config.currencies,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        total: 0,
      },
    }
  },
  watch: {
    'pagination.page'() {
      this.getPayments()
    },
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
      logoLoaderActive: 'logoLoader/active',
    }),
  },
  mounted() {
    this.getPayments()
    this.getTradingAccountTitle()
    //hint if dragger of the container doesn't work look for the data to not be null ,
    // If the DOM element with the specified id
    // is not available at the time dragContainer is called,
    this.dragContainer('#paymentTable')

  },
  created() {
    this.$bus.$on('pageChanged', p => {
      this.pagination.page = p
    })
  },
  methods: {
    getPayments() {
      this['logoLoader/set'](true)

      service
        .post('/finance/payment-history/user', {
          userID: this.User.info.ID,
          page: this.pagination.page,
          'include-external': true,
        })
        .then(response => {
          this.payments = response.data.payments
          this.pagination.total = response.data.count

          for (let p in this.payments) {
            this.$set(this.payments[p], 'expanded', false)
          }
          this['logoLoader/set'](false)
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    },
    chooseDisplayValue(payment) {
      if (payment.value == 0 || ((parseFloat(payment.value) + parseFloat(payment.fee)).toFixed(2) == 10.01 && parseFloat(payment.value_requested) == 10)) {
        return this.$formatMoney(payment.value_requested, this.currencySymbols[payment.currency])
      } else if (payment.gateway && (payment.gateway.indexOf('Crypto') !== -1 || payment.gateway.indexOf('Confirmo') !== -1) && payment.type && payment.type.indexOf('withdrawal') !== -1) {
        let paymentCurrency = payment.currency
        if (this.User && this.User.wallet && this.User.wallet.currency) {
          paymentCurrency = this.User.wallet.currency
        }
        return this.$formatMoney(payment.value_requested, this.currencySymbols[paymentCurrency])
      } else {
        return this.$formatMoney(parseFloat(payment.value), this.currencySymbols[payment.currency])
      }
    },
    chooseDisplayCurrency(payment) {
      if (payment.gateway && (payment.gateway.indexOf('Crypto') !== -1 || payment.gateway.indexOf('Confirmo') !== -1) && payment.type && payment.type.indexOf('withdrawal') !== -1 && this.User && this.User.wallet && this.User.wallet.currency) {
        return this.User.wallet.currency
      } else {
        return payment.currency
      }
    },
    getValueColor(paymentType) {
      if (paymentType.indexOf('deposit') !== -1) {
        return 'text-green'
      } else {
        return 'text-red'
      }
    },
    expandPayment(i) {
      this.$set(this.payments, i, { ...this.payments[i], expanded: !this.payments[i].expanded })
    },
    /**
     * Function to return the content of gateway column on payment history table depending on different cases.
     */
    renderGatewayContent(payment) {
      if(payment?.extra_info?.toLowerCase().includes('ib commission')) {
        return 'IB Commissions'
      } else if (payment.gateway=='VLoad') {
        return 'PayRedeem'
      } else if (payment.gateway=='') {
        return '-'
      } else {
        return payment.gateway;
      }
    },
   getTradingAccountTitle(account) {
      if (account === '-1') {
        return this.$t('wallet').tTitle();
      } else if (isNaN(account)) {
        return this.$t(account).tTitle();
      } else {
        return account;
      }
    },
    ...mapActions(['logoLoader/set', 'user/setInfo']),
  },
}
</script>
<style scoped>
#paymentTable::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}

#paymentTable::-webkit-scrollbar-thumb {
    background: #2b63e3;
    border-radius: 12px;
}
#paymentTable::-webkit-scrollbar-track {
    background: #e9edf4;
    border-radius: 12px;
}
  #paymentTable::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
/* Track */
#paymentTable::-webkit-scrollbar-track {
  background: #e9edf4;
  border-radius: 12px;
}
/* Handle */
#paymentTable::-webkit-scrollbar-thumb{
  background: #2b63e3;
  border-radius: 12px;
}

#paymentTable {
  scroll-behavior: smooth;
}

#paymentTable.active {
  scroll-behavior: auto;
}
</style>
