export default [
  {
    name: 'Return Confirmo',
    meta: {
      auth: true,
    },
    path: '/deposit/bitcoin/return',
    component: require('../views/finance/deposit-return/Bitcoin'),
  },
  {
    name: 'Return RegularPay Success',
    meta: {
      auth: true,
    },
    path: '/deposit/regularpay/success',
    component: require('../views/finance/deposit-return/RegularPaySuccess'),
  },
  {
    name: 'Return RegularPay Failed',
    meta: {
      auth: true,
    },
    path: '/deposit/regularpay/failed',
    component: require('../views/finance/deposit-return/RegularPayFailed'),
  },
  {
    name: 'Return EData Success',
    meta: {
      auth: true,
    },
    path: '/deposit/e-data/success',
    component: require('../views/finance/deposit-return/EDataSuccess'),
  },
  {
    name: 'Return EData Failed',
    meta: {
      auth: true,
    },
    path: '/deposit/e-data/failed',
    component: require('../views/finance/deposit-return/EDataFailed'),
  },
  {
    name: 'Return Skrill Success',
    meta: {
      auth: true,
    },
    path: '/deposit/skrill/success',
    component: require('../views/finance/deposit-return/SkrillSuccess'),
  },
  {
    name: 'Return Skrill Failed',
    meta: {
      auth: true,
    },
    path: '/deposit/skrill/failed',
    component: require('../views/finance/deposit-return/SkrillFailed'),
  },
  {
    name: 'Return Neteller Success',
    meta: {
      auth: true,
    },
    path: '/deposit/neteller/success',
    component: require('../views/finance/deposit-return/NetellerSuccess'),
  },
  {
    name: 'Return Neteller Failed',
    meta: {
      auth: true,
    },
    path: '/deposit/neteller/failed',
    component: require('../views/finance/deposit-return/NetellerFailed'),
  },
  {
    name: 'Return PayRedeem Success',
    meta: {
      auth: true,
    },
    path: '/deposit/vload/success',
    component: require('../views/finance/deposit-return/VLoadSuccess'),
  },
  {
    name: 'Return PayRedeem Failed',
    meta: {
      auth: true,
    },
    path: '/deposit/vload/failed',
    component: require('../views/finance/deposit-return/VLoadFailed'),
  },
  {
    name: 'Return KoalaPay Success',
    meta: {
      auth: true,
    },
    path: '/deposit/koalapay/success',
    component: require('../views/finance/deposit-return/KoalaPaySuccess'),
  },
  {
    name: 'Return KoalaPay Failed',
    meta: {
      auth: true,
    },
    path: '/deposit/koalapay/failed',
    component: require('../views/finance/deposit-return/KoalaPayFailed'),
  },
  {
    name: 'Return Fasapay Success',
    meta: {
      auth: true,
    },
    path: '/deposit/fasapay/success',
    component: require('../views/finance/deposit-return/FasapaySuccess'),
  },
  {
    name: 'Return Fasapay Failed',
    meta: {
      auth: true,
    },
    path: '/deposit/fasapay/failed',
    component: require('../views/finance/deposit-return/FasapayFailed'),
  },
  {
    name: 'Return Pago',
    meta: {
      auth: true,
    },
    path: '/deposit/pago/return',
    component: require('../views/finance/deposit-return/PagoCUP'),
  },
  {
    name: 'Return Crypto Success',
    meta: {
      auth: true,
    },
    path: '/deposit/lydiax/success',
    component: require('../views/finance/deposit-return/LydiaxSuccess'),
  },
  {
    name: 'Return Crypto Failed',
    meta: {
      auth: true,
    },
    path: '/deposit/lydiax/failed',
    component: require('../views/finance/deposit-return/LydiaxFailed'),
  },
  {
    name: 'Return SafeCharge Success',
    meta: {
      auth: true,
    },
    path: '/deposit/safecharge/success',
    component: require('../views/finance/deposit-return/SafeChargeSuccess'),
  },
  {
    name: 'Return SafeCharge Failed',
    meta: {
      auth: true,
    },
    path: '/deposit/safecharge/failed',
    component: require('../views/finance/deposit-return/SafeChargeFailed'),
  },
]
