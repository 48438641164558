<template>
  <div>
    <div @click="close()" class="fixed inset-0 z-50 bg-gray-900 bg-opacity-70 w-screen h-screen"></div>

    <div
      id="CAcontainer"
      class="transition-all duration-300 translate-x-full rtl:ease-in ease-in-out fixed flex flex-col items-center lg:items-end ltr:right-0 rtl:left-0 top-0 z-50 overflow-x-hidden overflow-y-auto h-full w-full lg:w-auto"
    >
      <div class="w-11/12 lg:w-[820px] max-w-[820px]">
        <button @click="close()" class="bg-white rounded-full h-10 w-10 lg:self-start appearance-none flex-shrink-0 mt-2 p-0">
          <div class="h-6 w-0.5 bg-blue rotate-45 ltr:ml-5 rtl:mr-5"></div>
          <div class="h-6 w-0.5 bg-blue -rotate-45 -mt-6 ltr:ml-5 rtl:mr-5"></div>
        </button>
      </div>
      <div
        class="bg-white ltr:rounded-br-xl ltr:lg:rounded-br-none ltr:rounded-tr-xl ltr:lg:rounded-tr-none ltr:rounded-tl-xl ltr:rounded-bl-xl rtl:rounded-bl-xl rtl:lg:rounded-bl-none rtl:rounded-tl-xl rtl:lg:rounded-tl-none rtl:rounded-tr-xl rtl:rounded-br-xl py-10 md:py-12 px-5 md:px-10 lg:px-12 w-11/12 lg:w-[820px] max-w-[820px] relative mt-4 mb-20 md:mb-0"
      >
        <!-- Step 1 -->
        <div v-if="showStep1">
          <div class="text-gray-900 font-display font-bold text-2xl md:text-3xl mb-9">{{ $t('create_trading_account') }}</div>

          <div class="flex gap-5 md:gap-16 mb-5 md:mb-6 flex-col md:flex-row">
            <!-- select type of account -->
            <div>
              <span class="text-gray-900 text-base font-semibold">{{ $t('select_type_of_account') }}</span>
              <div class="flex gap-2.5 mt-2 items-center">
                <div class="h-28 w-32 relative" :class="User.isLive ? 'cursor-pointer' : 'cursor-not-allowed' " @click="User.isLive ? selectTypeOfAc(false, 'live') : ''">
                  <div :class="[selectedType == 'live' ? 'border-2 border-blue' : 'border border-gray-300']" class="h-28 w-32 rounded-md border-solid relative"></div>
                  <div class="absolute w-full flex items-center justify-center inset-0 flex-col z-0 mt-3.5">
                    <span :class="[selectedType == 'live' ? 'text-blue' : 'text-gray-300']" class="font-display text-6xl font-bold leading-9 pb-2">L</span>
                    <span :class="[selectedType == 'live' ? 'text-blue font-bold' : 'text-gray-900']" class="text-base">{{$t('live')}}</span>
                  </div>
                </div>

                <div class="h-28 w-32 relative cursor-pointer" @click="selectTypeOfAc(true, 'demo')">
                  <div :class="[selectedType == 'demo' ? 'border-2 border-blue' : 'border border-gray-300']" class="h-28 w-32 rounded-md border-solid relative"></div>
                  <div class="absolute w-full flex items-center justify-center inset-0 flex-col z-0 mt-3.5">
                    <span :class="[selectedType == 'demo' ? 'text-blue' : 'text-gray-300']" class="font-display text-6xl font-bold leading-9 pb-2">D</span>
                    <span :class="[selectedType == 'demo' ? 'text-blue font-bold' : 'text-gray-900']" class="text-base">{{$t('demo')}}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- select platform -->
            <div class="overflow-x-auto ">
              <span class="text-gray-900 text-base font-semibold">{{ $t('select_platform') }}</span>
              <div class="flex gap-2.5 mt-2 ">
                <div class="h-28 w-32 relative cursor-pointer" @click="selectPlatform('mt4')">
                  <div :class="[selectedPlatform == 'mt4' ? 'border-2 border-blue' : 'border border-gray-300']" class="h-28 w-32 rounded-md border-solid relative"></div>
                  <div class="absolute w-full flex items-center justify-center inset-0 flex-col z-0">
                    <img v-if="selectedPlatform == 'mt4'" src="../assets/img/mt4-selected.svg" width="50" class="mb-2" />
                    <img v-else src="../assets/img/mt4-not-selected.svg" width="50" class="mb-2" />
                    <span :class="[selectedPlatform == 'mt4' ? 'text-blue font-bold' : 'text-gray-900']" class="text-base">MetaTrader 4</span>
                  </div>
                </div>
                <div class="h-28 w-32 relative cursor-pointer" @click="selectPlatform('mt5')">
                  <div :class="[selectedPlatform == 'mt5' ? 'border-2 border-blue' : 'border border-gray-300']" class="h-28 w-32 rounded-md border-solid relative"></div>
                  <div class="absolute w-full flex items-center justify-center inset-0 flex-col z-0">
                    <img v-if="selectedPlatform == 'mt5'" src="../assets/img/mt5-selected.svg" width="50" class="mb-2" />
                    <img v-else src="../assets/img/mt5-not-selected.svg" width="50" class="mb-2" />
                    <span :class="[selectedPlatform == 'mt5' ? 'text-blue font-bold' : 'text-gray-900']" class="text-base">MetaTrader 5</span>
                  </div>
                </div>
                <div v-if="selectedType!='demo'" class="h-28 w-32 relative cursor-pointer" @click="selectPlatform('pamm')">
                  <div :class="[selectedPlatform == 'pamm' ? 'border-2 border-blue' : 'border border-gray-300']" class="h-28 w-32 rounded-md border-solid relative"></div>
                  <div class="absolute w-full flex items-center justify-center inset-0 flex-col z-0">
                    <img v-if="selectedPlatform == 'pamm'" src="../assets/img/pamm-selected.svg" width="50" class="mb-2" />
                    <img v-else src="../assets/img/pamm-not-selected.svg" width="50" class="mb-2" />
                    <span :class="[selectedPlatform == 'pamm' ? 'text-blue font-bold' : 'text-gray-900']" class="text-base">PAMM</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- select currency -->
          <div class="mb-5 md:mb-6">
            <span class="text-gray-900 text-base font-semibold">{{ $t('select_currency').tTitle() }}</span>
            <div class="flex gap-4 mt-2 items-center">
              <div v-for="(c, i) in typesOfAccount" :key="i" class="h-12 w-1/2 relative">
                <div :class="[selectedCurrency == i ? 'border-2 border-blue' : 'border border-gray-300']" class="h-12 w-auto rounded-md border-solid relative"></div>
                <div class="absolute w-full flex items-center justify-center inset-0 flex-col z-0 mt-0.5 cursor-pointer" @click="selectCurrency(i)">
                  <span :class="[selectedCurrency == i ? 'text-blue font-bold' : 'text-gray-900']" class="text-base">{{ i }} ({{ c.symbol }})</span>
                </div>
              </div>
            </div>
          </div>
          <!-- select ac type -->
          <div class="mb-5 md:mb-6">
            <span class="text-gray-900 text-base font-semibold">{{ $t('select_account_type').tTitle() }}</span>
            <div class="flex gap-2.5 mt-2 items-center overflow-y-hidden overflow-x-scroll" id="accountTypesContainer">
              <div
                v-for="(item, index) in accountTypes"
                :key="index"
                :class="[Object.keys(accountTypes).length > 4 ? 'flex-none md:flex-1' : 'w-[91px] md:w-[112px]']"
                class="h-[103px] w-[91px] relative"
              >
                <div
                  :class="[selectedAccountType == item.name ? 'border-2 border-blue' : 'border border-gray-300']"
                  class="appearance-none h-[103px] w-full rounded-md border-solid relative"
                ></div>
                <div
                  class="absolute w-full flex items-center justify-center inset-0 flex-col z-0 mt-1.5 cursor-pointer"
                  @click="selectAcType(item.name)"
                >
                  <img
                    v-if="item.name=='micro'"
                    src="../assets/img/micro.svg"
                    width="50"
                    class="mb-2"
                  />
                  <img
                    v-else-if="item.name=='silver'"
                    src="../assets/img/silver.svg"
                    width="50"
                    class="mb-2"
                  />
                  <img
                    v-else-if="item.name=='gold-raw-is'"
                    src="../assets/img/islamic.svg"
                    width="50"
                    class="mb-2"
                  />
                  <img
                    v-else-if="item.name=='gold-ecn' || item.name=='gold-raw'"
                    src="../assets/img/gold.svg"
                    width="50"
                    class="mb-2"
                  />
                  <img
                    v-else-if="item.name=='platinum-vip'"
                    src="../assets/img/platinum.svg"
                    width="50"
                    class="mb-2"
                  />
                  <img
                    v-else-if="item.name=='pamm-investor-pro' || item.name=='pamm-investor-raw' || item.name=='investor'"
                    src="../assets/img/pamm-investor.svg"
                    width="50"
                    class="mb-2"
                  />
                  <img
                    v-else-if="item.name=='regular'"
                    src="../assets/img/demo.svg"
                    width="50"
                    class="mb-2"
                  />
                  <span
                    :class="[selectedAccountType==item.name?'text-blue font-bold':'text-gray-900']"
                    class="text-base text-center leading-4"
                  >{{item.name=='platinum-vip'?'Platinum VIP':item.name=='pamm-investor-raw'?'Investor Raw':item.name=='pamm-investor-pro'?'Investor Pro':item.display_name.tCapitalizeFirstLetter()}}</span>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedAccountType !== 'pamm-investor-pro' && selectedAccountType !== 'pamm-investor-raw' && selectedAccountType !== 'investor'" class="mb-5 md:mb-6">
            <!-- select leverage -->

            <div>
              <span class="text-gray-900 text-base font-semibold">{{ $t('select_leverage').tTitle() }}</span>
              <div class="flex gap-1.5 mt-2 items-center overflow-y-hidden overflow-x-scroll" id="leverageContainer">
                <div
                  v-for="(item, index) in leverages"
                  :key="index"
                  :class="[Object.keys(leverages).length > 3 ? 'flex-none md:flex-1' : 'w-[88px] md:w-[88px]']"
                  class="h-12 w-[88px] relative"
                >
                  <div :class="[selectedLeverage == item.leverage ? 'border-2 border-blue' : 'border border-gray-300']" class="h-12 w-full rounded-md border-solid relative"></div>
                  <div class="absolute w-full flex items-center justify-center inset-0 flex-col z-0 mt-0.5 cursor-pointer" @click="selectLeverage(item.leverage)">
                    <span :class="[selectedLeverage == item.leverage ? 'text-blue font-bold' : 'text-gray-900']" class="text-base">1:{{ item.leverage }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-5 md:mb-8" v-show="showBalances">
            <div>
              <span class="text-gray-900 text-base font-semibold">{{ $t('select_balance') }}</span>
              <div class="flex gap-1.5 mt-2 items-center overflow-x-scroll" id="balancesContainer">
                <div v-for="item in balances" :key="item" class="h-12 w-[79px] md:w-auto flex-none md:flex-1 relative">
                  <div :class="[selectedBalance == item ? 'border-2 border-blue' : 'border border-gray-300']" class="h-12 w-full rounded-md border-solid relative"></div>
                  <div class="absolute w-full flex items-center justify-center inset-0 flex-col z-0 mt-0.5 cursor-pointer" @click="selectBalance(item)">
                    <span :class="[selectedBalance == item ? 'text-blue font-bold' : 'text-gray-900']" class="text-base">{{ item.toLocaleString() }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-8 md:mb-10" v-if="BonusConfig.depositBonus.enabled && selectedType == 'live' && selectedAccountType !== 'pamm-investor-pro' && selectedAccountType !== 'pamm-investor-raw' && selectedAccountType !== 'investor' && depositBonusesLength < 3">
            <div class="form-check relative flex items-center">
              <input class="checkbox" :class="{ 'appearance-none': acceptBonus === false }" type="checkbox" v-model="acceptBonus" id="acceptBonus" />
              <label class="checkbox-label" for="acceptBonus" v-html="$t('would_like_to_receive_bonus')"> </label>
            </div>
          </div>

          <div
            class="mb-8 md:mb-10"
            v-if="selectedAccountType === 'pamm-investor-pro' || selectedAccountType === 'pamm-investor-raw' || selectedAccountType === 'investor'"
          >
            <div class="form-check relative flex items-center">
              <input
                class="checkbox"
                :class="{'appearance-none': acceptLPOA === false}"
                type="checkbox"
                v-model="acceptLPOA"
                id="acceptLPOA"
              >
              <label
                class="checkbox-label"
                for="acceptLPOA"
                v-html="$t('read_and_accept_lpoa',{URL:LPOA_URL})"
              >
              </label>
            </div>
            <span v-if="showLpoaError" class="text-xs text-red mt-4 table">{{ $t('must_accept_lpoa')}}</span>
          </div>

          <div class="flex justify-between items-start md:items-center flex-col md:flex-row">
            <button
              @click="gotoStep2()"
              class="appearance-none bg-blue text-white text-base font-bold rounded-md h-12 w-40 text-center"
              :class="{ 'bg-gray-400': !optionsValid }"
              :disabled="!optionsValid"
            >
              {{ $t('continue').tTitle() }}
            </button>
            <span v-show="showErrorMessage" class="text-sm text-red ltr:pr-3 rtl:pl-3 pt-3 md:pt-0">{{ $t('please_select_all_options') }}</span>
          </div>
        </div>

        <!-- Step 2 -->
        <div v-if="!showStep1">
          <div @click="showStep1 = !showStep1" class="h-6 w-20 bg-blue rounded-xl flex items-center justify-center gap-1.5 m-auto cursor-pointer mb-24">
            <img src="../assets/img/back-arrow.svg" class="w-3" />
            <span class="text-white text-base">{{ $t('back').tCapitalizeFirstLetter() }}</span>
          </div>

          <div class="text-gray-900 text-[2.5rem] font-display font-bold text-center mb-4 leading-[2.9rem]">{{ $t('one_final_step').tTitle() }}</div>
          <div class="text-gray-900 text-base text-center mb-12">{{ $t('check_your_options') }}</div>

          <table class="border-0 border-collapse w-full md:w-[29rem] max-w-[29rem] m-auto mb-14">
            <tr class="odd:bg-gray-100">
              <td class="ltr:rounded-tl-md ltr:rounded-bl-md rtl:rounded-tr-md rtl:rounded-br-md ltr:pl-5 rtl:pr-5 py-3.5 text-blue font-bold text-base">{{ $t('type_of_account') }}</td>
              <td class="ltr:rounded-tr-md ltr:rounded-br-md rtl:rounded-tl-md rtl:rounded-bl-md ltr:pr-5 rtl:pl-5 py-3.5 text-gray-900 text-base ltr:text-right rtl:text-left">{{ selectedType.tTitle() }}</td>
            </tr>
            <tr class="odd:bg-gray-100">
              <td class="ltr:rounded-tl-md ltr:rounded-bl-md rtl:rounded-tr-md rtl:rounded-br-md ltr:pl-5 rtl:pr-5 py-3.5 text-blue font-bold text-base">{{ $t('platform').tTitle() }}</td>
              <td class="ltr:rounded-tr-md ltr:rounded-br-md rtl:rounded-tl-md rtl:rounded-bl-md ltr:pr-5 rtl:pl-5 py-3.5 text-gray-900 text-base ltr:text-right rtl:text-left">{{ selectedPlatform == 'mt4' ? 'MetaTrader 4' : selectedPlatform == 'mt5' ? 'MetaTrader 5':'PAMM' }}</td>
            </tr>
            <tr class="odd:bg-gray-100">
              <td class="ltr:rounded-tl-md ltr:rounded-bl-md rtl:rounded-tr-md rtl:rounded-br-md ltr:pl-5 rtl:pr-5 py-3.5 text-blue font-bold text-base">{{ $t('currency').tTitle() }}</td>
              <td class="ltr:rounded-tr-md ltr:rounded-br-md rtl:rounded-tl-md rtl:rounded-bl-md ltr:pr-5 rtl:pl-5 py-3.5 text-gray-900 text-base ltr:text-right rtl:text-left">{{ selectedCurrency.tUpper() }}</td>
            </tr>
            <tr class="odd:bg-gray-100">
              <td class="ltr:rounded-tl-md ltr:rounded-bl-md rtl:rounded-tr-md rtl:rounded-br-md ltr:pl-5 rtl:pr-5 py-3.5 text-blue font-bold text-base">{{$t('account_type').tTitle()}}</td>
              <td class="ltr:rounded-tr-md ltr:rounded-br-md rtl:rounded-tl-md rtl:rounded-bl-md ltr:pr-5 rtl:pl-5 py-3.5 text-gray-900 text-base ltr:text-right rtl:text-left">{{selectedAccountType =='gold-raw'?'Gold Raw':selectedAccountType == 'platinum-vip'?'Platinum VIP':selectedAccountType == 'gold-raw-is'?'Islamic':selectedAccountType.replace('-',' ').tCapitalizeFirstLetter()}}</td>
            </tr>
            <tr v-if="selectedAccountType == 'pamm-investor-pro' && selectedAccountType == 'pamm-investor-raw' && selectedAccountType == 'investor'" class="odd:bg-gray-100">
              <td class="ltr:rounded-tl-md ltr:rounded-bl-md rtl:rounded-tr-md rtl:rounded-br-md ltr:pl-5 rtl:pr-5 py-3.5 text-blue font-bold text-base">{{ $t('leverage').tTitle() }}</td>
              <td class="rounded-tr-md rounded-br-md ltr:pr-5 rtl:pl-5 py-3.5 text-gray-900 text-base ltr:text-right rtl:text-left">1:{{ selectedLeverage }}</td>
            </tr>
            <tr v-if="selectedType == 'demo'" class="odd:bg-gray-100">
              <td class="ltr:rounded-tl-md ltr:rounded-bl-md rtl:rounded-tr-md rtl:rounded-br-md ltr:pl-5 rtl:pr-5 py-3.5 text-blue font-bold text-base">{{ $t('starting_balance') }}</td>
              <td class="ltr:rounded-tr-md ltr:rounded-br-md rtl:rounded-tl-md rtl:rounded-bl-md ltr:pr-5 rtl:pl-5 py-3.5 text-gray-900 text-base ltr:text-right rtl:text-left">{{ selectedBalance.toLocaleString() }}</td>
            </tr>
            <tr v-if="selectedType == 'live' && selectedAccountType!='investor' && depositBonusesLength < 3 " class="odd:bg-gray-100">
              <td class="ltr:rounded-tl-md ltr:rounded-bl-md rtl:rounded-tr-md rtl:rounded-br-md ltr:pl-5 rtl:pr-5 py-3.5 text-blue font-bold text-base">{{ $t('receive_bonus') }}</td>
              <td class="ltr:rounded-tr-md ltr:rounded-br-md ltr:rounded-tl-md ltr:rounded-bl-md ltr:pr-5 rtl:pl-5 py-3.5 text-gray-900 text-base ltr:text-right rtl:text-left">{{ acceptBonusYN[acceptBonus].tTitle() }}</td>
            </tr>
          </table>
          <div v-show="showErrorSubmitMessage" class="text-sm text-red pb-3 md:pt-0 text-center w-full">{{ $t('error_message_create_account') }}</div>
          <button @click="createTradingAccount()" class="appearance-none bg-blue text-white text-base font-bold rounded-md h-12 w-56 text-center m-auto table mb-20">
            {{ $t('create_account').tTitle() }}
          </button>
        </div>

        <!-- Step 3 -->
        <!-- <div v-if="step==3">
          <div class="h-6 w-20 flex items-center justify-center gap-1.5 m-auto mb-24"></div>
          <div class="text-gray-900 text-[2.5rem] font-display font-bold text-center mb-4 leading-[2.9rem]">{{ $t('congratulations').tTitle() }}</div>
          <p class="text-gray-900 text-base text-center mb-12 max-w-md mx-auto">{{ $t('credentials_to_login_to_mt') }}</p>
          <div>
            <table class="border-0 border-collapse w-full md:w-[29rem] max-w-[29rem] m-auto mb-14">
              <tr class="odd:bg-gray-100">
                <td class="rounded-tl-md rounded-bl-md pl-5 py-3.5 text-blue font-bold text-base">{{ $t('login').tTitle() }}</td>
                <td class="rounded-tr-md rounded-br-md pr-5 py-3.5 text-gray-900 text-base text-right">{{TAID}}</td>
              </tr>
              <tr class="odd:bg-gray-100">
                <td class="rounded-tl-md rounded-bl-md pl-5 py-3.5 text-blue font-bold text-base">{{ $t('password').tTitle() }}</td>
                <td class="rounded-tr-md rounded-br-md pr-5 py-3.5 text-gray-900 text-base text-right">
                  <div class="flex justify-end items-center">
                    {{generatedTAPassword}}
                    <a
                      class="leading-[0] cursor-pointer table"
                      @click="copyTAPassword(generatedTAPassword)">
                      <img src="../assets/img/copy.svg" width="32" />
                    </a>
                  </div>
                </td>
              </tr>
              <tr class="odd:bg-gray-100">
                <td class="rounded-tl-md rounded-bl-md pl-5 py-3.5 text-blue font-bold text-base">{{ $t('server_name').tTitle() }}</td>
                <td class="rounded-tr-md rounded-br-md pr-5 py-3.5 text-gray-900 text-base text-right">{{serverName}}</td>
              </tr>
              <tr class="odd:bg-gray-100">
                <td class="rounded-tl-md rounded-bl-md pl-5 py-3.5 text-blue font-bold text-base">{{ $t('account_type').tTitle() }}</td>
                <td class="rounded-tr-md rounded-br-md pr-5 py-3.5 text-gray-900 text-base text-right">{{selectedAccountType =='gold-raw'?'Gold Raw':selectedAccountType == 'platinum-vip'?'Platinum VIP':selectedAccountType.replace('-',' ').tCapitalizeFirstLetter()}}</td>
              </tr>
            </table>
            <button @click="close()" class="appearance-none bg-blue text-white text-base font-bold rounded-md h-12 w-56 text-center m-auto table mb-20">
              {{ $t('continue').tTitle() }}
            </button>
          </div>
        </div> -->

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import config from '../config/'
import { service } from '@/main.js'
import links from '../config/links'

export default {
  data() {
    return {
      showStep1: true,
      // step: 1,
      selectedType: '',
      selectedPlatform: '',
      selectedAccountType: '',
      selectedCurrency: '',
      selectedLeverage: '',
      selectedBalance: '',
      acceptLPOA: false,
      showErrorMessage: false,
      showErrorSubmitMessage: false,
      acceptBonus: false,
      showBalances: true,
      currencies: config.currencies,
      websiteURL: config.URL.website,
      typesOfAccount: {},
      // serverName: '',
      // generatedTAPassword: '',
      // TAID: '',
      accountTypes: [
        {
          name: 'micro',
          display_name: 'MICRO',
        },
        {
          name: 'silver',
          display_name: 'SILVER',
        },
        {
          name: 'gold-raw',
          display_name: 'GOLD Raw',
        },
        {
          name: 'platinum-vip',
          display_name: 'PLATINUM VIP',
        },
      ],
      leverages: [
        {
          leverage: '100',
        },
        {
          leverage: '300',
        },
        {
          leverage: '500',
        },
      ],
      balances: [500, 1000, 3000, 5000, 20000, 50000, 100000],
      mt4: {
        currency: '',
        group: '',
        groupOptions: {},
        leverage: '',
        acceptBonus: false,
        balance: '',
      },
      mt5: {
        currency: '',
        group: '',
        groupOptions: {},
        leverage: '',
        acceptBonus: false,
        balance: '',
      },
      acceptBonusYN: {
        true: this.$t('yes'),
        false: this.$t('no'),
      },
      showLpoaError: false,
      restrictedBonusCountries: ['AS','UM','VG','VI','ZZ']
    }
  },
  watch: {
    selectedType() {
      // If selected type is Demo remove EUR selection
      if(this.selectedType == 'demo') {
        this.typesOfAccount = { USD: this.$getCurrencies()['USD'] }
        this.selectedCurrency = '';
      } else {
        this.typesOfAccount = this.$getCurrencies();
      }
      if (this.selectedPlatform && this.selectedCurrency) {
        this.selectedAccountType = ''
        this.getPlatformAccountGroups(this.selectedPlatform, this.selectedCurrency, this.selectedType)
      }
    },
    selectedCurrency() {
      if (this.selectedPlatform && this.selectedType) {
        this.selectedAccountType = ''
        this.getPlatformAccountGroups(this.selectedPlatform, this.selectedCurrency, this.selectedType)
      }
    },
    selectedAccountType() {
      this.getGroupLeverages(this.selectedAccountType)

      for (const g in this.accountTypes) {
        let group = this.accountTypes[g]

        if (group.name.tUpper() === this.selectedAccountType.tUpper()) {
          this.selectedLeverage = group.default_leverage
        }
      }
      if(this.selectedAccountType == 'pamm-investor-pro' || this.selectedAccountType == 'pamm-investor-raw' || this.selectedAccountType == 'investor'){
        this.selectedLeverage = 300;
      }
    },
    selectedPlatform() {
      if(this.selectedPlatform=='pamm' || this.selectedType=='demo'){
        this.typesOfAccount = { USD: this.$getCurrencies()['USD'] }
      }
      else{
        this.typesOfAccount = this.$getCurrencies()
      }
      if (this.selectedType && this.selectedCurrency) {
        this.selectedAccountType = ''
        this.getPlatformAccountGroups(this.selectedPlatform, this.selectedCurrency, this.selectedType)
      }
    },
  },
  computed: {
    optionsValid() {
      if (
        !this.selectedType ||
        !this.selectedPlatform ||
        !this.selectedAccountType ||
        !this.selectedCurrency ||
        !this.selectedLeverage ||
        (this.selectedType == 'demo' && !this.selectedBalance)
      ) {
        return false
      } else {
        return true
      }
    },
    depositBonusesLength() {
      return this.TA.filter(item => item.accept_bonus === 'yes').length
    },
     LPOA_URL() {
     return links.getLink('acceptTheLpoa', this.Language);
    },
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
      BonusConfig: 'bonusconfig/all',
      logoLoaderActive: 'logoLoader/active',
      bonusData: 'bonusconfig/bonusData',
      TA: 'user/TA',
    }),
  },
  mounted() {
    this.dragContainer('#accountTypesContainer')
    this.dragContainer('#leverageContainer')
    this.dragContainer('#balancesContainer')
    this.typesOfAccount = this.$getCurrencies()
  },
  methods: {
    selectTypeOfAc(showBl, acType) {
      this.showBalances = showBl
      this.selectedType = acType
    },
    selectPlatform(type) {
      this.selectedPlatform = type
    },
    selectCurrency(type) {
      this.selectedCurrency = type
    },
    selectAcType(type) {
      this.selectedAccountType = type
    },
    selectLeverage(type) {
      this.selectedLeverage = type
    },
    selectBalance(type) {
      this.selectedBalance = type
    },
    getPlatformAccountGroups(platform, currency, mode) {
      service
        .post('/account-types/list-filtered', {
          platform: platform,
          currency: currency,
          mode: mode,
        })
        .then(response => {
          if (response.data) {
            var accountTypes = response.data.account_types;
            if(currency=='EUR' && accountTypes){
              accountTypes = accountTypes.filter(acc => !acc.name.includes('pamm'))
            }
            // Remove Islamic account type when the users country is not from countriesWithIslamicAccountType.
            if(config.countriesWithIslamicAccountType.findIndex(country => country == this.User.info.country_code) === -1) {
              accountTypes = accountTypes.filter(acc => acc.display_name.toLowerCase() !== 'islamic')
            }
            accountTypes = accountTypes.filter(acc => !acc.name.includes('no-deposit-bonus') && !acc.name.includes('no-deposit'))
            // var hasNoDepositBonusTA = false
            // this.TA.forEach((ta) => {
            //   if(ta.account_type_identifier=='no-deposit-bonus'){
            //     hasNoDepositBonusTA = true
            //   }
            // })
            // if(hasNoDepositBonusTA){
            //   accountTypes = accountTypes.filter(acc => !acc.name.includes('no-deposit-bonus') && !acc.name.includes('no-deposit'))
            // }
            // if (this.restrictedBonusCountries.includes(this.User.info.country_code)) {
            //   accountTypes = accountTypes.filter(acc => !acc.name.includes('no-deposit-bonus') && !acc.name.includes('no-deposit'))
            // }
            // if(!this.restrictedBonusCountries.includes(this.User.info.country_code) && currency!='USD'){
            //   accountTypes = accountTypes.filter(acc => !acc.name.includes('no-deposit-bonus') && !acc.name.includes('no-deposit'))
            // }
            this.accountTypes = Object.assign({}, accountTypes)
            // console.log(this.accountTypes)
          }else{
            this.accountTypes = []
          }
        })
    },
    getGroupLeverages(group) {
      if (group) {
        service
          .post('/account-types/list-leverages', {
            name: group,
          })
          .then(response => {
            if (response.data) {
              this.leverages = Object.assign({}, response.data.leverages)
            }
          })
      } else {
        // this.leverages = {}
        this.selectedLeverage = ''
      }
    },
    gotoStep2() {
      this.showLpoaError = false
      if(this.selectedAccountType=='investor' && !this.acceptLPOA){
        this.showLpoaError = true
        return
      }
      this.showStep1 = !this.showStep1
    },
    createTradingAccount() {
      this.showErrorSubmitMessage = false
      if (
        !this.selectedType ||
        !this.selectedPlatform ||
        !this.selectedAccountType ||
        !this.selectedCurrency ||
        !this.selectedLeverage ||
        (this.selectedType == 'demo' && !this.selectedBalance)
      ) {
        this.showErrorSubmitMessage = true
        return
      }
      this['logoLoader/set'](true)

      if (this.selectedType == 'demo') {
        service
          .post('/ta/create-ta-mt', {
            userID: this.User.info.ID,
            platform: this.selectedPlatform,
            accountType: this.selectedType,
            currency: this.selectedCurrency,
            accountGroup: this.selectedAccountType,
            leverage: parseInt(this.selectedLeverage),
            demoBalance: parseFloat(this.selectedBalance),
          })
          .then(response => {
            this['logoLoader/set'](false)
            if (response.data.success) {
              this.$toasted.success(this.$t('new_trading_account_message'))
              // this.step ++
              // if(this.selectedPlatform=='mt4'){
              //   this.serverName = 'PlexyTrade-Demo'
              // }
              // else if(this.selectedPlatform=='mt5'){
              //   this.serverName = 'PlexyTrade-Server01'
              // }
              // this.generatedTAPassword = response.data.messageID
              // this.TAID = response.data.message
              this.$toasted.success(this.$t('password_generated_for_ta'), { duration: 10000 })
              this.close()
              this.complete()
              // this.$router.push('/' + (this.Language || 'en') + '/trading-account')
            }
          })
      } else if (this.selectedType == 'live') {
        if (this.selectedAccountType === 'pamm-investor-pro' || this.selectedAccountType === 'pamm-investor-raw' || this.selectedAccountType === 'investor') {
          this.acceptBonus = false
        }

        service
          .post('/ta/create-ta-mt', {
            userID: this.User.info.ID,
            platform: this.selectedPlatform,
            accountType: this.selectedType,
            currency: this.selectedCurrency,
            accountGroup: this.selectedAccountType,
            leverage: parseInt(this.selectedLeverage),
            acceptBonus: this.acceptBonusYN[this.acceptBonus],
          })
          .then(response => {
            this['logoLoader/set'](false)
            if (response.data.success) {
              this.$toasted.success(this.$t('new_trading_account_message'))
              if(this.selectedAccountType!='investor'){
                this.$toasted.success(this.$t('password_generated_for_ta'), { duration: 10000 })
              }
              // if(this.selectedAccountType=='investor') {
              //   this.close()
              //   this.complete()
              //   return
              // }
              // this.step ++
              // if(this.selectedPlatform=='mt4'){
              //   this.serverName = 'PlexyTrade-Live'
              // }
              // else if(this.selectedPlatform=='mt5'){
              //   this.serverName = 'PlexyTrade-Server01'
              // }
              // this.generatedTAPassword = response.data.messageID
              // this.TAID = response.data.message
              this.close()
              this.complete()
              // this.$router.push('/' + (this.Language || 'en') + '/trading-account')
            } else {
              if (response.data.message) {
                this.$toasted.error(response.data.message, { duration: 10000 })
              }
            }
          })
          .catch(error => {
            this['logoLoader/set'](false)
            this.$toasted.error(this.$t('failed_account_creating_message') + error.response.data.detail, { duration: 10000 })
          })
      }
    },
    initializeValues() {
      this.selectedType = ''
      this.selectedPlatform = ''
      this.selectedAccountType = ''
      this.selectedLeverage = ''
      this.selectedBalance = ''
      this.selectedCurrency = ''
      this.acceptBonus = false
      this.showStep1 = true
      this.step = 1
      this.serverName = ''
      this.generatedTAPassword= ''
      this.TAID = ''
      this.showErrorMessage = false
      this.showErrorSubmitMessage = false
      this.showBalances = true
      this.acceptLPOA = false

      this.accountTypes = [
        {
          name: 'micro',
          display_name: 'MICRO',
        },
        {
          name: 'silver',
          display_name: 'SILVER',
        },
        {
          name: 'gold-raw',
          display_name: 'GOLD Raw',
        },
        {
          name: 'platinum-vip',
          display_name: 'PLATINUM VIP',
        },
      ]

      this.leverages = [
        {
          leverage: '100',
        },
        {
          leverage: '300',
        },
        {
          leverage: '500',
        },
      ]
    },
    close() {
      document.querySelector('body').classList.remove('overflow-hidden')
      this.initializeValues()
      this.$emit('close')
      this.$emit('complete')
    },
    complete() {
      this.$emit('complete')
    },
    copyTAPassword(val) {
        const el = document.createElement('textarea');
        el.value = val;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
          document.getSelection().removeAllRanges();
          document.getSelection().addRange(selected);
        }
        this.$toasted.success(this.$t('Copied'))
    },
    ...mapActions(['logoLoader/set']),
  },
}
</script>
<style>
#accountTypesContainer::-webkit-scrollbar,
#balancesContainer::-webkit-scrollbar,
#leverageContainer::-webkit-scrollbar {
  display: none;
}
#accountTypesContainer,
#balancesContainer,
#leverageContainer {
  scroll-behavior: smooth;
}

#accountTypesContainer.active,
#balancesContainer.active,
#leverageContainer.active {
  scroll-behavior: auto;
}

#acceptBonus:checked + svg {
  opacity: 1;
}

#CAcontainer::-webkit-scrollbar {
  width: 4px;
}
/* Track */
#CAcontainer::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 12px;
}

#CAcontainer::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 12px;
}

/* Handle */
#CAcontainer::-webkit-scrollbar-thumb {
  background: #2b63e3;
  border-radius: 12px;
}
</style>
