<template>
  <div class="content has-text-centered">
    <failed></failed>
  </div>
</template>

<script>
import Failed from './Failed'

export default {
  components: {
    Failed,
  },
  data() {
    return {
      title: '',
    }
  },
  mounted() {
    this.title = this.$t('method_deposit_status', { method: 'Crypto' })
    this.$bus.$emit('ChangeHeadings', this.title, 'Crypto')
  },
  methods: {},
}
</script>
