<template>
  <div class="content has-text-centered">
    <p>{{$t("my_card_desc")}}</p>

    <div class="flex md:flex-wrap mt-4 md:mt-6 gap-8 overflow-x-scroll md:overflow-hidden">
      <div
        v-for="(c, i) in cards"
        :key="i"
        class="min-w-[320px]"
      >
        <div class=" w-full h-[200px] mb-5 bg-blue rounded-xl text-white px-6 pt-4 bg-gradient-to-br from-blue-400 via-blue-900 to-blue-500">
          <div class="pb-12 w-2/3">
            <img src="../../assets/img/plexytrade-logo-white.svg" />
          </div>
          <div class="text-sm">{{ $t('card_no').tTitle() }}</div>
          <div class="font-bold text-lg">{{ splitCardNo(c.card_no) }}</div>
        </div>
        <div class="flex border border-gray-300 rounded-lg">
          <div class="w-1/2 border-r border-gray-300 p-4">
            <div>{{ $t('last_used').tTitle() }}</div>
            <div class="font-bold">{{ $formatDate(c.last_used )}}</div>
          </div>
          <div class="w-1/2 p-4">
            <div>{{ $t('status').tTitle() }}</div>
            <div
              class="font-bold"
              :class="{'text-red' : c.status == 'ban', 'text-green': c.status == 'accept'}"
            >{{ status[c.status].tTitle() }}</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { service } from '@/main.js'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      cards: {},
      status: {
        ban: 'Declined',
        accept: 'Approved',
        pending: 'Pending',
      },
    }
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
    }),
  },
  mounted() {
    this.getCardStatus()
  },
  methods: {
    getCardStatus() {
      service
        .post('/finance/card-get-status', {
          userID: this.User.info.ID,
        })
        .then(response => {
          this.cards = response.data.list
        })
    },
    splitCardNo(number) {
      if (number.length != 16) {
        return number
      }

      return number.replace(/.{4}/g, '$& ')
    },
  },
}
</script>
