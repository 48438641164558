<template>
  <div class="h-auto ltr:pr-2 rtl:pl-2 lg:px-0 md:pt-6 pb-20">
    <div class="w-full bg-white rounded-md shadow-ts1 mb-24" id="taContainer">
      <div class="px-6 py-5 border-b border-gray-400">
        <h2 class="font-display text-xl font-bold text-blue leading-none">{{ $t('account_list').tTitle() }}</h2>
      </div>

      <div class="flex flex-wrap justify-between p-6">
        <div class="w-auto inline-block relative order-1">
          <select
            v-model="selectedPlatform"
            name="platform"
            class="rtl:bg-left bg-none ltr:pl-4 rtl:pr-4 ltr:pr-12 rtl:pl-12 py-3 bg-white text-blue border border-gray-300 border-solid rounded-md text-base font-bold focus:border focus:border-gray-300 focus:border-solid focus:outline-none focus:ring-0 cursor-pointer"
          >
            <option value="all">{{ $t('platform').tTitle() }}</option>
            <option value="mt4">MT4</option>
            <option value="mt5">MT5</option>
            <option value="pamm">PAMM</option>
          </select>
          <div class="select-arrow">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>

        <div class="flex bg-gray-400 w-full lg:w-[370px] h-[42px] rounded-3xl text-gray-200 m-1 order-last lg:order-2 mt-12 lg:mt-1">
          <div
            @click="selectedAccountType = 'all'"
            class="w-1/3 text-center leading-10 cursor-pointer"
            :class="[selectedAccountType == 'all' ? 'text-white text-bold bg-blue rounded-3xl' : 'text-gray-900']"
          >
            {{ $t('all') }}
          </div>
          <div
            @click="selectedAccountType = 'live'"
            class="w-1/3 text-center leading-10 cursor-pointer"
            :class="[selectedAccountType == 'live' ? 'text-white text-bold bg-blue rounded-3xl' : 'text-gray-900']"
          >
            {{ $t('live') }}
          </div>
          <div
            @click="selectedAccountType = 'demo'"
            class="w-1/3 text-center leading-10 cursor-pointer"
            :class="[selectedAccountType == 'demo' ? 'text-white text-bold bg-blue rounded-3xl' : 'text-gray-900']"
          >
            {{ $t('demo') }}
          </div>
        </div>
        <div class="flex gap-4 order-3 items-center">
          <div class="w-20 h-7 border border-gray-300 border-solid rounded-[21px] hidden lg:flex">
            <div
              @click="selectedView = 'list'"
              :class="[selectedView == 'list' ? 'bg-blue bg-opacity-15' : '']"
              class="w-1/2 flex flex-col justify-center items-center ltr:rounded-l-[21px] rtl:rounded-r-[21px] cursor-pointer"
            >
              <span :class="[selectedView == 'list' ? 'bg-blue' : 'bg-[#C9C9C9]']" class="h-[1px] w-4 mb-1 table"></span>
              <span :class="[selectedView == 'list' ? 'bg-blue' : 'bg-[#C9C9C9]']" class="h-[1px] w-4 mb-1 table"></span>
              <span :class="[selectedView == 'list' ? 'bg-blue' : 'bg-[#C9C9C9]']" class="h-[1px] w-4 table"></span>
            </div>
            <div
              @click="selectedView = 'grid'"
              :class="[selectedView == 'grid' ? 'bg-blue bg-opacity-15' : '']"
              class="w-1/2 flex flex-col justify-center items-center ltr:rounded-r-[21px] rtl:rounded-l-[21px] cursor-pointer"
            >
              <div class="flex gap-0.5 mb-0.5">
                <div :class="[selectedView == 'grid' ? 'border-blue' : 'border-[#C9C9C9]']" class="w-[7px] h-[7px] rounded-[2px] border border-solid"></div>
                <div :class="[selectedView == 'grid' ? 'border-blue' : 'border-[#C9C9C9]']" class="w-[7px] h-[7px] rounded-[2px] border border-solid"></div>
              </div>
              <div class="flex gap-0.5">
                <div :class="[selectedView == 'grid' ? 'border-blue' : 'border-[#C9C9C9]']" class="w-[7px] h-[7px] rounded-[2px] border border-solid"></div>
                <div :class="[selectedView == 'grid' ? 'border-blue' : 'border-[#C9C9C9]']" class="w-[7px] h-[7px] rounded-[2px] border border-solid"></div>
              </div>
            </div>
          </div>
          <div @click="showCAModal()" class="bg-blue w-[155px] h-[50px] rounded-md text-white text-center leading-10 p-1 font-bold cursor-pointer order-3">
            {{ $t('create_account') }}
          </div>
        </div>
      </div>

      <div v-if="selectedView == 'grid'" class="flex flex-wrap justify-center lg:justify-start ltr:lg:ml-6 rtl:lg:mr-6 pb-3.5">
        <div
          v-for="item in filteredTradingAccounts"
          :key="item.external_id"
          :id="item.external_id"
          class="w-80 flex-shrink-0 mx-[11px] ltr:lg:ml-0 rtl:lg:mr-0 ltr:lg:mr-[23px] rtl:lg:ml-[23px] mb-[23px] pt-2.5 rounded-lg border border-gray-300 border-solid"
        >
          <div class="p-4 relative noUserSelect" :class="[item.account_type=='' ||  item.account_type.toLowerCase()=='archived'?'opacity-60':'']">
            <div
              v-if="item.account_type.toLowerCase().includes('slave of')"
              class="absolute -top-5 right-4 h-5 px-2.5 flex justify-center items-center bg-[#F0F3FF] border border-[#D3D3D3] border-solid rounded-xl text-[9px] text-gray-900 font-medium">
                {{item.account_type.toLowerCase().replace('slave of',$t('attached_to'))}}
            </div>
            <div class="flex mb-4">
               <img v-if="getItemImage(item)" class="h-[60px] w-[60px] m-auto" :src="getItemImage(item)" />
              <div class="w-full">
                <div class="flex justify-between items-center">
                  <div :class="[item.type == 'live' ? 'bg-green' : 'bg-gray-900']" class="h-5 text-white text-xs font-medium rounded-xl px-5 ltr:ml-4 rtl:mr-4 pt-0.5 inline-block">
                    {{ item.type.tTitle() }}
                  </div>
                  <div
                    v-if="item.account_type != '' && item.account_type.toLowerCase() != 'archived'"
                    @click="showActionsTooltip(item.external_id)"
                    class="tooltip-icon h-7 w-7 bg-white hover:bg-gray-400 text-center rounded-full transition-all duration-300 ease-in-out text-2xl text-gray-900 leading-4 cursor-pointer"
                  >
                    ...
                  </div>
                </div>
                <p class="text-gray-900 font-bold text-xl ltr:ml-4 rtl:mr-4">{{ item.external_id }}</p>
                <p class="text-gray-900 text-xs ltr:ml-4 rtl:mr-4 font-medium">
                  {{
                    item.account_type == ''
                      ? $t('archived')
                      : item.account_type_identifier == 'silver-islamic'
                      ? 'Islamic'
                      : item.account_type_identifier == 'pamm-master'
                      ? 'Master'
                      : item.account_type_identifier == 'pamm-investor'
                      ? 'Investor'
                      : item.account_type
                  }}
                  / {{ item.platform == 'mt4' ? 'MetaTrader 4' : item.platform == 'mt5' ? 'MetaTrader 5' : 'PAMM' }}
                </p>
              </div>
            </div>
            <div class="w-full rounded-lg bg-blue bg-opacity-15 px-4 py-1.5 mb-3.5">
              <p class="text-gray-900 text-sm leading-6 font-medium">{{ $t('account_balance').tTitle() }}</p>
              <p class="text-blue font-bold text-4xl mt-[-3px]">{{ $formatMoney(item.balance, currencySymbols[item.currency]) }}</p>
            </div>
            <div class="w-full flex space-x-3.5 rtl:space-x-reverse">
              <div class="w-1/2 rounded-lg bg-blue bg-opacity-15">
                <p class="text-gray-900 text-sm leading-6 font-medium pt-1 ltr:pl-4 rtl:pr-4">{{ $t('equity').tTitle() }}</p>
                <p class="text-blue font-medium text-base ltr:pl-4 rtl:pr-4 pb-1 leading-6">{{ $formatMoney(item.equity, currencySymbols[item.currency]) }}</p>
              </div>
              <div class="w-1/2 rounded-lg bg-blue bg-opacity-15">
                <p class="text-gray-900 text-sm leading-6 font-medium pt-1 ltr:pl-4 rtl:pr-4">{{ $t('leverage').tTitle() }}</p>
                <p class="text-blue font-medium text-base ltr:pl-4 rtl:pr-4 pb-1 leading-6">1:{{ item.leverage }}</p>
              </div>
            </div>
            <div :class="[item.accept_bonus == 'no' ? 'bg-[#e0e1e4]' : 'bg-[#def4ec]']" class="w-full rounded-lg flex gap-1 px-4 py-2 mt-3.5">
              <p class="text-gray-900 text-sm font-semibold">{{ $t('bonus_120') }}:</p>
              <p class="text-gray-900 font-medium text-sm">{{ item.accept_bonus == 'no' ? $t('no_bonus') : getTABonus(item.external_id) }}</p>
            </div>
            <div
              class="w-full mt-3.5">
              <button
                v-if="item.transfer_locked == 'yes' && item.transfer_account_external_id!=''"
                class="h-[30px] w-full  rounded-md flex justify-center items-center text-white font-bold text-sm"
                :class="[item.account_type=='' || item.account_type.toLowerCase()=='archived'?'bg-gray-900 bg-opacity-25 pointer-events-none':'bg-green']"
                @click="openTransferBackModal(item.external_id, item.equity)"
              >{{ $t('transfer').tTitle() }}</button>
              <router-link
                v-else
                :to="'/' + Language + '/finance'"
                :class="[item.account_type == '' || item.account_type.toLowerCase() == 'archived' ? 'bg-gray-900 bg-opacity-25 pointer-events-none' : 'bg-green']"
                class="h-[30px] w-full rounded-md flex justify-center items-center text-white font-bold text-sm"
              >
                {{ $t('deposit').tTitle() }}
              </router-link>
            </div>

            <div
              v-if="item.account_type != '' && item.account_type.toLowerCase() != 'archived'"
              class="actions-tooltip absolute top-[3.4rem] ltr:right-6 rtl:left-6 bg-white border border-gray-300 border-solid shadow-ts1 rounded-lg px-3 py-5 w-[215px] hidden"
            >
              <a
                v-if="item.account_type_identifier != 'pamm-investor'"
                @click=";(changeTAPassword.TAID = item.external_id), (changeTAPassword.modalActive = true)"
                class="w-full hover:bg-blue rounded-md text-gray-900 hover:text-white text-center appearance-none py-2 text-base leading-6 mb-2 table cursor-pointer"
                >{{ $t('change_password').tTitle() }}</a
              >
              <router-link
                v-if="item.account_type_identifier != 'pamm-investor'"
                :to="'/' + Language + '/trading-account/download'"
                class="w-full hover:bg-blue rounded-md text-gray-900 hover:text-white text-center appearance-none py-2 text-base leading-6 mb-2 table"
                >{{ $t('download').tTitle() }}</router-link
              >
              <a
                v-if="item.platform == 'mt4' || item.platform == 'mt5'"
                :href="[item.platform == 'mt4' ? mt4WebTraderLinks : mt5WebTraderLinks]"
                target="_blank"
                class="w-full hover:bg-blue rounded-md text-gray-900 hover:text-white text-center appearance-none py-2 text-base leading-6 mb-2 table"
                >{{ $t('launch_webtrader').tTitle() }}</a
              >
              <button
                @click="$refs.viewTradesModal.openModal(item.external_id, item.platform, item.type)"
                class="w-full hover:bg-blue rounded-md text-gray-900 hover:text-white text-center appearance-none py-2 text-base leading-6 table cursor-pointer"
              >
                {{ $t('view_trades').tTitle() }}
              </button>
              <!-- <a
                v-if="item.account_type_identifier=='pamm-investor' || item.account_type_identifier=='pamm-master'"
                href="https://pamm.plexytrade.com/"
                target="_blank"
                class="w-full hover:bg-blue rounded-md text-gray-900 hover:text-white text-center appearance-none py-2 text-base leading-6 mt-2 table"
              >{{$t('pamm_dashboard').tTitle()}}</a> -->
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="selectedView=='list'" class="hidden md:block mx-6 overflow-auto pb-6 lg:pb-[25px]" id="taListContainer">
        <table cellpadding="0" cellspacing="0" class="w-full border-separate min-w-[1100px] whitespace-nowrap" style="border-spacing:0 16px;">
          <tr
            v-for="item in filteredTradingAccounts"
            :key="item.external_id"
            :id="item.external_id"
            class="w-full h-20"
            :class="[item.account_type == '' || item.account_type.toLowerCase() == 'archived' ? 'opacity-60' : '']"
          >
            <td class="w-[88px] pl-7  border-y ltr:border-l rtl:border-r border-gray-300 border-solid ltr:rounded-l-lg rtl:rounded-r-lg">
              <img v-if="getItemImage(item)" class="h-[60px] w-[60px] m-auto" :src="getItemImage(item)" />
            </td>
            <td class="border-y border-gray-300 border-solid  text-center max-w-[200px]">
              <div
                v-if="item.account_type.toLowerCase().includes('slave of')"
                class="absolute -top-2.5 left-3 h-5 px-2.5 flex justify-center items-center bg-[#F0F3FF] border border-[#D3D3D3] border-solid rounded-xl text-[9px] text-gray-900 font-medium">
                  {{item.account_type.toLowerCase().replace('slave of',$t('attached_to'))}}
              </div>
              <p class="text-gray-900 font-bold text-xl leading-5 text-center">
                {{
                  item.account_type == ''
                    ? $t('archived')
                    : item.account_type_identifier == 'silver-islamic'
                    ? 'Islamic'
                    : item.account_type_identifier == 'pamm-master'
                    ? 'Master'
                    : item.account_type_identifier == 'pamm-investor'
                    ? 'Investor'
                    : item.account_type
                }}
              </p>
              <div :class="[item.type == 'live' ? 'bg-green' : 'bg-gray-900']" class="h-5 text-white text-xs font-medium rounded-xl px-5 pt-0.5 inline-block ">
                {{ item.type.tTitle() }}
              </div>
            </td>
            <td class="h-full bg-[#F7F7F9] ltr:border-l rtl:border-r border-gray-300 border-solid border-y text-center">
              <div class="h-full flex flex-col items-center justify-center">
                <p class="text-sm font-medium text-gray-900">{{ $t('platform').tTitle() }}</p>
                <p class="text-base text-blue font-semibold">{{ item.platform.tUpper() }}</p>
              </div>
            </td>
            <td class="h-full bg-[#F7F7F9] ltr:border-r rtl:border-r border-gray-300 border-solid border-y text-center">
              <div class="h-full flex flex-col items-center justify-center">
                <p class="text-sm font-medium text-gray-900">{{ $t('account_number') }}</p>
                <p class="text-base text-blue font-semibold">{{ item.external_id }}</p>
              </div>
            </td>
            <td class="h-full border-y border-gray-300 border-solid text-center">
              <div class="h-full flex flex-col justify-center items-center">
                <div class="flex gap-1">
                  <p class="text-sm font-medium text-gray-900">{{ $t('bonus').tTitle() }}</p>
                  <div :class="[item.account_type.toLowerCase() == 'archived' ? 'pointer-events-none' : '']" class="cursor-pointer group relative flex-shrink-0">
                    <img src="../../assets/img/info.svg" width="15" />
                    <div
                      class="h-[66px] w-[215px] absolute bottom-[-332%] bg-white shadow-ts3 hidden group-hover:flex justify-center items-center rounded-lg border border-gray-300 border-solid"
                    >
                      {{ $t('cash_bonus_120') }}
                    </div>
                  </div>
                </div>
                <p class="text-base text-blue font-semibold">{{ item.accept_bonus == 'no' ? $t('no_bonus') : getTABonus(item.external_id) }}</p>
              </div>
            </td>
            <td class="h-full border-y border-gray-300 border-solid text-center">
              <div class="h-full flex flex-col justify-center">
                <p class="text-sm font-medium text-gray-900">{{ $t('leverage').tTitle() }}</p>
                <p class="text-base text-blue font-semibold">1:{{ item.leverage }}</p>
              </div>
            </td>
            <td class="h-full border-y border-gray-300 border-solid text-center">
              <div class="h-full flex flex-col justify-center">
                <p class="text-sm font-medium text-gray-900">{{ $t('equity').tTitle() }}</p>
                <p class="text-base text-blue font-semibold">{{ $formatMoney(item.equity, currencySymbols[item.currency]) }}</p>
              </div>
            </td>
            <td class="h-full bg-blue bg-opacity-15 ltr:border-l rtl:border-r border-gray-300 border-solid border-y text-center">
              <div class="h-full flex flex-col justify-center">
                <p class="text-sm font-medium text-gray-900">{{ $t('account_balance').tTitle() }}</p>
                <p class="text-4xl leading-10 text-blue font-bold">{{ $formatMoney(item.balance, currencySymbols[item.currency]) }}</p>
              </div>
            </td>
            <td class="h-full w-[100px] pr-7 bg-blue bg-opacity-15 border-y border-gray-300 border-solid">
              <button
                v-if="item.transfer_locked == 'yes' && item.transfer_account_external_id!='' && item.account_type!='' && item.account_type.toLowerCase()!='archived'"
                :class="[item.account_type=='' || item.account_type.toLowerCase()=='archived'?'bg-gray-900 bg-opacity-25 pointer-events-none':'bg-green']"
                class="h-[30px] w-full  rounded-md flex justify-center items-center text-white font-bold text-sm"
                @click="openTransferBackModal(item.external_id, item.equity)"
              >{{ $t('transfer').tTitle() }}</button>
              <router-link
                v-else-if="item.account_type!='' && item.account_type.toLowerCase()!='archived'"
                :to="'/' + Language + '/finance'"
                class="h-[30px] w-[100px] bg-green rounded-md flex justify-center items-center text-white font-bold text-sm m-auto"
              >
                {{ $t('deposit').tTitle() }}
              </router-link>
            </td>
            <td class="h-full bg-blue bg-opacity-15 border-y ltr:border-r rtl:border-l border-gray-300 border-solid ltr:rounded-r-lg rtl:rounded-l-lg text-center m-auto">
              <div class="relative">
                <div
                    v-if="item.account_type!='' && item.account_type.toLowerCase()!='archived'"
                    @click="showActionsTooltip(item.external_id)"
                    class="tooltip-icon h-7 w-7 border border-gray-600 text-gray-600 text-[27px] pl-[1px] pb-[16px] border-solid bg-white rounded-full flex justify-center items-center cursor-pointer p-auto"
                  >...</div>
                <div v-if="item.account_type!='' && item.account_type.toLowerCase()!='archived'" :id="item.external_id" class="actions-tooltip absolute top-[2rem] z-[1] right-0 bg-white border border-gray-300 border-solid shadow-ts1 rounded-lg px-3 py-5 w-[215px] flex flex-col hidden">
                  <a
                    v-if="item.account_type_identifier != 'pamm-investor'"
                    @click=";(changeTAPassword.TAID = item.external_id), (changeTAPassword.modalActive = true)"
                    class="w-full hover:bg-blue rounded-md text-gray-900 hover:text-white text-center appearance-none py-2 text-base leading-6 mb-2 inline-block cursor-pointer"
                    >{{ $t('change_password').tTitle() }}</a
                  >
                  <router-link
                    v-if="item.account_type_identifier != 'pamm-investor'"
                    :to="'/' + Language + '/trading-account/download'"
                    class="w-full hover:bg-blue rounded-md text-gray-900 hover:text-white text-center appearance-none py-2 text-base leading-6 mb-2 inline-block"
                    >{{ $t('download').tTitle() }}</router-link
                  >
                  <a
                    v-if="item.platform == 'mt4' || item.platform == 'mt5'"
                    :href="[item.platform == 'mt4' ? mt4WebTraderLinks : mt5WebTraderLinks]"
                    target="_blank"
                    class="w-full hover:bg-blue rounded-md text-gray-900 hover:text-white text-center appearance-none py-2 text-base leading-6 mb-2 inline-block"
                    >{{ $t('launch_webtrader').tTitle() }}</a
                  >
                  <button
                    @click="$refs.viewTradesModal.openModal(item.external_id, item.platform, item.type)"
                    class="w-full hover:bg-blue rounded-md text-gray-900 hover:text-white text-center appearance-none py-2 text-base leading-6 inline-block cursor-pointer"
                  >
                    {{ $t('view_trades').tTitle() }}
                  </button>
                  <!-- <a
                    v-if="item.account_type_identifier=='pamm-investor' || item.account_type_identifier=='pamm-master'"
                    href="https://pamm.plexytrade.com/"
                    target="_blank"
                    class="w-full hover:bg-blue rounded-md text-gray-900 hover:text-white text-center appearance-none py-2 text-base leading-6 mt-2 table"
                  >{{$t('pamm_dashboard').tTitle()}}</a> -->
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <change-password-modal v-if="changeTAPassword.modalActive" :TAPasswordData="changeTAPassword"></change-password-modal>

    <create-account-modal
      @close="closeModal"
      @complete="refreshTAs"
      v-show="showCreateAccountModal"
    ></create-account-modal>

    <transfer-back-modal
        v-if="transferBack.modalActive"
      :transferBackData="transferBack"
      @complete="refreshTAs">
    </transfer-back-modal>

    <view-trades-modal ref="viewTradesModal"></view-trades-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { service } from '../../main.js'
import config from '../../config'
import api from '../../api'
import moment from 'moment'
import ViewTradesModal from '../../components/ViewTradesModal.vue'
import ChangePasswordModal from '../../components/ChangePasswordModal.vue'
import CreateAccountModal from '../../components/CreateAccountModal.vue'
import TransferBackModal from '../../components/TransferBackModal.vue'
import links from '@/config/links.js'

export default {
  components: {
    ViewTradesModal,
    ChangePasswordModal,
    CreateAccountModal,
    TransferBackModal
  },
  props: ['platformImages'],
  data() {
    return {
      filteredTradingAccounts: [],
      selectedPlatform: 'all',
      selectedAccountType: 'all',
      active: false,
      showCreateAccountModal: false,
      name: 'Account list',
      tabsActive: 'openTrades',
      dateFrom: moment(moment().subtract(6, 'months')).format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD'),
      prevFrom: moment(moment().subtract(6, 'months')).format('YYYY-MM-DD'),
      prevTo: moment().format('YYYY-MM-DD'),
      tradesModalTAID: 0,
      tradesModalPlatform: '',
      tradesModalType: '',
      trades: [],
      prevTrades: [],
      sums: [],
      prevSums: [],
      translations: {
        text: {
          currentPassword: this.$t('current_password').tTitle(),
          newPassword: this.$t('new_password').tTitle(),
          newPasswordConfirm: this.$t('confirm_new_password').tTitle(),
        },
      },
      changeTAPassword: {
        modalActive: false,
        new: '',
        newConfirm: '',
        TAID: null,
      },
      transferBack: {
        modalActive: false,
        amount: 0,
        equity: 0,
        TAID: 0,
      },
      currencySymbols: config.currencies,
      websiteURL: config.URL.website,
      selectedView: 'list',
    }
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
      User: 'user/all',
      Requests: 'requests/all',
      logoLoaderActive: 'logoLoader/active',
      UserInfo: 'user/info',
      Bonuses: 'bonusconfig/bonusData',
    }),
     mt4WebTraderLinks(){
     return links.getLink('mt4WebTrader', this.Language);
    },
    mt5WebTraderLinks(){
     return links.getLink('mt5WebTrader', this.Language);
    }
  },
  watch: {
    selectedPlatform() {
      this.filterAccounts()
    },
    selectedAccountType() {
      this.filterAccounts()
    },
    tabsActive() {
      this.changeTab()
    },
    'User.info'(u) {
      if (u.ID) {
        api.GetTradingAccounts().then(() => {
          this.filterAccounts()
        })
      }
    },
    'changeTAPassword.modalActive'(v) {
      if (v === true) {
        this.disableScroll()
      } else {
        this.enableScroll()
      }
    },
    showCreateAccountModal(value) {
      if (value === true) {
        this.disableScroll()
      } else {
        this.enableScroll()
      }
    },
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },
  },
  created() {
    window.addEventListener('resize', this.setDefaultView)
    this.filterAccounts()
  },
  mounted() {
    // set selectedView as Grid when the device is mobile.
    this.setDefaultView()

    this.$bus.$on('AppClick', this.onAppClick)
    // // load TA
    // if (typeof this.User.info.ID !== 'undefined') {
    //   this['logoLoader/set'](true)
    //   api.GetTradingAccounts().then(() => {
    //     this.filterAccounts()
    //     this['logoLoader/set'](false)
    //   })
    // }
    this['logoLoader/set'](true)
    api
      .GetTradingAccounts()
      .then(() => {
        this.filterAccounts()
        this['logoLoader/set'](false)
      })
      .catch(() => {
        this['logoLoader/set'](false)
      })

    document.addEventListener('click', function (e) {
      var tooltips = document.getElementsByClassName('actions-tooltip')
      var tooltipIcons = document.getElementsByClassName('tooltip-icon')

      for (let i = 0; i < tooltips.length; i++) {
        if (e.target != tooltips[i] && e.target != tooltipIcons[i]) {
          tooltips[i].classList.add('hidden')
        }
      }
    })
    this.dragContainer('#taListContainer')
  },
  beforeDestroy() {
    this.$bus.$off('AppClick', this.onAppClick)
    // this.$parent.$el.removeEventListener('click', this.onAppClick)
  },
  methods: {
    filterAccounts() {
      this.filteredTradingAccounts = []
      for (let i = 0; i < this.User.TA.length; i++) {
        if (
          (this.User.TA[i].platform === this.selectedPlatform || this.selectedPlatform === 'all') &&
          (this.User.TA[i].type === this.selectedAccountType || this.selectedAccountType === 'all') &&
          (this.User.TA[i].platform!='transfer')
        ) {
          this.filteredTradingAccounts.push(this.User.TA[i])
        }
      }
    },
    showActionsTooltip(ta) {
      let tooltips = document.getElementsByClassName('actions-tooltip')
      let clickedTA = document.getElementById(ta)
      let selectedTooltip = clickedTA.getElementsByClassName('actions-tooltip')[0]

      for (let i = 0; i < tooltips.length; i++) {
        tooltips[i].id != ta ? tooltips[i].classList.add('hidden') : null;
      }
      selectedTooltip.classList.toggle('hidden')
    },
    openTransferBackModal(TAID, equity) {
      this.transferBack.modalActive = true
      this.transferBack.TAID = TAID
      this.transferBack.equity = equity
    },
    changeTAPasswordReq() {
      // validate
      this['logoLoader/set'](true)
      this.$validator
        .validateAll('form-changePassword')
        .then(result => {
          if (!result) {
            this['logoLoader/set'](false)
          } else {
            service
              .post('/ta/change-password', {
                TAID: this.changeTAPassword.TAID,
                userID: this.User.info.ID,
                password: this.changeTAPassword.new,
                passwordConfirm: this.changeTAPassword.newConfirm,
              })
              .then(() => {
                this.closeChangePasswordModal()
                this['logoLoader/set'](false)

                this.$toasted.success(this.$t('success'), { duration: 10000 })
              })
              .catch(error => {
                this.closeChangePasswordModal()
                this['logoLoader/set'](false)

                if (typeof error.response.data !== 'undefined') {
                  this.$toasted.error(error.response.data.detail + '<span class="error-id">' + error.response.data.id + '</span>', { duration: 10000 })
                }
              })
          }
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    },
    getTABonus(ta) {
      for (let i = 0; i < this.Bonuses.length; i++) {
        if (this.Bonuses[i].trading_account === ta) {
          return this.Bonuses[i].bonusAmount
        }
      }
      return 0
    },
    preSelecteDepositTA() {
      // might want to pre-select psp here
      this.$router.push('/' + this.Language + '/finance')
    },
    changeTab() {
      let tempFrom, tempTo, tempTrades, tempSums

      tempFrom = this.dateFrom
      tempTo = this.dateTo
      tempTrades = this.trades
      tempSums = this.sums

      this.dateFrom = this.prevFrom
      this.dateTo = this.prevTo
      this.trades = this.prevTrades
      this.sums = this.prevSums

      this.prevFrom = tempFrom
      this.prevTo = tempTo
      this.prevTrades = tempTrades
      this.prevSums = tempSums
    },
    timestampToDate(t) {
      return moment.utc(moment.unix(t)).format('YYYY-MM-DD HH:mm')
    },
    imagePath() {
      // return require('../../assets/img/logos/' + platform + '-small.png')
    },
    toggleSettingsActive(e) {
      // rest of settings
      let el = document.getElementsByClassName('settings')
      for (let i = 0; i < el.length; i++) {
        if (el[i] !== e.target.parentElement) {
          el[i].classList.remove('is-active')
        }
      }
      // row
      let par = e.target.parentElement
      while (par.tagName !== 'TR') {
        par = par.parentElement
        if (par === null) {
          break
        }
      }
      // rest of rows
      let trs = document.getElementById('tas')?.getElementsByTagName('tr') || []
      for (let i = 0; i < trs.length; i++) {
        if (trs[i] !== par) {
          trs[i].classList.remove('selected')
        }
      }
      // target
      e.target.parentElement.classList.toggle('is-active')

      par.classList.toggle('selected')
    },
    onAppClick(e) {
      if (!e.target.parentElement.classList.contains('settings')) {
        let trs = document.getElementById('tas')?.getElementsByTagName('tr') || []
        for (let i = 0; i < trs.length; i++) {
          trs[i].classList.remove('selected')
        }
        let el = document.getElementsByClassName('settings')
        for (let i = 0; i < el.length; i++) {
          el[i].classList.remove('is-active')
        }
      }
    },
    closeChangePasswordModal() {
      this.changeTAPassword.modalActive = false
      this.changeTAPassword.TAID = null
      this.changeTAPassword.new = ''
      this.changeTAPassword.newConfirm = ''
      this.errors.clear()
      this.$nextTick(() => {
        this.$validator.reset()
      })
    },
    closeModal() {
      this.showCreateAccountModal = false
      document.getElementById('CAcontainer').classList.add('translate-x-full')
    },
    refreshTAs() {
      api.GetTradingAccounts().then(() => {
        this.filterAccounts()
      })
    },
    showCAModal() {
      this.showCreateAccountModal = true
      setTimeout(() => document.getElementById('CAcontainer').classList.remove('translate-x-full'), 10)
    },
    setDefaultView() {
      if (window.innerWidth < 1180) {
        this.selectedView = 'grid'
      } else {
        this.selectedView = 'list'
      }
    },
    disableScroll() {
      document.querySelector('body').classList.add('overflow-hidden')
    },
    enableScroll() {
      document.querySelector('body').classList.remove('overflow-hidden')
    },
    getItemImage(item) {
       if (item.type === 'demo') {
        return require('../../assets/img/demo.svg');
      }
      const accountTypeImages = {
        'silver': 'silver',
        'gold-ecn': 'gold',
        'gold-raw': 'gold',
        'silver-islamic': 'silver-islamic',
        'platinum-vip': 'platinum',
        'micro': 'micro',
        'pamm-investor': 'pamm-investor',
        'pamm-master': 'pamm-investor',
        'no-deposit-bonus': 'no-deposit-bonus',
        'gold-raw-is':'islamic'

      };
      return accountTypeImages[item.account_type_identifier]
        ? require(`../../assets/img/${accountTypeImages[item.account_type_identifier]}.svg`)
        : null;
    },
    ...mapActions(['logoLoader/set', 'errorModal/set']),
  },
}
</script>
<style lang="css">
  .noUserSelect {
    -webkit-user-select: none;
    -webkit-user-drag: none;
    -webkit-app-region: no-drag;
  }
  #taListContainer::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
/* Track */
#taListContainer::-webkit-scrollbar-track {
  background: #e9edf4;
  border-radius: 12px;
}
/* Handle */
#taListContainer::-webkit-scrollbar-thumb{
  background: #2b63e3;
  border-radius: 12px;
}

#taListContainer {
  scroll-behavior: smooth;
}

#taListContainer.active {
  scroll-behavior: auto;
}
</style>
