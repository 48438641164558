<template>
  <div class="flex flex-wrap gap-4 mt-6 mb-12">
    <div class="bg-white w-full m-auto shadow-ts1 rounded-lg pb-8">
      <div class="w-full border-b border-gray-300 px-4 md:px-8 pt-5 pb-4 flex flex-col lg:flex-row justify-between items-start lg:items-center">
        <h2 class="font-display text-xl font-bold text-blue leading-none mb-5 lg:mb-0">{{ $t('statistics').tTitle() }}</h2>
        <div class="flex gap-2">
          <input
            type="search"
            :placeholder="$t('pamm_name_or_account_id').tTitle()"
            v-model="searchValue"
            class="rounded-md bg-gray-100 border border-gray-100 ltr:pl-5 rtl:pr-5 w-full h-[50px] text-base text-gray-900 focus:outline-none focus:ring-0 focus:bg-gray-100 placeholder:text-gray-900 placeholder:text-opacity-20 focus:border-gray-900"
          />
          <button @click="getPammMasterStatSummaries()" class="py-3 px-10 h-full bg-blue rounded-md text-white appearance-none font-semibold text-base text-center cursor-pointer">
            {{ $t('search').tTitle() }}
          </button>
        </div>
      </div>
      <div v-if="pammMasterStatSummaries" class="p-2 md:p-6 justify-center">
        <div class="hidden md:block overflow-auto pb-6" id="PammStatisticTable">
          <table class="min-w-[1100px] w-full mt-5 border-collapse" id="desktop-table">
          <thead class="font-bold text-left text-blue">
            <tr class="h-[50px]">
              <th class="ltr:pl-8 rtl:pr-8">{{ $t('name').tTitle() }}</th>
              <th>{{ $t('account').tTitle() }}</th>
              <th>{{ $t('gains_prc').tTitle() }}</th>
              <th>{{ $t('balance').tTitle() }}</th>
              <th>{{ $t('equity').tTitle() }}</th>
              <th>{{ $t('open_p_l').tTitle() }}</th>
              <th>{{ $t('monthly_avg').tTitle() }}</th>
              <th>{{ $t('profit').tTitle() }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="stats in pammMasterStatSummaries" :key="'stats-' + stats.id" class="odd:bg-gray-100 even:bg-white py-4 h-[50px]">
              <td class="font-bold ltr:rounded-l-xl rtl:rounded-r-xl ltr:pl-8 rtl:pr-8">{{ stats.displayName }}</td>
              <td>{{ stats.trading_account }}</td>
              <td :class="decimalValColor(stats.currentGains)">{{ stats.currentGains }}</td>
              <td :class="decimalValColor(stats.balance)">{{ stats.balance }}</td>
              <td :class="decimalValColor(stats.true_equity)">{{ stats.true_equity }}</td>
              <td :class="decimalValColor(stats.openPnL)">{{ stats.openPnL }}</td>
              <td :class="decimalValColor(stats.monthly_average)">{{ stats.monthly_average }}</td>
              <td :class="decimalValColor(stats.currentProfits)">{{ stats.currentProfits }}</td>
              <td class="w-[200px] ltr:rounded-r-xl rtl:rounded-l-xl">
                <div class="flex gap-1 justify-center">
                  <!-- <div
                    class='py-1 px-6 h-full cursor-pointer'
                    @click="openPopup(stats, 'viewGraphTab')"
                  >
                    <img src="../../assets/img/graph.png" width="20" />
                  </div> -->
                  <div
                    class="py-1 px-8 h-full ltr:mr-3 rtl::ml-3 bg-green rounded-md text-white appearance-none font-semibold text-sm text-center cursor-pointer"
                    @click="openPopup(stats, 'investTab')"
                  >
                    {{ $t('invest').tTitle() }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          </table>
         </div>

        <div class="w-full md:hidden text-body p-2">
          <div
            v-for="(stats, i) in pammMasterStatSummaries"
            :key="stats.id"
            @click="expandStat(i)"
            class="px-4 pt-[13px] rounded-lg odd:bg-gray-400 overflow-hidden"
            :class="{ 'max-h-[50px]': !stats.expanded }"
          >
          <div class="flex justify-between">
            <div class="text-blue font-bold w-[35%]">
              <label class="block h-9">
                <img width="12px" height="12px" class="ltr:mr-1 rtl:ml-1 cursor-pointer inline" :class="{ '-rotate-90': !stats.expanded }" src="../../assets/img/platform-dropdown.svg" />
                {{ $t('name').tTitle() }}
              </label>
            </div>
            <div class="text-right w-[65%]">
              <p class="font-bold" :class="{'truncate': !stats.expanded}">{{ stats.displayName }}</p>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="text-blue font-bold w-[35%]">
              <label class="block h-9">{{ $t('account').tTitle() }}</label>
            </div>
            <div class="text-right w-[65%]">
              <p class="h-9">{{ stats.trading_account }}</p>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="text-blue font-bold w-[35%]">
              <label class="block h-9">{{ $t('gains_prc').tTitle() }}</label>
            </div>
            <div class="text-right w-[65%]">
              <p :class="decimalValColor(stats.currentGains)" class="h-9">{{ stats.currentGains }}</p>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="text-blue font-bold w-[35%]">
              <label class="block h-9">{{ $t('balance').tTitle() }}</label>
            </div>
            <div class="text-right w-[65%]">
              <p :class="decimalValColor(stats.balance)" class="h-9">{{ stats.balance }}</p>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="text-blue font-bold w-[35%]">
              <label class="block h-9">{{ $t('equity').tTitle() }}</label>
            </div>
            <div class="text-right w-[65%]">
              <p :class="decimalValColor(stats.true_equity)" class="h-9">{{ stats.true_equity }}</p>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="text-blue font-bold w-[35%]">
              <label class="block h-9">{{ $t('open_p_l').tTitle() }}</label>
            </div>
            <div class="text-right w-[65%]">
              <p :class="decimalValColor(stats.openPnL)" class="h-9">{{ stats.openPnL }}</p>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="text-blue font-bold w-[35%]">
              <label class="block h-9">{{ $t('monthly_avg').tTitle() }}</label>
            </div>
            <div class="text-right w-[65%]">
              <p :class="decimalValColor(stats.monthly_average)" class="h-9">{{ stats.monthly_average }}</p>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="text-blue font-bold w-[35%]">
              <label class="block h-9">{{ $t('profit').tTitle() }}</label>
            </div>
            <div class="text-right w-[65%]">
              <p :class="decimalValColor(stats.currentProfits)" class="h-9">{{ stats.currentProfits }}</p>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="text-blue font-bold w-[35%]">
              <label class="block h-9"></label>
            </div>
            <div class="text-right w-[65%]">
              <div class="flex items-center justify-end">
                <!-- <button
                  @click="openPopup(stats, 'viewGraphTab')"
                  class='py-1 px-6 cursor-pointer'>
                  <img src="../../assets/img/graph.png" width="20" />
                </button> -->
                <button class="py-1 px-6 bg-green rounded-md text-white appearance-none font-semibold text-sm text-center cursor-pointer" @click="openPopup(stats, 'investTab')">
                  {{ $t('invest').tTitle() }}
                </button>
              </div>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="text-blue font-bold w-[35%]">
              <label class="block h-9"></label>
            </div>
            <div class="text-right w-[65%]"></div>
          </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center content-center w-full py-5 mx-8 relative">
        <div class="w-[100px] h-[40px] font-body text-sm leading-10 absolute left-0">{{ this.pagination.total }} {{ $t('records') }}</div>
        <div v-if="this.pagination.total > pagination.rowsPerPage" class="flex h-[40px] font-body text-center leading-10">
          <div class="text-center">
            <pagination-component :page="pagination.page" :maxPerPage="pagination.rowsPerPage" :total="pagination.total" />
          </div>
        </div>
      </div>
      <div v-if="showLoader" class="bg-white h-[456px] lg:h-[618px] w-full absolute  inset-0 top-80 lg:top-36 rounded-lg flex justify-center items-center z-50">
        <div class="flex flex-col items-center justify-center ">
          <!-- div class="w-32 h-32 border-b-4 border-white rounded-full animate-spin"></div -->
          <div class="w-12 h-12 relative rounded-full before:border-2 before:rounded-full before:w-full before:h-full before:left-0 before:absolute after:border-2 after:rounded-full after:w-full after:h-full after:left-0 after:absolute before:scale-1 after:scale-0 before:animate-rippleA after:animate-rippleB"></div>
          <div
            v-if="text && text.length > 0"
            class="relative top-10"
          >
            <div class="bg-gradient-to-r from-green/[0.7] via-blue to-green/[0.7] py-2 px-6 rounded-full text-white animate-seesaw">{{ text }}</div>
          </div>
        </div>

      </div>
    </div>

    <div v-if="confirmActive" class="fixed flex justify-center items-center inset-0 z-[51] bg-gray-900 bg-opacity-70 w-full h-full">
      <div class="bg-white rounded-xl py-10 px-8 w-10/12 lg:w-[550px] max-w-[550px] m-auto relative">
        <button @click="confirmActive = false" class="bg-white rounded-full h-10 w-10 absolute -top-14 right-0 appearance-none p-0">
          <div class="h-6 w-0.5 bg-blue rotate-45 ltr:ml-5 rtl:mr-5"></div>
          <div class="h-6 w-0.5 bg-blue -rotate-45 -mt-6 ltr:ml-5 rtl:mr-5"></div>
        </button>
        <p class="text-lg font-medium text-gray-900 pb-9" v-html="confirmText"></p>
        <div class="flex gap-5">
          <a class="py-2.5 w-48 bg-blue rounded-md text-white font-bold text-center cursor-pointer text-base" @click="attach(create)">{{ $t('continue').tTitle() }}</a>
          <a class="py-2.5 text-gray-900 text-base cursor-pointer" @click="confirmActive = false">{{ $t('cancel').tTitle() }}</a>
        </div>
      </div>
    </div>

    <div v-if="pammMasterTAID > 0" class="">
      <div @click="closePopup" class="fixed inset-0 z-50 bg-gray-900 bg-opacity-70 w-screen h-screen"></div>
      <div id="PammAttachBox" class="fixed flex flex-col items-center lg:items-end right-0 top-0 z-50 overflow-x-hidden overflow-y-scroll h-full w-full">
        <div class="w-11/12 lg:w-[950px] max-w-[950px]">
          <button @click="closePopup" class="bg-white rounded-full h-10 w-10 lg:self-start appearance-none flex-shrink-0 mt-2 p-0 block lg:hidden">
            <div class="h-6 w-0.5 bg-blue rotate-45 ltr:ml-5 rtl:mr-5"></div>
            <div class="h-6 w-0.5 bg-blue -rotate-45 -mt-6 ltr:ml-5 rtl:mr-5"></div>
          </button>
        </div>
        <div class="bg-white rounded-xl py-10 px-6 lg:px-8 w-11/12 lg:w-[1055px] max-w-[1055px] m-auto relative mt-3 lg:mt-auto">
          <button @click="closePopup" class="bg-white rounded-full h-10 w-10 absolute -top-14 right-0 appearance-none p-0 hidden lg:block">
            <div class="h-6 w-0.5 bg-blue rotate-45 ltr:ml-5 rtl:mr-5"></div>
            <div class="h-6 w-0.5 bg-blue -rotate-45 -mt-6 ltr:ml-5 rtl:mr-5"></div>
          </button>
          <div class="max-h-[65vh] overflow-auto p-1">
            <p class="font-display text-2xl font-bold text-gray-900 pb-9">{{ $t('invest_under_master_account') }}</p>
            <div class="flex bg-gray-400 w-full lg:w-[370px] h-[42px] rounded-3xl text-gray-200 m-0 lg:m-1 order-last lg:order-2 mt-0 lg:mt-1">
              <div
                @click="tabsActive = 'investTab'"
                class="w-1/2 text-center leading-10 cursor-pointer"
                :class="[tabsActive == 'investTab' ? 'text-white text-bold bg-blue rounded-3xl' : 'text-gray-900']"
              >
                {{ $t('invest').tTitle() }}
              </div>
              <div
                @click="
                  tabsActive = 'viewGraphTab'
                  getGraphSRC()
                "
                class="w-1/2 text-center leading-10 cursor-pointer"
                :class="[tabsActive == 'viewGraphTab' ? 'text-white text-bold bg-blue rounded-3xl' : 'text-gray-900']"
              >
                {{ $t('view_graph').tTitle() }}
              </div>
            </div>
            <div class="flex justify-between mt-8 gap-8 flex-col lg:flex-row">
              <div v-if="tabsActive == 'investTab'" class="w-full lg:w-8/12">
                <!-- <div class="flex gap-6 bg-gray-100 rounded-lg px-4 py-2">
                <p class="font-semibold text-gray-dark">{{popupData.displayName}}</p>
                <p class="font-semibold text-gray-dark">{{popupData.trading_account}}</p>
              </div> -->
                <div class="flex gap-4 mb-4">
                  <div class="w-1/2 bg-gray-100 rounded-lg py-2 px-4">
                    <p class="text-xs font-semibold text-gray-dark">{{ $t('name').tUpper() }}</p>
                    <div class="text-blue font-medium">{{ popupData.displayName }}</div>
                  </div>
                  <div class="w-1/2 bg-gray-100 rounded-lg py-2 px-4">
                    <p class="text-xs font-semibold text-gray-dark">{{ $t('trading_account').tUpper() }}</p>
                    <div class="text-blue font-medium">{{ popupData.trading_account }}</div>
                  </div>
                </div>
                <div class="mt-4 flex gap-4 mb-4">
                  <div class="w-1/2 bg-gray-100 rounded-lg py-2 px-4">
                    <p class="text-xs font-semibold text-gray-dark">{{ $t('performance_fee').tUpper() }}</p>
                    <div class="text-blue font-medium">{{ popupData.performance_fee }}%</div>
                  </div>
                  <div class="w-1/2 bg-gray-100 rounded-lg py-2 px-4">
                    <p class="text-xs font-semibold text-gray-dark">{{ $t('minimum_deposit').tUpper() }}</p>
                    <div class="text-blue font-medium">{{ popupData.minimum_deposit }}</div>
                  </div>
                </div>
                <div v-if="popupData.locked_deposits === 'yes'" class="text-sm text-gray-dark font-medium bg-[#fff1c6] rounded-lg py-2 px-4">
                  * {{ $t('pamm_master_locks_deposits') }}
                </div>
                <div v-if="popupData.locked_withdrawals === 'yes'" class="text-sm text-gray-dark font-medium bg-[#fff1c6] rounded-lg py-2 px-4">
                  * {{ $t('pamm_master_locks_withdrawals') }}
                </div>
                <div class="mt-5 w-1/2" v-if="popupData.private == 'yes'">
                  <span class="text-sm">{{ $t('enter_private_code').tTitle() }}</span>
                  <input type="text" class="mt-2" id="masterCodeTextbox" name="masterCodeTextbox" value="" v-model="form.privateMasterCode" />
                </div>
                <div class="flex gap-4 lg:gap-7 mt-10 flex-col lg:flex-row">
                  <p>
                    <label class="font-body text-sm text-gray-900 flex items-center">
                      <input class="text-gray-900" id="acceptToS" name="acceptToS" type="checkbox" v-model="acceptToSVal" />
                      <span class="checkmark"></span>
                      <span class="ltr:ml-2 rtl:mr-2" v-html="$t('i_accept_the_terms_of_service', { URL: termsOfService})"></span>
                    </label>
                  </p>
                  <p>
                    <label class="font-body text-sm text-gray-900 flex items-center">
                      <input class="text-gray-900" id="acceptLPOA" name="acceptLPOA" type="checkbox" v-model="acceptLPOAVal" />
                      <span class="checkmark"></span>
                      <span class="ltr:ml-2 rtl:mr-2" v-html="$t('i_accept_the_lpoa', { URL: LPOA_URL })"></span>
                    </label>
                  </p>
                </div>
                <div class="mt-4" v-if="this.selectedPammMaster.additional_terms === 'yes'">
                  <label class="font-body text-sm text-gray-900 flex items-center">
                    <input class="text-gray-900" id="acceptAdditionalTerms" name="acceptAdditionalTerms" type="checkbox" v-model="acceptAdditionalTerms" />
                    <span class="checkmark"></span>
                    <span class="ltr:ml-2 rtl:mr-2">{{ $t('additional_terms_apply').tTitle() }}</span>
                  </label>
                </div>
                <div class="mt-10 flex gap-3 flex-col lg:flex-row">
                  <div
                    class="w-full lg:w-1/2 px-6 h-10 rounded-3xl text-center leading-10 border relative cursor-pointer"
                    :class="[attachOptionRadioVal == 'attachExisting' ? 'active-option bg-blue border-blue text-white' : 'border-[#9A9A9A] text-gray-dark']"
                  >
                    <input name="attachOption" v-model="attachOptionRadioVal" type="radio" value="attachExisting" id="attachExisting" class="opacity-0 w-full h-10" />
                    <label for="attachExisting" class="absolute text-sm w-full left-0 cursor-pointer leading-[38px]">{{ $t('select_a_pamm_account_to_attach') }}</label>
                  </div>
                  <div
                    class="w-full lg:w-1/2 px-6 h-10 rounded-3xl text-center leading-10 border relative cursor-pointer"
                    :class="[attachOptionRadioVal == 'attachNew' ? 'active-option bg-blue border-blue text-white' : 'border-[#9A9A9A] text-gray-dark']"
                  >
                    <input name="attachOption" v-model="attachOptionRadioVal" type="radio" value="attachNew" id="attachNew" class="opacity-0 w-full h-10" />
                    <label for="attachNew" class="absolute text-sm w-full left-0 cursor-pointer leading-[38px]">{{ $t('create_and_attach_new_pamm_account') }}</label>
                  </div>
                </div>
                <div v-if="attachOptionRadioVal == 'attachExisting'" class="flex gap-3 lg:gap-5 mt-6 lg:mt-[30px] flex-col lg:flex-row">
                  <select name="tradingAccount" id="selectPammAccount" class="flex-grow" v-model="form.selectedUserTA">
                    <option v-for="ta in detachedPammAccounts" :value="ta.external_id" :key="ta.external_id">{{ ta.external_id }}</option>
                  </select>
                  <div
                    :class="checkboxesTicked()"
                    class="py-3 px-10 h-full bg-green rounded-md text-white appearance-none font-semibold text-base text-center cursor-pointer"
                    @click="showConfirm()"
                  >
                    {{ $t('attach').tTitle() }}
                  </div>
                </div>
                <div v-if="attachOptionRadioVal == 'attachNew'" class="mt-6 flex justify-center">
                  <div
                    :class="checkboxesTicked()"
                    class="py-3 px-1 h-full w-full max-w-[250px] bg-green rounded-md text-white appearance-none font-semibold text-base text-center cursor-pointer"
                    @click="showConfirm(true)"
                  >
                    {{ $t('create_and_attach').tTitle() }}
                  </div>
                </div>
              </div>
              <div v-else-if="tabsActive == 'viewGraphTab'" class="w-full lg:w-8/12">
                <div class="h-[350px] w-full">
                  <!-- <iframe
                  id='graphIFrame'
                  scrolling='no'
                  frameborder='0'
                  class="h-[350px] w-full"
                  :src="getGraph()"
                > </iframe> -->

                  <apexchart width="100%" type="area" :options="graph.options" :series="graph.options.series"></apexchart>
                </div>
                <div>
                  <div class="flex w-full justify-between mt-5 items-center flex-col lg:flex-row">
                    <div class="w-full flex gap-3 overflow-x-scroll lg:overflow-x-auto h-[41px]">
                      <div
                        id="allTime"
                        class="flex-shrink-0 lg:flex-shrink cursor-pointer h-10 rounded-xl border text-sm text-gray-dark px-3 leading-10"
                        @click="
                          selectedPeriod = 'allTime'
                          getGraphSRC()
                        "
                        :class="[selectedPeriod == 'allTime' ? 'border-blue' : 'border-[#9A9A9A]']"
                      >
                        {{ $t('all_time').tTitle() }}
                      </div>
                      <div
                        id="lastmonth"
                        class="flex-shrink-0 lg:flex-shrink cursor-pointer h-10 rounded-xl border text-sm text-gray-dark px-3 leading-10"
                        @click="
                          selectedPeriod = 'lastmonth'
                          getGraphSRC()
                        "
                        :class="[selectedPeriod == 'lastmonth' ? 'border-blue' : 'border-[#9A9A9A]']"
                      >
                        {{ $t('last_month').tTitle() }}
                      </div>
                      <div
                        id="lastweek"
                        class="flex-shrink-0 lg:flex-shrink cursor-pointer h-10 rounded-xl border text-sm text-gray-dark px-3 leading-10"
                        @click="
                          selectedPeriod = 'lastweek'
                          getGraphSRC()
                        "
                        :class="[selectedPeriod == 'lastweek' ? 'border-blue' : 'border-[#9A9A9A]']"
                      >
                        {{ $t('last_week').tTitle() }}
                      </div>
                      <div
                        id="last3days"
                        class="flex-shrink-0 lg:flex-shrink cursor-pointer h-10 rounded-xl border text-sm text-gray-dark px-3 leading-10"
                        @click="
                          selectedPeriod = 'last3days'
                          getGraphSRC()
                        "
                        :class="[selectedPeriod == 'last3days' ? 'border-blue' : 'border-[#9A9A9A]']"
                      >
                        {{ $t('last_3_days').tTitle() }}
                      </div>
                      <div
                        v-if="tabsActive === 'viewGraphTab'"
                        @click="tabsActive = 'investTab'"
                        class="hidden lg:block flex-grow cursor-pointer h-10 rounded-xl bg-green text-sm px-4 leading-10 text-center text-white"
                      >
                        {{ $t('invest').tTitle() }}
                      </div>
                    </div>
                    <div
                      v-if="tabsActive === 'viewGraphTab'"
                      @click="tabsActive = 'investTab'"
                      class="block lg:hidden cursor-pointer w-full mt-6 h-10 rounded-xl bg-green text-sm px-4 leading-10 text-center text-white"
                    >
                      {{ $t('invest').tTitle() }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-4/12 border border-[#9A9A9A80] rounded-xl p-4">
                <div class="bg-[#f7f7f7] rounded-xl p-2.5 mb-1.5">
                  <p class="font-medium text-sm text-left min-h-[14px] mb-[5px]">{{ $t('name').tTitle() }}</p>
                  <p class="text-xs leading-[14px] min-h-[14px] mt-[5px]">{{ popupData.displayName }}</p>
                </div>
                <div class="bg-[#f7f7f7] rounded-xl p-2.5 mb-1.5">
                  <p class="font-medium text-sm text-left min-h-[14px] mb-[5px]">{{ $t('account_id').tTitle() }}</p>
                  <p class="text-xs leading-[14px] min-h-[14px] mt-[5px]">{{ popupData.trading_account }}</p>
                </div>
                <div class="flex gap-1.5">
                  <div class="w-1/2 bg-[#f7f7f7] rounded-xl p-2.5 mb-1.5">
                    <p class="font-medium text-sm text-left min-h-[14px] mb-[5px]">{{ $t('gains_prc').tTitle() }}</p>
                    <p :class="decimalValColor(popupData.currentGains)" class="text-xs leading-[14px] min-h-[14px] mt-[5px]">{{ popupData.currentGains }}</p>
                  </div>
                  <div class="w-1/2 bg-[#f7f7f7] rounded-xl p-2.5 mb-1.5">
                    <p class="font-medium text-sm text-left min-h-[14px] mb-[5px]">{{ $t('balance').tTitle() }}</p>
                    <p :class="decimalValColor(popupData.balance)" class="text-xs leading-[14px] min-h-[14px] mt-[5px]">{{ popupData.balance }}</p>
                  </div>
                </div>
                <div class="flex gap-1.5">
                  <div class="w-1/2 bg-[#f7f7f7] rounded-xl p-2.5 mb-1.5">
                    <p class="font-medium text-sm text-left min-h-[14px] mb-[5px]">{{ $t('equity').tTitle() }}</p>
                    <p :class="decimalValColor(popupData.true_equity)" class="text-xs leading-[14px] min-h-[14px] mt-[5px]">{{ popupData.true_equity }}</p>
                  </div>
                  <div class="w-1/2 bg-[#f7f7f7] rounded-xl p-2.5 mb-1.5">
                    <p class="font-medium text-sm text-left min-h-[14px] mb-[5px]">{{ $t('open_p_l').tTitle() }}</p>
                    <p :class="decimalValColor(popupData.openPnL)" class="text-xs leading-[14px] min-h-[14px] mt-[5px]">{{ popupData.openPnL }}</p>
                  </div>
                </div>
                <div class="flex gap-1.5">
                  <div class="w-1/2 bg-[#f7f7f7] rounded-xl p-2.5 mb-1.5">
                    <p class="font-medium text-sm text-left min-h-[14px] mb-[5px]">{{ $t('monthly_avg').tTitle() }}</p>
                    <p :class="decimalValColor(popupData.monthly_average)" class="text-xs leading-[14px] min-h-[14px] mt-[5px]">{{ popupData.monthly_average }}</p>
                  </div>
                  <div class="w-1/2 bg-[#f7f7f7] rounded-xl p-2.5 mb-1.5">
                    <p class="font-medium text-sm text-left min-h-[14px] mb-[5px]">{{ $t('profit').tTitle() }}</p>
                    <p :class="decimalValColor(popupData.currentProfits)" class="text-xs leading-[14px] min-h-[14px] mt-[5px]">{{ popupData.currentProfits }}</p>
                  </div>
                </div>
                <div class="bg-[#f7f7f7] rounded-xl p-2.5 mb-1.5">
                  <p class="font-medium text-sm text-left min-h-[14px] mb-[5px]">{{ $t('max_drawdown').tTitle() }}</p>
                  <p :class="decimalValColor(popupData.drawdown)" class="text-xs leading-[14px] min-h-[14px] mt-[5px]">
                    {{ popupData.drawdown }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <error-modal></error-modal>
  </div>
</template>

<script>
import { service } from '@/main.js'
import { mapGetters, mapActions } from 'vuex'
import config from '../../config'
import api from '../../api'
import PaginationComponent from '@/components/Pagination.vue'
import ErrorModal from '@/components/ErrorModal'
import moment from 'moment'
import links from '../../config/links'


export default {
  components: {
    PaginationComponent,
    ErrorModal,
  },
  data() {
    return {
      tabsActive: '',
      pammMasterStatSummaries: [],
      pammMasterTAID: 0,
      attachOptionRadioVal: 'attachExisting',
      acceptToSVal: false,
      acceptLPOAVal: false,
      acceptAdditionalTerms: false,
      selectedPeriod: 'allTime',
      confirmText: '',
      confirmActive: false,
      create: false,
      detachedPammAccounts: [],
      form: {
        selectedUserTA: 0,
        privateMasterCode: '',
      },
      pagination: {
        page: 1,
        total: 0,
        rowsPerPage: 10,
      },
      popupData: [],
      selectedPammMaster: {},
      URL: config.URL,
      searchValue: '',
      showLoader: false,
      graph: {
        options: {},
      },
    }
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
      logoLoaderActive: 'logoLoader/active',
    }),
     termsOfService() {
     return links.getLink('termsOfService', this.Language);
    },
     LPOA_URL() {
     return links.getLink('acceptTheLpoa', this.Language);
    },
  },
  mounted() {
    this.getPammMasterStatSummaries()
    api.GetTradingAccounts()

    if (this.$route.query['pm']) {
      var code = this.$route.query['pm']
      this.getSpecificPammMasterStatSummaries(code)
    }
  },
  watch: {
    'pagination.page'() {
      this.getPammMasterStatSummaries()
    },
    tabsActive() {
      // this.alignContentColumnHeight()
    },
    pammMasterTAID() {
      // this.alignContentColumnHeight()
    },
    'User.TA'() {
      let first = true
      for (let i = 0; i < this.User.TA.length; i++) {
        if (this.User.TA[i].platform === 'pamm' && this.User.TA[i].account_type === 'Detach') {
          this.detachedPammAccounts.push(this.User.TA[i])
          if (first === true) {
            this.form.selectedUserTA = this.User.TA[i].external_id
            first = false
          }
        }
      }
    },
  },
  created() {
    this.$bus.$on('pageChanged', p => {
      this.pagination.page = p
    })
  },
  methods: {
    attach(create) {
      this['logoLoader/set'](true)
      this.confirmActive = false
      if (create) {
        service
          .post('/pamm/attach-pamm-account', {
            masterTAID: this.popupData.trading_account,
            private: this.popupData.private,
            privateMasterCode: this.form.privateMasterCode,
            create: true,
          })
          .then(response => {
            this['logoLoader/set'](false)
            if (response.data.success) {
              // close main modal
              this.pammMasterTAID = 0
              this.form.privateMasterCode = ''
              this.attachOptionRadioVal = 'attachExisting'
              this.selectedPeriod = 'allTime'
              this.$toasted.success(this.$t('pamm_success'))
            } else {
              this['errorModal/set']({ active: true, text: this.$t('pamm_operation_failed_invalid') })
            }
          })
          .catch(() => {
            this['logoLoader/set'](false)
            this['errorModal/set']({ active: true, text: this.$t('pamm_operation_failed_error') })
          })
      } else {
        this.confirmActive = false
        service
          .post('/pamm/attach-pamm-account', {
            userTAID: this.form.selectedUserTA,
            masterTAID: this.popupData.trading_account,
            private: this.popupData.private,
            privateMasterCode: this.form.privateMasterCode,
            create: false,
          })
          .then(response => {
            this['logoLoader/set'](false)
            if (response.data.success) {
              // close main modal
              this.pammMasterTAID = 0
              this.form.privateMasterCode = ''
              this.attachOptionRadioVal = 'attachExisting'
              this.selectedPeriod = 'allTime'
              this.$toasted.success(this.$t('pamm_success'))
            } else {
              this['errorModal/set']({ active: true, text: this.$t('pamm_operation_failed_invalid') })
            }
          })
          .catch(() => {
            this['logoLoader/set'](false)
            this['errorModal/set']({ active: true, text: this.$t('pamm_operation_failed_error') })
          })
      }
    },
    expandStat(i) {
      this.$set(this.pammMasterStatSummaries, i, { ...this.pammMasterStatSummaries[i], expanded: !this.pammMasterStatSummaries[i].expanded })
    },
    showConfirm(create) {
      if (this.checkboxesTicked() !== 'disabled') {
        if (this.selectedPammMaster.additional_terms === 'yes') {
          if (this.acceptAdditionalTerms === false) {
            this['errorModal/set']({ active: true, text: this.$t('refer_to_pamm_dept') })
            return
          }
        }

        if (create) {
          this.create = true
          this.confirmText = this.$t('create_pamm_question')
        } else {
          this.create = false
          this.confirmText = this.$t('attach_pamm_question')
        }

        if (this.selectedPammMaster.additional_terms === 'yes') {
          this.confirmText += '<br />' + this.$t('additional_terms_check')
        }

        this.confirmActive = true
      }
    },
    checkboxesTicked() {
      if (this.acceptToSVal === true && this.acceptLPOAVal === true) {
        return ''
      } else {
        return 'disabled pointer-events-none cursor-default opacity-50'
      }
    },
    closePopup() {
      this.pammMasterTAID = 0
      this.form.privateMasterCode = ''
      this.attachOptionRadioVal = 'attachExisting'
      document.querySelector('body').classList.remove('overflow-hidden')
    },
    openPopup(stats, tab) {
      // *** to testing the FE uncomment the below and comment the api call

      // this.selectedPammMaster = {
      //     "additional_terms": "no",
      //     "approved": "yes",
      //     "display": "yes",
      //     "id": 993,
      //     "locked_deposits": "no",
      //     "locked_withdrawals": "yes",
      //     "master_group": "umam-Master",
      //     "md_initial_only": "no",
      //     "minimum_deposit": 300,
      //     "name": "TEST MASTER",
      //     "performance_fee": 50,
      //     "private": "no",
      //     "private_pw": "oZW3yviv",
      //     "slave_group": "umam_50356491",
      //     "trading_account": 80000577
      // }
      // this.acceptToSVal = false
      // this.acceptLPOAVal = false
      // this.acceptAdditionalTerms = false

      // this.tabsActive = tab
      // this.pammMasterTAID = stats.trading_account
      // this.popupData = stats
      document.querySelector('body').classList.add('overflow-hidden')
      service
        .post('/pamm/get-master', {
          taid: stats.trading_account,
        })
        .then(response => {
          this.selectedPammMaster = response.data

          this.acceptToSVal = false
          this.acceptLPOAVal = false
          this.acceptAdditionalTerms = false

          this.tabsActive = tab
          this.pammMasterTAID = stats.trading_account
          this.popupData = stats
        })
    },
    decimalValColor(val) {
      if (val > 0) {
        return 'text-green'
      } else {
        return 'text-red'
      }
    },
    getPammMasterStatSummaries() {
      this.showLoader = true
      service
        .post('/pamm/pamm-stats-summaries', {
          page: this.pagination.page,
          search: this.searchValue,
        })
        .then(response => {
          this.showLoader = false
          this.pammMasterStatSummaries = response.data.PammSummariesMulti
          this.pagination.total = response.data.count
          this.$nextTick(() => {
            this.dragContainer('#PammStatisticTable');
          });
        })
    },
    getGraphSRC() {
      service
        .post('/pamm/pamm-gains-graph', {
          taid: this.pammMasterTAID,
          period: this.selectedPeriod,
        })
        .then(response => {
          // console.log(response.data.data)
          // var timestamps = []
          // var gains = []
          // console.log(response.data.data.length)
          var graphData = []
          var fromTime, maxGain, minGain
          if (response.data.data) {
            (maxGain = parseInt(response.data.data[0]['gainsProgrTotal'].toFixed(0))), (minGain = parseInt(response.data.data[0]['gainsProgrTotal'].toFixed(0)))

            for (let i = 0; i < response.data.data.length; i++) {
              graphData[i] = []
              graphData[i][0] = response.data.data[i]['close_time'] * 1000
              graphData[i][1] = response.data.data[i]['gainsProgrTotal'].toFixed(0)
              if (i == 0) {
                fromTime = response.data.data[i]['close_time'] * 1000
              }
              if (parseInt(response.data.data[i]['gainsProgrTotal'].toFixed(0)) > parseInt(maxGain)) {
                maxGain = parseInt(response.data.data[i]['gainsProgrTotal'].toFixed(0))
              }
              if (parseInt(response.data.data[i]['gainsProgrTotal'].toFixed(0)) < parseInt(minGain)) {
                minGain = parseInt(response.data.data[i]['gainsProgrTotal'].toFixed(0))
              }
            }
          } else {
            graphData = [['', 0]]
            minGain = 1
          }

          // console.log(minGain)
          // console.log(maxGain)
          // console.log(graphData)

          this.graph.options = {
            series: [
              {
                name: 'Gains (%)',
                data: graphData,
              },
            ],
            chart: {
              id: 'area-datetime',
              type: 'area',
              height: 350,
              zoom: {
                autoScaleYaxis: true,
              },
              toolbar: {
                show: false,
              },
              stroke: {
                curve: 'smooth',
              },
              animations: {
                enabled: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            markers: {
              size: 0,
              style: 'hollow',
            },
            yaxis: {
              seriesName: 'Gains %',
              min: minGain - 1,
              max: maxGain + 1,
              tickAmount: 4,
            },
            xaxis: {
              type: 'datetime',
              min: new Date(fromTime).getTime(),
              tickAmount: 6,
              labels: {
                formatter: function (timestamp) {
                  return moment(timestamp).format('DD/MM/YYYY')
                },
              },
            },
            tooltip: {
              x: {
                format: 'DD/MM/YYYY',
              },
            },
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100],
              },
            },
          }
        })
      // return 'https://clients1.lqdfx.com/plugins/lqdfx.clientarea/core/ajax/getGainsGraphData.php?login=' + this.pammMasterTAID + '&period=' + this.selectedPeriod
    },
    getGraph() {
      return 'https://clients1.lqdfx.com/plugins/lqdfx.clientarea/core/ajax/getGainsGraphData.php?login=' + this.pammMasterTAID + '&period=' + this.selectedPeriod
    },
    getSpecificPammMasterStatSummaries(code) {
      this['logoLoader/set'](true)
      service
        .post('/pamm/pamm-stats-summaries-specific', {
          code: code,
        })
        .then(response => {
          this['logoLoader/set'](false)
          this.openPopup(response.data, 'investTab')
        })
    },
    ...mapActions(['logoLoader/set', 'errorModal/set', 'user/setInfo']),
  },
}
</script>
<style>
#PammAttachBox::-webkit-scrollbar,
#PammStatisticTable::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
/* Track */
#PammAttachBox::-webkit-scrollbar-track,
#PammStatisticTable::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 12px;
}

/* Handle */
#PammAttachBox::-webkit-scrollbar-thumb,
#PammStatisticTable::-webkit-scrollbar-thumb {
  background: #2b63e3;
  border-radius: 12px;
}

#PammStatisticTable.active {
  scroll-behavior: auto;
}
</style>
