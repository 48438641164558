<template>
  <div class="content has-text-centered">
    <h1 class="title">{{ $t('deposit_failed')}}</h1>

    <!-- <p class="subtitle">You can try again <router-link :to="'/' + Language + '/finance'">here</router-link></p> -->
    <!-- <hr> -->
    <!-- <article class="message is-danger" v-if="reason">
			<div class="message-body">
				<strong>Reason:</strong> {{reason}}
			</div>
    </article>-->
    <p class="has-text-danger" v-if="reason">
      <strong>{{ $t('reason')}}</strong>
      {{reason}}
    </p>
    <p>
      <template v-if="attempts < maxAttempts">
        {{ $t('attempts', { attempts: attempts, maxAttempts: maxAttempts}) }}
        <template
          v-if="attempts == 1"
        >{{ $t('two_more_remaining')}}</template>
        <template v-else-if="attempts == 2">{{ $t('one_more_remaining')}}</template>
      </template>
      <br />
      <span v-html="$t('please_contact_bank')"></span>
      <!-- Do not hesitate to contact our customer support if the problem persists even after contacting your bank. -->
    </p>
  </div>
</template>

<script>
// import Failed from './Failed'

export default {
  components: {
    // Failed,
    // maxAttempts
  },
  data() {
    return {
      title: '',
      blockCookieName: 'ccFailedAttempts',
      attempts: 0,
      maxAttempts: 3,
      reason: '',
    }
  },
  mounted() {
    if (Object.keys(this.$route.query).length > 0) {
      this.reason = this.$route.query['reason']
      if (this.reason.toLowerCase().indexOf('affiliate monthly volume exceeded') !== -1) {
        this.reason = ''
      }
    }

    let cookie = this.$getCookie(this.blockCookieName)
    this.attempts = cookie ? parseInt(cookie) + 1 : 1
    this.$createCookie(this.blockCookieName, this.attempts, 1)
    // this.$createCookie(this.blockCookieName, 0, 1)

    this.title = this.$t('method_deposit_status', { method: 'Credit Card' })
    this.$bus.$emit('ChangeHeadings', this.title, 'Credit Card')
  },
  methods: {},
}
</script>
