<template>
  <div></div>
</template>

<script>
import { service } from '../../main.js'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
    }),
  },
  mounted() {
    if (Object.keys(this.$route.query).length <= 0) {
      this.$router.push('/' + this.Language)
      return
    }
    if (this.$route.query.t === undefined && this.$route.query.cxd === undefined) {
      this.$router.push('/' + this.Language)
      return
    }

    // $conf['URLs']['lp-partners'] = "/partners";
    const destinationURLs = {
      register: `/${this.Language}/register/live`,
      demo: `/${this.Language}/register/demo`,
      partner: `/${this.Language}/register/partner`,
      bonus: `https://plexytrade.com/promotions/bonus`,
      pamm: `https://plexytrade.com/platforms/pamm/#statistics`,
      home: `https://plexytrade.com`,
    }

    let destination = destinationURLs['register']

    if (this.$route.query.term !== undefined) {
      destination = destinationURLs[this.$route.query.term]
    }

    if (this.$route.query.cxd !== undefined) {
      this.$createCookie('cx_token', this.$route.query.cxd, 14)
    }
    if (this.$route.query.affid !== undefined) {
      this.$createCookie('aff_id', this.$route.query.affid, 14)
    }

    // set cookie and attach 't' to home and bonus destinations
    if (this.$route.query.t !== undefined) {
      // Attach 't' to the home and bonus link as a query parameter if destiation is bonus or home
      if(destination === destinationURLs['bonus'] || destination === destinationURLs['home'] || destination === destinationURLs['pamm']) {
        destination = destination + `?t=${this.$route.query.t}`;
      }
      // set cookie
      this.$createCookie('refcode', this.$route.query.t, 30)

      // ajax store action
      service.get('/ib/add-visit', {
        params: {
          code: this.$route.query.t,
        },
      })
    }

    // redirect regardless
    window.location = destination
  },
}
</script>
