<template>
  <div>
    <div class="mt-6 pt-5 bg-white rounded-lg shadow-ts1 mx-5 lg:mx-0">
      <div class="w-full pb-4 border-b border-gray-400 border-solid flex gap-2 items-center relative">
        <h2 class="ltr:pl-5 ltr:md:pl-8 rtl:pr-5 rtl:md:pr-8 text-blue font-display font-bold text-xl">{{ $t('marketing_material').tTitle() }}</h2>
        <div class="relative overflow-visible">
          <img @mouseenter="showTooltip = !showTooltip" @mouseleave="showTooltip = !showTooltip" src="../../assets/img/info.svg" class="h-3 w-3 cursor-pointer" />
        </div>

        <div v-if="showTooltip" class="w-[89%] md:w-80 max-w-xs absolute ltr:left-5 ltr:md:left-52 rtl:right-5 rtl:md:right-52 top-7 p-5 bg-white rounded-md shadow-ts7 z-10">
          <p class="text-gray-900 text-base leading-7">*{{ $t('banners_ad_blocker_notice') }}.</p>
        </div>
      </div>

      <div class="w-auto mt-6 mx-5 md:mx-8 min-h-[320px] pb-14">
        <div v-if="trackingCode !== ''">
          <div v-for="(banner, index) in banners" :key="index">
            <div class="border border-solid border-gray-300 bg-white rounded-lg shadow-ts2 py-6 px-7 mb-5">
              <h4 class="text-gray-900 text-base font-semibold mb-6 rtl:text-right">{{ banner.name }}</h4>

              <div id="banners" :key="'banner-' + index" :class="{ 'direction-rtl': Language == 'fa' }" class="w-full flex gap-11 flex-col lg:flex-row items-start">
                <div class="w-[300px]">
                  <img :src="banner.images[banner.selectedBanner]" class="rounded-lg object-contain" />
                </div>
                <div class="w-full lg:w-[73%] overflow-hidden">
                  <div :id="'sizesContainer-' + index" class="sizesContainer hidden md:flex gap-2.5 mb-5 w-full overflow-x-scroll">
                    <div
                      v-for="(size, sizeKey) in banner.images"
                      :key="sizeKey"
                      :class="[banner.selectedBanner === sizeKey ? 'border-green' : 'border-[#F0F0F4]']"
                      @click="updateSelectedBanner(index , sizeKey)"
                      class="py-3 w-44 bg-[#F0F0F4] rounded-md border border-solid text-gray-900 text-center font-bold cursor-pointer shrink-0"
                    >
                      {{ sizeKey }}
                    </div>
                  </div>
                  <div class="block md:hidden" id="sizesCarousel">
                    <VueSlickCarousel v-if="banner.images" v-bind="carouselSettings" :initialSlide="isRTL ? 2 : 0">
                      <div v-for="(size, sizeKey) in banner.images" :key="sizeKey" class="ltr:mr-5 rtl:ml-5">
                        <div
                          :class="[banner.selectedBanner === sizeKey ? 'border-green' : 'border-[#F0F0F4]']"
                           @click="updateSelectedBanner(index , sizeKey)"
                          class="py-3 w-[8.6rem] bg-[#F0F0F4] rounded-md border border-solid text-gray-900 text-center font-bold cursor-pointer ltr:mr-5 rtl:ml-5"
                        >
                          {{ sizeKey }}
                        </div>
                      </div>
                    </VueSlickCarousel>
                  </div>
                  <div class="min-h-[112px] border border-solid border-gray-300 rounded-md bg-white px-5 py-7 mb-5">
                    <p
                      class="text-gray-900 text-base leading-7 break-all"
                      v-text="'<a href=' + '\'\'' + trackURL + trackingCode + '\'\'' + '><img src=' + '\'\'' + banner.images[banner.selectedBanner] + '\'\'' + '/></a>'"
                    ></p>
                  </div>
                  <button
                    v-clipboard:copy="returnHTMLCode(banner.images[banner.selectedBanner])"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    class="ltr:md:float-right rtl:md:float-left appearance-none py-3 text-base rounded-md text-white bg-green w-40 text-center font-bold"
                  >
                    {{ $t('copy') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import config from '../../config'
import { service } from '../../main.js'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      showTooltip: false,
      message: '',
      imageBaseURL: '/../../assets/img/',
      // imageBaseURL: config.URL.website + 'wp-content/uploads/sites/5/2021/05/',
      trackURL: config.URL.track,
      trackingCode: '',

      carouselSettings: {
        arrows: false,
        dots: true,
        infinite: false,
        slidesToShow: 1.5,
        speed: 500,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 590,
            settings: {
              slidesToShow: 2.5,
            },
          },
          {
            breakpoint: 535,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.8,
            },
          },
          {
            breakpoint: 440,
            settings: {
              slidesToShow: 1.5,
            },
          },
        ],
      },
      selectedBannerSizes: {}
    }
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
    }),
    isRTL() {
      if (this.Language === 'fa') {
        return true
      } else {
        return false
      }
    },
    banners() {
      return [
        {
          name: this.$t('banner_trading'),
          images: {
            '160x600': `${config.URL.website}assets/img/marketing-material/${this.Language}/Trading-160x600.png`,
            '300x250': `${config.URL.website}assets/img/marketing-material/${this.Language}/Trading-300x250.png`,
            '300x600': `${config.URL.website}assets/img/marketing-material/${this.Language}/Trading-300x600.png`,
            '728x90': `${config.URL.website}assets/img/marketing-material/${this.Language}/Trading-728x90.png`,
            '970x250': `${config.URL.website}assets/img/marketing-material/${this.Language}/Trading-970x250.png`,
          },
          selectedBanner: this.selectedBannerSizes[0] || '300x250',
        },
        {
          name: this.$t('banner_pamm_platform'),
          images: {
            '160x600': `${config.URL.website}assets/img/marketing-material/${this.Language}/PAMM-Platform-160x600.png`,
            '300x250': `${config.URL.website}assets/img/marketing-material/${this.Language}/PAMM-Platform-300x250.png`,
            '300x600': `${config.URL.website}assets/img/marketing-material/${this.Language}/PAMM-Platform-300x600.png`,
            '728x90': `${config.URL.website}assets/img/marketing-material/${this.Language}/PAMM-Platform-728x90.png`,
            '970x250': `${config.URL.website}assets/img/marketing-material/${this.Language}/PAMM-Platform-970x250.png`,
          },
          selectedBanner: this.selectedBannerSizes[1] || '300x250',
        },
        {
          name: this.$t('banner_mt4_mt5_platform'),
          images: {
            '160x600': `${config.URL.website}assets/img/marketing-material/${this.Language}/MT4-MT5-160x600.png`,
            '300x250': `${config.URL.website}assets/img/marketing-material/${this.Language}/MT4-MT5-300x250.png`,
            '300x600': `${config.URL.website}assets/img/marketing-material/${this.Language}/MT4-MT5-300x600.png`,
            '728x90': `${config.URL.website}assets/img/marketing-material/${this.Language}/MT4-MT5-728x90.png`,
            '970x250': `${config.URL.website}assets/img/marketing-material/${this.Language}/MT4-MT5-970x250.png`,
          },
          selectedBanner: this.selectedBannerSizes[2] || '300x250',
        },
        {
          name: this.$t('banner_cash_bonus'),
          images: {
            '160x600': `${config.URL.website}assets/img/marketing-material/${this.Language}/Cash-Bonus-160x600.png`,
            '300x250': `${config.URL.website}assets/img/marketing-material/${this.Language}/Cash-Bonus-300x250.png`,
            '300x600': `${config.URL.website}assets/img/marketing-material/${this.Language}/Cash-Bonus-300x600.png`,
            '728x90': `${config.URL.website}assets/img/marketing-material/${this.Language}/Cash-Bonus-728x90.png`,
            '970x250': `${config.URL.website}assets/img/marketing-material/${this.Language}/Cash-Bonus-970x250.png`,
          },
          selectedBanner: this.selectedBannerSizes[3] || '300x250',
        },
        {
          name: this.$t('banner_leverage'),
          images: {
            '160x600': `${config.URL.website}assets/img/marketing-material/${this.Language}/Leverage-160x600.png`,
            '300x250': `${config.URL.website}assets/img/marketing-material/${this.Language}/Leverage-300x250.png`,
            '300x600': `${config.URL.website}assets/img/marketing-material/${this.Language}/Leverage-300x600.png`,
            '728x90': `${config.URL.website}assets/img/marketing-material/${this.Language}/Leverage-728x90.png`,
            '970x250': `${config.URL.website}assets/img/marketing-material/${this.Language}/Leverage-970x250.png`,
          },
          selectedBanner: this.selectedBannerSizes[4] || '300x250',
        },
      ]
    },
  },

  mounted() {
    // get IB overview info
    if (!Object.keys(this.User.IB).length || !this.User.IB.tracking_links || !Object.keys(this.User.IB.tracking_links).length) {
      this['logoLoader/set'](true)
      service
        .get('/ib/' + this.User.info.ID)
        .then(response => {
          this['user/setIB'](response.data)
          this['logoLoader/set'](false)
          this.trackingCode = Object.values(this.User.IB.tracking_links)[0]
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    } else {
      this.trackingCode = Object.values(this.User.IB.tracking_links)[0]
    }

    setTimeout(() => {
      for (var key in this.banners) {
        this.dragContainer('#sizesContainer-' + key)
        // document.getElementById('#sizesContainer-' + key).style.scrollBehavior = 'smooth'
      }
    }, 500)
  },
  methods: {
    returnHTMLCode(text) {
      return '<a href="' + this.trackURL + this.trackingCode + '&term=register' + '"><img src="' + text + '"></a>'
    },
    onCopy: function () {
      // this.$toasted.success(this.$t('copied_text_successfully', { text: '<strong>' + this.$escapeHtml(e.text) + '</strong>' }))
      this.$toasted.success(this.$t('copied'), { duration: 10000 })

      // alert('You just copied: ' + e.text)
    },
    onError: function () {
      this.$toasted.error('Failed to copy text', { duration: 10000 })
    },
   updateSelectedBanner(index, size) {
      this.$set(this.selectedBannerSizes, index, size);
    },
    ...mapActions(['user/setIB', 'logoLoader/set']),
  },
}
</script>
<style>
.sizesContainer::-webkit-scrollbar {
  display: none;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

#sizesCarousel .slick-dots {
  position: relative;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;
}
[dir='ltr'] #sizesCarousel .slick-dots {
  text-align: left;
}
[dir='rtl'] #sizesCarousel .slick-dots {
  text-align: right;
}

#sizesCarousel .slick-dots li {
  position: relative;

  display: inline-block;

  width: 10px;
  height: 20px;
  margin: 0 4px;
  padding: 0;

  cursor: pointer;
}
#sizesCarousel .slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 10px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
#sizesCarousel .slick-dots li button:hover,
#sizesCarousel .slick-dots li button:focus {
  outline: none;
}
#sizesCarousel .slick-dots li button:hover:before,
#sizesCarousel .slick-dots li button:focus:before {
  opacity: 1;
}
#sizesCarousel .slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 10px;
  height: 2px;

  content: '';
  text-align: center;

  background-color: #cfd9de;
  border-radius: 12px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#sizesCarousel .slick-dots li.slick-active {
  width: 20px;
}
#sizesCarousel .slick-dots li.slick-active button:before {
  opacity: 1;
  width: 20px;
  background-color: #2b63e3;
  border-radius: 12px;
}
</style>
