<template>
  <div class="step-two px-[20px]">
    <div class="form-wrapper grid grid-cols-4-default gap-5 mb-5">
      <!-- Number Of Shareholders -->
      <div class="col-span-4 lg:col-span-2">
        <label class="font-body text-base text-gray-900 tracking-wide" :class="{ 'has-text-right': Language == 'fa' }" for="numberOfShareholders"
          >{{ translations.text.number_of_shareholders }}<span class="text-orange ml-1">*</span></label
        >
        <div class="my-2">
          <span class="select is-fullwidth">
            <select
              v-validate="'required|min_value:1'"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('numberOfShareholders') }"
              :data-vv-as="translations.text.number_of_shareholders"
              name="numberOfShareholders"
              v-model="data.numberOfShareholders"
              class="text-base text-gray-900 font-semibold rtl:bg-left"
              @change="changeShareholder(data.numberOfShareholders)"
            >
              <option value="" selected hidden>{{ translations.text.number_of_shareholders }}</option>
              <option v-for="index in 4" :value="index" :key="index">{{ index }}</option>
            </select>
          </span>
          <span v-show="errors.has('numberOfShareholders')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
            errors.first('numberOfShareholders')
          }}</span>
        </div>
      </div>
      <!-- Number Of Directors -->
      <div class="col-span-4 lg:col-span-2">
        <label class="font-body text-base text-gray-900 tracking-wide" :class="{ 'has-text-right': Language == 'fa' }" for="numberOfDirectors"
          >{{ translations.text.number_of_directors }}<span class="text-orange ml-1">*</span></label
        >
        <div class="my-2">
          <span class="select is-fullwidth">
            <select
              v-validate="'required|min_value:1'"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('numberOfDirectors') }"
              :data-vv-as="translations.text.number_of_directors"
              name="numberOfDirectors"
              v-model="data.numberOfDirectors"
              class="text-base text-gray-900 font-semibold rtl:bg-left"
              @change="changeDirector(data.numberOfDirectors)"
            >
              <option value="" selected hidden>{{ translations.text.number_of_directors }}</option>
              <option v-for="index in 4" :value="index" :key="index">{{ index }}</option>
            </select>
          </span>
          <span v-show="errors.has('numberOfDirectors')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
            errors.first('numberOfDirectors')
          }}</span>
        </div>
      </div>
      <div class="col-span-4 lg:col-span-4 shareholders-buttons-wrapper grid grid-cols-4-default gap-5" v-if="data.numberOfShareholders">
        <div :class="`shareholder-${index}`" v-for="index in data.numberOfShareholders" :key="index">
          <div
            class="shareholder-title px-[25px] py-[11px] bg-gray-400 rounded-md cursor-not-allowed opacity-40 font-display font-bold text-[18px]"
            :class="{
              '!cursor-pointer': shareholderErrors[index - 1].length === 0 && proceed,
              'opacity-100 border border-blue !cursor-pointer': activeShareholder === index,
            }"
            @click="changeShareholder(index)"
          >
            {{ `Shareholder-${index}` }}
          </div>
        </div>
      </div>
      <hr class="col-span-4 lg:col-span-4 border-gray-300" v-if="data.numberOfShareholders" />
      <div class="col-span-4 lg:col-span-4 shareholder-form" v-show="data.numberOfShareholders && activeShareholder">
        <ShareholderDirectorForm
          :translations="translations"
          :accountType="accountType"
          :index="activeShareholder"
          :dataArray="data.shareholders"
          :type="'Shareholder'"
          :prefilledTestData="prefilledTestData"
          :testData="testData"
        />
      </div>
      <div class="col-span-4 lg:col-span-4 directors-buttons-wrapper grid grid-cols-4-default gap-5" v-if="data.numberOfDirectors">
        <div :class="`director-${index}`" v-for="index in data.numberOfDirectors" :key="index">
          <div
            class="director-title px-[25px] py-[11px] bg-gray-400 rounded-md cursor-not-allowed opacity-40 font-display font-bold text-[18px]"
            :class="{ '!cursor-pointer': directorErrors[index - 1].length === 0 && proceed, 'opacity-100 border border-blue !cursor-pointer': activeDirector === index }"
            @click="changeDirector(index)"
          >
            {{ `Director-${index}` }}
          </div>
        </div>
      </div>
      <hr class="col-span-4 lg:col-span-4 border-gray-300" v-if="data.numberOfDirectors" />
      <div class="col-span-4 lg:col-span-4 directors-wrapper" v-show="data.numberOfDirectors && activeDirector">
        <ShareholderDirectorForm
          :translations="translations"
          :accountType="accountType"
          :index="activeDirector"
          :dataArray="data.directors"
          :type="'Director'"
          :prefilledTestData="prefilledTestData"
          :testData="testData"
        />
      </div>
    </div>
    <div class="button-wrapper flex justify-between md:justify-start gap-[5%]">
      <button class="w-full max-w-[185px] bg-white text-blue border border-blue rounded-md font-body font-bold text-[16px] h-[50px] px-[2%]" @click="changePage()">
        {{ $t('previous') }}
      </button>
      <button class="w-full max-w-[185px] bg-blue text-white rounded-md font-body font-bold text-[16px] h-[50px] px-[2%]" @click="changePage(true)">
        {{ $t('continue').tTitle() }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import ShareholderDirectorForm from '../registerComponents/ShareholderDirectorForm.vue'
export default {
  components: {
    ShareholderDirectorForm,
  },
  props: {
    accountType: String,
    activeStep: Number,
    isCompany: Boolean,
    translations: Object,
    formData: Object,
    upgrade: Boolean,
    prefilledTestData: Boolean,
    testData: Object,
  },
  data() {
    return {
      page: this.activeStep,
      activeShareholder: 1,
      activeDirector: 1,
      monthDayIndexes: Array.from({ length: 31 }, (v, i) => ++i),
      years: Array.from({ length: 100 }, (v, i) => moment().year() - i + 1),
      data: {
        numberOfShareholders: 0,
        numberOfDirectors: 0,
        shareholders: [{}, {}, {}, {}],
        directors: [{}, {}, {}, {}],
        ib_main_target_region: '',
      },

      shareholderErrors: [[], [], [], []],
      directorErrors: [[], [], [], []],
      proceed: false,
    }
  },
  beforeMount() {
    if (this.formData.numberOfShareholders > 0 || this.formData.numberOfDirectors > 0) {
      this.data.numberOfShareholders = this.formData.numberOfShareholders
      this.data.numberOfDirectors = this.formData.numberOfDirectors
      this.liveShareholderArray = this.formData.shareholders
      this.liveDirectorArray = this.formData.directors
    }
  },
  created() {
    this.$validator.reset()
    this.errors.clear()
    this.$bus.$on('ChangeShareholderData', this.shareholderDataChange)
    this.$bus.$on('ChangeDirectorData', this.directorDataChange)
    this.$bus.$on('PopulateValidationErrors', this.populateValidationErrors)
    this.$bus.$emit('ValidateForms')
    if (this.prefilledTestData && this.testData) {
      Object.keys(this.testData).forEach(key => {
        this.data[key] = this.testData[key]
      })
    }
  },
  watch: {
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
      this.$bus.$emit('ValidateForms')
    },
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
      BonusConfig: 'bonusconfig/all',
    }),
    companyActive() {
      this.$validator.reset()
      return this.isCompany
    },
    liveShareholderArray: {
      get() {
        return this.data.shareholders
      },
      set(newValue) {
        this.data.shareholders = newValue
        return
      },
    },
    liveDirectorArray: {
      get() {
        return this.data.directors
      },
      set(newValue) {
        return (this.data.directors = newValue)
      },
    },
  },
  methods: {
    changePage(nextStep) {
      if (nextStep) {
        this.$bus.$emit('ValidateForms', null, true)
        this.$validator.validateAll().then(result => {
          if (result) {
            let readyToGoOn = true
            this.liveDirectorArray.forEach((director, index) => {
              if (index < this.data.numberOfDirectors && Object.keys(director).length === 0) {
                readyToGoOn = false
                document
                  .querySelector(`.director-${index + 1}`)
                  .querySelector('.director-title')
                  .classList.add(...['border', 'border-red']),
                  document.querySelector(`.director-${index + 1}`).scrollIntoView({ behavior: 'smooth', block: 'center' })
              }
            })
            this.liveShareholderArray.forEach((shareholder, index) => {
              if (index < this.data.numberOfShareholders && Object.keys(shareholder).length === 0) {
                readyToGoOn = false
                document
                  .querySelector(`.shareholder-${index + 1}`)
                  .querySelector('.shareholder-title')
                  .classList.add(...['border', 'border-red']),
                  document.querySelector(`.shareholder-${index + 1}`).scrollIntoView({ behavior: 'smooth', block: 'center' })
              }
            })
            this.$bus.$emit('DataSubmitted', this.data)
            if (this.errors.items.length > 0) {
              let el = document.getElementsByName(this.errors.items[0].field)[0]
              if (readyToGoOn && el) return el.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
            if (this.proceed && readyToGoOn) return this.$bus.$emit('ChangePage', this.page + 1)
          }
        })
      } else return this.$bus.$emit('DataSubmitted', this.data), this.$bus.$emit('ChangePage', this.page - 1)
    },
    shareholderDataChange(data) {
      this.data.shareholders[data.index - 1] = data.data
      this.liveShareholderArray = this.data.shareholders
      this.$bus.$emit('DataSubmitted', this.data)
    },
    directorDataChange(data) {
      this.data.directors[data.index - 1] = data.data
      this.liveDirectorArray = this.data.directors

      this.$bus.$emit('DataSubmitted', this.data)
    },
    populateValidationErrors(errors) {
      this.shareholderErrors = errors.shareholderErrors
      this.directorErrors = errors.directorErrors
      let err = []
      this.shareholderErrors.forEach(arr => {
        if (arr.length > 0) err.push(arr.flat())
      })
      this.directorErrors.forEach(arr => {
        if (arr.length > 0) err.push(arr.flat())
      })
      if (err.flat().length > 0 && this.data.numberOfShareholders > 0 && this.data.numberOfDirectors > 0) return (this.proceed = false)
      else return (this.proceed = true)
    },
    changeShareholder(index) {
      if (this.shareholderErrors[this.activeShareholder - 1].length === 0 && this.proceed) return (this.activeShareholder = index), this.$bus.$emit('ValidateForms', null, true)
    },
    changeDirector(index) {
      if (this.directorErrors[this.activeShareholder - 1].length === 0 && this.proceed) return (this.activeDirector = index), this.$bus.$emit('ValidateForms', null, true)
    },
  },
}
</script>
<style scoped></style>
