<template>
  <div class="lg:px-0 mt-32 md:mt-0">

    <p class="ltr:maxMd:pl-5 rtl:maxMd:pr-5 font-display text-2xl font-bold mb-4 text-gray-900">{{title}}</p>

    <div
      class="fixed l150:w-max maxMd:w-full  l150:overflow-x-hidden  md:relative z-[2] md:z-auto top-[70px] md:top-auto py-3  px-6 md:px-0 bg-white md:bg-transparent ltr:left-0 ltr:md:left-auto  grid grid-flow-col gap-2"
      :class="[ `xl:grid-cols-${matchedRoutes.length} lg:grid-cols-${matchedRoutes.length} md:grid-cols-${matchedRoutes.length}` ,  needsScroll ? 'custom-scrollbar  scroll-smooth overflow-x-scroll ' : '' , matchedRoutes.length <=3 ? 'w-max':''] "
      id="sub-menu-items"
      ref="subMenuItems"
    >
      <div
        v-for="(route, i) in matchedRoutes"
        :key="`sub-menu-routes-${i}`"
        class="cursor-pointer text-center text-sm text-blue p-2 min-w-[160px] rounded-3xl hover:bg-blue-100"
        :class="{ 'bg-blue-200 font-bold': route.isActive }"
        @click="$router.push(pathWithLanguage(route.path))"
      >
          {{ route.title }}
        <!-- <div
          class="md:hidden w-full h-1 rounded-3xl mt-5"
          :class="route.isActive ? 'bg-blue' : 'bg-gray-300'"
        >
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    title: String,
    routes: Array,
  },
  data(){
    return {
     needsScroll: false
    }
  },
   watch: {
    matchedRoutes() {
      this.$nextTick(this.checkOverflow);
    }
  },
    beforeDestroy() {
    window.removeEventListener('resize', this.checkOverflow);
  },
  computed: {
    matchedRoutes() {
      return this.routes.map(route => ({ ...route, isActive: this.pathWithoutLanguage(this.$route.fullPath) === this.pathWithoutLanguage(route.path) }))
    },
    ...mapGetters({
      Language: 'language/code',
    }),
  },
  mounted() {
    this.dragContainer('#sub-menu-items')
    this.alignActiveRouteOnMobile()
     this.checkOverflow();
    window.addEventListener('resize', this.checkOverflow); // Recheck on window resize
  },
  methods: {
    alignActiveRouteOnMobile() {
      const activeRouteIndex = this.matchedRoutes.findIndex(r => r.isActive)
      if (activeRouteIndex) {
        document.getElementById('sub-menu-items').scrollLeft += 160 * activeRouteIndex - 80
      }
    },
    pathWithLanguage(path) {
      if (!path.startsWith('/')) {
        path = '/' + path
      }
      if (path.endsWith('/')) {
        path = path.slice(0, -1)
      }
      let parts = path.split('/')
      if (parts[1].length === 2) {
        return parts.join('/')
      }
      parts = parts.filter(p => p.length > 0)
      parts.unshift('', this.Language)
      return parts.join('/')
    },
    pathWithoutLanguage(path) {
      if (!path.startsWith('/')) {
        path = '/' + path
      }
      if (path.endsWith('/')) {
        path = path.slice(0, -1)
      }
      let parts = path.split('/')
      if (parts[1].length === 2) {
        parts.splice(1, 1)
      }
      return parts.join('/')
    },
     checkOverflow() {
      const container = this.$refs.subMenuItems;
      this.needsScroll = container.scrollWidth > container.clientWidth;
    }

  },
}
</script>

<style>
.custom-scrollbar::-webkit-scrollbar {
  height: 4px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: #E9EDF4;
  border-radius: 12px;
}
 .custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 12px;
  border: 3px solid #2b63e3;
}

</style>
