<template>
  <div>
    <div
      class="w-full flex flex-wrap justify-center bg-blue items-center relative z-50"
      style="height: 70px;"
    >
      <router-link :to="'/' + Language + '/'">
        <img
          width="176"
          src="../../assets/img/plexytrade-logo-white.svg"
        />
      </router-link>
      <div class="absolute ltr:right-4 rtl:left-4 top-4">
          <language-popover></language-popover>
      </div>
    </div>
    <div class="flex justify-center items-center h-calc1 relative z-0 m-2 md:m-0">
      <div class="bg-white rounded-lg shadow-ts1 px-5 md:px-7 py-12 w-full md:w-[36rem] max-w-[580px]">
        <h1 class="text-blue font-display font-bold text-3xl md:text-[2.5rem] pb-10 text-center">{{$t('forgot_password').tTitle()}}</h1>
        <p class="text-gray-900 text-base leading-7 w-full md:w-[24.5rem] m-auto text-center pb-10">{{$t('forgot_password_text')}}</p>
        <form v-on:submit.prevent="validateCaptcha">
          <div class="mb-10">
            <label
              for="email"
              class="text-base text-gray-900"
            >{{$t('email').tTitle()}}</label>
            <input
              type="email"
              name="email"
              id="email"
              v-validate="'required'"
              class="rounded-md bg-gray-100 ltr:pl-5 rtl:pr-5 w-full h-12 mt-2 text-base text-gray-900 font-semibold focus:outline-none focus:ring-0 border border-gray-100 border-solid focus:border-gray-900 placeholder:text-gray-900 placeholder:text-opacity-20"
              placeholder="email@example.org"
              v-model="data.body.email"
            />
          </div>
          <button class="bg-blue text-white text-center text-base font-bold h-[3.1rem] w-[12.3rem] rounded-md m-auto table appearance-none mb-10">{{$t('submit').tTitle()}}</button>
          <p class="text-gray-900 w-full text-center text-[11px] md:text-xs border-t border-solid border-[#DEDFE2] pt-10">{{$t('risk_sentence')}}<a
              class="font-bold ltr:pl-1 rtl:pr-1"
              :href="privacyPolicyLink"
              target="_blank"
            >{{$t('privacy_policy').tTitle()}}</a></p>
        </form>
      </div>
    </div>
    <div class="max-w-[1399px] relative m-auto">
      <footer-collapsible></footer-collapsible>
    </div>

  </div>
</template>

<script>
import { service } from '../../main.js'
import config from '../../config'
import { mapActions, mapGetters } from 'vuex'
import FooterCollapsible from '../../components/FooterCollapsible.vue'
import LanguagePopover from '@/components/Language.vue'
import links from '../../config/links'


export default {
  components: {
    FooterCollapsible,
    LanguagePopover
  },
  data() {
    return {
      showLoader: false,
      data: {
        body: {
          email: null,
          recaptchaToken: null
        },
      },
      error: null,
    }
  },
  computed: {
    VPSLink(){
      return links.getLink('vpsToolsHosting', this.Language )
    },
    privacyPolicyLink() {
      return links.getLink('privacyPolicy', this.Language);
    },
    ...mapGetters({
      Language: 'language/code',
      logoLoaderActive: 'logoLoader/active',
    }),
  },
  mounted() {
    this.$bus.$emit('ChangeHeadings', this.$t('forgot_password').tCapitalizeFirstLetter(), null)
    this.data.body.email = ''

    if (this.$auth.check()) {
      this.$router.push('/' + (this.Language || 'en') + '/' + config.defaultURL)
    }
  },
  methods: {
    validateCaptcha() {
      this.$recaptchaLoaded()
      this.recaptchaToken = this.$recaptcha('login')

      this.recaptchaToken.then(value => {
        this.sendResetPasswordLink(value)
      }).catch(err => {
        console.log(err)
      })
    },
    sendResetPasswordLink(token) {
      if (this.data.body.email == '') {
        document.getElementById('email').focus()
        this.$toasted.error(this.$t('please_enter_your_email'), { duration: 10000 })
        return
      }

      this['logoLoader/set'](true)
      service
        .post('/users/forgot-password?isClient=1', {
          email: this.data.body.email.trim(),
            recaptchaToken: token
        })
        .then(response => {
          this['logoLoader/set'](false)
          if (response.data.success) {
            this.$toasted.success(this.$t('forgot_password_submit_success_message', { email: this.data.body.email }).tTitle(), { duration: 10000 })
          } else {
            if (typeof response.data.messageID !== 'undefined') {
              this.$toasted.error(this.$t(response.data.messageID).tTitle(), { duration: 10000 })
            }
          }
          this.data.body.email = ''
        })
        .catch(() => {
          this['logoLoader/set'](false)
          this.$toasted.error(this.$t('internal_error'), { duration: 10000 })
        })
    },
    ...mapActions(['logoLoader/set']),
  },

  filters: {
    json: function (value) {
      return value
    },
  },
}
</script>
