<template>
  <div class="grid grid-cols-5 grid-flow-row lg:grid-flow-col gap-4">
    <div class="col-span-5 lg:col-span-2">
      <div class="border-t border-[#707070] mb-4 divide-gray-900"></div>
      <div class="text-gray-900 ltr:text-left rtl:text-right">
        <h5 class="text-sm font-bold">{{ $t('please_note') }}</h5> <br>
        <p class="opacity-70 text-[13px] pb-4">{{ $t('bank_statement_text_1') }}</p>
        <p class="opacity-70 text-[13px] pb-4">{{ $t('bank_statement_text_2') }}</p>
      </div>
    </div>
    <div class="col-span-5 lg:col-span-3 rounded-lg border-[0.75px] border-gray-300 bg-blue-light">
      <div class="flex justify-center py-12 px-4">
        <div class="flex flex-col max-w-[377px]">
          <div>
            <img :src="require(`../../../../assets/img/bank_1.svg`)" class="w-[377px]" alt="">
            <div class="mt-6 p-4 bg-[#FFFFFF8D] rounded-lg">
              <p class="text-sm text-blue">{{ $t('bank_statement_image_text_1') }}</p>
            </div>
          </div>
          <div class="pt-8">
            <img :src="require(`../../../../assets/img/bank_2.svg`)" class="w-[377px]" alt="">
            <div class="mt-6 p-4 bg-[#FFFFFF8D] rounded-lg">
              <p class="text-sm text-blue">{{ $t('bank_statement_image_text_2') }}</p>
            </div>
          </div>
          <div class="pt-8">
            <img :src="require(`../../../../assets/img/bank_3.svg`)" class="w-[377px]" alt="">
            <div class="mt-6 p-4 bg-[#FFFFFF8D] rounded-lg">
              <p class="text-sm text-blue">{{ $t('bank_statement_image_text_3') }}</p>
            </div>
          </div>
          <div class="pt-8">
            <img :src="require(`../../../../assets/img/bank_4.svg`)" class="w-[377px]" alt="">
            <div class="mt-6 p-4 bg-[#FFFFFF8D] rounded-lg">
              <p class="text-sm text-blue">{{ $t('bank_statement_image_text_4') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'bank-statement',
  data() {
    return {}
  },
  computed: {},
  mounted() { },
  methods: {},
}
</script>
