const disableAutocomplete = {
  install(Vue) {
    Vue.mixin({
      mounted() {
        let elements = document.querySelectorAll('[autocomplete="off"]')

        if (!elements) {
          return
        }

        elements.forEach(element => {
          element.setAttribute('readonly', 'readonly')
            setTimeout(() => {
              element.removeAttribute('readonly')
              if(document.querySelector('#logo-loader')) document.querySelector('#logo-loader').classList.add('hidden')
            }, 300)
          })
      },
    })
  },
}

export default disableAutocomplete
