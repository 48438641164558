<template>
  <div
    class="content has-text-left"
  >
    <div v-show="modal.subIBClients.active">

      <div class="fixed inset-0 z-50 bg-gray-900 bg-opacity-70 w-screen h-screen">
        <div
          id="ClientDetailsInner"
          class="flex flex-col items-center lg:items-end ltr:right-0 rtl:left-0 top-0 z-30 overflow-x-hidden overflow-y-scroll h-full w-full lg:w-auto justify-center"
        >
          <div class="w-11/12 max-w-[1540px]">
            <button
              @click="modal.subIBClients.active = false"
              class="bg-white rounded-full h-10 w-10 lg:self-start appearance-none flex-shrink-0 mt-2 p-0"
            >
              <div class="h-6 w-0.5 bg-blue rotate-45 ltr:ml-5 rtl:mr-5"></div>
              <div class="h-6 w-0.5 bg-blue -rotate-45 -mt-6 ltr:ml-5 rtl:mr-5"></div>
            </button>
          </div>
          <div class="bg-white ltr:rounded-br-xl ltr:lg:rounded-br-none ltr:rounded-tr-xl ltr:lg:rounded-tr-none ltr:rounded-tl-xl ltr:rounded-bl-xl rtl:rounded-bl-xl rtl:lg:rounded-bl-none rtl:rounded-tl-xl rtl:lg:rounded-tl-none rtl:rounded-tr-xl rtl:rounded-br-xl py-8 md:py-12 pb-8 md:pb-24 px-5 md:px-10 lg:px-24 w-11/12 max-w-[1540px] relative mt-4 mb-20 md:mb-0 lg:max-h-[700px] lg:h-full">
            <sub-ib-clients :IBID="modal.subIBClients.subIBID"></sub-ib-clients>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="pagination.pageSubIBs.length>0"
      class="mx-5 lg:mx-7"
    >
      <table
        id="ib-subibs"
        class="mt-10 w-full hidden lg:table"
      >
        <thead>
          <tr>
            <th class="ltr:pl-7 rtl:pr-7 text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{ $t('status').tTitle() }}</th>
            <th class="text-blue font-bold text-base pb-4 cursor-pointer ltr:text-left rtl:text-right"
              :class="orderClass('date')"
              @click="changeOrderBy('date')"
            >
              <div class="flex">
                {{$t("date_joined").tTitle()}}
                <img
                  class="w-3 h-2 mt-2.5 ltr:ml-1.5 rtl:mr-1.5"
                  src="../../assets/img/platform-dropdown.svg"
                />
              </div>
            </th>
            <th :class="orderClass('fullName')"
              @click="changeOrderBy('fullName')" class="text-blue font-bold text-base pb-4 cursor-pointer ltr:text-left rtl:text-right">
              <div class="flex">
                {{$t('full_name').tTitle()}}
                <img
                  class="w-3 h-2 mt-2.5 ltr:ml-1.5 rtl:mr-1.5"
                  src="../../assets/img/platform-dropdown.svg"
                />
              </div>
            </th>
            <th class="text-blue font-bold text-base pb-4 cursor-pointer ltr:text-left rtl:text-right"
             :class="orderClass('commission')"
              @click="changeOrderBy('commission')"
            >
              <div class="flex">
                {{$t('total_commission_generated').tTitle()}}
                <img
                  class="w-3 h-2 mt-2.5 ltr:ml-1.5 rtl:mr-1.5"
                  src="../../assets/img/platform-dropdown.svg"
                />
              </div>
            </th>
            <th class="text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{ $t('total_deposits').tTitle() }}</th>
            <th class="text-blue font-bold text-base pb-4  ltr:text-left rtl:text-right">{{ $t('total_withdrawals').tTitle() }}</th>
            <th class="text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="subIB in pagination.pageSubIBs"
            :key="`sub-ib-${subIB.sub_ib_id}`"
            class="odd:bg-[#F0F0F4]"
          >
            <td class="ltr:pl-7 rtl:pr-7 text-base text-gray-900 py-3 ltr:rounded-tl-md ltr:rounded-bl-md rtl:rounded-tr-md rtl:rounded-br-md">{{ $statusFormat(subIB.status) }}</td>
            <td class="text-base text-gray-900 py-3">{{ $formatDate(subIB.date_joined) }}</td>
            <td class="text-base text-gray-900 py-3">{{ subIB.full_name }}</td>
            <td class="text-base text-gray-900 py-3">
              <span v-if="subIB.total_parent_commission_generated">
                {{ $formatMoney(subIB.total_parent_commission_generated, currencies['USD'].symbol) }}
              </span>
            </td>
            <td class="text-base text-gray-900 py-3">
              <span
                v-for="(value, currency) in subIB.total_deposits"
                :key="`total-${currency}-deposits`"
              >
                {{ $formatMoney(value, currencies[currency].symbol) }}
              </span>
            </td>
            <td class="text-base text-gray-900 py-3">
              <span
                v-for="(value, currency) in subIB.total_withdrawals"
                :key="`total-${currency}-withdrawals`"
              >
                {{ $formatMoney(value, currencies[currency].symbol) }}
              </span>
            </td>
            <td class="text-sm  py-3 ltr:rounded-tr-md ltr:rounded-br-md rtl:rounded-tl-md rtl:rounded-bl-md ltr:pr-5 rtl:pl-5">
              <a
                class="bg-blue text-white rounded-md p-2 cursor-pointer"
                @click="openSubIBDetailsModal(subIB.sub_ib_id)"
              >
                <span>{{$t('clients').tTitle()}}</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="w-full lg:hidden mt-10">
        <div
          v-for="(subIB,i) in pagination.pageSubIBs"
          :key="`sub-ib-${subIB.sub_ib_id}`"
          :id="`sub-ib-${subIB.sub_ib_id}`"
          class="px-4 pt-3 rounded-lg odd:bg-[#F0F0F4] overflow-hidden"
        >
          <div
            @click="expandIBRow(i)"
            class="flex justify-between cursor-pointer pb-3"
          >
            <label class="w-1/2 block text-blue font-bold">
              <img
                :id="'arrow-subib-'+i"
                width="12px"
                height="12px"
                class="arrow-subib ltr:mr-1 rtl:ml-1 inline"
                :class="{'-rotate-90': !subIB.expanded}"
                src='../../assets/img/platform-dropdown.svg'
              >
              {{$t('full_name').tTitle()}}
            </label>
            <p class="w-1/2 ltr:text-right rtl:text-left">{{ subIB.full_name }}</p>
          </div>
          <div
            :id="'content-subib-'+i"
            class="row-subib"
            :class="{'hidden': !subIB.expanded}"
          >
            <div class="flex justify-between">
              <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("status").tTitle()}}</label>
              <p class="h-9">{{ $statusFormat(subIB.status) }}</p>
            </div>
            <div class="flex justify-between">
              <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("date_joined").tTitle()}}</label>
              <p class="h-9">{{ $formatDate(subIB.date_joined) }}</p>
            </div>
            <div class="flex justify-between">
              <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("total_commission").tTitle()}}</label>
              <p class="h-9"><span v-if="subIB.total_parent_commission_generated">
                  {{ $formatMoney(subIB.total_parent_commission_generated, currencies['USD'].symbol) }}
                </span></p>
            </div>
            <div class="flex justify-between tableCarousel">
              <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("total_deposits").tTitle()}}</label>
              <VueSlickCarousel
                v-if="subIB.total_deposits"
                :dots="false"
                :infinite="true"
                :slidesToShow=1
                :speed=500
                class="w-[100px]"
              >
                <p class="h-9">
                  <span
                    v-for="(value, currency) in subIB.total_deposits"
                    :key="`total-${currency}-deposits`"
                    class="ltr:pl-3 rtl:pr-3"
                  >{{ $formatMoney(value, currencies[currency].symbol) }}</span>
                </p>
              </VueSlickCarousel>
            </div>
            <div class="flex justify-between tableCarousel">
              <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("total_withdrawals").tTitle()}}</label>
              <VueSlickCarousel
                v-if="subIB.total_withdrawals"
                :dots="false"
                :infinite="true"
                :slidesToShow=1
                :speed=500
                class="w-[100px]"
              >
                <p class="h-9">
                  <span
                    v-for="(value, currency) in subIB.total_withdrawals"
                    :key="`total-${currency}-withdrawals`"
                    class="ltr:pl-3 rtl:pr-3"
                  >{{ $formatMoney(value, currencies[currency].symbol) }}</span>
                </p>
              </VueSlickCarousel>
            </div>
            <div class="ltr:ml-5 rtl:mr-5 h-9 mb-3 mt-2">
              <a
                class="bg-blue text-white rounded-md p-2 cursor-pointer"
                @click="openSubIBDetailsModal(subIB.sub_ib_id)"
              >
                <span>{{$t('clients').tTitle()}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="mt-10"
    >
      <p class="text-center text-base text-blue font-semibold">{{$t('no_records')}}</p>
    </div>

    <!-- <pagination
      for="ib-client"
      :records="pagination.total"
      :per-page="pagination.maxItemsPerPage"
      :chunk="4"
      :count-text="pagination.total + ' ' + $t('records')"
    ></pagination> -->
    <div
      v-if="pagination.pageSubIBs.length>0"
      class="flex justify-between w-full py-5 mx-5"
    >
      <div class="w-[100px] h-[40px] font-body text-sm leading-10">{{IBSubIBs.length}} {{$t('records')}}</div>
      <div
        v-if="IBSubIBs.length > pagination.rowsPerPage"
        class="flex h-[40px] font-body text-center leading-10"
      >
        <pagination-component
          :page="pagination.page"
          :maxPerPage="pagination.rowsPerPage"
          :total="IBSubIBs.length"
        />
      </div>
      <div class="w-[100px]"></div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { service } from '../../main'
import config from '../../config'
import SubIbClients from './Clients.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import PaginationComponent from '../../components/Pagination.vue'
import moment from 'moment'
import { exportCSVFile } from '@/utils/csvUtils.js';

export default {
  components: {
    SubIbClients,
    VueSlickCarousel,
    PaginationComponent,
  },
  data() {
    return {
      IBSubIBs: [],
      currencies: config.currencies,
      pagination: {
        page: 1,
        rowsPerPage: 7,
        pageSubIBs: [],
      },

      modal: {
        subIBClients: {
          active: false,
          subIBID: null,
        },
      },
      orderBy: 'date',
      orderMode: 'desc',
      dateFrom: '',
      dateTo: '',
      nameSearch: '',
      SubIBClientsArgs:{}
    }
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      Requests: 'requests/all',
      Language: 'language/code',
      logoLoaderActive: 'logoLoader/active',
    }),
  },
  watch: {
    'pagination.page'() {
      this.changePage()
    },
    'modal.subIBClients.active'(val){
      if (val == true) {
        this.disableScroll()
      } else {
        this.enableScroll()
      }
    },
  },
  mounted() {
    // clients pagination

    this.getIBSubIBs(1)
  },
  created() {
    this.$bus.$on('pageChanged', p => {
      this.pagination.page = p
    }),
    this.$on('export-csv', this.exportCSVData);

  },
  beforeDestroy() {
    this.$bus.$off('pageChanged', p => {
      this.pagination.page = p
    })
  },
  methods: {
    openSubIBDetailsModal(subIBID) {
      // Load sub IB clients like Clients.vue.
      this.modal.subIBClients.subIBID = subIBID
      this.modal.subIBClients.active = true
      // this.$emit('subIBID', {subIBID: subIBID,  modalState:this.modal.subIBClients.active}  )
    },
    orderClass(name) {
      return this.orderBy === name ? this.orderMode : ''
    },
     changeOrderBy(orderBy) {
      if (this.orderBy === orderBy) {
        this.orderMode = this.orderMode === 'desc' ? 'asc' : 'desc'
      } else {
        this.orderMode = 'desc'
      }
      this.orderBy = orderBy
      this.getIBSubIBs(1)
    },
    getIBSubIBs(page) {
      if (isNaN(parseInt(page))) {
        setTimeout(() => {
          this.getIBClients(1)
        }, 200)
        return
      }
      if (!page) {
        if (this.pagination.page) {
          page = this.pagination.page
        } else {
          page = 1
        }
      }

      let dateTimeFrom = this.dateFrom
      let dateTimeTo = this.dateTo

      if (dateTimeFrom != '') {
        dateTimeFrom += ' 00:00:00'
      }
      if (dateTimeTo != '') {
        dateTimeTo += ' 23:59:59'
      }

      let dateFromUnix = moment(dateTimeFrom, 'YYYY-MM-DD hh:mm:ss').utcOffset(0, true).unix()
      let dateToUnix = moment(dateTimeTo, 'YYYY-MM-DD hh:mm:ss').utcOffset(0, true).unix()

      if (isNaN(dateFromUnix)) {
        dateFromUnix = 0
      }
      if (isNaN(dateToUnix)) {
        dateToUnix = 0
      }
      this.SubIBClientsArgs = {
          userID: this.User.info.ID,
          page: -1,
          orderBy: this.orderBy,
          orderMode: this.orderMode || 'desc',
          dateFrom: dateFromUnix,
          dateTo: dateToUnix,
          nameSearch: this.nameSearch,
      }
      this['logoLoader/set'](true)
      service
        .post('/ib/sub-ibs', {
          userID: this.User.info.ID,
          page: page,
          orderBy: this.orderBy,
          orderMode: this.orderMode || 'desc',
          dateFrom: dateFromUnix,
          dateTo: dateToUnix,
          nameSearch: this.nameSearch,
        })
        .then(response => {
          if (typeof response.data.IBSubIBs !== 'undefined') {
            this.IBSubIBs = response.data.IBSubIBs
            this.pagination.total = response.data.CountIBSubIBs
          }
          this.changePage()
          this['logoLoader/set'](false)
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    },
    expandIBRow(i) {
      this.$set(this.pagination.pageSubIBs, i, { ...this.pagination.pageSubIBs[i], expanded: !this.pagination.pageSubIBs[i].expanded })
    },
    changePage() {
      this.pagination.pageSubIBs = []

      let start = (this.pagination.page - 1) * this.pagination.rowsPerPage
      let end = start + this.pagination.rowsPerPage

      for (let i = start; i < end; i++) {
        if (this.IBSubIBs[i] === undefined) {
          break
        }
        this.IBSubIBs[i].expanded = false
        this.pagination.pageSubIBs.push(this.IBSubIBs[i])
      }
    },
    disableScroll() {
    document.querySelector('body').classList.add('overflow-hidden')
    },
    enableScroll() {
    document.querySelector('body').classList.remove('overflow-hidden')
    },
    async exportCSVData() {
      let CsvData;

      try {
        const response = await service.post('ib/sub-ibs', this.SubIBClientsArgs);
        if (response && response.data && response.data.IBSubIBs) {
          CsvData = response.data.IBSubIBs;
        }
      } catch (error) {
        return;
      }

      const headers = {
        sub_ib_id: 'Sub IB ID',
        date_joined: 'Date Joined',
        full_name: 'Full Name',
        ib_id: 'Ib ID',
        status: 'Status',
      };

      const itemsFormatted = CsvData.map(item => ({
        sub_ib_id: item.sub_ib_id,
        date_joined: item.date_joined,
        full_name: item.full_name,
        ib_id: item.ib_id,
        status: item.status,
      }));

      const fileTitle = 'SubIBs';
      exportCSVFile(headers, itemsFormatted, fileTitle);
    },
    ...mapActions(['logoLoader/set', 'user/setIB']),
  },
}
</script>
<style>
.tableCarousel .slick-prev,
.tableCarousel .slick-next {
  text-indent: -999999px;
  margin-left: -10px;
  position: absolute;
  top: 0;
}
.tableCarousel .slick-next {
  right: 10px;
}
.tableCarousel .slick-prev:before,
.tableCarousel .slick-next:before {
  content: '';
  background-image: url('../../assets/img/platform-dropdown.svg');
  background-repeat: no-repeat;
  position: absolute;
  width: 30px;
  background-size: 10px 10px;
  background-position: center;
  transform: rotate(90deg);
  inset: 0px;
  z-index: 1;
}

.tableCarousel .slick-next:before {
  transform: rotate(270deg);
}
</style>
