<template>
  <div class="content has-text-centered">
    <failed :reason="failReason"></failed>
  </div>
</template>

<script>
import Failed from './Failed'

export default {
  props: ['reason'],
  components: {
    Failed,
  },
  data() {
    return {
      title: '',
      failReason: '',
    }
  },
  mounted() {
    this.title = this.$t('method_deposit_status', { method: 'SafeCharge' })
    this.$bus.$emit('ChangeHeadings', this.title, 'SafeCharge')

    this.failReason = this.$route.query['reason']

    switch (this.reason) {
      case 'declined':
        this.failReason = 'Transaction was declined.'
        break
      case 'failed':
        this.failReason = 'Transation failed.'
        break
      case 'cancelled':
        this.failReason = 'Transation was cancelled.'
        break
      default:
        this.failReason = 'Generic error.'
    }
  },
  methods: {},
}
</script>
