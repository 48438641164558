<template>
  <div>
    <div class="relative z-10 mb-24 lg:mb-0">
      <div class="verification-wrapper bg-gray-200">
        <div class="code-wrapper flex flex-col justify-evenly bg-white rounded-lg m-auto w-[95%] md:w-[580px] px-[15px] md:px-8 py-12 md:py-[48px]">
          <p v-if="!hasError && !codeExpired && attemptsLeft" class="text-center font-display text-[30px] md:text-[40px] text-blue mb-[55px] leading-[62px] font-bold">
            {{ $t('verify_your_email') }}
          </p>
          <p
            v-else-if="codeExpired && attemptsLeft"
            @click="requestNewCode"
            class="text-center font-display text-[30px] md:text-[40px] text-orange mb-[50px] leading-[62px] font-bold"
          >
            {{ $t('resend_code') }}
          </p>
          <p v-else-if="!attemptsLeft" class="text-center font-display text-[30px] md:text-[40px] text-orange mb-[50px] leading-[62px] font-bold">
            {{ $t('verification_failed') }}
          </p>
          <p v-else class="text-center font-display text-[30px] md:text-[40px] text-orange mb-[50px] leading-[62px] font-bold">{{ $t('try_again') }}</p>

          <div :class="{'border-4 border-orange border-solid pt-[45px] px-3 md:px-0': hasError}">

            <p v-if="!hasError && !codeExpired && attemptsLeft" class="text-center font-body text-[16px] leading-7 md:px-[60px] text-gray-900">
            {{ $t('verify_your_email_description') }}
          </p>
          <p v-else-if="codeExpired" class="text-center font-body text-[16px] leading-7 md:px-[60px] text-gray-900">{{ $t('try_again_expired') }}</p>
          <div v-else-if="!attemptsLeft" class="text-center font-body text-[16px] leading-7 md:px-[60px] text-gray-900">
            <p class="text-center font-body text-[16px] leading-7 md:px-[60px] text-gray-900 font-semibold">{{ $t('verification_failed_header') }}</p>
            <p class="text-center font-body text-[16px] leading-7 md:px-[60px] text-gray-900">{{ $t('verification_failed_description') }}</p>
          </div>
          <div v-else>
            <p class="text-center font-body text-[16px] leading-7 md:px-[60px] text-gray-900 font-semibold">{{ $t('try_again') }}</p>
            <p class="text-center font-body text-[16px] leading-7 md:px-[60px] text-gray-900">{{ $t('try_again_description') }}</p>
          </div>

          <div v-if="countDown" class="w-full mt-[45px]">
            <span class="leading-4 flex align-center text-center flex-col font-display text-green">
              <span class="text-[35px] font-bold">{{ timerCount }}</span>
              <span class="text-base">sec</span>
            </span>
          </div>

          <div class="flex flex-col" v-if="!codeExpired && attemptsLeft">
            <div class="flex gap-2 justify-center md:px-[60px] pb-[21px] pt-[35px] md:pt-[44px] w-fit m-auto" :class="{'md:pt-[25px]': countDown}">
              <div v-for="(digit, index) in verificationCode" :key="index" class="verification-box">
                <input
                  class="w-[42px] h-[50px] text-center"
                  :class="{ 'border-solid border-orange': hasError }"
                  ref="inputs"
                  type="text"
                  v-model="verificationCode[index]"
                  maxlength="1"
                  @input="handleInputChange(index)"
                  @paste="handlePaste"
                />
              </div>
            </div>
            <div v-if="hasError && !codeExpired && attemptsLeft" class="text-center">{{ `${isEmailVerified.attempts}  ${$t('attempts_left')}` }}</div>
            <div class="flex justify-between w-[292px] m-auto md:w-full md:px-[112px] pb-[44px] md:pb-[58px]">
              <p v-if="!hasError" class="w-1/2 text-[12px] text-gray-900">{{ $t('code_not_recieved') }}</p>
              <button v-if="!hasError && attemptsLeft" @click="requestNewCode" class="w-1/2 text-[12px] ltr:text-right rtl:text-left text-green p-0" :class="{'pointer-events-none' : countDown}">{{ $t('resend') }}</button>
            </div>
          </div>

          </div>

          <div class="clock flex justify-center py-8" v-if="codeExpired && !hasError && attemptsLeft">
            <img src="../../assets/img/expired.png" width="71" height="71" />
          </div>
          <div class="button-wrapper text-center mt-10">
            <button
              v-if="!codeExpired && attemptsLeft"
              @click="verifyEmail"
              class="appearance-none bg-blue text-white text-base font-bold rounded-md px-[24px] py-[13px] text-center m-auto mb-[10px]"
            >
              {{ $t('confirm_email') }}
            </button>
            <a
              v-else-if="!codeExpired && !attemptsLeft"
              class="appearance-none bg-blue text-white text-base font-bold rounded-md px-[24px] py-[13px] text-center m-auto mb-[10px]"
              :href="contactURL"
            >
              {{ $t('contact_us') }}
            </a>
            <button
              v-else-if="codeExpired && attemptsLeft"
              @click="requestNewCode"
              class="appearance-none bg-white text-blue border border-solid border-blue text-base font-bold rounded-md px-[24px] py-[13px] text-center m-auto mb-[10px]"
            >
              {{ $t('resend_code') }}
            </button>
            <p class="text-gray-900" v-if="attemptsLeft">
              {{ $t('need_help') }} <a :href="contactURL"><span class="underline">{{ $t('contact_us') }}</span></a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { service } from '../../main.js'
import { mapGetters, mapActions } from 'vuex'
import links from '@/config/links.js'

export default {
  data() {
    return {
      activeMessage: '',
      verificationCode: ['', '', '', '', '', ''],
      hasError: false,
      expired: false,
      redirectToHome: false,
      countDown: false,
      timerCount: 60,
    }
  },
  computed: {
    codeExpired: {
      get() {
        return this.isEmailVerified.attempts > 0 && new Date(new Date(this.isEmailVerified.code_issued_at).getTime() + 60 * 60 * 24 * 1000) <= new Date()
      },
      set(newValue) {
        this.expired = newValue
      },
    },
    attemptsLeft() {
      if (this.isEmailVerified) return this.isEmailVerified.attempts > 0
      return true
    },
    contactURL(){
     return links.getLink('contact', this.Language);
    },
    ...mapGetters({
      Language: 'language/code',
      User: 'user/all',
      isEmailVerified: 'user/isEmailVerified',
    }),
  },
  created() {
    if (this.isEmailVerified.verified === true) return this.$router.replace('/' + this.Language + '/')
    this.codeExpired = this.isEmailVerified.attempts > 0 && new Date(new Date(this.isEmailVerified.code_issued_at).getTime() + 60 * 60 * 24 * 1000) <= new Date()
  },
  mounted() {
    this['logoLoader/set'](false)
    this.$bus.$emit('ChangeHeadings', 'Verify your Email', this.$t('verify_your_email')+ ' | Plexytrade')
  },
  watch: {
    /**
     * Watch for countdown finish. Reset it and ready for next countdown.
     */
    timerCount() {
      if (this.timerCount === 0){
        this.timerCount = 60;
        this.countDown = false;
      }
    },
    /**
     * Watch for countDown if true start the countDown.
     */
    countDown() {
      if(this.countDown) this.countDownTimer();
    }
  },
  methods: {
    verifyEmail() {
      // console.log('jemi ktu')
      this.hasError = false
      const code = this.verificationCode.toString().replaceAll(',', '')
      // console.log(code,length)
      if (code === '') return (this.hasError = true)
      if (code.length < 6) return (this.hasError = true)
      this['logoLoader/set'](true)
      service
        .post('/email/verify', {
          email: this.User.info.email,
          verification_code: code,
        })
        .then(response => {
          this['logoLoader/set'](false)
          if (response.data.error === 'expired') return (this.codeExpired = true)
          if (response.data.error === 'wrong_code') {
            this.$store.commit('user/SET_EMAIL_VERIFIED', {
              attempts: this.isEmailVerified.attempts - 1,
              code_issued_at: this.isEmailVerified.code_issued_at,
              verified: this.isEmailVerified.verified,
            })
            return (this.hasError = true)
          }
          if (response.data.verified) {
            this.$store.commit('user/SET_EMAIL_VERIFIED', {
              attempts: this.isEmailVerified.attempts,
              code_issued_at: this.isEmailVerified.code_issued_at,
              verified: response.data.verified,
            })
            return this.$router.push('/' + this.Language + '/')
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    requestNewCode() {
      this['logoLoader/set'](true)
      service
        .post('/email/request-email-verification', {
          email: this.User.info.email,
        })
        .then(() => {
          this['logoLoader/set'](false)
          this.codeExpired = false
          this.hasError = false
          this.$store.commit('user/SET_EMAIL_VERIFIED', {
            attempts: this.isEmailVerified.attempts,
            code_issued_at: new Date(),
            verified: this.isEmailVerified.verified,
          })
          // Start countDown timer
          this.countDown = true;
        })
        .catch(error => {
          console.log(error)
        })
    },
    handleInputChange(index) {
      if (/^[0-9]$/.test(this.verificationCode[index]) && index < this.verificationCode.length - 1) return this.$refs.inputs[index + 1].focus()
    },
    handlePaste(event) {
      event.preventDefault()
      const pasteData = event.clipboardData.getData('text/plain').trim()
      if(pasteData.length > 6) return
      const digits = pasteData.split('').slice(0, 6)
      digits.forEach((digit, index) => {
        if (/^[0-9]$/.test(digit)) {
          this.$refs.inputs[index].value = digit
          this.verificationCode[index] = digit
        }
      })
    },
    /**
     * Function to start the countdown timer
     */
    countDownTimer() {
      if (this.timerCount > 0) {
        setTimeout(() => {
          this.timerCount -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    ...mapActions([
      'logoLoader/set',
    ]),
  },
}
</script>
<style scoped>
.verification-box {
  display: inline-block;
}
.verification-box input {
  padding: 0;
}
.hasError {
  border: 1px solid;
}
</style>
