<template>
  <div class="content has-text-centered">
    <success></success>
    <div v-if="pending">{{$t("safe_charged_success_message")}}</div>
  </div>
</template>

<script>
import Success from './Success'
// import { service } from '@/main.js'

export default {
  components: {
    Success,
  },
  data() {
    return {
      title: '',
      pending: false,
    }
  },
  mounted() {
    this.title = this.$t('method_deposit_status', { method: 'SafeCharge' })
    this.$bus.$emit('ChangeHeadings', this.title, 'SafeCharge')
    this.pending = this.$route.query['pending'] == 'true' ? true : false
    // direct ipn
    // service.get('/finance/ipn/safecharge/deposit', { params: this.$route.query }, 'useraccounttypes')
  },
  methods: {},
}
</script>
