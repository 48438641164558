<template>
  <div
    class="content"
    id="content_404"
  >
    <div class="bg mt-40 lg:mt-10 px-6 lg:px-0">
      <img
        class="not_found_img max-w-[900px] mx-auto"
        src="./../assets/img/404-bg.svg"
      />
      <h1 class="font-bold text-gray-900 mt-14 text-center text-4xl lg:text-5xl font-display mb-6">{{$t("title_404")}}</h1>
      <p class="text-gray-dark text-base text-opacity-70 leading-8 max-w-xl m-auto text-center">{{$t("text_line_1_404")}}<br />{{$t("text_line_2_404")}}</p>
      <router-link
        v-if="!$auth.check()"
        :to="'/'+Language"
        class="back_button text-center m-auto text-gray-900 text-base table mt-10"
      >
        <img
          class="m-auto mb-4"
          src="./../assets/img/go-back.svg"
          width="57"
        />
        <span>{{$t("button_404")}}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import {  mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      Language: 'language/code',
    }),
  },
  mounted() {
    this.$bus.$emit('ChangeHeadings', '404 Page not Found', null)
  },
}
</script>
