<template>
  <div
    class="h-auto md:h-screen px-2 lg:px-0 md:pt-6 pb-20"
  >
    <div class="w-auto mx-2 lg:mx-0 max-w-[1096px]  bg-white rounded-lg shadow-ts1 pt-5 pb-7 ltr:lg:pr-7 rtl:lg:pl-7">
      <div class="flex justify-between items-center pb-4 border-b border-solid border-gray-300">
        <div>
          <p class="ltr:pl-8 rtl:pr-8 text-blue font-display font-bold text-xl leading-none">{{$t('platforms')}}</p>
        </div>

      </div>
      <div class="relative overflow-hidden ltr:md:pr-5 rtl:md:pl-5 mb-6">
        <div
          id="step1"
          class="transition-all duration-300 ease-in-out ltr:md:pr-5 rtl:md:pl-5"
        >
          <!-- <p class="text-gray-900 text-base pb-7 pl-5 md:pl-8 pt-7">{{$t('select_operating_system')}}</p> -->
          <div class="mx-5 md:mx-8 flex gap-5 flex-col md:flex-row pt-7">
            <div
              class="w-full md:w-1/4 h-[10.5rem] cursor-default flex-shrink-0"
              @click="gotoStep2('windows')"
            >
              <div class="h-full w-full mb-3 bg-white rounded-lg border border-gray-300 relative group ltr:mr-2.5 rtl:ml-2.5 flex justify-center">
                <img
                  class="w-[5.4rem] m-auto"
                  src="../../assets/img/windows.svg"
                />
                <div class="w-full bg-blue bg-opacity-85 absolute inset-0 text-center rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                  <div class="flex justify-center w-full h-full text-center items-center">
                    <p class="text-white font-bold text-base">Windows</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="w-full md:w-1/4 h-[10.5rem] cursor-default flex-shrink-0"
              @click="gotoStep2('android')"
            >
              <div class="h-full w-full mb-3 bg-white rounded-lg border border-gray-300 relative group ltr:mr-2.5 rtl:ml-2.5 flex justify-center">
                <img
                  class="w-[5.4rem] m-auto"
                  src="../../assets/img/android.svg"
                />
                <div class="w-full bg-blue bg-opacity-85 absolute inset-0 text-center rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                  <div class="flex justify-center w-full h-full text-center items-center">
                    <p class="text-white font-bold text-base">Android</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="w-full md:w-1/4 h-[10.5rem] cursor-default flex-shrink-0"
              @click="gotoStep2('ios')"
            >
              <div class="h-full w-full mb-3 bg-white rounded-lg border border-gray-300 relative group ltr:mr-2.5 rtl:ml-2.5 flex justify-center">
                <img
                  class="w-[5.4rem] m-auto"
                  src="../../assets/img/apple.svg"
                />
                <div class="w-full bg-blue bg-opacity-85 absolute inset-0 text-center rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                  <div class="flex justify-center w-full h-full text-center items-center">
                    <p class="text-white font-bold text-base">iOS</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="w-full md:w-1/4 h-[10.5rem] cursor-default flex-shrink-0"
              @click="gotoStep2('webtrader')"
            >
              <div class="h-full w-full mb-3 bg-white rounded-lg border border-gray-300 relative group ltr:mr-2.5 rtl:ml-2.5 flex justify-center">
                <img
                  class="w-[5.4rem] m-auto"
                  src="../../assets/img/webtrader.svg"
                />
                <div class="w-full bg-blue bg-opacity-85 absolute inset-0 text-center rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                  <div class="flex justify-center w-full h-full text-center items-center">
                    <p class="text-white font-bold text-base">Webtrader</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="step2"
          class="absolute w-full ltr:left-full top-0 transition-all duration-300 ease-in-out ltr:pr-5 rtl:pl-5 rtl:right-full"
        >
          <!-- <p class="text-gray-900 text-base pb-7 pl-5 md:pl-8 pt-7">{{$t('select_platform')}}</p> -->
          <div class="mx-5 md:mx-8 flex gap-5 flex-col md:flex-row relative pt-7">
            <div
              @click="gotoStep1()"
              class="absolute ltr:-left-4 ltr:md:-left-5 rtl:-right-4 rtl:md:-right-5 top-[44%] z-10 cursor-pointer"
            >
              <img
                src="/static/img/arrow.svg"
                class="w-3 rotate-180"
              />
            </div>
            <div
              class="w-full md:w-1/4 h-[10.5rem] cursor-default flex-shrink-0"
              @click="downloadPlatform('mt4')"
            >
              <div class="h-full w-full mb-3 bg-white rounded-lg border border-gray-300 relative group ltr:mr-2.5 rtl:ml-2.5 flex justify-center">
                <img
                  class="w-[5.4rem] m-auto"
                  src="../../assets/img/mt4.svg"
                />
                <div class="w-full bg-blue bg-opacity-85 absolute inset-0 text-center rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                  <div class="flex justify-center w-full h-full text-center items-center">
                    <p class="text-white font-bold text-base">MetaTrader 4</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="w-full md:w-1/4 h-[10.5rem] cursor-default flex-shrink-0"
              @click="downloadPlatform('mt5')"
            >
              <div class="h-full w-full mb-3 bg-white rounded-lg border border-gray-300 relative group ltr:mr-2.5 rtl:ml-2.5 flex justify-center">
                <img
                  class="w-[5.4rem] m-auto"
                  src="../../assets/img/mt5.svg"
                />
                <div class="w-full bg-blue bg-opacity-85 absolute inset-0 text-center rounded-lg opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                  <div class="flex justify-center w-full h-full text-center items-center">
                    <p class="text-white font-bold text-base">MetaTrader 5</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 h-[10.5rem] cursor-default flex-shrink-0 hidden md:block">
              <div class="h-full w-full mb-3 bg-white ltr:mr-2.5 rtl:ml-2.5 flex justify-center">

              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="ltr:ml-8 rtl:mr-8 flex space-x-3 rtl:space-x-reverse mb-12 relative z-10 "
        :class="[!showDownloadStep1?'-mt-44 md:mt-0':'mt-0']"
      >
        <div
          @click="gotoStep1()"
          :class="[showDownloadStep1?'bg-blue w-10':'w-4 bg-gray-300']"
          class="h-0.5 rounded-xl cursor-pointer"
        ></div>
        <div
          :class="[!showDownloadStep1?'bg-blue w-10':'w-4 bg-gray-300']"
          class="h-0.5 rounded-xl"
        ></div>
      </div>
      <a
        id="downloadLink"
        target="_blank"
        :class="[selectedOperatingSystem=='webtrader'?'w-48':'w-40']"
        class="bg-gray-300 text-center text-white rounded-md py-3 text-base font-bold table ltr:ml-7 rtl:mr-7 mb-4 relative z-10"
      >
        <span v-if="selectedOperatingSystem=='webtrader'">{{$t('launch_webtrader').tTitle()}}</span>
        <span v-else>{{$t('download').tTitle()}}</span>
      </a>
    </div>

  </div>
</template>

<script>
// import { service } from '../../main.js'
import { mapGetters } from 'vuex'
import config from '../../config'

export default {
  data() {
    return {
      links: config.platformDownloadLinks,
      selectedOperatingSystem: '',
      showDownloadStep1: true,
    }
  },
  mounted() {},
  methods: {
    gotoStep1() {
      this.showDownloadStep1 = true
      this.selectedOperatingSystem = ''

      let step1 = document.getElementById('step1')
      let step2 = document.getElementById('step2')

      step1.classList.remove('ltr:-translate-x-full' ,'rtl:translate-x-full')
      step1.classList.remove('opacity-0')
      step2.classList.add('ltr:left-full', 'rtl:right-full')
      step2.classList.remove('ltr:left-0', 'rtl:right-0')

      let downloadLink = document.getElementById('downloadLink')

      downloadLink.classList.add('bg-gray-300')
      downloadLink.classList.remove('bg-green')
      downloadLink.removeAttribute('href')
    },
    gotoStep2(os) {
      this.selectedOperatingSystem = os

      let step1 = document.getElementById('step1')
      let step2 = document.getElementById('step2')

      step1.classList.add('ltr:-translate-x-full', 'rtl:translate-x-full')
      step1.classList.add('opacity-0')
      step2.classList.remove('ltr:left-full', 'rtl:right-full')
      step2.classList.add('ltr:left-0', 'rtl:right-0')

      this.showDownloadStep1 = !this.showDownloadStep1
    },
    downloadPlatform(platform) {
      let downloadLink = document.getElementById('downloadLink')

      if (platform == 'mt4') {
        if (this.selectedOperatingSystem == 'windows') {
          downloadLink.href = this.links.mt4.windows
        } else if (this.selectedOperatingSystem == 'android') {
          downloadLink.href = this.links.mt4.android + '=' + this.Language
        } else if (this.selectedOperatingSystem == 'ios') {
          downloadLink.href = this.links.mt4.ios
        } else if (this.selectedOperatingSystem == 'webtrader') {
          downloadLink.href = this.links.mt4.webtrader
        }
      } else if (platform == 'mt5') {
        if (this.selectedOperatingSystem == 'windows') {
          downloadLink.href = this.links.mt5.windows
        } else if (this.selectedOperatingSystem == 'android') {
          downloadLink.href = this.links.mt5.android + '=' + this.Language
        } else if (this.selectedOperatingSystem == 'ios') {
          downloadLink.href = this.links.mt5.ios
        } else if (this.selectedOperatingSystem == 'webtrader') {
          downloadLink.href = this.links.mt5.webtrader
        }
      }

      if (downloadLink.href != '') {
        downloadLink.classList.remove('bg-gray-300')
        downloadLink.classList.add('bg-green')
      }
    },
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
    }),
  },
}
</script>
