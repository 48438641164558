<template>
  <div
    id="logo-loader"
    :class="[{ active: isActive }, position]"
  >
    <div class="bg-white h-full w-full fixed inset-0 flex justify-center items-center z-50">
      <div class="flex flex-col items-center justify-center ">
        <!-- div class="w-32 h-32 border-b-4 border-white rounded-full animate-spin"></div -->
        <div class="w-12 h-12 relative rounded-full before:border-2 before:rounded-full before:w-full before:h-full ltr:before:left-0 rtl:before:right-0 before:absolute after:border-2 after:rounded-full after:w-full after:h-full after:left-0 after:absolute before:scale-1 after:scale-0 before:animate-rippleA after:animate-rippleB"></div>
        <div
          v-if="text && text.length > 0"
          class="relative top-10"
        >
          <div class="bg-gradient-to-r from-green/[0.7] via-blue to-green/[0.7] py-2 px-6 rounded-full text-white animate-seesaw">{{ text }}</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: ['isActive', 'position', 'text'],
  data() {
    return {}
  },
}
</script>
