<template>
  <div class="grid grid-cols-5 grid-flow-row lg:grid-flow-col gap-4">
    <div class="col-span-5 lg:col-span-2">
      <div class="flex justify-between lg:flex-col gap-4 lg:gap-2">
        <button @click="selectFace('front')" :class="{ 'bg-gold': selectedFace === 'front' }"
          class="w-full py-[12px] lg:py-[15px] text-sm font-bold text-gray-900 border border-gold rounded-lg">{{ $t('front')
          }}</button>
        <button @click="selectFace('back')" :class="{ 'bg-gold': selectedFace === 'back' }"
          class="w-full py-[12px] lg:py-[15px] text-sm font-bold text-gray-900 border border-gold rounded-lg">{{ $t('back').tTitle()
          }}</button>
      </div>
      <div class="border-t border-[#707070] mt-10 mb-4 divide-gray-900"></div>
      <div class="text-gray-900 ltr:text-left rtl:text-right">
        <h5 class="text-sm font-bold">{{ $t('please_note') }}</h5> <br>
        <p class="opacity-70 text-[13px] pb-4">{{ selectedFace === 'front' ? $t('identity_card_text_front_1') :
          $t('identity_card_text_back_1') }}</p>
        <p class="opacity-70 text-[13px] pb-4">{{ $t('identity_card_text_2') }}</p>
        <p class="opacity-70 text-[13px] pb-4">{{ $t('identity_card_text_3') }}</p>
        <p class="opacity-70 text-[13px] pb-4">{{ $t('identity_card_text_4') }}</p>
      </div>
    </div>
    <div class="col-span-5 lg:col-span-3 rounded-lg border-[0.75px] border-gray-300 bg-blue-light">
      <div class="flex justify-center py-12 px-4">
        <div class="flex flex-col max-w-[377px]">
          <img :src="require(`../../../../assets/img/id-card-${selectedFace}.svg`)" class="w-[377px]" alt="">
          <div class="mt-6 p-4 bg-[#FFFFFF8D] rounded-lg">
            <p class="text-sm text-blue">{{ $t('identity_card_image_text') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'identity-card',
  props: {face: String},
  data() {
    return {
      selectedFace: 'front'
    }
  },
  computed: {},
  watch: {
    face(){
      this.selectFace(this.face)
    }
  },
  mounted() {},
  methods: {
    selectFace(face) {
      this.selectedFace = face;
    }
  },
}
</script>
