<template>
  <div>
    <div class="bg-gray-100 absolute top-0 ltr:left-0 rtl:right-0 z-0 flex w-full justify-center">
      <img
        width="1000px"
        height="1000px"
        src="../../assets/img/plexytrade-login-bg.svg"
      />
    </div>

    <div class="relative z-10 mb-24 lg:mb-0">
      <div
        class="flex flex-wrap justify-center bg-blue"
        style="height: 70px;"
      >
        <img
          width="176"
          src="../../assets/img/plexytrade-logo-white.svg"
        />
        <div class="absolute ltr:right-4 rtl:left-4 top-4">
            <language-popover></language-popover>
        </div>
      </div>
      <div class="h-32"></div>

      <div class="flex flex-wrap justify-center">
        <div
          class="w-[96%] lg:w-1/2 bg-white py-12 rounded-lg shadow-md px-8"
          style="max-width: 610px;"
        >
          <p
            v-html="$t('login_to_your_account').tTitle()"
            class="text-blue font-display font-bold text-4xl text-center mb-14"
          ></p>

          <form v-on:submit.prevent="validateCaptcha">
            <p class="">
              <label class="block font-body">{{ $t('email').tTitle() }}</label>
              <input
                v-model="data.body.email"
                class="rounded-md w-full p-3 bg-gray-400 placeholder-gray-300 focus:outline-none border-none"
                type="email"
                placeholder="Email"
                autocomplete="email"
                name="email"
              />
            </p>

            <p class="mt-4">
              <label class="block label">{{ $t('password').tTitle() }}</label>
              <input
                class="rounded-md w-full p-3 bg-gray-400 placeholder-gray-300 focus:outline-none"
                type="password"
                placeholder="Password"
                v-model="data.body.password"
                autocomplete="current-password"
                name="current-password"
              />
            </p>

            <div class="mt-4 flex flex-row justify-between">
              <!-- remember me check box -->
              <div> <input
                  class="checkbox"
                  :class="{ 'appearance-none': data.body.rememberMe == '' }"
                  type="checkbox"
                  id="chk-rem-me"
                  name="chk-rem-me"
                  value=""
                  v-model="data.body.rememberMe"
                />
                <label
                  class="checkbox-label-xs"
                  for="chk-rem-me"
                  id="lbl-rem"
                >{{ $t('remember_me') }}</label>
              </div>

              <!-- Forgot your password? -->
              <div>
                <router-link
                  class="mt-1 ml-auto forgotPassword font-body text-xs text-gray-900"
                  id="lnk-forgot-pass"
                  :to="'/' + (Language || 'en') + '/forgot-password'"
                >{{
                $t('forgot_password_q').tTitle()
              }}</router-link>
              </div>

            </div>

            <!-- Login button -->
            <div class="flex flex-wrap justify-center mt-8">
              <button
                type="submit"
                id="btn-ete-submit"
                class="bg-green text-white font-body font-bold w-[248px] h-[50px] py-2 px-4 rounded-md"
              >{{ $t('login').tTitle() }}</button>
            </div>

            <hr class="mt-10 border-t-2 border-gray-400" />

            <div class="flex flex-wrap justify-center mt-5">
              <h4 class="font-body font-bold text-xs sm:text-sm text-gray-900">{{ $t('not_registered_with_question').tTitle() }}</h4>
            </div>
            <!-- open live a/c -->
            <div class="flex flex-wrap justify-center mt-5 items-center gap-2">
              <router-link
                id="btn-ete-open-live"
                class="font-body font-bold text-blue text-xs sm:text-sm"
                :to="'/' + (Language || 'en') + '/register/live'"
              >{{
                  $t('open_live_account').tCapitalizeFirstLetter()
                }}</router-link>
              <div class="h-[14px] bg-gray-900 w-[1px]"></div>
              <!-- open demo a/c -->
              <router-link
                id="btn-ete-open-demo"
                class="font-body font-bold text-xs sm:text-sm text-gray-900"
                :to="'/' + (Language || 'en') + '/register/demo'"
              >{{
                  $t('open_demo_account').tCapitalizeFirstLetter()
                }}</router-link>
            </div>

            <hr class="mt-5 border-t-2 border-gray-400" />
            <!-- To see how we use your data, please review our -->
            <div class="text-center mt-10">
              <div class="font-body text-xs text-gray-900">
                <span id="spn-your-data">{{ $t('risk_sentence') }}</span>
                <a
                  class="font-bold ltr:pl-1 rtl:pr-1 text-gray-900"
                  :href="privacyPolicyLink"
                  target="_blank"
                >{{ $t('privacy_policy').tTitle() }}</a>
              </div>
            </div>
            <div class="is-clearfix"></div>
          </form>
        </div>
      </div>
    </div>

    <!-- <two-factor-modal
      :email="data.body.email"
      :text="$t('two_factor_login')"
      ref="login-2fa"
    ></two-factor-modal> -->

    <div class="fixed bottom-0 w-full z-20">
      <div class="m-auto table max-w-[1399px] w-full">
        <footer-collapsible></footer-collapsible>
      </div>

    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import config from '../../config'
import { Base64 } from 'js-base64'
import LanguagePopover from '@/components/Language.vue'

// import TwoFactorModal from '../../components/TwoFactorModal.vue'
import FooterCollapsible from '../../components/FooterCollapsible.vue'
import links from '../../config/links'


export default {
  components: {
    // TwoFactorModal,
    FooterCollapsible,
    LanguagePopover
  },
  data() {
    return {
      data: {
        body: {
          email: null,
          password: '',
          rememberMe: '',
          recaptchaToken: null,
        },
      },
      error: null,
      passwordValue: '',
    }
  },
  computed: {
    websiteURL() {
      return config.URL.website
    },
    ...mapGetters({
      Language: 'language/code',
    }),
    passwordValueAsterisk() {
      let string = ''
      for (let i = 0; i < this.data.body.password.length; i++) {
        string += '*'
      }
      return string
    },
    privacyPolicyLink() {
      return links.getLink('privacyPolicy', this.Language);
    },
  },
  mounted() {
    this.$bus.$emit('ChangeHeadings', 'Login to your account', null)
    if (this.$auth.check()) {
      this.$router.push('/' + (this.Language || 'en') + '/' + config.defaultURL)
    } else {
      this.$bus.$emit('LiveFeedClose')
    }

    setTimeout(() => {
      if (this.$auth.check()) {
        this.$router.push('/' + (this.Language || 'en') + '/' + config.defaultURL)
      }
    }, 500)

    // phone verification event
    // this.$bus.$on('otpPassed', this.otpPassed)
  },
  beforeDestroy() {
    // this.$bus.$off('otpPassed', this.otpPassed)
  },
  methods: {
    passwordChanged(pass) {
      let passLength = pass.target.value.length
      let newCharacter = pass.target.value.replaceAll('*', '')
      if (this.data.body.password.length - pass.target.value.length < -3) {
        this.data.body.password = ''
      } else {
        this.data.body.password += newCharacter
        if (this.data.body.password.length !== passLength) {
          this.data.body.password = this.data.body.password.substring(0, passLength)
        }
      }
    },
    validateCaptcha() {
      this.$recaptchaLoaded()
      this.recaptchaToken = this.$recaptcha('login')

      this.recaptchaToken.then(value => {
        this.login(value)
      }).catch(err => {
        console.log(err)
      })
    },
    changeLang(event) {
      this.$bus.$emit('ChangeLang', event)
    },

    login(token) {
      this.$auth
        .login({
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic ' + Base64.encode(this.data.body.email + ':' + this.data.body.password + ':' + token),
          },
          redirect: {
            name: 'Dashboard | english',
          },
          staySignedIn: this.data.body.rememberMe,
        })
        .then(
          () => {
            this['menu/reset']()
            this.$auth.fetch().then(() => {
              this.$bus.$emit('LiveFeedConnect')
            })
          },
          err => {
            if (err.response) {
              this.$toasted.error(err.response.data.detail, { duration: 10000 })
              this.error = err.response.data
              this.$store.state.token = null
            }
          }
        )
    },
    otpPassed() {
      this.validateCaptcha()
    },
    ...mapActions(['menu/reset']),
  },

  filters: {
    json: function (value) {
      return value
    },
  },
}
</script>
