<template>
  <div class="register-form-wrapper w-full lg:w-[70%] xl:w-[80%]">
    <div class="lg:w-[70%] lg:m-auto">
      <div class="form-header mb-[30px] lg:mb-[40px] px-[22px]">
        <div class="risk-wrapper w-full hidden lg:block">
          <footer-collapsible :bottom="false"></footer-collapsible>
        </div>
        <div class="account-type hidden mt-[30px] lg:mt-[85px] lg:flex justify-between">
          <div class="font-display font-bold text-3xl" :class="accountType.toLowerCase() === 'demo' ? 'text-gray-900' : 'text-blue'">
            {{ $t(`${accountType.toLowerCase()}_account`).tTitle() }}
          </div>
          <div>
            <a class="font-body text-base text-gray-900 flex items-center cursor-pointer" :href="contactUrl">
              {{ $t('need_help').tTitle() }}
            </a>
          </div>
        </div>
        <div class="type-toggle w-full lg:w-fit flex lg:flex-row rounded-3xl bg-gray-100 mt-[30px] lg:mt-[85px]" v-if="accountType !== 'demo' && activeStep === 1">
          <div
            @click="companyToggle"
            class="w-1/2 px-[30px] text-center leading-10 cursor-pointer"
            :class="[!activateCompany ? 'text-white font-bold bg-blue rounded-3xl' : 'text-gray-900']"
          >
            {{ $t('individual').tTitle() }}
          </div>
          <div
            @click="companyToggle"
            class="w-1/2 px-[30px] text-center leading-10 cursor-pointer"
            :class="[activateCompany ? 'text-white font-bold bg-blue rounded-3xl' : 'text-gray-900']"
          >
            {{ $t('company').tTitle() }}
          </div>
        </div>
      </div>
      <div class="form-content w-[95%] m-auto bg-white rounded-lg pb-[40px] mb-[40px]">
        <p class="px-[20px] py-[22px] text-gray-900 font-display font-bold text-[20px] border-b border-b-gray-300 mb-[36px]">{{ $t(steps[activeStep - 1].label) }}</p>
        <RegistrationStepOne
          v-if="activeStep === 1"
          :account-type="accountType"
          :active-step="activeStep"
          :is-company="activateCompany"
          :translations="translations"
          :formData="formData"
          :legalType="legalType"
          :upgrade="upgrade"
          :prefilledTestData="prefilledTestData"
          :testData="testData"
        />
        <RegistrationStepTwoIndividual
          v-if="activeStep === 2 && !activateCompany && accountType.toLowerCase() !== 'demo'"
          :account-type="accountType"
          :active-step="activeStep"
          :is-company="activateCompany"
          :translations="translations"
          :formData="formData"
          :legalType="legalType"
          :upgrade="upgrade"
          :prefilledTestData="prefilledTestData"
          :testData="testData"
        />
        <RegistrationStepTwoCompany
          v-if="activeStep === 2 && activateCompany && accountType.toLowerCase() !== 'demo'"
          :account-type="accountType"
          :active-step="activeStep"
          :is-company="activateCompany"
          :translations="translations"
          :formData="formData"
          :legalType="legalType"
          :upgrade="upgrade"
          :prefilledTestData="prefilledTestData"
          :testData="testData"
        />
        <RegistrationStepThreeCompany
          v-if="activeStep === 3 && activateCompany"
          :account-type="accountType"
          :active-step="activeStep"
          :is-company="activateCompany"
          :translations="translations"
          :formData="formData"
          :legalType="legalType"
          :upgrade="upgrade"
          :prefilledTestData="prefilledTestData"
          :testData="testData"
        />
        <p v-if="activeStep === 3 && activateCompany" class="px-[20px] py-[22px] text-gray-900 font-display font-bold text-[20px] border-b border-b-gray-300 mb-[36px]">
          {{ $t('reg_form_step_3_individual') }}
        </p>
        <RegistrationStepThree
          v-if="activeStep === 3 || (accountType.toLowerCase() === 'demo' && activeStep === 2)"
          :account-type="accountType"
          :active-step="activeStep"
          :is-company="activateCompany"
          :translations="translations"
          :formData="formData"
          :legalType="legalType"
          :upgrade="upgrade"
          :prefilledTestData="prefilledTestData"
          :testData="testData"
        />
      </div>
      <div class="mb-[40px] px-[25px] md:hidden">
        <a class="font-body text-base text-gray-900 flex items-center cursor-pointer" :href="contactUrl">
          {{ $t('need_help').tTitle() }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import FooterCollapsible from '@/components/FooterCollapsible.vue'
import RegistrationStepOne from '../registerComponents/RegistrationStepOne.vue'
import RegistrationStepTwoIndividual from '../registerComponents/RegistrationStepTwoIndividual.vue'
import RegistrationStepTwoCompany from '../registerComponents/RegistrationStepTwoCompany.vue'
import RegistrationStepThree from '../registerComponents/RegistrationStepThree.vue'
import RegistrationStepThreeCompany from '../registerComponents/RegistrationStepThreeCompany.vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import links from '../../config/links'


export default {
  components: {
    FooterCollapsible,
    RegistrationStepOne,
    RegistrationStepTwoIndividual,
    RegistrationStepTwoCompany,
    RegistrationStepThree,
    RegistrationStepThreeCompany,
  },
  props: {
    accountType: String,
    activeStep: Number,
    isCompany: Boolean,
    steps: Array,
    translations: Object,
    legalType: String,
    upgrade: Boolean,
    formData: Object,
  },
  data() {
    return {
      companyProfile: false,
      prefilledTestData: false,
      isUpgrade: false,
    }
  },
  computed: {
    activateCompany: {
      get() {
        return this.isCompany
      }
    },
    contactUrl(){
      return links.getLink('contact', this.Language);
    },
    ...mapGetters({
      Language: 'language/code',
      User: 'user/all',
      UserInfo: 'user/info',
      DocCount: 'user/docCount',
      isEmailVerified: 'user/isEmailVerified',
      Menu: 'menu/all',
      logoLoaderActive: 'logoLoader/active',
      logoLoaderText: 'logoLoader/text',
      BonusConfig: 'bonusconfig/all',
    }),
    testData() {
      if (!this.isUpgrade) {
        if (this.isCompany && this.prefilledTestData) {
          return {
            company_name: 'CompanyNameTest',
            website: 'WebsiteTest',
            country: 'AD',
            dialCode: '376',
            phone: '8' + moment(),
            email: 'test' + moment() + '@test.com',
            social_platform: 'x',
            gender: '',
            dateofbirth: {
              day: 1,
              month: 6,
              year: 1988,
            },
            address: 'TestAddress',
            address2: '',
            ib_main_target_region: 'TestRegion',
            city: 'TestCity',
            state_province: 'TestState',
            post_code: Math.floor(Math.random() * 89999 + 10000).toString(),
            activeShareholder: 1,
            activeDirector: 1,
            numberOfShareholders: 1,
            numberOfDirectors: 1,
            shareholders: [
              {
                firstname: 'ShareholderFirstNameTest',
                lastname: 'ShareholderLastNameTest',
                gender: 'male',
                dateofbirth: {
                  day: 13,
                  month: 4,
                  year: 1983,
                },
                address: 'TestShareholderAddress',
                address2: '',
                ib_main_target_region: 'TestShareholderRegion',
                city: 'TestShareholderCity',
                state_province: 'TestShareholderState',
                post_code: Math.floor(Math.random() * 89999 + 10000).toString(),
                skype_id: '',
                socialMedia: '',
              },
              {},
              {},
              {},
            ],
            directors: [
              {
                firstname: 'DirectorFirstNameTest',
                lastname: 'DirectorLastNameTest',
                gender: 'female',
                dateofbirth: {
                  day: 11,
                  month: 11,
                  year: 1976,
                },
                address: 'TestDirectorAddress',
                address2: '',
                ib_main_target_region: 'TestDirectorRegion',
                city: 'TestDirectorCity',
                state_province: 'TestDirectorState',
                post_code: Math.floor(Math.random() * 89999 + 10000).toString(),
                skype_id: '',
                socialMedia: '',
              },
              {},
              {},
              {},
            ],
            walletCurrency: 'USD',
            platform: 'mt5',
            tradingAccountCurrency: 'USD',
            account_type: 'micro',
            accountBalance: 5000,
            include_account_in_bonus_program: false,
            privacy_policy_link: true,
            terms_link: true,
            marketing_link: false,
          }
        } else if(this.prefilledTestData) {
          return {
            firstname: 'FirstNameTest',
            lastname: 'LastNameTest',
            country: 'AD',
            dialCode: '376',
            phone: '8' + moment(),
            email: 'test' + moment() + '@test.com',
            social_platform: 'x',
            gender: 'male',
            dateofbirth: {
              day: 1,
              month: 6,
              year: 1988,
            },
            address: 'TestAddress',
            address2: '',
            ib_main_target_region: 'TestRegion',
            city: 'TestCity',
            state_province: 'TestState',
            post_code: Math.floor(Math.random() * 89999 + 10000).toString(),
            skype_id: '',
            socialMedia: '',
            numberOfShareholders: 0,
            numberOfDirectors: 0,
            walletCurrency: 'USD',
            platform: 'mt5',
            tradingAccountCurrency: 'USD',
            account_type: 'micro',
            accountBalance: 5000,
            include_account_in_bonus_program: false,
            privacy_policy_link: true,
            terms_link: true,
            marketing_link: false,
          }
        } else return
      } else {
        return {
          firstname: this.User.info.firstname,
          lastname: this.User.info.lastname,
          country: this.User.info.country_code,
          phone: this.User.info.phone,
          email: this.User.info.email,
          gender: '',
          dateofbirth: {
            day: '',
            month: '',
            year: '',
          },
          address: '',
          address2: '',
          ib_main_target_region: '',
          city: '',
          state_province: '',
          post_code: '',
          skype_id: '',
          socialMedia: '',
          numberOfShareholders: 0,
          numberOfDirectors: 0,
          walletCurrency: this.User.wallet.currency,
          platform: 'mt5',
          tradingAccountCurrency: 'USD',
          account_type: 'micro',
          accountBalance: '',
          include_account_in_bonus_program: false,
          privacy_policy_link: false,
          terms_link: false,
          marketing_link: false,
        }
      }
    },
  },
  created() {
    this.$bus.$on('fillWithTestData', this.fillWithTestData)
  },
  mounted() {
    if (this.upgrade) return this.fillUpgradeData()
  },
  methods: {
    companyToggle() {
      this.errors.clear()
      this.$bus.$emit('ToggleCompany')
      this.prefilledTestData = false
    },
    fillWithTestData() {
      this.prefilledTestData = true
    },
    fillUpgradeData() {
      this.prefilledTestData = true
      this.isUpgrade = true
    },
  },
}
</script>
