<template>
  <div class="has-text-left">
    <h5 class="text-gray-900 text-base font-semibold">{{$t("please_enter_amount")}}</h5>

    <div class="flex gap-5 mt-7 items-start flex-col md:flex-row">
      <div class="w-full md:w-auto">
        <label
          for="currency"
          class="text-base text-gray-900"
        >{{$t('currency').tTitle()}}</label>
        <div class="mt-2">
          <div class="bg-white py-3 w-full md:w-44 text-gray-900 text-base font-semibold ltr:pl-5 rtl:pr-5 rounded-md">{{User.wallet.currency}}</div>
        </div>
      </div>
      <div class="grow w-full md:w-auto">
        <label
          for="amount"
          class="text-base text-gray-900"
        >{{$t('amount').tTitle()}}</label>
        <input
          v-validate="'required|currency|min_value:50|max_value:350000'"
          :class="{'input': true, 'is-danger': errors.has('amount') }"
          name="amount"
          type="text"
          :data-vv-as="translations.text.amount"
          :placeholder="currencySymbols[User.wallet.currency].symbol+'00.000'"
          v-model="amount"
          class="mt-2 bg-white"
        />
        <span
          v-show="errors.has('amount')"
          class="text-red text-xs pt-0.5 rtl:text-right"
        >{{ errors.first('amount') }}</span>
      </div>
    </div>

    <div class="mt-5 mb-16 md:mb-20">
      <p class="text-gray-900 text-base">{{$t('popular_deposits')}}</p>
      <div class="flex mt-2 gap-1 md:gap-2 flex-wrap xl:flex-nowrap">
        <div
          v-for="item in popularDeposits"
          :key=item
          @click="selectedPopularDeposit=item;amount=item"
          class="w-[100px] xl:w-1/6 shrink-0 xl:shrink py-3 text-gray-900 text-base font-semibold rounded-md text-center bg-white border border-solid cursor-pointer focus:border-0 focus:outline-none focus:ring-0"
          :class="[selectedPopularDeposit==item?'border-green':'border-white']"
        >{{currencySymbols[User.wallet.currency].symbol+item}}</div>
      </div>
    </div>
    <a
      class="bg-green text-white text-center text-base font-bold py-3 rounded-md w-52 table cursor-pointer"
      @click="deposit"
    >
      <span>{{$t("deposit").tTitle()}}</span>
    </a>

    <p class="pt-4 text-gray-900 text-sm">{{ $t('provider_redirect', { provider: 'Confirmo'}) }}</p>
    <p v-html="$t('confirmo_deposit_note')" class="pt-4 text-gray-900 text-sm leading-6"></p>
  </div>
</template>

<script>
import config from '../../../config'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      amount: 0,
      translations: {
        text: {
          amount: this.$t('amount'),
        },
      },
      currencySymbols: config.currencies,
      selectedPopularDeposit: '',
      popularDeposits: ['50', '75', '250', '500', '1000', '5000'],
    }
  },
  watch: {
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
    }),
  },
  mounted() {},
  methods: {
    deposit() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return false
        }
        this.$bus.$emit(
          'RequestDeposit',
          '/finance/deposit/bitcoin',
          {
            amount: String(this.amount),
            userID: this.$store.state.user.info.ID,
          },
          response => {
            if (response.data.success) {
              window.location.replace(response.data.redirectURL)
            }
          }
        )
      })
    },
  },
}
</script>
