<template>
  <div
    class="mb-10 pb-10"
  >
    <div
      class="box p-5 bg-[#c6e3dd] rounded-lg mb-6"
    >
      <h4
        class="mb-2 text-gray-900 text-base font-semibold rtl:text-right"
      >
        <span class="text-danger">{{$t("please_note")}}</span>
        {{$t("lydiax_note")}}
      </h4>
      <p
        class="mb-2 text-gray-900 text-base rtl:text-right"
      >{{ $t('lydiax_note1', { crypto: selectedCrypto.name || 'crypto'}) }}</p>
    </div>

    <!-- <div
      class="mt-6 p-5 bg-[#c6e3dd] rounded-lg mb-6"
      :class="{'text-right': Language == 'fa'}"
    >
      <h4 class="mb-2 text-gray-900 text-base">
        <span class="text-red font-semibold">{{$t("price_estimate")}}</span>
      </h4>
      <p class="mb-6 text-gray-900 text-base">{{ $t('lydiax_note2', { crypto: selectedCrypto.name || 'crypto'}) }}</p>

      <div class="box">
        <div class="flex gap-5 items-start mb-3 flex-col md:flex-row">
          <p class="grow flex flex-col">
            <label
              class="text-base text-gray-900 font-semibold"
              :class="{'text-right': Language == 'fa'}"
              for="estimate"
            >{{ $t('please_enter_crypto_amount', { crypto: selectedCrypto.name || 'crypto'}) }}</label>
            <input
              class="mt-2 bg-white"
              v-validate="'decimal:10'"
              name="estimate"
              type="text"
              v-model="estimate.crypto"
            />
            <span
              v-show="errors.has('estimate')"
              class="text-red text-xs pt-0.5"
              :class="{'has-text-right': Language == 'fa'}"
            >{{ errors.first('estimate') }}</span>
          </p>
          <a
            class="w-40 h-[50px] bg-green text-center text-white text-base font-bold table rounded-md py-3 cursor-pointer md:mt-8"
            @click="estimatePrice"
          >
            <span>{{$t("check_price")}}</span>
          </a>

        </div>

        <span
          class="text-red text-base pt-1"
          :class="{'text-right': Language == 'fa'}"
          v-if="estimate.fiat"
        >Estimated: {{ $formatMoney(estimate.fiat, currencySymbols[User.wallet.currency]) }}</span>
      </div>
    </div> -->

    <div v-if="address === '' && availableCryptoOptions.length">
      <h5 class="text-gray-900 text-base font-semibold mb-2">{{$t("lydiax_enter_deposit").tLower().tTitle()}}</h5>

      <div class="flex gap-5">
        <!-- <label for="currency">{{$t("currency").tTitle()}}</label> -->
        <span class="grow">
          <select
            name="cryptocurrency"
            v-validate="'required'"
            v-model="cryptocurrency"
            class="bg-white rtl:bg-left"
          >
            <option value="">{{$t('lydiax_please_select')}}</option>
            <option
              :value="crypto.code"
              v-for="(crypto, i) in availableCryptoOptions"
              :key="'crypto-options-' + i"
            >{{ crypto.name }}</option>
          </select>
          <span
              v-show="errors.has('cryptocurrency')"
              class="text-xs text-red rtl:text-right"
            >{{ errors.first('cryptocurrency') }}</span>
        </span>
      </div>

      <div v-if="cryptocurrency == 'USDT'"  class="select-box network-box mt-3">
        <h5 class="text-gray-900 text-base font-semibold mb-2">{{$t("select_crypto_network",{crypto:cryptocurrency}).tTitle()}}</h5>
        <span class="select">
          <select
            name="network"
            v-validate="'required'"
            v-model="network"
            class="bg-white rtl:bg-left"
          >
            <option
              :value="netw"
              v-for="(netw, i) in networkOptsUSDT"
              :key="'network-' + i"
            >{{ netw }}</option>
          </select>
          <span
              v-show="errors.has('network')"
              class="text-xs text-red rtl:text-right"
            >{{ errors.first('network') }}</span>
        </span>
      </div>

      <div v-if="cryptocurrency == 'USDC'"  class="select-box network-box mt-3">
        <h5 class="text-gray-900 text-base font-semibold mb-2">{{$t("select_crypto_network",{crypto:cryptocurrency}).tTitle()}}</h5>
        <span class="select">
          <select
            name="network"
            v-validate="'required'"
            v-model="network"
            class="bg-white rtl:bg-left"
          >
            <option
              :value="netw"
              v-for="(netw, i) in networkOptsUSDC"
              :key="'network-' + i"
            >{{ netw }}</option>
          </select>
          <span
              v-show="errors.has('network')"
              class="text-xs text-red rtl:text-right"
            >{{ errors.first('network') }}</span>
        </span>
      </div>

      <a
        class="w-40 h-[50px] mt-6 bg-green text-center text-white text-base font-bold table rounded-md py-3 cursor-pointer"
        @click="deposit"
      >
        <span>{{$t("create_address")}}</span>
      </a>

      <p class="text-gray-900 text-sm mt-5 font-bold">{{$t('crypto_note')}}:</p>
      <p class="text-gray-900 text-sm mt-2">{{$t('crypto_note_line_1')}}</p>
      <p class="text-gray-900 text-sm mt-2">{{$t('crypto_note_line_2')}}</p>
      <p class="text-gray-900 text-sm mt-2">{{$t('crypto_note_line_3')}}</p>
    </div>
    <div v-else class="p-5 bg-white rounded-lg text-sm">
      <h5
        class="title"
        :class="{'has-text-right': Language == 'fa'}"
      >{{ $t('lydiax_note4', { crypto: selectedCrypto.name}) }}</h5>
      <div class="relative bg-[#eaeaea] p-1.5 ltr:text-left rtl:text-right ltr:pl-4 rtl:pr-4 rounded-md font-semibold my-2 text-sm ltr:pr-12 ltr:md:pr-0 rtl:pl-12 rtl:md:pl-0 break-all md:break-normal">
        <code>{{ address }}</code>
        <div
          @click="copyAddress(address)"
          class="absolute ltr:right-4 rtl:left-4 top-0.5 cursor-pointer">
          <img src="../../../assets/img/copy.svg" width="30" />
        </div>
      </div>
      <div v-if="selectedCrypto.code === 'XRP'" class="pt-3 text-sm">
        {{$t("lydiax_required_address")}}<br/>
        <div class="relative bg-[#eaeaea] p-1.5 ltr:text-left rtl:text-right ltr:pl-4 rtl:pr-4 rounded-md font-semibold my-2 text-sm ltr:pr-12 ltr:md:pr-0 rtl:pl-12 rtl:md:pl-0 break-all md:break-normal">
          {{$t("address").tTitle()}}:
          <code>{{ xrp.address }}</code>
          <div
            @click="copyAddress(xrp.address)"
            class="absolute ltr:right-4 rtl:left-4 top-0.5 cursor-pointer">
            <img src="../../../assets/img/copy.svg" width="30" />
          </div>
        </div>
        <div class="relative bg-[#eaeaea] p-1.5 text-left ltr:pl-4 rtl:pr-4 rounded-md font-semibold my-2 text-sm ltr:pr-12 ltr:md:pr-0 rtl:pl-12 rtl:md:pl-0 break-all md:break-normal">
          {{$t("tag")}}:
          <code>{{ xrp.tag }}</code>
          <div
            @click="copyAddress(xrp.tag)"
            class="absolute ltr:right-4 rtl:left-4 top-0.5 cursor-pointer">
            <img src="../../../assets/img/copy.svg" width="30" />
          </div>
        </div>
      </div>
      <p :class="{'has-text-right': Language == 'fa'}" class="text-sm pt-5">{{ $t('lydiax_note5', { crypto: selectedCrypto.name}) }}</p>
    </div>

    <div v-if="showLydiaxModal">
      <div class="fixed flex justify-center items-center inset-0 z-50 bg-gray-900 bg-opacity-70 w-full h-full">
        <div class="bg-white rounded-lg w-11/12 lg:w-[642px] max-w-[642px] m-auto relative max-h-[82%] p-10">
          <button @click="showLydiaxModal = false" class="bg-white rounded-full h-10 w-10 absolute -top-14 ltr:right-0 rtl:left-0 appearance-none p-0">
            <div class="h-6 w-0.5 bg-blue rotate-45 ltr:ml-5 rtl:mr-5"></div>
            <div class="h-6 w-0.5 bg-blue -rotate-45 -mt-6 ltr:ml-5 rtl:mr-5"></div>
          </button>
          <p class="text-xl font-bold text-gray-dark mb-4">{{ $t('important_note')}}</p>
          <p class="text-base leading-7 text-gray-dark">{{ $t('lydiax_deposit_note')}}</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import config from '../../../config'
import { mapActions, mapGetters } from 'vuex'
import { service } from '../../../main.js'

export default {
  data() {
    return {
      cryptocurrency: '',
      availableCrypto: [],
      cryptocurrencyOptions: config.cryptocurrencyOptions,
      address: '',
      amount: 0,
      estimate: {
        crypto: 0,
        fiat: 0,
        raw: {},
      },
      translations: {
        text: {
          amount: this.$t('amount'),
        },
      },
      currencySymbols: config.currencies,
      network: '',
      networkOptsUSDT: ['ERC20', 'TRC20'],
      networkOptsUSDC: ['ERC20'],
      showLydiaxModal: true,
    }
  },
  watch: {
    showLydiaxModal(){
      if(!this.showLydiaxModal) return document.querySelector('body').classList.remove('overflow-hidden')
    },
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },
  },
  computed: {
    xrp() {
      if (!this.selectedCrypto || this.selectedCrypto.code !== 'XRP') {
        return ''
      }
      const x = this.address.split('?dt=')
      return { address: x[0], tag: x[1] }
    },
    availableCryptoOptions() {
      return this.cryptocurrencyOptions
    },
    selectedCrypto() {
      return this.cryptocurrencyOptions.filter(c => c.code === this.cryptocurrency)[0] || {}
    },
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
    }),
  },
  mounted() {
    this.getAvailableCrypto()
    document.querySelector('body').classList.add('overflow-hidden')
  },
  methods: {
    getAvailableCrypto() {
      this['logoLoader/set'](true)
      service
        .get('finance/get-available-lydiax-crypto')
        .then(response => {
          this.availableCrypto = response.data.crypto
          this['logoLoader/set'](false)
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    },
    estimatePrice() {
      this.estimate.fiat = 0
      this.estimate.raw = {}

      this['logoLoader/set'](true)
      service
        .post('finance/get-lydiax-price', {
          order_type: 'sell',
          crypto: this.cryptocurrency,
        })
        .then(response => {
          const orderPrice = response.data
          if (orderPrice.currency !== this.User.wallet.currency) {
            service.get('/finance/rates/' + orderPrice.currency + '/' + this.User.wallet.currency).then(response => {
              this.estimate.fiat = orderPrice.price * response.data.rate * this.estimate.crypto
            })
          } else {
            this.estimate.fiat = orderPrice.price * this.estimate.crypto
          }

          this.estimate.raw = orderPrice
          this['logoLoader/set'](false)
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    },
    deposit() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return false
        }
        this.$bus.$emit(
          'RequestDeposit',
          '/finance/deposit/lydiax',
          {
            cryptocurrency: this.cryptocurrency,
            amount: String(this.amount),
            userID: this.$store.state.user.info.ID,
            network: (this.cryptocurrency === 'USDT' || this.cryptocurrency === 'USDC') ? this.network : '',
          },
          response => {
            if (response.data.success) {
              this.address = response.data.redirectURL
              this['logoLoader/set'](false)
              // window.location.replace(response.data.redirectURL)
            }
          }
        )
      })
    },
    copyAddress(el){
      navigator.clipboard.writeText(el)
      this.$toasted.success(this.$t('copied'))
    },
    ...mapActions(['logoLoader/set']),
  },
}
</script>
