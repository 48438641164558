<template>
  <div>
    <div class="mt-6 pt-5 bg-white rounded-lg shadow-ts1 mx-5 md:mx-0 lg:mx-0">
      <div class="w-full pb-4 border-b border-gray-400 border-solid">
        <h2 class="ltr:pl-5 ltr:md:pl-8 rtl:pr-5 rtl:md:pr-8 text-blue font-display font-bold text-xl leading-none">{{ $t('overview').tTitle() }}</h2>
      </div>

      <div class="w-auto mt-6 mx-5 md:mx-8 relative max-w-[1320px]">
        <div>
          <div @click="scrollLeft('overviewContainer', 300)" class="absolute ltr:-left-4 rtl:-right-4 top-[4.8rem] cursor-pointer">
            <img src="../../assets/img/arrow-left.svg" class="h-9 w-9" />
          </div>
          <div @click="scrollRight('overviewContainer', 300)" class="absolute ltr:-right-4 rtl:-left-4 top-[4.8rem] cursor-pointer">
            <img src="../../assets/img/arrow-right.svg" class="h-9 w-9" />
          </div>
        </div>
        <div class="flex w-full gap-4 pb-10 overflow-x-scroll max-w-[1320px]" id="overviewContainer">
          <div class="w-[250px] h-[180px] bg-blue bg-opacity-15 rounded-lg hover:bg-opacity-30 transition-all duration-300 ease-in-out shrink-0">
            <div class="py-6 px-5">
              <img src="../../assets/img/balance.svg" class="h-[3.75rem] mb-5" />
              <p class="text-gray-900 font-medium text-sm leading-6">{{ $t('balance').tTitle() }}</p>
              <p class="text-blue font-bold text-4xl">{{ $formatMoney(User.IB.balance) }}</p>
            </div>
          </div>
          <div class="w-[250px] h-[180px] bg-blue bg-opacity-15 rounded-lg hover:bg-opacity-30 transition-all duration-300 ease-in-out shrink-0">
            <div class="py-6 px-5">
              <img src="../../assets/img/total-earned.svg" class="h-[3.75rem] mb-5" />
              <p class="text-gray-900 font-medium text-sm leading-6">{{ $t('total_earned').tTitle() }}</p>
              <p class="text-blue font-bold text-4xl">{{ $formatMoney(User.IB.total_earned) }}</p>
            </div>
          </div>
          <div class="w-[250px] h-[180px] bg-blue bg-opacity-15 rounded-lg hover:bg-opacity-30 transition-all duration-300 ease-in-out shrink-0">
            <div class="py-6 px-5">
              <img src="../../assets/img/total-paid.svg" class="h-[3.75rem] mb-5" />
              <p class="text-gray-900 font-medium text-sm leading-6">{{ $t('total_paid').tTitle() }}</p>
              <p class="text-blue font-bold text-4xl">{{ $formatMoney(User.IB.total_paid) }}</p>
            </div>
          </div>
          <div class="w-[250px] h-[180px] bg-blue bg-opacity-15 rounded-lg hover:bg-opacity-30 transition-all duration-300 ease-in-out shrink-0">
            <div class="py-6 px-5">
              <img src="../../assets/img/total-clients.svg" class="h-[3.75rem] mb-5" />
              <p class="text-gray-900 font-medium text-sm leading-6">{{ $t('total_clients').tTitle() }}</p>
              <p class="text-blue font-bold text-4xl">{{ User.IB.total_clients }}</p>
            </div>
          </div>
          <div class="w-[250px] h-[180px] bg-blue bg-opacity-15 rounded-lg hover:bg-opacity-30 transition-all duration-300 ease-in-out shrink-0">
            <div class="py-6 px-5">
              <img src="../../assets/img/total-sub-ibs.svg" class="h-[3.75rem] mb-5" />
              <p class="text-gray-900 font-medium text-sm leading-6">{{ $t('total_sub_ibs').tTitle() }}</p>
              <p class="text-blue font-bold text-4xl">{{ User.IB.total_sub_ib }}</p>
            </div>
          </div>
          <div v-if="User.IB.total_deposits" class="w-[250px] h-[180px] bg-blue bg-opacity-15 rounded-lg hover:bg-opacity-30 transition-all duration-300 ease-in-out shrink-0">
            <div class="py-6 px-5">
              <img src="../../assets/img/total-deposits.svg" class="h-[3.75rem] mb-5" />
              <p class="text-gray-900 font-medium text-sm leading-6">{{ $t('total_deposits').tTitle() }}</p>
              <VueSlickCarousel :dots="false" :infinite="true" :slidesToShow="1" :speed="500">
                <p v-for="(value, currency) in User.IB.total_deposits" :key="currency" class="text-blue font-bold text-4xl ltr:mr-4 rtl:ml-4">
                  {{ currencies[currency].symbol }}{{ value }}
                </p>
              </VueSlickCarousel>
            </div>
          </div>
          <div v-if="User.IB.total_withdrawals" class="w-[250px] h-[180px] bg-blue bg-opacity-15 rounded-lg hover:bg-opacity-30 transition-all duration-300 ease-in-out shrink-0">
            <div class="py-6 px-5">
              <img src="../../assets/img/total-withdrawals.svg" class="h-[3.75rem] mb-5" />
              <p class="text-gray-900 font-medium text-sm leading-6">{{ $t('total_withdrawals').tTitle() }}</p>
              <VueSlickCarousel :dots="false" :infinite="true" :slidesToShow="1" :speed="500">
                <p v-for="(value, currency) in User.IB.total_withdrawals" :key="currency" class="text-blue font-bold text-4xl ltr:mr-4 rtl:ml-4">
                  {{ currencies[currency].symbol }}{{ value }}
                </p>
              </VueSlickCarousel>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-2">
        <h2 class="ltr:pl-5 ltr:md:pl-8 rtl:pr-5 rtl:md:pr-8 text-blue font-display font-bold text-lg leading-none">{{ $t('referal_links').tTitle() }}</h2>
      </div>
      <div class="w-auto mx-5 md:mx-8 mb-11 md:mb-0 pb-11 relative">
        <div class="flex w-full gap-4 pb-4 overflow-x-auto"  ref="trackingCarousel" id="trackingCarouselContainer">
          <div v-for="(value, key) in trackingLinks" :key="key" class=" transition-all duration-300 ease-in-out shrink-0">
            <p class="text-gray-900 text-base pb-1.5">{{ $t(`${key}`).tTitle() }}</p>
            <div class="flex maxLg:flex-col gap-2 maxLg:gap-5 w-[423px] maxLg:w-[330px]">
              <div class="grow px-5 py-3 text-[12px] leading-6 bg-[#F0F0F4] rounded-md font-semibold text-gray-900 break-all md:mb-0 w-4/5 maxLg:w-full">
                {{ trackURL + value }}
              </div>
              <button @click="copyTrackingLink(trackURL + value)" class="appearance-none py-3 text-base rounded-md text-white bg-green w-1/5 maxLg:w-1/3 text-center font-bold">
                {{ $t('copy') }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="isScrollable">
          <div @click="scrollLeft('trackingCarouselContainer', 300)" class="absolute ltr:left-0 rtl:right-0 cursor-pointer">
            <img src="../../assets/img/arrow-previous.svg" class="h-6 w-6" />
          </div>
          <div @click="scrollRight('trackingCarouselContainer', 300)" class="absolute ltr:left-8 rtl:right-8 cursor-pointer">
            <img src="../../assets/img/arrow-next.svg" class="h-6 w-6" />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-11 mx-5 lg:mx-0">
      <div class="w-full flex gap-9 relative">
        <div
          @click="selectedTab = 'clients'"
          :class="[selectedTab == 'clients' ? 'font-bold border-b-2 border-blue border-solid text-opacity-100' : 'text-opacity-70']"
          class="text-blue pb-2.5 relative z-[1] cursor-pointer"
        >
          {{ $t('clients').tTitle() }}
        </div>
        <div
          @click="selectedTab = 'subibs'"
          :class="[selectedTab == 'subibs' ? 'font-bold border-b-2 border-blue border-solid text-opacity-100' : 'text-opacity-70']"
          class="text-blue pb-2.5 cursor-pointer"
        >
          {{ $t('sub_ibs').tTitle() }}
        </div>

        <div class="absolute bottom-0 ltr:left-0 rtl:right-0 w-full border-b border-solid border-gray-300 z-0"></div>
      </div>

      <div class="mt-8 pb-14 pt-5 bg-white rounded-lg shadow-ts1 mb-20">
        <div v-if="selectedTab == 'clients'">
          <div class="w-full flex justify-between items-center pb-4 px-7 border-b border-gray-400 border-solid">
            <h2 class="text-blue font-display font-bold text-xl leading-none text-center">{{ $t('clients').tTitle() }}</h2>
            <button @click="exportClientDataCSV" class="appearance-none py-3 text-base rounded-md text-white bg-blue w-24 text-center font-bold">
              {{ $t('export').tTitle() }}
            </button>
          </div>

          <clients :isClientTab="isClientTabSelected" ref="ClientData"></clients>
        </div>

        <div v-if="selectedTab == 'subibs'">
          <div class="w-full flex justify-between items-center pb-4 px-7 border-b border-gray-400 border-solid">
            <h2 class="text-blue font-display font-bold text-xl leading-none">{{ $t('sub_ibs').tTitle() }}</h2>
            <button @click="exportSubIbDataCSV" class="appearance-none py-3 text-base rounded-md text-white bg-blue w-24 text-center font-bold">
              {{ $t('export').tTitle() }}
            </button>
          </div>
          <sub-i-bs ref="SubIbData"></sub-i-bs>
        </div>

        <div v-if="selectedTab == 'cpa'">
          <div class="w-full pb-4 border-b border-gray-400 border-solid">
            <h2 class="ltr:pl-5 ltr:md:pl-8 rtl:pr-5 rtl:md:pr-8 text-blue font-display font-bold text-xl leading-none">{{ $t('cpa').tTitle() }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { service } from '../../main.js'
import config from '../../config'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import Clients from './Clients.vue'
import SubIBs from './SubIBs.vue'

export default {
  components: {
    VueSlickCarousel,
    Clients,
    SubIBs,
  },
  data() {
    return {
      trackURL: config.URL.track,
      trackingLinks: '',
      currencies: config.currencies,
      selectedTab: 'clients',
      carouselSettings: {
        arrows: false,
        dots: true,
        focusOnSelect: true,
        infinite: false,
        slidesToShow: 1.3,
        speed: 500,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      trackingCarouselSettings: {
        dots: false,
        infinite: false,
        slidesToShow: 3.4,
        speed: 500,
        responsive: [
          {
            breakpoint: 1210,
            settings: {
              slidesToShow: 1.8,
            },
          },
          {
            breakpoint: 1047,
            settings: {
              slidesToShow: 1.2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.1,
            },
          },
        ],
      },
       isScrollable:false,
    }

  },
  watch: {
    'detailsModal.modalState'(value) {
      if (value === true) {
        this.disableScroll()
      } else {
        this.enableScroll()
      }
    },
     trackingLinks: {
      handler() {
        this.$nextTick(() => {
          this.checkScrollable();
        });
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
    }),
    isClientTabSelected() {
      return this.selectedTab === 'clients'
    },
  },
  mounted() {
    this.dragContainer('#overviewContainer')
    // get IB overview info
    if (!Object.keys(this.User.IB).length) {
      this['logoLoader/set'](true)
      service
        .get('/ib/' + this.User.info.ID)
        .then(response => {
          this['user/setIB'](response.data)
          this['logoLoader/set'](false)
          this.addHomeAndBonusLinks()
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    } else {
      this.addHomeAndBonusLinks()
    }
   this.$nextTick(() => {
      this.checkScrollable();
      window.addEventListener('resize', this.checkScrollable);
    });
  },
    beforeDestroy() {
    window.removeEventListener('resize', this.checkScrollable);
  },
  methods: {
    copyTrackingLink(link) {
      navigator.clipboard.writeText(link)
      this.$toasted.success(this.$t('copied'))
    },
    scrollLeft(containerId, amount) {
      document.getElementById(containerId).scrollLeft -= amount
    },
    scrollRight(containerId, amount) {
      document.getElementById(containerId).scrollLeft += amount
    },
    /**
     * Function to add the home and bonus link to the trackingLinks array.
     */
    addHomeAndBonusLinks() {
      let entries = Object.entries(this.User.IB.tracking_links)
      // get tracking code from User.
      let trackingCode = entries[0][1]
      let links = {
        'registration_link': trackingCode+"&term=register",
        'website_home_page': trackingCode+"&term=home",
        'bonus_page': trackingCode+"&term=bonus",
        'pamm_page': trackingCode+"&term=pamm"
      }
      this.trackingLinks = links
    },
    subIBID(value) {
      this.detailsModal.IBID = value.subIBID
      this.detailsModal.modalState = value.modalState
    },
    disableScroll() {
      document.querySelector('body').classList.add('overflow-hidden')
    },
    enableScroll() {
      document.querySelector('body').classList.remove('overflow-hidden')
    },
    exportClientDataCSV() {
      this.$refs.ClientData.$emit('export-csv')
    },
    exportSubIbDataCSV() {
      this.$refs.SubIbData.$emit('export-csv')
    },
   checkScrollable() {
      this.$nextTick(() => {
        const container = this.$refs.trackingCarousel;
        if (container) {
          this.isScrollable = container.scrollWidth > container.clientWidth;
        }
      });
    },
    ...mapActions(['logoLoader/set', 'user/setIB']),
  },
}
</script>
<style lang="css">
#overviewContainer::-webkit-scrollbar,
#trackingCarouselContainer::-webkit-scrollbar {
  display: none;
}

[dir='ltr'] #overviewCarousel .slick-dots {
  text-align: left;
}
[dir='rtl'] #overviewCarousel .slick-dots {
  text-align: right;
}

#overviewContainer,
#trackingCarouselContainer {
  scroll-behavior: smooth;
}

#overviewContainer.active {
  scroll-behavior: auto;
}
</style>
