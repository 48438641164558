<template>
  <div
    class="mt-6 mb-24"
  >

    <div v-if="IsVerified" class="flex gap-7 h-auto min-h-[70vh] flex-col lg:flex-row mx-5 md:mx-0 mb-24">
      <div class="w-full lg:w-[37%] bg-white rounded-lg shadow-ts1 order-2 lg:order-1 pb-7 lg:pb-0">
        <div key="images">
          <div class="pb-4 border-b border-solid border-gray-300 mt-5">
            <div>
              <p class="ltr:pl-5 ltr:md:pl-8 rtl:pr-5 rtl:md:pr-8 text-blue font-display font-bold text-xl leading-none">{{$t('withdraw_funds').tTitle()}}</p>
            </div>
          </div>
          <p class="mt-7 text-gray-900 font-semibold text-base mb-5 ltr:ml-5 ltr:md:ml-8 rtl:mr-5 rtl:md:mr-8">{{$t('please_select_payment_method')}}</p>
          <div class="hidden lg:flex flex-col gap-2 mx-8 items-center">
            <div
              v-for="(img,name) in activePaymentImages"
              @click="selectMethod(name)"
              :key="img.PSPName"
              class="w-full rounded-lg border border-solid py-3 group relative hover:border-blue max-w-xs cursor-pointer select-none"
              :class="[selected.paymentMethod==name?'border-green':'border-gray-300']"
            >
              <img
                :src="img.path"
                class="w-40 m-auto h-14"
              />
              <div class="w-full rounded-lg flex items-center justify-center bg-blue bg-opacity-85 absolute inset-0 text-white text-center font-bold text-base transition-all ease-in-out duration-200 opacity-0 group-hover:opacity-100"><span>{{$t(name).tTitle()}}</span></div>
            </div>
          </div>
          <div
            class="mx-5 block lg:hidden"
            id="paymentMethodsCarousel"
          >
            <VueSlickCarousel
              v-bind="carouselSettings"
              class="w-full max-w-[480px]"
            >
              <div
                v-for="(img,name) in activePaymentImages"
                @click="selectMethod(name)"
                :key="img.PSPName"
              >
                <div
                  class="w-[90%] h-[130px] rounded-lg border border-solid flex items-center justify-center"
                  :class="[selected.paymentMethod==name?'border-green':'border-gray-300']"
                >
                  <img
                    :src="img.mobile_path"
                    class="w-20 m-auto h-full"
                  />
                </div>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
      </div>

      <div class="w-full lg:w-3/4 bg-gray-900 bg-opacity-15 rounded-lg shadow-ts1 order-3 lg:order-2 mb-20 lg:mb-0 pb-7 lg:pb-0">
        <div class="pb-4 border-b border-solid border-white mt-5">
          <div>
            <p class="ltr:pl-5 ltr:md:pl-8 rtl:pr-5 rtl:md:pr-8 text-gray-900 font-display font-bold text-xl leading-none">{{$t(paymentImages[selected.paymentMethod].Component.tLower())}}</p>
          </div>
        </div>
        <div
          class="mx-5 md:mx-8 mt-7"
          key="component"
        >
          <component
            :selected="selected"
            v-bind:is="paymentImages[selected.paymentMethod].Component"
            :apm="getAPM(paymentImages[selected.paymentMethod].Component, selected.paymentMethod)"
          ></component>
        </div>
      </div>
      <div class="w-full lg:w-1/4 flex flex-row lg:flex-col gap-4 md:gap-8 order-1 lg:order-3">
        <div class="w-full h-28 md:h-[8.2rem] bg-white rounded-lg shadow-ts1">
          <div class="h-full flex justify-center ltr:pl-5 ltr:md:pl-8 rtl:pr-5 rtl:md:pr-8 flex-col">
            <p class="text-gray-900 text-sm leading-6 font-semibold">{{ $t('wallet_balance').tTitle() }}</p>
            <p class="pt-1 text-blue font-bold text-[1.37rem] md:text-4xl">{{ $formatMoney(walletBalance, currencySymbols[User.wallet.currency]) }}</p>
          </div>
        </div>
        <div class="w-full h-28 md:h-[8.2rem] bg-white rounded-lg shadow-ts1 relative">
          <div class="h-full flex justify-center ltr:pl-5 ltr:md:pl-8 rtl:pr-5 rtl:md:pr-8 flex-col relative">
            <div class="flex justify-between">
              <p class="text-gray-900 text-sm leading-6 font-semibold">{{ $t('total_withdrawals').tTitle() }}</p>
              <!-- <div class="absolute top-1 md:top-auto right-0 md:relative">
                <select
                  v-model="selectedCurrency"
                  name="currency"
                  class="w-16 h-5 bg-none bg-transparent text-gray-900 border-0 p-0 rounded-md text-sm md:text-base font-bold appearance-none focus:outline-none focus:ring-0"
                >
                  <option>USD</option>
                  <option>EUR</option>
                </select>
                <div class="select-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div> -->
            </div>
            <p class="pt-1 text-green font-bold text-[1.37rem] md:text-4xl">{{ $formatMoney(totalWithdrawals, currencySymbols[selectedCurrency]) }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="px-2 lg:px-0 mt-32 md:mt-0">
        <div class="h-[74vh] mt-6 pt-5 bg-white rounded-lg shadow-ts1 flex flex-col justify-center items-center px-5 lg:px-0">
          <p  class="text-base text-gray-dark leading-7 text-center max-w-xl mx-auto">{{ $t('withdrawal_note')}}</p>
        </div>
      </div>
    </div>
    <error-modal></error-modal>

    <div v-if="showMaintenanceModal">
      <div class="fixed flex justify-center items-center inset-0 z-50 bg-gray-900 bg-opacity-70 w-full h-full">
        <div class="bg-white rounded-lg w-11/12 lg:w-[642px] max-w-[642px] m-auto relative max-h-[82%] p-10">
          <button @click="showMaintenanceModal = false" class="bg-white rounded-full h-10 w-10 absolute -top-14 right-0 appearance-none p-0">
            <div class="h-6 w-0.5 bg-blue rotate-45 ml-5"></div>
            <div class="h-6 w-0.5 bg-blue -rotate-45 -mt-6 ml-5"></div>
          </button>
          <p class="text-xl font-bold text-gray-dark mb-4">{{ $t('important_note')}}</p>
          <p v-html="$t('confirmo_maintenance_note')" class="text-base leading-7 text-gray-dark"></p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { service } from '../../main.js'
import api from '../../api'
import config from '../../config'
// import RegularPay from './withdraw-methods/RegularPay'
// import EpayPro from './withdraw-methods/EpayPro'
// import EData from './withdraw-methods/EData'
// import Skrill from './withdraw-methods/Skrill'
// import Neteller from './withdraw-methods/Neteller'
import Bitcoin from './withdraw-methods/Bitcoin'
import Lydiax from './withdraw-methods/Lydiax'
// import KoalaPay from './withdraw-methods/KoalaPay'
// import PagoCUP from './withdraw-methods/PagoCUP'
// import Fasapay from './withdraw-methods/Fasapay'
// import BankWire from './withdraw-methods/BankWire'
import VLoad from './withdraw-methods/VLoad'
// import SafeCharge from './withdraw-methods/SafeCharge'
// import PowerCash from './withdraw-methods/PowerCash'
import ErrorModal from '../../components/ErrorModal'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

const imgPath = require.context('../../assets/img/funding', true)

export default {
  components: {
    VueSlickCarousel,
    // RegularPay,
    // EpayPro,
    // EData,
    // Skrill,
    // Neteller,
    Bitcoin,
    // KoalaPay,
    // PagoCUP,
    // Fasapay,
    // BankWire,
    VLoad,
    Lydiax,
    // SafeCharge,
    // PowerCash,
    ErrorModal,
  },
  data() {
    return {
      step: 1,
      selected: {
        paymentMethod: 'bitcoin',
        amount: 0,
      },
      paymentImages: {},
      currencySymbols: config.currencies,
      selectedCurrency: '',
      totalWithdrawals: 0,
      showMaintenanceModal: false,
      carouselSettings: {
        arrows: false,
        dots: true,
        focusOnSelect: true,
        infinite: false,
        slidesToShow: 2,
        speed: 500,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 430,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 380,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    }
  },
  watch: {
    'selected.paymentMethod'() {
      if (this.selected.paymentMethod) {
        this.step = 2
      }
    },
  },
  computed: {
    activePaymentImages() {
      if (Object.values(this.paymentImages).length == 0) {
        return null
      }
      var tempObj = new Object()
      for (const property in this.paymentImages) {
        if (!this.User.wallet.currency) {
          break
        }

        let item = this.paymentImages[property]

        if (!item.BlockedCurrencies && !item.BlockedCountries) {
          tempObj[property] = this.paymentImages[property]
        }
        let PSPCurrencyAllowed = !item.BlockedCurrencies || !item.BlockedCurrencies.length || item.BlockedCurrencies.indexOf(this.User.wallet.currency.toUpperCase()) === -1
        let PSPCountryAllowed = !item.BlockedCountries || !item.BlockedCountries.length || item.BlockedCountries.indexOf(this.User.info.country_code.toUpperCase()) === -1

        // temporarily removed visa withdrawals of US due to EUROTRADER-11
        if (this.User.info.country_code.toUpperCase() === 'US' && property === 'visa-mastercard') {
          PSPCountryAllowed = false
        }

        if (PSPCurrencyAllowed && PSPCountryAllowed) {
          tempObj[property] = this.paymentImages[property]
        }
      }
      return Object.assign(this.paymentImages, tempObj)
      // return this.paymentImages.filter((img, name) => {
      //   if (!this.User.wallet.currency) {
      //     return false
      //   }
      //   if (!img.BlockedCurrencies && !img.BlockedCountries) {
      //     return true
      //   }
      //   let PSPCurrencyAllowed = !img.BlockedCurrencies || !img.BlockedCurrencies.length || img.BlockedCurrencies.indexOf(this.User.wallet.currency.toUpperCase()) === -1
      //   let PSPCountryAllowed = !img.BlockedCountries || !img.BlockedCountries.length || img.BlockedCountries.indexOf(this.User.info.country_code.toUpperCase()) === -1

      //   // temporarily removed visa withdrawals of US due to EUROTRADER-11
      //   if (this.User.info.country_code.toUpperCase() === 'US' && name === 'visa-mastercard') {
      //     PSPCountryAllowed = false
      //   }
      //   return PSPCurrencyAllowed && PSPCountryAllowed
      // })
    },
    selectedPaymentMethod() {
      return this.paymentImages.filter(item => this.selected.paymentMethod === item.name)
      // return this.paymentImages.filter((_, name) => name == this.selected.paymentMethod)
    },
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
      Finance: 'finance/all',
      logoLoaderActive: 'logoLoader/active',
      IsVerified: 'user/isVerified',
    }),
  },
  created() {},
  mounted() {
    // PSPs (handling image loading/path)
    this.paymentImages = Object.assign({}, this.Finance.PSPs)
    let platNames = Object.keys(this.paymentImages)
    for (let i = 0, l = platNames.length; i < l; i++) {
      this.paymentImages[platNames[i]]['path'] = imgPath('./' + platNames[i] + '.svg', true)
      this.paymentImages[platNames[i]]['mobile_path'] = imgPath('./' + platNames[i] + '-mob.svg', true)
    }
    api.GetWallet()

    this.$bus.$on('RequestWithdraw', this.requestWithdraw)
    this.walletBalance = Math.floor(this.User.wallet.balance * 100) / 100
    this.selectedCurrency = this.User.wallet.currency
    this.getTotals()

    // if(this.selected.paymentMethod==='bitcoin'){
    //   this.showMaintenanceModal = true
    // }
  },
  beforeDestroy() {
    this.$bus.$off('RequestWithdraw', this.requestWithdraw)
  },
  methods: {
    selectMethod(method) {
      // if (method === 'bank-wire') {
      // 	this.$toasted.info('The bank wire transfer option is temporarily unavailable. Please select another withdrawal method until a new payment service provider is made available for sending and receiving bank wires. We will make all efforts to offer you an alternative bank wire transfer solution as soon as possible. We apologize for the inconvenience and greatly appreciate your patience and understanding.', { duration: 100000 })
      // 	return
      // }

      // if (method === 'bitcoin') {
      // this.$toasted.info('Due to some technical updates, this method is temporarily unavailable. Please use Crypto option to place your withdrawal by Bitcoin', { duration: 100000 })
      // return
      // }

      // if(method==='bitcoin'){
      //   this.showMaintenanceModal = true
      // }

      this['logoLoader/set'](true)
      service
        .post('finance/withdraw/check-method', {
          userID: this.User.info.ID,
          psp: this.paymentImages[method].Component,
        })
        .then(response => {
          this['logoLoader/set'](false)
          if (!response.data.Applicable) {
            if (typeof response.data.NeedsCreditCardAmount !== 'undefined') {
              if (response.data.NeedsCreditCardAmount > 0) {
                if (this.paymentImages[method].Component === 'regular-pay') {
                  this.selected.amount = response.data.NeedsCreditCardAmount
                } else {
                  this.$toasted.error(
                    this.$t('withdrawals_credit_card_remaining_amount_message', { amount: this.currencySymbols[this.User.wallet.currency] + response.data.NeedsCreditCardAmount }) +
                      '<span class="error-id"></span>',
                    {
                      duration: 10000,
                      action: {
                        text: 'Close',
                        onClick: (e, toastObj) => {
                          toastObj.goAway(0)
                        },
                      },
                    }
                  )
                  return
                }
              }
            } else if (typeof response.data.CountryIsBlocked !== 'undefined') {
              if (response.data.CountryIsBlocked) {
                this.$toasted.error(this.$t('country_blocked_for_this_method') + '<span class="error-id"></span>', { duration: 10000 })
              }
              return
            }
          }

          if (this.selected.paymentMethod === method) {
            return
          }
          this.selected.paymentMethod = method
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    },
    requestWithdraw(url, data, successcb, failedcb) {
      this['logoLoader/set'](true)
      service
        .post(url, data, 'requestwithdraw')
        .then(response => {
          if (!response.data.success) {
            // handle error messages here.
            if (response.data.messageID) {
              this['errorModal/set']({ active: true, text: this.$t(response.data.messageID).tTitle() })
            } else {
              this['errorModal/set']({ active: true, text: this.$t('error_request_failed').tTitle() })
            }
            this['logoLoader/set'](false)
            if (failedcb) {
              failedcb(response.data.error)
            }
            return
          }
          api.GetWallet()
          this.$router.push('/' + (this.Language || 'en') + '/finance/payment-history')
          this.$toasted.success(this.$t('withdrawal_request_success_message').tTitle(), { duration: 10000 })
          successcb(response)
          this['logoLoader/set'](false)
        })
        .catch(error => {
          this['logoLoader/set'](false)
          if (error.response) {
            if (error.response.data.meta) {
              let meta = error.response.data.meta
              let tkey = config.API.translationKeyName
              if (meta[tkey]) {
                this['errorModal/set']({ active: true, text: this.$t(meta[tkey]).tTitle() })
              }
            }
          }
          failedcb(error)
        })
    },
    getAPM(component, psp) {
      if (component == 'skrill' && psp != 'skrill') {
        return psp
      }

      return null
    },
    getTotals() {
      service.get('/finance/user/' + this.User.info.ID + '/totals-converted', {}).then(response => {
        if (response.data.total_withdrawals) {
          for (let i = 0; i < response.data.total_withdrawals.length; i++) {
            if (response.data.total_withdrawals[i].currency == this.User.wallet.currency) {
              this.totalWithdrawals = response.data.total_withdrawals[i].value
              break
            }
          }
        }
      })
    },
    ...mapActions(['logoLoader/set', 'errorModal/set']),
  },
}
</script>
<style>
#paymentMethodsCarousel .slick-dots {
  text-align: left;
}
</style>
