<template>
  <div class="mb-4">
    <div class="ltr:pl-1 rtl:pr-1 text-gray-900">{{title}}</div>
    <div class="mt-2">
      <input
        type="text"
        :value="value"
        :disabled="disabled"
        class="px-4 py-3 font-semibold rounded bg-gray-100 placeholder-gray-900 placeholder-opacity-25 w-full text-gray-900"
        :placeholder="placeholder"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    value: String,
    disabled: Boolean,
    placeholder: String,
  },
  data() {
    return {}
  },
  mounted() {},
}
</script>
