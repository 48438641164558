<template>
  <div class="mt-0 md:mt-6">
    <div class="flex gap-5 md:gap-8 items-center justify-center pb-5 md:pb-8 flex-col md:flex-row mx-5 md:mx-0">
      <div class="w-full md:w-1/2 rounded-lg bg-white shadow-ts1 py-3 md:py-4 relative">
        <div
          v-if="totalBonusesGranted!=0 && totalBonusesGranted!=''"
          class="absolute w-16 lg:w-auto top-1 lg:top-4 ltr:right-1 ltr:lg:right-5 rtl:left-1 rtl:lg:left-5"
        >
          <!-- <select
            v-model="selectedGrantedCurrency"
            @change='showBonusGranted'
            class="w-16 h-5 bg-none bg-transparent text-gray-900 border-0 p-0 rounded-md text-sm md:text-base font-bold appearance-none focus:outline-none focus:ring-0 rtl:bg-left"
          >
            <option v-if="bonusGrantedUSD!=0">USD</option>
            <option v-if="bonusGrantedEUR!=0">EUR</option>
          </select> -->
          <!-- <div class="select-arrow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div> -->
        </div>
        <p class="text-gray-900 text-sm font-medium text-center">{{$t("total_bonuses_granted").tTitle()}}</p>
        <p class="text-blue text-4xl font-bold text-center">{{$formatMoney(totalBonusesGranted)}}</p>

      </div>
      <div class="w-full md:w-1/2 rounded-lg bg-white shadow-ts1 py-3 md:py-4 relative">
        <div
          v-if="totalBonusesConverted!=0 && totalBonusesConverted!=''"
          class="absolute w-16 lg:w-auto top-1 ltr:right-1 ltr:lg:right-5 rtl:left-1 rtl:lg:left-5"
        >
          <!-- <select
            v-model="selectedConvertedCurrency"
            @change='showBonusConverted'
            class="w-16 h-5 bg-none bg-transparent text-gray-900 border-0 p-0 rounded-md text-sm md:text-base font-bold appearance-none focus:outline-none focus:ring-0 rtl:bg-left"
          >
            <option v-if="bonusConvertedUSD!=0">USD</option>
            <option v-if="bonusConvertedEUR!=0">EUR</option>
          </select> -->
          <!-- <div class="select-arrow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div> -->
        </div>
        <p class="text-gray-900 text-sm font-medium text-center">{{$t("total_bonuses_converted").tTitle()}}</p>
        <p class="text-blue text-4xl font-bold text-center">{{$formatMoney(totalBonusesConverted)}}</p>
      </div>
    </div>
    <div class="w-auto md:w-full bg-white rounded-lg shadow-ts1 mb-8 pt-5 mx-5 md:mx-0">
      <div class="w-full pb-4 border-b border-gray-400 border-solid">
        <h2 class="ltr:pl-5 ltr:md:pl-8 rtl:pr-5 rtl:md:pr-8 text-blue font-display font-bold text-xl leading-none">{{$t('overview').tTitle()}}</h2>
      </div>
      <div
        v-if="Bonuses"
        class="mt-10 mx-5 md:mx-8 pb-14"
      >
        <table
          class="w-full hidden lg:table"
          id="getBonus"
        >
          <thead>
            <tr>
              <th class="ltr:pl-7 rtl:pr-7 text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t("trading_account").tTitle()}}</th>
              <th class="text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t("platform").tTitle()}}</th>
              <!-- <th>{{$t("type").tUpper()}}</th> -->
              <th class="text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t("amount").tTitle()}}</th>
              <th class="text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t("date").tTitle()}}</th>
              <th class="text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t("status").tTitle()}}</th>
              <th class="text-blue font-bold text-base pb-4 ltr:text-left rtl:text-right">{{$t("conversion_progress").tTitle()}}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="bonus in Bonuses"
              :key="bonus.trading_account"
              class="odd:bg-[#F0F0F4]"
            >
              <td class="ltr:pl-7 rtl:pr-7 text-base text-gray-900 py-3 ltr:rounded-tl-md ltr:rounded-bl-md rtl:rounded-tr-md rtl:rounded-br-md">{{bonus.trading_account}}</td>
              <td class="text-base text-gray-900 py-3">{{bonus.platform}}</td>
              <!-- <td>{{bonus.bonusType}}</td> -->
              <td class="text-base text-gray-900 py-3">{{$formatMoney(bonus.bonusAmount, '')}}</td>
              <td class="text-base text-gray-900 py-3">{{bonus.dateGranted.substring(0, 16).replace("T", " ")}}</td>
              <td class="text-base text-gray-900 py-3">{{bonus.status}}</td>
              <td class="text-base text-gray-900 py-3 ltr:rounded-tr-md ltr:rounded-br-md rtl:rounded-tl-md rtl:rounded-bl-md ltr:pr-7 rtl:pl-7">
                <div class="w-full rounded-xl bg-blue-300 relative h-3">
                  <div
                    class="absolute top-0 ltr:left-0 rtl:right-0 rounded-xl bg-blue h-full"
                    :style="'width: '+Math.floor(bonus.conversion_progress)+'%;'"
                  ></div>
                  <div class="absolute w-full text-center text-white text-[0.6rem] leading-[0.7rem] font-medium">{{Math.floor(bonus.conversion_progress)}}%</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="w-full lg:hidden text-body">
          <div
            v-for="(bonus,i) in Bonuses"
            :key="bonus.trading_account"
            :id="bonus.trading_account"
            class="px-4 pt-3 rounded-lg odd:bg-[#F0F0F4] overflow-hidden"
          >
            <div
              @click="expandRow(i)"
              class="flex justify-between cursor-pointer pb-3 flex-col"
            >
              <div class="w-full flex justify-between mb-5">
                <label class="w-1/2 block text-blue font-bold">
                  <img
                    :id="'arrow-'+i"
                    width="12px"
                    height="12px"
                    class="arrow ltr:mr-1 rtl:ml-1 inline ltr:-rotate-90 rtl:rotate-90 "
                    src='../assets/img/platform-dropdown.svg'
                  >
                  {{$t('trading_account').tTitle()}}
                </label>
                <p class="w-1/2 ltr:text-right rtl:text-left">{{bonus.trading_account}}</p>
              </div>
              <div class="ltr:ml-5 rtl:mr-5">
                <div class="w-full rounded-xl bg-blue-300 relative h-3 mb-1">
                  <div
                    class="absolute top-0 ltr:left-0 rtl:right-0 rounded-xl bg-blue h-full"
                    :style="'width: '+Math.floor(bonus.conversion_progress)+'%;'"
                  ></div>
                  <div class="absolute w-full text-center text-white text-[0.6rem] leading-[0.7rem] font-medium">{{Math.floor(bonus.conversion_progress)}}%</div>
                </div>
                <p class="text-blue text-xs font-bold">{{$t("conversion_progress").tTitle()}}</p>
              </div>
            </div>
            <div
              :id="'content-'+i"
              class="row hidden"
            >
              <div class="flex justify-between">
                <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("platform").tTitle()}}</label>
                <p class="h-9">{{bonus.platform}}</p>
              </div>
              <div class="flex justify-between">
                <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("amount").tTitle()}}</label>
                <p class="h-9">{{$formatMoney(bonus.bonusAmount, '')}}</p>
              </div>
              <div class="flex justify-between">
                <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("date").tTitle()}}</label>
                <p class="h-9">{{bonus.dateGranted.substring(0, 16).replace("T", " ")}}</p>
              </div>
              <div class="flex justify-between">
                <label class="block h-9 ltr:pl-5 rtl:pr-5 text-blue font-bold">{{$t("status").tTitle()}}</label>
                <p class="h-9">{{bonus.status}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="py-10"
      >
        <p class="text-center text-base text-blue font-semibold">{{$t('no_records')}}</p>
      </div>
    </div>

  </div>
</template>

<script>
import { service } from '../main.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      Bonuses: null,
      bonus: null,
      active: false,
      name: 'Bonus list',
      totalBonusesGranted: 0,
      totalBonusesConverted: 0,
      selectedGrantedCurrency: 'USD',
      // selectedConvertedCurrency: 'USD',
      bonusGrantedUSD: 0,
      bonusGrantedEUR: 0,
      // bonusConvertedUSD: 0,
      // bonusConvertedEUR: 0,
    }
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
      logoLoaderActive: 'logoLoader/active',
    }),
  },
  mounted() {
    this.getBonus()
  },
  methods: {
    getBonus() {
      if(this.$store.getters['user/info'] && this.$store.getters['user/info'].ID>0){
        this['logoLoader/set'](true)
        service.get('/bonus/' + this.$store.getters['user/info'].ID).then(response => {
          this.Bonuses = response.data.Bonuses
          if (this.Bonuses) {
            for (var i = 0; i < this.Bonuses.length; i++) {
              let item = this.Bonuses[i]
              if (Math.floor(item.conversion_progress) == 100) {
                // if (item.currency == 'USD') {
                  // this.bonusConvertedUSD += item.bonusAmount
                  // this.totalBonusesConverted = this.bonusConvertedUSD
                  // this.selectedConvertedCurrency = 'USD'
                // } else if (item.currency == 'EUR') {
                  // this.bonusConvertedEUR += item.bonusAmount
                  // if (this.bonusConvertedEUR > this.totalBonusesConverted) {
                    // this.totalBonusesConverted = this.bonusConvertedEUR
                    // this.selectedConvertedCurrency = 'EUR'
                  // }
                // }
              }

              if (item.status == 'granted') {
                if (item.currency == 'USD') {
                  this.bonusGrantedUSD += item.bonusAmount
                  this.totalBonusesGranted = this.bonusGrantedUSD
                  this.selectedGrantedCurrency = 'USD'
                } else if (item.currency == 'EUR') {
                  this.bonusGrantedEUR += item.bonusAmount
                  if (this.bonusGrantedEUR > this.totalBonusesGranted) {
                    this.totalBonusesGranted = this.bonusGrantedEUR
                    this.selectedGrantedCurrency = 'EUR'
                  }
                }
              }
            }
          }
          this['logoLoader/set'](false)
        }).catch(() => {
           this['logoLoader/set'](false)// Ensure loading state is set to false in case of an error
        });
      }
    },
    submitSearchBonus() {
      this.getBonus()
    },
    expandRow(index) {
      let arrows = document.getElementsByClassName('arrow')
      let rows = document.getElementsByClassName('row')

      for (let i = 0; i < arrows.length; i++) {
        if (i != index) {
          rows[i].classList.add('hidden')
        }
      }

      let arrow = document.getElementById('arrow-' + index)
      arrow.classList.toggle('ltr:-rotate-90')
      arrow.classList.toggle('rtl:rotate-90')

      let content = document.getElementById('content-' + index)
      content.classList.toggle('hidden')
    },
    showBonusGranted() {
      if (this.selectedGrantedCurrency == 'USD') this.totalBonusesGranted = this.bonusGrantedUSD
      else if (this.selectedGrantedCurrency == 'EUR') this.totalBonusesGranted = this.bonusGrantedEUR
    },
    ...mapActions(['logoLoader/set']),
    // showBonusConverted() {
    //   if (this.selectedConvertedCurrency == 'USD') this.totalBonusesConverted = this.bonusConvertedUSD
    //   else if (this.selectedConvertedCurrency == 'EUR') this.totalBonusesConverted = this.bonusConvertedEUR
    // },
  },

}
</script>
