const state = {
	active: false,
	text: '',
}

const mutations = {
	SET_ERROR_MODAL(state, set) {
		state.active = set.active
		if (set.text) {
			state.text = set.text
		}
	},
}

const actions = {
	set: ({ commit }, set) => commit('SET_ERROR_MODAL', set),
}

const getters = {
	all: state => state,
	active: state => state.active,
	text: state => state.text,
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
