<template>
  <div class="content">
    <h1 class="title">{{$t("deposit_failed").tTitle()}}</h1>
    <p class="subtitle">
      <i18n
        tag="label"
        place="here"
      >
        {{$t("you_can_try").tTitle()}}
        <router-link :to="'/' + Language + '/finance'">{{$t("here").tTitle()}}</router-link>
      </i18n>
    </p>
    <hr />
    <p>{{(reason)?'Reason: '+reason:''}}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['reason'],
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
    }),
  },
  mounted() {},
}
</script>
