<template>
  <div class="h-9 md:h-10">
    <button @click="showDropdown = !showDropdown" ref="button">
      <div class="cursor-pointer h-9 md:h-10 w-9 md:w-10 bg-[#e3eafa] rounded-full p-2.5">
        <img class="w-5" src="/static/img/languages.svg" />
      </div>
    </button>
    <div
      v-closable="{ exclude: ['button' , 'select'], handler: 'onClose'}"
      v-if="showDropdown"
      class="w-60 ltr:maxMd:right-0 rtl:maxMd:left-0 ltr:right-14 rtl:left-14 absolute md:top-16 top-20 z-10"
      :class="{'top-16': !$auth.check()}"

    >
    <div class="maxMd:w-[90%] bg-white rounded-lg border-[0.74px] border-gray-300 shadow-ts1 grid gap-1 px-5 py-6 grid-cols-1 maxMd:m-auto">
      <div class="grid gap-1  grid-cols-1 minMaxMobile:gap-x-8" ref="select">
        <div v-for="(locale, index) in languages" :key="locale" @click="changeLang(index)"  :class="{ 'rounded-lg bg-white shadow-ts8': index === language }" class="flex items-center gap-16  cursor-pointer hover:rounded-lg hover:shadow-ts8 py-3 px-5 maxXs:px-3 maxMd:gap-6">
          <img :src="getFlagSrc(locale)" :alt="locale" class="w-8 h-8 rounded-full maxMd:max-w-[30%]" />
          <span class=" text-base text-gray-900">{{ locale }}</span>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import config from '../config'
import Vue from 'vue'

// This variable will hold the reference to
// document's click handler
let handleOutsideClick
Vue.directive('closable', {
  bind(el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = e => {
      e.stopPropagation()
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value
      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          const excludedEl = vnode.context.$refs[refName]
          // See if this excluded element
          // is the same element the user just clicked on
          clickedOnExcludedEl = excludedEl.contains(e.target)
        }
      })
      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]()
      }
    }
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unbind() {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  },
})

export default {
  data() {
    return {
      showDropdown: false,
      languages: config.languages,
      language: 'en',
    }
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
    }),
  },
  mounted() {
    this.language = this.Language
  },
  methods: {
    changeLang(index) {
      this.$bus.$emit('ChangeLang', index)
      this.language = index
      this.showDropdown = false
    },
    onClose() {
      this.showDropdown = false
    },
    getFlagSrc(locale) {
      const flagMap = {
        English: '/static/img/uk.svg',
        Spanish: '/static/img/spain.svg',
        Farsi: '/static/img/fa.svg',
      }
      return flagMap[locale]
    },
  },
}
</script>
