<template>
  <div v-if="User.info">
    <div
      :class="{ hidden: !changeTAPassword.modalActive }"
      class="fixed flex justify-center items-center inset-0 z-50 bg-gray-900 bg-opacity-70 w-full h-full"
      id="changeTAPassword"
    >

      <div class="bg-white rounded-xl py-10 px-8 w-10/12 lg:w-[550px] max-w-[550px] m-auto relative">
        <button
          @click="changeTAPassword.modalActive = false"
          class="bg-white rounded-full h-10 w-10 absolute -top-14 ltr:right-0 rtl:left-0 appearance-none p-0"
        >
          <div class="h-6 w-0.5 bg-blue rotate-45 ltr:ml-5 rtl:mr-5"></div>
          <div class="h-6 w-0.5 bg-blue -rotate-45 -mt-6 ltr:ml-5 rtl:mr-5"></div>
        </button>
        <p class="font-display text-2xl font-bold text-gray-900 pb-9">{{$t('change_password_of').tTitle()}} {{changeTAPassword.TAID}}</p>
        <form
          @submit.prevent
          data-vv-scope="form-changePassword"
          :class="{'direction-rtl': Language == 'fa'}"
        >
          <div class="mb-3">
            <label
              for="newPassword"
              class="text-base text-gray-900"
            >{{$t('new_password').tTitle()}}</label>
            <div class="relative">
              <input
              :type="[showNewPwd ? 'text' : 'password']"
              name="newPassword"
              v-validate="'required|strong-password|confirmed:newPasswordConfirm'"
              :class="[ errors.has('form-changePassword.newPassword')?'border-red focus:border-red':'border-gray-100 focus:border-blue' ]"
              class="rounded-md bg-gray-100 ltr:pl-5 rtl:pr-5 w-full h-11 mt-2 text-base text-gray-900 focus:outline-none focus:ring-0 border placeholder:text-gray-900 placeholder:text-opacity-20"
              :data-vv-as="$t('new_password').tTitle()"
              :placeholder="$t('new_password').tTitle()"
              v-model="changeTAPassword.new"
            />
             <button class="absolute ltr:right-6 rtl:left-6 bottom-4 focus:border-none focus:outline-none" @click="showNewPwd = !showNewPwd">
              <img src="../assets/img/settings-eye-hidden.svg" width="20" alt="show password" v-if="!showNewPwd" />
              <img src="../assets/img/settings-eye-shown.svg" width="20" alt="hide password" v-if="showNewPwd" />
            </button>
            </div>

            <span
              v-show="errors.has('form-changePassword.newPassword')"
              class="text-xs leading-3 text-red rtl:text-right"
            >{{ errors.first('form-changePassword.newPassword') }}</span>
          </div>

          <div>
            <label
              for="newPasswordConfirm"
              class="text-base text-gray-900"
            >{{$t('confirm_new_password').tTitle()}}</label>
            <div class="relative">
                <input
              v-validate="'required'"
                :type="[showConNewPwd ? 'text':'password']"
              name="newPasswordConfirm"
              :class="[ errors.has('form-changePassword.newPasswordConfirm')?'border-red focus:border-red':'border-gray-100 focus:border-blue' ]"
              class="rounded-md bg-gray-100 ltr:pl-5 rtl:pr-5 w-full h-11 mt-2 text-base text-gray-900 focus:outline-none focus:ring-0 border placeholder:text-gray-900 placeholder:text-opacity-20"
              :data-vv-as="$t('confirm_new_password').tTitle()"
              :placeholder="$t('confirm_new_password').tTitle()"
              v-model="changeTAPassword.newConfirm"
              ref="newPasswordConfirm"
            />
              <button class="absolute ltr:right-6 rtl:left-6 bottom-4 focus:border-none focus:outline-none" @click="showConNewPwd = !showConNewPwd">
              <img src="../assets/img/settings-eye-hidden.svg" width="20" alt="show password" v-if="!showConNewPwd" />
              <img src="../assets/img/settings-eye-shown.svg" width="20" alt="hide password" v-if="showConNewPwd" />
            </button>
            </div>

            <span
              v-show="errors.has('form-changePassword.newPasswordConfirm')"
              class="text-xs text-red rtl:text-right"
            >{{ errors.first('form-changePassword.newPasswordConfirm') }}</span>
          </div>

          <div class="mt-9 flex gap-5">
            <a
              class="py-2.5 w-48 bg-blue rounded-md text-white font-bold text-center cursor-pointer text-base"
              @click="changeTAPasswordReq()"
            >{{ $t('change_password').tTitle() }}</a>
            <a
              class="py-2.5 text-gray-900 text-base cursor-pointer"
              @click="closeChangePasswordModal()"
            >{{ $t('cancel').tTitle() }}</a>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>

<script>
import { service } from '../main.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['TAPasswordData'],
  data() {
    return {
      changeTAPassword: this.TAPasswordData,
      showNewPwd:false,
      showConNewPwd:false
    }
  },
  watch: {
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
      logoLoaderActive: 'logoLoader/active',
    }),
  },
  mounted() {},
  methods: {
    changeTAPasswordReq() {
      // validate
      this['logoLoader/set'](true)
      this.$validator
        .validateAll('form-changePassword')
        .then(result => {
          if (!result) {
            this['logoLoader/set'](false)
          } else {
            service
              .post('/ta/change-password', {
                TAID: this.changeTAPassword.TAID,
                userID: this.User.info.ID,
                password: this.changeTAPassword.new,
                passwordConfirm: this.changeTAPassword.newConfirm,
              })
              .then(() => {
                this.closeChangePasswordModal()
                this['logoLoader/set'](false)

                this.$toasted.success(this.$t('success'), { duration: 10000 })
              })
              .catch(error => {
                this.closeChangePasswordModal()
                this['logoLoader/set'](false)

                if (typeof error.response.data !== 'undefined') {
                  this.$toasted.error(error.response.data.detail + '<span class="error-id">' + error.response.data.id + '</span>', { duration: 10000 })
                }
              })
          }
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    },
    closeChangePasswordModal() {
      this.changeTAPassword.modalActive = false
      this.changeTAPassword.TAID = null
      this.changeTAPassword.new = ''
      this.changeTAPassword.newConfirm = ''
      this.errors.clear()
      this.$nextTick(() => {
        this.$validator.reset()
      })
    },
    ...mapActions(['logoLoader/set']),
  },
}
</script>
