<template>
  <div v-if="User.info">
    <div
      :class="{ hidden: !modal.active }"
      class="fixed flex justify-center md:justify-end items-start md:items-center top-0 ltr:left-0 rtl:right-0 z-10 bg-gray-600 bg-opacity-30 w-full h-full overflow-y-auto"
    >
      <div class="w-[95%] max-w-[1539px]">
        <div class="h-[70px] w-full">
          <button
            class="w-[40px] h-[40px] rounded-3xl bg-white p-[8px] mt-[15px] ltr:float-right ltr:md:float-left rtl:float-left rtl:md:float-right"
            @click="closeModal()"
          >
            <img
              width="24px"
              height="24px"
              src="../assets/img/icons/close-blue.png"
            >
          </button>
        </div>
        <div class=" md:pt-12 md:px-16 bg-white rounded-xl ltr:md:rounded-r-none rtl:md:rounded-l-none">
          <div class="w-full p-5">
            <div class="flex flex-wrap justify-between">
              <h2 class="w-full md:w-auto font-display text-xl md:text-3xl font-bold pt-[3px] text-gray-900">{{ $t('view_trades').tTitle() }}</h2>
              <div class="w-full md:w-[200px] ltr:md:text-right rtl:md:text-left mt-5 md:mt-0 flex flex-col items-end">
                <p class="font-body text-sm text-gray-900">{{ $t('account_number').tTitle() }}</p>
                <p class="font-body text-5xl md:text-3xl text-blue font-bold">{{ TAID }}</p>
                <button
                  @click="exportToCSV()"
                  class="block mt-8 md:mt-0 bg-blue text-white font-body font-bold w-1/2 md:w-[162px] h-[42px] text-center rounded md:ml-2 leading-[100%]"
                >
                  {{ $t('export_csv').tTitle() }}
                </button>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap md:justify-between w-full bg-white md:mt12">
            <div class="flex bg-gray-400 w-full md:w-[370px] h-[42px] rounded-3xl text-gray-200 mt-10 md:mt-4 order-last md:order-1 m-5 md:m-1">
              <div
                @click="loadTrades('openTrades')"
                class="w-1/2 text-center leading-10 cursor-pointer"
                :class="[selectedTradesType === 'openTrades' ? 'text-white text-bold bg-blue rounded-3xl' : 'text-gray-900']"
              >
                {{ $t('open_trades').tTitle() }}
              </div>
              <div
                @click="loadTrades('tradeHistory')"
                class="w-1/2 text-center leading-10 cursor-pointer"
                :class="[selectedTradesType === 'tradeHistory' ? 'text-white text-bold bg-blue rounded-3xl' : 'text-gray-900']"
              >
                {{ $t('closed_trades').tTitle() }}
              </div>
            </div>
            <div class="md:flex w-full md:w-auto mt-4 order-2 bg-gray-400 md:bg-white p-5 md:p-0 md:rounded-lg">
              <label class="block font-body leading-7 md:leading-10 mt-8 md:mt-1 ltr:mr-2 rtl:ml-2 whitespace-nowrap text-gray-900">{{$t('date_from').tTitle()}}</label>
              <date-picker
                class="!w-44 maxMd:!w-full"
                value-type="format"
                format="YYYY-MM-DD"
                v-model="dateFrom"
                :lang="Language"
                name="dateFrom"
                :rtl="Language === 'fa'"
              ></date-picker>
              <label class="block font-body leading-7 md:leading-10 mt-8 md:mt-1 ltr:ml-0 ltr:md:ml-4 rtl:mr-0 rtl:md:mr-4 ltr:mr-2 rtl:ml-2 whitespace-nowrap text-gray-900">{{$t('date_until').tTitle()}}</label>
              <date-picker
                class="!w-44 maxMd:!w-full "
                value-type="format"
                format="YYYY-MM-DD"
                v-model="dateTo"
                :lang="Language"
                name="dateTo"
                :rtl="Language === 'fa'"
              ></date-picker>
              <button
                @click="getTradeHistory()"
                class="block mt-8 md:mt-0 bg-blue text-white font-body font-bold w-1/2 md:w-40 h-[42px] text-center rounded ltr:md:ml-2 rtl:md:mr-2 leading-[100%]"
              >{{$t('search').tTitle()}}</button>
            </div>
          </div>
          <div class="w-full">
            <table class="hidden md:table w-full mt-5 border-collapse">
              <thead class="font-body text-blue font-bold ltr:text-left rtl:text-right">
                <tr class="h-[50px]">
                  <th class="ltr:pl-2 rtl:pr-2">{{$t('trade_no').tTitle()}}</th>
                  <th>{{$t('datetime').tTitle()}}</th>
                  <th>{{$t('type').tTitle()}}</th>
                  <th>{{$t('symbol').tTitle()}}</th>
                  <th class="ltr:text-right rtl:text-left">{{$t('volume').tTitle()}}</th>
                  <th class="ltr:text-right rtl:text-left">{{$t('open_price').tTitle()}}</th>
                  <th class="ltr:text-right rtl:text-left">{{$t('close_price').tTitle()}}</th>
                  <th class="ltr:text-right rtl:text-left">{{$t('swap').tTitle()}}</th>
                  <th class="ltr:text-right rtl:text-left">{{$t('commission').tTitle()}}</th>
                  <th class="ltr:text-right rtl:text-left ltr:pr-2 rtl:pl-2">{{$t('p_l').tTitle()}}</th>
                </tr>
              </thead>
              <tbody class="font-body ltr:text-left rtl:text-right">
                <tr
                  v-for="trade in pagination.pageTrades"
                  :key="trade.order"
                  :id="trade.order"
                  class="odd:bg-gray-400 even:bg-white h-[50px]"
                >
                  <td class="ltr:rounded-l-xl rtl:rounded-r-xl ltr:pl-2 rtl:pr-2">{{ trade.order }}</td>
                  <td>{{ selectedTradesType == 'openTrades' ? trade.open_time_formatted : trade.close_time_formatted }}</td>
                  <td>{{ trade.cmd == '0' ? $t('buy').tTitle() : trade.cmd == '1' ? $t('sell').tTitle() : $t('balance').tTitle() }}</td>
                  <td>{{ $simplePair(trade.symbol) }}</td>
                  <td class="ltr:text-right rtl:text-left">{{ trade.true_volume }}</td>
                  <td class="ltr:text-right rtl:text-left">{{ trade.open_price }}</td>
                  <td class="ltr:text-right rtl:text-left">{{ trade.close_price }}</td>
                  <td class="ltr:text-right rtl:text-left">{{ trade.storage }}</td>
                  <td class="ltr:text-right rtl:text-left">{{ trade.commission }}</td>
                  <td class="ltr:rounded-r-xl rtl:rounded-l-xl ltr:text-right rtl:text-left ltr:pr-2 rtl:pl-2">{{ trade.true_profit.toFixed(2) }}</td>
                </tr>
                <tr class="odd:bg-gray-400 even:bg-white h-[50px]">
                  <td
                    colspan="7"
                    class="ltr:rounded-l-xl rtl:rounded-r-xl ltr:pl-2 rtl:pr-2 font-semibold text-gray-900"
                  >{{$t("sums").tTitle()}}</td>
                  <td class="ltr:text-right rtl:text-left font-bold">{{ sums.storage }}</td>
                  <td class="ltr:text-right rtl:text-left font-bold">{{ sums.commission }}</td>
                  <td class="ltr:rounded-r-xl rtl:rounded-l-xl ltr:text-right rtl:text-left font-bold ltr:pr-2 rtl:pl-2">{{ sums.true_profit }}</td>
                </tr>
              </tbody>
            </table>
            <div class="w-full md:hidden text-body p-5">
              <div
                v-for="(trade, i) in pagination.pageTrades"
                :key="'m-' + trade.order"
                :id="'m-' + trade.order"
                class="flex justify-between px-4 pt-[13px] rounded-lg odd:bg-gray-400 overflow-hidden"
                :class="{ 'max-h-[50px]': !trade.expanded }"
              >
                <div class="text-blue font-bold">
                  <label class="block h-9">
                    <img
                      @click="expandTrade(i)"
                      width="12px"
                      height="12px"
                      class="ltr:mr-1 rtl:ml-1 cursor-pointer inline"
                      :class="{'-rotate-90': !trade.expanded}"
                      src='../assets/img/platform-dropdown.svg'
                    >
                    {{$t('trade_no').tTitle()}}
                  </label>
                  <label class="block h-9">{{ $t('datetime').tTitle() }}</label>
                  <label class="block h-9">{{ $t('type').tTitle() }}</label>
                  <label class="block h-9">{{ $t('symbol').tTitle() }}</label>
                  <label class="block h-9">{{ $t('volume').tTitle() }}</label>
                  <label class="block h-9">{{ $t('open_price').tTitle() }}</label>
                  <label class="block h-9">{{ $t('close_price').tTitle() }}</label>
                  <label class="block h-9">{{ $t('swap').tTitle() }}</label>
                  <label class="block h-9">{{ $t('commission').tTitle() }}</label>
                  <label class="block h-9">{{ $t('p_l').tTitle() }}</label>
                </div>
                <div class="ltr:text-right rtl:text-left">
                  <p class="h-9">{{ trade.order }}</p>
                  <p class="h-9">{{ selectedTradesType == 'openTrades' ? trade.open_time_formatted : trade.close_time_formatted }}</p>
                  <p class="h-9">{{ trade.cmd == '0' ? $t('buy').tTitle() : trade.cmd == '1' ? $t('sell').tTitle() : $t('balance').tTitle() }}</p>
                  <p class="h-9">{{ $simplePair(trade.symbol) }}</p>
                  <p class="h-9">{{ trade.true_volume }}</p>
                  <p class="h-9">{{ trade.open_price }}</p>
                  <p class="h-9">{{ trade.close_price }}</p>
                  <p class="h-9">{{ trade.storage }}</p>
                  <p class="h-9">{{ trade.commission }}</p>
                  <p class="h-9">{{ trade.profit }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-between w-full p-5 ">
            <div class="w-[100px] h-[40px] font-body text-sm leading-10">{{trades.length}} {{$t('records')}}</div>
            <div
              v-if="trades.length > pagination.rowsPerPage"
              class="flex h-[40px] font-body text-center leading-10"
            >
              <pagination-component
                :page="pagination.page"
                :maxPerPage="pagination.rowsPerPage"
                :total="trades.length"
              />
            </div>
            <div class="w-[100px]"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { service } from '../main.js'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import PaginationComponent from '../components/Pagination.vue'
import 'vue2-datepicker/index.css'
import { json2csv } from 'json-2-csv'

export default {
  components: {
    DatePicker,
    PaginationComponent,
  },
  props: [],
  data() {
    return {
      modal: {
        active: false,
      },
      pagination: {
        page: 1,
        rowsPerPage: 7,
        pageTrades: [],
      },
      TAID: 0,
      platform: '',
      type: '',
      selectedTradesType: 'openTrades',
      dateFrom: moment(moment().subtract(6, 'months')).format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD'),
      prevFrom: moment(moment().subtract(6, 'months')).format('YYYY-MM-DD'),
      prevTo: moment().format('YYYY-MM-DD'),
      trades: [],
      prevTrades: [],
      sums: {},
      prevSums: {},
      tradeFields: {
        order: 'TRADE NO',
        datetimeO: 'open_time',
        datetimeC: 'close_time',
        cmd: 'TYPE',
        symbol: 'SYMBOL',
        true_volume: 'VOLUME',
        open_price: 'OPEN PRICE',
        close_price: 'CLOSE PRICE',
        storage: 'SWAP',
        commission: 'COMMISSION',
        true_profit: 'P&L',
      },
      tradeCSVFieldsOpened: [
        { value: 'order', label: 'TRADE NO' },
        { value: 'open_time', label: 'DATETIME' },
        { value: 'cmd', label: 'TYPE' },
        { value: 'symbol', label: 'SYMBOL' },
        { value: 'true_volume', label: 'VOLUME' },
        { value: 'open_price', label: 'OPEN PRICE' },
        { value: 'close_price', label: 'CLOSE PRICE' },
        { value: 'storage', label: 'SWAP' },
        { value: 'commission', label: 'COMMISSION' },
        { value: 'true_profit', label: 'P&L' },
      ],
      tradeCSVFieldsClosed: [
        { value: 'order', label: 'TRADE NO' },
        { value: 'close_time', label: 'DATETIME' },
        { value: 'cmd', label: 'TYPE' },
        { value: 'symbol', label: 'SYMBOL' },
        { value: 'true_volume', label: 'VOLUME' },
        { value: 'open_price', label: 'OPEN PRICE' },
        { value: 'close_price', label: 'CLOSE PRICE' },
        { value: 'storage', label: 'SWAP' },
        { value: 'commission', label: 'COMMISSION' },
        { value: 'true_profit', label: 'P&L' },
      ],
      tradesFormatted: {},
    }
  },
  watch: {
    selectedTradesType() {
      // this.changeTab()
    },
    'pagination.page'() {
      this.changePage()
    },
    'modal.active'(value) {
      if (value === true) {
        this.getTradeHistory()
      }
    },
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
      logoLoaderActive: 'logoLoader/active',
    }),
  },
  created() {
    this.$bus.$on('pageChanged', p => {
      this.pagination.page = p
    })
  },
  mounted() {},
  beforeDestroy() {
    this.$bus.$off('pageChanged', p => {
      this.pagination.page = p
    })
  },
  methods: {
    exportToCSV() {
      this['logoLoader/set'](true)
      let downloadFunc = function (err, csv) {
        if (err) {
          console.log('error converting csv')
          console.log(err)
          this['logoLoader/set'](false)
        }
        let a = document.createElement('a')
        let file = new Blob([csv], { type: 'text/csv' })
        a.href = URL.createObjectURL(file)
        a.download = moment().unix() + '-trade_history.csv'
        a.click()
      }
      var csvFields = {}
      if (this.tabsActive == 'openTrades') {
        csvFields = this.tradeCSVFieldsOpened
      } else {
        csvFields = this.tradeCSVFieldsClosed
      }
      let w = trades => {
        this.formatTrades(trades)
        try {
          var endData = json2csv(this.tradesFormatted, {
            checkSchemaDifferences: true,
            keys: csvFields.map(v => v.value),
          })
          downloadFunc('', endData)
        } catch (err) {
          console.error(err)
        }
        this['logoLoader/set'](false)
      }
      let b = []

      service
        .post('/ta/pamm-trades', {
          userID: this.User.info.ID,
          TAID: this.TAID,
          platform: this.platform,
          type: this.type,
          dateFrom: this.dateFrom + ' 00:00:00',
          dateTo: this.dateTo + ' 23:59:59',
          tradesType: this.selectedTradesType,
        })
        .then(response => {
          if (response.data && response.data.pammTrades) {
            b.push(...response.data.pammTrades)
          }
          w(b)
        })
        .catch(error => {
          this['logoLoader/set'](false)
          this.$toasted.error(error.response)
        })
    },
    formatTrades(data) {
      let keys = Object.keys(data)
      this.tradesFormatted = JSON.parse(JSON.stringify(data))
      let formattedArray = []
      for (let i = 0, l = keys.length; i < l; i++) {
        let allFields = Object.keys(this.tradeFields)
        for (let j = 0, k = allFields.length; j < k; j++) {
          if (typeof this.tradesFormatted[i][allFields[j]] === 'undefined' || this.tradesFormatted[i][allFields[j]] === '-1') {
            this.tradesFormatted[i][allFields[j]] = ''
          }
          if (this.tradesFormatted[keys[i]]['cmd'] == 0) {
            this.tradesFormatted[keys[i]]['cmd'] = 'BUY'
          } else if (this.tradesFormatted[keys[i]]['cmd'] == 1) {
            this.tradesFormatted[keys[i]]['cmd'] = 'SELL'
          } else if (this.tradesFormatted[keys[i]]['cmd'] == 6) {
            this.tradesFormatted[keys[i]]['cmd'] = 'BALANCE'
          }
        }
        this.tradesFormatted[keys[i]]['close_time'] = this.timestampToDate(this.tradesFormatted[keys[i]]['close_time'])
        this.tradesFormatted[keys[i]]['open_time'] = this.timestampToDate(this.tradesFormatted[keys[i]]['open_time'])
        formattedArray.push(this.tradesFormatted[i])
      }
      this.tradesFormatted = formattedArray
    },
    openModal(TAID, platform, type) {
      this.modal.active = true
      this.TAID = TAID
      this.platform = platform
      this.type = type
      document.querySelector('body').classList.add('overflow-hidden')
    },
    closeModal() {
      this.modal.active = false
      this.TAID = 0
      this.platform = ''
      this.type = ''
      this.trades = []
      this.prevTrades = []
      this.sums = {}
      this.prevSums = {}
      this.pagination.pageTrades = []
      this.pagination.page = 1
      document.querySelector('body').classList.remove('overflow-hidden')
    },
    getTradeHistory() {
      this['logoLoader/set'](true)

      service
        .post('/ta/pamm-trades', {
          userID: this.User.info.ID,
          TAID: this.TAID,
          platform: this.platform,
          type: this.type,
          dateFrom: this.dateFrom + ' 00:00:00',
          dateTo: this.dateTo + ' 23:59:59',
          tradesType: this.selectedTradesType,
        })
        .then(response => {
          this.trades = []
          if (response.data.pammTrades) {
            this.trades = response.data.pammTrades
          }

          this.sums.storage = 0
          this.sums.commission = 0
          this.sums.true_profit = 0
          for (let i = 0; i < this.trades.length; i++) {
            this.sums.storage += this.trades[i].storage
            this.sums.commission += this.trades[i].commission
            this.sums.true_profit += this.trades[i].true_profit
          }

          this.sums.net_total = this.sums.true_profit + this.sums.storage + this.sums.commission

          this.sums.storage = Math.round(this.sums.storage * 100) / 100
          this.sums.commission = Math.round(this.sums.commission * 100) / 100
          this.sums.true_profit = Math.round(this.sums.true_profit * 100) / 100
          this.sums.net_total = Math.round(this.sums.net_total * 100) / 100

          this.changePage()
          this['logoLoader/set'](false)
        })
        .catch(() => {
          this['logoLoader/set'](false)
        })
    },
    timestampToDate(t) {
      return moment.utc(moment.unix(t)).format('YYYY-MM-DD HH:mm')
    },
    changeTab() {
      let tempFrom, tempTo, tempTrades, tempSums

      tempFrom = this.dateFrom
      tempTo = this.dateTo
      tempTrades = this.trades
      tempSums = this.sums

      this.dateFrom = this.prevFrom
      this.dateTo = this.prevTo
      this.trades = this.prevTrades
      this.sums = this.prevSums

      this.prevFrom = tempFrom
      this.prevTo = tempTo
      this.prevTrades = tempTrades
      this.prevSums = tempSums
    },
    changePage() {
      this.pagination.pageTrades = []

      let start = (this.pagination.page - 1) * this.pagination.rowsPerPage
      let end = start + this.pagination.rowsPerPage

      for (let i = start; i < end; i++) {
        if (this.trades[i] === undefined) {
          break
        }
        this.trades[i].expanded = false
        this.pagination.pageTrades.push(this.trades[i])
      }
    },
    expandTrade(i) {
      this.$set(this.pagination.pageTrades, i, { ...this.pagination.pageTrades[i], expanded: !this.pagination.pageTrades[i].expanded })
    },
    loadTrades(type) {
      this.selectedTradesType = type
      this.getTradeHistory()
    },
    ...mapActions(['logoLoader/set', 'user/setInfo']),
  },
}
</script>

<style>
.mx-input {
  height: 42px ;
  border: 0px ;
  background-color: rgba(233, 237, 244, var(--tw-bg-opacity));
  -webkit-box-shadow: none ;
  box-shadow: none ;
}

@media only screen and (max-width: 768px) {
 .mx-input {
    background-color: white;
  }
}
 [dir="rtl"] .mx-icon-calendar, [dir="rtl"] .mx-icon-clear  {
  left: 15px;
  right: auto;
 }
</style>
