import moment from 'moment'
import accounting from 'accounting'
// var moment = require('moment')
// window.moment = moment
// var accounting = require('accounting')
// window.accounting = accounting

export default {
  install(Vue) {
    // Vue.directive('currency-format', {
    // 	bind(el, binding, vnode, oldVnode){
    // 		let opt = binding.expression
    // 		let val = parseFloat(el.innerHTML)
    // 		if (isNaN(val)){
    // 			return
    // 		}
    // 		el.innerHTML = accounting.formatMoney(val, opt.currency, opt.precision, opt.decimal, opt.thousand)
    // 	}
    // })

    Vue.prototype.$createCookie = function (name, value, days) {
      let expires = ''
      if (days) {
        let date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = '; expires=' + date.toUTCString()
      }
      document.cookie = name + '=' + value + expires + '; path=/'
    }
    Vue.prototype.$deleteCookie = function (tokenAlias) {
      document.cookie = tokenAlias + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;'
    }
    Vue.prototype.$getCookie = function (cName) {
      if (document.cookie.length > 0) {
        let cStart = document.cookie.indexOf(cName + '=')
        if (cStart !== -1) {
          cStart = cStart + cName.length + 1
          let cEnd = document.cookie.indexOf(';', cStart)
          if (cEnd === -1) {
            cEnd = document.cookie.length
          }
          return unescape(document.cookie.substring(cStart, cEnd))
        }
      }
      return ''
    }
    Vue.prototype.$formatMoney = accounting.formatMoney
    Vue.prototype.$formatDate = function (t) {
      return moment(t).format('DD/MM/YYYY')
    }
    Vue.prototype.$formatDateTime = function (t) {
      return moment(t).format('YYYY-MM-DD HH:mm')
    }
    Vue.prototype.$dateFromNow = function (t) {
      return moment(t).fromNow()
    }
    Vue.prototype.$statusFormat = function (str) {
      if (str === 'full') {
        return this.$t('client_state_approved').tTitle()
      } else if (str === 'pending') {
        return this.$t('client_state_pending').tTitle()
      } else {
        return ''
      }
    }
    Vue.prototype.$validateEmail = function (str) {
      if (str == '')
        return
      // eslint-disable-next-line
      if (str.match(/^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/)) {
        return true
      }
      return false
    }
    Vue.prototype.$getRouteName = function (raw) {
      if (raw === null) {
        return ''
      }
      return raw.substring(0, raw.indexOf('|') - 1)
    }
    Vue.prototype.$elementParentExists = function (el, parentSelector) {
      if (parentSelector === undefined) {
        return false
      }
      let p = el.parentElement
      if (p === null) {
        return false
      }
      while (p !== parentSelector) {
        p = p.parentElement
        if (p === null) {
          return false
        }
      }
      return true
    }
    Vue.prototype.$escapeHtml = function (str) {
      let entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;',
      }
      // return String(str).replace(/[&<>"'`=\/]/g, function fromEntityMap(s) {
      // 	return entityMap[s]
      // })
      return String(str).replace(/[&<>"'`=/]/g, s => entityMap[s])
    }
    Vue.prototype.$simplePair = function (pair) {
      if (pair.indexOf('.') !== -1) {
        return pair.split('.')[0]
      }
      return pair
    }
    Vue.prototype.$randomString = function () {
      let rstr = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
      return rstr
    }

    Vue.prototype.dragContainer = function (id) {
      const slider = document.querySelector(id)
      let isDown = false
      let startX
      let scrollLeft

      if (!slider) {
        return
      }

      slider.addEventListener('mousedown', e => {
        isDown = true
        slider.classList.add('active')
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
        slider.style.cssText = 'cursor: grabbing;cursor: -webkit-grabbing;'
      })
      slider.addEventListener('mouseleave', () => {
        isDown = false
        slider.classList.remove('active')
        slider.removeAttribute('style')
      })
      slider.addEventListener('mouseup', () => {
        isDown = false
        slider.classList.remove('active')
        slider.removeAttribute('style')
      })
      slider.addEventListener('mousemove', e => {
        if (!isDown) return
        e.preventDefault()
        const x = e.pageX - slider.offsetLeft
        const walk = (x - startX) * 3 // scroll-fast
        slider.scrollLeft = scrollLeft - walk
      })
    }
  },
}
