<template>
  <div v-if="IsVerified || User.info.migrated==1">
    <sub-menu
      :title="title"
      :routes="routes"
    ></sub-menu>

    <router-view></router-view>
  </div>
  <div v-else>
    <div class="px-2 lg:px-0 mt-32 md:mt-0">
      <p class="font-display text-2xl font-bold mb-4 text-gray-900 ltr:pl-5 ltr:lg:pl-0 rtl:pr-5 rtl:lg:pr-0">{{title}}</p>
      <div class="h-[74vh] mt-6 pt-5 bg-white rounded-lg shadow-ts1 flex flex-col justify-center items-center px-5 lg:px-0">
        <div v-if="IsDemo">
          <img src="../../assets/img/upgrade-to-live.svg" width="115" class="mx-auto mb-10"/>
          <p class="max-w-[450px] mx-auto text-gray-900 text-base text-center mb-10">{{ $t('upgrade_to_live_text') }}</p>
          <router-link
            :to="'/' + Language + '/register/upgrade'"
            class="bg-green text-white text-center text-base font-bold py-3 rounded-md w-48 table cursor-pointer mx-auto"
          >
            {{$t("upgrade_to_live").tTitle()}}
          </router-link>
        </div>
        <div v-if="IsLive">
          <img src="../../assets/img/upload-your-documents.svg" width="115" class="mx-auto mb-10"/>
          <p class="max-w-[500px] mx-auto text-gray-900 text-base text-center mb-10">{{ $t('upload_your_documents_text') }}</p>
          <router-link
            :to="'/' + Language + '/profile/upload-documents'"
            class="bg-blue text-white text-center text-base font-bold py-3 rounded-md px-5 table cursor-pointer mx-auto"
          >
            {{$t("upload_your_documents").tTitle()}}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubMenu from '@/components/SubMenu.vue'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      title: this.$t('transactions'),
      routes: this.getRoutes(),
    }
  },
  components: {
    SubMenu,
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
      IsLive: 'user/isLive',
      IsDemo: 'user/isDemo',
      IsVerified: 'user/isVerified',
      BonusConfig: 'bonusconfig/all',
      User: 'user/all',
    }),
  },
  watch: {
    Language() {
      this.updateRoutesAndTitle()
    }
  },
  methods: {
    getRoutes() {
      return [
        { path: '/finance', title: this.$t('deposit').tTitle() },
        { path: '/finance/withdraw', title: this.$t('withdrawal').tTitle() },
        { path: '/finance/internal-transfer', title: this.$t('internal_transfer').tTitle() },
        { path: '/finance/payment-history', title: this.$t('payment_history').tTitle() },
        { path: '/finance/my-bonuses', title: this.$t('bonuses').tTitle() },
      ]
    },
    updateRoutesAndTitle() {
      this.title = this.$t('transactions')
      this.routes = this.getRoutes()
    }
  }
}
</script>

