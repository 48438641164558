<template>
  <div class="page-content flex flex-col lg:flex-row lg:h-auto bg-gray-200 lg:min-h-[100vh]">
    <RegistrationMenu :account-type="type" :active-step="activeStep" :is-company="activateCompany" :steps="steps" />
    <RegistrationForm :account-type="type" :active-step="activeStep" :is-company="activateCompany" :upgrade="upgrade" :steps="steps" :translations="translations" :formData="formData" />
  </div>
</template>
<script>
import RegistrationMenu from '@/components/registerComponents/RegistrationMenu.vue'
import RegistrationForm from '@/components/registerComponents/RegistrationForm.vue'
export default {
  props: {
    translations: Object,
    legalType: String,
    upgrade: Boolean,
    accountType: String,
  },
  components: {
    RegistrationMenu,
    RegistrationForm,
  },
  data() {
    return {
      type: this.accountType,
      isCompany: false,
      defaultStep: 1,
      formData: {},
    }
  },
  mounted() {
    this.$bus.$on('ChangePage', this.changePage)
    this.$bus.$on('ToggleCompany', this.toggleCompany)
    this.$bus.$on('DataSubmitted', this.dataBuildingFunction)
    this.$bus.$on('ApiCall', this.submitData)
    // if (this.accountType === 'live' && this.upgrade) return (this.activeStep = 2)
  },
  computed: {
    steps() {
      if (this.type === 'demo') {
        return [
          {
            step: 1,
            label: this.isCompany ? 'reg_form_step_1_company' : 'reg_form_step_1_individual',
          },
          {
            step: 2,
            label: 'reg_form_step_3_individual',
          },
        ]
      } else {
        return [
          {
            step: 1,
            label: this.isCompany ? 'reg_form_step_1_company' : 'reg_form_step_1_individual',
          },
          {
            step: 2,
            label: this.isCompany ? 'reg_form_step_2_company' : 'reg_form_step_2_individual',
          },
          {
            step: 3,
            label: this.isCompany ? 'reg_form_step_3_company' : 'reg_form_step_3_individual',
          },
        ]
      }
    },
    activeStep: {
      get() {
        return this.defaultStep
      },
      set(newValue) {
        return (this.defaultStep = newValue)
      },
    },
    activateCompany: {
      get() {
        return this.isCompany
      },
      set(newValue) {
        return (this.isCompany = newValue)
      },
    },
  },
  methods: {
    toggleCompany() {
      const legalType = !this.activateCompany ? 'company' : 'individual'
      this.$bus.$emit('SelectLegalType', legalType)
      return (this.activateCompany = !this.activateCompany)
    },
    changePage(value) {
      return (this.activeStep = value)
    },
    dataBuildingFunction(data) {
      Object.assign(this.formData, data)
    },
    submitData() {
      return this.validateCaptcha()
    },
    validateCaptcha() {
      this.$recaptchaLoaded()
      this.recaptcha = this.$recaptcha('complete')

      var badge = document.getElementsByClassName('grecaptcha-badge')[0]
      badge.style.zIndex = '21'

      this.recaptcha
        .then(value => {
          this.complete(value)
        })
        .catch(err => {
          console.log(err)
        })
    },
    complete(token) {
      if (this.formData.dateofbirth && this.formData.dateofbirth.year && this.formData.dateofbirth.month && this.formData.dateofbirth.day) {
        this.formData.dateofbirth = `${this.formData.dateofbirth.year}-${this.formData.dateofbirth.month.toString().padStart(2, 0)}-${this.formData.dateofbirth.day
          .toString()
          .padStart(2, 0)}`
      }
      if (this.formData.shareholders) {
        this.formData.shareholders.forEach((shareholder, index) => {
          if (shareholder.dateofbirth && shareholder.dateofbirth.year && shareholder.dateofbirth.month && shareholder.dateofbirth.day) {
            this.formData.shareholders[index].dateofbirth = `${shareholder.dateofbirth.year}-${shareholder.dateofbirth.month
              .toString()
              .padStart(2, 0)}-${shareholder.dateofbirth.day.toString().padStart(2, 0)}`
            this.formData.shareholders[index].country = this.formData.country
            this.formData.shareholders[index].year_moved_to_address = 1892
          }
        })
        this.formData.shareholders = this.formData.shareholders.filter(value => JSON.stringify(value) !== '{}')
      }
      if (this.formData.directors) {
        this.formData.directors.forEach((director, index) => {
          if (director.dateofbirth && director.dateofbirth.year && director.dateofbirth.month && director.dateofbirth.day) {
            this.formData.directors[index].dateofbirth = `${director.dateofbirth.year}-${director.dateofbirth.month.toString().padStart(2, 0)}-${director.dateofbirth.day
              .toString()
              .padStart(2, 0)}`
            this.formData.directors[index].country = this.formData.country
            this.formData.directors[index].year_moved_to_address = 1892
          }
        })
        this.formData.directors = this.formData.directors.filter(value => JSON.stringify(value) !== '{}')
      }

      if(this.formData.account_type=='investor'){
        this.formData.include_account_in_bonus_program = false
      }


      this.formData.recaptchaToken = token
      this.$bus.$emit('Complete', this.formData)
    },
  },
}
</script>
