const state = {
  active: false,
  text: ''
}

const mutations = {
	SET_LOGO_LOADER(state, active) {
    state.active = active
    if (!active) {
      state.text = ''
    }
  },
  SET_LOGO_TEXT(state, text) {
    state.text = text
  }
}

const actions = {
  set: ({ commit }, active) => commit('SET_LOGO_LOADER', active),
  text: ({ commit}, text) => commit('SET_LOGO_TEXT', text)
}

const getters = {
  active: state => state.active,
  text: state => state.text
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

