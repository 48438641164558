<template>
  <div>
    <div
      v-if="ErrorModal.active"
      class="fixed flex justify-center items-center inset-0 z-50 bg-gray-900 bg-opacity-70 w-full h-full"
    >

      <div class="bg-white rounded-xl py-10 px-8 w-10/12 lg:w-[550px] max-w-[550px] m-auto relative">
        <button
          @click="close()"
          class="bg-white rounded-full h-10 w-10 absolute -top-14 ltr:right-0 rtl:left-0 appearance-none p-0"
        >
          <div class="h-6 w-0.5 bg-blue rotate-45 ltr:ml-5 rtl:mr-5"></div>
          <div class="h-6 w-0.5 bg-blue -rotate-45 -mt-6 ltr:ml-5 rtl:mr-5"></div>
        </button>
        <p class="font-display text-xl text-gray-900 text-center font-semibold">{{ErrorModal.text}}</p>

        <a
          class="py-2.5 w-36 bg-blue rounded-md text-white font-bold text-center cursor-pointer text-base table m-auto mt-10"
          @click="close()"
        >{{$t('okay').tUpper()}}</a>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  date() {
    return {}
  },
  computed: {
    ...mapGetters({
      ErrorModal: 'errorModal/all',
    }),
  },
  methods: {
    close() {
      this['errorModal/set']({ active: false })
      this.$bus.$emit('errorModalClose')
    },
    ...mapActions(['errorModal/set']),
  },
}
</script>
