<template>
  <div :class="{ 'direction-rtl': Language == 'fa' }">
    <div class="border-b border-gray-300 w-full px-4 md:px-8 pt-5 pb-4 leading-none flex items-center gap-2 relative">
      <h2 class="font-display text-xl font-bold text-blue leading-none">{{ $t('change_password').tTitle() }}</h2>
      <div @mouseover="showTooltip = !showTooltip" @mouseleave="showTooltip = !showTooltip" class="hidden lg:block">
        <img class="w-[13px] h-[13px]" src="../../assets/img/icons/upload/info.svg" alt="Additional info" />
      </div>
      <div @mouseenter="showTooltip = !showTooltip" @mouseleave="showTooltip = !showTooltip" class="block lg:hidden">
        <img class="w-[13px] h-[13px]" src="../../assets/img/icons/upload/info.svg" alt="Additional info" />
      </div>
      <div v-if="showTooltip" class="w-full absolute top-14 left-0 z-[1]">
        <div
          class="font-body text-base leading-7 text-gray-900 text-left font-normal m-auto lg:m-0 lg:float-right bg-white rounded-lg shadow-ts3 w-11/12 max-w-[348px] p-5 object-right-bottom"
        >
          {{ $t('change_password_note') }}
        </div>
      </div>
    </div>
    <form @submit.prevent autocomplete="off">
      <div class="p-4 md:p-8">
        <div class="mb-4">
          <div class="pl-1">
            <label :class="{ 'has-text-right': Language == 'fa' }" for="oldPass">{{ $t('current_password').tTitle() }} </label>
          </div>
          <div class="mt-2">
            <input
              v-validate="'required'"
              :data-vv-as="$t('current_password').tCapitalizeFirstLetter()"
              type="text"
              name="oldPass"
              class="px-4 py-3 font-semibold rounded bg-gray-200 placeholder-gray-900 placeholder-opacity-25 w-full"
              :class="{ input: true, 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('oldPass') }"
              :placeholder="$t('current_password').tTitle()"
              :value="passwordValueMask('old')"
              @input="$event => passwordChanged($event, 'old')"
              @paste.prevent
              ref="passwordInput"
            />
            <span v-show="errors.has('oldPass')" class="font-body text-xs text-orange font-semibold">{{ errors.first('oldPass') }}</span>
          </div>
        </div>
        <div class="mb-4">
          <div class="pl-1">
            <label :class="{ 'has-text-right': Language == 'fa' }" for="newPassword">{{ $t('new_password').tTitle() }} </label>
          </div>
          <div class="mt-2">
            <div class="relative">
              <input
                v-validate="{ required: true, 'strong-password': changePassword.new, 'custom-confirmed': [changePassword.new, changePassword.newConfirm] }"
                :data-vv-as="$t('new_password').tCapitalizeFirstLetter()"
                type="text"
                autocomplete="off"
                name="newPassword"
                class="px-4 py-3 font-semibold rounded bg-gray-200 placeholder-gray-900 placeholder-opacity-25 w-full"
                :class="{ input: true, 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('newPassword') }"
                :placeholder="$t('new_password').tTitle()"
                @input="$event => passwordChanged($event, 'new')"
                :value="showNewPwd ? changePassword.new : passwordValueMask('new')"
                @paste.prevent
                @blur="validateField('newPassword', 'new')"
              />
              <button class="absolute right-6 bottom-5 focus:border-none focus:outline-none" @click="showNewPwd = !showNewPwd">
                <img src="../../assets/img/settings-eye-hidden.svg" width="20" alt="show password" v-if="!showNewPwd" />
                <img src="../../assets/img/settings-eye-shown.svg" width="20" alt="hide password" v-if="showNewPwd" />
              </button>
            </div>
            <span v-show="errors.has('newPassword')" class="font-body text-xs text-orange font-semibold">{{ errors.first('newPassword') }}</span>
          </div>
        </div>
        <div class="mb-4">
          <div class="pl-1">
            <label :class="{ 'has-text-right': Language == 'fa' }" for="newPasswordConfirm">{{ $t('confirm_password').tTitle() }} </label>
          </div>
          <div class="mt-2">
            <div class="relative">
              <input
                v-validate.disable="'required'"
                :data-vv-as="$t('confirm_new_password').tCapitalizeFirstLetter()"
                type="text"
                autocomplete="off"
                class="px-4 py-3 font-semibold rounded bg-gray-200 placeholder-gray-900 placeholder-opacity-25 w-full"
                :class="{ input: true, 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('newPasswordConfirm') }"
                name="newPasswordConfirm"
                ref="newPasswordConfirm"
                :placeholder="$t('confirm_password').tTitle()"
                @input="$event => passwordChanged($event, 'newConfirm')"
                :value="showConNewPwd ? changePassword.newConfirm : passwordValueMask('newConfirm')"
                @paste.prevent
                @blur="validateField('newPasswordConfirm', 'newConfirm'), validateField('newPassword', 'new')"
              />
              <button class="absolute right-6 bottom-5 focus:border-none focus:outline-none" @click="showConNewPwd = !showConNewPwd">
                <img src="../../assets/img/settings-eye-hidden.svg" width="20" alt="show password" v-if="!showConNewPwd" />
                <img src="../../assets/img/settings-eye-shown.svg" width="20" alt="hide password" v-if="showConNewPwd" />
              </button>
            </div>

            <span v-show="errors.has('newPasswordConfirm')" class="font-body text-xs text-orange font-semibold">{{ errors.first('newPasswordConfirm') }}</span>
          </div>
        </div>
        <div class="pt-4 flex">
          <div class="py-3 px-10 h-full bg-blue rounded-md text-white appearance-none font-semibold text-base text-center cursor-pointer" @click="ChangePassword()">
            {{ $t('change_password').tTitle() }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { service } from '@/main.js'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      showTooltip: false,
      changePassword: {
        old: '',
        new: '',
        newConfirm: '',
      },
      showNewPwd: false,
      showConNewPwd: false,
    }
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
      User: 'user/all',
    }),
    passwordValueMask() {
      return type => {
        let password = this.changePassword[type]
        let string = '*'.repeat(password.length)
        return string
      }
    },
  },
  mounted() {},
  watch: {
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },
  },
  methods: {
    passwordChanged(pass, type) {
      if (type === 'newConfirm') this.validateField('newPassword', 'new')
      const passLength = pass.target.value.length
      const newCharacter = pass.data
      const cursorPosition = pass.target.selectionStart
      const password = this.changePassword[type]
      const changeCount = password.length + 1 - passLength

      if (newCharacter === null) {
        // delete from a cursor position in the password
        if (cursorPosition >= 0) {
          this.changePassword[type] = password.substring(0, cursorPosition) + password.substring(cursorPosition + 1)
        }
        // delete the whole password
        if (password.length === 1 || passLength === 0) {
          this.changePassword[type] = ''
        }
        // delete selection from the password
        if (changeCount > 2) {
          this.changePassword[type] = password.substring(0, cursorPosition) + password.substring(cursorPosition + changeCount - 1)
        }
      } else {
        if (password.length - pass.target.value.length < -3) {
          this.changePassword[type] = ''
        } else {
          if (cursorPosition === password.length + 1) {
            // add new char in the end of the password
            this.changePassword[type] += newCharacter
          } else if (changeCount === 0) {
            // add new char in the position of the cursor
            this.changePassword[type] = this.changePassword[type].substring(0, cursorPosition - 1) + newCharacter + this.changePassword[type].substring(cursorPosition - 1)
          } else {
            // replace multiple chars in the password
            this.changePassword[type] = password.substring(0, cursorPosition - 1) + newCharacter + password.substring(changeCount + 1)
          }
          if (this.changePassword[type].length !== passLength) {
            this.changePassword[type] = this.changePassword[type].substring(0, passLength)
          }
        }
      }
    },
    async validateField(field, type) {
      return await this.$validator.validate(field, this.changePassword[type])
    },
    ChangePassword() {
      this.$validator.validateAll().then(result => {
        if (result) {
          service
            .post(
              '/users/change-password',
              {
                newPassword: this.changePassword.new,
                newPasswordConfirm: this.changePassword.newConfirm,
                oldPassword: this.changePassword.old,
                userID: this.User.info.ID,
              },
              'ChangePass'
            )
            .then(response => {
              if (response.data.success) {
                // this.$router.push('/' + this.Language + '/profile')
                this.$toasted.success(this.$t('changepass_success'), { duration: 10000 })
                this.changePassword.old = ''
                this.changePassword.new = ''
                this.changePassword.newConfirm = ''
              }
            })
            .catch(error => {
              if (typeof error.response.data !== 'undefined') {
                this.changePassword.old = ''
                this.changePassword.new = ''
                this.changePassword.newConfirm = ''
                if (this.Language === 'fa') {
                  this.$toasted.error(error.response.data.detail, { duration: 10000, position: 'ltr:top-left rtl:top-right' })
                } else {
                  this.$toasted.error(error.response.data.detail, { duration: 10000 })
                }
              }
            })
        } else {
          // Validation failed
        }
      })
    },
  },
}
</script>
<style scoped>
.hidePass {
  -webkit-text-security: disc;
}
</style>
