const state = {
	noDepositBonus: {
		enabled: false
	},
	depositBonus: {
		enabled: false
	},
  bonusData: []
}

const mutations = {
	SET_BONUS_CONFIG(state, bonusconfig) {
		state.noDepositBonus.enabled = bonusconfig.noDepositBonus.enabled
		state.depositBonus.enabled = bonusconfig.depositBonus.enabled
	},
  SET_BONUS_DATA(state, data) {
    state.bonusData = data
  }
}

const actions = {
	set: ({ commit }, bonusconfig) => commit('SET_BONUS_CONFIG', bonusconfig),
  setBonusData: ({ commit }, data) => commit('SET_BONUS_DATA', data)
}

const getters = {
	all: state => state,
  bonusData: state => state.bonusData
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
