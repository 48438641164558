export default {
  initial() {
    return [
      {
        name: 'Dashboard',
        meta: {
          active: false,
          auth: true,
          icon: '/static/img/dashboard.svg',
          skipTopNav: true,
          skipHeading: true,
          T: 'dashboard',
        },
        path: '/',
        component: require('../views/QuickMenu').default,
      },
      {
        name: 'Local Depositor',
        meta: {
          active: false,
          icon: '/static/img/local-depositor.svg',
          T: 'local_depositor',
          needsPrivileges: ['local_depositor'],
          auth: true,
          skipMenu: false,
        },
        path: '/local-depositor',
        component: require('../views/local-depositor/Dashboard').default,
      },
      {
        name: 'Trading',
        meta: {
          active: false,
          icon: '/static/img/trading.svg',
          T: 'trading',
          auth: true,
        },
        path: '/trading-account',
        component: require('../views/TA/TA.vue').default,
        children: [
          {
            path: '',
            name: 'Account list',
            meta: {
              navActive: false,
              T: 'account_list',
            },
            component: require('../views/TA/ListAccounts').default,
          },
          {
            path: 'vps',
            name: 'VPS',
            meta: {
              navActive: false,
              T: 'vps',
            },
            component: require('../views/TA/VPS').default,
          },
          {
            path: 'download',
            name: 'Platforms',
            meta: {
              navActive: false,
              T: 'download',
            },
            component: require('../views/TA/Download').default,
          },
        ],
      },
      {
        name: 'Transactions',
        meta: {
          active: false,
          icon: '/static/img/transactions.svg',
          T: 'transactions',
          auth: true,
        },
        path: '/finance',
        component: require('../views/finance/Finance.vue').default,
        children: [
          {
            path: '',
            name: 'Deposit',
            meta: {
              navActive: false,
              T: 'deposit',
            },
            component: require('../views/finance/Deposit').default,
          },
          {
            path: 'withdraw',
            name: 'Withdraw',
            meta: {
              navActive: false,
              T: 'withdraw',
            },
            component: require('../views/finance/Withdraw').default,
          },
          {
            path: 'internal-transfer',
            name: 'Internal Transfer',
            meta: {
              navActive: false,
              T: 'internal_transfer',
            },
            component: require('../views/finance/InternalTransfer').default,
          },
          {
            path: 'payment-history',
            name: 'Payment History',
            meta: {
              navActive: false,
              T: 'payment_history',
            },
            component: require('../views/finance/PaymentHistory').default,
          },
          {
            path: 'my-cards',
            name: 'My Cards',
            meta: {
              navActive: false,
              T: 'my_cards',
            },
            component: require('../views/finance/MyCards').default,
          },
          {
            path: 'my-bonuses',
            name: 'My Bonuses',
            meta: {
              navActive: false,
              T: 'bonuses',
              needsPrivileges: ['live'],
            },
            component: require('../views/my-bonuses').default,
          },
        ],
      },
      {
        name: 'Plexy Profile',
        meta: {
          active: false,
          icon: '/static/img/my-settings.svg',
          T: 'my_settings',
          auth: true,
        },
        path: '/profile',
        component: require('../views/profile/Profile.vue').default,
        children: [
          // {
          //   path: '/',
          //   name: 'My Profile Menu',
          //   meta: {
          //     navActive: false,
          //     skipHeading: true,
          //     Ticon: 'quickmenu',
          //     T: 'quick_menu',
          //   },
          // },
          {
            path: '',
            name: 'Personal Information',
            meta: {
              navActive: false,
              T: 'personal_information',
            },
            component: require('../views/profile/PersonalInformation').default,
          },
          // {
          //   path: 'change-password',
          //   name: 'Change Password',
          //   meta: {
          //     navActive: false,
          //     T: 'change_password',
          //   },
          //   component: require('../views/profile/ChangePassword').default,
          // },
          {
            path: 'upload-documents',
            name: 'Upload Documents',
            meta: {
              navActive: false,
              T: 'upload_documents',
              needsPrivileges: ['live'],
            },
            component: require('../views/profile/UploadDocuments').default,
          },
          {
            path: 'guidance-documents',
            name: 'Guidance Documents',
            meta: {
              navActive: false,
              T: 'guidance_documents',
              needsPrivileges: ['live'],
            },
            component: require('../views/profile/guidance-documents/Base').default,
          },
        ],
      },
      {
        name: 'Pamm',
        meta: {
          active: false,
          icon: '/static/img/pamm.svg',
          T: 'pamm',
          needsPrivileges: ['live'],
          auth: true,
          skipMenu: true,
        },
        path: '/pamm',
        component: require('../views/pamm/PAMM.vue').default,
        children: [
          {
            path: '',
            name: 'PAMM statistics',
            meta: {
              navActive: false,
              T: 'pamm',
            },
            component: require('../views/pamm/pamm-statistics').default,
          },
          {
            path: 'new-master',
            name: 'Create a new master account',
            meta: {
              navActive: false,
              T: 'new_master_account',
            },
            component: require('../views/pamm/new-master').default,
          },
        ],
      },
      {
        name: 'Partner',
        meta: {
          active: false,
          icon: '/static/img/partners.svg',
          T: 'partners',
          needsPrivileges: ['ib'],
          auth: true,
          skipMenu: true,
        },
        path: '/partner',
        component: require('../views/partner/Partner.vue').default,
        children: [
          {
            path: '',
            name: 'Partner Overview',
            meta: {
              navActive: false,
              T: 'partners',
            },
            component: require('../views/partner/Overview').default,
          },
          {
            path: 'marketing-material',
            name: 'Partner Marketing Material',
            meta: {
              navActive: false,
              T: 'marketing_material',
            },
            component: require('../views/partner/MarketingMaterial').default,
          },
        ],
      },
      {
        name: 'Register',
        meta: {
          active: false,
          T: 'register',
          skipMenu: true,
        },
        path: '/register',
        children: [
          {
            path: '/',
            name: 'Register Home (Live)',
            meta: {
              auth: false,
              navActive: false,
              T: 'register_live_account',
              // registerComponent: 'Live',
            },
            component: require('../views/register/Index').default,
          },
          {
            path: '/live',
            name: 'Register Live',
            meta: {
              auth: false,
              navActive: false,
              T: 'register_live_account',
              registerComponent: 'Live',
            },
            component: require('../views/register/Base').default,
          },
          {
            path: '/partner',
            name: 'Register Partner',
            meta: {
              auth: false,
              navActive: false,
              T: 'register_partner_account',
              registerComponent: 'Partner',
            },
            component: require('../views/register/Base').default,
          },
          {
            path: '/demo',
            name: 'Register Demo',
            meta: {
              auth: false,
              navActive: false,
              T: 'register_demo_account',
              registerComponent: 'Demo',
            },
            component: require('../views/register/Base').default,
          },
          {
            path: '/upgrade',
            name: 'Upgrade to live',
            meta: {
              auth: true,
              navActive: false,
              T: 'upgrade_to_live_account',
              registerComponent: 'Upgrade-live',
            },
            component: require('../views/register/Base').default,
          },
        ],
      },
      {
        path: '/login',
        name: 'Login',
        meta: {
          auth: false,
          skipStructure: true,
          skipMenu: true,
          navActive: false,
        },
        component: require('../views/auth/Login').default,
      },
      {
        path: '/verify-email',
        name: 'Verify Email',
        meta: {
          auth: true,
          navActive: false,
          skipStructure: true,
          skipMenu: true,
        },
        component: require('../views/auth/VerifyEmail').default,
      },
      {
        path: '/forgot-password',
        name: 'Forgot Password',
        meta: {
          auth: false,
          skipStructure: true,
          skipMenu: true,
        },
        component: require('../views/auth/ForgotPassword').default,
      },
      {
        path: '/transfer-reset-password',
        name: 'Transfer Reset Password',
        meta: {
          auth: false,
          skipStructure: true,
          skipMenu: true,
        },
        component: require('../views/auth/TransferResetPassword').default,
      },
      {
        path: '/reset-password',
        name: 'Reset Password',
        meta: {
          auth: false,
          skipStructure: true,
          skipMenu: true,
        },
        component: require('../views/auth/ResetPassword').default,
      },
      {
        path: '/set-auth-key/:key',
        name: 'Set authentication key',
        meta: {
          skipStructure: true,
          skipMenu: true,
        },
        component: require('../views/auth/SetAuthKey').default,
      },
      {
        name: 'IB tracking link redirector',
        meta: {
          active: false,
          auth: false,
          skipStructure: true,
          skipMenu: true,
        },
        path: '/ib/r',
        component: require('../views/partner/TrackingLinkRedirector').default,
      },
    ]
  },
}
