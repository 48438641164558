const state = {
  code: null,
}

const mutations = {
  SET_LANGUAGE(state, lang) {
    state.code = lang
  },
}

const actions = {
  set: ({ commit }, lang) => commit('SET_LANGUAGE', lang),
}

const getters = {
  all: state => state,
  code: state => state.code,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
