<template>
  <div class="px-2 lg:px-0 md:pt-6 pb-20">
    <div v-if="!VPSAccounts.length && VPSAccountsLoaded"  class="bg-white px-5 md:px-0 rounded-lg shadow-ts1 h-[91vh] maxMd:h-auto">
        <div class="h-full flex justify-start md:justify-center gap-4 items-center flex-col relative py-16 md:py-0">
          <img src="../../assets/img/vps.svg" class="h-28 w-28" />
          <h1 class="font-display text-[2.5rem] text-blue font-bold leading-none mb-5 text-center flex items-center gap-x-2">
            <div>
              <span class="text-green">{{ $t('vps') }}</span> {{ $t('hosting') }}
            </div>
            <div class="relative group">
              <img class="w-[13px] h-[13px]" src="../../assets/img/icons/upload/info.svg" alt="Additional info" />
              <div
                class="font-body text-base text-gray-900 text-left font-normal absolute t-6 bg-white rounded-lg shadow-ts3 w-72 p-4 object-right-bottom hidden group-hover:block"
              >
                {{ $t('vps_description').tTitle() }}
              </div>
            </div>
          </h1>
          <p class="text-gray-900 text-base leading-7 max-w-md text-center  ">{{ $t('vps_text') }}</p>
          <div class="w-full flex flex-col items-center select-none">
            <button
              @click="showModal()"
              :disabled="!TALoaded || !availableTA.length"
              class="appearance-none py-3 text-base rounded-md text-white bg-green disabled:bg-gray-300 w-40 text-center font-bold flex items-center justify-center"
            >
              <svg class="animate-spin ltr:-ml-1 rtl:-mr-1 ltr:mr-3 rtl:ml-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="!TALoaded">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              {{ $t('order_now') }}
            </button>
            <p class="text-orange mt-4 text-center w-full" v-if="TALoaded && !availableTA.length" @click="showCreateAccountModal()" v-html="$t('no_available_live_account')"></p>
          </div>
          <div class=" w-full text-center bottom-6">
            <a
              :href="VPSLink"
              target="_blank"
              class="text-gray-900 text-sm font-semibold"
            >{{$t('learn_more')}}</a>
          </div>
        </div>
    </div>

    <div v-if="VPSAccounts.length > 0" class="md:min-h-[84vh]">
      <div class="flex gap-6 md:min-h-[84vh] flex-col-reverse lg:flex-row">
        <div class="w-full lg:w-calc4 pt-5 bg-white rounded-lg shadow-ts1">
          <div class="w-full pb-4 border-b border-gray-400 border-solid">
            <h2 class="ltr:pl-5 rtl:pr-5 ltr:md:pl-8 rtl:md:pr-8 text-blue font-display font-bold text-xl leading-none">{{ $t('vps') }}</h2>
          </div>

          <div class="w-auto mt-10 pb-5 mx-5 lg:mx-0">
            <div class="flex w-full flex-wrap lg:px-5 pb-3.5 md:pb-0 gap-5" id="VpsAccountsContainer">
              <div
                v-for="item in VPSAccounts"
                :key="item.trading_account"
                :id="item.trading_account"
                class="w-full md:w-[310px] flex-shrink-0 rounded-lg border border-solid border-gray-300"
              >
                <div class="p-5 relative">
                  <div class="flex mb-5 items-center">
                    <div
                      :class="[item.product_name == 'standard' ? 'bg-gray-900 text-green' : item.product_name == 'gold' ? 'bg-[#FFBE3B] text-white' : 'bg-blue text-white']"
                      class="h-[70px] w-[70px] rounded-full text-center font-display font-bold text-[2.5rem] shrink-0 pt-2"
                    >
                      {{ item.product_name == 'standard' ? 'S' : item.product_name == 'gold' ? 'G' : 'P' }}
                    </div>
                    <div class="grow flex justify-between items-center">
                      <p class="text-blue font-semibold text-xl ltr:ml-4 rtl:mr-4">{{ item.product_name == 'standard' ? 'Silver' : item.product_name == 'gold' ? 'Gold' : 'Platinum' }}</p>
                      <div
                        :class="[item.status == 'requested' ? 'bg-[#FFBE3B]' : [item.status === 'active' ? 'bg-green' : 'bg-orange']]"
                        class="w-[4.7rem] h-5 text-white text-center font-semibold text-xs rounded-xl leading-4 pt-0.5"
                      >
                        {{ item.status == 'requested' ? 'Pending' : item.status.tTitle() }}
                      </div>
                    </div>
                  </div>
                  <div class="w-full rounded-lg bg-[#F0F0F4] p-4">
                    <div class="mb-4">
                      <p class="text-gray-900 text-sm leading-6 font-semibold">{{ $t('trading_account').tTitle() }}</p>
                      <p class="pt-0.5 text-blue font-bold text-4xl">{{ item.trading_account }}</p>
                    </div>
                    <div class="flex justify-between items-end">
                      <div>
                        <p class="text-gray-900 font-semibold text-xs leading-1">
                          {{ item.platform == 'mt4' ? 'MetaTrader 4' : item.platform == 'mt5' ? 'MetaTrader 5' : 'PAMM' }}
                        </p>
                        <p class="text-gray-900 font-semibold text-xs leading-1">
                          {{ item.type.tTitle() }}
                        </p>
                      </div>
                      <div v-if="item.next_renewal">
                        <p class="text-gray-900 font-light text-xs leading-1">{{ $t('renewal_on') }}</p>
                        <p class="pt-0.5 text-gray-900 font-semibold text-xs text-right">{{item.next_renewal ?  $formatDate(new Date(item.next_renewal)) : ""}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button @click="showMoreItems()" id="loadMoreButton" class="bg-white appearance-none text-gray-900 text-center table md:hidden m-auto mt-10 mb-8 font-semibold">
              {{ $t('load_more') }}
            </button>
          </div>
        </div>

        <div class="w-full lg:w-[340px] bg-green rounded-lg shadow-ts1 flex items-center relative shrink-0 mt-7 md:mt-0">
          <div class="w-full md:w-auto px-5 lg:px-10 py-5 lg:py-0">
            <p class="text-white lg:text-center font-display font-bold text-xl lg:text-[2.5rem] lg:leading-[1.2] mb-10 md:mb-5">{{ $t('vps_hosting_service') }}</p>
            <p class="text-white text-base leading-7 text-left lg:text-center mb-10 hidden md:block">{{ $t('vps_text') }}</p>
            <div class="flex justify-between w-full items-center">
              <div class="w-full flex flex-col items-center select-none">
                <button
                  @click="showModal()"
                  :disabled="!TALoaded || !availableTA.length"
                  class="appearance-none py-3 text-base rounded-md text-green bg-white disabled:bg-[#8FD9C0] disabled:text-green w-48 text-center font-bold lg:m-auto flex items-center justify-center"
                  :class="!TALoaded || !availableTA.length ? 'cursor-not-allowed	' : ''"
                >
                  <svg class="animate-spin ltr:-ml-1 rtl:-mr-1 ltr:mr-3 rtl:ml-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="!TALoaded">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  {{ $t('order_now') }}
                </button>
                <p
                  class="text-gray-900 text-sm font-bold mt-3 text-center w-full"
                  v-if="TALoaded && !availableTA.length"
                  @click="showCreateAccountModal()"
                  v-html="$t('no_available_live_account')"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VPSModal @close="closeModal" @complete="refreshVPS" v-show="showVPSModal" :available-trading-accounts="availableTA"></VPSModal>
    <create-account-modal @close="closeCreateAccountModal" @complete="refreshVPS" v-show="showCAModal"></create-account-modal>
  </div>
</template>

<script>
import api from '@/api'
import { service } from '@/main.js'
import { mapActions, mapGetters } from 'vuex'
import links from '@/config/links'
import VPSModal from '@/components/VPSModal.vue'
import CreateAccountModal from '@/components/CreateAccountModal.vue'

export default {
  components: {
    VPSModal,
    CreateAccountModal,
  },
  data() {
    return {
      VPSAccounts: [],
      VPSAccountsLoaded: false,
      TALoaded: false,
      showVPSModal: false,
      showCAModal: false,
      availableTA: [],
    }
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
      User: 'user/all',
      logoLoaderActive: 'logoLoader/active',
    }),
    VPSLink(){
      return links.getLink('vpsToolsHosting', this.Language )
    }
  },

  mounted() {
    this.refreshVPS()
  },
  created() {
    window.addEventListener('resize', this.showLoadMore)
  },
  methods: {
    closeModal() {
      this.showVPSModal = false
      document.getElementById('VPScontainer').classList.add('translate-x-full')
      document.querySelector('body').classList.remove('overflow-hidden')

    },
    showModal() {
      this.showVPSModal = true
      document.querySelector('body').classList.add('overflow-hidden')
      setTimeout(() => document.getElementById('VPScontainer').classList.remove('translate-x-full'), 10)

    },
    closeCreateAccountModal() {
      this.showCAModal = false
      document.getElementById('CAcontainer').classList.add('translate-x-full')
    },
    showCreateAccountModal() {
      this.showCAModal = true
      setTimeout(() => document.getElementById('CAcontainer').classList.remove('translate-x-full'), 10)
    },
    async refreshVPS() {
      this.VPSAccountsLoaded = false
      this.TALoaded = false
      this['logoLoader/set'](true)

      const getVPS = await service.post(
        'vps/list',
        {
          userID: this.User.info.ID.toString(),
          page: 1,
          limit: 1000,
        },
        'getVPSList'
      )

      if (getVPS?.status !== 200) {
        this.$toasted.error(this.$t('error_getting_VPS'), { duration: 10000 })
        this['logoLoader/set'](false)
        return
      }

      if (getVPS?.data?.list) {
        this.VPSAccounts = getVPS.data.list
        // this.showLoadMore()
      }
      this.VPSAccountsLoaded = true
      this['logoLoader/set'](false)

      const getTA = await api.GetTradingAccounts()
      if (getTA?.status !== 200) {
        this.$toasted.error(this.$t('error_request_failed'), { duration: 10000 })
        this.TALoaded = true
        return false
      }

      this.TALoaded = true

      this.availableTA = []
      for (let i = 0; i < this.User.TA.length; i++) {
        if (this.User.TA[i].type !== 'live' || this.User.TA[i].account_type_identifier === 'pamm-investor' || this.User.TA[i].account_type_identifier === 'no-deposit-bonus' || this.User.TA[i].account_type_identifier === 'no-deposit' || this.User.TA[i].account_type_identifier === '') {
          continue
        }
        let taHasVPS = false
        for (let j = 0; j < this.VPSAccounts.length; j++) {
          if (this.User.TA[i].external_id === this.VPSAccounts[j].trading_account) {
            taHasVPS = true
            break
          }
        }
        if (!taHasVPS) {
          this.availableTA.push(this.User.TA[i])
        }
      }
    },
    showLoadMore() {
      var children = document.getElementById('VpsAccountsContainer').children
      for (var i = 0; i < children.length; i++) {
        var child = children[i]
        if (window.innerWidth < 769 && i < 2) {
          document.getElementById('loadMoreButton').classList.remove('hidden')
          child.classList.add('hidden')
        } else {
          // document.getElementById('loadMoreButton').classList.add('hidden')
          child.classList.remove('hidden')
        }
      }
    },
    showMoreItems() {
      var children = document.getElementById('VpsAccountsContainer').children
      for (var i = 0; i < children.length; i++) {
        var child = children[i]
        child.classList.remove('hidden')
      }
      document.getElementById('loadMoreButton').classList.add('hidden')
    },
    ...mapActions(['logoLoader/set', 'errorModal/set']),
  },
}
</script>
