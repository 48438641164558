<template>
  <div class="h-auto px-2 lg:px-0 pb-20">

    <div class="w-full bg-white rounded-md mt-6 shadow-ts1">
      <div class="flex justify-between px-6 pt-5 pb-4 border-b border-gray-400">
        <p class="font-display text-xl font-bold text-blue leading-none">{{$t('new_master_account').tTitle()}}</p>
        <img
          class="md:hidden w-3.5 h-2 mt-2.5 cursor-pointer"
          :class="{'rotate-180': formExpanded}"
          @click="formExpanded = !formExpanded"
          src="../../assets/img/platform-dropdown.svg"
        />
      </div>
      <div
        :class="{'hidden md:block': !formExpanded}"
        class="px-6 pt-5 pb-10"
      >
        <p class="font-semibold text-gray-900">{{$t('create_new_master_account')}}</p>

        <div class="md:flex md:flex-wrap md:mt-5">
          <div class="ltr:md:pr-4 rtl:md:pl-4 md:mt-4 md:w-2/5 md:min-w-[200px]">
            <label class="block mt-7 mb-1 md:mt-0 text-gray-900">{{ $t("display_name").tTitle() }}</label>
            <input
              v-model="formData.displayName"
              class="w-full p-3 bg-gray-400 rounded-md placeholder-gray-300 focus:outline-none text-gray-900"
              type="text"
              :placeholder="$t('display_name').tTitle()"
            />
          </div>

          <div class="md:flex md:w-3/5 md:min-w-[300px]">
            <div class="ltr:md:pr-4 rtl:md:pl-4 md:mt-4 md:w-1/2">
              <label class="block mt-5 mb-1 md:mt-0 text-gray-900">{{ $t("performance_fee").tTitle() }}</label>
              <div class="w-full inline-block relative order-1">
                <select
                  v-model="formData.performanceFee"
                  class="w-full p-3 bg-none bg-gray-400 rounded-md text-base text-gray-900 appearance-none focus:outline-none focus:ring-0 cursor-pointer focus:bg-gray-400 font-semibold"
                >
                  <option value="0">0</option>
                  <option value="5">5%</option>
                  <option value="10">10%</option>
                  <option value="15">15%</option>
                  <option value="20">20%</option>
                  <option value="25">25%</option>
                  <option value="30">30%</option>
                  <option value="50">50%</option>
                </select>
                <div class="select-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div class="md:mr-p md:mt-4 md:w-1/2">
              <label class="block mt-5 mb-1 md:mt-0 text-gray-900">{{ $t("minimum_deposit").tTitle() }}</label>
              <input
                v-model="formData.minimumDeposit"
                class="w-full p-3 bg-gray-400 rounded-md placeholder-gray-300 focus:outline-none text-gray-900"
                type="text"
                placeholder="0"
              />
            </div>
          </div>
        </div>

        <div class="flex mt-6 gap-3.5 justify-between flex-col lg:flex-row">

          <!-- <div class="ltr:md:pr-4 rtl:md:pl-4 md:w-1/5 md:min-w-[100px]">
            <label class="block mt-5 mb-1 md:mt-0 text-gray-900">{{ $t("platform").tTitle() }}</label>
            <div class="w-full inline-block relative order-1">
              <select
                v-model="formData.platform"
                class="w-full rtl:bg-left p-3 bg-gray-400 rounded-md text-base text-gray-900 appearance-none focus:outline-none focus:ring-0 cursor-pointer focus:bg-gray-400 font-semibold"
              >
                <option value="mt4">MT4</option>
                <option value="mt5">MT5</option>
              </select>
              <div class="select-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>  -->

          <!-- <div class="ltr:md:pr-4 rtl:md:pl-4 md:w-1/5 md:min-w-[100px]">
            <label class="block mt-5 mb-1 md:mt-0 text-gray-900">{{ $t("currency").tTitle() }}</label>
            <div class="w-full inline-block relative order-1">
              <select
                v-model="formData.currency"
                class="w-full rtl:bg-left p-3 bg-gray-400 rounded-md text-base text-gray-900 appearance-none focus:outline-none focus:ring-0 cursor-pointer focus:bg-gray-400 font-semibold"
              >
                <option value="USD">USD</option>
              </select>
              <div class="select-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>  -->


            <div class="form-check">
              <input
                class="checkbox"
                :class="{ 'appearance-none': formData.private == false }"
                type="checkbox"
                id="private_account"
                value=""
                v-model="formData.private"
              />
              <label
                for="private_account"
                class="checkbox-label text-gray-900"
              >{{ $t("private_master_account").tTitle() }}</label>
            </div>

            <div v-if="formData.private" class="form-check">
              <input
                class="checkbox"
                type="checkbox"
                id="displayNameOnStatistics"
                value=""
                v-model="formData.displayNameOnStatistics"
              />
              <label
                for="displayNameOnStatistics"
                class="checkbox-label text-gray-900"
              >{{ $t("display_name_on_statistics_table").tTitle() }}</label>
            </div>

            <div class="form-check">
              <input
                class="checkbox"
                :class="{ 'appearance-none': formData.lockedWithdrawals == false }"
                type="checkbox"
                id="reject_withdrawals"
                value=""
                v-model="formData.lockedWithdrawals"
              />
              <label
                for="reject_withdrawals"
                class="checkbox-label text-gray-900"
              >{{ $t("reject_withdrawals_during_open_trades").tTitle() }}</label>
            </div>

            <div class="form-check">
              <input
                class="checkbox"
                type="checkbox"
                id="additionalTerms"
                value=""
                v-model="formData.additionalTerms"
              />
              <label
                for="additionalTerms"
                class="checkbox-label text-gray-900"
              >{{ $t("additional_terms").tTitle() }}</label>
            </div>
            <button
              @click="createMasterAccount()"
              :disabled="submittingCreateNewMaster"
              class="bg-blue text-white font-body font-bold w-full lg:w-[196px] h-[50px] py-2 px-4 rounded-md mt-6 lg:mt-0"
              :class="{'bg-gray-300': submittingCreateNewMaster}"
            >{{ $t('submit').tTitle() }}</button>


          <!-- <div class="mt-10 md:mt-7 md:grow md:flex md:justify-end">

          </div> -->
        </div>
      </div>
    </div>

    <div class="w-full bg-white rounded-md mt-6 pb-6 shadow-ts1">
      <div class="px-6 pt-5 pb-4 border-b border-gray-400">
        <p class="font-display text-xl font-bold text-blue leading-none">{{$t('master_accounts').tTitle()}}</p>
      </div>
      <div
        v-if="userPammMasterAccounts.length>0"
        class="px-6 py-5"
      >
       <div class="hidden md:block overflow-auto pb-6" id="PammMasterTable" >
        <table class="min-w-[1100px] w-full mt-5 border-collapse">
          <thead class="font-body text-blue font-bold ltr:text-left rtl:text-right">
            <tr class="h-[50px]">
              <th class="ltr:pl-8 rtl:pr-8 text-[13px]">{{ $t("trading_account").tTitle() }}</th>
              <th class="text-[13px]">{{ $t("name").tTitle() }}</th>
              <th class="text-[13px]">{{ $t("performance_fee").tTitle() }}</th>
              <th class="text-[13px]">{{ $t("min_deposit").tTitle() }}</th>
              <th class="text-[13px]">{{ $t("approved").tTitle() }}</th>
              <th class="text-[13px]">{{ $t("private").tTitle() }}</th>
              <th class="text-[13px]">{{ $t("private_code").tTitle() }}</th>
              <th class="text-[13px]">{{ $t("display_name_on_statistics_table").tTitle() }}</th>
              <th class="text-[13px]">{{ $t("investor_link").tTitle() }}</th>
            </tr>
          </thead>
          <tbody class="font-body ltr:text-left rtl:text-right">
            <tr
              v-for="pma in userPammMasterAccounts"
              :key="pma.order"
              :id="pma.order"
              class="odd:bg-gray-400 even:bg-white h-[50px]"
            >
              <td class="font-bold ltr:rounded-l-xl rtl:rounded-r-xl ltr:pl-8 rtl:pr-8">{{pma.trading_account}}</td>
              <td>{{pma.name}}</td>
              <td>{{pma.performance_fee}}</td>
              <td>{{pma.minimum_deposit}}</td>
              <td>{{$t(pma.approved)}}</td>
              <td>{{$t(pma.private)}}</td>
              <td>{{pma.private_pw}}</td>
              <td>{{$t(pma.display_name_on_statistics)}}</td>
              <td class="ltr:rounded-r-xl rtl:rounded-l-xl">
                <div class="flex w-[260px] gap-4 items-center">
                  <input
                    class="w-[234px] h-[18px] overflow-hidden border-none bg-transparent"
                    v-on:focus="$event.target.select()"
                    ref="investor_link"
                    readonly
                    :value="'https://my.plexytrade.com/'+Language+'/pamm?pm='+getMD5(pma.trading_account)"/>
                  <a
                    class="leading-[0] cursor-pointer table"
                    @click="copyUrl('https://my.plexytrade.com/'+Language+'/pamm?pm='+getMD5(pma.trading_account))">
                    <img src="../../assets/img/copy.svg" width="32" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        <div class="w-full md:hidden text-body">
          <div
            v-for="(pma, i) in userPammMasterAccounts"
            :key="'m-'+pma.trading_account"
            @click="expandRow(i)"
            :id="'m-'+pma.trading_account"
            class="flex justify-between px-4 pt-[13px] rounded-lg odd:bg-gray-400 overflow-hidden"
            :class="{'max-h-[50px]': !pma.expanded}"
          >
            <div class="text-blue font-bold">
              <label class="block h-9">
                <img
                  width="12px"
                  height="12px"
                  class="ltr:mr-1 rtl:ml-1 cursor-pointer inline"
                  :class="{'-rotate-90': !pma.expanded}"
                  src='../../assets/img/platform-dropdown.svg'
                >
                {{ $t("trading_account").tTitle() }}
              </label>
              <label class="block h-9">{{ $t("display_name").tTitle() }}</label>
              <label class="block h-9">{{ $t("performance_fee").tTitle() }}</label>
              <label class="block h-9">{{ $t("minimum_deposit").tTitle() }}</label>
              <label class="block h-9">{{ $t("approved").tTitle() }}</label>
              <label class="block h-9">{{ $t("private").tTitle() }}</label>
              <label class="block h-9">{{ $t("private_code").tTitle() }}</label>
              <label class="block h-9">{{ $t("display_name_on_statistics_table").tTitle() }}</label>
              <div :class="{'hidden': !pma.expanded}" class=" pb-16">
                <label>{{ $t("investor_link").tTitle() }}</label>
                <div class="flex gap-4 items-center absolute bg-[#f7f7f7] w-[79%] rounded-lg mt-2">
                  <input
                    class="w-[234px] h-[18px] overflow-hidden border-none bg-transparent"
                    v-on:focus="$event.target.select()"
                    ref="investor_link"
                    readonly
                    :value="'https://my.plexytrade.com/'+Language+'/pamm?pm='+getMD5(pma.trading_account)"/>
                  <a
                    class="leading-[0] cursor-pointer table"
                    @click="copyUrl('https://my.plexytrade.com/'+Language+'/pamm?pm='+getMD5(pma.trading_account))">
                    <img src="../../assets/img/copy.svg" width="32" />
                  </a>
                </div>
              </div>
            </div>
            <div class="ltr:text-right rtl:text-left">
              <p class="h-9">{{pma.trading_account}}</p>
              <p class="h-9">{{pma.name}}</p>
              <p class="h-9">{{pma.performance_fee}}</p>
              <p class="h-9">{{pma.minimum_deposit}}</p>
              <p class="h-9">{{pma.approved.tTitle()}}</p>
              <p class="h-9">{{pma.private}}</p>
              <p class="h-9">{{pma.private_pw}}</p>
              <p class="h-9">{{pma.display_name_on_statistics}}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="mt-10"
      >
        <p class="text-center text-base text-blue font-semibold">{{$t('no_records')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { service } from '../../main.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      userPammMasterAccounts: [],
      formData: {
        displayName: '',
        performanceFee: 0,
        minimumDeposit: 0,
        private: false,
        lockedWithdrawals: false,
        additionalTerms: false,
        displayNameOnStatistics: false,
        // platform: 'mt4',
        // currency: 'USD',
      },
      formExpanded: true,
      submittingCreateNewMaster: false,
    }
  },
  computed: {
    ...mapGetters({
      User: 'user/all',
      Language: 'language/code',
      logoLoaderActive: 'logoLoader/active',
    }),
  },
  mounted() {
   this.getUserPammMasterAccounts()
  },
  watch: {},
  methods: {
    getUserPammMasterAccounts() {
      this['logoLoader/set'](true)
      service.get('/pamm/user-master-accounts/' + this.User.info.ID).then(response => {
        if (response.data.pammMasters) {
          this.userPammMasterAccounts = response.data.pammMasters
          this.$nextTick(() => {
            this.dragContainer('#PammMasterTable');
          });
        }
        for (let i = 0; i < this.userPammMasterAccounts.length; i++) {
          this.userPammMasterAccounts[i].expanded = false
        }

        this['logoLoader/set'](false)
        this.submittingCreateNewMaster = false
      })
    },
    createMasterAccount() {
      this.submittingCreateNewMaster = true
      this['logoLoader/set'](true)
      // if (this.userPammMasterAccounts.length >= 5) {
      //   this.$toasted.error(this.$t('cannot_create_more_pamm_master_accounts').tTitle(), { duration: 10000 })
      //   this['logoLoader/set'](false)
      //   this.submittingCreateNewMaster = false
      //   return
      // }

      if (this.formData.displayName.length <= 0 || this.formData.displayName.length > 32) {
        this.$toasted.error(this.$t('pamm_modal_error'), { duration: 10000 })
        this['logoLoader/set'](false)
        this.submittingCreateNewMaster = false
        return
      }

      if (
        Math.floor(this.formData.minimumDeposit) !== parseFloat(this.formData.minimumDeposit) ||
        isNaN(this.formData.minimumDeposit) ||
        this.formData.minimumDeposit < 0 ||
        this.formData.minimumDeposit > 1000000
      ) {
        this.$toasted.error(this.$t('pamm_modal_error1'), { duration: 10000 })
        this['logoLoader/set'](false)
        this.submittingCreateNewMaster = false
        return
      }

      service
        .post('/pamm/create-master-account', {
          displayName: this.formData.displayName,
          performanceFee: parseInt(this.formData.performanceFee),
          minimumDeposit: parseInt(this.formData.minimumDeposit),
          private: this.boolToYesNo(this.formData.private),
          lockedWithdrawals: this.boolToYesNo(this.formData.lockedWithdrawals),
          additionalTerms: this.boolToYesNo(this.formData.additionalTerms),
          displayNameOnStatistics: this.boolToYesNo(this.formData.private)=='no'?'yes':this.boolToYesNo(this.formData.displayNameOnStatistics),
          // platform: this.formData.platform,
          // currency: this.formData.currency,
        })
        .then(() => {
          this.$toasted.success(this.$t('pamm_master_account_created_successfully'), { duration: 10000 })
          this.$toasted.success(this.$t('password_generated_for_ta'), { duration: 10000 })
          this.displayName = ''
          this.getUserPammMasterAccounts()
        })
        .catch(() => {
          this['logoLoader/set'](false)
          this.submittingCreateNewMaster = false
        })
    },
    getMD5(str) {
      let md5 = require('md5')
      return md5(String(str))
    },
    boolToYesNo(b) {
      return b === true ? this.$t('yes') : this.$t('no')
    },
    expandRow(i) {
      this.$set(this.userPammMasterAccounts, i, { ...this.userPammMasterAccounts[i], expanded: !this.userPammMasterAccounts[i].expanded })
    },
    copyUrl(val) {
        const el = document.createElement('textarea');
        el.value = val;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
          document.getSelection().removeAllRanges();
          document.getSelection().addRange(selected);
        }
        this.$toasted.success(this.$t('Copied'))
    },
    ...mapActions(['logoLoader/set']),
  },
}
</script>
<style scoped>
#PammMasterTable::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}

#PammMasterTable::-webkit-scrollbar-thumb {
    background: #2b63e3;
    border-radius: 12px;
}
#PammMasterTable::-webkit-scrollbar-track {
    background: #e9edf4;
    border-radius: 12px;
}
  #PammMasterTable::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
/* Track */
#PammMasterTable::-webkit-scrollbar-track {
  background: #e9edf4;
  border-radius: 12px;
}
/* Handle */
#PammMasterTable::-webkit-scrollbar-thumb{
  background: #2b63e3;
  border-radius: 12px;
}

#PammMasterTable {
  scroll-behavior: smooth;
}

#PammMasterTable.active {
  scroll-behavior: auto;
}
</style>
