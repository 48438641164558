<template>
  <div class="step-two px-[20px]">
    <div class="form-wrapper grid grid-cols-4-default gap-5 mb-5">
      <!-- Gender -->
      <div class="col-span-4 lg:col-span-4" v-show="!companyActive">
        <label class="font-body text-base text-gray-900 tracking-wide" for="gender">{{ translations.text.gender }}<span class="text-orange ml-1">*</span></label>
        <div class="my-2">
          <span class="select is-fullwidth">
            <select
              v-validate="'required'"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('gender') }"
              :data-vv-as="translations.text.gender"
              name="gender"
              v-model="data.gender"
              class="text-base text-gray-900 font-semibold rtl:bg-left"
            >
              <option value="" selected hidden>{{ translations.text.gender }}</option>
              <option v-for="(item, index) in translations.genders" :value="index" :key="index">{{ item }}</option>
              <option value="none" key="n/a">{{ $t('gender_other') }}</option>
            </select>
          </span>
          <span v-show="errors.has('gender')" class="font-body text-xs text-orange font-semibold">{{ errors.first('gender') }}</span>
        </div>
      </div>
      <!-- Date of Birth -->
      <div class="col-span-4 lg:col-span-4 grid grid-cols-6-default gap-5" v-show="!companyActive">
        <!-- Year of Birth -->
        <div class="col-span-2 md:col-span-2">
          <label class="label is-small" for="dob_year">{{ translations.text.year }}<span class="text-orange ml-1">*</span></label>
          <div class="my-2">
            <span class="select is-fullwidth">
              <select
                v-validate="{ required: true, 'is-over-18': data.dateofbirth.day + '-' + data.dateofbirth.month + '-' + data.dateofbirth.year }"
                :class="{
                  'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('dob_year'),
                }"
                :data-vv-as="translations.text.year"
                name="dob_year"
                v-model="data.dateofbirth.year"
                class="text-base text-gray-900 font-semibold rtl:bg-left"
              >
                <option value="" selected hidden>{{ translations.text.year }}</option>
                <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
              </select>
            </span>
            <span
              v-show="errors.has('dob_year')"
              class="font-body text-xs text-orange font-semibold"
              :class="{ 'has-text-right': Language == 'fa' }"
              >{{ errors.first('dob_year') }}</span
            >
          </div>
        </div>
        <!-- Month of Birth -->
        <div class="col-span-2 md:col-span-2">
          <label class="label is-small" for="dob_month">{{ translations.text.month }}<span class="text-orange ml-1">*</span></label>
          <div class="my-2">
            <span class="select is-fullwidth">
              <select
                v-validate="'required'"
                :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('dob_month') }"
                :data-vv-as="translations.text.month"
                name="dob_month"
                v-model="data.dateofbirth.month"
                class="text-base text-gray-900 font-semibold rtl:bg-left"
              >
                <option value="" selected hidden>{{ translations.text.month }}</option>
                <option v-for="(month, index) in translations.months" :value="index" :key="index">{{ month }}</option>
              </select>
            </span>
            <span v-show="errors.has('dob_month')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
              errors.first('dob_month')
            }}</span>
          </div>
        </div>
        <!-- Day of Birth -->
        <div class="col-span-2 md:col-span-2">
          <label class="label is-small" for="dob_day">{{ translations.text.day }}<span class="text-orange ml-1">*</span></label>
          <div class="my-2">
            <span class="select is-fullwidth">
              <select
                v-validate="'required'"
                :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('dob_day') }"
                :data-vv-as="translations.text.day"
                name="dob_day"
                v-model="data.dateofbirth.day"
                class="text-base text-gray-900 font-semibold rtl:bg-left"
              >
                <option value="" selected hidden>{{ translations.text.day }}</option>
                <option v-for="day in daysInMonth" :value="day" :key="day">{{ day }}</option>
              </select>
            </span>
            <span v-show="errors.has('dob_day')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
              errors.first('dob_day')
            }}</span>
          </div>
        </div>
      </div>
      <!-- Address -->
      <div class="col-span-4 md:col-span-4">
        <label class="font-body text-base text-gray-900 tracking-wide" for="address">{{ translations.text.address }} 1<span class="text-orange ml-1">*</span></label>
        <div class="my-2">
          <input
            v-validate="'required|min:3|max:255'"
            :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('dir-address_') }"
            :name="'dir-address_'"
            type="text"
            :data-vv-as="translations.text.address"
            :placeholder="translations.text.address_placeholder"
            v-model="data.address"
            class="text-gray-900 text-base font-semibold"
          />
          <span v-show="errors.has('dir-address_')" class="font-body text-xs text-orange font-semibold">{{ errors.first('dir-address_') }}</span>
        </div>
      </div>
      <!-- Region -->
      <div class="col-span-4 md:col-span-4" v-if="accountType.toLowerCase() === 'partner'">
        <label class="font-body text-base text-gray-900 tracking-wide" for="address">{{ translations.text.target_geo_location }}<span class="text-orange ml-1">*</span></label>
        <div class="my-2">
          <input
            v-validate="'required|alpha_spaces|max:50'"
            :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('ib_main_target_region') }"
            :name="'ib_main_target_region'"
            type="text"
            :data-vv-as="translations.text.target_geo_location"
            :placeholder="translations.text.target_geo_location"
            v-model="data.ib_main_target_region"
            class="text-gray-900 text-base font-semibold"
          />
          <span v-show="errors.has('ib_main_target_region')" class="font-body text-xs text-orange font-semibold">{{ errors.first('ib_main_target_region') }}</span>
        </div>
      </div>
      <!-- Address 2 -->
      <div class="col-span-4 md:col-span-4">
        <label class="font-body text-base text-gray-900 tracking-wide" for="address2">{{ translations.text.address2 }}</label>
        <div class="my-2">
          <input
            v-validate="'min:3|max:255'"
            :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('dir-address2_') }"
            :name="'dir-address2_'"
            type="text"
            :data-vv-as="translations.text.address2"
            :placeholder="translations.text.optional"
            v-model="data.address2"
            class="text-gray-900 text-base font-semibold"
          />
          <span v-show="errors.has('dir-address2_')" class="font-body text-xs text-orange font-semibold">{{ errors.first('dir-address2_') }}</span>
        </div>
      </div>
      <!-- Town/City, Province/State, Postal/Zip Code -->
      <div class="col-span-4 lg:col-span-4 grid grid-cols-6-default gap-5" v-show="!companyActive">
        <!-- Town/City -->
        <div class="col-span-6 md:col-span-2">
          <label class="font-body text-base text-gray-900 tracking-wide" for="city">{{ translations.text.city }}<span class="text-orange ml-1">*</span></label>
          <p class="my-2">
            <input
              v-validate="'required|min:3|max:255'"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('city') }"
              name="city"
              type="text"
              :data-vv-as="translations.text.city"
              :placeholder="translations.text.city"
              v-model="data.city"
              class="text-base text-gray-900 font-semibold"
            />
            <span v-show="errors.has('city')" class="font-body text-xs text-orange font-semibold">{{ errors.first('city') }}</span>
          </p>
        </div>
        <!-- Province/State -->
        <div class="col-span-6 md:col-span-2">
          <label class="font-body text-base text-gray-900 tracking-wide" for="state_province">{{ translations.text.state_province }}</label>
          <p class="my-2">
            <input
              v-validate="'min:3|max:255'"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('state_province') }"
              name="state_province"
              type="text"
              :data-vv-as="translations.text.state_province"
              :placeholder="translations.text.state_province"
              v-model="data.state_province"
              class="text-base text-gray-900 font-semibold"
            />
            <span v-show="errors.has('state_province')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
              errors.first('state_province')
            }}</span>
          </p>
        </div>
        <!-- Postal/Zip Code -->
        <div class="col-span-6 md:col-span-2">
          <label class="font-body text-base text-gray-900 tracking-wide" for="post_code">{{ translations.text.post_zip_code }}<span class="text-orange ml-1">*</span></label>
          <p class="my-2">
            <input
              v-validate="'required|max:255'"
              :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('post_code') }"
              name="post_code"
              type="text"
              :data-vv-as="translations.text.post_code"
              :placeholder="translations.text.post_zip_code"
              v-model="data.post_code"
              class="text-base text-gray-900 font-semibold"
            />
            <span v-show="errors.has('post_code')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
              errors.first('post_code')
            }}</span>
          </p>
        </div>
      </div>
      <!-- Skype id -->
      <div class="col-span-4 md:col-span-2" v-if="accountType.toLowerCase() === 'partner'">
        <label class="font-body text-base text-gray-900 tracking-wide" :class="{ 'has-text-right': Language == 'fa' }" for="skype_id">{{ translations.text.skype_id }}</label>
        <p class="my-2">
          <input
            v-validate="'max:255'"
            :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('skype_id') }"
            name="skype_id"
            type="text"
            :data-vv-as="translations.text.skype_id"
            :placeholder="translations.text.skype_id"
            v-model="data.skype_id"
            class="text-gray-900 text-base font-semibold"
          />
          <span v-show="errors.has('skype_id')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
            errors.first('skype_id')
          }}</span>
        </p>
      </div>
      <!-- Social media -->
      <div class="col-span-4 md:col-span-2" v-if="accountType.toLowerCase() === 'partner'">
        <label class="font-body text-base text-gray-900 tracking-wide" :class="{ 'has-text-right': Language == 'fa' }" for="social_media">{{
          translations.text.social_media
        }}</label>
        <p class="my-2">
          <input
            v-validate="'max:255'"
            :class="{ 'ring-1 ring-orange bg-blue-200 placeholder:text-gray-900': errors.has('social_media') }"
            name="social_media"
            type="text"
            :data-vv-as="translations.text.social_media"
            :placeholder="translations.text.social_media"
            v-model="data.social_media"
            class="text-gray-900 text-base font-semibold"
          />
          <span v-show="errors.has('social_media')" class="font-body text-xs text-orange font-semibold" :class="{ 'has-text-right': Language == 'fa' }">{{
            errors.first('social_media')
          }}</span>
        </p>
      </div>
    </div>
    <div class="button-wrapper flex md:justify-start gap-[5%]">
      <button class="w-full max-w-[185px] h-[50px] bg-white text-blue border border-blue rounded-md font-body font-bold text-[16px] px-[2%]" @click="changePage()">
        {{ $t('previous') }}
      </button>
      <button class="w-full max-w-[185px] h-[50px] bg-blue text-white rounded-md font-body font-bold text-[16px] px-[2%]" @click="changePage(true)">
        {{ $t('continue').tTitle() }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  props: {
    accountType: String,
    activeStep: Number,
    isCompany: Boolean,
    translations: Object,
    formData: Object,
    upgrade: Boolean,
    prefilledTestData: Boolean,
    testData: Object,
  },
  data() {
    return {
      page: this.activeStep,
      years: Array.from({ length: 100 }, (v, i) => moment().year() - i + 1),
      data: {
        gender: '',
        dateofbirth: {
          day: '',
          month: '',
          year: '',
        },
        address: '',
        address2: '',
        ib_main_target_region: '',
        city: '',
        state_province: '',
        post_code: '',
        skype_id: '',
        socialMedia: '',
        numberOfShareholders: 0,
        numberOfDirectors: 0,
      },
    }
  },
  mounted() {
    if (!this.isCompany && this.formData.city) {
      Object.keys(this.formData).forEach(key => {
        this.data[key] = this.formData[key]
      })
    }
  },
  created() {
    this.$validator.reset()
    this.errors.clear()
    if (this.prefilledTestData && this.testData) {
      Object.keys(this.testData).forEach(key => {
        this.data[key] = this.testData[key]
      })
    }
  },
  watch: {
    // watcher for the i18n language change
    '$i18n.locale'() {
      this.$validator.reset()
    },
  },
  computed: {
    ...mapGetters({
      Language: 'language/code',
      BonusConfig: 'bonusconfig/all',
    }),
    companyActive() {
      this.$validator.reset()
      return this.isCompany
    },
    daysInMonth() {
      const year = this.data.dateofbirth.year || new Date().getFullYear()
      const month = this.data.dateofbirth.month || new Date().getMonth()
      return new Date(year, month, 0).getDate()
    },
  },
  methods: {
    changePage(nextStep) {
      if (nextStep) {
        this.validateForm()
      } else return this.$bus.$emit('ChangePage', this.page - 1)
    },
    validateForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$bus.$emit('DataSubmitted', this.data)
          this.$bus.$emit('ChangePage', this.page + 1)
          return
        }
        // scroll to first message
        let el = document.getElementsByName(this.errors.items[0].field)[0]
        if (el) return el.focus()
      })
    },
  },
}
</script>
<style scoped></style>
